import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { Add, ArrowLeft, ArrowRight, Cancel, Create, Delete } from '@material-ui/icons';

import labels from '../../../constants/Labels.json';
import './DomainValue.css';
import scrolling from '../../common/scrolling';

type Props = {
  label: string,
  level: number,
  index: number
};

type EditableDomainValue = {
  switchEditState: () => void,
  // eslint-disable-next-line react/no-unused-prop-types
  changeLabel: Function
};

type DraggableDomainValue = {
  moveDomain: Function,
  addChild: Function,
  changeLevel: Function,
  deleteElement: Function
};

function DomainValueLevel0(props: Props) {
  const [isEditing, setEdition] = useState(false);

  const switchEditState = e => {
    e.preventDefault();
    e.stopPropagation();
    setEdition(!isEditing);
  };

  return isEditing ? (
    <EditingStateDomainValue switchEditState={switchEditState} {...props} />
  ) : (
    <ReadOnlyDomainValue isDragging={false} isOver={false} switchEditState={switchEditState} {...props} />
  );
}

export default DomainValueLevel0;

function EditingStateDomainValue(props: Props & EditableDomainValue) {
  const { label, index, level, switchEditState, changeLabel } = props;

  const [value, setValue] = useState(label);

  return (
    <Grid
      container
      style={{
        width: '100%',
        paddingLeft: `${level * 2}em`,
        opacity: 1
      }}
      className="paddingTopBottom0"
    >
      <Grid item md={1}>
        <IconButton onClick={switchEditState}>
          <Cancel />
        </IconButton>
      </Grid>
      <Grid item md={11}>
        <TextField
          value={value}
          fullWidth
          onChange={e => setValue(e.target.value)}
          onBlur={() => {
            changeLabel(index, value);
          }}
          autoFocus
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              // Do code here
              ev.preventDefault();
              changeLabel(index, value);
              switchEditState();
            }
          }}
        />
      </Grid>
    </Grid>
  );
}

function ReadOnlyDomainValue({
  label,
  index,
  level,
  switchEditState,
  addChild,
  changeLevel,
  deleteElement,
  moveDomain
}: Props & EditableDomainValue & DraggableDomainValue) {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: { index, type: 'domainValue' },
    begin: () => {
      scrolling.addEventListenerForSidebar();
    },
    end: (item, monitor) => {
      scrolling.removeEventListenerForSidebar();
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        moveDomain(item.index, dropResult.hoverIndex);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const mAddChild = async event => {
    event.stopPropagation();
    await addChild(index)();
  };

  const [{ isOver }, drop] = useDrop({
    accept: 'domainValue',
    drop: () => ({ hoverIndex: index }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  drag(drop(ref));
  return (
    <div ref={ref} className="paddingTopBottom0">
      <Grid
        container
        alignItems="center"
        style={{
          width: '100%',
          paddingLeft: `${level * 2}em`,
          opacity: isDragging ? 0.8 : 1
        }}
        className={`${isOver ? "dropZone" : ''}
            ${level === 0 ? "title0" : ''}
            ${level === 1 ? "title1" : ''}
            ${"paddingTopBottom0"}`}
      >
        <Grid item md={1}>
          <IconButton onClick={switchEditState}>
            <Create />
          </IconButton>
        </Grid>
        <Grid item md={8}>
          <Typography variant="h6">{label}</Typography>
        </Grid>
        <Grid item md={3}>
          {(level === 0 || level === 1) && (
            <Tooltip title={labels.configuration.checkUpModels.addSubTitle}>
              <IconButton onClick={mAddChild}>
                <Add />
              </IconButton>
            </Tooltip>
          )}
          {level > 0 && (
            <Tooltip title={labels.configuration.checkUpModels.decrementLevel}>
              <IconButton onClick={changeLevel(index, -1)}>
                <ArrowLeft />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={labels.configuration.checkUpModels.incrementLevel}>
            <IconButton onClick={changeLevel(index, 1)}>
              <ArrowRight />
            </IconButton>
          </Tooltip>
          <Tooltip title={labels.deleteLabel}>
            <IconButton onClick={deleteElement(index)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}
