import labels from "../../../../../constants/labels/patient/test/TestFillingHeader.json";
import {inputIncludesErrorCentile} from "../../testUtils/calculateValue/ErrorCentileValue";
import {inputIncludesErrorTempsCentile} from "../../testUtils/calculateValue/ErrorTempsCentileValue";

const percentileError = ({ input = [], disableCentileColumn, columns = {}, id, testResult = {}, fireTab }) => ({
  header: {
    condition: !!((columns.ERR_CENTILE || columns.ERR_TEMPSCENTILE) && !disableCentileColumn),
    label: labels.resultColumnTitles.percentileError
  },
  column: {
    score: {
      displayCondition: inputIncludesErrorCentile(input) && !disableCentileColumn,
      replacementCondition: !!(columns.ERR_CENTILE && !inputIncludesErrorTempsCentile(input) && !disableCentileColumn),
      id: "ERR_CENTILE",
      placeholder: "-",
      controlName: `${id}.errCentile`,
      value: testResult.errCentile,
      fullWidth: true,
      inputProps: {
        inputProps: { step: 1, min: 0, max: 100, onKeyPress: fireTab }
      }
    },
    time: {
      displayCondition: inputIncludesErrorTempsCentile(input) && !disableCentileColumn,
      replacementCondition: !!(
        columns.ERR_TEMPSCENTILE &&
        !(columns.ERR_CENTILE && !inputIncludesErrorTempsCentile(input)) &&
        inputIncludesErrorCentile(input) &&
        !disableCentileColumn
      ),
      id: "ERR_TEMPSCENTILE",
      placeholder: "-",
      controlName: `${id}.errTempsCentile`,
      value: testResult.errTempsCentile,
      fullWidth: true,
      inputProps: {
        inputProps: { step: 1, min: 0, max: 100, onKeyPress: fireTab }
      }
    }
  }
});

export default percentileError;
