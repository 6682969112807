import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';

import '../../common/styles/dragAndDrop.css';

type Props = {
  rowNumber: number,
  columnNumber: number,
  isDragging: boolean
};

const DropColumn = (props: Props) => {
  const { isDragging, rowNumber, columnNumber } = props;
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: 'anamneseFormElement',
    drop: () => ({ rowNumber, columnNumber, newRow: false }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  drop(ref);

  return (
    <div ref={ref} className={`${isOver ? "dropZone" : ''} ${isDragging ? "emptyDropColumn" : ''} `} />
  );
};

export default DropColumn;
