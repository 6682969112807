import React from "react";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";
import labels from "../../../constants/labels/patient/report/ReportArrays.json";
import TextFieldFormControl from "../../TextFieldFormControl";
import trendUp from "../../../resources/images/progression/trend/trend_up.png";
import trendDown from "../../../resources/images/progression/trend/trend_down.png";
import trendStag from "../../../resources/images/progression/trend/trend_stag.png";
import trendStagRed from "../../../resources/images/progression/trend/trend_stag_red.png";
import arrowUp from "../../../resources/images/progression/circled_arrows/circled_arrow_up.png";
import arrowDown from "../../../resources/images/progression/circled_arrows/circled_arrow_down.png";
import arrowStag from "../../../resources/images/progression/circled_arrows/circled_arrow_stag.png";
import arrowStagRed from "../../../resources/images/progression/circled_arrows/circled_arrow_stag_low.png";
import ReportArrayChart2 from "../../patient/report/ReportArrayChart2";
import ReportArrayChart1 from "../../patient/report/ReportArrayChart1";


const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  progression: { width: '16px' },

  row: {
    marginBottom: `${theme.spacing(3)}px`
  },
  bgGreen: {
    backgroundColor: '#559944',
    '&:hover': {
      backgroundColor: '#2b9931'
    }
  },
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: `${theme.spacing(1)}px 0`
  },
  reportTable: {
    borderCollapse: 'collapse',
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    fontSize: '10px',
    lineHeight: '12px'
  },
  fullborder: {
    border: '#000 1px solid'
  },
  bleft: {
    borderLeft: 'black 1px solid',
    borderBottom: '1px solid #000'
  },
  bright: {
    borderRight: 'black 1px solid',
    borderBottom: '1px solid #000'
  },
  bhoriz: {
    borderBottom: '1px solid #000',
    borderTop: '1px solid #000'
  },
  tright: {
    textAlign: 'right'
  },
  tcenter: {
    textAlign: 'center'
  },
  tscore: {
    minWidth: '32px',
    paddingRight: '2px',
    textAlign: 'right'
  },
  tabProgress: {
    width: '12px'
  },
  tableRow: {
    width: '128px',
    textAlign: 'left',
    padding: '0px'
  }
}));


const valuesR1 = { zscore: -0.88, pc: 19, raw: 87 };
const valuesI1 = { zscore: -1.22, pc: 11, raw: 64 };
const valuesR2 = { zscore: -1.31, pc: 10, raw: 64 };
const valuesI2 = { zscore: -0.81, pc: 21, raw: 65 };

function getRowGeantEgoiste(content, reloadChart, classes) {
  const { options } = content;
  return (
    <tr>
      <td className={classNames(classes.bhoriz, classes.bleft)}>[BALE] Niveau de lecture (Le Géant Égoïste)</td>
      <td className={classNames(classes.bhoriz, classes.bright)} />
      <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesI2.raw}</td>
      <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesI2.zscore}</td>
      <td className={classNames(classes.bhoriz, classes.bright, classes.tscore)}>{valuesI2.pc}</td>
      <td
        className={classNames(classes.bhoriz, classes.tscore)}
        style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
      >
        {valuesR2.raw}
      </td>
      <td
        className={classNames(classes.bhoriz, classes.tscore)}
        style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
      >
        {valuesR2.zscore}
      </td>
      <td
        className={classNames(classes.bhoriz, classes.bright, classes.tscore)}
        style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
      >
        {valuesR2.pc}
      </td>
      {options.renewalOptions.displayTrendArrow && (
        <td>
          <img
            className={classes.tabProgress}
            src={options.renewalOptions.arrowSet === 'trend' ? trendDown : arrowDown}
            alt={labels.arrowDown}
          />
        </td>
      )}
      {options.displayBars && <ReportArrayChart2 content={content} reloadChart={reloadChart} classes={classes} />}
    </tr>
  );
}

function getRowMonsieurPetit(content, reloadChart, classes) {
  const { options } = content;
  return (
    <tr>
      <td className={classNames(classes.bhoriz, classes.bleft)}>[BALE] Niveau de lecture (Monsieur Petit)</td>
      <td className={classNames(classes.bhoriz, classes.bright)} />
      <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesI1.raw}</td>
      <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesI1.zscore}</td>
      <td className={classNames(classes.bhoriz, classes.bright, classes.tscore)}>{valuesI1.pc}</td>
      <td
        className={classNames(classes.bhoriz, classes.tscore)}
        style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
      >
        {valuesR1.raw}
      </td>
      <td
        className={classNames(classes.bhoriz, classes.tscore)}
        style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
      >
        {valuesR1.zscore}
      </td>
      <td
        className={classNames(classes.bhoriz, classes.bright, classes.tscore)}
        style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
      >
        {valuesR1.pc}
      </td>
      {options.renewalOptions.displayTrendArrow && (
        <td>
          <img
            className={classes.tabProgress}
            src={options.renewalOptions.arrowSet === 'trend' ? trendUp : arrowUp}
            alt={labels.arrowUp}
          />
        </td>
      )}
      {options.displayBars && <ReportArrayChart1 content={content} reloadChart={reloadChart} classes={classes} />}
    </tr>
  );
}

function getArrayChoiceDoubleHeader(content, reloadChart, classes) {
  const { options } = content;
  return (
    <div>
      <span style={{ fontSize: '14px' }}>{labels.arrayConfiguration.choice1}</span>
      <table className={classes.reportTable}>
        <tbody>
        <tr>
          <td colSpan="2" />
          <td colSpan="3" className={classNames(classes.fullborder, classes.tcenter)}>
            Bilan du 1.10.14
          </td>
          <td
            colSpan="3"
            className={classNames(classes.fullborder, classes.tcenter)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            Bilan R1
          </td>
          {options.renewalOptions.displayTrendArrow && <td />}
          {options.displayBars && <td />}
        </tr>
        <tr>
          <td colSpan="2" />
          <td className={classNames(classes.bleft, classes.tright, classes.tscore)} />
          <td className={classNames(classes.bhoriz, classes.tright, classes.tscore)}>É-T</td>
          <td className={classNames(classes.bright, classes.tright, classes.tscore)}>cent.</td>
          <td
            className={classNames(classes.bleft, classes.tright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          />
          <td
            className={classNames(classes.bhoriz, classes.tright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            É-T
          </td>
          <td
            className={classNames(classes.bright, classes.tright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            cent.
          </td>
          {options.renewalOptions.displayTrendArrow && <td />}
          {options.displayBars && <td />}
        </tr>
        {getRowMonsieurPetit(content, reloadChart, classes)}
        {getRowGeantEgoiste(content, reloadChart, classes)}
        </tbody>
      </table>
    </div>
  );
}

function getArrayChoiceSingleHeader(content, reloadChart, classes) {
  const { options } = content;
  return (
    <div>
      <span style={{ fontSize: '14px' }}>{labels.arrayConfiguration.choice2}</span>
      <table className={classes.reportTable}>
        <tbody>
        <tr>
          <td colSpan="2" />
          <td colSpan="3" className={classNames(classes.fullborder, classes.tcenter)}>
            Bilan du 1.10.14
          </td>
          <td
            className={classNames(classes.bhoriz, classes.tright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          />
          <td
            className={classNames(classes.bhoriz, classes.tright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            É-T
          </td>
          <td
            className={classNames(classes.bhoriz, classes.bright, classes.tright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            cent.
          </td>
          {options.renewalOptions.displayTrendArrow && <td />}
          {options.displayBars && <td />}
        </tr>
        {getRowMonsieurPetit(content, reloadChart, classes)}
        {getRowGeantEgoiste(content, reloadChart, classes)}
        </tbody>
      </table>
    </div>
  );
}

function getArrayChoiceSingleHeaderSplit(content, reloadChart, classes) {
  const { options } = content;
  return (
    <div>
      <span style={{ fontSize: '14px' }}>{labels.arrayConfiguration.choice3}</span>
      <table className={classes.reportTable}>
        <tbody>
        <tr>
          <td colSpan="2" />
          <td
            className={classNames(classes.bhoriz, classes.bleft, classes.tright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          />
          <td
            className={classNames(classes.bhoriz, classes.tright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            É-T
          </td>
          <td
            className={classNames(classes.bhoriz, classes.bright, classes.tright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            cent.
          </td>
          {options.renewalOptions.displayTrendArrow && <td />}
          {options.displayBars && <td />}
          <td colSpan="3" className={classNames(classes.tcenter)}>
            Bilan du 1.10.14
          </td>
        </tr>
        <tr>
          <td className={classNames(classes.bhoriz, classes.left)}>[BALE] Niveau de lecture (Monsieur Petit)</td>
          <td className={classNames(classes.bhoriz, classes.bright)} />
          <td
            className={classNames(classes.bhoriz, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            {valuesI1.raw}
          </td>
          <td
            className={classNames(classes.bhoriz, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            {valuesI1.zscore}
          </td>
          <td
            className={classNames(classes.bhoriz, classes.bright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            {valuesI1.pc}
          </td>
          {options.displayBars && <ReportArrayChart1 content={content} reloadChart={reloadChart} classes={classes} />}
          {options.renewalOptions.displayTrendArrow && (
            <td>
              <img
                className={classes.tabProgress}
                src={options.renewalOptions.arrowSet === 'trend' ? trendUp : arrowUp}
                alt={labels.arrowUp}
              />
            </td>
          )}
          <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesR1.raw}</td>
          <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesR1.zscore}</td>
          <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesR1.pc}</td>
        </tr>
        <tr>
          <td className={classNames(classes.bhoriz, classes.bleft)}>[BALE] Niveau de lecture (Le Géant Égoïste)</td>
          <td className={classNames(classes.bhoriz, classes.bright)} />
          <td
            className={classNames(classes.bhoriz, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            {valuesI2.raw}
          </td>
          <td
            className={classNames(classes.bhoriz, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            {valuesI2.zscore}
          </td>
          <td
            className={classNames(classes.bhoriz, classes.bright, classes.tscore)}
            style={{ background: options.renewalOptions.currentAssessmentBackgroundColor }}
          >
            {valuesI2.pc}
          </td>
          {options.displayBars && <ReportArrayChart2 content={content} reloadChart={reloadChart} classes={classes} />}
          {options.renewalOptions.displayTrendArrow && (
            <td>
              <img
                className={classes.tabProgress}
                src={options.renewalOptions.arrowSet === 'trend' ? trendDown : arrowDown}
                alt={labels.arrowDown}
              />
            </td>
          )}
          <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesR2.raw}</td>
          <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesR2.zscore}</td>
          <td className={classNames(classes.bhoriz, classes.tscore)}>{valuesR2.pc}</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}


const ReportArrayForm = ({options, content, reloadChart, renewal, switchChange, change}) => {
  const classes = useStyles();

  return <Grid container>
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.bold}>
          {labels.arrayConfiguration.label}
        </FormLabel>
        <RadioGroup
          name="renewalOptions.tableType"
          value={options.renewalOptions.tableType}
          onChange={change}
        >
          <FormControlLabel
            value="double-headers"
            control={<Radio/>}
            label={getArrayChoiceDoubleHeader(content, reloadChart, classes)}
          />
          <FormControlLabel
            value="single-header"
            control={<Radio/>}
            label={getArrayChoiceSingleHeader(content, reloadChart, classes)}
          />
          <FormControlLabel
            value="single-header-split"
            control={<Radio/>}
            label={getArrayChoiceSingleHeaderSplit(content, reloadChart, classes)}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
    <Typography variant="h4">{labels.arrayOptions.label}</Typography>
    <Grid item xs={12} container>
      <Grid item xs={6}>
        <TextFieldFormControl
          itemMdValue={12}
          id="renewalOptions.currentAssessmentBackgroundColor"
          controlName="renewalOptions.currentAssessmentBackgroundColor"
          inputType="color"
          value={options.renewalOptions.currentAssessmentBackgroundColor}
          label={labels.arrayOptions.backgroundColor}
          onChangeHandler={change}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} container>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={options.renewalOptions.displayTrendArrow}
                onChange={switchChange('renewalOptions.displayTrendArrow')}
              />
            }
            label={labels.arrayOptions.progressionArrow.displayProgressionArrow}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={options.displayBars} onChange={switchChange('displayBars')}/>}
            label={labels.arrayOptions.progressionArrow.displayHorizontalBars}
          />
        </Grid>
      </Grid>
    </Grid>
    {renewal && (
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Divider/>
        </Grid>
        <Grid item xs={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.bold}>
              {labels.arrayOptions.progressionArrow.label}
            </FormLabel>
            <RadioGroup
              name="renewalOptions.arrowSet"
              value={options.renewalOptions.arrowSet}
              onChange={change}
            >
              <FormControlLabel
                value="trend"
                control={<Radio/>}
                label={
                  <span>
                          {labels.arrayOptions.progressionArrow.set1}
                    <img
                      className={classes.progression}
                      alt={labels.arrayOptions.progressionArrow.trendPicAlt.trendUp}
                      src={trendUp}
                    />
                          <img
                            className={classes.progression}
                            alt={labels.arrayOptions.progressionArrow.trendPicAlt.trendDown}
                            src={trendDown}
                          />
                          <img
                            className={classes.progression}
                            alt={labels.arrayOptions.progressionArrow.trendPicAlt.trendStag}
                            src={trendStag}
                          />
                          <img
                            className={classes.progression}
                            alt={labels.arrayOptions.progressionArrow.trendPicAlt.trendStagRed}
                            src={trendStagRed}
                          />
                        </span>
                }
              />
              <FormControlLabel
                value="circled"
                control={<Radio/>}
                label={
                  <span>
                          {labels.arrayOptions.progressionArrow.set2}
                    <img
                      className={classes.progression}
                      alt={labels.arrayOptions.progressionArrow.trendPicAlt.trendUp}
                      src={arrowUp}
                    />
                          <img
                            className={classes.progression}
                            alt={labels.arrayOptions.progressionArrow.trendPicAlt.trendDown}
                            src={arrowDown}
                          />
                          <img
                            className={classes.progression}
                            alt={labels.arrayOptions.progressionArrow.trendPicAlt.trendStag}
                            src={arrowStag}
                          />
                          <img
                            className={classes.progression}
                            alt={labels.arrayOptions.progressionArrow.trendPicAlt.trendStagRed}
                            src={arrowStagRed}
                          />
                        </span>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.bold}>
              {labels.arrayOptions.previousResultsDisplay.label}
              <Typography variant="body1">{labels.arrayOptions.previousResultsDisplay.tip}</Typography>
            </FormLabel>
            <RadioGroup
              name="renewalOptions.historyWithNoCurrentData"
              value={options.renewalOptions.historyWithNoCurrentData}
              onChange={change}
            >
              <FormControlLabel
                value="no-show"
                control={<Radio/>}
                label={labels.arrayOptions.previousResultsDisplay.onlyCommonTests}
              />
              <FormControlLabel
                value="show-in-table"
                control={<Radio/>}
                label={labels.arrayOptions.previousResultsDisplay.allPreviousResults}
              />
              <FormControlLabel
                value="show-as-text"
                control={<Radio/>}
                label={labels.arrayOptions.previousResultsDisplay.previousResultsOnTop}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    )}
  </Grid>;
};

export default ReportArrayForm;
