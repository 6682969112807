import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip
} from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import RichTextEditor from 'react-rte';
import uuidv4 from 'uuid/v4';
import { makeStyles } from '@material-ui/styles';
import { getColorEntity, getColorStyles, getTextAlignStyles } from 'react-rte/lib/lib/blockStyleFunctions';
import MyRichTextEditor from '../../../common/MyRichTextEditor';
import isEmptyComment from '../../../common/RichTextEditorUtil';
import labels from '../../../../constants/labels/configuration/memorizedTexts/EditingListItem.json';

type Props = {
  label: string,
  defaultValue?: string,
  onValidateLabel: string => void,
  onCancelAdd: void => void,
  insertedByDefault?: boolean,
  setInsertedByDefault?: boolean => void
};

const useStyles = makeStyles({
  showButtonNextLine: {
    display: 'contents !important'
  }
});

function EditingListItem({
  label,
  defaultValue,
  onValidateLabel,
  onCancelAdd,
  insertedByDefault,
  setInsertedByDefault
}: Props) {
  const classes = useStyles();

  const [newValue, setNewValue] = useState(
    defaultValue
      ? RichTextEditor.createValueFromString(defaultValue, 'html', { customInlineFn: getColorEntity })
      : RichTextEditor.createEmptyValue()
  );
  const editorRef = useRef();

  useEffect(() => {
    if (editorRef && editorRef.current && editorRef.current.refs && editorRef.current.refs.editor) {
      editorRef.current.refs.editor.focus();
    }
  }, [defaultValue]);

  const addNewLabel = () => {
    const s = newValue.toString('html', { blockStyleFn: getTextAlignStyles, entityStyleFn: getColorStyles });
    if (isEmptyComment(s)) {
      cancelAdd();
      return;
    }
    onValidateLabel(s);
  };

  const cancelAdd = () => {
    setNewValue('');
    onCancelAdd();
  };

  const textChange = v => {
    setNewValue(v);
  };

  const rteId = uuidv4();

  return (
    <ListItem>
      <ListItemText
        primary={
          <Fragment>
            {label && <InputLabel htmlFor={rteId}>{label}</InputLabel>}
            <MyRichTextEditor
              id={rteId}
              value={newValue}
              editorRef={editorRef}
              onChange={textChange}
              onBlur={addNewLabel}
              withColors
            />
          </Fragment>
        }
      />
      <ListItemSecondaryAction className={classes.showButtonNextLine}>
        <IconButton
          onClick={() => {
            addNewLabel();
            cancelAdd();
          }}
        >
          <Check />
        </IconButton>
        <IconButton onClick={cancelAdd}>
          <Clear />
        </IconButton>
        <FormControlLabel
          control={
            <Tooltip title={labels.tooltip}>
              <Switch
                checked={insertedByDefault}
                onChange={e => setInsertedByDefault(e.target.checked)}
                name="checkedB"
                color="primary"
              />
            </Tooltip>
          }
          label={labels.label}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

EditingListItem.defaultProps = {
  defaultValue: '',
  insertedByDefault: false,
  setInsertedByDefault: () => {}
};

export default EditingListItem;
