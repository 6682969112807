import _ from 'lodash';
import Patient from '../components/common/data/Patient';
import Anamnesis from '../components/common/data/Anamnesis';
import Bilan from '../components/common/data/Bilan';
import Cotation from '../components/common/data/Cotation';
import Comments from '../components/common/data/Comments';

// eslint-disable-next-line import/prefer-default-export
export async function importPatient(
  { patientUUID, jsonAnamnese, jsonBilan, jsonCotations, jsonPatient, reportComments },
  anonymousFirstname,
  anonymousLastname,
  anonymousComplaint,
  patientAlreadyExist
) {
  const patientId = (patientUUID || '').replace('{', '').replace('}', '');

  const savedDatas = {};

  if (jsonPatient) {
    const patient = JSON.parse(jsonPatient);

    if (patientAlreadyExist) {
      const patientFromDb = await Patient.retrievePatient(patientUUID.replace('{', '').replace('}', ''));
      patient._rev = patientFromDb._rev;
    }

    if (anonymousFirstname && anonymousFirstname !== '') {
      patient.firstName = anonymousFirstname;
    }
    if (anonymousLastname && anonymousLastname !== '') {
      patient.lastName = anonymousLastname;
    }
    if (anonymousComplaint && anonymousComplaint !== '') {
      patient.plainte = anonymousComplaint;
    }
    const newEmptyOne = await Comments.getNewEmptyOne();
    patient.comments = reportComments ? _.merge(newEmptyOne, JSON.parse(reportComments)) : newEmptyOne;
    const newBilan = await Bilan.getNewEmptyOne({ patientId });
    patient.bilan = jsonBilan ? _.merge(newBilan, JSON.parse(jsonBilan)) : newBilan;
    const newCotation = Cotation.getNewEmptyOne({ bilanId: patient.bilan._id });
    if (
      jsonCotations &&
      JSON.parse(jsonCotations) &&
      Array.isArray(JSON.parse(jsonCotations)) &&
      JSON.parse(jsonCotations)[0] &&
      Object.keys(JSON.parse(jsonCotations)[0]).length !== 0
    ) {
      // eslint-disable-next-line prefer-destructuring
      patient.cotation = _.merge(newCotation, JSON.parse(jsonCotations)[0]);
    } else if (
      jsonCotations &&
      JSON.parse(jsonCotations) &&
      !Array.isArray(JSON.parse(jsonCotations)) &&
      Object.keys(JSON.parse(jsonCotations)).length !== 0
    ) {
      // eslint-disable-next-line prefer-destructuring
      patient.cotation = _.merge(newCotation, JSON.parse(jsonCotations));
    } else {
      patient.cotation = newCotation;
    }
    const newAnamnese = Anamnesis.getNewEmptyOne({ patientId });
    patient.anamnese = jsonAnamnese
      ? _.merge(newAnamnese, { ...JSON.parse(jsonAnamnese), bilanId: patient.bilan.id, patientId: patient._id })
      : newAnamnese;
    console.log('import patient', { patient });
    savedDatas.patient = await Patient.updatePatient({
      ...patient,
      curClasse: patient.curClasse ? patient.curClasse.toString() : '',
      _id: patientId,
      dbtype: Patient.TYPE
    });
  }

  return savedDatas;
}
