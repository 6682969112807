const isScoreCentile = testResult =>
  testResult.inScoreCentile === true && testResult.scoreCentile !== undefined && testResult.scoreCentile !== '';

const isErrTempsCentile = testResult =>
  testResult.inErrTempsCentile === true &&
  testResult.errTempsCentile !== undefined &&
  testResult.errTempsCentile !== '';

const isErrCentile = testResult =>
  testResult.inErrCentile === true && testResult.errCentile !== undefined && testResult.errCentile !== '';

const isTempsCentile = testResult =>
  testResult.inTempsCentile === true && testResult.tempsCentile !== undefined && testResult.tempsCentile !== '';

const isNSOrScoreET = testResult =>
  (testResult.inNs === true || testResult.inScoreET === true) &&
  testResult.scoreET !== undefined &&
  testResult.scoreET !== '';

const isErrScoreET = testResult =>
  testResult.inErrScoreET === true && testResult.errScoreET !== undefined && testResult.errScoreET !== '';

const isTempsET = testResult =>
  testResult.inTempsET === true && testResult.tempsET !== undefined && testResult.tempsET !== '';

export { isScoreCentile, isErrTempsCentile, isErrCentile, isTempsCentile, isNSOrScoreET, isErrScoreET, isTempsET };
