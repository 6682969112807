import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { Delete, Edit, Unarchive } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import routes from '../../../constants/routes';
import commonLabels from '../../../constants/Labels.json';
import AnamnesisModel from '../../common/data/AnamnesisModel';
import log from '../../Logger';
import ListStyle from '../../../constants/styles/ListStyle.json';

const useStyles = makeStyles({ ...ListStyle });

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  exportAnamneseModel: any => void
};

const AnamneseList = ({ exportAnamneseModel }: Props) => {
  const classes = useStyles();
  const [models, setModels] = useState([]);

  useEffect(() => {
    updateCheckUpModelList();
  }, []);

  const updateCheckUpModelList = async () => {
    try {
      const modelsFromDb = await AnamnesisModel.getAll();
      setModels(modelsFromDb);
    } catch (err) {
      log.error('Error retrieving anamneseModels', err);
    }
  };

  const deleteModel = idToDelete => async () => {
    const modelToDelete = models.filter(({ _id }) => _id === idToDelete)[0];
    modelToDelete.archived = true;
    await AnamnesisModel.save(modelToDelete);
    await updateCheckUpModelList();
  };

  return (
    <Grid item md={12}>
      <List>
        {models.map(({ _id, name }) => (
          <Box boxShadow={2} className={classes.listItemBox} key={_id}>
            <ListItem
              button
              component={Link}
              to={routes.CONFIGURATION.ANAMNESIS.getForPath(_id)}
              className={classes.orthoscribeList}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary={name} />
              <ListItemSecondaryAction>
                <Tooltip title={commonLabels.deleteLabel}>
                  <IconButton onClick={deleteModel(_id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
                <Tooltip title={commonLabels.exportLabel}>
                  <IconButton onClick={() => exportAnamneseModel(_id)}>
                    <Unarchive />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          </Box>
        ))}
      </List>
    </Grid>
  );
};

export default AnamneseList;
