import React from 'react';
import { useDrag } from 'react-dnd';
import { Button } from '@material-ui/core';

import './DragButton.css';
import scrolling from '../../../common/scrolling';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  icon: any,
  label: string,
  // eslint-disable-next-line flowtype/no-weak-types
  value: any,
  // eslint-disable-next-line flowtype/no-weak-types
  onDrop: Function,
  // eslint-disable-next-line flowtype/no-weak-types
  onDragStateChange: Function
};

const DragButton = (props: Props) => {
  const { icon, label, value, onDragStateChange, onDrop } = props;

  const [, drag] = useDrag({
    item: { value, type: 'anamneseFormElement' },
    begin: () => {
      onDragStateChange(true);
      scrolling.addEventListenerForSidebar();
    },
    end: (item, monitor) => {
      scrolling.removeEventListenerForSidebar();
      onDragStateChange(false);
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(item.value, dropResult.rowNumber, dropResult.columnNumber, dropResult.newRow);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div ref={drag} className="fullHeight">
      <Button variant="contained" fullWidth>
        {icon} {label}
      </Button>
    </div>
  );
};

export default DragButton;
