import React from 'react';
import './Loader.css';

export default function Loader() {
  return (
    <div className="ldsEllipsis">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
