import React, { Component } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  FormLabel,
  Select
} from '@material-ui/core';

import AnamnesisElement from './AnamnesisElement';

import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class Age extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    return (
      <AnamnesisElement
        {...props}
        editableElement={editableElement}
        readOnlyElement={readOnlyElement}
      />
    );
  }
}

function editableElement(element, handleChange) {
  const { label, placeholder, unit } = element;
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={label}
          fullWidth
          name="label"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={placeholder}
          fullWidth
          name="placeholder"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Select native value={unit} name="unit" onChange={handleChange}>
                  <option value="semaines">
                    {labels.tabs.form.right.age.weeks}
                  </option>
                  <option value="mois">
                    {labels.tabs.form.right.age.months}
                  </option>
                  <option value="ans">
                    {labels.tabs.form.right.age.years}
                  </option>
                </Select>
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  );
}

function readOnlyElement(element) {
  const { label, placeholder, unit } = element;
  return (
    <div>
      <FormLabel component="legend">{label}</FormLabel>
      <TextField
        disabled
        fullWidth
        defaultValue={placeholder}
        style={{ marginBottom: '2rem' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Select native value={unit} disabled>
                <option value="semaines">
                  {labels.tabs.form.right.age.weeks}
                </option>
                <option value="mois">
                  {labels.tabs.form.right.age.months}
                </option>
                <option value="ans">{labels.tabs.form.right.age.years}</option>
              </Select>
            </InputAdornment>
          )
        }}
      />
    </div>
  );
}
