import React from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import labels from '../../../constants/labels/patient/test/CalculationTableSelector.json';

type Props = {
  currentTest: { calcul: Array<{ id: string, label: string }> } | undefined,
  currentTestResults: { $$calculOption: string } | undefined,
  // eslint-disable-next-line flowtype/no-weak-types
  changeSelectedCalculusTable: Function,
  fieldLabel: string | undefined,
  mdValue: number | undefined
};

const CalculationTableSelector = ({
  currentTest,
  currentTestResults,
  changeSelectedCalculusTable,
  fieldLabel = labels.calculationTable,
  mdValue = 3
}: Props) => (
  <Grid item md={mdValue}>
    <TextField
      fullWidth
      select
      value={currentTestResults.$$calculOption}
      onChange={changeSelectedCalculusTable}
      label={fieldLabel}
    >
      {(currentTest.calcul || []).map(({ id, label }) => (
        <MenuItem key={id} value={id}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  </Grid>
);

export default CalculationTableSelector;
