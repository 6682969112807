import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@material-ui/core';
import { ExpandMore, Settings, ShowChart, TableChart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import labels from '../../../../constants/labels/patient/report/ReportGeneration.json';
import routes from '../../../../constants/routes';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%'
  },
  row: {
    marginBottom: `${theme.spacing(3)}px`
  },
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: `${theme.spacing(1)}px 0`
  }
}));

type Props = {
  patientId: string | number,
  isRenewal: boolean
};

const GenerationOptions = ({ patientId, isRenewal }: Props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.fullWidth}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">{labels.options}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} container>
            <Grid item xs={12} className={classes.row}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={routes.PATIENT.REPORT.getForPath({
                  path: routes.PATIENT.REPORT.OPTIONS,
                  patientId
                })}
              >
                <Settings /> {labels.creationOptions}
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={routes.PATIENT.REPORT.getForPath({
                  path: routes.PATIENT.REPORT.GRAPHICS,
                  patientId
                })}
              >
                <ShowChart /> {labels.graphicsOptions}
              </Button>
            </Grid>
            {isRenewal && (
              <Grid item xs={12} className={classes.row}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={routes.PATIENT.REPORT.getForPath({
                    path: routes.PATIENT.REPORT.ARRAYS,
                    patientId
                  })}
                >
                  <TableChart /> {labels.arraysOptions}
                </Button>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default GenerationOptions;
