import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import routes from '../../../constants/routes';
import labels from '../../../constants/labels/configuration/reportOptions/ReportOptionChoice.json';
import reportTableOptions from '../../../resources/images/reportTableOptions.png';
import reportOptions from '../../../resources/images/reportOptions.png';
import reportGraphicOptions from '../../../resources/images/reportGraphicOptions.png';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 'auto',
    maxHeight: 'fit-content',
    height: 'fit-content',
    marginTop: '5vh'
  },
  media: {
    height: '15vh'
  }
});

const ReportOptionChoice = () => {
  const classes = useStyles();

  const array = [
    {
      labelEntry: labels.reportOptions,
      image: reportOptions,
      route: routes.CONFIGURATION.REPORT_OPTIONS.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.REPORT)
    },
    {
      labelEntry: labels.graphic,
      image: reportGraphicOptions,
      route: routes.CONFIGURATION.REPORT_OPTIONS.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.GRAPHIC)
    },
    {
      labelEntry: labels.table,
      image: reportTableOptions,
      route: routes.CONFIGURATION.REPORT_OPTIONS.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.TABLE)
    }
  ];

  return (
    <Grid item sm={12} container>
      {array.map(({ labelEntry, image, route }) => (
        <Card variant="outlined" className={classes.root} key={labelEntry.title}>
          <CardMedia className={classes.media} image={image} title={labelEntry.title} component={Link} to={route} />
          <CardActionArea component={Link} to={route}>
            <CardContent>
              <Typography color="textPrimary" gutterBottom variant="h6">
                {labelEntry.title}
              </Typography>
              <Typography variant="body2" component="p">
                {labelEntry.descriptions}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Grid>
  );
};

export default ReportOptionChoice;
