import React, { Component } from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';

import AnamnesisElement from './AnamnesisElement';
import icons from '../../../common/anamnesis/icons/AnamnesisIcons';
import log from '../../../Logger';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class Number extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;

    log.info(props.element);
    return <AnamnesisElement {...props} editableElement={editableElement} readOnlyElement={readOnlyElement} />;
  }
}

function editableElement(element, handleChange) {
  const { label } = element;
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <TextField InputLabelProps={{ shrink: true }} value={label} fullWidth name="label" onChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{icons['icon-clock']}</InputAdornment>
          }}
        />
      </Grid>
    </Grid>
  );
}

function readOnlyElement(element) {
  const { label, placeholder } = element;
  return (
    <TextField
      disabled
      label={label}
      defaultValue={placeholder}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: <InputAdornment position="end">{icons['icon-clock']}</InputAdornment>
      }}
      style={{ marginBottom: '2rem' }}
    />
  );
}
