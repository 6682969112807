// @flow
import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import LoginPage from './LoginPage';
import appVersionSpecific from '../appVersionSpecific.ortho';
import 'moment/locale/fr';
import User from '../components/common/data/User';
import UserLogin from '../components/common/data/UserLogin';
import ReportGeneratorUtil from '../components/patient/report/ReportGeneratorUtil';
import { fetchJson } from '../components/common/OrthoscribeWebSite';
import server from '../constants/server';
import Loader from '../components/common/Loader';
import useWindowQuery from '../components/common/useWindowQuery';
import MyStore from '../actions/MyStore';
import { isUserLegit as  isUserLegitFn } from '../actions/LicenceUtil';
import buildInfo from "../buildInfo.json";

Moment.locale('fr');

type Props = {
  children: React.Node
};

const App = ({ children }: Props) => {
  const [user, setUser] = useState();
  const [aesKey, setAesKey] = useState();
  const [userLegit, setUserLegit] = useState(false);
  const [loadingDatas, setLoadingDatas] = useState(true);

  const lastLoginDate = MyStore.get(appVersionSpecific.electronStoreLastLoginDate);
  const webSiteHost = MyStore.get(appVersionSpecific.electronStoreWebSiteHost) || server.main.url;
  const query = useWindowQuery();

  useEffect(() => {
    const loadDatas = async () => {
      const { val: cryptedLicense } = await User.retrieveValueFromDb({ keyValue: 'license' });
      let license;
      if (cryptedLicense) {
        try {
          license = await ReportGeneratorUtil.decryptLicence(cryptedLicense);
        } catch (e) {
          console.log('could not reach server');
        }
      }
      const { isUserLegit, legitHappyNeuron, legitOrthoscribe, hnJsonContent } = await isUserLegitFn({license, query});
      console.log({ isUserLegit, legitHappyNeuron, legitOrthoscribe, hnJsonContent })
      const extraData = {
        platform: "web",
        version: buildInfo.version
      };
      if (isUserLegit && !legitOrthoscribe && legitHappyNeuron && hnJsonContent) {

        const { email, firstName, lastName, expiration } = hnJsonContent.therapist;
        const { payload, status } = await fetchJson(`${webSiteHost}/api/happyneuron/user`, {
          method: 'POST',
          body: JSON.stringify({ email, firstName, lastName, expiration, ...extraData, edition: 'happyscribeV2' }),
          headers: {
            'Content-Type': 'application/json',
            dataType: 'json'
          }
        });
        if (status === 'ko') {
          setUserLegit(false);
          return;
        }

        if (payload.shouldBeOrthoscribeLogin) {
          setUser('');
          setAesKey('');
          setUserLegit(false);
          setLoadingDatas(false);
          return;
        }

        // update du user
        await UserLogin.login(payload, email);
      } else {
        const { val: authToken } = await User.retrieveValueFromDb({ keyValue: 'authToken' });
        if (authToken) {
          try {
            const { status, payload } = await fetchJson(`${webSiteHost}/api/user/license/get`, {
              method: 'POST',
              body: JSON.stringify({ authToken, ...extraData, edition: 'orthoscribeV2' }),
              headers: {
                'Content-Type': 'application/json',
                dataType: 'json'
              }
            });
            console.log({ status, payload });
          } catch (e) {
            console.log('could not reach orthoscribe server, do nothing');
          }
        }
      }

      setUser(MyStore.get(appVersionSpecific.electronStoreUserKey));
      setAesKey(MyStore.get(appVersionSpecific.electronStoreAesKey));
      setUserLegit(isUserLegit);
      setLoadingDatas(false);
    };

    loadDatas().then();
  }, [query, webSiteHost]);

  if (loadingDatas) {
    return <Loader />;
  }

  if (!lastLoginDate || new Moment().diff(lastLoginDate, 'day') > 7) {
    if (lastLoginDate && lastLoginDate !== '') {
      UserLogin.logout();
    }
    return <LoginPage />;
  }

  if (!aesKey || aesKey === '' || !user || user === '' || !userLegit) {
    return <LoginPage />;
  }
  // TODO discomment this
  // executeDatabaseUpdates();

  return <React.Fragment>{children}</React.Fragment>;
};

export default App;
