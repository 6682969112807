import getFilteredPercentileTableByMinPercentile from "./GetFilteredPercentileTableByMinPercentile";

const resultPerPercentile = {
  95: { percentileValue: 95, label: '95' },
  90: { percentileValue: 90, label: '90' },
  75: { percentileValue: 75, label: 'Q3' },
  50: { percentileValue: 50, label: 'Med.' },
  25: { percentileValue: 25, label: 'Q1' },
  10: { percentileValue: 10, label: '10' },
  7: { percentileValue: 7, label: '7' },
  5: { percentileValue: 5, label: '5' },
};

const p5ResultAndMatchFunction = {matchFunction: (value, pctTable) => value > pctTable[0].v, result :{ percentileValue: 5, label: '5'}};

/*
• Pour un score de PAE, un sujet situé au centile N a une distance à la réponse attendue strictement
supérieure à la valeur du centile N, mais inférieure (ou égal) à la valeur du centile N-1.
 */

const percentileMatchFunction = (value, pctTable, index) => value > pctTable[index].v && value <= pctTable[index + 1].v

const calculateExamath58PAE = ({ value, pctTable }) => {
  if (pctTable.length !== 8) {
    return {};
  }

  const filteredPercentileTableByMaxPercentile = getFilteredPercentileTableByMinPercentile(pctTable);

  // test for percentile > 95
  if (filteredPercentileTableByMaxPercentile.find(({p}) => p === 5) && p5ResultAndMatchFunction.matchFunction(value, pctTable)) {
    return p5ResultAndMatchFunction.result;
  }

  filteredPercentileTableByMaxPercentile.sort(({p:a}, {p:b}) => b-a);
  const transformedFilteredPctTable = filteredPercentileTableByMaxPercentile.map(({p}, index) => ({matchFunction: percentileMatchFunction, result: resultPerPercentile[p], index}));

  const found = transformedFilteredPctTable.find(({ matchFunction, index }) => matchFunction(value, filteredPercentileTableByMaxPercentile, index));
  return found ? found.result : transformedFilteredPctTable[0].result;
};

export default calculateExamath58PAE;
