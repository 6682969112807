import React, { Component } from 'react';
import { FormControlLabel, Grid, IconButton, Paper, Switch, Tooltip, Typography } from '@material-ui/core';
import { Undo } from '@material-ui/icons';

import labels from '../../../constants/labels/configuration/anamnesis/Anamnesis.json';
import MyStateFullEditor from '../../common/MyStateFullEditor';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function,
  // eslint-disable-next-line flowtype/no-weak-types
  onBlur: Function,
  loginMessage?: string,
  disableAddressDisplay?: number,
  // eslint-disable-next-line flowtype/no-weak-types
  resetLoginMessage: Function
};

export default class ConfigurationEdition extends Component<Props> {
  props: Props;

  static defaultProps = {
    loginMessage: '',
    disableAddressDisplay: 0
  };

  handleCheckChange = event => {
    const { onChange, onBlur } = this.props;
    onChange({
      target: {
        name: 'disableAddressDisplay',
        value: event.target.checked ? 1 : 0
      }
    });
    onBlur();
  };

  render() {
    const { loginMessage, onChange, onBlur, disableAddressDisplay, resetLoginMessage } = this.props;
    return (
      <Grid item xs={12} container spacing={4}>
        <Grid item xs={3}>
          <Paper>
            <Typography variant="h5">{labels.tabs.config.left.title}</Typography>
            <Typography variant="body2">{labels.tabs.config.left.chapter1}</Typography>
            <Typography variant="body2">{labels.tabs.config.left.chapter2}</Typography>
            <Typography variant="body2">{labels.tabs.config.left.chapter3}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper>
            <Typography variant="h5">{labels.tabs.config.right.title}</Typography>
            <Tooltip title={labels.tabs.config.right.resetText}>
              <IconButton onClick={resetLoginMessage}>
                <Undo />
              </IconButton>
            </Tooltip>
            <MyStateFullEditor
              value={loginMessage}
              controlName="loginMessage"
              onChange={e => {
                onChange(e);
                onBlur(e);
              }}
            />
            <Typography variant="h5">{labels.tabs.config.right.subTitle}</Typography>
            <Typography variant="body2">{labels.tabs.config.right.subText}</Typography>
            <FormControlLabel
              control={<Switch checked={disableAddressDisplay === 1} onChange={this.handleCheckChange} />}
              label={labels.tabs.config.right.disableAddressDisplayLabel}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
