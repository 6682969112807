import React from 'react';

import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { WifiOff } from '@material-ui/icons';

import labels from '../../../constants/labels/common/reportOptions/ReportOptionsForm.json';
import TextFieldFormControl from '../../TextFieldFormControl';
import { fileNameFormats } from '../../../constants/report/fileNameFormats';
import appVersionSpecific from '../../../appVersionSpecific.ortho';
import OnLineOffLineSwitcher from '../OnLineOffLineSwitcher';
import ReportOptionsChart from '../../patient/report/ReportOptionsChart';
import tableChartFormat from '../../../constants/report/tableChartFormat.json';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold'
  },
  halfWidth: {
    width: '40%',
    margin: '6px auto',
    boxShadow: '1px 1px 3px 1px #CCCCCC'
  },
  height: {
    margin: '6px auto',
    height: '27px',
    width: 'fit-content'
  }
});

type Props = {
  options: {
    fileNameFormat: string,
    fileNameUseRenewalMarker: boolean,
    fileNameUseReportDate: boolean,
    displayChrono: boolean,
    header: {
      addPersonalCompany: boolean
    },
    domainComments: 'comment' | 'nocomment',
    addUserSelectedMemorizedTextsAsDefaultComment: boolean,
    disableCommentHighlight: boolean,
    renewalOptions: {
      commentType: 'both' | 'normal' | 'renewal'
    },
    tableOptions: {
      displayTestLabelBeforeSubtest: boolean,
      alwaysDisplayTestLabelBeforeSubtest: boolean
    },
    displayBars: boolean,
    horizBarSize: number | undefined,
    tableChartFormat: string,
    tableChartOptions: {
      renewalMark: 'nomark' | 'smallbar' | 'diamond' | 'triangle'
    }
  },
  reloadChart: boolean,
  change: (event: React.ChangeEvent<HTMLInputElement>) => void,
  switchChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  resetHorizBar: () => void,
  // eslint-disable-next-line flowtype/no-weak-types
  content: any
};

const ReportOptionsForm = ({ options, reloadChart, content, change, switchChange, resetHorizBar }: Props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={8}>
      <Grid item xs={6} container>
        <Grid item xs={12}>
          <Typography variant="h6">{labels.creationOptions.subtitle}</Typography>
        </Grid>
        <TextFieldFormControl
          itemMdValue={12}
          id="fileNameFormat"
          controlName="fileNameFormat"
          label={labels.creationOptions.defaultFileName}
          select
          fullWidth
          value={options.fileNameFormat}
          onChangeHandler={change}
        >
          {fileNameFormats.map(({ id, label }) => (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          ))}
        </TextFieldFormControl>
        {appVersionSpecific.reportOptions.reportCreation.showFileNameUseRenewalMarker && (
          <Grid xs={12} item>
            <FormControlLabel
              control={
                <Switch
                  checked={options.fileNameUseRenewalMarker}
                  onChange={switchChange('fileNameUseRenewalMarker')}
                />
              }
              label={labels.creationOptions.fileNAmeUseRenewalMarker}
            />
          </Grid>
        )}
        <Grid xs={12} item>
          <FormControlLabel
            control={
              <Switch checked={options.fileNameUseReportDate} onChange={switchChange('fileNameUseReportDate')} />
            }
            label={labels.creationOptions.fileNameUseReportDate}
          />
        </Grid>
        {appVersionSpecific.reportOptions.reportCreation.showGraphicsOptions && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6">{labels.header.subtitle}</Typography>
            </Grid>
            <Grid xs={12} item>
              <FormControlLabel
                control={
                  <Switch
                    checked={options.header.addPersonalCompany}
                    onChange={switchChange('header.addPersonalCompany')}
                  />
                }
                label={labels.header.addPersonalCompany}
              />
            </Grid>
          </React.Fragment>
        )}
        {appVersionSpecific.reportOptions.reportCreation.showGraphicsOptions && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6">{labels.graphicsOptions.subtitle}</Typography>
            </Grid>
            <Grid xs={12} item>
              <FormControlLabel
                control={<Switch checked={options.displayChrono} onChange={switchChange('displayChrono')} />}
                label={labels.graphicsOptions.displayChrono}
              />
            </Grid>
            <Grid xs={12} item>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.bold}>
                  {labels.graphicsOptions.quantitativeComments.label}
                </FormLabel>
                <RadioGroup name="domainComments" value={options.domainComments} onChange={change}>
                  <FormControlLabel
                    value="comment"
                    control={<Radio />}
                    label={labels.graphicsOptions.quantitativeComments.comment}
                  />
                  <FormControlLabel
                    value="nocomment"
                    control={<Radio />}
                    label={labels.graphicsOptions.quantitativeComments.noComment}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid xs={12} item>
              <FormControlLabel
                control={
                  <Switch
                    checked={options.addUserSelectedMemorizedTextsAsDefaultComment}
                    onChange={switchChange('addUserSelectedMemorizedTextsAsDefaultComment')}
                  />
                }
                label={labels.graphicsOptions.addUserSelectedMemorizedTextsAsDefaultComment}
              />
            </Grid>
          </React.Fragment>
        )}
        {appVersionSpecific.reportOptions.reportCreation.showAnamnesisOptions && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6">{labels.anamnesisOptions.label}</Typography>
            </Grid>
            <Grid xs={12} item>
              <Tooltip title={labels.anamnesisOptions.tooltip}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={options.disableCommentHighlight}
                      onChange={switchChange('disableCommentHighlight')}
                    />
                  }
                  label={labels.anamnesisOptions.disableCommentHighlight}
                />
              </Tooltip>
            </Grid>
          </React.Fragment>
        )}
        {appVersionSpecific.reportOptions.reportCreation.showRenewalCheckup && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6">{labels.renewalCheckup.subtitle}</Typography>
            </Grid>
            <Grid xs={12} item>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.bold}>
                  {labels.renewalCheckup.comparativeComments.label}
                </FormLabel>
                <RadioGroup
                  name="renewalOptions.commentType"
                  value={options.renewalOptions.commentType}
                  onChange={change}
                >
                  <FormControlLabel
                    value="both"
                    control={<Radio />}
                    label={labels.renewalCheckup.comparativeComments.both}
                    disabled={options.domainComments === 'nocomment'}
                  />
                  <FormControlLabel
                    value="normal"
                    control={<Radio />}
                    label={labels.renewalCheckup.comparativeComments.normal}
                    disabled={options.domainComments === 'nocomment'}
                  />
                  <FormControlLabel
                    value="renewal"
                    control={<Radio />}
                    label={labels.renewalCheckup.comparativeComments.renewal}
                    disabled={options.domainComments === 'nocomment'}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <Grid item xs={6} container>
        {appVersionSpecific.reportOptions.reportCreation.showDisplayTestLabelBeforeSubtest && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6">{labels.arrayOptions.subtitle}</Typography>
            </Grid>
            <Grid xs={12} item>
              <FormControlLabel
                control={
                  <Switch
                    checked={(options.tableOptions || {}).displayTestLabelBeforeSubtest}
                    onChange={switchChange('tableOptions.displayTestLabelBeforeSubtest')}
                  />
                }
                label={labels.arrayOptions.displayTestLabelBeforeSubtest}
              />
            </Grid>
            <Grid xs={12} item>
              <FormControlLabel
                control={
                  <Switch
                    checked={(options.tableOptions || {}).alwaysDisplayTestLabelBeforeSubtest}
                    onChange={switchChange('tableOptions.alwaysDisplayTestLabelBeforeSubtest')}
                  />
                }
                label={labels.arrayOptions.alwaysDisplayTestLabelBeforeSubtest}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12}>
          <Typography variant="h6">{labels.graphicBars.subtitle}</Typography>
          {options.displayBars && <Typography variant="body1">{labels.graphicBars.label}</Typography>}
        </Grid>
        {options.displayBars && (
          <OnLineOffLineSwitcher
            onLine={
              <Grid item xs={12}>
                <ReportOptionsChart
                  reloadChart={reloadChart}
                  content={content}
                  zscore={1.2}
                  initial={-0.8}
                  classes={classes}
                />
                <ReportOptionsChart
                  reloadChart={reloadChart}
                  content={content}
                  zscore={-1.8}
                  initial={-2.5}
                  classes={classes}
                />
                <ReportOptionsChart
                  reloadChart={reloadChart}
                  content={content}
                  zscore={-0.4}
                  initial={0.3}
                  classes={classes}
                />
                <ReportOptionsChart
                  reloadChart={reloadChart}
                  content={content}
                  zscore={+0.8}
                  initial={1.3}
                  classes={classes}
                />
              </Grid>
            }
            offLine={
              <Tooltip title={labels.graphicBars.offLine}>
                <WifiOff />
              </Tooltip>
            }
          />
        )}
        <Grid xs={12} item>
          <FormControlLabel
            control={<Switch checked={options.displayBars} onChange={switchChange('displayBars')} />}
            label={labels.graphicBars.displayBars}
          />
        </Grid>
        <Grid xs={12} item container spacing={4}>
          <TextFieldFormControl
            itemMdValue={6}
            id="horizBarSize"
            controlName="horizBarSize"
            inputType="number"
            value={options.horizBarSize}
            disabled={!options.displayBars}
            label={labels.graphicBars.horizBarSize}
            onChangeHandler={change}
            fullWidth
            inputProps={{
              step: '1',
              min: '1',
              max: '128',
              endAdornment: <InputAdornment position="end">{labels.graphicBars.horizBarSizeUnit}</InputAdornment>
            }}
          />
          <TextFieldFormControl
            itemMdValue={6}
            id="tableChartFormat"
            controlName="tableChartFormat"
            value={options.tableChartFormat}
            disabled={!options.displayBars}
            onChangeHandler={change}
            select
            fullWidth
            label={labels.graphicBars.horizBarSizeType}
          >
            {tableChartFormat.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </TextFieldFormControl>
        </Grid>
        {appVersionSpecific.reportOptions.reportCreation.showRenewalCheckup && (
          <Grid xs={12} item>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.bold}>
                {labels.graphicBars.renewalCheckup.label}
              </FormLabel>
              <RadioGroup
                name="tableChartOptions.renewalMark"
                value={options.tableChartOptions.renewalMark}
                onChange={change}
              >
                <FormControlLabel value="nomark" control={<Radio />} label={labels.graphicBars.renewalCheckup.nomark} />
                <FormControlLabel
                  value="smallbar"
                  control={<Radio />}
                  label={labels.graphicBars.renewalCheckup.smallbar}
                />
                <FormControlLabel
                  value="diamond"
                  control={<Radio />}
                  label={labels.graphicBars.renewalCheckup.diamond}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        <Grid xs={12} item>
          <Button onClick={resetHorizBar}>{labels.reinit}</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportOptionsForm;
