import React, { Fragment, useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore, SpeakerNotes } from '@material-ui/icons';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import TestFillingRow from './TestFillingRow';
import {
  filterCheckupModelTestEntryByItsAvailabilityOnCalculationTable,
  filterCheckupModelTestEntryByItsVisibility
} from './TestListingByModelUtils';
import { shouldHideRow } from './TestUtils';
import TestFillingStepper from '../TestFillingStepper';
import Comment from './Comment';
import {CHECKUP_MODEL_MEMO_PREFIX, getKeyFromTestCodeAndId} from "../../common/data/MemoUtil";

const useStyles = makeStyles({
  iconSmall: {
    fontSize: 20
  },
  noPadding: {
    padding: '0px',
    lineHeight: '4em',
    '&:hover': {
      backgroundColor: '#e8e8e8'
    }
  },
  greyBackgroundOnHover: {
    '&:hover': {
      backgroundColor: '#e8e8e8',
      cursor: 'pointer'
    }
  },
  comment: {
    color: '#48a091',
    lineHeight: '1em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      textDecoration: 'underline'
    },
    '& p': {
      marginTop: '0.25em',
      marginBottom: '0.25em'
    }
  }
});

type Props = {
  label: string,
  level: number,
  // eslint-disable-next-line flowtype/no-weak-types
  changeTestValueHandler: Function,
  tests: array,
  childs: array,
  // eslint-disable-next-line flowtype/no-weak-types
  testValuesByTestCode: any,
  // eslint-disable-next-line flowtype/no-weak-types
  initialTestValuesByTestCode: any,
  allExpandeds: { [string]: boolean },
  uuid: string,
  // eslint-disable-next-line flowtype/no-weak-types
  setModelElementExpanded: Function,
  hideNonFilledTests: boolean,
  lastname: string,
  firstname: string,
  options: | {
        gfxcolor1: string,
        gfxcolor2: string,
        gfxcolor3: string,
        gfxcolor4: string,
        gfxcolor5: string,
        gfxcolor6: string
      }
    | undefined,
  // eslint-disable-next-line flowtype/no-weak-types
  comments: any,
  updateComment: Function,
  isRenewal: boolean,
  userSelectedModelId: string | number | undefined
};

const TestListingByModelElement = ({
  changeTestValueHandler,
  label,
  level,
  tests,
  childs,
  uuid,
  testValuesByTestCode,
  initialTestValuesByTestCode,
  allExpandeds,
  // eslint-disable-next-line react/prop-types
  setModelElementExpanded,
  hideNonFilledTests,
  lastname,
  firstname,
  options,
  comments,
  updateComment,
  isRenewal,
  userSelectedModelId
}: Props) => {
  const style = useStyles();

  const filteredTests = tests
    .filter(({ testCode }) => Object.keys(testValuesByTestCode).includes(testCode))
    .filter(filterCheckupModelTestEntryByItsVisibility(testValuesByTestCode))
    .filter(filterCheckupModelTestEntryByItsAvailabilityOnCalculationTable(testValuesByTestCode))
    .filter(({ id, testCode }) =>
      shouldHideRow(hideNonFilledTests, testValuesByTestCode[testCode].currentTestResults)({ id })
    );

  const { [uuid]: expanded } = allExpandeds;
  const setExpanded = setModelElementExpanded(uuid);
  const [currentlyOpenedExaminationCommentEditorId, setCurrentlyOpenedExaminationCommentEditorId] = useState(null);
  const [showCommentEditor, setShowCommentEditor] = useState(false);

  return (
    <Grid item md={12} container>
      <Grid item md={12} container className={style.greyBackgroundOnHover}>
        <Grid item md={level + 1 || 1}>
          <IconButton onClick={() => setShowCommentEditor(!showCommentEditor)}>
            <SpeakerNotes color="action" className={classNames(style.iconSmall, style.noPadding)} />
          </IconButton>
        </Grid>
        {(filteredTests.length >= 1 || childs.length >= 1) && (
          <Fragment>
            <Grid item md={12 - (level + 1) || 1}>
              <Typography
                variant="h6"
                onClick={() => {
                  if (filteredTests.length >= 1) setExpanded(!expanded);
                }}
              >
                {label}
                {filteredTests.length >= 1 && <IconButton>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>}
              </Typography>
            </Grid>
            {comments[uuid] && (
              <Grid item xs={12} className={style.comment}>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: comments[uuid] }}
                />
              </Grid>
            )}
            {showCommentEditor && (
              <Comment
                value={comments[uuid]}
                onChange={e => updateComment(uuid, e.target.value)}
                closeFunction={() => setShowCommentEditor(false)}
                controlName=""
                title={label}
                forceTextUpdate
                memo={getKeyFromTestCodeAndId(`${CHECKUP_MODEL_MEMO_PREFIX}${userSelectedModelId}`, uuid)}
              />
            )}
          </Fragment>
        )}
      </Grid>
      {!expanded && filteredTests.length >= 1 && (
        <Fragment>
          {!!level && <Grid item md={level} />}
          <Grid item md={12 - level || 1}>
            <Button onClick={() => setExpanded(!expanded)} component={Grid}>
              <div>
                {Object.entries(
                  filteredTests
                    .map(({ testCode, id }) => {
                      const { label: examinationLabel = `${testCode}_${id}` } =
                        testValuesByTestCode[testCode].examinations
                          .filter(({ id: examinationId }) => examinationId === id)
                          .shift() || {};
                      return { examinationLabel, testLabel: testValuesByTestCode[testCode].currentTest.label };
                    })
                    .reduce((acc, { examinationLabel, testLabel }) => {
                      acc[testLabel] = [...(acc[testLabel] || []), examinationLabel];
                      return acc;
                    }, {})
                ).map(([testLabel, examinationLabels]) => (
                  <Typography key={testLabel} variant="subtitle1">
                    {`${testLabel} : ${examinationLabels.join(', ')}`}
                  </Typography>
                ))}
              </div>
            </Button>
          </Grid>
        </Fragment>
      )}
      {expanded &&
        filteredTests.map(({ testCode, id }, index, array) => {
          const { label: examinationLabel = `${testCode}_${id}`, input } =
            testValuesByTestCode[testCode].examinations
              .filter(({ id: examinationId }) => examinationId === id)
              .shift() || {};
          return (
            <Grid item md={12} container key={`${id}${testCode}`} style={{ flexBasis: '100%' }}>
              <Grid item md={1}>
                <Typography variant="subtitle1">{testValuesByTestCode[testCode].currentTest.label}</Typography>
              </Grid>
              <Grid item md={11}>
                <TestFillingRow
                  id={id}
                  oddRow={false}
                  testRowData={
                    testValuesByTestCode[testCode].calculationData
                      ? testValuesByTestCode[testCode].calculationData[id]
                      : null
                  }
                  currentTestResult={testValuesByTestCode[testCode].currentTestResults[id]}
                  initialTestResult={initialTestValuesByTestCode[testCode].currentTestResults[id]}
                  showCommentLabel={false}
                  columns={testValuesByTestCode[testCode].columns}
                  columnWidth={isRenewal ? 5 : 3}
                  handleChange={changeTestValueHandler(testCode)}
                  displayRawScore={!!testValuesByTestCode[testCode].currentTestResults.$$displayRawScore}
                  disableCentileColumn={testValuesByTestCode[testCode].disableCentileColumn}
                  displayCalculatedPercentile={testValuesByTestCode[testCode].displayCalculatedPercentile}
                  mouseScrollWheelScoreEdition={false}
                  label={examinationLabel}
                  input={input}
                  testCode={testCode}
                  lastname={lastname}
                  firstname={firstname}
                  options={options}
                  showCommentEditor={currentlyOpenedExaminationCommentEditorId === id}
                  setShowCommentEditor={setCurrentlyOpenedExaminationCommentEditorId}
                  isRenewal={isRenewal}
                  stepper={
                    <TestFillingStepper
                      array={array}
                      activeStep={currentlyOpenedExaminationCommentEditorId === id ? index : 0}
                      steps={array.length}
                      index={index}
                      setCurrentlyOpenedExaminationCommentEditorId={setCurrentlyOpenedExaminationCommentEditorId}
                    />
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      {childs.map(({ uuid: childUuid, ...element }) => (
        <TestListingByModelElement
          key={childUuid}
          testValuesByTestCode={testValuesByTestCode}
          initialTestValuesByTestCode={initialTestValuesByTestCode}
          changeTestValueHandler={changeTestValueHandler}
          setModelElementExpanded={setModelElementExpanded}
          allExpandeds={allExpandeds}
          uuid={childUuid}
          hideNonFilledTests={hideNonFilledTests}
          lastname={lastname}
          firstname={firstname}
          comments={comments}
          updateComment={updateComment}
          isRenewal={isRenewal}
          options={options}
          userSelectedModelId={userSelectedModelId}
          {...element}
        />
      ))}
    </Grid>
  );
};

export default TestListingByModelElement;
