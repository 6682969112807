export const inputIncludesErrorCentile = (input) => input.includes("ERR_CENTILE");

export const getErrorCentileValue = (value, percentileObject) => ({
  returnValue: {
    inErrCentile: true,
    score: value,
    errCentile: percentileObject.percentileValue || percentileObject.value
  },
  percentileObject
});
