import React, { Fragment } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import labels from '../../constants/labels/configuration/displayOptions/DisplayOptionsPage.json';
import DisplayOptions from '../../components/configuration/displayOptions/DisplayOptions';

const DisplayOptionsPage = () => (
  <Fragment>
    <Grid container style={{ overflow: 'hidden' }} spacing={2}>
      <Grid item md={12}>
        <Typography variant="h6">{labels.mainHeader}</Typography>
        <Typography variant="subtitle2">{labels.secondHeader}</Typography>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
    </Grid>
    <DisplayOptions />
  </Fragment>
);

export default DisplayOptionsPage;
