import {findZone, getZoneColor, getZones} from './Zone';
import {getNormalizedZScore} from "../../../../constants/tests/ScoreEvaluator";

const getZoneColorFromZScore = (options, zscore, pathoThresholdAsZscore, difficultyThresholdAsZscore) => {
  const normalizedZScore = getNormalizedZScore(zscore, pathoThresholdAsZscore, difficultyThresholdAsZscore);
  const zones = getZones(options);
  const zone = findZone(normalizedZScore, zones);
  console.log({ zscore, normalizedZScore, zones, zone, pathoThresholdAsZscore, difficultyThresholdAsZscore });
  return getZoneColor(zone, options);
};

export default getZoneColorFromZScore;
