import moment from 'moment';
import { getLocation, parseFile } from './exalangCommonImport';

// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
const executeImport = ({ data: xmlPassationDatas, passationDate, birthDate, classe }) => {
  const passationAgeAsMonth = moment(passationDate.substring(0, 10), 'DD/MM/YYYY').diff(
    moment(birthDate, 'DD/MM/YYYY'),
    'month'
  );
  const calculOption = Object.entries(OPTIONS)
    .filter(
      ([, { maxAgeInMonth, minAgeInMonth }]) =>
        passationAgeAsMonth >= minAgeInMonth && passationAgeAsMonth < maxAgeInMonth
    )
    .map(([key]) => key)
    .shift();
  console.log({ calculOption });

  const count = Object.entries(DATACONV)
    .map(([dataconvKey, eprAsObject]) => {
      const [exalangAnswer] = xmlPassationDatas.getElementsByTagName(dataconvKey);
      return Object.entries(eprAsObject)
        .filter(() => exalangAnswer.getAttribute('Effectue') === 'oui')
        .filter(([eprKey]) => exalangAnswer.getAttribute(eprKey));
    })
    .flatMap(x => x).length;

  const values = Object.entries(DATACONV)
    .map(([dataconvKey, eprAsObject]) => {
      const [exalangAnswer] = xmlPassationDatas.getElementsByTagName(dataconvKey);
      return Object.entries(eprAsObject)
        .filter(() => exalangAnswer.getAttribute('Effectue') === 'oui')
        .filter(([eprKey]) => exalangAnswer.getAttribute(eprKey))
        .map(([eprKey, eprCode]) => {
          const intValue = parseInt(exalangAnswer.getAttribute(eprKey), 10);
          if (
            dataconvKey === 'TestComplementdePhrase08' &&
            eprKey === 'ReponsePresenceMotcible' &&
            exalangAnswer.getAttribute('ReponseComprehensionSemantique')
          ) {
            return {
              [eprCode]: {
                score: intValue + parseInt(exalangAnswer.getAttribute('ReponseComprehensionSemantique'), 10)
              }
            };
          }
          return { [eprCode]: { score: intValue } };
        });
    })
    .flatMap(x => x)
    .reduce((acc, obj) => {
      const [key, value] = Object.entries(obj).shift();
      acc[key] = value;
      return acc;
    }, {});

  return { count, values, calculOption: calculOption || classe, calculOptionType: 'age' };
};

const DATACONV = {
  TestDenomination00: { ReponsePhonologie: 'DENOM_PHONO', ReponseLexique: 'DENOM_LEX' },
  TestTopologieExpression01: { ReponseTopologieExpression: 'TOPO_EXPR' },
  TestQuantificateurEtNombre02: { ReponseQuantificateurEtNombre: 'QUANT_NOMB' },
  TestDenoCouleur03: { ReponseDenoCouleur: 'DECOM_COUL' },
  TestDenoRapideCouleur04: { Erreurs: 'DENOM_RAP_ERR', TempsGlobal: 'DENOM_RAP_TPS' },
  TestTopologieComprehension05: { ReponseTopologieComprehension: 'TOPO_RECEP' },
  TestDesignationImages06: { ReponseDesignationImages: 'DESIG_IMG' },
  TestDesignationPartieduCorps07: { bonnesReponses: 'DESIG_PARCOR' },
  TestComplementdePhrase08: { ReponsePresenceMotcible: 'COMPL_PHRS' },
  TestProductiondePhrase09: { ReponseScore: 'PROD_PHRS' },
  TestRepetitiondePhrase10: { ReponseRepetitiondephrase: 'REPET_PHRS' },
  TestRecitEtQCM11: { ReponseRecitEtQCM: 'COMPR_RECITS' },
  TestMorphoSyntaxe12: { ReponseMorphosyntaxe: 'APT_MORPHSNTX' },
  TestLogatome13: { ReponseLogatome: 'REPET_LOGATO' },
  TestRime14: { ReponseRime: 'RIMES_VOCALS' },
  TestDiscrimination15: { ReponseDiscrimination: 'GNOSIES_AUDPHON' },
  TestEmpanAuditifChiffre16: { ReponseEmpan: 'EMPAN_CHIF' },
  TestEmpanDeMots17: { ReponseEmpan: 'EMPAN_MOTS' },
  TestBarrage18: { Reponsetemps: 'BARRAG_TPS', ReponseNombredeChien: 'BARRAG_SCO' },
  TestAttentionAuditive19: { ReponseScore: 'ATTEN_AUDI' },
  TestLotoSonore20: { ReponseScore: 'LOTO_SONO' },
  TestComplementImage21: { ReponseScore: 'COMPL_IMGS' },
  TestBonhommeaReconstituer22: { ReponseScore: 'SCHEM_CORPO' },
  TestDiscriminationVisuelle23: { ReponseScore: 'DISCRI_VISU' }
};

const utf8Parse = encoding => filePath => parseFile(filePath, encoding);

const OPTIONS = {
  '3.0': { minAgeInMonth: 36, maxAgeInMonth: 42 },
  '3.6': { minAgeInMonth: 42, maxAgeInMonth: 48 },
  '4.0': { minAgeInMonth: 48, maxAgeInMonth: 54 },
  '4.6': { minAgeInMonth: 54, maxAgeInMonth: 60 },
  '5.0': { minAgeInMonth: 60, maxAgeInMonth: 66 },
  '5.6': { minAgeInMonth: 66, maxAgeInMonth: 9999 }
};

const exalang36Import = encoding => ({ getLocation, parseFile: utf8Parse(encoding), executeImport });
export default exalang36Import;
