import { jsonDownload } from '../../../import/utils/HappyneuronFileDownloader';
import happyneuronExalangClassToOrthoscribeClasse from '../HappyneuronExalangClassToOrthoscribeClasse';
import DEFAULT_HN_CLASSES from '../DEFAULT_HN_CLASSES.json';

const parseFile = async ({filePath, userId, ticket, programId}) => {
  const etal = await jsonDownload({userId, ticket, programId});
  console.log("etal", {etal, filePath})
  const { code: classe } = happyneuronExalangClassToOrthoscribeClasse(DEFAULT_HN_CLASSES, etal.user.class) || {};

  const hnClasseObject = Object.values(etal.classes).filter(({ classId }) => classId === etal.user.class)[0];

  return etal.workouts.map(workout => ({
    firstname: etal.user.firstName,
    lastname: etal.user.lastName,
    birthDate: etal.user.birthDate,
    passationDate: workout.startDate,
    passationNumber: workout.id,
    data: { workout, scores: etal.scores, hnClasseObject },
    hnWorkoutId: workout.id,
    classe,
    text: `${etal.user.firstName} ${etal.user.lastName} (${workout.startDate})`
  }));
};

export default parseFile;
