const mtpStyle = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  mainList: {
    height: '60vh',
    overflow: 'auto',
    borderRadius: '8px',
    paddingTop: 0,
    '& > :nth-child(odd)': {
      backgroundColor: '#EEE'
    },
    '& > :nth-child(odd).Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  nested: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(4)
  }
});

export default mtpStyle;
