import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import routes from '../../constants/routes';
import ReportGeneration from '../../components/patient/report/ReportGeneration';
import ReportOptions from '../../components/patient/report/ReportOptions';
import ReportGraphics from '../../components/patient/report/ReportGraphics';
import ReportArrays from '../../components/patient/report/ReportArrays';

export default class ReportGenerationPage extends Component {
  render(): React.ReactNode {
    return (
      <Switch>
        <Route exact path={`${routes.PATIENT.MAIN}${routes.PATIENT.REPORT.GENERATE}`} component={ReportGeneration} />
        <Route exact path={`${routes.PATIENT.MAIN}${routes.PATIENT.REPORT.OPTIONS}`} component={ReportOptions} />
        <Route exact path={`${routes.PATIENT.MAIN}${routes.PATIENT.REPORT.GRAPHICS}`} component={ReportGraphics} />
        <Route exact path={`${routes.PATIENT.MAIN}${routes.PATIENT.REPORT.ARRAYS}`} component={ReportArrays} />
      </Switch>
    );
  }
}
