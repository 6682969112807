import exalang36Import from '../../components/patient/test/importers/exalang36Import';
import { prepareTestForDisplay } from './PrepareTestForDisplay';
import EXALANG_3_6 from './EXALANG 3-6.json';
import exalangImport from '../../components/patient/test/importers/exalangImport';
import EXALANG_5_8 from './EXALANG 5-8.json';
import EXALANG_8_11 from './EXALANG 8-11.json';
import EXALANG_11_15 from './EXALANG 11-15.json';
import exalangLyfacImport from '../../components/patient/test/importers/exalangLyfacImport';
import EXALANG_LYFAC from './EXALANG-LYFAC.json';
import examathImport from '../../components/patient/test/importers/examathImport';
import EXAMATH_8_15 from './EXAMATH-8-15.json';
import predilemImport from '../../components/patient/test/importers/predilemImport';
import PREDILEM from './PREDILEM.json';
import predilacImport from '../../components/patient/test/importers/predilacImport';
import PREDILAC from './PREDILAC.json';
import evadysImport from '../../components/patient/test/importers/evadysImport';
import EVADYS from './EVADYS.json';
import evalecImport from '../../components/patient/test/importers/evalecImport';
import EVALEC from './EVALEC.json';
import predimemPredifexImport from '../../components/patient/test/importers/predimemPredifexImport';
import PREDIMEM from './PREDIMEM.json';
import PREDIFEX from './PREDIFEX.json';
import examath58Import from '../../components/patient/test/importers/examath58Import';
import EXAMATH_5_8 from './EXAMATH-5-8.json';
import { allLabeledTests, testByTestCode } from './tests';
import PROTOCOLEMILIEHAPPYNEURON from './PROTOCOLEMILIEHAPPYNEURON.json';
import protocoleEmilieImport from '../../components/patient/test/importers/protocoleEmilieImport';
import ETAL from './ETAL.json';
import etalImport from '../../components/patient/test/importers/etalImport';

export const happyneuronTestByProgramId = {
  '64': { testCode: 'EXALANG-3-6', importer: exalang36Import(), test: prepareTestForDisplay(EXALANG_3_6) },
  '65': { testCode: 'EXALANG-5-8', importer: exalangImport(), test: prepareTestForDisplay(EXALANG_5_8) },
  '66': { testCode: 'EXALANG-8-11', importer: exalangImport(), test: prepareTestForDisplay(EXALANG_8_11) },
  '67': { testCode: 'EXALANG-11-15', importer: exalangImport(), test: prepareTestForDisplay(EXALANG_11_15) },
  '68': { testCode: 'EXALANG-LYFAC', importer: exalangLyfacImport(), test: prepareTestForDisplay(EXALANG_LYFAC) },
  '86': { testCode: 'PROTOCOLEMILIEHAPPYNEURON', importer: protocoleEmilieImport(), test: prepareTestForDisplay(PROTOCOLEMILIEHAPPYNEURON) },
  '89': { testCode: 'EXAMATH-8-15', importer: examathImport(), test: prepareTestForDisplay(EXAMATH_8_15) },
  '87': { testCode: 'PREDILEM', importer: predilemImport(), test: prepareTestForDisplay(PREDILEM) },
  '91': { testCode: 'PREDILAC', importer: predilacImport(), test: prepareTestForDisplay(PREDILAC) },
  '93': { testCode: 'EVADYS', importer: evadysImport(), test: prepareTestForDisplay(EVADYS) },
  '102': { testCode: 'EVALEC', importer: evalecImport(), test: prepareTestForDisplay(EVALEC) },
  '108': {
    testCode: 'PREDIMEM',
    importer: predimemPredifexImport({ allLabeledTests, testByTestCode }),
    test: prepareTestForDisplay(PREDIMEM)
  },
  '109': {
    testCode: 'PREDIFEX',
    importer: predimemPredifexImport({ allLabeledTests, testByTestCode }),
    test: prepareTestForDisplay(PREDIFEX)
  },
  '112': { testCode: 'EXAMATH-5-8', importer: examath58Import(), test: prepareTestForDisplay(EXAMATH_5_8) },
  '116': { testCode: 'ETAL', importer: etalImport(), test: prepareTestForDisplay(ETAL) },
  '999': { testCode: 'PREDILEM', importer: predilemImport(), test: prepareTestForDisplay(PREDILEM) }
};
export const testCodesWithImportInTestFilling = [
  'EXALANG-3-6',
  'EXALANG-5-8',
  'EXALANG-8-11',
  'EXALANG-11-15',
  'EXALANG-LYFAC',
  'EXAMATH-8-15'
];
