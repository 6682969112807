import React, { Fragment, useEffect, useState } from 'react';
import { Save } from '@material-ui/icons';
import _ from 'lodash';
import ReportGraphicForm from '../../common/reportOptions/ReportGraphicForm';
import graphicTypes from '../../../constants/report/graphicTypes.json';
import SaveCancelRow from '../../common/SaveCancelRow';
import routes from '../../../constants/routes';
import User from '../../common/data/User';
import exampleContent from '../../../constants/patient/exampleContent.json';
import defaultReportGenerationOptions from '../../../constants/user/defaultReportGenerationOptions';
import initColorResultEditors from "../../common/options/InitColorResultEditors";

const GraphicOptions = () => {
  const [reloadChart, setReloadChart] = useState(true);
  const [content, setContent] = useState({
    ...exampleContent,
    options: defaultReportGenerationOptions
  });
  const [gTypes] = useState(graphicTypes);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  useEffect(() => {
    const loadOptions = async () => {
      const { val: loadedOptions } = await User.getReportGenerationOptions();
      setContent({ ...content, options: loadedOptions });
    };

    loadOptions().then();
  }, [content]);

  const change = async event => {
    const newOptions = _.set(content.options, event.target.name, event.target.value);
    setContent({ ...content, options: newOptions });
    setReloadChart(!reloadChart);
  };

  const switchChange = name => async event => {
    await change({ target: { name, value: event.target.checked } });
  };

  const handleSubmit = async () => {
    await User.saveReportGenerationOptions(content.options);
  };

  const switchLegendTypeChange = name => async event => {
    await change({ target: { name, value: event.target.checked ? 'legacy' : 'internal' } });
  };

  const selectFont = () => {
    // TODO voir avec Rodrigo si font native ou pas.
  };

  const colorResultEditors = initColorResultEditors(content.options, false, false);

  const resetColors = async () => {
    const newOptions = {
      ...JSON.parse(JSON.stringify(content.options)),
      gfxcolor1: defaultReportGenerationOptions.gfxcolor1,
      gfxcolor2: defaultReportGenerationOptions.gfxcolor2,
      gfxcolor3: defaultReportGenerationOptions.gfxcolor3,
      gfxcolor4: defaultReportGenerationOptions.gfxcolor4,
      gfxcolor5: defaultReportGenerationOptions.gfxcolor5,
      gfxcolor6: defaultReportGenerationOptions.gfxcolor6,
      legendOptions: {
        ...JSON.parse(JSON.stringify(content.options.legendOptions)),
        aboveAverage: defaultReportGenerationOptions.legendOptions.aboveAverage,
        averageHigh: defaultReportGenerationOptions.legendOptions.averageHigh,
        averageLow: defaultReportGenerationOptions.legendOptions.averageLow,
        average: defaultReportGenerationOptions.legendOptions.average,
        belowAverage: defaultReportGenerationOptions.legendOptions.belowAverage,
        pathological: defaultReportGenerationOptions.legendOptions.pathological,
        srslypathological: defaultReportGenerationOptions.legendOptions.srslypathological
      }
    };
    setContent({ ...content, options: newOptions });
  };

  return (
    <Fragment>
      <SaveCancelRow
        cancelRoute={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.MAIN)}
        saveAction={handleSubmit}
        saveIcon={<Save />}
        saveTooltip="Enregistrer"
      />
      <ReportGraphicForm
        content={content}
        options={content.options}
        change={change}
        gTypes={gTypes}
        setShowAdvancedOptions={setShowAdvancedOptions}
        showAdvancedOptions={showAdvancedOptions}
        switchChange={switchChange}
        renewal={false}
        switchLegendTypeChange={switchLegendTypeChange}
        colorResultEditors={colorResultEditors}
        resetColors={resetColors}
        selectFont={selectFont}
      />
    </Fragment>
  );
};

export default GraphicOptions;
