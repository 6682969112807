import React, { Component } from 'react';
import { FormControl, FormControlLabel, FormLabel, Grid, Input, Radio, RadioGroup } from '@material-ui/core';
import labels from '../constants/labels/patient/edit/Contact.json';

type Props = {
  controlName: string,
  label: string,
  value: ?string,
  choices: Array,
  otherLabel?: ?string,
  itemMdValue?: number,
  // eslint-disable-next-line flowtype/no-weak-types
  onChangeHandler: Function
};

export default class RadioFormControl extends Component<Props> {
  props: Props;

  static defaultProps = {
    itemMdValue: 6,
    otherLabel: null
  };

  onChange(e) {
    const { controlName, onChangeHandler } = this.props;
    e.target.name = controlName;
    onChangeHandler(e);
  }

  onChangeFreetext(e) {
    const { controlName, onChangeHandler } = this.props;
    e.target.name = controlName;
    e.target.value = `freetext:${e.target.value}`;
    onChangeHandler(e);
  }

  render() {
    const { controlName, label, value, choices, otherLabel, itemMdValue } = this.props;
    const [_value, _freetext = ''] = (value || '').split(':');

    return (
      <Grid item xs={itemMdValue}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup label={label} name={controlName} value={_value} onChange={e => this.onChange(e)} row>
            {choices.map(({ value: radioValue, label: radioLabel }) => (
              <FormControlLabel key={radioValue} value={radioValue} control={<Radio />} label={radioLabel} />
            ))}
            {otherLabel ? <FormControlLabel value="freetext" control={<Radio />} label={otherLabel} /> : ''}
          </RadioGroup>
          {otherLabel && _value === 'freetext' ? (
            <Input
              value={_freetext}
              onChange={e => this.onChangeFreetext(e)}
              placeholder={labels.patientLink.precise}
            />
          ) : (
            ''
          )}
        </FormControl>
      </Grid>
    );
  }
}
