export const getPersonalizedQuestions = (definitions) => {
  const allRows = definitions.flatMap(({rows}) => rows);
  const allQuestions = allRows.flatMap(question => question);
  return allQuestions.filter(({custom}) => !!custom);
};

export const getCustomRadioOnly = (personalizedQuestions) => personalizedQuestions.filter(({type}) => type === 'custom_radios')

export const customRadioOptionsById = (customRadioOnlys) => customRadioOnlys.reduce((acc, {id, options}) => ({...acc, [id]: reduceCustomRadioOptions(options)}), {})

export const reduceCustomRadioOptions = (options) => options.reduce((acc, {id, label}) => ({...acc, [id]: label}), {});

const AnamnesisBackOfficeAnswerConverter = (input) => {
  if (!input) return [];
  const {definitions, values} = input;
  const allPersonalizedQuestions = getPersonalizedQuestions(definitions);
  const customRadioOnly = getCustomRadioOnly(allPersonalizedQuestions);
  const customRadioOptionsByIds = customRadioOptionsById(customRadioOnly);

  const mappedValues = Object.entries(values)
    .filter(([key]) => !!customRadioOptionsByIds[key])
    .filter(([key, value]) => key && value && value.value)
    .reduce((acc, [key, value]) =>
      ({...acc, [key]:  { value: customRadioOptionsByIds[key][value.value] }})
    , {});

  return {...input, values: {...values, ...mappedValues}};
};

export default AnamnesisBackOfficeAnswerConverter;
