import { getLocation } from './exalangCommonImport';
import normalizeImporterClasse from './NormalizeImporterClasse';
import { jsonDownload } from '../../import/utils/HappyneuronFileDownloader';

// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
const executeImport = ({ data, classe }) => {
  const passation = { ...data };
  const { exercices } = passation;
  const values = Object.entries(exercices)
    .filter(([, ex]) => Array.isArray(ex))
    .flatMap(([key, ex]) =>
      ex
        .map((value, index) => ({ index, value }))
        .filter(({ value }) => typeof value === 'number')
        .map(({ value, index }) => {
          const keyName = `EXAMATH815_${key}_${index}`;
          console.log('Setting ', keyName, 'with', value);
          return { [keyName]: value };
        })
    )
    .reduce((acc, obj) => {
      const [key, value] = Object.entries(obj).shift();
      acc[key] = { score: value };
      return acc;
    }, {});

  // Remove examath "total score" if any of its subtotal is not set
  const dependencies = {
    EXAMATH815_317_0: ['EXAMATH815_317_1', 'EXAMATH815_317_4', 'EXAMATH815_317_7']
  };
  Object.entries(dependencies).forEach(([key, deps]) => {
    const count = deps.filter(s => values[s]).filter(s => typeof values[s].score === 'number').length;
    if (count !== deps.length && values[key]) {
      values[key].score = undefined;
    }
  });
  console.log({ values });

  return { count: Object.entries(values).length, values, calculOptionType: 'classe', calculOption: classe };
};

const parseFile = async ({filePath, userId, ticket, programId}) => {
  const examath = await jsonDownload({userId, ticket, programId});

  return examath.resultats.passations.flatMap(passation => ({
    firstname: examath.resultats.prenom,
    lastname: examath.resultats.nom,
    birthDate: examath.resultats.datenaissance,
    classe: examath.resultats.classe,
    passationNumber: passation.numero,
    passationDate: passation.date,
    cotationCode: normalizeImporterClasse(examath.resultats.classe),
    hnWorkoutId: passation.passationNumero,
    data: passation
  }));
};

const ExamathImport = () => ({ getLocation, parseFile, executeImport });
export default ExamathImport;
