import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useScrollTrigger } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const ScrollTop = props => {
  // eslint-disable-next-line react/prop-types
  const { children, thisRef } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: thisRef,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = () => {
    // eslint-disable-next-line react/prop-types
    thisRef.scrollTo(0, 0);
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
};

export default ScrollTop;
