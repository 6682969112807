import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function PrependTextFieldIcon() {
  return (
    <SvgIcon viewBox="0 0 10240 10240">
      <path fill="#252525" d="M640 3200l8960 0 0 3840 -8960 0 0 -3840zm640 640l0 2560 7680 0 0 -2560 -7680 0z" />
      <path style={{ fill: '#252525' }} d="m 737.6271,3248.8135 2863.7287,0 0,3482.0339 -2863.7287,0 z" />
      <path
        style={{ fill: '#ffffff' }}
        d="m 2104.4068,4349.8304 c 484.6271,0 878.644,384.2881 878.644,856.9491 0,472.6611 -394.0169,856.9492 -878.644,856.9492 -484.6272,0 -878.6441,-384.2881 -878.6441,-856.9492 0,-472.661 394.0169,-856.9491 878.6441,-856.9491 z"
      />
    </SvgIcon>
  );
}
