import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import {useHistory} from "react-router";

import labels from '../../../constants/Labels.json';
import routes from '../../../constants/routes';
import TextFieldFormControl from '../../TextFieldFormControl';
import CheckUpModelStructure from './CheckUpModelStructure';
import CheckUpModelTestsAssignment from './CheckUpModelTestsAssignment';
import * as Tests from '../../../constants/tests/tests';
import SaveCancelRow from '../../common/SaveCancelRow';
import CustomTests from '../../common/data/CustomTests';
import CheckUpModel from '../../common/data/CheckUpModel';
import log from '../../Logger';
import User from '../../common/data/User';
import Loader from '../../common/Loader';

type Props = {
  id: string
};

function buildAssignmentList(elements) {
  const assignmentList = {};
  elements.forEach(modelElement => {
    if (modelElement.tests) {
      modelElement.tests.forEach(ta => {
        if (!assignmentList[ta.testCode]) {
          assignmentList[ta.testCode] = [];
        }
        assignmentList[ta.testCode].push({
          epr: ta.id,
          model: modelElement.uuid
        });
      });
    }
  });
  return assignmentList;
}

// eslint-disable-next-line react/prop-types
function Text({ handleChange, id = '', label, value }) {
  const [text, setText] = useState(value);
  return (
    <TextFieldFormControl
      id={id}
      controlName={id}
      label={label}
      value={text}
      onChangeHandler={e => {
        setText(e.target.value);
      }}
      onBlurHandler={handleChange}
      fullWidth
    />
  );
}

function CheckUpModelEdit({ id }: Props) {
  const history = useHistory();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [checkUpModel, setCheckUpModel] = useState({
    _id: uuidv4(),
    label: '',
    description: '',
    elements: [],
    modificationDate: new Date(),
    creationDate: new Date()
  });
  const [assignmentList, setAssignmentList] = useState({});
  const [testList, setTestList] = useState([]);
  const [fullyLoaded, setFullyLoaded] = useState(false);

  const loadCheckupModel = useCallback(async () => {
    if (id !== 'new') {
      setFullyLoaded(false);
      try {
        const mcheckUpModel = await CheckUpModel.get(id);
        await setCheckUpModel(mcheckUpModel);
      } catch (err) {
        log.error('Error retrieving checkUpModel data', err);
      }
    }
  }, [id]);

  const loadOtherDatas = useCallback(async () => {
    const personalTests = await CustomTests.findAll();
    const userSelectedTests = await User.retrieveValueFromDb({ keyValue: 'tests', defaultValue: [] });
    const userSelectedTestCodes = userSelectedTests.val.map(({ testCode }) => testCode);
    const tests = Tests.filteredLabeledTests.filter(({ testCode }) => userSelectedTestCodes.includes(testCode));
    const mtestList = [...tests, ...personalTests];
    const massignmentList = buildAssignmentList(checkUpModel.elements);
    log.info({ massignmentList });
    await setAssignmentList(massignmentList);
    await setTestList(mtestList.length === 0 ? Tests.filteredLabeledTests : mtestList);
    setFullyLoaded(true);
  }, [checkUpModel]);


  useEffect(() => {
    loadCheckupModel().then();
  }, [id, loadCheckupModel]);

  useEffect(() => {
    loadOtherDatas().then();
  }, [checkUpModel, loadOtherDatas]);


  const handleChange = event => {
    const newCheckUpModel = _.set(
      { ...JSON.parse(JSON.stringify(checkUpModel)) },
      event.target.name,
      event.target.value
    );
    setCheckUpModel(newCheckUpModel);
    log.info('checkUpModel', newCheckUpModel);
  };

  const handleElementChange = e => {
    setCheckUpModel({ ...checkUpModel, elements: e.target.value });
  };

  const handleAssignmentsChange = e => {
    setAssignmentList(e.target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const elements = reverseAssignmentList();
    await CheckUpModel.save({
      ...checkUpModel,
      elements,
      modificationDate: new Date()
    });
    await loadCheckupModel();
  };

  const reverseAssignmentList = () => {
    const byUuid = {};
    Object.entries(assignmentList).forEach(([key, value]) => {
      value.forEach(({ epr, model }) => {
        if (!byUuid[model]) byUuid[model] = [];
        byUuid[model].push({ id: epr, testCode: key });
      });
    });

    return checkUpModel.elements.map(element => ({ ...element, tests: byUuid[element.uuid] || [] }));
  };

  return (
    <Grid item md={12} container>
      <Grid item md={12}>
        <Typography variant="h6">{labels.configuration.checkUpModels.mainHeader}</Typography>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={12} container>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
            <SaveCancelRow
              cancelRoute={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MODELS.MAIN)}
              saveAction={handleSubmit}
            />
            <Text
              handleChange={handleChange}
              id="label"
              label={labels.configuration.checkUpModels.label}
              value={checkUpModel.label}
              key={`label${checkUpModel.label}`}
            />
            <Text
              handleChange={handleChange}
              id="description"
              label={labels.configuration.checkUpModels.description}
              value={checkUpModel.description}
              key={`description${checkUpModel.description}`}
            />
          </Grid>
          {!fullyLoaded && <Loader />}
          {fullyLoaded && (
            <Fragment>
              <Grid item xs={12}>
                <Tabs
                  value={selectedTabIndex}
                  onChange={(a, newIndex) => {
                    if (newIndex === 2) {
                      history.push({ pathname: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MEMORIZED_TEXTS), state: { selectedTestCode: checkUpModel } });
                      return;
                    }
                    setSelectedTabIndex(newIndex);
                  }}
                  fullWidth
                >
                  <Tab label={labels.configuration.checkUpModels.checkUpStructure} />
                  <Tab label={labels.configuration.checkUpModels.testAssignment} />
                  <Tab label={labels.configuration.checkUpModels.memorizedTexts} />
                </Tabs>
              </Grid>
              <DndProvider backend={HTML5Backend}>
                {selectedTabIndex === 0 && (
                  <Grid item xs={6} style={{ paddingTop: '2em' }}>
                    <CheckUpModelStructure
                      modelElements={checkUpModel.elements}
                      controlName="elements"
                      onChange={handleElementChange}
                    />
                  </Grid>
                )}
                {selectedTabIndex === 1 && (
                  <Grid item xs={12}>
                    <CheckUpModelTestsAssignment
                      controlName="assignments"
                      assignmentList={assignmentList}
                      elements={checkUpModel.elements}
                      testList={testList}
                      onChange={handleAssignmentsChange}
                    />
                  </Grid>
                )}
              </DndProvider>
            </Fragment>
          )}
        </form>
      </Grid>
    </Grid>
  );
}

export default CheckUpModelEdit;
