import labels from "../../../../../constants/labels/patient/test/TestFillingHeader.json";
import { inputIncludesNS19 } from "../../testUtils/calculateValue/NS19Value";

const ns19 = ({ input = [], columns = {}, id, testResult = {}, fireTab }) => ({
  header: { condition: !!columns.NS19, label: labels.resultColumnTitles.ns19 },
  column: {
    displayCondition: inputIncludesNS19(input),
    replacementCondition: !!columns.NS19,
    id: "NS19",
    placeholder: "-",
    controlName: `${id}.ns`,
    value: testResult.ns,
    fullWidth: true,
    inputProps: { inputProps: { step: 1, min: 1, max: 19, onKeyPress: fireTab } }
  }
});

export default ns19;
