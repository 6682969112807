import React, { Fragment, useEffect, useState } from 'react';
import SaveCancelRow from '../../common/SaveCancelRow';
import routes from '../../../constants/routes';
import { Save, ZoomIn, ZoomOut } from '@material-ui/icons';
import { Divider, Grid, IconButton, MenuItem, Tooltip, Typography } from '@material-ui/core';
import labels from '../../../constants/labels/common/displayOptions/DisplayOptions.json';
import TextFieldFormControl from '../../TextFieldFormControl';
import MyStore from '../../../actions/MyStore';
import { makeStyles } from '@material-ui/core/styles';
// import User from '../../common/data/User';

const useStyles = makeStyles(theme => ({
  letterSpacingExample: {
    letterSpacing: ({ letterSpacing }) => `${letterSpacing}rem !important`
  }
}));


const DisplayOptions = () => {

  const [testFillingRowCompactDisplay, setTestFillingRowCompactDisplay] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [fontFamily, setFontFamily] = useState('Roboto');
  const [letterSpacing, setLetterSpacing] = useState(0);

  const classes = useStyles({letterSpacing});

  useEffect(() => {
    setPageSize(MyStore.get('pageSize', 5));
    setTestFillingRowCompactDisplay(MyStore.getAsBoolean('testFillingRowCompactDisplay', false));
    setFontFamily(MyStore.get('fontFamily', 'Roboto'));
    setLetterSpacing(MyStore.get('letterSpacing', 0));
  }, []);

  const handleSubmit = async () => {
    MyStore.set('pageSize', pageSize);
    MyStore.set('testFillingRowCompactDisplay', testFillingRowCompactDisplay);
    MyStore.set('fontFamily', fontFamily);
    MyStore.set('letterSpacing', letterSpacing);
    window.location.reload();
  };

  return (
    <Fragment>
      <SaveCancelRow
        cancelRoute={routes.CONFIGURATION.MAIN}
        saveAction={handleSubmit}
        saveIcon={<Save />}
        saveTooltip="Enregistrer"
      />
      <Grid container spacing={8}>
        <Grid item xs={6} container>
          <Grid item md={12} container direction='row' alignItems="center" justifyContent='space-between'>
            <Tooltip title={labels.letterSpacing.decrease}>
              <IconButton onClick={() => {if (letterSpacing> 0.1) setLetterSpacing(Number(letterSpacing)-0.1)}}>
                <ZoomOut/>
              </IconButton>
            </Tooltip>
            <Typography className={classes.letterSpacingExample}>{labels.letterSpacing.title}</Typography>
            <Tooltip title={labels.letterSpacing.increase}>
              <IconButton onClick={() => {setLetterSpacing(Number(letterSpacing)+0.1)}}>
                <ZoomIn />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          <TextFieldFormControl
            itemMdValue={12}
            id="homePageListSize"
            controlName="homePageListSize"
            label={labels.homePageListSize}
            select
            fullWidth
            value={pageSize}
            onChangeHandler={(e) => {setPageSize(e.target.value);}}
          >
            <MenuItem key={5} value={5}>5</MenuItem>
            <MenuItem key={10} value={10}>10</MenuItem>
            <MenuItem key={20} value={20}>20</MenuItem>
          </TextFieldFormControl>
          <TextFieldFormControl
            itemMdValue={12}
            id="testFillingListDisplaySize"
            controlName="testFillingListDisplaySize"
            label={labels.testFillingListDisplaySize}
            select
            fullWidth
            value={testFillingRowCompactDisplay}
            onChangeHandler={(e) => {setTestFillingRowCompactDisplay(e.target.value);}}
          >
            <MenuItem key={5} value={true}>{labels.listDisplay.compact}</MenuItem>
            <MenuItem key={10} value={false}>{labels.listDisplay.airy}</MenuItem>
          </TextFieldFormControl>
          <TextFieldFormControl
            itemMdValue={12}
            id="fontFamily"
            controlName="fontFamily"
            label={labels.fontFamily}
            select
            fullWidth
            value={fontFamily}
            onChangeHandler={(e) => {setFontFamily(e.target.value);}}
          >
            <MenuItem key={'Roboto'} value={'Roboto'}>Roboto</MenuItem>
            <MenuItem key={'Verdana'} value={'Verdana'}>Verdana</MenuItem>
            <MenuItem key={'Arial'} value={'Arial'}>Arial</MenuItem>
          </TextFieldFormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DisplayOptions;
