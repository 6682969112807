import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditableLabel from '../../common/EditableLabel';
import TextFieldFormControl from '../../TextFieldFormControl';

const useStyles = makeStyles({
  greenItalic: {
    fontStyle: 'italic',
    color: 'green'
  }
});

// eslint-disable-next-line react/prop-types
const ColorResultEditor = ({
  change,
  label,
  editableLabelId,
  editableLabelPlaceHolder,
  editableLabel,
  color,
  colorId
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} container>
      <Grid item xs={9} container>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.greenItalic}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <EditableLabel
            change={change}
            id={editableLabelId}
            controlName={editableLabelId}
            value={editableLabel}
            placeholder={editableLabelPlaceHolder}
          />
        </Grid>
      </Grid>
      <TextFieldFormControl
        itemMdValue={3}
        id={colorId}
        controlName={colorId}
        fullWidth
        onChangeHandler={change}
        inputType="color"
        value={color}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default ColorResultEditor;
