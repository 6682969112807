import uuidv4 from 'uuid/v4';

const MEMO_PREFIX = `comm.quali.`;
const CHECKUP_MODEL_MEMO_PREFIX = `checkupModel`;

const getKeyFromTestCodeAndId = (testCode, id) => {
  if (!id) return `${MEMO_PREFIX}${testCode}`;
  return `${MEMO_PREFIX}${testCode}.${id}`;
};

const mergeAndDeduplicateMemos = (nodesFromFile, memoNodesParam) => {
  const memoNodes = Array.isArray(memoNodesParam) ? memoNodesParam : [];
  const memoNodesCategoryLabels = memoNodes.map(({ label }) => label);
  const fileNodesCategoryLabels = nodesFromFile.map(({ label }) => label);
  const commonNodes = nodesFromFile
    .filter(({ label }) => memoNodesCategoryLabels.includes(label))
    .filter(({ texts }) => (texts || []).length > 0)
    .map(({ label, texts, ...nodeProps }) => {
      const [memoNodesWithSameLabel] = memoNodes.filter(({ label: memoLabel }) => memoLabel === label);
      return { label, texts: deduplicateTexts(memoNodesWithSameLabel.texts, texts), ...nodeProps };
    });
  const nodeFromFileOnly = nodesFromFile.filter(({ label }) => !memoNodesCategoryLabels.includes(label));
  const nodeFromMemoOnly = memoNodes.filter(({ label }) => !fileNodesCategoryLabels.includes(label));
  // console.log({ memoNodesParam, nodesFromFile, array: [...commonNodes, ...nodeFromMemoOnly, ...nodeFromFileOnly] });
  return [...commonNodes, ...nodeFromMemoOnly, ...nodeFromFileOnly];
};

const reduceData = datas => datas.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {});
const deduplicateTexts = (lhsTexts = [], rhsTexts = []) =>
  [...lhsTexts, ...rhsTexts]
    .filter(({ text }, index, array) => array.findIndex(fi => fi.text === text) === index)
    .map(({ uuid, ...rest }, index, array) => {
      if (array.findIndex(fi => fi.uuid === uuid) === index) {
        return { ...rest, uuid: uuidv4() };
      }
      return { ...rest, uuid };
    });

const cleanNodesDuplicateIds = memo => {
  const nodeIds = [];

  const updatedNodes = memo.val.nodes.map(({ uuid, texts, ...node }) => {
    nodeIds.push(uuid);
    const deduplicatedUuid = nodeIds.includes(uuid) ? uuidv4() : uuid;
    if (!nodeIds.includes(deduplicatedUuid)) {
      nodeIds.push(deduplicatedUuid);
    }
    const deduplicatedTexts = deduplicateTexts(texts).filter(({ text }) => !!text);
    return { ...node, uuid: deduplicatedUuid, texts: deduplicatedTexts };
  });
  return { ...memo, val: { ...memo.val, nodes: updatedNodes } };
};

export {
  mergeAndDeduplicateMemos,
  reduceData,
  deduplicateTexts,
  getKeyFromTestCodeAndId,
  cleanNodesDuplicateIds,
  MEMO_PREFIX,
  CHECKUP_MODEL_MEMO_PREFIX
};
