import React, { Fragment } from 'react';
import { ClickAwayListener, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Bookmark } from '@material-ui/icons';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Patient from '../common/data/Patient';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  _id: any,
  refreshList: void => void,
  rowColor: string
};

const colorPalette = {
  white: {
    bg: '#FFFFFF',
    bgh: 'rgba(255,255,255,0.5)'
  },
  green: {
    bg: '#E3FBE9',
    bgh: '#D6F8DE'
  },
  red: {
    bg: '#FFECEC',
    bgh: '#FFDFDF'
  },
  blue: {
    bg: '#ECF4FF',
    bgh: '#DBEBFF'
  },
  yellow: {
    bg: '#F7F9D0',
    bgh: '#F5F7C4'
  },
  purple: {
    bg: '#E1BEE7',
    bgh: '#DFBCE5'
  },
  teal: {
    bg: '#E0F2F1',
    bgh: '#cdF0ee'
  }
};

const CheckupColorpicker = ({ _id, refreshList, rowColor }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleColorChange = async (event, newColor) => {
    await Patient.setColor(_id, newColor);
    refreshList();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Fragment>
        <IconButton onClick={handleClick}>
          <Bookmark />
        </IconButton>
        <Menu id="colorPickerMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleClose}>
            <ToggleButtonGroup value={rowColor} exclusive onChange={handleColorChange} aria-label="couleur du bilan">
              {Object.entries(colorPalette).map(([key, value]) => (
                <ToggleButton value={key} key={key} aria-label={value.label} style={{ backgroundColor: value.bg }}>
                  <Fragment />
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </MenuItem>
        </Menu>
      </Fragment>
    </ClickAwayListener>
  );
};

export { colorPalette };
export default CheckupColorpicker;
