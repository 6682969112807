import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import labels from '../../../constants/labels/patient/anamnesis/AnamnesisModelSelection.json';
import TextFieldFormControl from '../../TextFieldFormControl';
import AnamnesisModel from '../../common/data/AnamnesisModel';
import Patient from '../../common/data/Patient';
import HappyNeuronDisabledComponent, { useApplicationVersion } from '../../common/useApplicationVersion';

type Props = {
  patientId: string,
  componentHasChangedPatient?: () => {}
};

const AnamnesisSelectionDropDown = ({ patientId, componentHasChangedPatient }: Props) => {
  const [anamnesisModels, setAnamnesisModels] = useState([]);
  const [patient, setPatient] = useState({
    anamnese: {
      modelId: 0
    }
  });

  const updateModelList = useCallback(async () => {
    const ams = await AnamnesisModel.getAll();
    setAnamnesisModels(ams);
  }, []);

  const updatePatient = useCallback(async () => {
    const p = await Patient.retrievePatient(patientId);
    setPatient(p);
  }, [patientId]);

  const updateModelListAndPatient = useCallback(async () => {
    await updateModelList();
    await updatePatient();
  }, [updateModelList, updatePatient]);

  useEffect(() => {
    updateModelListAndPatient().then();
  }, [patientId, updateModelListAndPatient]);


  const onChange = async event => {
    const modelId = event.target.value;
    const model = await AnamnesisModel.get(modelId);
    await Patient.updatePatient({
      ...patient,
      anamnese: {
        ...patient.anamnese,
        modelId: event.target.value,
        name: model.name,
        definitions: model.definitions
      }
    });
    await updatePatient();
    if (componentHasChangedPatient) {
      componentHasChangedPatient();
    }
  };

  const { isHappyNeuron } = useApplicationVersion();

  return <HappyNeuronDisabledComponent>
    <TextFieldFormControl
      itemMdValue={12}
      id="anamnesis.modelId"
      controlName="anamnesis.modelId"
      select
      fullWidth
      value={patient.anamnese.modelId || 0}
      onChangeHandler={onChange}
      disabled={isHappyNeuron}
    >
      <MenuItem value={0}>{labels.defaultModel}</MenuItem>
      {anamnesisModels.map(({ _id, name }) => <MenuItem key={_id} value={_id}>
          {name}
        </MenuItem>)}
    </TextFieldFormControl>
  </HappyNeuronDisabledComponent>;
};

AnamnesisSelectionDropDown.defaultProps = {
  componentHasChangedPatient: () => {}
};

export default AnamnesisSelectionDropDown;
