import React, { Component } from 'react';
import {
  Grid,
  TextField,
  FormLabel,
  FormControl,
  Typography,
  Select
} from '@material-ui/core';

import AnamnesisElement from './AnamnesisElement';
import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class Fratrie extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    return (
      <AnamnesisElement
        {...props}
        editableElement={editableElement}
        readOnlyElement={readOnlyElement}
      />
    );
  }
}

function editableElement(element, handleChange) {
  const { label } = element;
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <TextField
          value={label}
          name="label"
          fullWidth
          onChange={handleChange}
          style={{ paddingBottom: '2rem' }}
          InputLabelProps={{ shrink: true }}
        />
        <FormControl>
          <Select native disabled>
            <option value="">
              {labels.tabs.form.right.siblings.noSiblings}
            </option>
          </Select>
        </FormControl>
        <Typography variant="body2" gutterBottom>
          {labels.tabs.form.right.siblings.notaBene}
        </Typography>
      </Grid>
    </Grid>
  );
}

function readOnlyElement(element) {
  const { label } = element;
  return (
    <div>
      <FormLabel component="legend">{label}</FormLabel>
      <FormControl>
        <Select native disabled>
          <option value="">{labels.tabs.form.right.siblings.noSiblings}</option>
        </Select>
      </FormControl>
      <Typography variant="body2" gutterBottom>
        {labels.tabs.form.right.siblings.notaBene}
      </Typography>
    </div>
  );
}
