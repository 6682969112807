import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import labels from '../../../constants/labels/patient/comments/CheckupComments.json';
import CustomTests from '../../common/data/CustomTests';
import * as Tests from '../../../constants/tests/tests';
import routes from '../../../constants/routes';
import BehaviourComments from './BehaviourComments';
import ReportComments from './ReportComments';
import defaultOptions from '../report/ReportGraphicsDefaultOptions';
import ReportGraphicsChart from '../report/ReportGraphicsChart';
import ReportAnamnesis from '../report/ReportAnamnesis';
import { getHeaderAgeAndClassText } from '../../common/Header';
import PatientContentUtil from '../PatientContentUtil';
import Patient from "../../common/data/Patient";

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold'
  }
});

type Props = {
  match: ?{ params: ?{ patientId: ?string } }
};

const CheckupComments = ({
  match: {
    params: { patientId, bilanId }
  }
}: Props) => {
  const classes = useStyles();

  const [patient, setPatient] = useState({});
  const [cotation, setCotation] = useState({});
  const [comments, setComments] = useState({
    behaviours: [],
    summary: { text: '' },
    diagnosis: { text: '' },
    patientCare: { text: '' },
    therapeuticPlan: { text: '' }
  });
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [testLabelByTestCode, setTestLabelByTestCode] = useState({});
  const [content, setContent] = useState({
    bilan: [],
    patient: {},
    options: defaultOptions
  });
  const [reloadChart, setReloadChart] = useState(true);

  useEffect(() => {
    const init = async () => {
      const contentFromDb = await PatientContentUtil.getContent(patientId);

      const { patient: patientFromDb, cotation: cotationFromDb, reportComments: commentsFromDb, bilan } = contentFromDb;

      const customTestsFromDb = await CustomTests.findAll();
      const selectedTestCodes = (bilan.tests || []).map(test => test.testCode);
      const allTests = Tests.allLabeledTests
        .concat(customTestsFromDb)
        .filter(test => selectedTestCodes.includes(test.testCode));

      const testLabelByTestCodeInit = allTests.reduce((acc, { testCode, label }) => {
        acc[testCode] = label;
        return acc;
      }, {});

      allTests.forEach(({ testCode }) => {
        if (!commentsFromDb.behaviours.some(({ text }) => text === testCode)) {
          commentsFromDb.behaviours.push({ isTestReference: true, text: testCode });
        }
      });

      await setContent(contentFromDb);
      await setPatient(patientFromDb);
      await setCotation(cotationFromDb);
      await setComments(commentsFromDb);
      await setTestLabelByTestCode(testLabelByTestCodeInit);
      setReloadChart(!reloadChart);
    };

    init().then();
  }, [patientId, bilanId, reloadChart]);


  const updateAnamnesisText = async anamnesisText => {
    const updatedPatient = await Patient.updatePatient({ ...patient, anamnesisText });
    await setPatient(updatedPatient);
  };

  const ageAndClassText = getHeaderAgeAndClassText(patient.curclasse, patient.birthDay);

  return (
    <div>
      <Typography variant="h6">
        {labels.subTitle.part0}{' '}
        <span className={classes.bold}>
          {patient.firstName} {patient.lastName}
        </span>{' '}
        ({ageAndClassText})
      </Typography>
      <Divider />
      <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly">
        <Grid item xs={11}>
          <Tabs value={currentTabIndex} onChange={(event, newValue) => setCurrentTabIndex(newValue)}>
            <Tab label={labels.tabs.titles['0']} />
            <Tab label={labels.tabs.titles['1']} />
            <Tab label={labels.tabs.titles['2']} />
          </Tabs>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={routes.PATIENT.getForPath({
              path: routes.PATIENT.TESTS.TESTS,
              patientId
            })}
          >
            {labels.return}
          </Button>
        </Grid>
        {currentTabIndex === 1 && (
          <Grid item xs={12}>
            <ReportGraphicsChart content={content} reloadChart={reloadChart} />
          </Grid>
        )}
        {currentTabIndex === 2 && (
          <Grid item xs={12}>
            <ReportAnamnesis content={content} updateAnamnesisText={updateAnamnesisText}/>
          </Grid>
        )}
        <Grid item xs={12} container>
          <BehaviourComments
            comments={comments}
            patientId={patientId}
            bilanId={bilanId}
            cotation={cotation}
            testLabelByTestCode={testLabelByTestCode}
            updateComments={newComments => setComments(newComments)}
            updateCotation={newCotation => setCotation(newCotation)}
            patient={patient}
            updatePatient={newPatient => setPatient(newPatient)}
          />
        </Grid>
        <ReportComments
          comments={comments}
          updateCommentsFunction={setComments}
          patient={patient}
          updatePatientFunction={setPatient}
        />
      </Grid>
    </div>
  );
};

export default CheckupComments;
