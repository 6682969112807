import React, { useEffect, useState } from 'react';

import ReportGeneratorUtil from './ReportGeneratorUtil';
import log from '../../Logger';
import Loader from '../../common/Loader';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  content: any,
  classes: any,
  reloadChart: boolean
};

export default function ReportArrayChart1(props: Props) {
  const { content, reloadChart, classes } = props;
  const [chart, setChart] = useState(null);


  useEffect(() => {
      async function fetchChart() {
        setChart(null);
        const myChart = await ReportGeneratorUtil.generateChart1(content);
        log.info({ charts: myChart, reloadChart });
        setChart(myChart);
      }

      fetchChart().then();
    },
    [content, reloadChart]
  );

  if (!chart) {
    return (
      <td className={classes.tableRow}>
        <Loader />
      </td>
    );
  }

  return <td className={classes.tableRow} dangerouslySetInnerHTML={{ __html: chart }} />;
}
