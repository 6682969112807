import exalangs from '../../../../constants/tests/import/exalang.json';
import normalizeImporterClasse from './NormalizeImporterClasse';

// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
const executeImport = ({ data: node, testCode, classe }) => {
  const exadata = exalangs[testCode];
  const calculOption = normalizeImporterClasse(classe);

  let count = 0;
  const values = {};

  for (let i = 0; i < exadata.length; i += 1) {
    const exalangAnswer = node.getElementsByTagName('Test')[i];
    if (exalangAnswer.getAttribute('Effectue') === 'oui') {
      const reponse = exalangAnswer.getAttribute('Reponse');
      const split = reponse.split(';');
      let splitIndex = 0;
      let levelAdjust;
      let levelSuffix = '';
      for (let codeIndex = 0; codeIndex < exadata[i].codes.length; codeIndex += 1) {
        const eprCode = exadata[i].codes[codeIndex];
        if (eprCode === '$$level') {
          levelAdjust = true;
          levelSuffix = split[codeIndex];
        }
      }
      for (let codeIndex = 0; codeIndex < exadata[i].codes.length; ) {
        let ratioObject;
        const eprCode = exadata[i].codes[codeIndex];
        if (exadata[i].ratio) {
          ratioObject = exadata[i].ratio[eprCode];
        }
        if (ratioObject) {
          const { score: valScore = 0 } = values[ratioObject.score] || { score: 0 };
          const { score: valTemps = 0 } = values[ratioObject.temps] || { score: 0 };
          if (valTemps > 0) {
            const ratioval = parseFloat(((valScore / valTemps) * 100).toFixed(2));
            values[eprCode] = Object.assign(values[eprCode] || {}, { score: ratioval });
          }
          codeIndex += 1;
        } else if (splitIndex < split.length) {
          if (!values[eprCode]) {
            values[eprCode] = {};
          }
          if (levelAdjust) {
            console.log('setting level for ', eprCode, levelSuffix);
            values[eprCode].levelAdjuster = levelSuffix;
            console.log('Existing value', values[eprCode].comment);
            if (!values[eprCode].comment || /^(<html>)?Niveau .(<\/html>)?$/g.test(values[eprCode].comment)) {
              values[eprCode].comment = `Niveau ${levelSuffix}`;
            }
          } else {
            delete values[eprCode].levelAdjuster;
          }
          values[eprCode].score = parseFloat(split[splitIndex]);
          count += 1;
          if (!eprCode || !eprCode.substr || eprCode.substr(0, 2) !== '$$') {
            console.log('IMPORT WARNING: eprCode inconnu, ', eprCode);
          }
          splitIndex += 1;
          codeIndex += 1;
        } else {
          codeIndex += 1; // On incrémente codeIndex, mais sans rien faire, car il n'y plus de données dispo. On continue, car il est possible qu'il y ait un ratio calculable à la suite
        }
      }
    }
  }
  return { count, values, calculOption, calculOptionType: 'classe' };
};

export default executeImport;
