import React from 'react';
import { Wifi, WifiOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Box, CircularProgress, Tooltip } from '@material-ui/core';
import parse from 'json-templates';

import labels from '../constants/labels/IsOnlineIcon.json';
import useNavigatorOnLine from './common/UseNavigatorOnLine';

const useStyles = makeStyles(() => ({
  accountDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto'
  }
}));

const IsOnlineIcon = ({
  downloadProgress,
  newVersionDownloadAvailable
}: {
  downloadProgress: number | undefined,
  newVersionDownloadAvailable: boolean
}) => {
  const classes = useStyles();
  const isOnline = useNavigatorOnLine();

  if (isOnline && (downloadProgress || newVersionDownloadAvailable)) {
    const tooltipLabel = downloadProgress
      ? parse(labels.onlineTooltipAndDownload)({ downloadProgress })
      : labels.onlineTooltipAndDownloadAvailable;
    return (
      <div className={classes.accountDiv}>
        <Tooltip title={tooltipLabel} placement="left">
          <Box position="relative" display="inline-flex">
            {downloadProgress && <CircularProgress variant="determinate" value={downloadProgress} />}
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Badge
                color="error"
                badgeContent=""
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                invisible={!newVersionDownloadAvailable}
              >
                <Wifi />
              </Badge>
            </Box>
          </Box>
        </Tooltip>
      </div>
    );
  }
  if (isOnline) {
    return (
      <div className={classes.accountDiv}>
        <Tooltip title={labels.onlineTooltip} placement="left">
          <Wifi />
        </Tooltip>
      </div>
    );
  }
  return (
    <div className={classes.accountDiv}>
      <Tooltip placement="left" title={labels.offlineTooltip}>
        <WifiOff />
      </Tooltip>
    </div>
  );
};

export default IsOnlineIcon;
