import React, { Fragment } from 'react';
import labels from '../../constants/labels/patient/CheckupType.json';

type Props = {
  bilan:
    | {
        renewalNumber: number
      }
    | undefined
    | void,
  // eslint-disable-next-line flowtype/no-weak-types
  children?: any
};

const CheckupType = ({ bilan: { renewalNumber = null } = {}, children }: Props) => (
  <Fragment>
    <span>{renewalNumber ? labels.renewal + renewalNumber : labels.initial} </span>
    {children}
  </Fragment>
);

CheckupType.defaultProps = {
  children: null
};

export default CheckupType;
