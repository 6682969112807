import React, { Component } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';

import labels from '../../../constants/labels/configuration/anamnesis/Anamnesis.json';
import TextFieldFormControl from '../../TextFieldFormControl';
import MyStateFullEditor from '../../common/MyStateFullEditor';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function,
  // eslint-disable-next-line flowtype/no-weak-types
  onBlur: Function,
  mailSubject?: string,
  mailBody?: string
};

export default class EmailEdition extends Component<Props> {
  props: Props;

  static defaultProps = {
    mailSubject: '',
    mailBody: ''
  };

  render() {
    const { onChange, onBlur, mailSubject, mailBody } = this.props;
    return (
      <Grid item xs={12} container spacing={4}>
        <Grid item xs={4}>
          <Paper>
            <Typography variant="h5">{labels.tabs.email.left.title}</Typography>
            <Typography variant="body2">{labels.tabs.email.left.chapter1}</Typography>
            <Typography variant="body2">{labels.tabs.email.left.chapter2}</Typography>
            <ul>
              <li>
                <Typography variant="body2">{labels.tabs.email.left.bullet1}</Typography>
              </li>
              <li>
                <Typography variant="body2">{labels.tabs.email.left.bullet2}</Typography>
              </li>
              <li>
                <Typography variant="body2">{labels.tabs.email.left.bullet3}</Typography>
              </li>
              <li>
                <Typography variant="body2">{labels.tabs.email.left.bullet4}</Typography>
              </li>
              <li>
                <Typography variant="body2">{labels.tabs.email.left.bullet5}</Typography>
              </li>
              <li>
                <Typography variant="body2">{labels.tabs.email.left.bullet6}</Typography>
              </li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper>
            <Typography variant="h5">{labels.tabs.email.right.title}</Typography>
            <TextFieldFormControl
              id="anamneseModel.mailSubject"
              controlName="mailSubject"
              fullWidth
              itemMdValue={12}
              value={mailSubject}
              label={labels.tabs.email.right.subject}
              onChangeHandler={onChange}
              onBlurHandler={onBlur}
            />
            <MyStateFullEditor
              value={mailBody}
              onChange={e => {
                onChange(e);
                onBlur(e);
              }}
              controlName="mailBody"
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
