import React, { Component } from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { Check } from '@material-ui/icons';

import TextFieldFormControl from '../../TextFieldFormControl';
import labels from '../../../constants/labels/configuration/anamnesis/Anamnesis.json';
import commonLabels from '../../../constants/Labels.json';
import log from '../../Logger';

type TabEditionDefinition = {
  titre: string,
  titreCourt: string,
  description: string
};

type Props = {
  definition?: TabEditionDefinition,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function
};

export default class TabEdition extends Component<Props> {
  props: Props;

  static defaultProps = {
    definition: { titre: '', titreCourt: '', description: '' }
  };

  state = {
    definition: {}
  };

  componentDidMount(): void {
    const { definition } = this.props;
    const { ...copie } = definition;
    this.setState({
      definition: copie
    });
  }

  handleValidate = () => {
    const { onChange } = this.props;
    const { definition } = this.state;
    onChange({ target: { name: 'definition', value: definition } });
  };

  handleChange = event => {
    let { definition } = this.state;
    definition = _.set(definition, event.target.name, event.target.value);
    this.setState({ definition });
  };

  render() {
    const { definition } = this.state;
    const { titre = '', titreCourt = '', description = '' } = definition || {};
    log.info('render TabEdition');
    return (
      <Grid container>
        <TextFieldFormControl
          id="titre"
          controlName="titre"
          label={labels.tabs.form.right.tabEdition.title}
          value={titre}
          onChangeHandler={this.handleChange}
          itemMdValue={12}
          fullWidth
        />
        <TextFieldFormControl
          id="titreCourt"
          controlName="titreCourt"
          label={labels.tabs.form.right.tabEdition.shortTitle}
          value={titreCourt}
          onChangeHandler={this.handleChange}
          itemMdValue={12}
          fullWidth
        />
        <TextFieldFormControl
          id="description"
          controlName="description"
          label={labels.tabs.form.right.tabEdition.description}
          helperText={labels.tabs.form.right.tabEdition.descriptionHelper}
          value={description}
          onChangeHandler={this.handleChange}
          itemMdValue={12}
          fullWidth
        />
        <Grid item xs={12}>
          <Tooltip title={commonLabels.saveButton}>
            <IconButton onClick={this.handleValidate}>
              <Check />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
}
