import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import SelectAllCheckBox from '../../common/SelectAllCheckBox';
import './TestList.css';
import labels from '../../../constants/Labels.json';

const useStyles = makeStyles({
  ul: {
    columns: 4,
    '-webkit-columns': 4,
    '-moz-columns': 4,
    '& li': {
      display: 'inline-flex'
    }
  }
});

type Props = {
  label: string,
  ageTarget: boolean,
  allTestsOfLabel: Array<{ testCode: string }>,
  tests: Array<{ testCode: string }>,
  handleToggle: () => void,
  addSelectedTests: (Array<{ testCode: string }>) => void,
  removeSelectedTests: (Array<{ testCode: string }>) => void
};

const TestsOfLabelList = ({
  label,
  allTestsOfLabel,
  tests,
  handleToggle,
  ageTarget,
  addSelectedTests,
  removeSelectedTests
}: Props) => {
  const classes = useStyles();
  const [selectAll, setSelectAll] = useState({ checked: false, indeterminate: false });

  useEffect(() => {
    const testCodeList = allTestsOfLabel.map(({ testCode }) => testCode);
    const selectedTestCodes = tests.map(({ testCode }) => testCode);

    const checked = testCodeList.every(testCode => selectedTestCodes.includes(testCode));
    const indeterminate = !checked && testCodeList.some(testCode => selectedTestCodes.includes(testCode));
    setSelectAll({ checked, indeterminate });
  }, [allTestsOfLabel, tests]);

  return (
    <Grid item md={12}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <SelectAllCheckBox
                selectAll={selectAll}
                addAllValues={async () => {
                  await addSelectedTests(allTestsOfLabel);
                }}
                removeAllValues={async () => {
                  await removeSelectedTests(allTestsOfLabel);
                }}
              />
            }
            label={<Typography variant="h6">{label}</Typography>}
          />
        </AccordionSummary>
        <AccordionDetails>
          <List dense className={classes.ul}>
            {allTestsOfLabel.map(
              ({ label: testLabel, classe: { min = '', max = '' } = {}, IHMLabel, testCode: curTestCode }) => (
                <ListItem
                  key={IHMLabel || testLabel}
                  className="listElement"
                  onClick={handleToggle(curTestCode)}
                >
                  <Checkbox
                    checked={tests.map(({ testCode }) => testCode).indexOf(curTestCode) !== -1}
                    tabIndex={-1}
                    disableRipple
                    className="smallCheckbox"
                  />
                  <ListItemText
                    className="listElement"
                    primary={IHMLabel || testLabel}
                    secondary={
                      ageTarget && min && max ? `${min} ${labels.configuration.tests.classesFromTo} ${max}` : ''
                    }
                  />
                </ListItem>
              )
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default TestsOfLabelList;
