import { Box, ListItem, ListItemText, Tooltip, Typography } from '@material-ui/core';
import AnamnesisResponses from '../../common/data/AnamnesisResponses';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ListStyle from '../../../constants/styles/ListStyle.json';
import ReportGeneratorUtil from '../report/ReportGeneratorUtil';
import { readAnamnesisFile } from './AnamnesisFileUtil';

const useStyles = makeStyles({ ...ListStyle });

export function AnamnesisResponse({
                                    setFilename,
                                    fullName,
                                    label,
                                    size
                                  }: {
  setFilename: ((string) => void) | any,
  fullName: string,
  label: any,
  size: any
}) {
  const classes = useStyles();

  const [preview, setPreview] = useState();

  useEffect(() => {
    const generatePreview = async () => {
      const content = await AnamnesisResponses.downloadFileFromServer(fullName);
      const { patientFromDb, unCryptedFileContent: anamnesis } = await readAnamnesisFile(content);
      const text = await ReportGeneratorUtil.generateAnamnesis({...patientFromDb, anamnesis});
      setPreview(text)
    };

    generatePreview();
  }, [fullName]);

  return <Box boxShadow={2} className={classes.listItemBox}>
    <ListItem
      button
      onClick={() => {
        setFilename(fullName);
      }}
      className={classes.orthoscribeList}
    >
      <Tooltip title={<Typography variant="body2" dangerouslySetInnerHTML={{ __html: preview}}/>}>
        <ListItemText
          primary={AnamnesisResponses.getLabelDateFromFileName(label)}
          secondary={`${size} octets`}
        />
      </Tooltip>
    </ListItem>
  </Box>;
}
