import React from 'react';
import { AccordionDetails, Chip, Divider, Grid, Typography } from '@material-ui/core';
import { useDrop } from 'react-dnd';

import '../../../../common/styles/dragAndDrop.css';

type Props = {
  uuid: string,
  level: number,
  label: string,
  displayableAssignmentsByModel: array
};

const AssignmentDropZone = (props: Props) => {
  const { uuid, level, label, displayableAssignmentsByModel } = props;

  const [{ isOver }, drop] = useDrop({
    accept: 'assignment',
    drop: () => ({ model: uuid }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div ref={drop} className={isOver ? "dropZone" : ''}>
      <AccordionDetails
        style={{
          paddingLeft: `${level * 2}em`,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0
        }}
      >
        <Typography variant={level === 1 ? 'subtitle2' : 'body1'}>
          {label}
          {level === 1 ? <Divider /> : ''}
        </Typography>

        {displayableAssignmentsByModel[uuid] ? (
          <Grid container>
            {displayableAssignmentsByModel[uuid].map(test => (
              <Chip label={test} key={test} style={{ overflow: 'hidden' }} />
            ))}
          </Grid>
        ) : (
          ''
        )}
      </AccordionDetails>
    </div>
  );
};

export default AssignmentDropZone;
