import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import { Grid, IconButton } from '@material-ui/core';
import { Delete, SwapVerticalCircle } from '@material-ui/icons';
import { useDrag, useDrop } from 'react-dnd';

import TextFieldFormControl from '../../../TextFieldFormControl';
import { SelectTestType } from './SelectTestType';
import labels from '../../../../constants/Labels.json';

import '../../../common/styles/dragAndDrop.css';
import scrolling from '../../../common/scrolling';

export type Test = {
  id: string,
  input: array<string>,
  label: string
};

type Props = {
  test: Test,
  index: number,
  // eslint-disable-next-line flowtype/no-weak-types
  moveTest: Function,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function,
  // eslint-disable-next-line flowtype/no-weak-types
  handleDelete: Function
};

const TestValue = ({ test, handleDelete, index, moveTest, onChange }: Props) => {
  const [_label, setLabel] = useState(test.label);

  const handleChange = e => {
    const newTest = JSON.parse(JSON.stringify(test));
    newTest.label = e.target.value;
    onChange(newTest, index);
  };

  const handleInputChange = e => {
    const newTest = JSON.parse(JSON.stringify(test));
    newTest.input = [e.target.value];
    onChange(newTest, index);
  };

  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: { index, type: 'test' },
    begin: () => {
      scrolling.addEventListenerForSidebar();
    },
    end: (item, monitor) => {
      scrolling.removeEventListenerForSidebar();
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        moveTest(item.index, dropResult.hoverIndex);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const [{ isOver }, drop] = useDrop({
    accept: 'test',
    drop: () => ({ hoverIndex: index }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  drag(drop(ref));
  return (
    <div ref={ref} key={test.id}>
      <Grid
        container
        alignItems="center"
        style={{ opacity: isDragging ? 0.8 : 1 }}
        className={classNames("borderDashed", { "dropZone": isOver })}
      >
        <Grid item xs={1}>
          <SwapVerticalCircle />
        </Grid>
        <TextFieldFormControl
          id={test.id}
          value={_label}
          controlName=""
          label=""
          onChangeHandler={e => {
            setLabel(e.target.value);
          }}
          onBlurHandler={handleChange}
          itemMdValue={7}
          margin="none"
          fullWidth
        />
        <Grid item xs={3}>
          <SelectTestType value={test.input[0]} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label={labels.configuration.tests.personalTestEditPage} onClick={() => handleDelete(index)}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default TestValue;
