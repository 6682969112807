import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function TitleIcon() {
  return (
    <SvgIcon viewBox="0 0 10240 10240">
      <path
        style={{ fill: '#252525' }}
        d="M7680 2560l1280 0c353,0 640,287 640,640l0 160 -640 0 0 0c0,-88 -72,-160 -160,-160l-960 0c-88,0 -160,72 -160,160 0,533 0,1067 0,1600 0,88 72,160 160,160l960 0c88,0 160,-72 160,-160l0 0 640 0 0 160c0,353 -287,640 -640,640l-1280 0c-352,0 -640,-287 -640,-640l0 -1920c0,-353 288,-640 640,-640z"
      />
      <path
        style={{ fill: '#252525' }}
        d="M5440 2560c530,0 960,430 960,960 0,246 -92,470 -245,640 153,170 245,394 245,640 0,530 -430,960 -960,960l-1440 0c-88,0 -160,-72 -160,-160l0 -2880c0,-88 72,-160 160,-160l1440 0zm-960 1920l0 640 960 0c176,0 320,-144 320,-320 0,-176 -144,-320 -320,-320l-960 0zm0 -1280l0 640 960 0c176,0 320,-144 320,-320 0,-176 -144,-320 -320,-320l-960 0z"
      />
      <path
        style={{ fill: '#252525' }}
        d="M1294 4481l1252 0c-40,-444 -164,-874 -347,-1280l-558 0c-183,406 -307,836 -347,1280zm1266 640l-1280 0 0 640 -640 0 0 -960c0,-726 192,-1415 515,-2062 56,-112 168,-176 285,-176l960 0c117,0 229,64 285,176 323,647 515,1336 515,2062l0 960 -640 0 0 -640z"
      />
      <path
        style={{ fill: '#252525' }}
        d="m 628.70638,6188.4745 9011.97112,0 0,1290.8474 -9010.84095,0 c -20.34305,-211.7796 -30.51458,-427.5932 -30.51458,-645.4237 0,-217.8305 10.17153,-433.644 29.38441,-645.4237 z"
      />
    </SvgIcon>
  );
}
