/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TestFillingRowTextField from './TestFillingRowTextField';
import testFillingRowStyle from './TestFillingRowStyle.json';
import {getPercentileValue} from "./TestUtils";
import testResultHasACentileKeyAndPercentileObjectAValue
  from "./testUtils/TestResultHasACentileKeyAndPercentileObjectAValue";

const useStyles = makeStyles(testFillingRowStyle);

const TestFillingRowInitialScoreColumn = ({
  displayCalculatedPercentile,
  testResult,
  fields,
  columnWidth,
  handleScoreChange,
  handleBlur,
  mouseScrollWheelScoreEdition,
  testRowData
}) => {
  const style = useStyles();
  const [percentileObject, setPercentileObject] = useState({ label: '' });

  useEffect(() => {
    if (
      displayCalculatedPercentile &&
      testRowData &&
      testRowData.pct &&
      (testResult.score || testResult.score === 0)
    ) {
      const po = getPercentileValue({
        value: Number(testResult.score),
        pctTable: testRowData.pct.slice(),
        order: testRowData.order || testRowData.walkType,
        indexOfpc50: testRowData.indexOfpc50
      });
      setPercentileObject(po);
    }
  }, [displayCalculatedPercentile, testResult, testRowData])

  const displayPercentileObjectLabel = displayCalculatedPercentile && testResultHasACentileKeyAndPercentileObjectAValue(testResult, percentileObject);
  const displayEmptyPercentileLabel = displayCalculatedPercentile && !testResultHasACentileKeyAndPercentileObjectAValue(testResult, percentileObject);

  return (
    <Grid item md={12} lg={6} container className={style.extraDenseInput}>
      <Grid
        item
        md={
          displayCalculatedPercentile || (displayCalculatedPercentile && percentileObject && percentileObject.label)
            ? 8
            : 9
        }
        container
        className={style.alignVertically}
      >
        {fields.map(({ children, ...props }) => (
          <TestFillingRowTextField
            key={props.id}
            {...props}
            disabled
            itemMdValue={columnWidth}
            onChangeHandler={handleScoreChange}
            onBlurHandler={handleBlur}
            inputType="number"
            margin="dense"
            onWheel={mouseScrollWheelScoreEdition}
          >
            {children}
          </TestFillingRowTextField>
        ))}
      </Grid>
      {displayPercentileObjectLabel && (
        <Grid item md={2} className={style.alignVertically}>
          {percentileObject && percentileObject.label}
        </Grid>
      )}
      {displayEmptyPercentileLabel && (
        <Grid item md={2} className={style.alignVertically} />
      )}
    </Grid>
  );
};

export default TestFillingRowInitialScoreColumn;
