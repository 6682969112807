import React, { Component } from 'react';
import PersonalTestEdit from '../../components/configuration/tests/personal/PersonalTestEdit';

type Props = {
  match: ?{ params: ?{ testId: ?string } }
};

export default class PersonalTestEditPage extends Component<Props> {
  props: Props;

  render() {
    const {
      match: {
        params: { testId }
      }
    } = this.props;

    return <PersonalTestEdit testId={testId} />;
  }
}
