import labels from "../../../../../constants/labels/patient/test/TestFillingHeader.json";
import { inputIncludeScoreAD } from "../../testUtils/calculateValue/ScoreADValue";

const age = ({ input = [], columns = {}, id, testResult = {}, fireTab }) => ({
  header: { condition: !!columns.SCORE_AD, label: labels.resultColumnTitles.ADScore },
  column: {
    displayCondition: inputIncludeScoreAD(input),
    replacementCondition: !!columns.SCORE_AD,
    id: "SCORE_AD",
    placeholder: "-",
    controlName: `${id}.scoreAD`,
    value: testResult.scoreAD,
    fullWidth: true,
    inputProps: { inputProps: { step: "0.1", onKeyPress: fireTab } }
  }
});

export default age;
