// import appVersionSpecific from '../../../../appVersionSpecific.ortho';
// import normalizeImporterClasse from './NormalizeImporterClasse';
// import MyStore from "../../../../actions/MyStore";
//
import iconv from 'iconv-lite';
//
// const getFolderSuffixNames = testCode =>
//   ({
//     'EXALANG-3-6': ['Exalang 3-6', 'Exalang_3-6', 'Exalang_3_6', 'Exalang-3_6'],
//     'EXALANG-5-8': ['Exalang 5-8', 'Exalang_5-8', 'Exalang_5_8'],
//     'EXALANG-8-11': ['Exalang 8-11', 'Exalang_8-11', 'Exalang_8_11'],
//     'EXALANG-11-15': ['Exalang 11-15', 'Exalang_11-15', 'Exalang_11_15'],
//     'EXALANG-LYFAC': ['Exalang Lyfac', 'Motus/Exalang Lyfac/files', 'Exalang_Lyfac', 'Motus/Exalang_Lyfac']
//   }[testCode] || []);
// function getHappyNeuronFolders(testCode) {
//   return (
//     {
//       // 'EXALANG-3-6': [
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang36/files'),
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang36')
//       // ],
//       // 'EXALANG-5-8': [
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang58/files'),
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang58')
//       // ],
//       // 'EXALANG-8-11': [
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang811/files'),
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang811')
//       // ],
//       // 'EXALANG-11-15': [
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang11115/files'),
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang1115/files'),
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang11115'),
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang1115')
//       // ],
//       // 'EXALANG-LYFAC': [
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang1LyFac/files'),
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalangLyFac/files'),
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalang1LyFac'),
//       //   path.join(remote.app.getPath('appData'), './com.happyneuron.hnpro/exalangLyFac')
//       // ]
//     }[testCode] || []
//   );
// }
//
// const getWindowsApplicationPaths = () => [
//   // path.join(remote.app.getPath('appData').replace('roaming', 'local'), './VirtualStore/Program Files'),
//   // path.join(remote.app.getPath('appData').replace('roaming', 'local'), './VirtualStore/Program Files (x86)'),
//   // path.join(remote.app.getPath('appData'), '.'),
//   'C:/Program Files/',
//   'C:/Program Files (x86)/',
//   'C:/'
// ];
//
// function endsWithFileSeparator(thisPath: string) {
//   if (typeof thisPath === 'string' && thisPath.length > 0) {
//     return thisPath[thisPath.length - 1] === '/' || thisPath[thisPath.length - 1] === '\\';
//   }
//   return false;
// }
//
// const combinePath = (pathList: string[], pathList2: string[]): string[] => {
//   const result: string[] = [];
//   (pathList || ['']).forEach((p1: string) => {
//     (pathList2 || ['']).forEach((p2: string) => {
//       result.push(p1 + (!p2 || !p1 || endsWithFileSeparator(p1) ? '' : '/') + p2);
//     });
//   });
//   return result;
// };
import {xmlDownload} from "../../import/utils/HappyneuronFileDownloader";
import normalizeImporterClasse from "./NormalizeImporterClasse";

const getLocation = async (testCode, withPrevious) => {
  // const stored = MyStore.get(`${appVersionSpecific.electronStoreAppConfigTestImport}${testCode}`);
  // if (withPrevious && stored && stored.length > 0 && fs.existsSync(stored)) {
  //   return { filePath: stored, valid: true };
  // }
  // const folderSuffixNames = getFolderSuffixNames(testCode);
  // const additionalFolderSuffixNames = folderSuffixNames.map(name => name.replace('-', ' '));
  // const allFolderSuffixNames = [...folderSuffixNames, ...additionalFolderSuffixNames];
  // const possibleWindowsPaths = combinePath(getWindowsApplicationPaths(), ['', 'motus']);
  // const possiblePathsWithSuffix = combinePath(possibleWindowsPaths, allFolderSuffixNames);
  // const possiblePathsWithfile = combinePath(possiblePathsWithSuffix, ['files', '']);
  // const possiblePaths = [...possiblePathsWithfile, ...getHappyNeuronFolders(testCode)];
  // console.log('Exalang search path', { possiblePaths });
  //
  // const map = possiblePaths
  //   .filter(filePath => fs.existsSync(`${filePath}/prof.xml`))
  //   .map(filePath => ({
  //     filePath,
  //     mtime: fs.statSync(`${filePath}/prof.xml`).mtime || 0
  //   }));
  // if (map && map.length > 0) {
  //   const latestFilePath = map.sort(({ mtime: a }, { mtime: b }) => b - a).shift().filePath;
  //   const filePath = `${latestFilePath}/prof.xml`;
  //   MyStore.set(`${appVersionSpecific.electronStoreAppConfigTestImport}${testCode}`, filePath);
  //   return { filePath, valid: true };
  // }
  return { filePath: '', valid: false };
};

const parseFile = async ({userId, ticket, programId, encoding}) => {

  const xmlContent = await xmlDownload({userId, ticket, programId});

  const decodedContent = iconv.decode(xmlContent, encoding || 'win1252');
  const oDOM = new window.DOMParser().parseFromString(decodedContent, 'application/xml');
  const fiches = oDOM.getElementsByTagName('fiches')[0];

  return [...fiches.getElementsByTagName('profil')].flatMap(profilElement =>
    [...profilElement.getElementsByTagName('passation')].map(passation => ({
      firstname: profilElement.getAttribute('prenom'),
      lastname: profilElement.getAttribute('nom'),
      birthDate: profilElement.getAttribute('datenaissance'),
      classe: profilElement.getAttribute('classe'),
      passationNumber: passation.getAttribute('numero'),
      passationDate: passation.getAttribute('datepassage'),
      cotationCode: normalizeImporterClasse(profilElement.getAttribute('classe')),
      hnWorkoutId: passation.getAttribute('workoutId'),
      data: passation
    }))
  );
};

export { getLocation, parseFile };
