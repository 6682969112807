import React, { Component } from 'react';
import { Grid, Tooltip, IconButton } from '@material-ui/core';
import _ from 'lodash';
import { Check, Delete, AddCircle, RemoveCircle } from '@material-ui/icons';

import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis';
import commonLabels from '../../../../constants/Labels';
import './ElementStyle.css';
import AnamnesisReadOnlyDragElement from './AnamnesisReadOnlyDragElement';

class AnamnesisElement extends Component {
  props: Props;

  state = {
    isEditing: false,
    element: {}
  };

  componentDidMount(): void {
    const { element } = this.props;
    this.setState({
      isEditing: false,
      element
    });
  }

  handleChange = event => {
    let { element } = this.state;
    element = _.set(element, event.target.name, event.target.value);
    this.setState({ element });
  };

  setElementChanged = () => {
    const {
      elementFunction: { changeElement },
      rowNumber,
      columnNumber
    } = this.props;
    const { element } = this.state;
    changeElement(element, rowNumber, columnNumber);
    this.setState({ isEditing: false });
  };

  changeWidth = widthDifference => () => {
    const { element } = this.state;
    const { ...newElement } = element;
    newElement.span = (element.span || 12) + widthDifference;
    this.setState({ element: newElement });
  };

  switchEditState = () => {
    const { isEditing } = this.state;
    this.setState({ isEditing: !isEditing });
  };

  render() {
    const { ...props } = this.props;
    const {
      elementFunction,
      rowNumber,
      columnNumber,
      isDragging,
      child,
      editableElement
    } = props;
    const { element, isEditing } = this.state;
    const { removeElement } = elementFunction;
    const { id, label, span = 12 } = element;
    return (
      <Grid key={id || label} item xs={isDragging ? span - 1 : span} container>
        <Grid item xs>
          {isEditing ? (
            <Grid container className="element">
              <h1>
                <span>{label}</span>
              </h1>
              <Grid item xs={12} container justifyContent="space-around">
                <Grid item xs={1}>
                  <Tooltip title={commonLabels.validate}>
                    <IconButton onClick={this.setElementChanged}>
                      <Check />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {element.span !== 3 && (
                  <Grid item xs={1}>
                    <Tooltip title={labels.tabs.form.right.reduce}>
                      <IconButton onClick={this.changeWidth(-3)}>
                        <RemoveCircle />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                {(element.span || 12) !== 12 && (
                  <Grid item xs={1}>
                    <Tooltip title={labels.tabs.form.right.enlarge}>
                      <IconButton onClick={this.changeWidth(3)}>
                        <AddCircle />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item xs={1}>
                  <Tooltip title={commonLabels.deleteLabel}>
                    <IconButton
                      onClick={removeElement(rowNumber, columnNumber)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              {editableElement(element, this.handleChange)}
            </Grid>
          ) : (
            <AnamnesisReadOnlyDragElement
              {...props}
              switchEditState={this.switchEditState}
            />
          )}
        </Grid>
        {child}
      </Grid>
    );
  }
}

export default AnamnesisElement;
