import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import labels from '../../../constants/labels/configuration/memorizedTexts/MemorizedTextsCopy.json';
import { filteredLabeledTests } from '../../../constants/tests/tests';
import log from '../../Logger';
import User from '../../common/data/User';
import CustomTests from '../../common/data/CustomTests';
import { intersection, not } from './util';
import CustomList from './CustomList';

type Props = {
  open: boolean,
  showOnlyUserSelectedTestsDefault: boolean,
  setOpen: boolean => void,
  confirmCopy: (Array<string>) => void,
  copyData?: null | {
    category: { label: string },
    texts: Array<string>
  }
};

const useStyles = makeStyles({
  limitHeight75vh: {
    maxHeight: '75vh',
    height: '75vh',
    overflow: 'auto'
  },
  spanLimitedTo1Line: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis'
  }
});

const MemorizedTextsCopy = ({ open, setOpen, showOnlyUserSelectedTestsDefault, confirmCopy, copyData }: Props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [showOnlyUserSelectedTests, setShowOnlyUserSelectedTests] = useState(showOnlyUserSelectedTestsDefault);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [testList, setTestList] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);


  useEffect(() => {
    const updateContent = async () => {
      try {
        const userSelectedTests = await User.retrieveValueFromDb({ keyValue: 'tests', defaultValue: [] });
        const userSelectedTestCodes = userSelectedTests.val.map(({ testCode }) => testCode);
        const personalTests = await CustomTests.findAll();

        const allTests = [
          ...filteredLabeledTests.filter(({ testCode }) =>
            showOnlyUserSelectedTests ? userSelectedTestCodes.includes(testCode) : true
          ),
          ...personalTests
        ];
        const mapped = allTests.flatMap(({ label: testLabel, testCode, children }) =>
          children.map(({ id, label: childLabel }) => ({ testId: `${testCode}.${id}`, testCode, testLabel, childLabel }))
        );
        setTestList(mapped);
        setLeft(allTests.flatMap(({ testCode, children }) => children.map(({ id }) => `${testCode}.${id}`)));
      } catch (err) {
        log.error('Error retrieving datas', err);
      }
    };

    updateContent().then();
  }, [showOnlyUserSelectedTests]);

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const copy = () => {
    confirmCopy(right);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {copyData && copyData.texts && labels.title[copyData.texts.length > 1 ? 'plural' : 'singular']}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {copyData && copyData.texts && labels.text[copyData.texts.length > 1 ? 'plural' : 'singular']}
            <br />
            {copyData && copyData.category && copyData.category.label && copyData.texts && (
              <Fragment>
                {copyData.texts.slice(0, 4).map(s => (
                  <span className={classes.spanLimitedTo1Line}>
                    - &quot;{s}&quot;
                    <br />
                  </span>
                ))}
                {copyData.texts.length > 4 && (
                  <span className={classes.spanLimitedTo1Line}>
                    ...
                    <br />
                  </span>
                )}
                <span>
                  {labels.inCategory} &quot;{copyData.category.label}&quot;.
                </span>
              </Fragment>
            )}
          </DialogContentText>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item md={12}>
              <FormControlLabel
                control={
                  <Tooltip
                    title={labels.showOnlyUserSelectedTests[showOnlyUserSelectedTests ? 'true' : 'false'].tooltip}
                  >
                    <Switch
                      checked={showOnlyUserSelectedTests}
                      onChange={e => setShowOnlyUserSelectedTests(e.target.checked)}
                      name="checkedB"
                      color="primary"
                    />
                  </Tooltip>
                }
                label={labels.showOnlyUserSelectedTests[showOnlyUserSelectedTests ? 'true' : 'false'].label}
              />
            </Grid>
            <Grid item className={classes.limitHeight75vh}>
              <CustomList
                key={left}
                title="Épreuves des batteries de test"
                testList={testList}
                items={left}
                checked={checked}
                setChecked={setChecked}
              />
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item className={classes.limitHeight75vh}>
              <CustomList
                key={right}
                title="Épreuves vers lesquelles le commentaire sera copié"
                testList={testList}
                items={right}
                checked={checked}
                setChecked={setChecked}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {labels.cancel}
          </Button>
          <Button onClick={copy} color="primary" disabled={right.length === 0}>
            {labels.copy}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

MemorizedTextsCopy.defaultProps = {
  copyData: {
    category: { label: '' },
    texts: []
  }
};

export default MemorizedTextsCopy;
