/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import { Divider, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import labels from '../../../constants/labels/patient/test/TestListingHeader.json';
import TestListingStyle from './TestListingStyle.json';
import appVersionSpecific from '../../../appVersionSpecific.ortho';
import BackButton from '../../common/BackButton';
import { getHeaderAgeAndClassText } from '../../common/Header';

const useStyles = makeStyles(TestListingStyle);

const TestListingHeader = ({ bilan, patient, switchState, switchRoute, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const ageAndClassText = getHeaderAgeAndClassText(patient.curclasse, patient.birthDay);

  return (
    <Grid container item md={12} spacing={2} alignItems="flex-start" justifyContent="space-evenly">
      <Grid item md={12}>
        <BackButton backRoute={appVersionSpecific.mainPagePatientLink(patient._id)} />
      </Grid>
      <Grid item md={8}>
        <Typography variant="h3">{labels.title}</Typography>
        <Typography variant="h4">
          {labels.subTitle.part0}{' '}
          {bilan.renewalNumber ? labels.subTitle.renewal + bilan.renewalNumber : labels.subTitle.initial}{' '}
          {labels.subTitle.part1}{' '}
          <span className={classes.bold}>
            {patient.firstName} {patient.lastName}
          </span>{' '}
          ({ageAndClassText})
        </Typography>
      </Grid>
      <Grid item md={4} container>
        <Grid item md={12}>
          <FormControlLabel
            control={
              <Switch
                checked={switchState}
                onChange={() => {
                  history.push(switchRoute);
                }}
              />
            }
            label={labels.displayTestByModel}
          />
        </Grid>
        {children}
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default TestListingHeader;
