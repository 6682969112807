import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import TextFieldFormControl from '../TextFieldFormControl';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  change: Function,
  id: string,
  controlName: string,
  value?: string,
  placeholder?: string
};

const EditableLabel = ({ change, id, controlName, value, placeholder }: Props) => {
  const [isEditing, setEditing] = useState(false);
  const [mValue, setMValue] = useState(value);

  return isEditing ? (
    <TextFieldFormControl
      id={id}
      controlName={controlName}
      fullWidth
      value={mValue}
      onChangeHandler={e => {
        setMValue(e.target.value);
      }}
      onBlurHandler={event => {
        setEditing(false);
        change(event);
      }}
      placeholder={placeholder}
    />
  ) : (
    <Typography
      variant="body1"
      onClick={() => {
        setEditing(true);
      }}
    >
      {value}
    </Typography>
  );
};

EditableLabel.defaultProps = {
  placeholder: undefined,
  value: ''
};
export default EditableLabel;
