import React from 'react';
import { Route, Switch } from 'react-router';
import UserProfile from '../components/user/UserProfile';
import UserMailUpdate from "../components/user/UserMailUpdate";
import routes from "../constants/routes";

const UserProfilePage = () => (
  <Switch>
    <Route exact path={routes.USER.PROFILE} component={UserProfile}/>
    <Route exact path={routes.USER.MAIL_UPDATE} component={UserMailUpdate}/>
  </Switch>
);

export default UserProfilePage;
