import localDatabases from '../../../database/database';
import log from '../../Logger';

export type TestConfigType = {
  autoConv: 0 | 1,
  dbtype: 'testconfig',
  difficulty: string,
  patho: string,
  testName: string,
  _id: string,
  _rev: string
};

export default class TestConfig {
  static TYPE = 'testconfig';

  static async findAll() {
    const result = await localDatabases.getInstance().v1databaseRepository.find({
      selector: {
        _id: { $gte: null },
        dbtype: TestConfig.TYPE
      }
    });
    result.docs = result.docs.map(model => {
      const { _id, ...cleanedConfig } = model;
      cleanedConfig._id = TestConfig.getId(_id);
      return cleanedConfig;
    });
    log.info('TestConfig.findAll(), found docs : ');
    log.table(result.docs);
    return result;
  }

  static async findByTestName(testName) {
    const result = await localDatabases.getInstance().v1databaseRepository.find({
      selector: {
        _id: { $gte: null },
        dbtype: TestConfig.TYPE,
        testName
      }
    });
    const testConfig = (result.docs || [])
      .map(model => {
        const { _id, ...cleanedConfig } = model;
        cleanedConfig._id = TestConfig.getId(_id);
        return cleanedConfig;
      })
      .shift();
    log.info('TestConfig.findByTestName(), found docs : ');
    log.table(testConfig);
    return testConfig;
  }

  static async save(testConfigToSave) {
    const { ...testConfig } = testConfigToSave;
    testConfig.dbtype = TestConfig.TYPE;
    testConfig._id = TestConfig.setId(testConfig._id);
    const updatedTestConfig = await localDatabases.getInstance().v1databaseRepository.put(testConfig);
    testConfig._rev = updatedTestConfig.rev;
    testConfig._id = TestConfig.getId(testConfig._id);
    log.info('handleSubmit, testconfig :', testConfig);
    return testConfig;
  }

  static async delete(testConfig) {
    const { _id, ...testConfigToDelete } = testConfig;
    testConfigToDelete._id = TestConfig.setId(_id);
    const result = await localDatabases.getInstance().v1databaseRepository.remove(testConfigToDelete);
    return result;
  }

  static setId(id) {
    if (id && typeof id === 'string' && id.startsWith(TestConfig.TYPE)) {
      return id;
    }
    return TestConfig.TYPE + id;
  }

  static getId(id) {
    if (id && typeof id === 'string' && id.startsWith(TestConfig.TYPE)) {
      return id.substring(TestConfig.TYPE.length);
    }
    return id;
  }
}
