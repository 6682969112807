import React from 'react';
import classNames from 'classnames';
import { AppBar, Button, Divider, Drawer, Fab, IconButton, List, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, Close, KeyboardArrowUp, Menu as MenuIcon } from '@material-ui/icons';

import TileData from './TileData';

import routes from '../constants/routes';
import Logo from '../components/Logo';

import ScrollTop from '../components/common/ScrollTop';
import UserProfileMenu from '../components/UserProfileMenu';
import IsOnlineIcon from '../components/IsOnlineIcon';
import MyStore from "../actions/MyStore";
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    height: '-webkit-fill-available',
    letterSpacing: ({ letterSpacing }) => `${letterSpacing}rem !important`
  },
  appBar: {
    width: '-webkit-fill-available',
    backgroundColor: 'white',
    position: 'fixed',
    top: 0,
    '& svg': {
      fill: 'rgba(0, 0, 0, 0.87)'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    background: theme.palette.background.default,
    marginTop: '75px',
    overflow: 'auto',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    height: 'calc(100vh - 100px)',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  noPaddingBottom: {
    paddingBottom: 0
  },
  drawerPaper: {
    position: '',
    width: drawerWidth,
    backgroundColor: '#1f4d5a'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    '&  svg': {
      fill: 'white'
    },
    ...theme.mixins.toolbar
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  'contentShift-left': {
    marginLeft: drawerWidth
  },
  logoButton: {
    backgroundColor: 'white',
    cursor: 'pointer',
    border: 'none',
    '&:focus': {
      outline: 'none'
    }
  }
}));

export default function AppLayout({
  fontFamily,
  letterSpacing,
  downloadProgress,
  newVersionDownloadAvailable,
  children
}: Props) {
  const classes = useStyles({ letterSpacing, fontFamily });
  const [mainMenuOpened, setMainMenuOpened] = React.useState(false);
  const [scrollTarget, setScrollTarget] = React.useState(undefined);

  const handleDrawerClose = () => {
    MyStore.set('justLoggedIn', false);
    setMainMenuOpened(false);
  };

  const menu = (
    <Drawer
      variant="persistent"
      anchor="left"
      onClose={handleDrawerClose}
      open={mainMenuOpened}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <List role="presentation">
        <TileData />
      </List>
    </Drawer>
  );

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classNames(classes.appBar, {
          [classes.contentShift]: mainMenuOpened,
          [classes[`contentShift-left`]]: mainMenuOpened
        })}
      >
        <Toolbar>
          <IconButton
            onClick={() => {
              setMainMenuOpened(!mainMenuOpened);
            }}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            {mainMenuOpened ? <Close style={{ fontSize: '2.5rem' }} /> : <MenuIcon style={{ fontSize: '2.5rem' }} />}
          </IconButton>
          <Button
            component={Link}
            to={routes.HOME}
            className={classes.logoButton}
          >
            <Logo />
          </Button>
          <IsOnlineIcon downloadProgress={downloadProgress} newVersionDownloadAvailable={newVersionDownloadAvailable} />
          <UserProfileMenu />
        </Toolbar>
      </AppBar>
      {menu}
      <main
        className={classNames(classes.content, classes.noPaddingBottom, {
          [classes.contentShift]: mainMenuOpened,
          [classes[`contentShift-left`]]: mainMenuOpened
        })}
        role="presentation"
        onClick={handleDrawerClose}
        onKeyDown={handleDrawerClose}
        ref={node => {
          if (node) {
            setScrollTarget(node);
          }
        }}
      >
        {children}
      </main>
      <ScrollTop thisRef={scrollTarget}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>

    </div>
  );
}
