import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Typography
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Comment } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import labels from '../../../constants/labels/patient/import/ImportPatientList.json';
import PersonAdd from '../../common/PersonAdd';
import CheckupType from '../CheckupType';
import Patient from '../../common/data/Patient';
import filterUniqElementByProperty from '../../common/ArrayUtil';
import ListStyle from '../../../constants/styles/ListStyle.json';
import OkCancelDialog from '../../common/OkCancelDialog';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%'
  },
  margin16: {
    margin: '16px'
  },
  ...ListStyle
});

type Props = {
  // eslint-disable-next-line react/require-default-props
  firstname?: string,
  // eslint-disable-next-line react/require-default-props
  lastname?: string,
  importToPatient: ({ patientId: string, eraseResults: boolean }) => void,
  createRenewalCheckup: ({ patientId: string }) => void,
  passationDates: Array<string>
};

const ImportPatientList = ({
  passationDates,
  firstname = null,
  lastname = null,
  importToPatient,
  createRenewalCheckup
}: Props) => {
  const classes = useStyles();
  const [eraseResults, setEraseResults] = useState(false);
  const [fileTypeFilter, setFileTypeFilter] = useState('filteredOnName');
  const [patients, setPatients] = useState([]);
  const [sortField, setSortField] = useState('firstName');
  const [sortOrder, setSortOrder] = useState('asc');
  const [dialogOpened, setDialogOpened] = useState(false);
  const [patientId, setPatientId] = useState(null);

  useEffect(() => {
    const findPatient = async () => {
      const myPatientBase = [];

      if (fileTypeFilter === 'filteredOnName') {
        if (firstname && firstname !== '') {
          const { patients: firstnamePatientBase } = await Patient.getPatientList(false, firstname, sortField, sortOrder);
          myPatientBase.push(...firstnamePatientBase);
        }
        if (lastname && lastname !== '') {
          const { patients: lastnamePatientBase } = await Patient.getPatientList(false, lastname, sortField, sortOrder);
          myPatientBase.push(...lastnamePatientBase);
        }
      }
      if (fileTypeFilter === 'allFiles') {
        const { patients: patientBase } = await Patient.getPatientList(false, null, sortField, sortOrder);
        myPatientBase.push(...patientBase);
        const { patients: archivedPatientBase } = await Patient.getPatientList(true, null, sortField, sortOrder);
        myPatientBase.push(...archivedPatientBase);
      }
      if (fileTypeFilter === 'currentFiles') {
        const { patients: patientBase } = await Patient.getPatientList(false, null, sortField, sortOrder);
        myPatientBase.push(...patientBase);
      }
      if (fileTypeFilter === 'archivedFiles') {
        const { patients: patientBase } = await Patient.getPatientList(true, null, sortField, sortOrder);
        myPatientBase.push(...patientBase);
      }

      console.log({ myPatientBase });
      setPatients(filterUniqElementByProperty(myPatientBase, '_id'));
    };

    findPatient().then();
  }, [fileTypeFilter, firstname, lastname, sortOrder, sortField]);

  const setSort = event => {
    const [field, order] = event.target.value.split('-');
    setSortField(field);
    setSortOrder(order);
  };

  const handleCreationConfirmation = confirmation => async () => {
    if (confirmation) {
      await createRenewalCheckup({ patientId });
      return;
    }
    await setPatientId(null);
    await setDialogOpened(false);
  };

  return (
    <Fragment>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <Typography variant="h5">{labels.patientSelectTitle}</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControlLabel
            control={<Switch checked={eraseResults} onChange={() => setEraseResults(!eraseResults)} />}
            label={labels.erasePreviousResults}
          />
          <FormControlLabel
            control={
              <Select value={fileTypeFilter} onChange={e => setFileTypeFilter(e.target.value)}>
                <MenuItem value="filteredOnName">{labels.filter.filteredOnName}</MenuItem>
                <MenuItem value="allFiles">{labels.filter.allFiles}</MenuItem>
                <MenuItem value="currentFiles">{labels.filter.currentFiles}</MenuItem>
                <MenuItem value="archivedFiles">{labels.filter.archivedFiles}</MenuItem>
              </Select>
            }
            label={labels.filter.label}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Select value={`${sortField}-${sortOrder}`} onChange={setSort}>
                <MenuItem value="firstName-asc">
                  {labels.sort.firstname}
                  <ArrowDropUp />
                </MenuItem>
                <MenuItem value="firstName-desc">
                  {labels.sort.firstname}
                  <ArrowDropDown />
                </MenuItem>
                <MenuItem value="lastName-asc">
                  {labels.sort.lastname}
                  <ArrowDropUp />
                </MenuItem>
                <MenuItem value="lastName-desc">
                  {labels.sort.lastname}
                  <ArrowDropDown />
                </MenuItem>
                <MenuItem value="rdvDay-asc">
                  {labels.sort.checkupDate}
                  <ArrowDropUp />
                </MenuItem>
                <MenuItem value="rdvDay-desc">
                  {labels.sort.checkupDate}
                  <ArrowDropDown />
                </MenuItem>
              </Select>
            }
            label={labels.sort.label}
            labelPlacement="start"
          />
        </Grid>
        <Grid container item xs={12}>
          <List dense className={classes.fullWidth}>
            <ListItem
              button
              onClick={importToPatient({ patientId: 'new', eraseResults: true })}
              className={classes.orthoscribeList}
            >
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              <ListItemText primary={labels.patientCreateNew} />
            </ListItem>
            {patients.map(({ firstName, lastName, rdvDay, _id, bilan }) => (
              <ListItem
                key={_id}
                onClick={importToPatient({ patientId: _id, eraseResults })}
                className={classes.orthoscribeList}
              >
                <ListItemText
                  primary={`${firstName} ${lastName}`}
                  secondary={<CheckupType bilan={bilan}>{new Date(rdvDay).toLocaleDateString()}</CheckupType>}
                />
                {!passationDates.includes(new Date(rdvDay).toLocaleDateString()) && (
                  <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={importToPatient({ patientId: _id })}
                      className={classes.margin16}
                    >
                      {labels.patientCreateInitialCheckup}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<Comment />}
                      onClick={async () => {
                        await setPatientId(_id);
                        setDialogOpened(true);
                      }}
                      className={classes.margin16}
                    >
                      {labels.patientCreateRenewalCheckup}
                    </Button>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <OkCancelDialog
        open={dialogOpened}
        handleClose={handleCreationConfirmation}
        title={labels.renewalCheckupCreationDialog.title}
        contentText={labels.renewalCheckupCreationDialog.text}
      />
    </Fragment>
  );
};

export default ImportPatientList;
