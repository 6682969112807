import rawScore from "./testFilling/getHeaderFields/RawScore";
import ns19 from "./testFilling/getHeaderFields/Ns19";
import standardDeviation from "./testFilling/getHeaderFields/StandardDeviation";
import percentile from "./testFilling/getHeaderFields/Percentile";
import age from "./testFilling/getHeaderFields/Age";
import {timeOrErrorIcon} from "./TimeOrErrorIcon";

export const getFields = (input, displayRawScore, columns, id, testResult, maxScore, fireTab, disableCentileColumn) => [
  timeOrErrorIcon({input, id, columns}).column,
  rawScore({ input, displayRawScore, columns, id, testResult, maxScore, fireTab }).column,
  ns19({ input, columns, id, testResult, fireTab }).column,
  standardDeviation({ input, columns, id, testResult, fireTab }).column.score,
  standardDeviation({ input, columns, id, testResult, fireTab }).column.time,
  percentile({ input, disableCentileColumn, columns, id, testResult, fireTab }).column.score,
  percentile({ input, disableCentileColumn, columns, id, testResult, fireTab }).column.quartQuint,
  percentile({ input, disableCentileColumn, columns, id, testResult, fireTab }).column.sept,
  percentile({ input, disableCentileColumn, columns, id, testResult, fireTab }).column.octile,
  percentile({ input, disableCentileColumn, columns, id, testResult, fireTab }).column.time,
  age({ input, columns, id, testResult, fireTab }).column
];

export const getHeaders = (columns, displayRawScore, disableCentileColumn) => [
  timeOrErrorIcon({columns}).header,
  rawScore({ columns, displayRawScore }).header,
  ns19({ columns }).header,
  standardDeviation({ columns }).header,
  percentile({ columns, disableCentileColumn }).header,
  age({ columns }).header
];

export const displayedColumns = (columns, disableCentileColumn) => ({
  timeOrErrorIcon: columns.TEMPS || columns.TEMPS_ET || columns.ERR_SCORE_ET || columns.ERR_TEMPS_ET || columns.TEMPS_CENTILE || columns.ERR_CENTILE || columns.ERR_TEMPSCENTILE,
  rawScore: columns.SCORE || columns.TEMPS || columns.NS19,
  standardDeviation: columns.SCORE_ET || columns.TEMPS_ET || columns.ERR_SCORE_ET || columns.ERR_TEMPS_ET,
  percentile:
    (columns.TEMPS_CENTILE ||
      columns.SCORE_CENTILE ||
      columns.SCORE_QUINT ||
      columns.SCORE_QUART ||
      columns.SCORE_SEPTILE ||
      columns.SCORE_OCTILE ||
      columns.ERR_CENTILE ||
      columns.ERR_TEMPSCENTILE) &&
    !disableCentileColumn,
  age: columns.SCORE_AD
});
