import React from 'react';
import {
  Add,
  Assignment,
  AssignmentTurnedIn,
  Description,
  Edit,
  FiberManualRecord,
  Home,
  ListAlt,
  Settings,
  Visibility
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import menulabels from './constants/labels/Menu.json';
import routes from './constants/routes';
import modele from './constants/modele.bilan.standard.json';
import templateList from './constants/report/template-list';

import MoreActionsMenu from './components/home/MoreActionsMenu';
import labels from './constants/labels/home/Home.json';
import AnamnesisModel from './components/common/data/AnamnesisModel';
import anamnesisLabels from './constants/labels/configuration/anamnesis/Anamnesis.json';
import questionnaire from './resources/images/questionnaire.png';
import modeleDeDocument from './resources/images/modeleDeDocument.png';
import structureDeBilan from './resources/images/structureDeBilan.png';
import testsInclus from './resources/images/testsInclus.jpg';
import testsPersonnels from './resources/images/testsPersonnels.jpg';
import textesMemorises from './resources/images/textesMemorises.jpg';
import reportOptionsMenu from './resources/images/reportOptionsMenu.png';
import buildInfo from './buildInfo.json';

const appName = buildInfo.isHappyNeuron ? 'Happy' : 'Ortho';

const addIcon = (
  <MoreActionsMenu
    format="button"
    buttonText="Créer un dossier"
    buttonProps={{ variant: 'contained', color: 'primary' }}
    menuItemEntries={[
      {
        key: 'newPatient',
        element: (
          <Link to={routes.PATIENT.getForPath({ path: routes.PATIENT.EDIT, patientId: 'new' })}>
            Créer un bilan initial
          </Link>
        )
      },
      {
        key: 'renewalCheckup',
        element: (
          <Link to={routes.PATIENT.getForPath({ path: routes.PATIENT.RENEWAL.CHOOSE, patientId: 'new' })}>
            {labels.titleRow.createRenewalCheckup}
          </Link>
        )
      },
      {
        key: 'patientImport',
        element: (
          <Link to={routes.IMPORT.PATIENT}>
            {labels.titleRow.importPatientFile}
          </Link>
        )
      }
    ]}
    icon={<Add />}
  />
);

const noPatientSubTitle = (
  <React.Fragment>Pour cr&eacute;er votre premier patient, cliquez sur l&apos;ic&ocirc;ne {addIcon}</React.Fragment>
);

const subMenu = buildInfo.isHappyNeuron ?
  [
    {
      label: menulabels.documentModels.title,
      route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.DOCUMENT_MODEL),
      icon: <Description />,
      description: menulabels.documentModels.description,
      image: modeleDeDocument
    },
    {
      label: menulabels.memorizedTexts.title,
      route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MEMORIZED_TEXTS),
      icon: <FiberManualRecord />,
      description: menulabels.memorizedTexts.description,
      image: textesMemorises
    },
    {
      label: menulabels.reportOptions.title,
      route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.MAIN),
      icon: <ListAlt />,
      description: menulabels.reportOptions.description,
      image: reportOptionsMenu
    },
    {
      label: menulabels.display.title,
      route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.DISPLAY.MAIN),
      icon: <Visibility />,
      description: menulabels.display.description,
      image: reportOptionsMenu
    }
  ]
  :
  [
    {
    label: menulabels.anamnese.title,
    route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.ANAMNESIS.MAIN),
    icon: <Assignment />,
    description: menulabels.anamnese.description,
    image: questionnaire,
    actions: [
      {
        title: menulabels.anamnese.actions.new,
        action: async history => {
          const { name, ...anamneseModelProps } = AnamnesisModel.getDefault();
          const _id = uuidv4();
          await AnamnesisModel.save({ _id, name: `${anamnesisLabels.copyOf} ${name}`, ...anamneseModelProps });
          history.push(routes.CONFIGURATION.ANAMNESIS.getForPath(_id));
        }
      }
    ]
  },
  {
    label: menulabels.tests.title,
    route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.LIST),
    icon: <AssignmentTurnedIn />,
    description: menulabels.tests.description,
    image: testsInclus,
    actions: [
      {
        title: menulabels.tests.options,
        route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.OPTIONS)
      }
    ]
  },
  {
    label: menulabels.personalTests.title,
    route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.PERSONAL_TESTS.LIST),
    icon: <AssignmentTurnedIn />,
    description: menulabels.personalTests.description,
    image: testsPersonnels,
    actions: [
      {
        title: menulabels.personalTests.actions.new,
        route: routes.CONFIGURATION.TESTS.PERSONAL_TESTS.getForPath('new')
      }
    ]
  },
  {
    label: menulabels.models.title,
    route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MODELS.MAIN),
    icon: <Edit />,
    description: menulabels.models.description,
    image: structureDeBilan,
    actions: [
      {
        title: menulabels.models.actions.new,
        route: routes.CONFIGURATION.MODELS.getForPath('new')
      }
    ]
  },
  {
    label: menulabels.documentModels.title,
    route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.DOCUMENT_MODEL),
    icon: <Description />,
    description: menulabels.documentModels.description,
    image: modeleDeDocument
  },
  {
    label: menulabels.memorizedTexts.title,
    route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MEMORIZED_TEXTS),
    icon: <FiberManualRecord />,
    description: menulabels.memorizedTexts.description,
    image: textesMemorises
  },
  {
    label: menulabels.reportOptions.title,
    route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.MAIN),
    icon: <ListAlt />,
    description: menulabels.reportOptions.description,
    image: reportOptionsMenu
  },
  {
    label: menulabels.display.title,
    route: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.DISPLAY.MAIN),
    icon: <Visibility />,
    description: menulabels.display.description,
    image: reportOptionsMenu
  }
];

const appVersionSpecific = {
  backend: {
    url: 'https://www.orthoscribe.fr',
    secure: 'https://secure.orthoscribe.fr',
    securedDb: 'https://securedb.orthoscribe.fr'
  },
  db: {
    pathPrefix: `orthoscribe/database/V1/`,
    remoteUserPrefix: 'user-'
  },
  appName,
  isHappyNeuron: buildInfo.isHappyNeuron,
  mainMenu: [
    { label: menulabels.home, route: routes.HOME, icon: <Home /> },
    {
      label: menulabels.configuration,
      icon: <Settings />,
      route: routes.CONFIGURATION.MAIN,
      subMenu
    }
  ],
  remoteDbConnection: true,
  canImportOldOrthoscribeDatabase: true,
  electronStoreUserKey: 'orthoUser',
  electronStoreAesKey: 'orthoAesKey',
  electronStoreLastLoginDate: 'orthoLastLoginDate',
  electronStoreLicenseDate: 'orthoLcsDate',
  electronStoreWebSiteHost: 'webSiteHost',
  electronStoreAppConfigTestImport: 'testImport_',
  electronStoreLatestVersionNewDisplayed: 'latestVersionNewDisplayed',
  electronStoreLatestNewsDisplayed: 'latestNewsDisplayed',
  electronStoreLatestVersionDatabaseScriptRunned: 'latestVersionDBScriptRunned',
  homePage: {
    showType: true,
    showAnamnesis: true,
    showTestList: true,
    showReportLink: true,
    showRenewalPatientCreation: true,
    detailLink: routes.PATIENT.CONSULT,
    addIcon,
    noPatient: {
      mainTitle: `Bienvenue sur ${appName}scribe`,
      subTitle: noPatientSubTitle
    }
  },
  modeleBilanStandard: modele,
  mainPagePatientLink: patientId =>
    routes.PATIENT.getForPath({ path: appVersionSpecific.homePage.detailLink, patientId }),
  createInitialCheckupLabel: 'Créer un bilan initial',
  reportOptions: {
    reportCreation: {
      showFileNameUseRenewalMarker: true,
      showFileNameUseReportDate: true,
      showGraphicsOptions: true,
      showAnamnesisOptions: true,
      showRenewalCheckup: true,
      showDisplayTestLabelBeforeSubtest: true
    },
    reportGraphics: {
      showFontSelection: false
    }
  },
  templateList,
  fileNameFormatSuffix: ''
};

export default appVersionSpecific;
