import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import type {ReportGraphicOptions} from '../report/ReportGraphicsDefaultOptions';
import defaultOptions from '../report/ReportGraphicsDefaultOptions';
import {getColor, getValue, getWidth} from "./cotationBar/CotationBarUtil";
import {getTestByTestCode} from "../../../constants/tests/tests";
import TestConfig from "../../common/data/TestConfig";
import {getThresholds} from "../../../constants/tests/ScoreEvaluator";

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  children?: any,
  // eslint-disable-next-line flowtype/no-weak-types
  testResult?: any,
  height?: string,
  percentileObject: { percentileValue: number } | undefined,
  options: ReportGraphicOptions | undefined,
  testCode: string | undefined
};
const useStyles = makeStyles({
  bgc: {
    border: '1px solid #CCCCCC',
    borderRadius: '5px'
  }
});

const CotationBarChart = ({ children, testResult, height, percentileObject, options, testCode }: Props) => {
  const classes = useStyles();
  const [color, setColor] = useState('#FFFFFF');
  const [value, setValue] = useState(0);

  useEffect(() => {
    const updateDatas = async () => {

      const test = await getTestByTestCode(testCode);
      // const newVar = await TestConfig.findAll();
      // console.log({newVar})
      const testConfig = await TestConfig.findByTestName(testCode);
      const { pathoThresholdAsZscore, difficultyThresholdAsZscore, unit } = getThresholds(testConfig, test);

      const mvalue = getValue(percentileObject, testResult, unit);
      const mcolor = getColor(mvalue, testResult, options || defaultOptions, pathoThresholdAsZscore, difficultyThresholdAsZscore, unit);
      setColor(mcolor);
      setValue(mvalue);
    };

    updateDatas().then();
  }, [options, percentileObject, testCode, testResult]);

  const myStyle = {
    background: `linear-gradient(to right, white, ${color})`,
    width: `${getWidth(value)}%`,
    height: height || '100%'
  };
  return value ? (
    <div className={classes.bgc} style={myStyle}>
      {children}
    </div>
  ) : (
    <div />
  );
};

CotationBarChart.defaultProps = {
  testResult: {},
  height: '1.5rem',
  children: undefined
};

export default CotationBarChart;
