import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import TextFieldFormControl from '../TextFieldFormControl';

// eslint-disable-next-line react/prop-types
const PasswordField = ({ password, changeHandler, itemMdValue, placeholder, label, ...rest }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <TextFieldFormControl
      id="password"
      controlName="password"
      itemMdValue={itemMdValue}
      placeholder={placeholder}
      label={label}
      fullWidth
      inputType={passwordVisible ? 'text' : 'password'}
      value={password}
      onChangeHandler={changeHandler}
      inputProps={{
        required: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setPasswordVisible(!passwordVisible);
                setTimeout(() => setPasswordVisible(false), 5000);
              }}
              onMouseDown={event => event.preventDefault()}
            >
              {passwordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...rest}
    />
  );
};
export default PasswordField;
