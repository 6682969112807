import React, { useState } from 'react';
import TextFieldFormControl from '../../../TextFieldFormControl';

type Props = {
  id?: string,
  label: string,
  placeholder?: string,
  span?: number,
  myValue?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function
};

const TextArea = ({ id, label, placeholder, span, myValue: propValue, changeHandler }: Props) => {
  const [myValue, setMyValue] = useState(propValue || '');
  return (
    <TextFieldFormControl
      id={id || label}
      controlName={id}
      itemMdValue={span}
      label={label}
      placeholder={placeholder}
      fullWidth
      multiline
      rows="3"
      value={myValue}
      onChangeHandler={event => setMyValue(event.target.value)}
      onBlurHandler={() => changeHandler({ target: { name: id, value: myValue } })}
    />
  );
};

TextArea.defaultProps = {
  id: '',
  placeholder: '',
  span: 12,
  myValue: '',
  changeHandler: () => {}
};

export default TextArea;
