import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { Delete, Edit, Unarchive } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import routes from '../../../constants/routes';
import labels from '../../../constants/Labels.json';
import CheckUpModel from '../../common/data/CheckUpModel';
import log from '../../Logger';
import ListStyle from '../../../constants/styles/ListStyle.json';

const useStyles = makeStyles({ ...ListStyle });

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  exportModel: any => void
};

const CheckUpModelList = ({ exportModel }: Props) => {
  const classes = useStyles();
  const [models, setModels] = useState([]);

  useEffect(() => {
    updateCheckUpModelList();
  }, []);

  const updateCheckUpModelList = async () => {
    try {
      const modelsFromDb = await CheckUpModel.retrieveModelList();
      setModels(modelsFromDb);
    } catch (err) {
      log.error('Error retrieving anamneseModels', err);
    }
  };

  const deleteModel = idToDelete => async () => {
    const modelToDelete = models.filter(({ _id }) => _id === idToDelete);
    await CheckUpModel.delete(modelToDelete[0]);
    await updateCheckUpModelList();
  };

  return (
    <Grid item md={12}>
      <List>
        {models.map(({ _id, label, description }) => (
          <Box boxShadow={2} className={classes.listItemBox} key={_id}>
            <ListItem
              button
              component={Link}
              to={routes.CONFIGURATION.MODELS.getForPath(_id)}
              className={classes.orthoscribeList}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary={label} secondary={description} />
              <ListItemSecondaryAction>
                <Tooltip title={labels.deleteLabel}>
                  <IconButton onClick={deleteModel(_id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
                <Tooltip title={labels.exportLabel}>
                  <IconButton onClick={() => exportModel(_id)}>
                    <Unarchive />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          </Box>
        ))}
      </List>
    </Grid>
  );
};
export default CheckUpModelList;
