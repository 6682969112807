import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import labels from '../../../constants/labels/patient/patientConsult/Checkup.json';
import routes from '../../../constants/routes';
import patientConsultStyle from './patientConsultStyle.json';
import type {Patient} from "../Patient";

const useStyle = makeStyles({ ...patientConsultStyle });

type Props = {
  patient: Patient,
  bilan: {
    _id: string | undefined,
    renewalNumber: number | undefined,
    initialAssessmentUUID: string | undefined,
    tests: array<>
  },
  patientInit: {
    _id: string,
    lastName: string,
    firstName: string
  }
};

const Checkup = ({ patient, patientInit, bilan }: Props) => {
  const classes = useStyle();

  return (
    <Fragment>
      <Grid item xs={12} container>
        <Typography variant="h6">
          {bilan.initialAssessmentUUID ? labels.renewal.title : labels.initial.title}
        </Typography>
      </Grid>
      <Paper className={classes.paper}>
        {bilan.initialAssessmentUUID ? (
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant="body1">
                {labels.renewal.phrase0.part0}
                {bilan.renewalNumber}
                {labels.renewal.phrase0.part1}
                {patientInit.firstName} {patientInit.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {labels.renewal.phrase1.part0}
                {labels.renewal.phrase1.part1}
                <Button
                  className={classes.noPadding}
                  component={Link}
                  to={routes.PATIENT.getForPath({
                    path: routes.PATIENT.RENEWAL.CHOOSE,
                    patientId: patient._id
                  })}
                >
                  {labels.renewal.phrase1.link0}
                </Button>
                {labels.renewal.phrase1.part2}
                <Button
                  className={classes.noPadding}
                  component={Link}
                  to={routes.PATIENT.getForPath({
                    path: routes.PATIENT.RENEWAL.REMOVE,
                    patientId: patient._id
                  })}
                >
                  {labels.renewal.phrase1.link1}
                </Button>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant="body1">
                {labels.initial.phrase0.part0}
                {labels.initial.phrase0.part1}
                <Button
                  className={classes.noPadding}
                  component={Link}
                  to={routes.PATIENT.getForPath({
                    path: routes.PATIENT.RENEWAL.CHOOSE,
                    patientId: patient._id
                  })}
                >
                  {labels.initial.phrase0.link0}
                </Button>
                {labels.initial.phrase0.part2}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Fragment>
  );
};

export default Checkup;
