import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { InputAdornment } from '@material-ui/core';
import { Email } from '@material-ui/icons';

import TextFieldFormControl from './TextFieldFormControl';
import labels from '../constants/labels/EmailFieldFormControl.json';

type Props = {
  mailIsValid?: boolean => void,
  value: string | undefined,
  // eslint-disable-next-line flowtype/no-weak-types
  onChangeHandler: Function,
  // eslint-disable-next-line flowtype/no-weak-types
  inputProps?: any
};

const EmailFieldFormControl = ({ mailIsValid, onChangeHandler, value, inputProps, ...props }: Props) => {
  const [error, setError] = useState(!validator.isEmail(value || ''));

  useEffect(() => {
      const emailIsValid = validator.isEmail(value || '');
      setError(!emailIsValid);
      mailIsValid(emailIsValid);
  }, [value, mailIsValid]);

  const validateEmailAndPropagate = e => {
    const { value: mailValue } = e.target;
    const emailIsValid = validator.isEmail(mailValue);
    setError(!emailIsValid);
    mailIsValid(emailIsValid);
    onChangeHandler(e);
  };

  return (
    <TextFieldFormControl
      inputType="mail"
      onChangeHandler={validateEmailAndPropagate}
      helperText={error && labels.emailNotValid}
      error={error}
      {...{ ...props, value }}
      inputProps={{
        // eslint-disable-next-line react/prop-types
        ...inputProps,
        startAdornment: (
          <InputAdornment position="start">
            <Email />
          </InputAdornment>
        )

      }}
    />
  );
};

EmailFieldFormControl.defaultProps = {
  mailIsValid: () => {},
  inputProps: {}
};

export default EmailFieldFormControl;
