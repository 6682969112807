/* based on https://gist.github.com/orlandovallejos/ed9c79dd67e96c25cfcc8f1008df9489 */

/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
// import throttle from 'lodash/throttle';

/*------------------------------------------------*/
/* CONSTANTS
/*------------------------------------------------*/
const PX_DIFF = 40;

/*------------------------------------------------*/
/* GLOBAL VARIABLES
/*------------------------------------------------*/
let scrollIncrement = 0;
let isScrolling = false;
let sidebarElement = null;

/*------------------------------------------------*/
/* METHODS
/*------------------------------------------------*/

/**
 * Scroll up in the sidebar.
 */
const goUp = () => {
  scrollIncrement -= PX_DIFF;
  document.documentElement.scrollTop += scrollIncrement;
};

const onDragOver = () => {
  if (!isScrolling) {
    isScrolling = true;
    scrollIncrement = -sidebarElement.scrollTop;
    // console.log("there", {isScrolling, scrollIncrement});
    window.requestAnimationFrame(goUp);
  } else {
    isScrolling = false;
  }
};

/**
 * The "throttle" method prevents executing the same function SO MANY times.
 */
// const throttleOnDragOver = throttle(onDragOver, 500);

// IMPORTANT: CALL THIS METHOD IN: beginDrag!!!
const addEventListenerForSidebar = () => {
  sidebarElement = document.getElementById('root');
  sidebarElement.addEventListener('dragover', onDragOver);
};

// IMPORTANT: CALL THIS METHOD IN: endDrag!!!
const removeEventListenerForSidebar = () => {
  sidebarElement.removeEventListener('dragover', onDragOver);
  isScrolling = false;
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
const Scrolling = {
  addEventListenerForSidebar,
  removeEventListenerForSidebar
};
export default Scrolling;
