import {jsonDownload} from "../../../import/utils/HappyneuronFileDownloader";
import DEFAULT_HN_CLASSES from '../DEFAULT_HN_CLASSES.json';
import happyneuronExalangClassToOrthoscribeClasse from "../HappyneuronExalangClassToOrthoscribeClasse";

const parseFile = async ({filePath, userId, ticket, programId}) => {
  const emilie = await jsonDownload({userId, ticket, programId});
  console.log("emilie", {emilie, filePath})
  const { code: classe } = happyneuronExalangClassToOrthoscribeClasse(DEFAULT_HN_CLASSES, Number(emilie.user.class)) || {};

  return emilie.workouts.map(workout => ({
    firstname: emilie.user.firstName,
    lastname: emilie.user.lastName,
    birthDate: emilie.user.birthDate,
    passationDate: workout.startDate,
    passationNumber: workout.id,
    data: { workout },
    hnWorkoutId: workout.id,
    classe,
    text: `${emilie.user.firstName} ${emilie.user.lastName} (${workout.startDate})`
  }));
};

export default parseFile;
