import React from "react";
import {MenuItem} from "@material-ui/core";

import labels from "../../../../../constants/labels/patient/test/TestFillingHeader.json";
import {
  inputIncludesCentile,
  inputIncludesCentileKey,
  inputIncludeScoreOctile,
  inputIncludesQuart,
  inputIncludesQuartQuint,
  inputIncludesSeptile
} from "../../testUtils/calculateValue/CentileValue";
import {inputIncludesTempsCentile} from "../../testUtils/calculateValue/TimeCentileValue";
import {inputIncludesErrorCentile} from "../../testUtils/calculateValue/ErrorCentileValue";
import percentileError from "./PercentileError";
import {inputIncludesErrorTempsCentile} from "../../testUtils/calculateValue/ErrorTempsCentileValue";

const quartiledata = [
  { val: 'undefined', label: '' },
  { val: 12.5, label: 'Quart.1' },
  { val: 37.5, label: 'Quart.2' },
  { val: 62.5, label: 'Quart.3' },
  { val: 87.5, label: 'Quart.4' }
];
const quintiledata = [
  { val: 'undefined', label: '' },
  { val: 10, label: 'Quint.1' },
  { val: 30, label: 'Quint.2' },
  { val: 50, label: 'Quint.3' },
  { val: 70, label: 'Quint.4' },
  { val: 90, label: 'Quint.5' }
];
const septiledata = [
  { val: 'undefined', label: '' },
  { val: 7, label: 'Classe 1' },
  { val: 20, label: 'Classe 2' },
  { val: 38, label: 'Classe 3' },
  { val: 62, label: 'Classe 4' },
  { val: 80, label: 'Classe 5' },
  { val: 93, label: 'Classe 6' },
  { val: 99, label: 'Classe 7' }
];
const octileData = [
  { val: 'undefined', label: '' },
  { val: 1, label: 'Hors limite' },
  { val: 5, label: 'Très faible' },
  { val: 10, label: 'Faible' },
  { val: 30, label: 'Moyenne faible' },
  { val: 50, label: 'Moyenne' },
  { val: 70, label: 'Moyen fort' },
  { val: 90, label: 'Fort' },
  { val: 91, label: 'Très fort' }
]

const percentileScore = (input, disableCentileColumn, columns, id, testResult, fireTab) => ({
  displayCondition: inputIncludesCentile(input) && !disableCentileColumn,
  replacementCondition: !!(columns.SCORE_CENTILE && !inputIncludesCentileKey(input) && !inputIncludesTempsCentile(input) && !inputIncludesErrorCentile(input) && !inputIncludesErrorTempsCentile(input) && !disableCentileColumn),
  id: "SCORE_CENTILE",
  placeholder: "-",
  controlName: `${id}.scoreCentile`,
  value: testResult.scoreCentile,
  fullWidth: true,
  inputProps: { inputProps: { step: 1, min: 0, max: 100, onKeyPress: fireTab } }
});
const percentileTime = (input, disableCentileColumn, columns, id, testResult, fireTab) => ({
  displayCondition: inputIncludesTempsCentile(input) && !disableCentileColumn,
  replacementCondition: !!(columns.TEMPS_CENTILE && !inputIncludesCentileKey(input) && !inputIncludesErrorCentile(input) && !inputIncludesErrorTempsCentile(input) && !disableCentileColumn),
  id: "TEMPS_CENTILE",
  placeholder: "-",
  controlName: `${id}.tempsCentile`,
  value: testResult.tempsCentile,
  fullWidth: true,
  inputProps: {
    inputProps: { step: 1, min: 0, max: 100, onKeyPress: fireTab }
  }
});
const quartQuint = (input, disableCentileColumn, columns, id, testResult, fireTab) => ({
  displayCondition: inputIncludesQuartQuint(input) && !disableCentileColumn,
  replacementCondition: !!(
    (columns.SCORE_QUART || columns.SCORE_QUINT) &&
    !!inputIncludesCentileKey(input) &&
    !disableCentileColumn
  ),
  id: "SCORE_QUART|SCORE_QUINT",
  placeholder: "-",
  controlName: `${id}.scoreCentile`,
  value: testResult.scoreCentile,
  inputProps: { inputProps: { onKeyPress: fireTab } },
  select: true,
  fullWidth: true,
  children: (inputIncludesQuart(input) ? quartiledata : quintiledata).map(({ val, label: centileLabel }) => (
    <MenuItem key={val} value={val}>
      {centileLabel}
    </MenuItem>
  ))
});
const sept = (input, disableCentileColumn, columns, id, testResult, fireTab) => ({
  displayCondition: inputIncludesSeptile(input) && !disableCentileColumn,
  replacementCondition: !!(columns.SCORE_SEPTILE && !inputIncludesCentileKey(input) && !disableCentileColumn),
  id: "SCORE_SEPTILE",
  placeholder: "-",
  controlName: `${id}.scoreCentile`,
  value: testResult.scoreCentile,
  inputProps: { inputProps: { onKeyPress: fireTab } },
  select: true,
  fullWidth: true,
  children: septiledata.map(({ val, label: centileLabel }) => (
    <MenuItem key={val} value={val}>
      {centileLabel}
    </MenuItem>
  ))
});
const octile = (input, disableCentileColumn, columns, id, testResult, fireTab) => ({
  displayCondition: inputIncludeScoreOctile(input) && !disableCentileColumn,
  replacementCondition: !!(columns.SCORE_OCTILE && !inputIncludesCentileKey(input) && !disableCentileColumn),
  id: "SCORE_OCTILE",
  placeholder: "-",
  controlName: `${id}.scoreCentile`,
  value: testResult.scoreCentile,
  inputProps: { inputProps: { onKeyPress: fireTab } },
  select: true,
  fullWidth: true,
  children: octileData.map(({ val, label: centileLabel }) => (
    <MenuItem key={val} value={val}>
      {centileLabel}
    </MenuItem>
  ))
});
const percentile = ({ input = [], disableCentileColumn, columns = {}, id, testResult = {}, fireTab }) => ({
  header: {
    condition: !!(
      (columns.SCORE_CENTILE ||
        columns.SCORE_QUART ||
        columns.SCORE_QUINT ||
        columns.TEMPS_CENTILE ||
        columns.SCORE_SEPTILE ||
        columns.SCORE_OCTILE ||
        columns.ERR_CENTILE ||
        columns.ERR_TEMPSCENTILE) &&
      !disableCentileColumn
    ),
    label: labels.resultColumnTitles.percentile
  },
  column: {
    score: (inputIncludesErrorCentile(input) && !disableCentileColumn) ? percentileError({input, disableCentileColumn, columns, id, testResult, fireTab}).column.score : percentileScore(input, disableCentileColumn, columns, id, testResult, fireTab),
    quartQuint: quartQuint(input, disableCentileColumn, columns, id, testResult, fireTab),
    sept: sept(input, disableCentileColumn, columns, id, testResult, fireTab),
    octile: octile(input, disableCentileColumn, columns, id, testResult, fireTab),
    time: (inputIncludesErrorTempsCentile(input) && !disableCentileColumn) ? percentileError({input, disableCentileColumn, columns, id, testResult, fireTab}).column.time : percentileTime(input, disableCentileColumn, columns, id, testResult, fireTab)
  }
});

export default percentile;
