import {Checkbox, FormControlLabel, Grid} from "@material-ui/core";
import labels from "../../constants/labels/home/Home.json";
import React from "react";

export function MultiActionSelectAll(props: {
    multiAction: boolean,
    multiActionSelectedPatientList: [],
    patientBase: [],
    setMultiActionSelectedPatientList: ((Array) => void) | any
}) {
    return <>
        {props.multiAction &&
            <Grid item md={12} container>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.multiActionSelectedPatientList.length === props.patientBase.length}
                            indeterminate={props.multiActionSelectedPatientList.length && props.multiActionSelectedPatientList.length !== props.patientBase.length}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    console.log("checked")
                                    props.setMultiActionSelectedPatientList(props.patientBase.map(({_id}) => _id));
                                } else {
                                    props.setMultiActionSelectedPatientList([]);
                                }
                            }}
                        />
                    }
                    label={labels.selectAll}
                />
            </Grid>
        }
    </>;
}