export default class MathUtil {
  static stdDeviationToPercentile(zscore) {
    const sigma = 1.0;
    let pc = 0.5 + 0.5 * MathUtil.erf(zscore / (sigma * Math.sqrt(2.0)));
    pc *= 100.0;
    pc = pc < 1 ? 1 : pc;
    pc = pc > 99 ? 99 : pc;
    pc = Math.round(pc);
    return pc;
  }

  static percentileToStdDeviation(val) {
    const percentile = val / 100;

    if (percentile <= 0) {
      return -5;
    }
    if (percentile >= 100) {
      return 5;
    }

    return Number.parseFloat(MathUtil.ltqnorm(percentile).toFixed(4));
  }

  static erf(x) {
    // constants
    const a1 = 0.254829592;
    const a2 = -0.284496736;
    const a3 = 1.421413741;
    const a4 = -1.453152027;
    const a5 = 1.061405429;
    const p = 0.3275911;

    // Save the sign of x
    let sign = 1;
    if (x < 0) {
      sign = -1;
    }
    const newX = Math.abs(x);

    // A&S formula 7.1.26
    const t = 1.0 / (1.0 + p * newX);
    const y = 1.0 - ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-newX * newX);

    return sign * y;
  }

  static ltqnorm(p) {
    const a = [
      -3.969683028665376e1,
      2.209460984245205e2,
      -2.759285104469687e2,
      1.38357751867269e2,
      -3.066479806614716e1,
      2.506628277459239
    ];

    const b = [
      -5.447609879822406e1,
      1.615858368580409e2,
      -1.556989798598866e2,
      6.680131188771972e1,
      -1.328068155288572e1
    ];

    const c = [
      -7.784894002430293e-3,
      -3.223964580411365e-1,
      -2.400758277161838,
      -2.549732539343734,
      4.374664141464968,
      2.938163982698783
    ];

    const d = [7.784695709041462e-3, 3.224671290700398e-1, 2.445134137142996, 3.754408661907416];

    const LOW = 0.02425;
    const HIGH = 0.97575;

    if (p < 0 || p > 1) {
      // errno = EDOM;
      return 0.0;
    }
    if (p === 0) {
      // errno = ERANGE;
      return -Infinity /* minus "infinity" */;
    }
    if (p === 1) {
      // errno = ERANGE;
      return Infinity /* "infinity" */;
    }
    if (p < LOW) {
      /* Rational approximation for lower region */
      const q = Math.sqrt(-2 * Math.log(p));
      return (
        (((((c[0] * q + c[1]) * q + c[2]) * q + c[3]) * q + c[4]) * q + c[5]) /
        ((((d[0] * q + d[1]) * q + d[2]) * q + d[3]) * q + 1)
      );
    }
    if (p > HIGH) {
      /* Rational approximation for upper region */
      const q = Math.sqrt(-2 * Math.log(1 - p));
      return (
        -(((((c[0] * q + c[1]) * q + c[2]) * q + c[3]) * q + c[4]) * q + c[5]) /
        ((((d[0] * q + d[1]) * q + d[2]) * q + d[3]) * q + 1)
      );
    }

    /* Rational approximation for central region */
    const q = p - 0.5;
    const r = q * q;
    return (
      ((((((a[0] * r + a[1]) * r + a[2]) * r + a[3]) * r + a[4]) * r + a[5]) * q) /
      (((((b[0] * r + b[1]) * r + b[2]) * r + b[3]) * r + b[4]) * r + 1)
    );
  }

  static calculateAge(birthday, dateFrom = Date.now()) {
    // birthday is a date
    const ageDifMs = dateFrom - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
