import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import uuidv4 from 'uuid/v4';

import routes from '../../../constants/routes';
import labels from '../../../constants/labels/configuration/tests/IncludedTestsOptions.json';
import * as Tests from '../../../constants/tests/tests';
import IncludedTestOption from './IncludedTestOption';
import SaveCancelRow from '../../common/SaveCancelRow';
import User from '../../common/data/User';
import TestConfig from '../../common/data/TestConfig';
import log from '../../Logger';

const IncludedTestsOptions = () => {
  const [testOptions, setTestOptions] = useState([]);
  const [userTestConfigFromDb, setUserTestConfigFromDb] = useState([]);

  useEffect(() => {
    updateTestConfigList();
  }, []);

  const updateTestConfigList = async () => {
    try {
      const userSelectedTestsFromDb = await User.retrieveValueFromDb({ keyValue: 'tests', defaultValue: [] });
      const userSelectedTestsTestCodes = userSelectedTestsFromDb.val.map(({ testCode }) => testCode);

      const filteredTests =
        userSelectedTestsTestCodes.length > 0
          ? Tests.includedTests.filter(({ testCode }) => userSelectedTestsTestCodes.includes(testCode))
          : Tests.includedTests;

      const testConfigs = await TestConfig.findAll();

      const userTestConfigByTestCode = testConfigs.docs.reduce((acc, item) => {
        acc[item.testName] = item;
        return acc;
      }, {});
      const mTestOptions = filteredTests
        .filter(test => test.available === undefined || !!test.available)
        .map(test => {
          const userTestConfig = userTestConfigByTestCode[test.testCode];
          return {
            testCode: test.testCode,
            defaultPathologicalThreshold: test.pathoThreshold || test.patho,
            defaultDifficultyThreshold: test.difficultyThreshold || test.pathoThreshold || test.patho,
            pathologicalThreshold: userTestConfig ? userTestConfig.patho : test.pathoThreshold || test.patho,
            difficultyThreshold: userTestConfig
              ? userTestConfig.difficulty
              : test.difficultyThreshold || test.pathoThreshold || test.patho,
            defaultAutoConv: (test.options && test.options.indexOf('AUTOCONV') >= 0) || false,
            autoConv: userTestConfig
              ? userTestConfig.autoConv !== 0
              : (test.options && test.options.indexOf('AUTOCONV') >= 0) || false,
            modified: !!userTestConfig,
            label: test.IHMLabel || test.label,
            disableAutoconv: (test.options && test.options.includes('disableAutoconv')) || false,
            disabledThresholdConfig: (test.options && test.options.includes('disabledThresholdConfig')) || false
          };
        });
      setTestOptions(mTestOptions);
      setUserTestConfigFromDb(testConfigs.docs);
    } catch (e) {
      log.error('Error retrieving user test config', e);
      throw e;
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    await Promise.all(
      testOptions
        .filter(({ modified }) => modified)
        .map(test => {
          let testInDb = userTestConfigFromDb.filter(({ testName }) => testName === test.testCode);
          [testInDb] = testInDb;
          if (!testInDb) {
            testInDb = {
              _id: uuidv4(),
              testName: test.testCode
            };
          }
          testInDb.patho = test.pathologicalThreshold;
          testInDb.difficulty = test.difficultyThreshold;
          testInDb.autoConv = test.autoConv ? 1 : 0;
          return TestConfig.save(testInDb);
        })
    );

    const modifiedTestOptionsCodes = testOptions.filter(({ modified }) => modified).map(({ testCode }) => testCode);

    await Promise.all(
      userTestConfigFromDb
        .filter(({ testName }) => !modifiedTestOptionsCodes.includes(testName))
        .map(testToDelete => TestConfig.delete(testToDelete))
    );
    await updateTestConfigList();
  };

  const handleChange = test => {
    const index = testOptions.findIndex(({ testCode }) => testCode === test.testCode);
    const newTests = JSON.parse(JSON.stringify(testOptions));
    newTests.splice(index, 1, test);
    setTestOptions(newTests);
  };

  return (
    <Grid item md={12} container>
      <Grid item md={12}>
        <Typography variant="h6">{labels.title}</Typography>
        <Typography variant="subtitle1">{labels.header}</Typography>
        <Typography variant="body2">{labels.subHeader}</Typography>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
          <SaveCancelRow
            cancelRoute={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.LIST)}
            saveAction={handleSubmit}
          />
          <Grid item xs={12} container style={{ borderBottom: 'thin solid' }}>
            <Grid item xs={3}>
              <div>{labels.testBattery}</div>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={2}>
              <div>{labels.pathologicalThreshold}</div>
            </Grid>
            <Grid item xs={2}>
              {labels.difficultyThreshold}
            </Grid>
            <Grid item xs={3}>
              {labels.autoConversion}
            </Grid>
            <Grid item xs={1} />
          </Grid>
          {testOptions.map(testOption => (
            <IncludedTestOption key={testOption.testCode} testOption={testOption} onChange={handleChange} />
          ))}
        </Grid>
      </form>
    </Grid>
  );
};

export default IncludedTestsOptions;
