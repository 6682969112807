import React, { Component } from 'react';
import { Divider, Grid, MenuItem, TextField } from '@material-ui/core';

import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis.json';
import FratrieType from '../../../common/types/anamnesis/Fratrie';
import FratrieDetails from './FratrieDetails';
import defaultFratrie from '../../../../constants/patient/anamnesis/defaultFratrie.json';

type Props = {
  id?: string,
  label: string,
  span?: number,
  myValue?: FratrieType,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function
};

function getLabelForMenu(int) {
  switch (int) {
    case 0:
      return labels.tabs.form.right.siblings['0'];
    case 1:
      return labels.tabs.form.right.siblings['1'];
    default:
      return `${int}${labels.tabs.form.right.siblings.more}`;
  }
}

export default class Fratrie extends Component<Props> {
  props: Props;

  static defaultProps = {
    id: '',
    span: 12,
    myValue: defaultFratrie,
    changeHandler: () => {}
  };

  onChangeCount = event => {
    const { id, changeHandler, myValue } = this.props;
    const value = JSON.parse(JSON.stringify(myValue));
    value.count.id = event.target.value;
    value.count.label = getLabelForMenu(event.target.value);
    changeHandler({ target: { name: id, value } });
  };

  render() {
    const { id, span, label, myValue, changeHandler } = this.props;
    return (
      <Grid key={id || label} item xs={span} container>
        <TextField
          select
          label={label}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          value={myValue.count.id}
          onChange={this.onChangeCount}
        >
          {[...Array(13).keys()].map(int => (
            <MenuItem value={int} key={int}>
              {getLabelForMenu(int)}
            </MenuItem>
          ))}
        </TextField>
        <Grid xs={12} item>
          <Divider />
        </Grid>
        {myValue.count.id > 0 && (
          <Grid item xs={12} container>
            <Grid item xs={3}>
              {labels.tabs.form.right.siblings.age}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              {labels.tabs.form.right.siblings.firstname}
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
              {labels.tabs.form.right.siblings.relation}
            </Grid>
          </Grid>
        )}
        {myValue.count.id > 0 &&
          [...Array(myValue.count.id).keys()].map((int, index) => {
            const ageValue = { value: myValue.detail[int].age, unit: myValue.detail[int].unit };
            const { relation, name = '' } = myValue.detail[int];
            return <FratrieDetails {...{ id, int, index, ageValue, relation, name, changeHandler, myValue }} />;
          })}
      </Grid>
    );
  }
}
