// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
import EVALEC from '../../../../../constants/tests/EVALEC.json';
import calculateRawResult from './CalculateRawResult';

const executeImport = ({ data, classe }) => {
  if (data && data.workout) {
    const values = EVALEC.children
      .map(({ id, input }) => calculateRawResult(id, data.workout, data, input))
      .filter(Boolean)
      .reduce((acc, { eprId, score, annotation }) => {
        acc[eprId] = { score, comment: annotation };
        return acc;
      }, {});
    return { count: Object.entries(values).length, values, calculOption: classe, calculOptionType: 'classe' };
  }
};

export default executeImport;
