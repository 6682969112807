import React, { useState } from 'react';
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { CheckCircle, DeleteOutlined, Edit, FileCopy } from '@material-ui/icons';

import EditingListItem from './EditingListItem';
import labels from '../../../../constants/labels/configuration/memorizedTexts/MemorizedListItem.json';
import SimpleTextEditingListItem from './SimpleTextEditingListItem';

type Props = {
  text: string,
  selected?: boolean,
  original?: boolean,
  readOnly?: boolean,
  simple?: boolean,
  onItemSelected: string => void,
  onChange: string => void,
  onDelete: void => void,
  // eslint-disable-next-line flowtype/no-weak-types
  copyAction: undefined | Function,
  insertedByDefault?: boolean,
  setInsertedByDefault?: boolean => void
};

function MemorizedListItem({
  text,
  original,
  onItemSelected,
  onChange,
  onDelete,
  selected,
  copyAction,
  simple,
  readOnly,
  insertedByDefault,
  setInsertedByDefault
}: Props) {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    if (simple) {
      return (
        <SimpleTextEditingListItem
          label=""
          onValidateLabel={onChange}
          onCancelAdd={() => setIsEditing(false)}
          defaultValue={text}
        />
      );
    }
    return (
      <EditingListItem
        label=""
        onValidateLabel={onChange}
        onCancelAdd={() => setIsEditing(false)}
        defaultValue={text}
        insertedByDefault={insertedByDefault}
        setInsertedByDefault={setInsertedByDefault}
      />
    );
  }

  return (
    <ListItem button
              onMouseDown={e => e.preventDefault()}
              onClick={() => onItemSelected(text)} selected={selected} className="drag-handle">
      {insertedByDefault && (
        <ListItemAvatar>
          <Tooltip title={labels.insertedByDefault}>
            <CheckCircle color="primary" />
          </Tooltip>
        </ListItemAvatar>
      )}
      <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: text }} />} />
      {!original && !readOnly && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="edit" size="small" onClick={() => setIsEditing(true)}>
            <Tooltip title={labels.edit}>
              <Edit />
            </Tooltip>
          </IconButton>
          <IconButton edge="end" aria-label="delete" size="small" onClick={onDelete}>
            <Tooltip title={labels.delete}>
              <DeleteOutlined />
            </Tooltip>
          </IconButton>
          {copyAction && (
            <IconButton aria-label="copy" size="small" onClick={() => copyAction(text)}>
              <Tooltip title={labels.copy}>
                <FileCopy />
              </Tooltip>
            </IconButton>
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

MemorizedListItem.defaultProps = {
  selected: false,
  original: false,
  simple: false,
  readOnly: false,
  insertedByDefault: false,
  setInsertedByDefault: () => {}
};

export default MemorizedListItem;
