const normalizeImporterClasse = clazz =>
  ({
    '6': '6EME',
    '5': '5EME',
    '4': '4EME',
    '3': '3EME',
    '2': '2ND',
    '1': '1ERE'
  }[clazz] || clazz);
export default normalizeImporterClasse;
