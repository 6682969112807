import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Grid } from '@material-ui/core';

import appVersionSpecific from '../../appVersionSpecific.ortho';
import dateFormatOptions from '../patient/patientConsult/dateFormatOptions.json';
import { loadTeamNews, getLastestNewsDate } from '../common/TeamNewsUtil';
import MyStore from "../../actions/MyStore";

const css = `
h1,
h1 ~ *,
h2 ~ h1,
h2 ~ h1 ~ *,
h3 ~ h1,
h3 ~ h1 ~ * {
    margin-left: 0px;
    color: #477988;
}


h2,
h2 ~ *,
h1 ~ h2,
h1 ~ h2 ~ *:not(h1):not(h3):not(details) {
    margin-left: 40px;
}

h3,
h3 ~ *,
h1 ~ h3,
h1 ~ h3 ~ *:not(h1):not(h2):not(details) {
    margin-left: 80px;
}

details {
    margin-left: unset;
}
details summary {
    cursor: pointer;
}
details summary > * {
    display: inline
}
`;

const TeamNews = () => {
  const [news, setNews] = useState([]);

  const latestNewsDisplayed = MyStore.get(appVersionSpecific.electronStoreLatestNewsDisplayed, 0);

  useEffect(() => {
    loadNews();
  }, []);

  const loadNews = async () => {
    const { payload } = await loadTeamNews();
    const postingDate = getLastestNewsDate(payload);
    await setNews(payload);
    if (postingDate) {
      MyStore.set(appVersionSpecific.electronStoreLatestNewsDisplayed, postingDate);
    }
  };

  return (
    <Grid item container md={12} spacing={2}>
      <style type="text/css">{css}</style>
      <Grid item md={12}>
        {news
          .sort(({ postingDate: a }, { postingDate: b }) => b - a)
          .map(({ postingDate, summary, content }) => (
            <details key={`${postingDate}${summary}`} open={postingDate >= latestNewsDisplayed}>
              <summary>
                <h1>
                  {summary} {new Date(postingDate).toLocaleDateString('fr-FR', dateFormatOptions)}
                </h1>
              </summary>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                  // Rewrite `img`s to limit their width
                  img: ({ ...props }) => <img style={{ maxWidth: '70vw' }} {...props} alt="" />,
                  a: ({href, children, ...props}) => <a href={href} {...props} target="_blank" rel="noreferrer">{children}</a>
                }}
              >
                {content}
              </ReactMarkdown>
            </details>
          ))}
      </Grid>
    </Grid>
  );
};

export default TeamNews;
