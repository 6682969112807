// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
import EXAMATH58 from '../../../../../constants/tests/EXAMATH-5-8.json';
import { happyneuron2021FileFormat } from '../HappyNeuronImporterUtil';

const executeImport = ({ data, classe }) => {
  const calculValue = EXAMATH58.calcul.find(({ id }) => id === classe);
  const rawResultTransformationFunction = ({ eprId, score, annotation }) => {
    if ((score === undefined || score === null) && !annotation) return null;
    if (!calculValue && !calculValue.data) return { eprId, score, annotation };
    const { displayConstantValue } = calculValue.data[eprId] || {};
    if (displayConstantValue) {
      return {
        eprId,
        score,
        annotation: `${
          annotation ? 'annotation<br/>' : ''
        }Constante = ${displayConstantValue}, score du patient ${score}`
      };
    }
    return { eprId, score, annotation };
  };

  return happyneuron2021FileFormat.executeImport({
    data,
    classe,
    test: EXAMATH58,
    positionCorrectionFunction: index => index - 1,
    rawResultTransformationFunction
  });
};

export default executeImport;
