import React, { Fragment, useEffect, useState } from 'react';
import { CreateNewFolder, GetApp, Person } from '@material-ui/icons';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import moment from 'moment';
import classNames from 'classnames';

import labels from '../../constants/labels/patient/PatientImportFile.json';
import SaveCancelRow from '../common/SaveCancelRow';
import routes from '../../constants/routes';
import { uncompress } from '../../actions/FileImportExport';
import * as PatientImport from '../../actions/PatientImport';
import TextFieldFormControl from '../TextFieldFormControl';
import Patient from '../common/data/Patient';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold'
  },
  paddingTop16: {
    paddingTop: '16px'
  },
  colorRed: {
    color: 'red'
  }
});

const PatientImportFile = () => {
  const history = useHistory();
  const inputRef = React.useRef(null)

  const classes = useStyles();
  const [filename, setFilename] = useState();
  const [patient, setPatient] = useState();
  const [anonymousFile, setAnonymousFile] = useState(false);
  const [datas, setDatas] = useState();
  const [rows, setRows] = useState();
  const [saveCancelRowState, setSaveCancelRowState] = useState();
  const [error, setError] = useState();
  const [anonymousFirstname, setAnonymousFirstname] = useState('');
  const [anonymousLastname, setAnonymousLastname] = useState('');
  const [anonymousComplaint, setAnonymousComplaint] = useState('');
  const [patientAlreadyExist, setPatientAlreadyExist] = useState(false);

  const fileVerifications = [
    {
      assertion: ({ header, data }) => header.length === 0 || data.length === 0,
      errorLabel: labels.errors.emptyOrInaccessible
    },
    {
      assertion: ({ header }) => [`ORTHOSCRIBE/PATIENT/1`, `ORTHOSCRIBE/ANONYMIZEDPATIENT/1`].includes(header),
      errorLabel: labels.errors.unknownFormat
    }
  ];

  const submitForm = async () => {
    const { patient: savedPatient } = await PatientImport.importPatient(
      datas,
      anonymousFirstname,
      anonymousLastname,
      anonymousComplaint,
      patientAlreadyExist
    );
    setPatient(savedPatient);
    setSaveCancelRowState('importDone');
  };

  function goToPatient() {
    history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId: patient._id }));
  }

  const parseFile = async (event) => {
    const filePath = event.target.files[0]
    if (!filePath) {
      return;
    }

    setFilename(filePath.name);

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const { header, data } = await uncompress(fileReader.result);
      const [firstErrorLabel] = fileVerifications
        .filter(({ assertion }) => assertion({ header, data }))
        .map(({ errorLabel }) => errorLabel);
      if (firstErrorLabel) {
        setError(firstErrorLabel);
        return;
      }
      const s = header.toString('utf8');
      if (s === `ORTHOSCRIBE/ANONYMIZEDPATIENT/1`) {
        setAnonymousFile(true);
      }
      const parsedDatas = JSON.parse(data);
      console.log({ data: parsedDatas, patient: JSON.parse(parsedDatas.jsonPatient) });
      setDatas(parsedDatas);
      setPatient(JSON.parse(parsedDatas.jsonPatient));
      setSaveCancelRowState('canImport');
      try {
        const patientFromDb = await Patient.retrievePatient(parsedDatas.patientUUID.replace('{', '').replace('}', ''));
        if (Object.entries(patientFromDb).length > 1) {
          setPatientAlreadyExist(true);
        }
      } catch (e) {
        setPatientAlreadyExist(false);
      }
    };
    fileReader.readAsArrayBuffer(filePath);
  };



  useEffect(() => {
    inputRef.current.click()
  }, []);
  useEffect(() => {
    const setValuesFromPatient = () => {
      if (patient) {
        const newRows = [];
        if (!anonymousFile) {
          newRows.push({ label: labels.patient.name, value: `${patient.lastName} ${patient.firstName}` });
        }
        newRows.push({ label: labels.patient.birthDate, value: moment(patient.birthDay).format('LL') });
        if (!anonymousFile) {
          newRows.push({ label: labels.patient.complaint, value: patient.plainte });
        }
        if (datas.stamp) {
          const stamp = moment(datas.stamp);
          newRows.push({
            label: labels.patient.fileExportingDate,
            value: `Le ${stamp.format('LL')} à ${stamp.format('LT')}`
          });
        }
        setRows(newRows);
      }
    };

    setValuesFromPatient();
  }, [anonymousFile, datas, patient]);

  return (
    <div>
      <Typography variant="h6">{labels.mainTitle}</Typography>
      <Typography variant="subtitle1">{labels.subTitle}</Typography>
      <Divider />
      <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
        {error && <SaveCancelRow cancelRoute={routes.HOME} />}
        {saveCancelRowState === 'canImport' && (
          <SaveCancelRow
            cancelRoute={routes.HOME}
            saveAction={submitForm}
            saveButtonLabel={patientAlreadyExist ? labels.overwritePatientAction : labels.updatePatientAction}
            saveIcon={<CreateNewFolder />}
          />
        )}
        {saveCancelRowState === 'importDone' && (
          <Fragment>
            <SaveCancelRow
              cancelRoute={routes.HOME}
              saveAction={goToPatient}
              saveButtonLabel={labels.consultResponse}
              saveIcon={<Person />}
              title={<Typography variant="subtitle1" style={{ color: 'green' }}>
                {labels.importSuccess}
              </Typography>}
            />
          </Fragment>
        )}
        <Grid item md={12} container>
          {!filename &&
            <Grid item md={2}>
              <input
                id="contained-button-file"
                type="file"
                hidden
                onChange={parseFile}
                accept=".patient"
              />
              <label htmlFor="contained-button-file">
                <Button ref={inputRef} variant="outlined" component="span" startIcon={<GetApp />}>
                  {labels.selectFile}
                </Button>
              </label>
            </Grid>
          }
          {filename &&
            <Grid item md={12}>
              <Typography variant="subtitle1">{filename || labels.filenameNotExisting}</Typography>
            </Grid>
          }
          <Grid item md={12}>
            <Typography variant="subtitle1">{error}</Typography>
          </Grid>
          {patientAlreadyExist && (
            <Grid item md={12}>
              <Typography variant="subtitle1" className={classes.colorRed}>
                <span className={classes.bold}>{labels.patientAlreadyExist.warning}</span>
                {labels.patientAlreadyExist.title}
              </Typography>
            </Grid>
          )}
          {rows &&
            rows.map(({ label, value }) => (
              <Fragment key={label}>
                <Grid item md={12}>
                  <Divider />
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body1" className={classes.bold}>
                    {label}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  {value}
                </Grid>
              </Fragment>
            ))}
          {anonymousFile && (
            <Grid item md={12} container spacing={2} className={classes.paddingTop16}>
              <Grid item md={12}>
                <Typography variant="subtitle1" className={classNames(classes.bold, classes.colorRed)}>
                  {labels.anonymousPatient.title}
                </Typography>
              </Grid>
              <TextFieldFormControl
                onChangeHandler={event => setAnonymousFirstname(event.target.value)}
                id="firstname"
                controlName="firstname"
                value={anonymousFirstname}
                itemMdValue={6}
                fullWidth
                label={labels.anonymousPatient.firstname}
              />
              <TextFieldFormControl
                onChangeHandler={event => setAnonymousLastname(event.target.value)}
                id="lastname"
                controlName="lastname"
                value={anonymousLastname}
                itemMdValue={6}
                fullWidth
                label={labels.anonymousPatient.lastname}
              />
              <TextFieldFormControl
                onChangeHandler={event => setAnonymousComplaint(event.target.value)}
                id="complaint"
                controlName="complaint"
                value={anonymousComplaint}
                itemMdValue={12}
                fullWidth
                label={labels.anonymousPatient.complaint}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientImportFile;
