import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function AntecedentIcon() {
  return (
    <SvgIcon viewBox="0 0 10240 10240">
      <path
        style={{ fill: '#252525' }}
        d="m 6481.1525,2720 1418.8475,0 0,1280 -1418.8475,0 0,-1280 z m -2128.2711,0 1418.8474,0 0,1280 -1418.8474,0 0,-1280 z m 2128.2711,1600 1418.8475,0 0,1280 -1418.8475,0 0,-1280 z m -2128.2711,0 1418.8474,0 0,1280 -1418.8474,0 0,-1280 z m 2128.2711,1600 1418.8475,0 0,1280 -1418.8475,0 0,-1280 z m -2128.2711,0 1418.8474,0 0,1280 -1418.8474,0 0,-1280 z"
      />
      <path
        style={{ fill: '#252525' }}
        d="m 2497.9661,2736.2712 1280,0 0,640 -1280,0 0,-640 z m 0,1920 1280,0 0,640 -1280,0 0,-640 z m -5,-960 1280,0 0,640 -1280,0 0,-640 z"
      />
    </SvgIcon>
  );
}
