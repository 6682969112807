import React, { useEffect, useState } from 'react';
import { Badge, IconButton, Menu, MenuItem } from '@material-ui/core';
import { PersonOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import labels from '../constants/labels/UserProfileMenu.json';
import UserLogin from './common/data/UserLogin';
import routes from '../constants/routes';
import { getLastestNewsDate, loadTeamNews } from './common/TeamNewsUtil';
import MyStore from "../actions/MyStore";
import appVersionSpecific from '../appVersionSpecific.ortho';
import buildInfo from '../buildInfo.json';

const useStyles = makeStyles(() => ({
  accountDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: '0'
  }
}));

const UserProfileMenu = () => {
  const classes = useStyles();
  const [profileMenuOpened, setProfileMenuOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [badgeVisible, setBadgeVisibility] = useState(true);

  useEffect(() => {
    loadBadgeVisibility();
  }, []);

  const loadBadgeVisibility = async () => {
    const latestVersionNewsDisplayed = MyStore.get(appVersionSpecific.electronStoreLatestVersionNewDisplayed, '');
    const latestVersionTeamNewsDisplayed = MyStore.get(appVersionSpecific.electronStoreLatestNewsDisplayed, 0);
    const { payload } = await loadTeamNews();
    const postingDate = getLastestNewsDate(payload);
    setBadgeVisibility(
      buildInfo.version !== latestVersionNewsDisplayed || Number(postingDate) !== Number(latestVersionTeamNewsDisplayed)
    );
  };

  const logout = async () => {
    await UserLogin.logout();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const goToProfile = () => {
    history.push({ pathname: routes.USER.PROFILE, state: { selectedTab: 0 } });
    setProfileMenuOpened(false);
  };

  return (
    <div className={classes.accountDiv}>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={e => {
          setProfileMenuOpened(true);
          setAnchorEl(e.currentTarget);
        }}
        color="inherit"
      >
        <Badge
          color="error"
          badgeContent=""
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          invisible={profileMenuOpened || !badgeVisible}
        >
          <PersonOutline style={{ fontSize: '2.5rem' }} />
        </Badge>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={profileMenuOpened}
        onClose={() => setProfileMenuOpened(false)}
      >
        <Badge badgeContent="" color="error" invisible={!badgeVisible}>
          <MenuItem onClick={goToProfile}>{labels.profile}</MenuItem>
        </Badge>
        <MenuItem onClick={logout}>{labels.logout}</MenuItem>
      </Menu>
    </div>
  );
};

export default UserProfileMenu;
