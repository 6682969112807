import {
  Card,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { intersection, not, union } from './util';
import labels from '../../../constants/labels/configuration/memorizedTexts/CustomList.json';

type Props = {
  title: string,
  testList: Array<{ testId: string, testCode: string, testLabel: string, childLabel: string }>,
  items: Array<string>,
  checked: Array,
  setChecked: () => void
};

const CustomList = ({ title, testList, items, checked, setChecked }: Props) => {
  const [testOpened, setTestOpened] = useState([]);
  const [groupedItems, setGroupedItems] = useState({});

  useEffect(() => {
    const byGroup = testList
      .filter(({ testId }) => items.includes(testId))
      .reduce((acc, currentValue) => {
        acc[currentValue.testCode] = [...(acc[currentValue.testCode] || []), currentValue];
        return acc;
      }, {});
    setGroupedItems(byGroup);
  }, [testList, items]);

  const handleToggle = testId => () => {
    if (checked.includes(testId)) {
      setChecked([...checked.filter(e => e !== testId)]);
    } else {
      setChecked([...checked, testId]);
    }
  };

  const numberOfChecked = i => intersection(checked, i).length;

  const handleToggleAll = i => () => {
    if (numberOfChecked(items) === i.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleToggleSubList = i => () => {
    if (intersection(checked, i).length === i.length) {
      setChecked(not(checked, i));
    } else {
      setChecked(union(checked, i));
    }
  };

  const toggleTestCodeOpened = testCode => {
    if (testOpened.includes(testCode)) {
      setTestOpened([...testOpened.filter(e => e !== testCode)]);
    } else {
      setTestOpened([...testOpened, testCode]);
    }
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${labels.selected}`}
      />
      <Divider />
      <List dense component="div" role="list">
        {Object.entries(groupedItems).map(([testCode, children]) => {
          const allTestIdOfTestCode = testList
            .filter(({ testId }) => items.includes(testId))
            .filter(({ testCode: tcList }) => tcList === testCode)
            .map(({ testId }) => testId);
          return (
            <Fragment key={testCode}>
              <ListItem button>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={
                      numberOfChecked(allTestIdOfTestCode) === allTestIdOfTestCode.length &&
                      allTestIdOfTestCode.length !== 0
                    }
                    indeterminate={
                      numberOfChecked(allTestIdOfTestCode) !== allTestIdOfTestCode.length &&
                      numberOfChecked(allTestIdOfTestCode) !== 0
                    }
                    onChange={handleToggleSubList(allTestIdOfTestCode)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': testCode }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={groupedItems[testCode][0].testLabel}
                  onClick={() => toggleTestCodeOpened(testCode)}
                  secondary={`${numberOfChecked(allTestIdOfTestCode)}/${allTestIdOfTestCode.length} ${labels.selected}`}
                />
                <IconButton edge="end" onClick={() => toggleTestCodeOpened(testCode)}>
                  {testOpened.includes(testCode) ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </ListItem>
              <Collapse in={testOpened.includes(testCode)} timeout="auto" unmountOnExit>
                {children.map(({ testId, childLabel }) => (
                  <ListItem key={testId} role="listitem" button onClick={handleToggle(testId)}>
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.includes(testId)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': testId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={testId} primary={childLabel} />
                  </ListItem>
                ))}
              </Collapse>
            </Fragment>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
};

export default CustomList;
