import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  List,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GetApp, Storage, Unarchive } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import {useLocation} from "react-router-dom";
import {useHistory} from "react-router";

import MemorizedTexts from '../../components/patient/comments/memorizedTexts/MemorizedTexts';
import User from '../../components/common/data/User';
import CheckupComments from '../../components/configuration/memorizedTexts/commentType/CheckupComments';
import labels from '../../constants/labels/configuration/memorizedTexts/MemorizedTextsPage.json';
import MemorizedTextsCopy from '../../components/configuration/memorizedTexts/MemorizedTextsCopy';
import Memo from '../../components/common/data/Memo';
import ReportGeneratorUtil from '../../components/patient/report/ReportGeneratorUtil';
import Loader from '../../components/common/Loader';
import MemorizedTextSearch from './MemorizedTexts/MemorizedTextSearch';
import { Warning, useWarnings } from '../../components/common/Warning';
import defaultMemorizedTextsByKey from '../../constants/defaultMemorizedTextsByKey.json';
import { getKeyFromTestCodeAndId } from '../../components/common/data/MemoUtil';
import TestComments from "../../components/configuration/memorizedTexts/commentType/TestComments";
import CheckupModelStructureComments
  from "../../components/configuration/memorizedTexts/commentType/CheckupModelStructureComments";
import TestCommentSubTestSelection
  from "../../components/configuration/memorizedTexts/commentType/TestCommentSubTestSelection";
import mtpStyle from "../../components/configuration/memorizedTexts/MemorizedTextsPageStyle";
import CheckupModelStructureElementsSelection
  from "../../components/configuration/memorizedTexts/commentType/CheckupModelStructureElementsSelection";
import routes from "../../constants/routes";
import { download } from '../../actions/FileImportExport';

const useStyles = makeStyles(theme => ({
  borderRadius8: {
    '& > div': {
      borderRadius: '8px'
    }
  },
  ...mtpStyle(theme)
}));

const MemorizedTextsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { state: { selectedTestCode: defaultSelectedTestCode = {} } = {} } = location;

  const [selectedTestCode, setSelectedTestCode] = useState(defaultSelectedTestCode);
  const [selectedMemoKey, setSelectedMemoKey] = useState();
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [copyData, setCopyData] = useState(null);
  const [importResult, setImportResult] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMemoKeys, setFilteredMemoKeys] = useState([]);
  const [selectedCategoryUuid, setSelectedCategoryUuid] = useState(null);
  const [memoKeyWithTexts, setMemoKeyWithTexts] = useState([]);

  const [warnings, addWarning, clearWarnings] = useWarnings();

  useEffect(() => {
    const loadMemoKeyWithTexts = async () => {
      const allMemos = await Memo.findAll();
      const map = allMemos
        .map(({_id, val})=>({id:_id, texts: val.nodes.flatMap(({texts})=> texts)}))
        .filter(({texts}) => texts.length)
        .map(({id}) => id);
      console.log({allMemos, map})
      await setMemoKeyWithTexts(map)
    };

    loadMemoKeyWithTexts().then();
  }, []);


  const importTexts = async (event) => {
    const filePath = event.target.files[0]
    if (!filePath) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = async () => {

      setImportResult('running');
      const fileContent = fileReader.result;
      // console.log({ fileContent });
      try {
        const contentAsString = await ReportGeneratorUtil.decryptMemorizedTextsFile(fileContent);
        // console.log({ contentAsString });
        if (contentAsString) {
          const content = JSON.parse(contentAsString);
          // console.log({ content });
          if (content && content.data && content.data.data) {
            await Memo.deduplicateAndAddMemos(content.data.data);
            if (selectedMemoKey) {
              const key = JSON.parse(JSON.stringify(selectedMemoKey));
              await setSelectedMemoKey(null);
              setTimeout(() => {
                setSelectedMemoKey(key);
              }, 100);
            }
            setImportResult('success');
          } else {
            setImportResult('error');
          }
        }
      } catch (e) {
        console.error('could not load file', { e });
        setImportResult('error');
      }
    };
    fileReader.readAsArrayBuffer(filePath);
  };

  const importDefaultMemos = async () => {
    setImportResult('running');
    const data = Object.entries(defaultMemorizedTextsByKey).map(([key, value]) => ({ key, value: { nodes: value } }));
    await Memo.deduplicateAndAddMemos(data);
    if (selectedMemoKey) {
      const key = JSON.parse(JSON.stringify(selectedMemoKey));
      await setSelectedMemoKey(null);
      setTimeout(() => {
        setSelectedMemoKey(key);
      }, 100);
    }
    setImportResult('success');
  };

  const exportTexts = async () => {
    await clearWarnings();

    setImportResult('running');
    const allMemos = await Memo.findAll();
    const data = allMemos.map(({ _id, k, val: { enabledPersonalInfoReplacement, nodes } }) => ({
      key: k || _id,
      value: { enabledPersonalInfoReplacement, nodes: nodes.map(({ texts = [], ...rest }) => ({ texts, ...rest })) }
    }));

    const { firstName, lastName } = await User.getUser(['firstName', 'lastName']);

    const memos = {
      data,
      firstName,
      lastName,
      exportDate: Date.now(),
      // orthoscribeVersion: remote.app.getVersion()
    };

    const fileReader = new FileReader();
    fileReader.onload = async event => {
      try {
        download("mesTextesMémorisés", event.target.result);
        setImportResult('exportSuccess');
      } catch (e) {
        await addWarning(labels.errorExportingTexts);
        setImportResult(null);
      }
    };
    const message = await ReportGeneratorUtil.encryptMemorizedTextsFile({ memos });
    if (message) {
      fileReader.readAsArrayBuffer(message);
    }
  };

  const changeSelectedTestCodeAndMemoKey = (newSelectedTestCode, newSelectedMemoKey) => {
    history.push({ pathname: routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MEMORIZED_TEXTS), state: { selectedTestCode: newSelectedTestCode } });
    setSelectedTestCode(newSelectedTestCode);
    setSelectedMemoKey(newSelectedMemoKey);
  }

  const showCopyDialog = (category, texts) => {
    console.log({ category, texts });
    setCopyData({ category, texts });
    setCopyDialogOpen(true);
  };

  const confirmCopy = async destinationTestIds => {
    const keys = destinationTestIds.map(s => getKeyFromTestCodeAndId(s));
    const { label: categoryLabel } = copyData.category;
    const { texts } = copyData;

    // eslint-disable-next-line no-restricted-syntax
    for await (const key of keys) {
      const memo = await Memo.findByKey(key);
      const categoryUuid = Memo.findCategoryUuidByLabel(memo, categoryLabel);
      if (categoryUuid) {
        await Memo.addTextsToCategory(memo, texts, categoryUuid);
      } else {
        const { updatedMemo, newCategory } = await Memo.addCategory(memo, categoryLabel);
        await Memo.addTextsToCategory(updatedMemo, texts, newCategory.uuid);
      }
    }
  };

  const isSelectedTestCodeTestIdentifier = stc => stc && stc.children && stc.children.length > 0;

  const isSelectedTestCodeCheckupModelStructure = stc => stc && stc.elements && stc.elements.length > 0;

  const searchTermChanged = useCallback(({ searchTerm: newSearchTerm, filteredMemoKeys: newFilteredMemoKeys }) => {
    setSearchTerm(newSearchTerm);
    setFilteredMemoKeys(newFilteredMemoKeys);
  }, []);

  return (
    <Grid container style={{ overflow: 'hidden' }} spacing={2}>
      <Grid item md={12}>
        <Typography variant="h6">{labels.mainHeader}</Typography>
        <Typography variant="subtitle2">{labels.secondHeader}</Typography>
        <Typography variant="body2">{labels.thirdHeader}</Typography>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Warning warnings={warnings} />
      {(!importResult || importResult !== 'running') && (
        <Grid item container md={12} spacing={2}>
          <Grid item md={6}/>
          <Grid item md={2}>
            <Button variant="contained" color="secondary" startIcon={<Storage />} onClick={importDefaultMemos}>
              {labels.importDefaultTexts}
            </Button>
          </Grid>
          <Grid item md={2}>
            <Button variant="contained" color="primary" startIcon={<Unarchive />} onClick={exportTexts}>
              {labels.exportModel}
            </Button>
          </Grid>
          <Grid item md={2}>
            <input
              id="contained-button-file"
              type="file"
              hidden
              onChange={importTexts}
              accept=".orthoform"
            />
            <label htmlFor="contained-button-file">
              <Button variant="outlined" component="span" startIcon={<GetApp />}>
                {labels.importModel}
              </Button>
            </label>
          </Grid>
        </Grid>
      )}
      {importResult && (
        <Grid item md={12}>
          {importResult === 'running' && <Loader />}
          {importResult !== 'running' && (
            <Alert
              severity={importResult}
              onClose={() => {
                setImportResult(null);
              }}
            >
              {labels.importResult[importResult]}
            </Alert>
          )}
        </Grid>
      )}
      <MemorizedTextSearch
        searchTermChanged={searchTermChanged}
      />
      <Grid item md={3}>
        <Typography variant="h4">{labels.titles.commentType}</Typography>
        <List component="nav" aria-label="main" className={classes.mainList}>
          <CheckupComments
            changeSelectedTestCodeAndMemoKey={changeSelectedTestCodeAndMemoKey}
            filteredMemoKeys={filteredMemoKeys}
            selectedMemoKey={selectedMemoKey}
          />
          <TestComments
            changeSelectedTestCodeAndMemoKey={changeSelectedTestCodeAndMemoKey}
            filteredMemoKeys={filteredMemoKeys}
            selectedTestCode={selectedTestCode}
          />
          <CheckupModelStructureComments
            changeSelectedTestCodeAndMemoKey={changeSelectedTestCodeAndMemoKey}
            filteredMemoKeys={filteredMemoKeys}
            selectedTestCode={selectedTestCode}
          />
        </List>
      </Grid>
      {isSelectedTestCodeTestIdentifier(selectedTestCode) && (
        <TestCommentSubTestSelection
          filteredMemoKeys={filteredMemoKeys}
          selectedTestCode={selectedTestCode}
          setSelectedMemoKey={setSelectedMemoKey}
          selectedMemoKey={selectedMemoKey}
        />
      )}
      {
        isSelectedTestCodeCheckupModelStructure(selectedTestCode) &&
        <CheckupModelStructureElementsSelection
          filteredMemoKeys={filteredMemoKeys}
          selectedTestCode={selectedTestCode}
          setSelectedMemoKey={setSelectedMemoKey}
          selectedMemoKey={selectedMemoKey}
          memoKeyWithTexts={memoKeyWithTexts}
        />
      }
      <Grid
        item
        md={(isSelectedTestCodeTestIdentifier(selectedTestCode) || isSelectedTestCodeCheckupModelStructure(selectedTestCode)) ? 6 : 9}
        className={classes.borderRadius8}
      >
        {selectedMemoKey && (
          <Fragment>
            <Typography variant="h4">{labels.titles.memorizedTexts}</Typography>
            <MemorizedTexts
              key={`${selectedMemoKey}-${searchTerm}`}
              memoKey={selectedMemoKey}
              bigSize
              byCategory
              showHistory={false}
              onTextSelected={() => {}}
              copyAction={showCopyDialog}
              withCommandPanel={false}
              defaultSearchTerm={searchTerm}
              selectedCategoryUuid={selectedCategoryUuid}
              onCategorySelected={setSelectedCategoryUuid}
            />
          </Fragment>
        )}
      </Grid>
      <MemorizedTextsCopy
        key={`${JSON.stringify(copyData)}`}
        open={copyDialogOpen}
        setOpen={setCopyDialogOpen}
        showOnlyUserSelectedTestsDefault
        confirmCopy={confirmCopy}
        copyData={copyData}
      />
    </Grid>
  );
};

export default MemorizedTextsPage;
