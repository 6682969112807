import labels from "../../constants/labels/patient/TestListing.json";
import {Box, Grid, ListItem, ListItemIcon, ListItemText, Tooltip, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import routes from "../../constants/routes";
import {Edit, Person} from "@material-ui/icons";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import TestListingStyle from "./test/TestListingStyle.json";
import ListStyle from "../../constants/styles/ListStyle.json";
import {countEnabledTestForTestCode, countTestDoneForTestCode} from "./test/TestUtils";

const useStyle = makeStyles(() => ({ ...TestListingStyle, ...ListStyle }));


export function TestListingElement(props: {
    patient: any,
    test: any,
    bilanId: any
}) {
    const classes = useStyle();
    const secondaryLabel = !(props.test.available || true) ? labels.newerTestVersion : '';
    const results = props.patient.cotation.results || {};
    const {count: doneTestCount} = countTestDoneForTestCode(results, props.test);
    const enabledTestCount = countEnabledTestForTestCode(results, props.test);
    return <Tooltip title={labels.clickToFill}>
        <Box boxShadow={2} className={classes.listItemBox}>
            <ListItem
                button
                component={Link}
                to={routes.PATIENT.TESTS.getForPath({
                    path: routes.PATIENT.TESTS.FILLING,
                    patientId: props.patient._id,
                    testCode: props.test.testCode,
                    bilanId: props.bilanId
                })}
                className={classes.orthoscribeList}
            >
                <ListItemIcon>{props.test.custom ? <Person/> : <Edit/>}</ListItemIcon>
                <ListItemText
                    primary={props.test.label}
                    secondary={secondaryLabel}
                    classes={{secondary: classes.secondary}}
                />
                <Grid item xs={1}>
                    <Typography variant="body1">{doneTestCount}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1">{enabledTestCount}</Typography>
                </Grid>
            </ListItem>
        </Box>
    </Tooltip>;
}