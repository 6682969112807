import React, { useState, useEffect, useCallback } from 'react';
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Forward, Search } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import labels from '../../../constants/labels/patient/RenewalCreation.json';
import routes from '../../../constants/routes';
import PatientListRow from './PatientListRow';
import Patient from '../../common/data/Patient';
import log from '../../Logger';
import BackButton from '../../common/BackButton';

type PatientListProps = {
  patients: Array<>,
  choose: {
    patientId: string,
    route: string
  }
};

const useStyles = makeStyles({
  patientList: {
    '& > :nth-child(odd)': {
      backgroundColor: 'lightgrey',
      borderRadius: '15px'
    }
  }
});

const PatientList = ({ patients, choose }: PatientListProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} container>
      <List dense component="nav" style={{ width: '100%' }} className={classes.patientList}>
        {patients.map(({ _id: originPatientId, firstName, lastName, typeBilan, rdvDay }) => (
          <ListItem
            key={originPatientId}
            button
            component={Link}
            to={routes.PATIENT.getForPath({
              path: choose.route,
              patientId: choose.patientId,
              originPatientId
            })}
          >
            <PatientListRow
              patientId={originPatientId}
              firstName={firstName}
              lastName={lastName}
              typeBilan={typeBilan}
              rdvDay={rdvDay}
            />

            <ListItemSecondaryAction>
              <Tooltip title={labels.choose}>
                <IconButton>
                  <Forward />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

type Props = {
  match: ?{ params: ?{ patientId: ?string } }
};

const RenewalCheckupSelection = ({
  match: {
    params: { patientId }
  }
}: Props) => {
  const [patients, setPatients] = useState([]);
  const [searchField, setSearchField] = useState('');

  const handleChange = useCallback(async event => {
    const { value } = event.target;
    setSearchField(value);
    if (value === '') {
      setPatients([]);
      return;
    }
    try {
      const { patients: mPatients } = await Patient.getPatientList(false, value, 'lastName', 'asc');
      const { patients: archivedPatients } = await Patient.getPatientList(true, value, 'lastName', 'asc');
      setPatients([...mPatients, ...archivedPatients].filter(({ _id }) => _id !== patientId));
    } catch (e) {
      log.error('error searching in db', e);
      setSearchField(value);
    }
  }, [patientId]);

  useEffect(() => {

    const loadPatient = async () => {
      const patient = await Patient.retrievePatient(patientId);
      console.log({ patient });
      if (patient) {
        const { firstName } = patient;
        await handleChange({ target: { value: `${firstName}` } });
      }
    };

    if (patientId !== 'new') {
      loadPatient().then();
    }
  }, [patientId, handleChange]);

  const choose = mPatientId => ({
    patientId: mPatientId,
    route: mPatientId === 'new' ? routes.PATIENT.RENEWAL.CREATE : routes.PATIENT.RENEWAL.LINK
  });

  const subLabels = patientId === 'new' ? labels.create : labels.convert;
  return (
    <Grid container>
      <BackButton />
      <Grid item xs={12}>
        <Typography variant="h3">{subLabels.subTitle}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Paper style={{ width: '100%', padding: '0.5em', marginTop: '0.5em' }}>
        <Grid item xs={12}>
          <Typography variant="h3">{subLabels.label}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="search"
            value={searchField}
            name="searchField"
            variant="outlined"
            placeholder={labels.placeholder}
            fullWidth
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        {patients.length > 0 ? (
          <PatientList {...{ patients, choose: choose(patientId) }} />
        ) : (
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant="body1">{labels.row0}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{labels.row1}</Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default RenewalCheckupSelection;
