import React, { Component } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from '@material-ui/core';

import AnamnesisElement from './AnamnesisElement';
import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis.json';
import log from '../../../Logger';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class AntecedentFamiliaux extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    log.info(props.element);
    return <AnamnesisElement {...props} editableElement={readOnlyElement} readOnlyElement={readOnlyElement} />;
  }
}

function readOnlyElement() {
  return (
    <Grid container>
      <Grid item xs={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{labels.tabs.form.right.antecedent.scholarDifficulty}</FormLabel>
          <FormGroup>
            <FormControlLabel control={<Checkbox disabled />} label={labels.tabs.form.right.antecedent.mother} />
            <FormControlLabel control={<Checkbox disabled />} label={labels.tabs.form.right.antecedent.father} />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{labels.tabs.form.right.antecedent.orthophonicsAntecedent}</FormLabel>
          <FormGroup>
            <FormControlLabel control={<Checkbox disabled />} label={labels.tabs.form.right.antecedent.mother} />
            <FormControlLabel control={<Checkbox disabled />} label={labels.tabs.form.right.antecedent.father} />
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}
