const defaultAnamnesis = {
  /* Le nom par défaut de l'anamnese, tel qu'elle apparaitra dans la liste de l'orthophoniste  */
  name: 'Anamnèse standard',

  /* Ne pas toucher aux trois champs ci-dessous */
  customDefinitions: {},
  customValues: {},
  values: {},

  modificationDate: 0,

  /* Le template de mail envoyé par l'orthophoniste */
  mailSubject: 'Rendez-vous / séance orthophonie',
  mailBody:
    "<div>@CIVILITE,<br /><br />Suite &agrave; notre entretien, je vous confirme que je vous recevrai avec @PRENOM pour un bilan initial le @JOUR &agrave; @HEURE (dur&eacute;e approximative : @DUREE).</div><p>Je vous remercie de bien vouloir pr&eacute;-remplir autant que possible le dossier de @PRENOM avant notre rendez-vous en vous connectant &agrave; l'adresse web ci-dessous.</p><p> @REF </p><p><b>Informatique et libertés</b></p><p>Ce cabinet dispose d’un système informatique destiné à faciliter la gestion des dossiers des patients et à assurer la facturation des actes et la télétransmission des feuilles de soins aux caisses de sécurité sociale.</p><p>Sauf opposition justifiée de votre part, les informations recueillies sur ce formulaire ainsi que lors de votre consultation feront l’objet d’un enregistrement informatique réservé à l’usage de votre professionnel de santé.</p><p>Votre professionnel de santé traitant se tient à votre disposition pour vous communiquer ces renseignements ainsi que toutes informations nécessaires sur votre état de santé (*).</p><p>Conformément au Règlement général sur la protection des données (RGPD), vous pouvez exercer votre droit d'accès aux données vous concernant, les faire rectifier, effacer, limiter ou vous opposer au traitement en contactant votre professionnel de santé par écrit.</p><p>Cordialement</p><p>@OrthoPrenom @OrthoNom</p><p>(*) Tout médecin désigné par vous peut également prendre connaissance de l’ensemble de votre dossier médical.</p><p>RGPD : RÈGLEMENT (UE) 2016/679 DU PARLEMENT EUROPÉEN ET DU CONSEIL du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des données)</p>",
  loginMessage:
    "Bonjour. <p>Votre enfant a prochainement un rendez vous pour un bilan orthophonique initial.  Afin de préparer au mieux ce bilan, il est nécessaire que vous consacriez un peu de temps (15 minutes sont suffisantes) à remplir son dossier. Pour un bilan de qualité, il est important que votre orthophoniste puisse mieux connaître votre enfant, son histoire et son contexte de vie. Ce dossier (personnel) sera revu avec vous le jour du bilan par votre praticien afin d'y apporter le maximum d'informations et de précisions.</p> <p>N'oubliez pas de transmettre ce dossier à votre orthophoniste en validant votre réponse sur la dernière page</p> <p>Les données saisies sont strictement confidentielles et ne seront communiquées à aucune autre personne que votre orthophoniste. Merci pour votre collaboration.</p>",

  /* "definitions" contient chaque section du formulaire */
  definitions: [
    /* Les informations personnelles */
    {
      id: 'infopers', // ne pas toucher, valeur interne
      titreCourt: 'Infos',
      titre: 'Informations Personnelles', // Un titre, éventuellement affiché
      /* Contient le texte d'aide situé à gauche du formulaire */
      description:
        "Vos informations personnelles permettent à votre orthophoniste d'avoir toutes les informations nécessaires pour vous contacter.",

      rows: [
        [{ type: 'header', label: 'Vos informations personnelles' }],
        [
          {
            id: 'prenom',
            type: 'icon-prepend',
            icon: 'icon-user',
            label: "Prénom de l'assuré(e)",
            span: 6,
            placeholder: 'Prénom'
          },
          {
            id: 'nom',
            type: 'icon-prepend',
            icon: 'icon-user',
            label: "Nom de l'assuré(e)",
            span: 6,
            tip: 'Un exemple de tip',
            placeholder: 'Nom'
          }
        ],
        [
          {
            id: 'email',
            type: 'icon-prepend',
            icon: 'icon-envelope',
            label: 'Votre email',
            span: 6,
            tip: 'Un exemple de tip',
            placeholder: 'votreadresse@email.fr'
          },
          {
            id: 'relationassure',
            label: "Relation de l'assuré(e) avec le patient:",
            type: 'radios',
            span: 6,
            shortLabel: 'Relation assuré/patient',
            value: false,
            options: [
              { label: 'Mère', id: 'mere' },
              { label: 'Père', id: 'pere' },
              {
                label: 'Autre (précisez) :',
                id: 'autre',
                type: 'freevalue'
              }
            ]
          }
        ],
        [
          {
            id: 'adresse',
            label: 'Adresse postale',
            type: 'textarea',
            span: 12,
            placeholder: "L'adresse principale, ou l'une des adresses principales de résidence"
          }
        ],
        [
          {
            id: 'codepostal',
            type: 'icon-prepend',
            mask: '99999',
            icon: 'icon-flag',
            label: 'Code postal',
            span: 6,
            placeholder: '00000'
          },
          {
            id: 'ville',
            type: 'icon-prepend',
            icon: 'icon-home',
            label: 'Ville',
            span: 6,
            placeholder: 'Ville ou commune'
          }
        ],
        [
          {
            id: 'telephone1',
            type: 'icon-prepend',
            mask: '99 99 99 99 99',
            icon: 'icon-phone',
            label: 'Téléphone portable',
            span: 6
          },
          {
            id: 'telephone2',
            type: 'icon-prepend',
            mask: '99 99 99 99 99',
            icon: 'icon-phone',
            label: 'Téléphone fixe',
            span: 6
          }
        ],
        [
          {
            id: 'professionparent1',
            type: 'input',
            label: 'Profession du parent assuré(e)',
            span: 6,
            placeholder: 'Profession'
          },
          {
            id: 'professionparent2',
            type: 'input',
            label: "Profession de l'autre parent",
            span: 6,
            placeholder: 'Profession'
          }
        ]
      ]
    },

    {
      id: 'infoadmin',
      titreCourt: 'Administratif',
      titre: 'Informations Administratives',
      description:
        'Ces informations administratives sont importantes car elles permettent la prise en compte du bilan orthophonique dans le cadre du parcours de soin.',

      rows: [
        [{ type: 'header', label: 'Le médecin prescripteur' }],
        [{ id: 'medecintraitant', type: 'icon-prepend', icon: 'icon-stethoscope', label: 'Nom du médecin' }],
        [{ id: 'adressemedecin', label: 'Adresse du médecin', type: 'textarea' }],
        [
          {
            id: 'telephonemedecin',
            type: 'icon-prepend',
            mask: '99 99 99 99 99',
            icon: 'icon-phone',
            label: 'Téléphone du médecin',
            span: 6
          },
          {
            id: 'emailmedecin',
            type: 'icon-prepend',
            icon: 'icon-envelope',
            label: 'Email du médecin',
            span: 6,
            placeholder: 'medecin@email.fr'
          }
        ],
        [{ type: 'header', label: 'Sécurité sociale' }],
        [
          {
            id: 'numsecu',
            type: 'numsecu',
            icon: 'icon-hospital',
            label: 'Numéro de sécurité sociale (NIR)',
            placeholder: 'Sur 13 chiffres',
            span: 6
          }
        ]
      ]
    },

    {
      id: 'plainte',
      titreCourt: 'Motif',
      titre: 'Motif du bilan',
      description: "Il s'agit ici d'identifier le trouble dont souffre votre enfant et la personne qui l'a remarqué.",

      rows: [
        [{ type: 'header', label: 'Rappel' }],
        [{ id: 'plainte', type: 'info', label: 'Motif de la visite' }],
        [
          {
            id: 'plainteprecisions',
            type: 'textarea',
            label: 'Si vous souhaitez ajouter des précisions sur le motif de la visite:'
          }
        ],
        [
          {
            id: 'origineplainte',
            label: "Qui est à l'origine de la plainte ?",
            type: 'inlineradios',
            value: 'medecin',
            options: [
              { label: 'Vous / votre entourage familial', id: 'famille' },
              { label: "L'enseignant", id: 'instituteur' },
              { label: 'Votre médecin', id: 'medecin' },
              { label: 'un spécialiste orthoptiste', id: 'orthoptiste' },
              { label: 'un spécialiste pédopsychiatre ou pédopsychologue', id: 'pedopsy' },
              { label: 'Autre (précisez):', id: 'autre', type: 'freevalue' }
            ]
          }
        ]
      ]
    },

    {
      id: 'famille',
      titreCourt: 'Famille',
      titre: 'Environnement familial',

      description:
        "Le contexte de vie est important pour contextualiser les troubles éventuels de votre enfant. Il s'agit ici notamment de décrire les rapports de celui-ci avec ses frères et soeurs.",

      rows: [
        [{ type: 'header', label: 'Résidence familiale' }],

        [
          {
            id: 'residence',
            label: 'Lieu de résidence:',
            type: 'radios',
            options: [
              { label: 'Chez ses parents (non séparés)', id: 'parents' },
              { label: 'En garde alternée', id: 'gardealternee' },
              { label: 'Résidence principale chez la mère, avec droit de visite "standard"', id: 'mere' },
              { label: 'Résidence principale chez le père, avec droit de visite "standard"', id: 'pere' },
              { label: 'Autre (précisez) :', id: 'autre', type: 'freevalue' }
            ]
          }
        ],
        [{ type: 'header', label: 'Fratrie' }],
        [
          {
            id: 'fratrie',
            label: "Frères ou soeurs résidant habituellement avec l'enfant",
            type: 'fratrie',
            value: { nb: 0, detail: [] }
          }
        ],
        [
          {
            id: 'fratrieprecisions',
            type: 'textarea',
            label:
              "Décrivez les rapports de votre enfant avec ses frères et soeurs ou toutes autres informations utiles pour comprendre l'épanouissement de votre enfant au sein de la famille (faits marquants, joue-t-il avec ses frères et soeurs, les voit-il souvent, etc.)"
          }
        ]
      ]
    },

    {
      id: 'antecedentsfamiliaux',
      titreCourt: 'Antécédents',
      titre: 'Antécédents familiaux',
      description: "Il s'agit de connaître les antécédents familiaux, d'un point de vue scolaire et orthophonique.",

      rows: [
        [{ type: 'header', label: 'Scolaires/orthophoniques' }],
        [{ id: 'antecedents', type: 'antecedentsfamiliaux' }],
        [{ id: 'antecedentsprecision', type: 'textarea', label: 'Précisions si nécessaire' }]
      ]
    },

    {
      id: 'antecedentsmedicaux',
      titreCourt: 'Médical',
      titre: 'Antécédents médicaux',
      description:
        "Il s'agit de connaître les antécédents médicaux de votre enfant qui pourraient avoir un impact sur son problème actuel.",

      rows: [
        [{ type: 'header', label: 'Antécédents médicaux' }],
        [
          {
            id: 'lunettes',
            type: 'radios',
            span: 6,
            label: 'Port de verres correcteurs',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui, précisez:', type: 'freevalue' }
            ]
          },
          {
            id: 'allergies',
            type: 'radios',
            span: 6,
            label: 'Allergies',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui, précisez:', type: 'freevalue' }
            ]
          }
        ],
        [{ type: '---', label: 'Antécédents médicaux' }],
        [
          {
            id: 'hospitalisations',
            type: 'radios',
            span: 6,
            label: 'Hospitalisations',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui, précisez:', type: 'freevalue' }
            ]
          },
          {
            id: 'traitements',
            type: 'radios',
            span: 6,
            label: 'Traitements en cours',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui, précisez:', type: 'freevalue' }
            ]
          }
        ],
        [{ type: '---', label: 'Antécédents médicaux' }],
        [
          {
            id: 'diabolo',
            type: 'inlineradios',
            span: 6,
            label: 'Aérateur transtympaniques (yoyo/diabolo)',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui' }
            ]
          },
          {
            id: 'otitessereuses',
            type: 'inlineradios',
            span: 6,
            label: 'Otites séreuses',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui' }
            ]
          }
        ],
        [
          {
            id: 'otitesrepetition',
            type: 'inlineradios',
            span: 6,
            label: 'Otites à répétition',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui' }
            ]
          }
        ],
        [{ type: '---', label: 'Antécédents médicaux' }],
        [
          {
            id: 'bronchites',
            type: 'inlineradios',
            span: 6,
            label: 'Bronchites asthmatiformes',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui' }
            ]
          },
          {
            id: 'audiogramme',
            type: 'inlineradios',
            span: 6,
            label: 'A-t-il/elle eu un audiogramme ?',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui' }
            ]
          }
        ],
        [{ type: '---', label: 'Antécédents médicaux' }],
        [
          {
            id: 'suiviortho',
            type: 'radios',
            span: 6,
            label: 'Votre enfant a-t-il déjà été suivi par un orthophoniste ?',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui, par: ', type: 'freevalue' }
            ]
          },
          {
            id: 'suivitherap',
            type: 'radios',
            span: 6,
            label: 'Autre suivi thérapeutique en cours',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui, précisez:', type: 'freevalue' }
            ]
          }
        ]
      ]
    },

    {
      id: 'devpsycholang',
      titreCourt: 'Développement',
      titre: 'Développement psychomoteur et langagier',
      description:
        "Comment votre enfant a-t-il grandi dans ses apprentissages de la naissance à aujourd'hui ? Quel est son rythme et sa qualité de sommeil ?",

      rows: [
        [{ type: 'header', label: 'Développement psychomoteur et langagier' }],
        [
          {
            id: 'grossesse',
            type: 'inlineradios',
            label: 'Grossesse',
            span: 12,
            options: [
              { id: 'normale', label: 'Sans problème' },
              { id: 'complication', label: 'Complication', type: 'freevalue' }
            ]
          }
        ],
        [
          {
            id: 'terme',
            type: 'age',
            numberType: 'digits',
            unit: 'semaines',
            label: "Terme de la grossesse, en semaines d'aménorrhée (terme = 41 semaines)",
            span: 12
          }
        ],
        [
          {
            id: 'poidsnaissance',
            type: 'number',
            unit: 'kg',
            step: '0.1',
            label: 'Poids à la naissance',
            span: 6
          },
          {
            id: 'accouchement',
            type: 'inlineradios',
            span: 6,
            label: 'Accouchement',
            options: [
              { id: 'naturelle', label: 'Voies naturelles' },
              { id: 'césarienne', label: 'Césarienne' }
            ]
          }
        ],
        [
          {
            id: 'nuits',
            type: 'age',
            unit: 'mois',
            label: 'A fait ses nuits à',
            span: 6,
            invalid: 'Non acquis'
          },
          {
            id: 'lateralite',
            type: 'inlineradios',
            span: 6,
            label: 'Latéralité',
            options: [
              { id: 'droitier', label: 'Droitier' },
              {
                id: 'gaucher',
                label: 'Gaucher'
              },
              { id: 'ambidextre', label: 'Ambidextre' },
              { id: 'indeterm', label: 'Pas encore déterminé' }
            ]
          }
        ],
        [
          {
            id: 'allaitement',
            type: 'radios',
            span: 6,
            label: 'Allaitement (au sein)',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: "Oui, jusqu'à", type: 'freevalue' }
            ]
          },
          {
            id: 'bilinguisme',
            type: 'radios',
            span: 6,
            label: 'Parle une ou plusieurs autres langues',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui', type: 'freevalue' }
            ]
          }
        ],
        [
          {
            id: 'marche',
            type: 'age',
            unit: 'mois',
            label: 'Premiers pas à',
            span: 6,
            invalid: 'Non acquis'
          },
          { id: 'parle', type: 'age', unit: 'mois', label: 'Premiers mots à', span: 6, invalid: 'Non acquis' }
        ],
        [
          {
            id: 'succion',
            type: 'radios',
            span: 3,
            label: 'Succion...',
            options: [
              { id: 'tetine', label: 'Tétine' },
              { id: 'pouce', label: 'Pouce' }
            ]
          },
          {
            id: 'succionfin',
            type: 'radios',
            span: 9,
            label: "...jusqu'à",
            options: [
              { id: 'encore', label: "Encore aujourd'hui" },
              {
                id: 'jusqua',
                label: "Jusqu'à",
                type: 'freevalue'
              }
            ]
          }
        ],
        [
          { id: 'propre', type: 'age', unit: 'mois', label: 'Propre à', span: 6, invalid: 'Non acquis' },
          {
            id: 'niveaulangageecole',
            type: 'inlineradios',
            span: 6,
            label: "Niveau de langage à l'entrée à l'école",
            options: [
              { id: 'faible', label: 'Faible' },
              { id: 'normal', label: 'Normal' },
              {
                id: 'eleve',
                label: 'Élevé'
              }
            ]
          }
        ],
        [
          {
            id: 'age4pattes',
            type: 'age',
            unit: 'mois',
            label: 'À quel âge a-t-il fait du 4 pattes ?',
            span: 6,
            invalid: 'Non acquis'
          },
          {
            id: 'agelacet',
            type: 'age',
            unit: 'ans',
            label: 'À quel âge a-t-il su faire ses lacets ?',
            span: 6,
            invalid: 'Non acquis'
          }
        ],
        [
          {
            id: 'agevelo',
            type: 'age',
            unit: 'ans',
            label: 'À quel âge a-t-il su faire du vélo ?',
            span: 6,
            invalid: 'Non acquis'
          }
        ],
        [
          {
            id: 'heurestv',
            type: 'number',
            unit: 'h/semaine',
            label:
              'Combien de temps votre enfant passe-t-il devant un écran par semaine (TV/Ordi/console/tablettes...)',
            span: 12
          }
        ],
        [{ type: 'header', label: 'Sommeil' }],
        [
          { id: 'leversemaine', type: 'time', label: 'Heure du lever en semaine', span: 6 },
          { id: 'leverwe', type: 'time', label: 'Heure du lever le week-end', span: 6 }
        ],
        [
          { id: 'couchersemaine', type: 'time', label: 'Heure du coucher en semaine', span: 6 },
          { id: 'coucherwe', type: 'time', label: 'Heure du coucher le week-end', span: 6 }
        ],
        [
          {
            id: 'regularitecoucher',
            type: 'radios',
            span: 6,
            label: "L'heure du coucher est-elle :",
            options: [
              { id: 'tresreguliere', label: 'Très régulière' },
              {
                id: 'assezreguliere',
                label: 'Assez régulière'
              },
              { id: 'peureguliere', label: 'Peu régulière' }
            ]
          },
          {
            id: 'endormissementdifficile',
            type: 'radios',
            span: 6,
            label: "Votre enfant a-t-il des difficultés pour s'endormir le soir ?",
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui' }
            ]
          }
        ],
        [
          {
            id: 'bavenocturne',
            type: 'radios',
            span: 6,
            label: 'Votre enfant bave-t-il pendant son sommeil?',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui' }
            ]
          },
          {
            id: 'ronflements',
            type: 'radios',
            span: 6,
            label: 'Ronfle-t-il la nuit?',
            options: [
              { id: 'non', label: 'Non' },
              { id: 'oui', label: 'Oui' }
            ]
          }
        ]
      ]
    },

    {
      id: 'parcoursscolaire',
      titreCourt: 'Scolarité',
      titre: 'Parcours scolaire',
      description:
        "Votre enfant et l'école... <br/>Sélectionnez sa classe actuelle, cochez les cases qui correspondent au mieux selon vous au ressenti de votre enfant tout au long de sa scolarité ainsi qu'à ses résultats scolaires. <br/> Pensez à bien préciser les classes redoublées (dernière ligne). <br/>Vous pouvez alors m'envoyer vos réponses, simplement en cliquant sur le bouton 'Terminer et envoyer'. <br/>Je vous remercie pour votre collaboration et vous dis à bientôt pour le bilan de votre enfant !",

      rows: [[{ id: 'parcoursscolaire', type: 'parcoursscolaire' }]]
    },

    {
      id: 'inflib',
      titreCourt: 'Inf & libertés',
      titre: 'Informatique et Libertés',
      description: 'Informatique et Libertés',
      rows: [
        [
          {
            id: 'inflib2',
            type: 'info',
            label:
              'Ce cabinet dispose d’un système informatique destiné à faciliter la gestion des dossiers des patients et à assurer la facturation des actes et la télétransmission des feuilles de soins aux caisses de sécurité sociale. Sauf opposition justifiée de votre part, les informations recueillies sur ce formulaire ainsi que lors de votre consultation feront l’objet d’un enregistrement informatique réservé à l’usage de votre professionnel de santé. Votre professionnel de santé traitant se tient à votre disposition pour vous communiquer ces renseignements ainsi que toutes informations nécessaires sur votre état de santé(*).'
          }
        ],
        [
          {
            id: 'inflib3',
            type: 'info',
            label:
              "Conformément au Règlement général sur la protection des données (RGPD), vous pouvez exercer votre droit d'accès aux données vous concernant, les faire rectifier, effacer, limiter ou vous opposer au traitement en contactant votre professionnel de santé par écrit."
          }
        ],
        [
          {
            id: 'inflib4',
            type: 'info',
            label:
              "(*) Tout médecin désigné par vous peut également prendre connaissance de l’ensemble de votre dossier médical. RGPD : RÈGLEMENT (UE) 2016/679 DU PARLEMENT EUROPÉEN ET DU CONSEIL du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des données)"
          }
        ]
      ]
    }
  ]
};

export default defaultAnamnesis;
