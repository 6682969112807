import React, { Component } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import AnamnesisElement from './AnamnesisElement';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class Info extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    return (
      <AnamnesisElement
        {...props}
        editableElement={editableElement}
        readOnlyElement={readOnlyElement}
      />
    );
  }
}

function editableElement(element, handleChange) {
  const { label } = element;
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <TextField
          value={label}
          name="label"
          fullWidth
          onChange={handleChange}
          style={{ paddingBottom: '2rem' }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
}

function readOnlyElement(element) {
  const { label } = element;
  return <Typography variant="body1">{label}</Typography>;
}
