import _ from 'lodash';
import Patient from '../common/data/Patient';
import Comments from '../common/data/Comments';
import CustomTests from '../common/data/CustomTests';
import CheckUpModel from '../common/data/CheckUpModel';
import { includedTestsByName } from '../../constants/tests/tests';
import log from '../Logger';
import User from '../common/data/User';
import appVersionSpecific from '../../appVersionSpecific.ortho';
import addCustomTestAffectationsToCheckUpModel from './report/ModelCustomTestUtil';
import TestConfig from '../common/data/TestConfig';
import defaultReportGenerationOptions from '../../constants/user/defaultReportGenerationOptions';
import { bilanHasExalang, bilanHasExamath58, bilanIsExalangOnly, bilanIsExamath58Only } from './test/TestUtils';
import Anamnesis from '../common/data/Anamnesis';
import replaceCarriageReturnByLineBreakElement from "../common/CommentUtils";

const modele = appVersionSpecific.modeleBilanStandard;

export default class PatientContentUtil {
  static async getContent(patientId) {
    const patient = await Patient.retrievePatient(patientId);
    const newEmptyComments = await Comments.getNewEmptyOne();
    const { bilan, cotation, comments: reportComments = newEmptyComments, anamnesisText } = patient;

    const anamnesis = await this.getAnamnesis(patient);
    const testsConfigs = await this.getTestsConfig();
    const user = await PatientContentUtil.getUserValues();
    const { options, userReportGenerationOption } = this.getOptions(user, bilan);
    user['report-generation-options'] = userReportGenerationOption;
    const customTests = await CustomTests.findAll();
    const customTestsByUuid = customTests.reduce((accumulator, currentValue) => {
      accumulator[currentValue.testCode || currentValue.uuid] = currentValue;
      return accumulator;
    }, {});

    const checkupModels = await CheckUpModel.retrieveModelList();
    checkupModels.splice(0, 0, { _id: 0, ...modele });

    const { specificModel, userDefaultModel } = await this.getSpecificModel(user, customTests);
    user['model.default'] = userDefaultModel;

    if (!user['report.postAction']) {
      user['report.postAction'] = 'openfile';
    }

    if (user.email) {
      user.email = user.email.replace('@', '@@');
    }

    reportComments.summary.text = replaceCarriageReturnByLineBreakElement(reportComments.summary.text);
    reportComments.diagnosis.text = replaceCarriageReturnByLineBreakElement(reportComments.diagnosis.text);
    reportComments.patientCare.text = replaceCarriageReturnByLineBreakElement(reportComments.patientCare.text);
    reportComments.therapeuticPlan.text = replaceCarriageReturnByLineBreakElement(reportComments.therapeuticPlan.text);

    if (!reportComments.behaviours) {
      reportComments.behaviours = [];
    }

    Object.keys(cotation.results)
      .filter(testName => cotation.results[testName].behaviourComment)
      .filter(testName => !reportComments.behaviours.some(({ text }) => text === testName))
      .forEach(testName => {
        reportComments.behaviours.push({ isTestReference: true, text: testName });
      });

    Object.keys(cotation.results)
      .map(k => cotation.results[k])
      .filter(testResult => !!testResult.behaviourComment)
      .forEach(testResult => {
        // eslint-disable-next-line no-param-reassign
        testResult.behaviourComment = replaceCarriageReturnByLineBreakElement(testResult.behaviourComment);
      });

    Object.keys(cotation.results)
      .map(k => cotation.results[k])
      .flatMap(Object.entries)
      .filter(([, value]) => !!value.comment)
      .forEach(([, value]) => {
        if (value && value.comment && typeof value.comment === "string") {
          // eslint-disable-next-line no-param-reassign
          value.comment = replaceCarriageReturnByLineBreakElement(value.comment);
        }
      });

    let initialPatient;
    let initialCotation;
    let initialTestList;

    if (bilan.initialAssessmentUUID) {
      initialPatient = await Patient.retrievePatient(patient.initialPatientId);
      initialCotation = initialPatient.cotation;
      initialTestList = bilan.initialAssessmentTests.map(({ testCode }) => testCode);
    }

    const allTests = { ...includedTestsByName, ...customTestsByUuid };

    const content = {
      anamnesisText: replaceCarriageReturnByLineBreakElement(anamnesisText || ''),
      bilan: { ...bilan, reportOptions: options },
      patient,
      cotation,
      anamnesis,
      user,
      options,
      specificModel,
      customTestsByUuid,
      includedTestsByName: allTests,
      testsConfigs,
      reportComments,
      initialPatient,
      initialCotation,
      initialTestList,
      checkupModels,
      customTests
    };
    log.info({ content });

    return content;
  }

  static async getUserValues() {
    const user = await User.getUser([
      'option.cr.displayChrono',
      'option.cr.displayBars',
      'option.cr.filename.format',
      'report-generation-options',
      'model.default',
      'firstName',
      'lastName',
      'address',
      'zipCode',
      'city',
      'telFixe',
      'telPortable',
      'email',
      'titles',
      'titre',
      'customRtfList',
      'report.postAction',
      'report.path',
      'doctemplate.default'
    ]);
    log.info({ user });
    return user;
  }

  static async getSpecificModel(user, customTests) {
    if (user['model.default'] && user['model.default'] !== '0') {
      try {
        const specificModel = await CheckUpModel.get(user['model.default']);
        return { specificModel, userDefaultModel: user['model.default'] };
      } catch (e) {
        const specificModel = addCustomTestAffectationsToCheckUpModel(modele, customTests);
        return { specificModel, userDefaultModel: 0 };
      }
    } else {
      const specificModel = addCustomTestAffectationsToCheckUpModel(modele, customTests);
      return { specificModel, userDefaultModel: 0 };
    }
  }

  static async getTestsConfig() {
    const { docs } = await TestConfig.findAll();
    return docs.reduce((acc, testConfig) => {
      acc[testConfig.testName] = testConfig;
      return acc;
    }, {});
  }

  static getOptions(user, bilan) {
    let bilanReportOptions = bilan.reportOptions;
    while (typeof bilanReportOptions === 'string') {
      bilanReportOptions = JSON.parse(bilanReportOptions);
    }
    let userReportGenerationOption = user['report-generation-options'];
    while (typeof userReportGenerationOption === 'string') {
      userReportGenerationOption = JSON.parse(userReportGenerationOption);
    }
    userReportGenerationOption = _.merge({}, defaultReportGenerationOptions, userReportGenerationOption);
    const isExalangOnly = bilanIsExalangOnly(bilan);
    const hasExalang = bilanHasExalang(bilan);
    const hasExamath58 = bilanHasExamath58(bilan);
    const reportOptions = Object.assign(userReportGenerationOption, bilanReportOptions);
    const graphVScale = this.getGraphVScale(bilan);
    const options = _.merge({}, defaultReportGenerationOptions, {
      ...JSON.parse(JSON.stringify(reportOptions)),
      graphVScale,
      tableChartFormat: isExalangOnly ? 'simple' : reportOptions.tableChartFormat,
      mainChartFormat:
        (hasExalang || hasExamath58) && reportOptions.mainChartFormat === 'vertical-points'
          ? 'vertical-columns'
          : reportOptions.mainChartFormat
    });
    return { options, userReportGenerationOption };
  }

  static getGraphVScale(bilan) {
    if (bilanIsExamath58Only(bilan)) {
      return 'examath58';
    }
    if (bilanIsExalangOnly(bilan)) {
      return 'exalang';
    }
    return 'nearlog';
  }

  static async getAnamnesis(patient) {
    const { anamnese } = patient;
    if (!anamnese) {
      return Anamnesis.getNewEmptyOne({ patientId: patient.id });
    }
    return anamnese;
  }
}
