import React, { Fragment, useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router';

import { listAnsweredTests, listNonAffectedAnsweredTests } from '../test/TestUtils';
import labels from '../../../constants/labels/patient/report/NonFilledTestInModel.json';
import routes from '../../../constants/routes';

type Props = {
  cotation?:
    | {
        results: {}
      }
    | undefined,
  bilan?: { tests: Array } | undefined,
  checkupModel?:
    | {
        _id: number,
        elements: Array
      }
    | undefined
};

const NonFilledTestInModel = ({
  cotation: { results = {} },
  bilan: { tests = [] },
  checkupModel: { _id: checkupModelId = 0, elements = [] }
}: Props) => {
  const [showList, setShowList] = useState(false);
  const [answeredTests, setAnsweredTests] = useState([]);
  const [nonAffectedAnsweredTests, setNonAffectedAnsweredTests] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const updateDatas = async () => {
      const selectedTestCodes = tests.map(({ testCode }) => testCode);
      const filteredResults = Object.entries(results)
        .filter(([testCode]) => selectedTestCodes.includes(testCode))
        .reduce((acc, [testCode, value]) => {
          acc[testCode] = value;
          return acc;
        }, {});
      const at = listAnsweredTests(filteredResults);
      const naat = await listNonAffectedAnsweredTests(elements, at);
      setAnsweredTests(at);
      setNonAffectedAnsweredTests(naat);
    };

    updateDatas().then();
  }, [elements, results, tests]);

  console.log({ nonAffectedAnsweredTests, tests, results, elements });
  if (!nonAffectedAnsweredTests.length) return null;

  return (
    <Alert severity="error">
      <AlertTitle>{`${labels.warning}, ${nonAffectedAnsweredTests.length} épreuve${
        nonAffectedAnsweredTests.length > 1 ? 's' : ''
      } (sur ${answeredTests.length > 1 ? 'les' : ''} ${answeredTests.length} saisie${
        answeredTests.length > 1 ? 's' : ''
      }) n'${
        nonAffectedAnsweredTests.length > 1 ? 'ont' : 'a'
      } pas d'affectation dans le modèle de bilan que vous avez choisi. Cela signifie que ${
        nonAffectedAnsweredTests.length > 1
          ? 'ces épreuves ne seront pas mentionnées'
          : 'cette épreuve ne sera pas mentionnée'
      } dans le compte rendu.`}</AlertTitle>
      {!showList && (
        <Tooltip title={labels.showDetail}>
          <IconButton onClick={() => setShowList(true)}>
            <Visibility />
          </IconButton>
        </Tooltip>
      )}
      {showList && (
        <Fragment>
          <Typography>{labels.list}</Typography>
          {nonAffectedAnsweredTests.map(({ id, testLabel }) => (
            <Typography key={id}>
              - {testLabel} : {id}{' '}
            </Typography>
          ))}
          <Tooltip title={labels.editModel}>
            <IconButton onClick={() => history.push(routes.CONFIGURATION.MODELS.getForPath(checkupModelId))}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Fragment>
      )}
    </Alert>
  );
};

NonFilledTestInModel.defaultProps = {
  cotation: {
    results: {}
  },
  bilan: { tests: [] },
  checkupModel: {
    elements: []
  }
};

export default NonFilledTestInModel;
