import React from 'react';

import TextFieldIcon from '../../common/anamnesis/icons/TextFieldIcon';
import TextAreaIcon from '../../common/anamnesis/icons/TextAreaIcon';
import RadioButtonGroupIcon from '../../common/anamnesis/icons/RadioButtonGroupIcon';
import TitleIcon from '../../common/anamnesis/icons/TitleIcon';
import PrependTextFieldIcon from '../../common/anamnesis/icons/PrependTextFieldIcon';
import AntecedentIcon from '../../common/anamnesis/icons/AntecedentIcon';

import labels from '../../../constants/labels/configuration/anamnesis/Anamnesis.json';

const AnamnesisFormElements = [
  {
    groupTitle: labels.tabs.form.left.groups.customizableElements.title,
    panelId: 'panel1',
    elements: [
      {
        icon: <TitleIcon />,
        label: labels.tabs.form.left.groups.customizableElements.elements.title,
        value: {
          guiLabel: 'Titre',
          label: 'Titre de section',
          type: 'header'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.customizableElements.elements.shortText,
        value: {
          custom: true,
          guiLabel: 'Texte court',
          label: 'Votre question',
          placeholder: 'Réponse',
          reportLabel: '',
          reportLabelPlaceholder: 'Intitulé de la réponse dans le compte rendu',
          reportLabelType: 'reuse',
          shortLabel: 'Texte court',
          span: 6,
          type: 'custom_input'
        }
      },
      {
        icon: <TextAreaIcon />,
        label: labels.tabs.form.left.groups.customizableElements.elements.longText,
        value: {
          custom: true,
          guiLabel: 'Texte long',
          label: 'Votre question',
          placeholder: 'Réponse',
          reportLabel: '',
          reportLabelPlaceholder: 'Intitulé de la réponse dans le compte rendu',
          reportLabelType: 'reuse',
          shortLabel: 'Zone de texte',
          span: 6,
          type: 'custom_textarea'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.customizableElements.elements.choice,
        value: {
          custom: true,
          guiLabel: 'Choix 1',
          label: 'Un titre',
          options: [
            {
              id: '913d0357-7132-4455-8a1d-cede09566490',
              label: 'Option 1'
            },
            {
              id: '913d0357-7132-4455-8a1d-cede09566491',
              label: 'Option 2'
            }
          ],
          placeholder: '',
          reportLabel: '',
          reportLabelPlaceholder: 'Intitulé de la réponse dans le compte rendu',
          reportLabelType: 'reuse',
          shortLabel: 'Liste de choix',
          span: 6,
          type: 'custom_radios'
        }
      }
    ]
  },
  {
    groupTitle: labels.tabs.form.left.groups.personalInformations.title,
    panelId: 'panel2',
    elements: [
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.firstName,
        value: {
          guiLabel: 'Prénom',
          icon: 'icon-user',
          id: 'prenom',
          label: "Prénom de l'assuré(e)",
          placeholder: 'Prénom',
          span: 6,
          type: 'icon-prepend'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.lastName,
        value: {
          guiLabel: 'Nom',
          icon: 'icon-user',
          id: 'nom',
          label: "Nom de l'assuré(e)",
          placeholder: 'Nom',
          span: 6,
          tip: 'Un exemple de tip',
          type: 'icon-prepend'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.mail,
        value: {
          guiLabel: 'Mail',
          icon: 'icon-envelope',
          id: 'email',
          label: 'Votre email',
          placeholder: 'votreadresse@email.fr',
          span: 6,
          tip: 'Un exemple de tip',
          type: 'icon-prepend'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.insuredReferences,
        value: {
          guiLabel: 'Rel. Assuré',
          id: 'relationassure',
          label: "Relation de l'assuré(e) avec le patient:",
          options: [
            {
              id: 'mere',
              label: 'Mère'
            },
            {
              id: 'pere',
              label: 'Père'
            },
            {
              id: 'autre',
              label: 'Autre (précisez) :',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios',
          value: false
        }
      },
      {
        icon: <TextAreaIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.address,
        value: {
          guiLabel: 'Adresse',
          id: 'adresse',
          label: 'Adresse postale',
          placeholder: "L'adresse principale, ou l'une des adresses principales de résidence",
          span: 12,
          type: 'textarea'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.postalCode,
        value: {
          guiLabel: 'C. Postal',
          icon: 'icon-flag',
          id: 'codepostal',
          label: 'Code postal',
          mask: '99999',
          placeholder: '00000',
          span: 6,
          type: 'icon-prepend'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.city,
        value: {
          guiLabel: 'Ville',
          icon: 'icon-home',
          id: 'ville',
          label: 'Ville',
          placeholder: 'Ville ou commune',
          span: 6,
          type: 'icon-prepend'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.phone1,
        value: {
          guiLabel: 'Tel.1',
          icon: 'icon-phone',
          id: 'telephone1',
          label: 'Téléphone portable',
          mask: '99 99 99 99 99',
          placeholder: '',
          span: 6,
          type: 'icon-prepend'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.phone2,
        value: {
          guiLabel: 'Tel.2',
          icon: 'icon-phone',
          id: 'telephone2',
          label: 'Téléphone fixe',
          mask: '99 99 99 99 99',
          placeholder: '',
          span: 6,
          type: 'icon-prepend'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.profession1,
        value: {
          guiLabel: 'Profession1',
          id: 'professionparent1',
          label: 'Profession du parent assuré(e)',
          placeholder: 'Profession',
          span: 6,
          type: 'input'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.personalInformations.elements.profession2,
        value: {
          guiLabel: 'Profession2',
          id: 'professionparent2',
          label: "Profession de l'autre parent",
          placeholder: 'Profession',
          span: 6,
          type: 'input'
        }
      }
    ]
  },
  {
    groupTitle: labels.tabs.form.left.groups.administrativeInformations.title,
    panelId: 'panel3',
    elements: [
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.administrativeInformations.elements.doctor,
        value: {
          guiLabel: 'Médecin',
          icon: 'icon-stethoscope',
          id: 'medecintraitant',
          label: 'Nom du médecin',
          placeholder: '',
          type: 'icon-prepend'
        }
      },
      {
        icon: <TextAreaIcon />,
        label: labels.tabs.form.left.groups.administrativeInformations.elements.doctorAddress,
        value: {
          guiLabel: 'Add. Méd.',
          id: 'adressemedecin',
          label: 'Adresse du médecin',
          placeholder: '',
          type: 'textarea'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.administrativeInformations.elements.doctorPhone,
        value: {
          guiLabel: 'Tel. Méd.',
          icon: 'icon-phone',
          id: 'telephonemedecin',
          label: 'Téléphone du médecin',
          mask: '99 99 99 99 99',
          placeholder: '',
          span: 6,
          type: 'icon-prepend'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.administrativeInformations.elements.doctorMail,
        value: {
          guiLabel: 'Mail Méd.',
          icon: 'icon-envelope',
          id: 'emailmedecin',
          label: 'Email du médecin',
          placeholder: 'medecin@email.fr',
          span: 6,
          type: 'icon-prepend'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.administrativeInformations.elements.doctorNIR,
        value: {
          guiLabel: 'NIR',
          icon: 'icon-hospital',
          id: 'numsecu',
          label: 'Numéro de sécurité sociale (NIR)',
          placeholder: 'Sur 13 chiffres',
          span: 6,
          type: 'numsecu'
        }
      }
    ]
  },
  {
    groupTitle: labels.tabs.form.left.groups.complaint.title,
    panelId: 'panel4',
    elements: [
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.complaint.elements.complaint,
        value: {
          guiLabel: 'Plainte',
          id: 'plainte',
          label: 'Motif de la visite',
          type: 'info'
        }
      },
      {
        icon: <TextAreaIcon />,
        label: labels.tabs.form.left.groups.complaint.elements.details,
        value: {
          guiLabel: 'Précisions',
          id: 'plainteprecisions',
          label: 'Si vous souhaitez ajouter des précisions sur le motif de la visite:',
          placeholder: '',
          type: 'textarea'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.complaint.elements.origin,
        value: {
          guiLabel: 'Origine',
          id: 'origineplainte',
          label: "Qui est à l'origine de la plainte ?",
          options: [
            {
              id: 'famille',
              label: 'Vous / votre entourage familial'
            },
            {
              id: 'instituteur',
              label: "L'enseignant"
            },
            {
              id: 'medecin',
              label: 'Votre médecin'
            },
            {
              id: 'orthoptiste',
              label: 'un spécialiste orthoptiste'
            },
            {
              id: 'pedopsy',
              label: 'un spécialiste pédopsychiatre ou pédopsychologue'
            },
            {
              id: 'autre',
              label: 'Autre (précisez):',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          type: 'inlineradios',
          value: 'medecin'
        }
      }
    ]
  },
  {
    groupTitle: labels.tabs.form.left.groups.familyEnvironment.title,
    panelId: 'panel5',
    elements: [
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.familyEnvironment.elements.residence,
        value: {
          guiLabel: 'Résidence',
          id: 'residence',
          label: 'Lieu de résidence:',
          options: [
            {
              id: 'parents',
              label: 'Chez ses parents (non séparés)'
            },
            {
              id: 'gardealternee',
              label: 'En garde alternée'
            },
            {
              id: 'mere',
              label: 'Résidence principale chez la mère, avec droit de visite \\"standard\\"'
            },
            {
              id: 'pere',
              label: 'Résidence principale chez le père, avec droit de visite \\"standard\\"'
            },
            {
              id: 'autre',
              label: 'Autre (précisez) :',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          type: 'radios'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.familyEnvironment.elements.siblings,
        value: {
          guiLabel: 'Fratrie',
          id: 'fratrie',
          label: "Frères ou soeurs résidant habituellement avec l'enfant",
          type: 'fratrie',
          value: {
            detail: [],
            nb: 0
          }
        }
      },
      {
        icon: <TextAreaIcon />,
        label: labels.tabs.form.left.groups.familyEnvironment.elements.details,
        value: {
          guiLabel: 'Précisions',
          id: 'fratrieprecisions',
          label:
            "Décrivez les rapports de votre enfant avec ses frères et soeurs ou toutes autres informations utiles pour comprendre l'épanouissement de votre enfant au sein de la famille (faits marquants, joue-t-il avec ses frères et soeurs, les voit-il souvent, etc.)",
          placeholder: '',
          type: 'textarea'
        }
      }
    ]
  },
  {
    groupTitle: labels.tabs.form.left.groups.familyHistory.title,
    panelId: 'panel6',
    elements: [
      {
        icon: <AntecedentIcon />,
        label: labels.tabs.form.left.groups.familyHistory.elements.familyHistory,
        value: {
          guiLabel: 'Antécédents',
          id: 'antecedents',
          label: 'Scolaires/orthophoniques',
          type: 'antecedentsfamiliaux'
        }
      },
      {
        icon: <TextAreaIcon />,
        label: labels.tabs.form.left.groups.familyHistory.elements.details,
        value: {
          guiLabel: 'Précisions',
          id: 'antecedentsprecision',
          label: 'Précisions si nécessaire',
          placeholder: '',
          type: 'textarea'
        }
      }
    ]
  },
  {
    groupTitle: labels.tabs.form.left.groups.medicalHistory.title,
    panelId: 'panel7',
    elements: [
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.glasses,
        value: {
          guiLabel: 'Lunettes',
          id: 'lunettes',
          label: 'Port de verres correcteurs',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui, précisez:',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.allergies,
        value: {
          guiLabel: 'Allergies',
          id: 'allergies',
          label: 'Allergies',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui, précisez:',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.hospitalizations,
        value: {
          guiLabel: 'Hospit.',
          id: 'hospitalisations',
          label: 'Hospitalisations',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui, précisez:',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.treatments,
        value: {
          guiLabel: 'Traitements',
          id: 'traitements',
          label: 'Traitements en cours',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui, précisez:',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.diabolo,
        value: {
          guiLabel: 'Diabolo',
          id: 'diabolo',
          label: 'Aérateur transtympaniques (yoyo/diabolo)',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'inlineradios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.earInfection1,
        value: {
          guiLabel: 'Otites 1',
          id: 'otitessereuses',
          label: 'Otites séreuses',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'inlineradios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.earInfection2,
        value: {
          guiLabel: 'Orites 2',
          id: 'otitesrepetition',
          label: 'Otites à répétition',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'inlineradios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.bronchitis,
        value: {
          guiLabel: 'Bronchites',
          id: 'bronchites',
          label: 'Bronchites asthmatiformes',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'inlineradios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.audiogram,
        value: {
          guiLabel: 'Audiogramme',
          id: 'audiogramme',
          label: 'A-t-il/elle eu un audiogramme ?',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'inlineradios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.monitoring,
        value: {
          guiLabel: 'Suivi',
          id: 'suiviortho',
          label: 'Votre enfant a-t-il déjà été suivi par un orthophoniste ?',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui, par: ',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.medicalHistory.elements.other,
        value: {
          guiLabel: 'Autre',
          id: 'suivitherap',
          label: 'Autre suivi thérapeutique en cours',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui, précisez:',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      }
    ]
  },
  {
    groupTitle: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.title,
    panelId: 'panel8',
    elements: [
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.pregnancy,
        value: {
          guiLabel: 'Grossesse',
          id: 'grossesse',
          label: 'Grossesse',
          options: [
            {
              id: 'normale',
              label: 'Sans problème'
            },
            {
              id: 'complication',
              label: 'Complication',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 12,
          type: 'inlineradios'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.term,
        value: {
          guiLabel: 'Terme',
          id: 'terme',
          label: "Terme de la grossesse, en semaines d'aménorrhée (terme = 41 semaines)",
          numberType: 'digits',
          placeholder: '',
          span: 12,
          type: 'age',
          unit: 'semaines'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.weight,
        value: {
          guiLabel: 'Poids',
          id: 'poidsnaissance',
          label: 'Poids à la naissance',
          placeholder: '',
          span: 6,
          step: '0.1',
          type: 'number',
          unit: 'kg'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.delivery,
        value: {
          guiLabel: 'Accouchement',
          id: 'accouchement',
          label: 'Accouchement',
          options: [
            {
              id: 'naturelle',
              label: 'Voies naturelles'
            },
            {
              id: 'césarienne',
              label: 'Césarienne'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'inlineradios'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.nights,
        value: {
          guiLabel: 'Nuits',
          id: 'nuits',
          invalid: 'Non acquis',
          label: 'A fait ses nuits à',
          placeholder: '',
          span: 6,
          type: 'age',
          unit: 'mois'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.laterality,
        value: {
          guiLabel: 'Latéralité',
          id: 'lateralite',
          label: 'Latéralité',
          options: [
            {
              id: 'droitier',
              label: 'Droitier'
            },
            {
              id: 'gaucher',
              label: 'Gaucher'
            },
            {
              id: 'ambidextre',
              label: 'Ambidextre'
            },
            {
              id: 'indeterm',
              label: 'Pas encore déterminé'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'inlineradios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.nursing,
        value: {
          guiLabel: 'Allaitement',
          id: 'allaitement',
          label: 'Allaitement (au sein)',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: "Oui, jusqu'à",
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.languages,
        value: {
          guiLabel: 'Langues',
          id: 'bilinguisme',
          label: 'Parle une ou plusieurs autres langues',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui',
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.walking,
        value: {
          guiLabel: 'Marche',
          id: 'marche',
          invalid: 'Non acquis',
          label: 'Premiers pas à',
          placeholder: '',
          span: 6,
          type: 'age',
          unit: 'mois'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.fisrtWords,
        value: {
          guiLabel: 'Langage',
          id: 'parle',
          invalid: 'Non acquis',
          label: 'Premiers mots à',
          placeholder: '',
          span: 6,
          type: 'age',
          unit: 'mois'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.suction1,
        value: {
          guiLabel: 'Succion 1',
          id: 'succion',
          label: 'Succion...',
          options: [
            {
              id: 'tetine',
              label: 'Tétine'
            },
            {
              id: 'pouce',
              label: 'Pouce'
            }
          ],
          placeholder: '',
          span: 3,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.suction2,
        value: {
          guiLabel: 'Succion 2',
          id: 'succionfin',
          label: "...jusqu'à",
          options: [
            {
              id: 'encore',
              label: "Encore aujourd'hui"
            },
            {
              id: 'jusqua',
              label: "Jusqu'à",
              type: 'freevalue'
            }
          ],
          placeholder: '',
          span: 9,
          type: 'radios'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.cleanliness,
        value: {
          guiLabel: 'Propreté',
          id: 'propre',
          invalid: 'Non acquis',
          label: 'Propre à',
          placeholder: '',
          span: 6,
          type: 'age',
          unit: 'mois'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.languageLevel,
        value: {
          guiLabel: 'Langage',
          id: 'niveaulangageecole',
          label: "Niveau de langage à l'entrée à l'école",
          options: [
            {
              id: 'faible',
              label: 'Faible'
            },
            {
              id: 'normal',
              label: 'Normal'
            },
            {
              id: 'eleve',
              label: 'Élevé'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'inlineradios'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.fourLegWalk,
        value: {
          guiLabel: '4 pattes',
          id: 'age4pattes',
          invalid: 'Non acquis',
          label: 'À quel âge a-t-il fait du 4 pattes ?',
          placeholder: '',
          span: 6,
          type: 'age',
          unit: 'mois'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.laces,
        value: {
          guiLabel: 'Lacets',
          id: 'agelacet',
          invalid: 'Non acquis',
          label: 'À quel âge a-t-il su faire ses lacets ?',
          placeholder: '',
          span: 6,
          type: 'age',
          unit: 'ans'
        }
      },
      {
        icon: <PrependTextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.bike,
        value: {
          guiLabel: 'Vélo',
          id: 'agevelo',
          invalid: 'Non acquis',
          label: 'À quel âge a-t-il su faire du vélo ?',
          placeholder: '',
          span: 6,
          type: 'age',
          unit: 'ans'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.screen,
        value: {
          guiLabel: 'Ecrans',
          id: 'heurestv',
          label: 'Combien de temps votre enfant passe-t-il devant un écran par semaine (TV/Ordi/console/tablettes...)',
          placeholder: '',
          span: 12,
          type: 'number',
          unit: 'h/semaine'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.wakeUp,
        value: {
          guiLabel: 'Lever',
          id: 'leversemaine',
          label: 'Heure du lever en semaine',
          span: 6,
          type: 'time'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.lieDown,
        value: {
          guiLabel: 'Coucher',
          id: 'couchersemaine',
          label: 'Heure du coucher en semaine',
          span: 6,
          type: 'time'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.wakeUpWeekEnd,
        value: {
          guiLabel: 'Lever WE',
          id: 'leverwe',
          label: 'Heure du lever le week-end',
          span: 6,
          type: 'time'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.lieDownWeekEnd,
        value: {
          guiLabel: 'Coucher WE',
          id: 'coucherwe',
          label: 'Heure du coucher le week-end',
          span: 6,
          type: 'time'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.regularity,
        value: {
          guiLabel: 'Régularité',
          id: 'regularitecoucher',
          label: "L'heure du coucher est-elle :",
          options: [
            {
              id: 'tresreguliere',
              label: 'Très régulière'
            },
            {
              id: 'assezreguliere',
              label: 'Assez régulière'
            },
            {
              id: 'peureguliere',
              label: 'Peu régulière'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.fallAsleep,
        value: {
          guiLabel: 'Endorm.',
          id: 'endormissementdifficile',
          label: "Votre enfant a-t-il des difficultés pour s'endormir le soir ?",
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.nightDrool,
        value: {
          guiLabel: 'Bave Noct.',
          id: 'bavenocturne',
          label: 'Votre enfant bave-t-il pendant son sommeil?',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      },
      {
        icon: <RadioButtonGroupIcon />,
        label: labels.tabs.form.left.groups.psychoAndLanguageDevelopment.elements.snoring,
        value: {
          guiLabel: 'Ronflements',
          id: 'ronflements',
          label: 'Ronfle-t-il la nuit?',
          options: [
            {
              id: 'non',
              label: 'Non'
            },
            {
              id: 'oui',
              label: 'Oui'
            }
          ],
          placeholder: '',
          span: 6,
          type: 'radios'
        }
      }
    ]
  },
  {
    groupTitle: labels.tabs.form.left.groups.schoolCourse.title,
    panelId: 'panel9',
    elements: [
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.schoolCourse.elements.schoolPath,
        value: {
          guiLabel: 'Parcours (fr)',
          id: 'parcoursscolaire',
          label: 'Parcours scolaire',
          type: 'parcoursscolaire'
        }
      }
    ]
  },
  {
    groupTitle: labels.tabs.form.left.groups.computingAndFreedom.title,
    panelId: 'panel10',
    elements: [
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.computingAndFreedom.elements.part1,
        value: {
          guiLabel: 'CNIL1/3',
          id: 'inflib2',
          label:
            'Ce cabinet dispose d’un système informatique destiné à faciliter la gestion des dossiers des patients et à assurer la facturation des actes et la télétransmission des feuilles de soins aux caisses de sécurité sociale. Sauf opposition justifiée de votre part, les informations recueillies sur ce formulaire ainsi que lors de votre consultation feront l’objet d’un enregistrement informatique réservé à l’usage de votre professionnel de santé. Votre professionnel de santé traitant se tient à votre disposition pour vous communiquer ces renseignements ainsi que toutes informations nécessaires sur votre état de santé(*).',
          type: 'info'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.computingAndFreedom.elements.part2,
        value: {
          guiLabel: 'CNIL2/3',
          id: 'inflib3',
          label:
            "Conformément au Règlement général sur la protection des données (RGPD), vous pouvez exercer votre droit d'accès aux données vous concernant, les faire rectifier, effacer, limiter ou vous opposer au traitement en contactant votre professionnel de santé par écrit.",
          type: 'info'
        }
      },
      {
        icon: <TextFieldIcon />,
        label: labels.tabs.form.left.groups.computingAndFreedom.elements.part3,
        value: {
          guiLabel: 'CNIL3/3',
          id: 'inflib3',
          label:
            "(*) Tout médecin désigné par vous peut également prendre connaissance de l’ensemble de votre dossier médical. RGPD : RÈGLEMENT (UE) 2016/679 DU PARLEMENT EUROPÉEN ET DU CONSEIL du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des données)",
          type: 'info'
        }
      }
    ]
  }
];
export default AnamnesisFormElements;
