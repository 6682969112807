import React, { useState } from 'react';
import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';

type Props = {
  id?: string,
  label: string,
  span?: number,
  options?: array,
  myValue?: {
    free?: string,
    value?: string
  },
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function
};

const InlineRadio = ({ id, span, label, options, myValue: defaultValue, changeHandler }: Props) => {
  const [myValue, setMyValue] = useState(defaultValue);

  function onChange(event) {
    setMyValue({ free: event.target.value, value: myValue.value });
  }

  function onRadioChange(event) {
    const newMyValue = { free: myValue.free, value: event.target.value };
    setMyValue(newMyValue);
    changeHandler({ target: { name: id, value: newMyValue } });
  }

  function onBlur() {
    changeHandler({ target: { name: id, value: myValue } });
  }

  return (
    <Grid key={id || label} item xs={span} container>
      <Grid item xs={12}>
        <FormLabel component="legend">{label}</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup onChange={onRadioChange} value={myValue.value} row>
          {getOptionsMap(options, myValue, onChange, onBlur)}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

InlineRadio.defaultProps = {
  id: '',
  span: 12,
  options: [],
  myValue: {
    free: '',
    value: ''
  },
  changeHandler: () => {}
};

function getOptionsMap(options, myValue, freeTextOnChange, freeTextOnBlur) {
  return options.map(({ id, label, type }) =>
    type === 'freevalue' ? (
      <FormControlLabel
        key={id || label}
        value={id || label}
        control={<Radio />}
        label={
          <span>
            {label}
            <TextField
              value={myValue.free || ''}
              disabled={myValue.value !== id}
              onChange={freeTextOnChange}
              onBlur={freeTextOnBlur}
            />
          </span>
        }
      />
    ) : (
      <FormControlLabel key={id || label} value={id || label} control={<Radio />} label={label} />
    )
  );
}

export default InlineRadio;
