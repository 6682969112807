import GibberishAES from 'gibberish-aes/dist/gibberish-aes-1.0.0';
import JSEncrypt from 'jsencrypt';
import { base64DecToArr, UTF8ArrToStr } from './CryptoServiceLegacy';

const util = require('util');

const CryptoService = {
  aesEncrypt(data, password) {
    const str = JSON.stringify(data);
    return GibberishAES.enc(str, password, false);
  },

  aesDecrypt(data, password) {
    return JSON.parse(GibberishAES.dec(data, password, false));
  },

  getRSA(keySize) {
    return new RSA(keySize || 1024);
  },

  base64Decode(base64str) {
    return UTF8ArrToStr(base64DecToArr(base64str));
  }
};

export default CryptoService;

class RSA {
  // eslint-disable-next-line flowtype/no-weak-types
  crypt: any;

  keySize: number;

  constructor(keySize) {
    this.crypt = new JSEncrypt({ default_key_size: keySize });
    this.keySize = keySize;
  }

  generateKeys = callback => {
    this.crypt.getKey(() => {
      console.log('Generated keys:', this.crypt);
      console.log('Generated public keys:', this.crypt.getPublicKey('base64'));
      console.log('Generated private keys:', this.crypt.getPrivateKey('base64'));
      callback();
    });
    return this;
  };

  setPublicKey = pubKey => {
    this.crypt.setPublicKey(pubKey);
    return this;
  };

  getPublicKey = () => this.crypt.getPublicKey('base64');

  setPrivateKey = privKey => {
    this.crypt.setPrivateKey(privKey);
    return this;
  };

  getPrivateKey = () => this.crypt.getPrivateKey('base64');

  encrypt = (data, callback, errorCallback) => {
    let cursor = 0;
    let buffer = '';
    const maxLen = this.keySize / 8 - 12;
    let str = JSON.stringify(data);
    try {
      str = window.btoa(str);
    } catch (e) {
      errorCallback(e);
    }

    const encryptSlice = () => {
      console.log(`slice @ ${cursor}`);

      try {
        if (cursor < str.length) {
          if (cursor > 0) {
            buffer += '!';
          }
          const len = Math.min(maxLen, str.length - cursor);
          const line = this.crypt.encrypt(str.substr(cursor, len));
          if (line === false || line === 'false') {
            errorCallback('false encrypt buffer');
          } else {
            buffer += line;
            cursor += len;
            setTimeout(encryptSlice, 0);
          }
        } else {
          setTimeout(() => {
            callback(buffer);
          }, 0);
        }
      } catch (e) {
        errorCallback(e);
      }
    };

    setTimeout(encryptSlice, 0);
  };

  decrypt = (str, progressionPercentCallback, callback) => {
    let buffer = '';
    const split = str.split('!');
    let index = 0;

    const decryptSlice = () => {
      try {
        if (progressionPercentCallback && split.length > 0) {
          progressionPercentCallback(100 * (index / split.length));
        }
        if (index < split.length) {
          console.log('Decrypting block', index);
          const block = this.crypt.decrypt(split[index]);
          buffer += block;
          index += 1;
          window.setTimeout(decryptSlice, 0);
        } else {
          buffer = window.atob(buffer);
          const object = JSON.parse(buffer);
          callback(null, object);
        }
      } catch (err) {
        if (callback && typeof callback === 'function') {
          callback(err);
        }
      }
    };
    setTimeout(decryptSlice, 0);
  };

  decryptSync = util.promisify(this.decrypt);
}
