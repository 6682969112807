import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Refresh, ZoomIn, ZoomOut } from '@material-ui/icons';

import Loader from '../../common/Loader';
import './ReportGraphicsChart.css';
import ReportGeneratorUtil from './ReportGeneratorUtil';
import useDebounce from '../../common/UseDebounce';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  content: any,
  debounceDelay: number | undefined
};

export default function OnLineReportGraphicsChart({ content, debounceDelay = 5000 }: Props) {
  const [chart, setChart] = useState(null);

  const debouncedContent = useDebounce(content, debounceDelay);

  const fetchChart = useCallback(async() => {
    setChart(null);
    if (content.patient._id) {
      const myChart = await ReportGeneratorUtil.generateGraph(content);
      await setChart(myChart);
    }
  }, [content]);

  useEffect(() => {
    fetchChart().then();
  }, [debouncedContent, fetchChart]);

  if (!chart) {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  // eslint-disable-next-line react/no-danger
  return (
    <Fragment>
      <Grid container direction="column" justifyContent="space-between" alignItems="center">
        <Grid item>
          <IconButton onClick={() => fetchChart()}>
            <Refresh />
          </IconButton>
        </Grid>
        <Grid item id="reportGraphicChart" className="parentDiv">
          <input type="checkbox" id="zoomedSvg" hidden />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
          <label htmlFor="zoomedSvg" className="label">
            <ZoomIn className="zoomIn" />
            <ZoomOut className="zoomOut" />
          </label>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="svgContainer" dangerouslySetInnerHTML={{ __html: chart }} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
