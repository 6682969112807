import { useEffect, useState } from 'react';
import User from './data/User';

export function useDeveloperMode() {
  const [isDeveloperMode, setDeveloperMode] = useState(false);

  useEffect(() => {
    async function loadDeveloperMode() {
      const developerMode = await User.isDeveloperMode();
      setDeveloperMode(developerMode);
    }

    loadDeveloperMode();
  }, []);

  return isDeveloperMode;
}
