import { getAnnotationIndex, getGameScores, getScoreDefinition } from '../HappyNeuronImporterUtil';

const calculateRawResult = (eprId, workout, datas) => {
  const splitted = eprId.split('_');
  const gameId = parseInt(splitted[1], 10);
  const scoreId = parseInt(splitted[2], 10);
  if (!workout.exercises[gameId.toString()]) {
    return { eprId, score: undefined, annotation: undefined };
  }
  const { results: workoutResult } = workout.exercises[gameId.toString()];
  const gameScores = getGameScores(datas.scores.games, gameId);
  const annotationIndex = getAnnotationIndex(gameScores);
  const { position: scoreIndex } = getScoreDefinition(gameScores, scoreId);

  // console.log({ eprId, workoutResult, gameId, scoreIndex, annotationIndex });
  const score = workoutResult[scoreIndex - 1]
    ? Math.round(workoutResult[scoreIndex - 1] * 100) / 100
    : workoutResult[scoreIndex - 1];
  if (score === -1) {
    return { eprId, score: undefined, annotation: undefined };
  }
  return { eprId, score, annotation: annotationIndex ? workoutResult[annotationIndex] : undefined };
};

export default calculateRawResult;
