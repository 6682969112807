import erasePreviousResults from '../../test/testUtils/ErasePreviousResults';
import updateCurrentTestResultsWithImportValues from '../../test/testUtils/UpdateCurrentTestResultsWithImportValues';

const mergeSelectedPassations = async (
  selectedPassations,
  cotation,
  { birthDay, firstName, lastName },
  eraseResults,
  options
) => {
  const updatedCotations = JSON.parse(JSON.stringify(cotation));

  const array = await Promise.all(
    Object.values(selectedPassations)
      .map(({ test, testCode, data, executeImport, passationDate, choosenCalcul }) => {
        const { values, calculOption } = executeImport({
          data,
          passationDate,
          birthDate: birthDay,
          testCode,
          classe: choosenCalcul
        });
        return { test, testCode, values, calculOption };
      })
      .map(({ test, testCode, values, calculOption }) => {
        const currentTestResult = eraseResults
          ? erasePreviousResults(test, cotation.results[testCode])
          : cotation.results[testCode] || {};
        return { test, testCode, values, calculOption, currentTestResult };
      })
      .map(async ({ test, testCode, values, calculOption, currentTestResult }) => {
        const resultForTestCode = await updateCurrentTestResultsWithImportValues(
          test,
          calculOption,
          currentTestResult,
          values,
          firstName,
          lastName,
          options.addUserSelectedMemorizedTextsAsDefaultComment
        );
        return { testCode, resultForTestCode };
      })
  );

  return array.reduce((acc, { testCode, resultForTestCode }) => {
    acc.results[testCode] = resultForTestCode;
    return acc;
  }, updatedCotations);
};

export default mergeSelectedPassations;
