/* eslint-disable react/prop-types */
import React from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import TestFillingRowTextField from './TestFillingRowTextField';
import CotationBarChart from './CotationBarChart';
import testFillingRowStyle from './TestFillingRowStyle.json';
import testResultHasACentileKeyAndPercentileObjectAValue
    from "./testUtils/TestResultHasACentileKeyAndPercentileObjectAValue";

const useStyles = makeStyles(testFillingRowStyle);

const TestFillingRowScoreColumn = ({
  displayCalculatedPercentile,
  percentileObject,
  fields,
  testResult,
  columnWidth,
  handleScoreChange,
  handleBlur,
  mouseScrollWheelScoreEdition,
  options,
  testCode
}) => {
  const style = useStyles();

  const displayPercentileObjectLabel = displayCalculatedPercentile && testResultHasACentileKeyAndPercentileObjectAValue(testResult, percentileObject);
  const displayEmptyPercentileLabel = displayCalculatedPercentile && !testResultHasACentileKeyAndPercentileObjectAValue(testResult, percentileObject);
  return (
    <Grid item md={12} lg={6} container className={style.extraDenseInput}>
      <Grid
        item
        md={
          displayCalculatedPercentile || (displayCalculatedPercentile && percentileObject && percentileObject.label)
            ? 8
            : 9
        }
        container
        className={style.alignVertically}
      >
        {fields.map(({ children, disabled = testResult.$$DISABLETYPING, ...props }) => (
          <TestFillingRowTextField
            key={props.id}
            itemMdValue={columnWidth}
            {...props}
            disabled={disabled}
            onChangeHandler={handleScoreChange}
            onBlurHandler={handleBlur}
            inputType="number"
            margin="dense"
            onWheel={mouseScrollWheelScoreEdition}
          >
            {children}
          </TestFillingRowTextField>
        ))}
      </Grid>
      {displayPercentileObjectLabel && (
        <Grid item md={2} className={style.alignVertically}>
          {percentileObject && percentileObject.label}
        </Grid>
      )}
      {displayEmptyPercentileLabel && (
        <Grid item md={2} className={style.alignVertically} />
      )}
      <Grid item md={2} className={style.alignVertically}>
        <CotationBarChart
          key={JSON.stringify(testResult)}
          testResult={testResult}
          percentileObject={percentileObject}
          options={options}
          testCode={testCode}
        />
      </Grid>
    </Grid>
  );
};

export default TestFillingRowScoreColumn;
