import React from 'react';
import { PersonAdd as PersonAddIcon } from '@material-ui/icons';

const PersonAdd = () => (
  <PersonAddIcon
    fontSize="large"
    component={svgProps => (
      <svg {...svgProps}>
        {React.cloneElement(svgProps.children[0], {
          fill: 'greenyellow',
          stroke: 'grey',
          strokeWidth: '1'
        })}
      </svg>
    )}
  />
);

export default PersonAdd;
