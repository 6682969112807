import React, { useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';

type Props = {
  id?: string,
  label: string,
  span?: number,
  options?: array,
  myValue?: {
    free?: string,
    value?: string
  },
  type: string,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function
};

export default function Radios(props: Props) {
  const { id, span, label, options, myValue: defaultValue, changeHandler, type } = props;
  const [myValue, setMyValue] = useState(defaultValue);
  return (
    <Grid key={id || label} item xs={span} container>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup onChange={onRadioChange} value={myValue.value}>
          {getOptionsMap(options, myValue, type, onChange, onBlur)}
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  function onChange(event) {
    setMyValue({ free: event.target.value, value: myValue.value });
  }

  function onRadioChange(event) {
    const newMyValue = { free: myValue.free, value: event.target.value };
    setMyValue(newMyValue);
    changeHandler({ target: { name: id, value: newMyValue } });
  }

  function onBlur() {
    changeHandler({ target: { name: id, value: myValue } });
  }
}

Radios.defaultProps = {
  id: '',
  span: 12,
  options: [],
  myValue: { free: '', value: '' },
  changeHandler: () => {}
};

const getOptionsMap = (options, myValue, type, freeTextOnChange, freeTextOnBlur) =>
  options.map(({ id, label, type: optionType }) => {
    const value = type === 'radios' ? id || label : label || id;
    return optionType === 'freevalue' ? (
      <FormControlLabel
        key={value}
        value={value}
        control={<Radio />}
        style={{ width: '100%' }}
        label={
          <span style={{ width: '100%' }}>
            {label}
            <TextField
              value={myValue.free}
              disabled={myValue.value !== id}
              onChange={freeTextOnChange}
              onBlur={freeTextOnBlur}
              fullWidth
            />
          </span>
        }
      />
    ) : (
      <FormControlLabel key={id || label} value={value} control={<Radio />} label={label} />
    );
  });
