import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import Patient from '../common/data/Patient';
import appVersionSpecific from '../../appVersionSpecific.ortho';
import localDatabases from '../../database/database';
import { useDeveloperMode } from '../common/useDeveloperMode';
import { download } from '../../actions/FileImportExport';

const DeveloperModeHomeExtraButtons = ({ q }: { q: string }) => {
  const history = useHistory();

  const isDeveloperMode = useDeveloperMode();

  const goToPatient = id => async () => {
    history.push(appVersionSpecific.mainPagePatientLink(id));
  };

  const searchPatientByBilanId = bilanId => async () => {
    const [patientFromDb] = (await Patient.getInitOrRenewalAssessmentPatient(bilanId, 'uuid')) || [];
    console.log({ patientFromDb });
    if (patientFromDb && patientFromDb._id) {
      history.push(appVersionSpecific.mainPagePatientLink(patientFromDb._id));
    }
  };

  const saveEverything = async () => {
    try {
      const everything = await localDatabases.getInstance().v1databaseRepository.find({ selector: {} });
      download(`database_content.json`, JSON.stringify(everything, null, 4));
      console.log({ everything });
    } catch (err) {
      console.log(err);
    }
  };

  const destroyDb = async () => {
    try {
      const result = await localDatabases.getInstance().v1databaseRepository.destroy();
      console.log({ result });
    } catch (err) {
      console.log(err);
    }
  };

  const reloadEverything = async (filePath) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      try {
        const fileContent = new TextDecoder().decode(fileReader.result);
        const content = JSON.parse(fileContent);
        const docsWithoutRev = content.docs.map(({ _rev, ...doc }) => doc);
        const result = await localDatabases.getInstance().v1databaseRepository.bulkDocs(docsWithoutRev);
        console.log({ result });
      } catch (err) {
        console.log(err);
      }
    };
    fileReader.readAsArrayBuffer(filePath);

  };

  if (!isDeveloperMode) return null;

  return (
    <Grid item md={12} container>
      <Button
        onClick={() => {
          goToPatient(q)();
        }}
        variant="contained"
        color="primary"
      >
        Aller au patient dont l&apos;Id est dans la zone de recherche
      </Button>
      <Button
        onClick={() => {
          searchPatientByBilanId(q)();
        }}
        variant="contained"
        color="primary"
      >
        Aller au patient dont l&apos;Id de bilan est dans la zone de recherche
      </Button>
      <Button
        onClick={() => {
          Patient.regenerateLookupField();
        }}
        variant="contained"
        color="primary"
      >
        Re-générer le champ lookup
      </Button>
      <Button
        onClick={() => {
          Patient.deleteElement(q);
        }}
        variant="contained"
        color="primary"
      >
        Supprimer l&apos;entrée dont l&apos;id est dans le champ
      </Button>
      <Button onClick={saveEverything} variant="contained" color="primary">
        save everything
      </Button>
      <Button onClick={destroyDb} variant="contained" color="primary">
        destroy DB
      </Button>
      <Grid item md={12}>
        <input
          id="contained-button-file"
          type="file"
          hidden
          onChange={(event) => {
            const filePath = event.target.files[0]
            if (!filePath) {
              return;
            }
            reloadEverything(filePath)
          }}
          accept=".json"
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            reload everything
          </Button>
        </label>
      </Grid>
    </Grid>
  );
};

export default DeveloperModeHomeExtraButtons;
