import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { AssignmentTurnedIn, Create, Visibility } from '@material-ui/icons';
import labels from '../../constants/labels/home/TestsLink.json';
import routes from '../../constants/routes';
import MoreActionsMenu from './MoreActionsMenu';

type Props = {
  patientId: string,
  bilan:
    | {
        tests: Array | undefined | void
      }
    | undefined
    | void,
  withPreviewIcon?: boolean
};

const TestsLink = ({ patientId, bilan, withPreviewIcon }: Props) => {
  const { tests } = bilan || {};
  const testListWithDefault = tests && tests.length > 0 ? 'TESTS' : 'SELECT';
  return (
    <Grid container alignItems="center">
      {withPreviewIcon && testListWithDefault === 'TESTS' && (
        <Grid item xs={3}>
          <IconButton
            component={Link}
            to={routes.PATIENT.TESTS.getForPath({
              path: routes.PATIENT.TESTS.LISTING_BY_MODEL,
              patientId,
              bilanId: bilan._id
            })}
          >
            <Tooltip title={labels[testListWithDefault].default}>
              <Visibility color="action" />
            </Tooltip>
          </IconButton>
        </Grid>
      )}
      <Grid item xs={testListWithDefault === 'TESTS' ? 3 : 6}>
        <IconButton
          component={Link}
          to={routes.PATIENT.getForPath({
            path: routes.PATIENT.TESTS[testListWithDefault],
            patientId
          })}
        >
          <Tooltip title={labels[testListWithDefault].default}>
            {testListWithDefault === 'TESTS' ? <Create color="action" /> : <AssignmentTurnedIn color="action" />}
          </Tooltip>
        </IconButton>
      </Grid>
      <Grid item xs={6}>
        {tests && tests.length > 0 && (
          <MoreActionsMenu menuItemEntries={getMoreActionMenuItemEntries(patientId, bilan)[testListWithDefault]} />
        )}
      </Grid>
    </Grid>
  );
};

function getMoreActionMenuItemEntries(patientId, bilan) {
  return {
    SELECT: [
      {
        key: 'entry0',
        element: (
          <Link
            to={routes.PATIENT.getForPath({
              path: routes.PATIENT.TESTS.SELECT,
              patientId
            })}
          >
            {labels.SELECT.entry0}
          </Link>
        )
      }
    ],
    TESTS: [
      {
        key: 'entry0',
        element: (
          <Link
            to={routes.PATIENT.getForPath({
              path: routes.PATIENT.TESTS.TESTS,
              patientId
            })}
          >
            {labels.TESTS.entry0}
          </Link>
        )
      },
      {
        key: 'entry2',
        element: (
          <Link
            to={routes.PATIENT.TESTS.getForPath({
              path: routes.PATIENT.TESTS.LISTING_BY_MODEL,
              patientId,
              bilanId: bilan._id
            })}
          >
            {labels.TESTS.entry2}
          </Link>
        )
      },
      {
        key: 'entry1',
        element: (
          <Link
            to={routes.PATIENT.getForPath({
              path: routes.PATIENT.TESTS.SELECT,
              patientId
            })}
          >
            {labels.TESTS.entry1}
          </Link>
        )
      }
    ]
  };
}

TestsLink.defaultProps = {
  withPreviewIcon: false
};

export default TestsLink;
