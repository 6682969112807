const buildOneLevelTreeModelElement = (modelElements, levelToFilter=0) => {
  // console.log({modelElements})
  // add index to each element
  const modelElementsWithIndex = modelElements.map((modelElement, index) => ({ ...modelElement, index }));

  // filter only level 0 elements and add index of next level 0 element to each level 0 element
  return modelElementsWithIndex
    .filter(({ level }) => level === levelToFilter)
    .map(({ index: thisIndex, ...modelElement }) => ({
      ...modelElement,
      index: thisIndex,
      nextLevel0ElementIndex: (
        modelElementsWithIndex.filter(({ level }) => level === levelToFilter).find(({ index }) => index > thisIndex) || {}
      ).index
    })).map(
    ({ index: parentIndex, nextLevel0ElementIndex, ...modelElement }) => {
      const childs = modelElementsWithIndex.filter(({ index }) =>
        nextLevel0ElementIndex ? index > parentIndex && index < nextLevel0ElementIndex : index > parentIndex
      );
      return {
        ...modelElement,
        nextLevel0ElementIndex,
        index: parentIndex,
        childs
      };
    }
  );
};

export default buildOneLevelTreeModelElement;
