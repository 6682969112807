import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { Edit } from '@material-ui/icons';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import labels from '../../../constants/labels/patient/patientConsult/PatientDetails.json';
import dateFormatOptions from './dateFormatOptions.json';
import routes from '../../../constants/routes';
import patientConsultStyle from './patientConsultStyle.json';
import type {Patient} from "../Patient";

const useStyle = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(1),
    '& > div': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5)
    },
    '& > :nth-child(even)': {
      backgroundColor: '#ececef',
      borderRadius: '4px'
    }
  },
  showTooltipOnHover: {
    '& button': {
      display: 'none'
    },
    '&:hover': {
      border: '2px solid lightgreen'
    },
    '&:hover button': {
      display: 'block'
    }
  },
  ...patientConsultStyle
}));

type Props = {
  patient: Patient
};

const PatientDetail = ({ patient }: Props) => {
  const classes = useStyle();
  const history = useHistory();

  const editPatient = () => {
    const forPath = routes.PATIENT.getForPath({ path: routes.PATIENT.EDIT, patientId: patient._id });
    history.push(forPath);
  };

  const getContactLien = contactLien =>
    contactLien && contactLien.split(':')[1] ? contactLien.split(':')[1] : contactLien;

  return (
    <Fragment>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Typography variant="h6">{labels.patient.title}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">{labels.contact.title}</Typography>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <Grid item xs={12} container className={classes.showTooltipOnHover} onClick={editPatient}>
          <Grid item xs={12}>
            <Tooltip title={labels.patient.edit}>
              <IconButton onClick={editPatient}>
                <Edit />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={6} container className={classes.root}>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                {labels.patient.name}
              </Grid>
              <Grid item xs={6}>
                {patient.lastName} {patient.firstName}
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                {labels.patient.birthDate}
              </Grid>
              <Grid item xs={6}>
                {patient.birthDay ? new Date(patient.birthDay).toLocaleDateString('fr-FR', dateFormatOptions) : ''}
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                {labels.patient.checkupDate}
              </Grid>
              <Grid item xs={6}>
                {patient.rdvDay ? new Date(patient.rdvDay).toLocaleDateString('fr-FR', dateFormatOptions) : ''}
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                {labels.patient.complaint}
              </Grid>
              <Grid item xs={6}>
                {patient.plainte}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} container className={classes.root}>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                {labels.contact.name}
              </Grid>
              <Grid item xs={6}>
                {patient.contactLastName} {patient.contactFirstName}
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                {labels.contact.patientRelation}
              </Grid>
              <Grid item xs={6}>
                {getContactLien(patient.contactLien)}
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                {labels.contact.phone}
              </Grid>
              <Grid item xs={6}>
                {patient.contactTel}
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                {labels.contact.mail}
              </Grid>
              <Grid item xs={6}>
                {patient.contactMail}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default PatientDetail;
