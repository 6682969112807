import React, { useCallback, useEffect, useRef, useState } from 'react';
import {Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip} from '@material-ui/core';
import {Close, FavoriteBorder, SaveAlt} from '@material-ui/icons';
import RichTextEditor from 'react-rte';
import {EditorValue, getColorStyles} from 'react-rte/lib/RichTextEditor';
import {getColorEntity, getTextAlignStyles} from 'react-rte/lib/lib/blockStyleFunctions';
import {makeStyles} from '@material-ui/styles';
import EditorState from "draft-js/lib/EditorState";
import SelectionState from "draft-js/lib/SelectionState";

import labels from '../../../constants/labels/patient/test/Comment.json';
import MemorizedTexts from '../comments/memorizedTexts/MemorizedTexts';
import Memo from '../../common/data/Memo';
import MyRichTextEditor from '../../common/MyRichTextEditor';
import isEmptyComment from '../../common/RichTextEditorUtil';
import replaceCarriageReturnByLineBreakElement from "../../common/CommentUtils";
import MyStore from '../../../actions/MyStore';

type Props = {
  value?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function,
  // eslint-disable-next-line flowtype/no-weak-types
  closeFunction?: ?Function,
  controlName: string,
  showMemorizedTexts?: boolean,
  memo: string | undefined,
  memoByCategory?: boolean,
  forceTextUpdate?: boolean,
  firstname?: string,
  lastname?: string,
  title: string | undefined,
  extraHeader: React.ReactElement | undefined,
  extraElements?: React.ReactElement | undefined,
  stepper: React.ReactElement | undefined
};

const useStyles = makeStyles(theme => ({
  editorMaxHeight: props => ({
    '& .public-DraftEditor-content': {
      height: props.memo ? '20vh' : '50vh',
      maxHeight: props.memo ? '20vh' : '50vh'
    },
    lineHeight: 'initial'
  }),
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

function setSelectionAtEndOfText(editorValue) {
  const editorState = editorValue.getEditorState();
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const key = blockMap.last().getKey();
  const length = blockMap.last().getLength();
  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });

  const newEditorState = EditorState.moveSelectionToEnd(editorState);
  return EditorState.forceSelection(newEditorState, selection);
}

function Comment({
  value,
  controlName,
  closeFunction,
  showMemorizedTexts,
  memo,
  memoByCategory,
  onChange,
  forceTextUpdate,
  firstname,
  lastname,
  title,
  extraHeader,
  extraElements,
  stepper
}: Props) {
  const classes = useStyles({ memo });

  const [memorizedTextsVisible, setMemorizedTextsVisible] = useState(true);
  const [text, setText] = useState(
    value
      ? RichTextEditor.createValueFromString(value, 'html', { customInlineFn: getColorEntity })
      : RichTextEditor.createEmptyValue()
  );
  console.log("Comment, value", value, text)
  const [currentTextSelected, setCurrentTextSelected] = useState('');
  const [customControls, setCustomControls] = useState([]);
  const [selectedCategoryUuid, setSelectedCategoryUuid] = useState(null);
  const editorRef = useRef();

  useEffect(() => {
    const saveCurrentSelectedTextAsMemorizedText = async () => {
      if (!selectedCategoryUuid) {
        // eslint-disable-next-line no-alert
        alert(labels.shouldSelectACategoryFirst);
        return;
      }
      console.log(`will save text "${replaceCarriageReturnByLineBreakElement(currentTextSelected)}" in category with uuid ${selectedCategoryUuid}`);
      const currentMemo = await Memo.findByKey(memo);

      const newText = Memo.replaceValuesInverted(
        replaceCarriageReturnByLineBreakElement(currentTextSelected),
        currentMemo.val.enabledPersonalInfoReplacement,
        lastname,
        firstname
      );

      await Memo.addTextToCategory(currentMemo, newText, selectedCategoryUuid);
      const tempValue = memorizedTextsVisible;
      setMemorizedTextsVisible(!tempValue);
      setTimeout(() => {
        setMemorizedTextsVisible(tempValue);
      }, 100);
    };

    const controls = [];
    if (showMemorizedTexts) {
      controls.push(
        <Tooltip key="tm" title={memorizedTextsVisible ? labels.hideMemorizedTexts : labels.displayMemorizedTexts}>
          <IconButton
            size="small"
            onClick={() => {
              setMemorizedTextsVisible(!memorizedTextsVisible);
            }}
          >
            <FavoriteBorder />
          </IconButton>
        </Tooltip>
      );
    }
    if (currentTextSelected) {
      controls.push(
        <Tooltip key="record" title={labels.saveMemorizedText}>
          <IconButton size="small" onClick={saveCurrentSelectedTextAsMemorizedText}>
            <SaveAlt />
          </IconButton>
        </Tooltip>
      );
    }

    setCustomControls(controls);
  }, [showMemorizedTexts, closeFunction, currentTextSelected, memorizedTextsVisible, selectedCategoryUuid, memo, lastname, firstname]);

  useEffect(() => {
    retrieveLastSelectedCategoryId();
    if (editorRef && editorRef.current && editorRef.current.refs && editorRef.current.refs.editor) {
      editorRef.current.refs.editor.focus();
    }
  }, []);

  const handleChange = event => {
    console.log("Comment", event)
    const mEvent = { ...event };
    if (isEmptyComment(event.target.value)) {
      mEvent.target.value = '';
    }
    onChange(mEvent);
  };

  const memorizedTextSelected = async (memorizedText, enabledPersonalInfoReplacement) => {
    const replacedText = Memo.replaceValues(memorizedText, enabledPersonalInfoReplacement, lastname, firstname);

    const hasFocus = text.getEditorState().getSelection().getHasFocus();
    if (!hasFocus) {
      const tempState = setSelectionAtEndOfText(text);
      await setText(new EditorValue(tempState));
    }

    const cd = new DataTransfer()
    cd.setData('text/html', replacedText);
    // eslint-disable-next-line no-underscore-dangle
    editorRef.current.editor._onPaste({
      preventDefault : () => {},
      clipboardData  : cd
    })

    const newValue = RichTextEditor.createValueFromString(replaceCarriageReturnByLineBreakElement(editorRef.current.editor.editor.innerText), 'html', { customInlineFn: getColorEntity });
    await setText(new EditorValue(setSelectionAtEndOfText(newValue)))

    if (forceTextUpdate) {
      onChange({ target: { name: controlName, value: replaceCarriageReturnByLineBreakElement(newValue.toString('html', { blockStyleFn: getTextAlignStyles, entityStyleFn: getColorStyles })) } });
    }
  };

  const textChange = newValue => {
    setText(newValue);
    const editorState = newValue.getEditorState();

    // Get block for current selection
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);

    // Then based on the docs for SelectionState -
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selectedText = currentBlock.getText().slice(start, end);
    setCurrentTextSelected(selectedText);
  };

  const saveLastSelectedCategoryId = lastSelectedCategoryId => {
    MyStore.set('lastSelectedCategoryId', { lastSelectedCategoryId });
  };

  const retrieveLastSelectedCategoryId = () => {
    setSelectedCategoryUuid(MyStore.get('lastSelectedCategoryId'));
  };

  const onCategorySelected = useCallback((categoryUuid)=> {
    if (categoryUuid) {
      saveLastSelectedCategoryId(categoryUuid);
      setSelectedCategoryUuid(categoryUuid);
    }
  }, [])

  return (
    <Dialog open={true} onClose={closeFunction} aria-labelledby="form-dialog-title" fullWidth maxWidth="xl" scroll="paper">
      <DialogTitle id="form-dialog-title">
        {title}
        {stepper}
        {extraHeader}
        {closeFunction && (
          <IconButton aria-label="close" className={classes.closeButton} onClick={closeFunction}>
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container item md={12}>
          <Grid item md={12}>
            {extraElements}
            <MyRichTextEditor
              value={text}
              editorRef={editorRef}
              onChange={textChange}
              onBlur={() =>
                handleChange({
                  target: {
                    name: controlName,
                    value: replaceCarriageReturnByLineBreakElement(text.toString('html', { blockStyleFn: getTextAlignStyles, entityStyleFn: getColorStyles }))
                  }
                })
              }
              className={classes.editorMaxHeight}
              customControls={customControls}
              withColors
            />
          </Grid>
          {memo && memorizedTextsVisible && (
            <Grid item md={12}>
              <MemorizedTexts
                memoKey={memo}
                onTextSelected={memorizedTextSelected}
                byCategory={memoByCategory}
                selectedCategoryUuid={selectedCategoryUuid}
                onCategorySelected={onCategorySelected}
                readOnly
                bigSize
                copyAction={() => {}}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

Comment.defaultProps = {
  showMemorizedTexts: true,
  closeFunction: null,
  forceTextUpdate: false,
  firstname: '',
  lastname: '',
  memoByCategory: true,
  value: '',
  extraElements: null
};

export default Comment;
