import React, { Component } from 'react';
import _ from 'lodash';
import { Checkbox, Grid, Typography } from '@material-ui/core';

import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis.json';
import FratrieType from '../../../common/types/anamnesis/Fratrie';
import defaultFratrie from '../../../../constants/patient/anamnesis/defaultFratrie.json';

type Props = {
  id?: string,
  span?: number,
  myValue?: {
    mere: {
      antortho: boolean,
      diffscol: boolean
    },
    pere: {
      antortho: boolean,
      diffscol: boolean
    }
  },
  fratrie?: FratrieType,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function
};

export default class AntecedentFamiliaux extends Component<Props> {
  props: Props;

  static defaultProps = {
    id: '',
    span: 12,
    myValue: {
      mere: {
        antortho: false,
        diffscol: false
      },
      pere: {
        antortho: false,
        diffscol: false
      }
    },
    fratrie: defaultFratrie,
    changeHandler: () => {}
  };

  state = {
    myValue: {
      mere: { antortho: false, diffscol: false },
      pere: { antortho: false, diffscol: false }
    }
  };

  componentDidMount() {
    const { myValue } = this.props;
    const { myValue: state } = this.state;
    this.setState({ myValue: _.merge(state, myValue) });
  }

  changeParents = name => event => {
    const { id, changeHandler } = this.props;
    const { myValue } = this.state;
    const value = _.set(myValue, name, event.target.checked);
    changeHandler({ target: { name: id, value } });
  };

  changeBrotherhood = name => event => {
    const { changeHandler, fratrie } = this.props;

    const value = _.set(fratrie, name, event.target.checked);
    changeHandler({ target: { name: 'fratrie', value } });
  };

  render() {
    const { id, span, fratrie } = this.props;
    const { myValue } = this.state;
    const {
      mere: { antortho: antorthoMere, diffscol: diffscolMere },
      pere: { antortho: antorthoPere, diffscol: diffscolPere }
    } = myValue;
    return (
      <Grid key={id} container item xs={span}>
        <Grid item xs={12} container>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Typography variant="h6">{labels.tabs.form.right.antecedent.scholarDifficulty}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">{labels.tabs.form.right.antecedent.orthophonicsAntecedent}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={4}>
            <Typography variant="h6">{labels.tabs.form.right.antecedent.mother}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              checked={diffscolMere}
              value={`${id}.mere.diffscol`}
              onChange={this.changeParents(`mere.diffscol`)}
            />
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              checked={antorthoMere}
              value={`${id}.mere.antortho`}
              onChange={this.changeParents(`mere.antortho`)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={4}>
            <Typography variant="h6">{labels.tabs.form.right.antecedent.father}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              checked={diffscolPere}
              value={`${id}.pere.diffscol`}
              onChange={this.changeParents(`pere.diffscol`)}
            />
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              checked={antorthoPere}
              value={`${id}.pere.antortho`}
              onChange={this.changeParents(`pere.antortho`)}
            />
          </Grid>
        </Grid>
        {fratrie.count.id > 0 &&
          [...Array(fratrie.count.id).keys()].map(int => (
            <Grid item xs={12} container key={`fratrie${int}`}>
              <Grid item xs={4}>
                <Typography variant="h6">
                  {fratrie.detail[int].name} ({fratrie.detail[int].age} {fratrie.detail[int].unit.label})
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  checked={fratrie.detail[int].diffscol}
                  value={`fratrie.detail[${int}].diffscol`}
                  onChange={this.changeBrotherhood(`detail[${int}].diffscol`)}
                />
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  checked={fratrie.detail[int].antortho}
                  value={`fratrie.detail[${int}].antortho`}
                  onChange={this.changeBrotherhood(`detail[${int}].antortho`)}
                />
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  }
}
