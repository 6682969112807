
function canCompute(calculationData, subtestName: string): boolean {
  const el = calculationData[subtestName];
  return (
    typeof el === 'object' &&
    ((el.ET !== undefined && el.moyenne !== undefined) || (el.pct !== undefined && el.pct.length > 0))
  );
}

export default canCompute;
