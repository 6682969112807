import React, { ChangeEvent } from 'react';
import { Divider, Grid } from '@material-ui/core';

import Header from './elements/Header';
import IconPrepend from './elements/IconPrepend';
import Input from './elements/Input';
import TextArea from './elements/TextArea';
import Radios from './elements/Radios';
import Info from './elements/Info';
import InlineRadio from './elements/InlineRadio';
import Fratrie from './elements/Fratrie';
import AntecedentFamiliaux from './elements/AntecedentFamiliaux';
import Age from './elements/Age';
import Number from './elements/Number';
import Time from './elements/Time';
import ParcoursScolaire from './elements/ParcoursScolaire';
import MedecinTraitant from './elements/MedecinTraitant';

type Props = {
  row: Array,
  // eslint-disable-next-line flowtype/no-weak-types
  values: any,
  changeHandler: ChangeEvent => void,
  deleteDoctor: ChangeEvent => void
};

const choice = ({ id, label, ...elementProps }, myValue, type, changeHandler, fratrie) => {
  const props = {
    id,
    label,
    key: `${id || label}${JSON.stringify(myValue)}`,
    ...elementProps,
    myValue,
    changeHandler,
    fratrie
  };

  return (
    {
      header: () => <Header {...props} />,
      'icon-prepend': () => <IconPrepend {...props} />,
      input: () => <Input {...props} />,
      textarea: () => <TextArea {...props} />,
      numsecu: () => <IconPrepend {...props} />,
      info: () => <Info {...props} />,
      inlineradios: () => <InlineRadio {...props} />,
      radios: () => <Radios {...props} />,
      time: () => <Time {...props} />,
      number: () => <Number {...props} />,
      age: () => <Age {...props} />,
      fratrie: () => <Fratrie {...props} />,
      custom_input: () => <Input {...props} />,
      custom_textarea: () => <TextArea {...props} />,
      custom_radios: () => <Radios {...props} />,
      '---': () => <Divider />,
      antecedentsfamiliaux: () => <AntecedentFamiliaux {...props} />,
      parcoursscolaire: () => <ParcoursScolaire {...props} />,
      applicationMedecinTraitant: () => <MedecinTraitant {...props} />,
      maskedinput: () => <Input {...props} />
    }[type] || (() => <Input {...props} />)
  )();
};

const AnamnesisRow = ({ row, values, changeHandler, deleteDoctor }: Props) => (
  <Grid item xs={12} container spacing={4}>
    {row
      .map(({ type, id, ...elementProps }) => ({
        id,
        type: id === 'medecintraitant' ? 'applicationMedecinTraitant' : type,
        deleteDoctor,
        ...elementProps
      }))
      .map(
        ({ type, label, id, ...elementProps }) =>
          choice({ type, label, id, ...elementProps }, values[id], type, changeHandler, values.fratrie) ||
          `%${type}% %${label}%`
      )}
  </Grid>
);

export default AnamnesisRow;
