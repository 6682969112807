import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function RadioButtonGroupIcon() {
  return (
    <SvgIcon viewBox="0 0 10240 10240">
      <path style={{ fill: '#252525' }} d="M4480,6400 9600,6400 9600,8320 4480,8320z" />
      <path
        style={{ fill: '#252525' }}
        d="M2240 5760c884,0 1600,716 1600,1600 0,884 -716,1600 -1600,1600 -884,0 -1600,-716 -1600,-1600 0,-884 716,-1600 1600,-1600zm0 640c-530,0 -960,430 -960,960 0,530 430,960 960,960 530,0 960,-430 960,-960 0,-530 -430,-960 -960,-960z"
      />
      <path style={{ fill: '#252525' }} d="M4480,1920 9600,1920 9600,3840 4480,3840z" />
      <path
        style={{ fill: '#252525' }}
        d="M2240 1280c884,0 1600,716 1600,1600 0,884 -716,1600 -1600,1600 -884,0 -1600,-716 -1600,-1600 0,-884 716,-1600 1600,-1600zm0 640c-530,0 -960,430 -960,960 0,530 430,960 960,960 530,0 960,-430 960,-960 0,-530 -430,-960 -960,-960z"
      />
      <path
        style={{ fill: '#252525' }}
        d="M2240 2240c353,0 640,287 640,640 0,353 -287,640 -640,640 -353,0 -640,-287 -640,-640 0,-353 287,-640 640,-640z"
      />
    </SvgIcon>
  );
}
