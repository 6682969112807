const defaultReportGenerationOptions = {
  addUserSelectedMemorizedTextsAsDefaultComment: true,
  displayBars: true,
  displayChrono: true,
  domainComments: 'comment',
  fileNameFormat: 'LF',
  fileNameUseReportDate: true,
  fileNameUseRenewalMarker: true,
  gfxcolor1: '#90faa4',
  gfxcolor2: '#d7fca4',
  gfxcolor3: '#feffa7',
  gfxcolor4: '#f7cd76',
  gfxcolor5: '#f29c4b',
  gfxcolor6: '#b95e29',
  horizBarSize: 10,
  mainChartFont: {
    bold: false,
    family: 'Arial',
    italic: 'Arial',
    size: 12
  },
  mainChartFormat: 'vertical-points',
  mainChartOptions: {
    drawColumnsSeparator: false,
    verticalColumnsMaxColumns: 20,
    verticalColumnsSort: true,
    verticalColumnsSubtype: 'detailed',
    verticalPointsBarColor: '#bbb8c4',
    verticalPointsDrawLine: false,
    verticalPointsLineColor: '#555555',
    verticalPointsMinMaxWidth: 10,
    verticalPointsRadius: 33,
    verticalPointsSubtype: 'minmax',
    verticalPointsWidth: 6
  },
  renewalOptions: {
    commentType: 'both',
    markerColor: '#000000',
    useMarker: 'diamond',
    tableType: 'double-headers'
  },
  tableChartFormat: 'simple',
  tableChartOptions: {
    renewalMark: 'triangle',
    renewalTrendUp: 'pack10/Arrow Down Blue.png'
  },
  tableDescriptor: {},
  tableOptions: {
    displayTestLabelBeforeSubtest: true,
    alwaysDisplayTestLabelBeforeSubtest: false
  },
  disableCommentHighlight: false,
  legendOptions: {
    averageDetails: true,
    aboveAverage: 'Excellent résultat',
    averageHigh: 'Résultat dans la moyenne haute',
    averageLow: 'Résultat dans la moyenne basse',
    average: 'Résultat dans la norme',
    belowAverage: 'Zone de fragilité',
    pathological: 'Zone de difficulté',
    srslypathological: 'Zone de difficulté sévère'
  },
  header: {
    addPersonalCompany: true
  }
};

export default defaultReportGenerationOptions;
