import React from 'react';
import Box from '@material-ui/core/Box';
import { Checkbox, Grid, IconButton, ListItem, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Edit, NoteAdd } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import appVersionSpecific from '../../appVersionSpecific.ortho';
import CheckupType from '../patient/CheckupType';
import AnamneseLink from './AnamneseLink';
import TestsLink from './TestsLink';
import routes from '../../constants/routes';
import labels from '../../constants/labels/home/Home.json';
import HomePatientListStyle from '../../constants/styles/HomePatientListStyle.json';
import ListStyle from '../../constants/styles/ListStyle.json';
import CheckupColorpicker, { colorPalette } from '../patient/CheckupColorpicker';
import OnLineOffLineSwitcher from '../common/OnLineOffLineSwitcher';

const bilanHasColor = props => {
  if (props && props.bilan && props.bilan.displayInfo) {
    const rowColor =
      (typeof props.bilan.displayInfo === 'object' && props.bilan.displayInfo.rowColor) ||
      (typeof props.bilan.displayInfo === 'string' && JSON.parse(props.bilan.displayInfo).rowColor) ||
      'white';
    return colorPalette[rowColor];
  }
  return colorPalette.white;
};

const useStyles = makeStyles({
  ...HomePatientListStyle,
  ...ListStyle,
  listItem: props => ({
    padding: '0',
    backgroundColor: bilanHasColor(props).bg,
    marginBottom: '0.7em',
    borderRadius: '8px',
    fontSize: '1.75em',
    lineHeight: props.testFillingRowCompactDisplay ? 'auto' : '3.5em',
    '& h6': {
      fontSize: '1.25rem',
      fontWeight: '500'
    },
    '&:hover': {
      backgroundColor: bilanHasColor(props).bgh
      // '#e8e8e8'
    }
  })
});

type Props = {
  patient: {
    firstName: string,
    lastName: string,
    // eslint-disable-next-line flowtype/no-weak-types
    _id: any,
    // eslint-disable-next-line flowtype/no-weak-types
    _rev: any,
    // eslint-disable-next-line flowtype/no-weak-types
    rdvDay: any,
    // eslint-disable-next-line flowtype/no-weak-types
    rdvTime: any,
    // eslint-disable-next-line flowtype/no-weak-types
    anamnese: any,
    // eslint-disable-next-line flowtype/no-weak-types
    bilan: any
  },
  testFillingRowCompactDisplay: boolean,
  goToPatient: () => void,
  multiAction: boolean,
  multiActionSelectedPatientList: Array<string>,
  setMultiActionSelectedPatientList: (Array<string>) => void,
  refreshList: void => void,
  patientHasAnamnesisResponseAvailable: boolean
};

const HomePatientListItem = ({
  patient: { _id, _rev, firstName, lastName, rdvDay, rdvTime, anamnese, bilan },
  testFillingRowCompactDisplay,
  multiAction,
  goToPatient,
  setMultiActionSelectedPatientList,
  multiActionSelectedPatientList,
  refreshList,
  patientHasAnamnesisResponseAvailable
}: Props) => {
  const classes = useStyles({ bilan, testFillingRowCompactDisplay });

  const checkBoxChange = (e, id) => {
    const patientIdIndexInList = multiActionSelectedPatientList.indexOf(id);
    if (patientIdIndexInList > -1) {
      setMultiActionSelectedPatientList(multiActionSelectedPatientList.filter(patientId => patientId !== id));
    } else {
      setMultiActionSelectedPatientList([...multiActionSelectedPatientList, id]);
    }
  };

  return (
    <ListItem className={classNames(classes.listItem, classes.orthoscribeList)} key={`${_id}${_rev}`}>
      <Box boxShadow={2} className={classes.listItemBox}>
        <Grid key={_id} item md={12} container alignItems="center">
          <Grid item md={1} className={classes.editFile}>
            {!multiAction && (
              <IconButton onClick={goToPatient(_id)}>
                <Edit />
              </IconButton>
            )}
            {multiAction && (
              <Checkbox
                checked={multiActionSelectedPatientList.includes(_id)}
                tabIndex={-1}
                disableRipple
                onChange={e => checkBoxChange(e, _id)}
              />
            )}
            <CheckupColorpicker
              _id={_id}
              refreshList={refreshList}
              rowColor={(bilan && bilan.displayInfo && bilan.displayInfo.rowColor) || 'white'}
            />
          </Grid>
          <Grid item md={2} onClick={goToPatient(_id)} className={classes.editFile}>
            <Typography variant="h6">{firstName}</Typography>
          </Grid>
          <Grid item md={2} onClick={goToPatient(_id)} className={classes.editFile}>
            <Typography variant="h6">{lastName}</Typography>
          </Grid>
          <Grid item md={2} onClick={goToPatient(_id)} className={classes.editFile}>
            <Tooltip
              title={`${rdvDay ? new Date(rdvDay).toLocaleDateString() : ''} ${rdvDay && rdvTime ? rdvTime : ''}`}
            >
              <Typography variant="h6">{rdvDay ? new Date(rdvDay).toLocaleDateString() : ''}</Typography>
            </Tooltip>
          </Grid>
          {appVersionSpecific.homePage.showType && (
            <Grid item md={1} onClick={goToPatient(_id)} className={classes.editFile}>
              <Typography variant="h6">
                <CheckupType bilan={bilan} />
              </Typography>
            </Grid>
          )}
          {appVersionSpecific.homePage.showAnamnesis && (
            <Grid item md={1}>
              <AnamneseLink patientId={_id} anamnese={anamnese} responseAvailable={patientHasAnamnesisResponseAvailable} />
            </Grid>
          )}
          {appVersionSpecific.homePage.showTestList && (
            <Grid item md={1}>
              <TestsLink patientId={_id} bilan={bilan} />
            </Grid>
          )}
          <Grid item md={1} container alignItems="center">
            {appVersionSpecific.homePage.showReportLink && (
              <Grid item md={12}>
                <OnLineOffLineSwitcher
                  onLine={
                    <IconButton
                      component={Link}
                      to={routes.PATIENT.getForPath({ path: routes.PATIENT.REPORT.GENERATE, patientId: _id })}
                    >
                      <Tooltip title={labels.reportCreation}>
                        <NoteAdd color="action" />
                      </Tooltip>
                    </IconButton>
                  }
                  offLine={
                    <Tooltip title={labels.reportCreationUnavailable}>
                      <NoteAdd color="red" />
                    </Tooltip>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </ListItem>
  );
};

export default HomePatientListItem;
