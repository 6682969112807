
export function hexEncode(string) {
  let result = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < string.length; i++) {
    const hex = string.charCodeAt(i).toString(16);
    result += hex.slice(-4);
  }

  return result;
}

export function hexDecode(string) {
  if (!string) return null;

  const hexes = string.match(/.{1,2}/g) || [];
  let back = '';
  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < hexes.length; j++) {
    back += String.fromCharCode(parseInt(hexes[j], 16));
  }

  return back;
}
