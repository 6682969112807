/* eslint-disable no-template-curly-in-string */
import dateFormat from 'dateformat';
import appVersionSpecific from '../../appVersionSpecific.ortho';

export const fileNameFormats = [
  {
    id: 'LF',
    label: `Nom_Prénom${appVersionSpecific.fileNameFormatSuffix}`,
    template: ({ lastName, firstName }) => `${lastName}_${firstName}${appVersionSpecific.fileNameFormatSuffix}`
  },
  {
    id: 'FL',
    label: `Prénom_Nom${appVersionSpecific.fileNameFormatSuffix}`,
    template: ({ lastName, firstName }) => `${firstName}_${lastName}${appVersionSpecific.fileNameFormatSuffix}`
  },
  {
    id: 'dLF',
    label: `Date (int.)_Nom_Prénom${appVersionSpecific.fileNameFormatSuffix}`,
    template: ({ lastName, firstName, rdvDay }) =>
      `${dateFormat(new Date(rdvDay), 'yyyy-mm-dd')}_${firstName}_${lastName}${appVersionSpecific.fileNameFormatSuffix}`
  },
  {
    id: 'LFD',
    label: `Nom_Prénom_Date (fr)${appVersionSpecific.fileNameFormatSuffix}`,
    template: ({ lastName, firstName, rdvDay }) =>
      `${lastName}_${firstName}_${dateFormat(new Date(rdvDay), 'dd-mm-yyyy')}${appVersionSpecific.fileNameFormatSuffix}`
  },
  {
    id: 'FLD',
    label: `Prénom_Nom_Date (fr)${appVersionSpecific.fileNameFormatSuffix}`,
    template: ({ lastName, firstName, rdvDay }) =>
      `${firstName}_${lastName}_${dateFormat(new Date(rdvDay), 'dd-mm-yyyy')}${appVersionSpecific.fileNameFormatSuffix}`
  },
  {
    id: 'LFd',
    label: `Nom_Prénom_Date (int.)${appVersionSpecific.fileNameFormatSuffix}`,
    template: ({ lastName, firstName, rdvDay }) =>
      `${lastName}_${firstName}_${dateFormat(new Date(rdvDay), 'yyyy-mm-dd')}${appVersionSpecific.fileNameFormatSuffix}`
  },
  {
    id: 'FLd',
    label: `Prénom_Nom_Date (int.)${appVersionSpecific.fileNameFormatSuffix}`,
    template: ({ lastName, firstName, rdvDay }) =>
      `${firstName}_${lastName}_${dateFormat(new Date(rdvDay), 'yyyy-mm-dd')}${appVersionSpecific.fileNameFormatSuffix}`
  }
];

export const fileNameFormatsById = fileNameFormats.reduce((accumulator, currentValue) => {
  accumulator[currentValue.id] = currentValue;
  return accumulator;
}, {});
