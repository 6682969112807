export default class PatientUtil {
  static TYPE = 'patients';

  static setId(id) {
    if (id && typeof id === 'string' && id.startsWith(PatientUtil.TYPE)) {
      return id;
    }
    return PatientUtil.TYPE + id;
  }

  static getId(id) {
    if (id && typeof id === 'string' && id.startsWith(PatientUtil.TYPE)) {
      return id.substring(PatientUtil.TYPE.length);
    }
    return id;
  }
}
