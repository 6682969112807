import { classMatchConstraint } from '../../../../constants/Grades';

const shouldIgnoreOnClassConstraint = (currentTest, currentTestResults) => {
  if (!(currentTest.classConstraint && currentTestResults.$$calculOption && currentTestResults.$$calculOption !== '')) {
    return false;
  }
  return !classMatchConstraint(
    currentTestResults.$$calculOption,
    currentTest.classConstraint.min,
    currentTest.classConstraint.max
  );
};

export default shouldIgnoreOnClassConstraint;
