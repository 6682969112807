import React, { Component, Fragment } from 'react';
import { Divider, Grid, IconButton, InputAdornment, MenuItem, Paper, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import {withRouter} from "react-router";
import labels from '../../constants/labels/UserProfile.json';
import RadioFormControl from '../RadioFormControl';
import civilities from '../../constants/user/civilities.json';
import TextFieldFormControl from '../TextFieldFormControl';
import countries from '../../constants/user/countries.json';
import User from '../common/data/User';
import routes from "../../constants/routes";
import EmailFieldFormControl from "../EmailFieldFormControl";

class UserForm extends Component {
  state = {
    user: {}
  };

  async componentDidMount(): void {
    const user = await User.getUser([
      'email',
      'civ',
      'firstName',
      'lastName',
      'titles',
      'address',
      'zipCode',
      'city',
      'country',
      'telFixe'
    ]);
    this.setState({ user });
  }

  change = event => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  changeWithSync = async event => {
    this.change(event);
    await this.blur(event);
  };

  blur = async event => {
    await User.save({ keyValue: event.target.name, value: event.target.value });
  };

  render() {
    const {
      user: { civ, firstName, lastName, titles, address, zipCode, city, country, telFixe, email }
    } = this.state;
    // eslint-disable-next-line react/prop-types
    const { history } = this.props;
    return (
      <Fragment>
        <Typography variant="h5" style={{ paddingBottom: '1em', paddingTop: '1em' }}>
          {labels.header.title}
        </Typography>
        <Typography component="p">{labels.header.subtitle}</Typography>
        <Divider />
        <Paper elevation={1} style={{ width: '100%', marginTop: '1em', padding: '1em' }}>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={4}>
            <RadioFormControl
              controlName="civ"
              label={labels.fields.civility}
              itemMdValue={6}
              value={civ}
              onChangeHandler={this.changeWithSync}
              choices={civilities}
            />
            <EmailFieldFormControl
              id="email"
              controlName="email"
              itemMdValue={6}
              value={email}
              disabled
              fullWidth
              onChangeHandler={()=> {}}
              label={labels.fields.email.label}
              tooltip={labels.fields.email.tooltip}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => {
                      history.push(routes.USER.MAIL_UPDATE);
                    }}>
                      <Edit />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextFieldFormControl
              id="firstName"
              controlName="firstName"
              itemMdValue={6}
              value={firstName}
              onChangeHandler={this.change}
              onBlurHandler={this.blur}
              fullWidth
              label={labels.fields.firstname}
            />
            <TextFieldFormControl
              id="lastName"
              controlName="lastName"
              itemMdValue={6}
              value={lastName}
              onChangeHandler={this.change}
              onBlurHandler={this.blur}
              fullWidth
              label={labels.fields.lastname}
            />
            <TextFieldFormControl
              id="titles"
              controlName="titles"
              rows="2"
              multiline
              itemMdValue={12}
              value={titles}
              onChangeHandler={this.change}
              onBlurHandler={this.blur}
              fullWidth
              label={labels.fields.titles}
            />
            <TextFieldFormControl
              id="address"
              controlName="address"
              itemMdValue={12}
              rows="2"
              multiline
              value={address}
              onChangeHandler={this.change}
              onBlurHandler={this.blur}
              fullWidth
              label={labels.fields.address}
            />
            <TextFieldFormControl
              id="zipCode"
              controlName="zipCode"
              itemMdValue={6}
              value={zipCode}
              onChangeHandler={this.change}
              onBlurHandler={this.blur}
              fullWidth
              label={labels.fields.zipCode}
            />
            <TextFieldFormControl
              id="city"
              controlName="city"
              itemMdValue={6}
              value={city}
              onChangeHandler={this.change}
              onBlurHandler={this.blur}
              fullWidth
              label={labels.fields.city}
            />
            <TextFieldFormControl
              id="country"
              controlName="country"
              itemMdValue={6}
              value={country}
              onChangeHandler={this.changeWithSync}
              fullWidth
              label={labels.fields.country}
              select
            >
              {countries.map(({ id, label }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </TextFieldFormControl>
            <TextFieldFormControl
              id="telFixe"
              controlName="telFixe"
              itemMdValue={6}
              value={telFixe}
              onChangeHandler={this.change}
              onBlurHandler={this.blur}
              fullWidth
              label={labels.fields.telFixe}
            />
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}

export default withRouter(UserForm);
