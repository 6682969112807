import SaveCancelRow from '../../common/SaveCancelRow';
import routes from '../../../constants/routes';
import labels from '../../../constants/labels/patient/anamnesis/AnamnesisMailImportFile.json';
import { CreateNewFolder, Person } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Grid, LinearProgress, Typography } from '@material-ui/core';
import Patient from '../../common/data/Patient';
import { useHistory } from 'react-router';
import { readAnamnesisFile } from './AnamnesisFileUtil';
import moment from 'moment/moment';
import { makeStyles } from '@material-ui/styles';
import { useDeveloperMode } from '../../common/useDeveloperMode';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold'
  }
});

const AnamnesisFileContentImport = ({fileContent, filename}) => {

  const history = useHistory();
  const classes = useStyles();
  const [patient, setPatient] = useState();
  const [rows, setRows] = useState();
  const [saveCancelRowState, setSaveCancelRowState] = useState();
  const [error, setError] = useState();
  const [anamnesis, setAnamnesis] = useState();
  const [progress, setProgress] = useState(0);
  const isDeveloperMode = useDeveloperMode();

  useEffect(() => {
    const setValuesFromPatient = () => {
      if (patient) {
        const newRows = [
          { label: labels.patient.name, value: patient.name },
          { label: labels.patient.birthDate, value: patient.birthDate },
          { label: labels.patient.complaint, value: patient.complaint },
          { label: labels.patient.responseSendingDate, value: patient.responseSendingDate },
          ...[
            isDeveloperMode
              ? { label: labels.fileContent, value: JSON.stringify(patient.unCryptedFileContent, null, 4) }
              : []
          ]
        ];
        setRows(newRows);
      }
    };

    setValuesFromPatient();
  }, [isDeveloperMode, patient]);

  useEffect(() => {
    const parseFileContent = async () => {
      if (!fileContent) return;
      try {
        const { patientFromDb, unCryptedFileContent, lines } = await readAnamnesisFile(fileContent, setProgress, setError);
        await setProgress(null);
        const { anamnese: anamnesisFromDb } = patientFromDb || {};
        if (!anamnesisFromDb) {
          setError(labels.errors.bilanNotFound);
          setRows(
            ...[
              isDeveloperMode
                ? [{ label: labels.fileContent, value: JSON.stringify(unCryptedFileContent, null, 4) }]
                : []
            ]
          );
          return;
        }
        setAnamnesis(anamnesisFromDb);
        setPatient({
          name: `${patientFromDb.firstName} ${patientFromDb.lastName}`,
          birthDate: moment(patientFromDb.birthDay, patientFromDb.birthDay.substring(4, 5) === '-' ? 'YYYY-MM-DD' : 'DD/MM/YYYY').format('DD/MM/YYYY'),
          complaint: patientFromDb.plainte,
          responseSendingDate: moment(new Date(parseInt(lines[2], 10)), 'DD/MM/YYYY').format('DD/MM/YYYY'),
          cryptedDatas: lines[4],
          id: patientFromDb._id,
          unCryptedFileContent
        });
        setSaveCancelRowState('canImport');
      } catch (e) {
        console.error(e);
        if (['Bad Key', 'Decryption error: Maybe bad key'].includes(e)) {
          setError(labels.errors.wrongKey);
          return;
        }
        setError(labels.errors.bilanNotFound);
      }
    }

    parseFileContent().then();
  }, [fileContent, isDeveloperMode]);

  const goToPatient = () => {
    history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.ANAMNESIS.RESPONSE, patientId: patient.id }));
  }

  const submitForm = async () => {
    const { values, customValues } = patient.unCryptedFileContent;
    const anamnese = { ...anamnesis, values, customValues, status: 'RESPONSE' };
    const p = await Patient.retrievePatient(patient.id);
    await Patient.updatePatient({ ...p, anamnese });
    console.log({ values, customValues });
    setSaveCancelRowState('importDone');
  };

  if (!fileContent) return null;

  return <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
    {error && <SaveCancelRow cancelRoute={routes.HOME} />}
    {saveCancelRowState === 'canImport' && (
      <SaveCancelRow
        cancelRoute={routes.HOME}
        saveAction={submitForm}
        saveButtonLabel={labels.updatePatientAction}
        saveIcon={<CreateNewFolder />}
      />
    )}
    {saveCancelRowState === 'importDone' && (
      <Fragment>
        <Grid item md={12}>
          <Typography variant="subtitle1" style={{ color: 'green' }}>
            {labels.importSuccess}
          </Typography>
        </Grid>
        <SaveCancelRow
          cancelRoute={routes.HOME}
          saveAction={goToPatient}
          saveButtonLabel={labels.consultResponse}
          saveIcon={<Person />}
        />
      </Fragment>
    )}
    <Grid item md={12} container>
      <Grid item md={12}>
        <Typography variant="subtitle1">{filename}</Typography>
      </Grid>
      {!!progress && (
        <Fragment>
          <Grid item md={12}>
            <Typography variant="subtitle1">{labels.uncryptingProgress}</Typography>
          </Grid>
          <Grid item md={12}>
            <LinearProgress variant="determinate" value={progress} />
          </Grid>
        </Fragment>
      )}
      <Grid item md={12}>
        <Typography variant="subtitle1">{error}</Typography>
      </Grid>
      {rows &&
        rows.map(({ label, value }) => (
          <Fragment key={label}>
            <Grid item md={12}>
              <Divider />
            </Grid>
            <Grid item md={6}>
              <Typography variant="body1" className={classes.bold}>
                {label}
              </Typography>
            </Grid>
            <Grid item md={6}>
              {value}
            </Grid>
          </Fragment>
        ))}
    </Grid>
  </Grid>;
}

export default AnamnesisFileContentImport;
