import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';

import CheckupCommentEditor from './CheckupCommentEditor';

type Props = {
  title: string,
  value: string,
  controlName: string,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler: Function,
  memo: string,
  lastName: string | undefined,
  firstName: string | undefined,
  extraElements: React.ReactElement | undefined
};

const CommentTextWithTitle = ({
  title,
  value,
  changeHandler,
  controlName,
  memo,
  lastName,
  firstName,
  extraElements
}: Props) => (
  <Fragment>
    <Grid xs={12} item>
      <Typography variant="h6">{title}</Typography>
    </Grid>
    <Grid xs={12} item>
      <CheckupCommentEditor
        value={value}
        onChange={changeHandler}
        controlName={controlName}
        memo={memo}
        memoByCategory
        firstName={firstName}
        lastName={lastName}
        defaultLabel={title}
        title={title}
        extraElements={extraElements}
      />
    </Grid>
  </Fragment>
);

export default CommentTextWithTitle;
