export const inputIncludesErrorScoreStandardDeviation = (input) => input.includes("ERR_SCORE_ET");
export const getErrorScoreStandardDeviation = (value, percentileObject, testRowData) => ({
  returnValue: {
    inErrScoreET: true,
    score: value,
    errScoreET: Math.round(((value - testRowData.moyenne) / testRowData.ET) * 100) / 100,
    scoreCentile: percentileObject.percentileValue || percentileObject.value,
  },
  percentileObject
});
