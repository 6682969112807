import React, { Fragment, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import moment from 'moment';
import _ from 'lodash';

import SaveCancelRow from '../../common/SaveCancelRow';
import labels from '../../../constants/labels/patient/import/HappyNeuronImport.json';
import Patient, { defaultPatient } from '../../common/data/Patient';
import routes from '../../../constants/routes';
import ImportMessage from './ImportMessage';
import ImportPatientList from './ImportPatientList';
import ImportPassationList from './ImportPassationList';
import importers, {
  createPatientFromPassation,
  happyneuronExalangClassToOrthoscribeClasseId
} from '../test/importers/Importers';
import mergeSelectedPassations from './utils/MergeSelectedPassations';
import useWindowQuery from '../../common/useWindowQuery';
import { hnParamsDownload } from './utils/HappyneuronFileDownloader';

function createPatientFromFilecontent(fileContent) {
  const birthDay = fileContent.patient.birthDate ? moment(fileContent.patient.birthDate, 'DD/MM/YYYY') : null;

  const sexe = fileContent.patient.civilite === 1 ? 'F' : 'H';
  return {
    firstName: fileContent.patient.firstName,
    lastName: fileContent.patient.lastName,
    sexe,
    birthDay,
    meta: { hn: { uid: fileContent.patient.userId } },
    curclasse: happyneuronExalangClassToOrthoscribeClasseId(fileContent.classes, fileContent.patient.classId || fileContent.patient.class)
  };
}

async function happyneuronImportCreatePatient(patientFromPassation, fileContent) {
  const p = await defaultPatient();
  const newPatient = _.merge(p, patientFromPassation);

  if (fileContent && fileContent.patient) {
    if (typeof fileContent.patient.plainte === 'string') {
      newPatient.plainte = fileContent.patient.plainte;
    }
    if (typeof fileContent.patient.class === 'number') {
      const oclss = happyneuronExalangClassToOrthoscribeClasseId(fileContent.classes, fileContent.patient.class);
      if (oclss) {
        newPatient.curclasse = oclss.toString(10);
      }
    }
    if (typeof fileContent.patient.birthDate === 'string') {
      newPatient.birthDay = moment(fileContent.patient.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
    if (typeof fileContent.patient.email === 'string') {
      newPatient.contactMail = fileContent.patient.email;
    }
  }
  return Patient.createPatient(newPatient);
}

async function getCheckupTestListAndCotation(selectedPassations, patient, eraseResults) {
  const { bilan, cotation } = patient;
  console.log({ selectedPassations, bilan, cotation, patient });
  const checkupTestList = Object.values(selectedPassations)
    .filter(({ testCode }) => !bilan.tests.includes({ testCode }))
    .map(({ testCode }) => ({ testCode }));
  const updatedCotations = await mergeSelectedPassations(
    selectedPassations,
    cotation,
    patient,
    eraseResults,
    bilan.reportOptions
  );
  return { checkupTestList, updatedCotations };
}

const HappyNeuronImport = () => {
  const history = useHistory();
  const [filePathParsingError, setFilePathParsingError] = useState(false);
  const [selectedPassations, setSelectedPassations] = useState({});
  const [fileContent, setFileContent] = useState(null);
  const query = useWindowQuery();

  const ticket = query.get("ticket");
  const userId = query.get("userId");

  useEffect(() => {
    const readFile = async () => {
      try {
        const hnJsonContent = await hnParamsDownload({ticket, userId});
        console.log({hnJsonContent})
        setFilePathParsingError(false);
        setFileContent(hnJsonContent);
      } catch (e) {
        console.log('Error downloading file.');
        setFilePathParsingError(true);
      }
    };

    readFile().then();
  }, [query, ticket, userId]);

  async function createNewPatient() {
    const [, firstSelectedPassation] = Object.entries(selectedPassations).shift();

    const patientFromPassation = importers('UTF-8')[firstSelectedPassation.testCode]
      ? createPatientFromPassation(firstSelectedPassation, fileContent)
      : createPatientFromFilecontent(fileContent);
    const firstDate = Object.entries(selectedPassations)
      .map(([, { passationDate }]) => moment(passationDate.substring(0, 10), 'DD/MM/YYYY'))
      .filter(moment.isMoment)
      .sort((a, b) => a.isAfter(b))
      .shift();
    patientFromPassation.rdvDay = firstDate.format('YYYY-MM-DD');

    return happyneuronImportCreatePatient(patientFromPassation, fileContent);
  }

  const getPatient = async id => {
    if (id === 'new') {
      return createNewPatient();
    }
    return Patient.retrievePatient(id);
  };

  const importToPatient = ({ patientId, eraseResults }) => async () => {
    const patient = await getPatient(patientId);
    const { checkupTestList, updatedCotations } = await getCheckupTestListAndCotation(
      selectedPassations,
      patient,
      eraseResults
    );
    await Patient.updatePatient({
      ...patient,
      bilan: {
        ...patient.bilan,
        tests: [...patient.bilan.tests, ...checkupTestList]
      },
      cotation: updatedCotations
    });
    history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId: patient._id }));
  };

  const createRenewalCheckup = async ({ patientId: patientInitId }) => {
    const patientInit = await Patient.retrievePatient(patientInitId);
    const { bilan: bilanInit } = patientInit;
    const patient = await createNewPatient();
    const { checkupTestList, updatedCotations } = await getCheckupTestListAndCotation(selectedPassations, patient);
    await Patient.updatePatient({
      ...patient,
      typeBilan: 'renouvellement',
      initialPatientId: patientInitId,
      anamnese: {
        ...patient.anamnese,
        modelId: patientInit.anamnese.modelId,
        definitions: patientInit.anamnese.definitions,
        values: patientInit.anamnese.values
      },
      bilan: {
        ...patient.bilan,
        tests: [...patient.bilan.tests, ...checkupTestList],
        initialAssessmentUUID: bilanInit.uuid,
        initialAssessmentTests: bilanInit.tests,
        renewalNumber: (bilanInit.renewalNumber || 0) + 1
      },
      cotation: updatedCotations
    });
    history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId: patient._id }));
  };

  const noAssessmentToImport = async () => {
    console.log('##################### noAssessmentToImport #####################');
    if (fileContent.patient && fileContent.patient.userId) {
      const [hnPatient] = await Patient.getHappyneuronPatient(fileContent.patient.userId);
      if (hnPatient && hnPatient.bilan && hnPatient.bilan.tests && hnPatient.bilan.tests.length) {
        console.log('##################### noAssessmentToImport, patient found, tests found #####################');
        history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId: hnPatient._id }));
      } else if (hnPatient && hnPatient._id) {
        console.log('##################### noAssessmentToImport, patient found, no tests found #####################');
        history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.ANAMNESIS.default, patientId: hnPatient._id }));
      } else {
        console.log('##################### noAssessmentToImport, no patient found #####################');
        const patientFromFilecontent = createPatientFromFilecontent(fileContent);
        const patient = await happyneuronImportCreatePatient(patientFromFilecontent, fileContent);
        history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.ANAMNESIS.default, patientId: patient._id }));
      }
    }
  };

  console.log({filePathParsingError, fileContent})
  return (
    <Fragment>
      <SaveCancelRow title={labels.title} />
      {/* {filePathExist && <Typography>{hnParamFilePath}</Typography>} */}
      {/* {!filePathExist && <Typography>{labels.filePathNotExist}</Typography>} */}
      {filePathParsingError && <Typography>{labels.filePathParsingError}</Typography>}
      <ImportMessage />
      {!filePathParsingError && fileContent && (
        <Fragment>
          <ImportPassationList
            ticket={ticket}
            userId={userId}
            fileContent={fileContent}
            selectedPassations={selectedPassations}
            setSelectedPassations={setSelectedPassations}
            noAssessmentToImport={noAssessmentToImport}
          />
          {Object.entries(selectedPassations).length > 0 && (
            <ImportPatientList
              importToPatient={importToPatient}
              createRenewalCheckup={createRenewalCheckup}
              firstname={fileContent.patient.firstName}
              lastname={fileContent.patient.lastName}
              passationDates={Object.values(selectedPassations).map(({ passationDate }) => passationDate)}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default HappyNeuronImport;
