import React, { Component, Fragment } from 'react';
import { Divider, Grid, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { Alert, AlertTitle } from '@material-ui/lab';

import { Edit, Email, Refresh } from '@material-ui/icons';
import Patient from '../../common/data/Patient';
import AnamnesisModel from '../../common/data/AnamnesisModel';
import SaveCancelRow from '../../common/SaveCancelRow';
import labels from '../../../constants/labels/patient/anamnesis/AnamnesisMailRecap.json';
import routes from '../../../constants/routes';
import TextFieldFormControl from '../../TextFieldFormControl';
import MyStateFullEditor from '../../common/MyStateFullEditor';
import User from '../../common/data/User';
import OnLineOffLineSwitcher from '../../common/OnLineOffLineSwitcher';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  classes: any,
  // eslint-disable-next-line flowtype/no-weak-types
  history: any,
  match: ?{ params: ?{ patientId: ?string } }
};

const styles = () => ({
  bold: {
    fontWeight: 'bold'
  },
  bordered: {
    border: '1px solid',
    borderRadius: '5px'
  },
  blueBackground: {
    backgroundColor: 'lightblue'
  }
});

async function getAnamnesisModel(anamnesis) {
  if (anamnesis.modelId === 0) {
    return AnamnesisModel.getDefault();
  }
  const anamnesisModel = await AnamnesisModel.get(anamnesis.modelId);
  return anamnesisModel;
}

async function buildMailBodyFromModel(
  modelMailBody,
  { contactSexe, firstName, lastName, rdvDay, rdvTime, rdvDuration }
) {
  const { firstName: orthoFirstName, lastName: orthoLastName } = await User.getUser(['firstName', 'lastName']);

  return modelMailBody
    .replace(/@CIVILITE/gi, contactSexe === 'H' ? 'Monsieur' : 'Madame')
    .replace(/@PRENOM/gi, firstName)
    .replace(/@NOM/gi, lastName)
    .replace(/@JOUR/gi, moment(rdvDay, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    .replace(/@HEURE/gi, (rdvTime || '1:00').replace(':', 'h'))
    .replace(/@DUREE/gi, rdvDuration || 'à définir')
    .replace(/@ORTHOPRENOM/gi, orthoFirstName)
    .replace(/@ORTHONOM/gi, orthoLastName);
}

class AnamnesisMailRecap extends Component<Props> {
  props: Props;

  state = {
    patient: {},
    anamnesis: {},
    isEditing: false,
    anamnesisSave: null
  };

  async componentDidMount(): void {
    const {
      match: {
        params: { patientId }
      }
    } = this.props;

    const patient = await Patient.retrievePatient(patientId);
    const { anamnese: anamnesis } = patient;
    const anamnesisModel = await getAnamnesisModel(anamnesis);
    anamnesis.name = anamnesis.name || anamnesisModel.name;
    anamnesis.mailBody = anamnesis.mailBody || (await buildMailBodyFromModel(anamnesisModel.mailBody, patient));
    anamnesis.mailSubject = anamnesis.mailSubject || anamnesisModel.mailSubject;
    anamnesis.definitions = anamnesis.definitions || anamnesisModel.definitions;
    anamnesis.customDefinitions = anamnesis.customDefinitions || anamnesisModel.customDefinitions;
    const updatedPatient = await Patient.updatePatient({ ...patient, anamnese: anamnesis });
    this.setState({ patient: updatedPatient, anamnesis: updatedPatient.anamnese });
  }

  sendMail = async () => {
    const { history } = this.props;
    const { patient } = this.state;
    history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.ANAMNESIS.MAIL_RESULT, patientId: patient._id }));
  };

  resetMail = async () => {
    const { anamnesis } = this.state;
    const anamnesisModel = await AnamnesisModel.get(anamnesis.modelId);
    anamnesis.mailBody = anamnesisModel.mailBody;
    anamnesis.mailSubject = anamnesisModel.mailSubject;
    this.setState({ anamnesis });
  };

  cancelEdit = () => {
    const { anamnesisSave } = this.state;
    this.setState({ isEditing: false, anamnesis: anamnesisSave });
  };

  setEditState = () => {
    const { anamnesis } = this.state;
    this.setState({ isEditing: true, anamnesisSave: _.cloneDeep(anamnesis) });
  };

  endEdit = () => {
    this.setState({ isEditing: false });
  };

  onChange = async event => {
    const { patient, anamnesis } = this.state;
    const anamnesisUpdated = _.set(anamnesis, event.target.name, event.target.value);
    anamnesisUpdated.modificationDate = new Date();
    const updatedPatient = await Patient.updatePatient({ ...patient, anamnese: anamnesisUpdated });
    this.setState({ patient: updatedPatient, anamnesis: updatedPatient.anamnese });
  };

  render(): React.ReactNode {
    const { classes } = this.props;
    const { patient, anamnesis, isEditing } = this.state;
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">{labels.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {labels.subtitle}
              <span className={classes.bold}>
                {patient.firstName} {patient.lastName}
              </span>{' '}
              :
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {isEditing ? (
          <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly">
            <SaveCancelRow cancelAction={this.cancelEdit} saveAction={this.endEdit} saveTooltip={labels.next} />
            <TextFieldFormControl
              id="mailSubject"
              controlName="mailSubject"
              itemMdValue={12}
              label={labels.subject}
              value={anamnesis.mailSubject}
              onChangeHandler={this.onChange}
              fullWidth
            />
            <MyStateFullEditor value={anamnesis.mailBody} onChange={this.onChange} controlName="mailBody" />
            <Grid item xs={12} className={classes.blueBackground}>
              <span>{labels.notaBene}</span>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly">
            <OnLineOffLineSwitcher
              onLine={
                <SaveCancelRow
                  cancelRoute={routes.PATIENT.getForPath({
                    path: routes.PATIENT.ANAMNESIS.default,
                    patientId: patient._id
                  })}
                  saveAction={this.sendMail}
                  saveIcon={<Email />}
                  saveButtonLabel={labels.next}
                  saveTooltip={labels.next}
                />
              }
              offLine={
                <Fragment>
                  <div style={{ width: '100%' }}>
                    <Alert severity="info">
                      <AlertTitle>{labels.currentlyOffLine}</AlertTitle>
                      <Typography>{labels.offLineMailSendingDisabled}</Typography>
                    </Alert>
                  </div>
                  <SaveCancelRow
                    cancelRoute={routes.PATIENT.getForPath({
                      path: routes.PATIENT.ANAMNESIS.default,
                      patientId: patient._id
                    })}
                    saveAction={() => {}}
                    saveIcon={<Email />}
                    saveButtonLabel={labels.next}
                    saveActionDisabled
                    saveTooltip={labels.offLineMailSendingDisabled}
                  />
                </Fragment>
              }
            />
            <Grid item xs={12}>
              <Tooltip title={labels.clicToEdit}>
                <IconButton onClick={this.setEditState}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={labels.resetValues}>
                <IconButton onClick={this.resetMail}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={12} onClick={this.setEditState}>
              <Tooltip title={labels.clicToEdit}>
                <Typography variant="h6" gutterBottom>
                  {labels.subject} : {anamnesis.mailSubject}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} className={classes.bordered} onClick={this.setEditState}>
              <Tooltip title={labels.clicToEdit}>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: anamnesis.mailBody }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AnamnesisMailRecap);
