import { gzip } from 'pako';

import log from '../../Logger';
import User from '../../common/data/User';
import server from '../../../constants/server';

export default class ReportGeneratorUtil {
  static GENERATION_TYPE_GENERATE_REPORT = 0;

  static GENERATION_TYPE_GENERATE_GRAPH = 1;

  static GENERATION_TYPE_GENERATE_CHART = 2;

  static GENERATION_TYPE_DECRYPT_LICENCE = 3;

  static GENERATION_TYPE_GENERATE_ANAMNESIS = 4;

  static GENERATION_TYPE_TRANSFORM_MEMO = 5;

  static GENERATION_TYPE_DECRYPT_MEMO_FILE = 6;

  static GENERATION_TYPE_ENCRYPT_MEMO_FILE = 7;

  static async getContentHash(content) {
    const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(JSON.stringify(content)));
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }

  static async decryptLicence(cryptedLicence) {
    return new Promise(async (resolve, reject) => {
      try {
        const message = await this.callHttpReportGeneratorLauncher(
          '',
          cryptedLicence,
          '',
          ReportGeneratorUtil.GENERATION_TYPE_DECRYPT_LICENCE,
          null,
          null
        );

        const fileReader = new FileReader();
        fileReader.onload = async event => {
          const licence = new TextDecoder().decode(event.target.result);
          const parsed = JSON.parse(licence);
          resolve(parsed);
        };
        if (message) {
          fileReader.readAsArrayBuffer(message);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  static async generateAnamnesis(content) {
    return new Promise(async (resolve, reject) => {
      try {
        const { anamnesis: contentAnamnesis } = content;
        const hashHex = await this.getContentHash(contentAnamnesis);
        const message = await this.callHttpReportGeneratorLauncher(
          '',
          content,
          '',
          ReportGeneratorUtil.GENERATION_TYPE_GENERATE_ANAMNESIS,
          null,
          null,
          hashHex
        );
        const fileReader = new FileReader();
        fileReader.onload = async event => {
          const anamnesis = new TextDecoder().decode(event.target.result);
          resolve(anamnesis);
        };
        if (message) {
          fileReader.readAsArrayBuffer(message);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  static async generateGraph(content) {
    return this.generateSvg(content, ReportGeneratorUtil.GENERATION_TYPE_GENERATE_GRAPH);
  }

  static async generateChart1(content) {
    return this.generateSvg(content, ReportGeneratorUtil.GENERATION_TYPE_GENERATE_CHART, -0.88, -1.22);
  }

  static async generateChart2(content) {
    return this.generateSvg(content, ReportGeneratorUtil.GENERATION_TYPE_GENERATE_CHART, -1.31, -0.81);
  }

  static async generateSvg(content, generationType, zscore, initial) {
    return new Promise(async (resolve, reject) => {
      try {
        const message = await this.callHttpReportGeneratorLauncher('', content, '', generationType, zscore, initial);
        const fileReader = new FileReader();
        fileReader.onload = async event => {
          const originalGraph = new TextDecoder().decode(event.target.result);
          const indexOfWidth = originalGraph.indexOf("width='");
          const endIndexOfWidth = originalGraph.indexOf("px'", indexOfWidth);
          const width = originalGraph.substring(indexOfWidth + 7, endIndexOfWidth);

          const indexOfHeight = originalGraph.indexOf("height='");
          const endIndexOfHeight = originalGraph.indexOf("px'", indexOfHeight);
          const height = originalGraph.substring(indexOfHeight + 8, endIndexOfHeight);

          const graph = originalGraph
            .replace("width='", `viewBox="0 0 ${width} ${height}" width='`)
            .replace(`width='${width}px'`, '')
            .replace(`height='${height}px'`, '');

          resolve(graph);
        };
        if (message) {
          fileReader.readAsArrayBuffer(message);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  static async transformMemo(content) {
    return new Promise(async (resolve, reject) => {
      try {
        const message = await this.callHttpReportGeneratorLauncher(
          '',
          { val: Array.from(content.val) },
          '',
          ReportGeneratorUtil.GENERATION_TYPE_TRANSFORM_MEMO
        );
        const fileReader = new FileReader();
        fileReader.onload = async event => {
          const memo = new TextDecoder().decode(event.target.result);
          resolve(memo);
        };
        if (message) {
          fileReader.readAsArrayBuffer(message);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  static async decryptMemorizedTextsFile(fileContent) {
    return new Promise(async (resolve, reject) => {
      try {
        const message = await this.callHttpReportGeneratorLauncher(
          '',
          '',
          fileContent,
          ReportGeneratorUtil.GENERATION_TYPE_DECRYPT_MEMO_FILE
        );
        const fileReader = new FileReader();
        fileReader.onload = async event => {
          const memo = new TextDecoder().decode(event.target.result);
          resolve(memo);
        };
        if (message) {
          fileReader.readAsArrayBuffer(message);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  static async encryptMemorizedTextsFile(memos) {
    return new Promise(async (resolve, reject) => {
      try {
        const message = await this.callHttpReportGeneratorLauncher(
          '',
          memos,
          '',
          ReportGeneratorUtil.GENERATION_TYPE_ENCRYPT_MEMO_FILE
        );
        resolve(message);
      } catch (e) {
        reject(e);
      }
    });
  }

  static async generateReport(modelName, content, model) {
    return this.callHttpReportGeneratorLauncher(
      modelName,
      content,
      model,
      ReportGeneratorUtil.GENERATION_TYPE_GENERATE_REPORT
    );
  }

  static async generateReportUrl(url, content) {
    return this.callHttpReportGeneratorLauncherURL(
      url,
      content,
      ReportGeneratorUtil.GENERATION_TYPE_GENERATE_REPORT
    );
  }

  static async generateReportS3(objectKey, content) {
    return this.callHttpReportGeneratorLauncherS3(
      objectKey,
      content,
      ReportGeneratorUtil.GENERATION_TYPE_GENERATE_REPORT
    );
  }
  
  static async callHttpReportGeneratorLauncher(modelName, content, model, generationType, zscore, initial, hashHex) {
    const { val: securedWebSiteHost } = await User.retrieveValueFromDb({
      keyValue: 'securedWebSiteHost',
      defaultValue: server.secured.url
    });
    const { val: jwToken } = await User.retrieveValueFromDb({
      keyValue: 'jwt'
    });

    const formData = new FormData();
    formData.append('modelName', modelName);
    formData.append('parameters', new Blob([gzip(JSON.stringify(content))], { type: 'application/json' }));
    formData.append('model', new Blob([model], { type: 'application/octet-stream' }));
    formData.append('generationType', generationType);
    formData.append('zscore', zscore);
    formData.append('initial', initial);

    const hash = hashHex || (await this.getContentHash(content));

    try {
      const url = `${securedWebSiteHost}/api/reportGeneration/generate?hash=${hash}`;

      const init = {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${jwToken}`,
          AcceptEncoding: 'gzip'
        }),
        body: formData
      };

      const request = new Request(url, init);

      const response = await fetch(request, init);
      // log.info({ response });
      if (response.status === 200) {
        const message = await response.blob();
        return message;
      }
      throw new Error('Network response was not ok', response);
    } catch (e) {
      log.error(e);
      throw e;
    }
  }


  static async callHttpReportGeneratorLauncherURL(url, content, generationType) {
    return await ReportGeneratorUtil.callHttpReportGeneratorLauncherFromDocumentStorage(url, content, generationType, 'url', `generateFromUrl`);
  }

  static async callHttpReportGeneratorLauncherS3(objectKey, content, generationType) {
    return await ReportGeneratorUtil.callHttpReportGeneratorLauncherFromDocumentStorage(objectKey, content, generationType, 'objectKey', `generateFromS3`);
  }

  static async callHttpReportGeneratorLauncherFromDocumentStorage(objectKey, content, generationType, parameterName: string, methodName: string) {
    const { val: securedWebSiteHost } = await User.retrieveValueFromDb({
      keyValue: 'securedWebSiteHost',
      defaultValue: server.secured.url
    });
    const { val: jwToken } = await User.retrieveValueFromDb({
      keyValue: 'jwt'
    });

    const formData = new FormData();
    formData.append(parameterName, objectKey);
    formData.append('parameters', new Blob([gzip(JSON.stringify(content))], { type: 'application/json' }));
    formData.append('generationType', generationType);

    const hash = await this.getContentHash(content);

    try {
      const url = `${securedWebSiteHost}/api/reportGeneration/${methodName}?hash=${hash}`;

      const init = {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${jwToken}`,
          AcceptEncoding: 'gzip'
        }),
        body: formData
      };

      const request = new Request(url, init);

      const response = await fetch(request, init);
      // log.info({ response });
      if (response.status === 200) {
        const message = await response.blob();
        return message;
      }
      throw new Error('Network response was not ok', response);
    } catch (e) {
      log.error(e);
      throw e;
    }
  }
}
