import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

type Props = {
  open: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  handleClose: Function,
  title?: string,
  contentText?: string,
  cancelButton?: boolean,
  okButtonLabel?: string,
  cancelButtonLabel?: string
};

function OkCancelDialog({ open, handleClose, title, contentText, cancelButton, okButtonLabel, cancelButtonLabel }: Props) {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelButton && (
          <Button onClick={handleClose(false)} color="primary">
            {cancelButtonLabel}
          </Button>
        )}
        <Button onClick={handleClose(true)} color="primary">
          {okButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

OkCancelDialog.defaultProps = {
  title: '',
  contentText: '',
  cancelButton: true,
  okButtonLabel: 'Confirmer',
  cancelButtonLabel: 'Annuler'
};

export default OkCancelDialog;
