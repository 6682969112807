import React from 'react';

import OnLineOffLineSwitcher from '../../common/OnLineOffLineSwitcher';
import OnLineReportGraphicsChart from './OnLineReportGraphicsChart';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  content: any,
  debounceDelay: number | undefined
};

const ReportGraphicsChart = ({ content, debounceDelay = 5000 }: Props) => (
  <OnLineOffLineSwitcher onLine={<OnLineReportGraphicsChart content={content} debounceDelay={debounceDelay} />} />
);

export default ReportGraphicsChart;
