import localDatabases from '../../../database/database';
import log from '../../Logger';

export default class CheckUpModel {
  static TYPE = 'modeles';

  static async retrieveModelList() {
    const documents = await localDatabases.getInstance().v1databaseRepository.find({
      selector: {
        _id: { $gte: null },
        dbtype: CheckUpModel.TYPE
      }
    });
    const { docs } = documents;
    const updatedDocs = docs.map(({ _id, elements, ...cleanedModel }) => ({
      ...cleanedModel,
      elements,
      _id: CheckUpModel.getId(_id)
    }));
    log.info('CheckUpModel.retrieveModelList(), found docs: ');
    log.table(updatedDocs);
    return updatedDocs;
  }

  static async get(id) {
    const updatedId = CheckUpModel.setId(id);
    try {
      const checkUpModel = await localDatabases.getInstance().v1databaseRepository.get(updatedId);
      checkUpModel._id = CheckUpModel.getId(checkUpModel._id);
      log.info('CheckUpModel.get(), checkUpModel: ', checkUpModel);
      return checkUpModel;
    } catch (err) {
      log.error('Error getting database information, checkUpModel', err);
      throw err;
    }
  }

  static async save(checkUpModelToSave) {
    const { ...checkUpModel } = checkUpModelToSave;
    checkUpModel.dbtype = CheckUpModel.TYPE;
    checkUpModel._id = CheckUpModel.setId(checkUpModel._id);
    const updatedcheckUpModel = await localDatabases.getInstance().v1databaseRepository.put(checkUpModel);
    checkUpModel._rev = updatedcheckUpModel.rev;
    log.info('CheckUpModel.save(), checkUpModel: ', checkUpModel);
    checkUpModel._id = CheckUpModel.getId(checkUpModel._id);
    return checkUpModel;
  }

  static async delete(checkUpModelToDelete) {
    const { ...checkUpModel } = checkUpModelToDelete;
    checkUpModel._id = CheckUpModel.setId(checkUpModel._id);
    await localDatabases.getInstance().v1databaseRepository.remove(checkUpModel);
  }

  static setId(id) {
    if (id && typeof id === 'string' && id.startsWith(CheckUpModel.TYPE)) {
      return id;
    }
    return CheckUpModel.TYPE + id;
  }

  static getId(id) {
    if (id && typeof id === 'string' && id.startsWith(CheckUpModel.TYPE)) {
      return id.substring(CheckUpModel.TYPE.length);
    }
    return id;
  }
}
