import React from 'react';
import AnamneseEdit from '../../components/configuration/anamnesis/AnamneseEdit';

type Props = {
  match: ?{ params: ?{ anamnesisId: ?string } }
};

const AnamneseEditPage = ({
  match: {
    params: { anamnesisId }
  }
}: Props) => <AnamneseEdit id={anamnesisId} />;

export default AnamneseEditPage;
