import User from './User';
import { fetchJson } from '../OrthoscribeWebSite';
import server from '../../../constants/server';
import labels from '../../../constants/labels/common/data/AnamnesisResponses.json';

export default class AnamnesisResponses {
  static async listResponsesOnDocumentStorage() {
    const { val: jwToken } = await User.retrieveValueFromDb({
      keyValue: 'jwt'
    });
    const init = {
      headers: new Headers({
        Authorization: `Bearer ${jwToken}`
      })
    };
    const list = await fetchJson(server.documentStorage.anamnesis, init);
    return list.map(({ objectKey, size }) => ({label: objectKey.split("/")[1], fullName: objectKey, size}));
  }

  static async listResponsesOnDocumentStorageForPatient(patientId) {
    const responses = await AnamnesisResponses.listResponsesOnDocumentStorage();
    return AnamnesisResponses.sortResponsesByDate(AnamnesisResponses.filterResponsesForPatientId(responses, patientId));
  }

  static sortResponsesByDate(responses) {
    return responses.sort(({label: labelA}, {label: labelB}) => {
      const a = AnamnesisResponses.getDateFromFileName(labelA);
      const b = AnamnesisResponses.getDateFromFileName(labelB);
      return a-b;
    });
  }

  static getDateFromFileName(label) {
    return label.split('.')[0].split('-').slice(-2).join('');
  }

  static getLabelDateFromFileName(label) {
    const string = AnamnesisResponses.getDateFromFileName(label);
    const year = string.slice(0,4);
    const month = string.slice(4,6);
    const day = string.slice(6,8);
    const hour = string.slice(8,10);
    const minute = string.slice(10,12);
    const second = string.slice(12,14);

    return `${labels.answerDate} ${day}/${month}/${year} à ${hour}:${minute}:${second}`;
  }

  static filterResponsesForPatientId(responses, patientId) {
    return responses.filter(({label}) => label.includes(patientId));
  }

  static patientHasResponseAvailable(responses, patientId) {
    return !!AnamnesisResponses.filterResponsesForPatientId(responses, patientId).length;
  }

  static async downloadFileFromServer(filename) {
    const readFileContent = blob =>
      new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onload = async event => resolve(event.target.result);
        fileReader.readAsArrayBuffer(blob);
      });

    const response = await fetch(`${server.documentStorage.anamnesis}/download/${filename}`);
    const message = await response.blob();
    const fileContentArrayBuffer = await readFileContent(message);

    const content = new TextDecoder().decode(fileContentArrayBuffer);
    return content;
  }
}
