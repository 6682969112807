import React, {useEffect, useState} from "react";
import {
  Switch,
  Tooltip
} from "@material-ui/core";
import labels from "../../../../constants/labels/configuration/memorizedTexts/TestComments.json";
import {MEMO_PREFIX} from "../../../common/data/MemoUtil";
import {allLabeledTests, filteredLabeledTests} from "../../../../constants/tests/tests";
import User from "../../../common/data/User";
import CustomTests from "../../../common/data/CustomTests";
import log from "../../../Logger";
import CommentType from "./CommentType";
import appVersionSpecific from '../../../../appVersionSpecific.ortho';

type Props = {
  changeSelectedTestCodeAndMemoKey: (string, string) => void,
  filteredMemoKeys: Array<string>,
  selectedTestCode: {
    testCode: string | null | undefined
  } | null | undefined
};

const TestComments = ({changeSelectedTestCodeAndMemoKey, filteredMemoKeys, selectedTestCode}: Props) => {

  const [showOnlyUserSelectedTests, setShowOnlyUserSelectedTests] = useState(true);
  const [testList, setTestList] = useState([]);
  const [userHasSelectedTestCodes, setUserHasSelectedTestCodes] = useState(false);

  useEffect(() => {
    const updateContent = async () => {
      try {
        if (appVersionSpecific.isHappyNeuron) {
          setTestList(allLabeledTests);
        } else {
          const userSelectedTests = await User.retrieveValueFromDb({ keyValue: 'tests', defaultValue: [] });
          const userSelectedTestCodes = userSelectedTests.val.map(({ testCode }) => testCode);
          const personalTests = await CustomTests.findAll();

          const allTests = [
            ...filteredLabeledTests.filter(({ testCode }) => {
              if (!showOnlyUserSelectedTests) return true;
              if (!userSelectedTestCodes.length) {
                return true;
              }
              setUserHasSelectedTestCodes(true);
              return userSelectedTestCodes.includes(testCode);
            }),
            ...personalTests
          ];
          setTestList(allTests);
        }
      } catch (err) {
        log.error('Error retrieving datas', err);
      }
    };

    updateContent().then();
  }, [showOnlyUserSelectedTests]);

  return <CommentType
    label={labels.tests}
    listItemSecondaryAction={userHasSelectedTestCodes ? {
      label:labels.showOnlyUserSelectedTests[showOnlyUserSelectedTests ? 'true' : 'false'].label,
      control: <Tooltip title={labels.showOnlyUserSelectedTests[showOnlyUserSelectedTests ? 'true' : 'false'].tooltip} >
        <Switch
          checked={showOnlyUserSelectedTests}
          onChange={e => {setShowOnlyUserSelectedTests(e.target.checked);e.preventDefault()}}
          name="checkedB"
          color="primary"
        />
      </Tooltip>
    } : null}
    listItems={testList
      .filter(({ testCode }) => {
        if (!filteredMemoKeys || !filteredMemoKeys.length) return true;
        return filteredMemoKeys.map(s => s.replace(MEMO_PREFIX, '').split('.')[0]).includes(testCode);
      })
        .map(tcwl => ({key: tcwl.testCode,
            selected: selectedTestCode.testCode === tcwl.testCode,
            onClick: () => {changeSelectedTestCodeAndMemoKey(tcwl, null);},
            label: tcwl.label}))
    }
  />
};

export default TestComments;

