import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import { RemoveCircle, SpeakerNotes } from '@material-ui/icons';
import labels from '../../../constants/labels/patient/test/TestFillingRow.json';
import Comment from './Comment';
import { getFields } from './TestFillingUtil';
import type { ReportGraphicOptions } from '../report/ReportGraphicsDefaultOptions';
import TestFillingRowScoreColumn from './TestFillingRowScoreColumn';
import testFillingRowStyle from './TestFillingRowStyle.json';
import Memo from '../../common/data/Memo';
import { getKeyFromTestCodeAndId } from '../../common/data/MemoUtil';
import TestFillingRowInitialScoreColumn from './TestFillingRowInitialScoreColumn';
import getPercentileValue from "./testUtils/GetPercentileValue";
import calculateValues from "./testUtils/CalculateValues";

const useStyles = makeStyles({
  sortableField: {
    cursor: 'default',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selfCenteredAligned: {
    'align-self': 'center'
  },
  greyBackGround: {
    borderRadius: '0.5em',
    backgroundColor: '#EEE'
  },
  iconSmall: {
    fontSize: 20
  },
  noPadding: {
    padding: '0px',
    lineHeight: ({ testFillingRowCompactDisplay }) => (testFillingRowCompactDisplay ? 'auto' : '4em'),
    '&:hover': {
      backgroundColor: '#e8e8e8',
      cursor: 'pointer'
    }
  },
  strikethrough: {
    textDecoration: 'line-through'
  },
  comment: {
    color: '#48a091',
    lineHeight: '1em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      textDecoration: 'underline'
    },
    '& p': {
      marginTop: '0.25em',
      marginBottom: '0.25em'
    }
  },
  ...testFillingRowStyle
});

type Props = {
  testCode: string,
  id: string,
  label: string,
  // eslint-disable-next-line react/require-default-props
  IHMLabel?: string,
  oddRow: boolean,
  showCommentLabel: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  currentTestResult?: any,
  isRenewal: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  initialTestResult?: any,
  // eslint-disable-next-line flowtype/no-weak-types
  handleChange?: Function,
  input: array,
  maxScore: number | undefined,
  columnWidth: number,
  disableCentileColumn?: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  columns: any,
  // eslint-disable-next-line flowtype/no-weak-types
  testRowData: any,
  displayRawScore?: boolean,
  displayCalculatedPercentile?: boolean,
  mouseScrollWheelScoreEdition: boolean,
  display?: {
    superHeader?: string,
    header?: string
  },
  lastname: string,
  firstname: string,
  testFillingRowCompactDisplay?: boolean,
  options: ReportGraphicOptions | undefined,
  stepper: React.ReactElement | undefined,
  showCommentEditor: boolean,
  setShowCommentEditor: (number | null) => void
};

const typeKeyCorrespondence = {
  scoreET: 'inScoreET',
  tempsET: 'inTempsET',
  scoreCentile: 'inScoreCentile',
  tempsCentile: 'inTempsCentile',
  errTempsCentile: 'inErrTempsCentile',
  errCentile: 'inErrCentile'
};

const TestFillingRow = ({
  disableCentileColumn = true,
  displayRawScore = false,
  displayCalculatedPercentile = false,
  currentTestResult = {},
  isRenewal = false,
  initialTestResult = {},
  handleChange = () => {},
  testRowData,
  testCode,
  id,
  label,
  IHMLabel,
  maxScore,
  oddRow,
  showCommentLabel,
  input,
  columnWidth,
  columns,
  mouseScrollWheelScoreEdition,
  display: { superHeader, header } = {},
  lastname,
  firstname,
  testFillingRowCompactDisplay,
  options,
  stepper,
  showCommentEditor,
  setShowCommentEditor
}: Props) => {
  const style = useStyles({ testFillingRowCompactDisplay });

  const [percentileObject, setPercentileObject] = useState({ label: '' });
  const [testResult, setTestResult] = useState(currentTestResult);

  useEffect(() => {
    const initialiseValues = async () => {
      if (
        displayCalculatedPercentile &&
        testRowData &&
        testRowData.pct &&
        (currentTestResult.score || currentTestResult.score === 0)
      ) {
        const newPercentileObject = getPercentileValue({
          value: Number(currentTestResult.score),
          pctTable: testRowData.pct.slice(),
          order: testRowData.order || testRowData.walkType,
          indexOfpc50: testRowData.indexOfpc50
        });
        await setPercentileObject(newPercentileObject);
      }
      await setTestResult(currentTestResult);
    };

    initialiseValues().then();
  }, [currentTestResult, displayCalculatedPercentile, testRowData]);

  const skiptest = () => {
    testResult.disabled = !currentTestResult.disabled;
    handleChange({ target: { name: id, value: testResult } });
  };

  const handleBlur = async () => {
    const comment = await Memo.verifyIfMemoByTestCodeAndIdHasInsertedByDefaultTextAndAddThem(
      testCode,
      id,
      testResult.comment,
      firstname,
      lastname,
      options.addUserSelectedMemorizedTextsAsDefaultComment
    );
    handleChange({ target: { name: id, value: { ...testResult, comment } } });
  };

  const fireTab = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      // const event = new KeyboardEvent("keydown", {keyCode: 'Tab'});
      // remote.getCurrentWindow().webContents.sendInputEvent({ type: 'keyDown', keyCode: 'Tab' });
    }
  };

  const handleScoreChange = async ({ target: { name, value } }) => {
    const scoreType = name.replace(`${id}.`, '');
    const newTestResults = {
      ...testResult,
      scoreET: name === `${id}.ns` ? parseFloat(((value.ns - 10) / 3).toFixed(2)) : testResult.scoreET,
      [scoreType]: value
    };
    if (typeKeyCorrespondence && typeKeyCorrespondence[scoreType]) {
      newTestResults[typeKeyCorrespondence[scoreType]]= true;
    }
    if (testRowData && name === `${id}.score`) {
      const calculateValues1 = calculateValues(testRowData, value, input);
      console.log({calculateValues1, testRowData, value, input});
      const { returnValue, percentileObject: newPercentileObject } = calculateValues1;
      await setPercentileObject(newPercentileObject);
      await setTestResult({ ...newTestResults, ...returnValue });
      return;
    }
    await setTestResult({ ...newTestResults });
  };

  const fields = useMemo(
    () => getFields(input, displayRawScore, columns, id, testResult, maxScore, fireTab, disableCentileColumn),
    [input, displayRawScore, columns, id, testResult, maxScore, disableCentileColumn]
  );

  if (id === "LEC_TEX_PREC") {
    console.log({input, displayRawScore, columns, id, testResult, maxScore, disableCentileColumn, fields})
  }

  const initialFields = useMemo(
    () =>
      isRenewal
        ? getFields(input, displayRawScore, columns, id, initialTestResult, maxScore, fireTab, disableCentileColumn)
        : {},
    [isRenewal, input, displayRawScore, columns, id, initialTestResult, maxScore, disableCentileColumn]
  );

  const testFillingRowScoreColumns = isRenewal ? (
    <Grid item md={12} lg={6} container>
      <TestFillingRowInitialScoreColumn
        fields={initialFields}
        columnWidth={columnWidth}
        handleScoreChange={handleScoreChange}
        handleBlur={handleBlur}
        testResult={initialTestResult}
        displayCalculatedPercentile={displayCalculatedPercentile}
        testRowData={testRowData}
      />
      <TestFillingRowScoreColumn
        displayCalculatedPercentile={displayCalculatedPercentile}
        percentileObject={percentileObject}
        fields={fields}
        testResult={testResult}
        columnWidth={columnWidth}
        handleScoreChange={handleScoreChange}
        handleBlur={handleBlur}
        mouseScrollWheelScoreEdition={mouseScrollWheelScoreEdition}
        options={options}
        testCode={testCode}
      />
    </Grid>
  ) : (
    <TestFillingRowScoreColumn
      displayCalculatedPercentile={displayCalculatedPercentile}
      percentileObject={percentileObject}
      fields={fields}
      testResult={testResult}
      columnWidth={columnWidth}
      handleScoreChange={handleScoreChange}
      handleBlur={handleBlur}
      mouseScrollWheelScoreEdition={mouseScrollWheelScoreEdition}
      options={options}
      testCode={testCode}
    />
  );

  return (
    <Fragment>
      {superHeader && (
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ backgroundColor: 'lightgray', fontWeight: 'bold', textTransform: 'uppercase', marginTop: '1em' }}
        >
          <Typography variant="h4">{superHeader}</Typography>
        </Grid>
      )}
      {header && (
        <Grid item xs={12} container style={{ backgroundColor: 'white', fontWeight: 'bold' }}>
          <Typography variant="h5">{header}</Typography>
        </Grid>
      )}
      <Grid item xs={12} container className={`${oddRow ? style.greyBackGround : ''} ${style.noPadding}`}>
        <Grid item xs={12} container>
          <Grid item md={12} lg={6} container style={{ paddingLeft: '30px' }}>
            <Grid
              item
              lg={showCommentLabel ? 8 : 10}
              xl={9}
              className={style.alignVertically}
              onClick={() => setShowCommentEditor(id)}
            >
              <Typography
                variant={showCommentEditor ? 'h5' : 'subtitle1'}
                className={testResult.disabled ? style.strikethrough : ''}
              >
                {IHMLabel || label} {maxScore && `/ ${maxScore}`}
                {currentTestResult.levelAdjuster && (
                  <span
                    style={{ fontSize: '80%', color: 'dodgerblue', fontStyle: 'italic' }}
                  >{` ${labels.levelAdjusterLabel} ${currentTestResult.levelAdjuster}`}</span>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              lg={showCommentLabel ? 3 : 1}
              xl={2}
              className={classNames(style.alignVertically, style.sortableField, style.noPadding)}
              onClick={() => setShowCommentEditor(id)}
            >
              <Tooltip title={showCommentEditor ? labels.closeComment : labels.comment}>
                <Grid>
                  <SpeakerNotes color="action" className={classNames(style.iconSmall, style.noPadding)} />
                  <Typography variant="button" style={{ display: 'inline-block' }}>
                    {showCommentLabel ? ` ${labels.commentLabel}` : ''}
                  </Typography>
                </Grid>
              </Tooltip>
            </Grid>
            <Grid item lg={1} className={classNames(style.alignVertically, style.sortableField)}>
              <Tooltip title={labels.nonRunTest}>
                <RemoveCircle
                  style={{ color: testResult.disabled ? 'red' : '' }}
                  className={classNames(style.iconSmall, style.noPadding)}
                  onClick={skiptest}
                />
              </Tooltip>
            </Grid>
          </Grid>
          {testResult.disabled ? (
            <Grid item xs={6} className={style.alignVertically}>
              <Typography variant="subtitle2">{labels.nonRunTest}</Typography>
            </Grid>
          ) : (
            testFillingRowScoreColumns
          )}
        </Grid>
        <Grid item md={12}>
          {!testResult.disabled && !showCommentEditor && testResult.comment && (
            <Grid item xs={12} className={style.comment} onClick={() => setShowCommentEditor(id)}>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: testResult.comment }}
              />
            </Grid>
          )}
          {showCommentEditor && (
            <Comment
              value={testResult.comment}
              controlName={`${id}.comment`}
              onChange={handleChange}
              showMemorizedTexts
              memoByCategory
              closeFunction={() => setShowCommentEditor(null)}
              memo={getKeyFromTestCodeAndId(testCode, id)}
              forceTextUpdate
              lastname={lastname}
              firstname={firstname}
              title={IHMLabel || label}
              extraHeader={testFillingRowScoreColumns}
              stepper={stepper}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

TestFillingRow.defaultProps = {
  disableCentileColumn: true,
  displayRawScore: false,
  displayCalculatedPercentile: false,
  currentTestResult: {},
  initialTestResult: {},
  handleChange: () => {},
  display: {},
  testFillingRowCompactDisplay: false
};

export default TestFillingRow;
