import React from 'react';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import { NavigateBefore } from '@material-ui/icons';
import labels from '../../constants/labels/common/BackButton.json';
import routes from '../../constants/routes';

type Props = {
  backRoute?: ?string
};

const BackButton = ({ backRoute }: Props) => {
  const history = useHistory();

  const goBack = () => {
    history.push(backRoute);
  };

  return (
    <Button variant="outlined" onClick={goBack} startIcon={<NavigateBefore />}>
      {labels.back}
    </Button>
  );
};

BackButton.defaultProps = {
  backRoute: routes.HOME
};

export default BackButton;
