import React, { Component } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import labels from '../../../../constants/configuration/tests/personal/SelectTestType.json';

const PersonalTestTypes = [
  {
    key: 'SCORE_ET',
    label: labels.SCORE_ET
  },
  {
    key: 'TEMPS_ET',
    label: labels.TEMPS_ET
  },
  {
    key: 'ERR_SCORE_ET',
    label: labels.ERR_SCORE_ET
  },
  {
    key: 'ERR_TEMPS_ET',
    label: labels.ERR_TEMPS_ET
  },
  {
    key: 'SCORE_CENTILE',
    label: labels.SCORE_CENTILE
  },
  {
    key: 'SCORE_SEPTILE',
    label: labels.SCORE_SEPTILE
  },
  {
    key: 'SCORE_OCTILE',
    label: labels.SCORE_OCTILE
  },
  {
    key: 'TEMPS_CENTILE',
    label: labels.TEMPS_CENTILE
  },
  {
    key: 'ERR_CENTILE',
    label: labels.ERR_CENTILE
  },
  {
    key: 'ERR_TEMPSCENTILE',
    label: labels.ERR_TEMPSCENTILE
  },
  {
    key: 'SCORE_AD',
    label: labels.SCORE_AD
  },
  {
    key: 'SCORE_QUART',
    label: labels.SCORE_QUART
  },
  {
    key: 'SCORE_QUINT',
    label: labels.SCORE_QUINT
  },
  {
    key: 'NS19',
    label: labels.NS19
  },
  {
    key: 'QUALI',
    label: labels.QUALI
  }
];

type Props = {
  value?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function
};

class SelectTestType extends Component<Props> {
  props: Props;

  static defaultProps = {
    value: ''
  };

  render() {
    const { value, onChange } = this.props;
    return (
      <Select
        value={value}
        onChange={onChange}
        inputProps={{
          name: 'testInputValue'
        }}
      >
        {PersonalTestTypes.map(({ key, label }) => (
          <MenuItem key={key} value={key}>
            {label}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

export { PersonalTestTypes, SelectTestType };
