import labels from "../../../constants/labels/common/options/initColorResultEditor.json";

const initColorResultEditors = (options, exalangOnly, examath58Only) => {
  const colorResultEditors = [
    {
      label: labels.higherThanNorm.label,
      editableLabelId: "legendOptions.aboveAverage",
      editableLabelPlaceHolder: labels.higherThanNorm.placeHolder,
      editableLabel: (options && options.legendOptions && options.legendOptions.aboveAverage) || "",
      colorId: "gfxcolor1",
      color: options.gfxcolor1
    }
  ];

  if (options && options.legendOptions && options.legendOptions.averageDetails) {
    colorResultEditors.push(
      {
        label: labels.normHigherPart.label,
        editableLabelId: "legendOptions.averageHigh",
        editableLabelPlaceHolder: labels.normHigherPart.placeHolder,
        editableLabel: (options && options.legendOptions && options.legendOptions.averageHigh) || "",
        color: options.gfxcolor2,
        colorId: "gfxcolor2"
      },
      {
        label: labels.normLowerPart.label,
        editableLabelId: "legendOptions.averageLow",
        editableLabelPlaceHolder: labels.normLowerPart.placeHolder,
        editableLabel: (options && options.legendOptions && options.legendOptions.averageLow) || "",
        color: options.gfxcolor3,
        colorId: "gfxcolor3"
      }
    );
  } else {
    colorResultEditors.push({
      label: labels.average.label,
      editableLabelId: "legendOptions.average",
      editableLabelPlaceHolder: labels.average.placeHolder,
      editableLabel: (options && options.legendOptions && options.legendOptions.average) || "",
      color: options.gfxcolor2,
      colorId: "gfxcolor2"
    });
  }
  colorResultEditors.push(
    {
      label: labels.nonPathologicalLowResult.label,
      editableLabelId: "legendOptions.belowAverage",
      editableLabelPlaceHolder: labels.nonPathologicalLowResult.placeHolder,
      editableLabel: (options && options.legendOptions && options.legendOptions.belowAverage) || "",
      color: options.gfxcolor4,
      colorId: "gfxcolor4"
    },
    {
      label: labels.pathologicalResult.label,
      editableLabelId: "legendOptions.pathological",
      editableLabelPlaceHolder: labels.pathologicalResult.placeHolder,
      editableLabel: (options && options.legendOptions && options.legendOptions.pathological) || "",
      color: options.gfxcolor5,
      colorId: "gfxcolor5"
    }
  );
  if (exalangOnly || examath58Only) {
    colorResultEditors.push({
      label: labels.srslypathological.label,
      editableLabelId: "legendOptions.srslypathological",
      editableLabelPlaceHolder: labels.srslypathological.placeHolder,
      editableLabel: (options && options.legendOptions && options.legendOptions.srslypathological) || "",
      color: options.gfxcolor6,
      colorId: "gfxcolor6"
    });
  }

  return colorResultEditors;
};

export default initColorResultEditors;
