import uuidv4 from 'uuid/v4';
import AnamnesisModel from './AnamnesisModel';
import defaultAnamnesis from '../../../constants/default-anamnese';

export default class Anamnesis {
  static TYPE = 'anamneses';

  static getNewEmptyOne({ anamnesisId = uuidv4(), patientId, bilanId, definitions, values }) {
    return {
      _id: anamnesisId,
      dbtype: Anamnesis.TYPE,
      uuid: `{${uuidv4()}}`,
      creationDate: new Date(),
      modificationDate: new Date(),
      patientId,
      bilanId,
      modelId: 0,
      name: AnamnesisModel.getDefault().name,
      definitions: definitions || defaultAnamnesis.definitions,
      values: values || defaultAnamnesis.values
    };
  }
}
