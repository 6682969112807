import React from 'react';
import { Route, Switch } from 'react-router';
import { Grid } from '@material-ui/core';
import routes from '../../constants/routes';
import ConfigurationCenterPage from './ConfigurationCenterPage';
import IncludedTestsOptionsPage from './IncludedTestsOptionsPage';
import PersonalTestEditPage from './PersonalTestEditPage';
import CheckUpModelPage from './CheckUpModelPage';
import CheckUpModelEditPage from './CheckUpModelEditPage';
import AnamnesePage from './AnamnesePage';
import AnamneseEditPage from './AnamneseEditPage';
import DocumentModelPage from './DocumentModelPage';
import TestListPage from './TestListPage';
import PersonalTestListPage from './PersonnalTestListPage';
import MemorizedTextsPage from './MemorizedTextsPage';
import ReportOptionsPage from './ReportOptionsPage';
import DisplayOptionsPage from './DisplayOptionsPage';

const ConfigurationPage = () => (
  <Grid container>
    <Grid item xs={12}>
      <Switch>
        <Route exact path={routes.CONFIGURATION.MAIN} component={ConfigurationCenterPage} />
        <Route
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.ANAMNESIS.EDIT)}
          component={AnamneseEditPage}
        />
        <Route path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.ANAMNESIS.MAIN)} component={AnamnesePage} />
        <Route
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.PERSONAL_TESTS.EDIT)}
          component={PersonalTestEditPage}
        />
        <Route path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.LIST)} component={TestListPage} />
        <Route
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.PERSONAL_TESTS.LIST)}
          component={PersonalTestListPage}
        />
        <Route
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.OPTIONS)}
          component={IncludedTestsOptionsPage}
        />
        <Route
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MODELS.EDIT)}
          component={CheckUpModelEditPage}
        />
        <Route
          exact
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MODELS.MAIN)}
          component={CheckUpModelPage}
        />
        <Route
          exact
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.DOCUMENT_MODEL)}
          component={DocumentModelPage}
        />
        <Route
          exact
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MEMORIZED_TEXTS)}
          component={MemorizedTextsPage}
        />
        <Route
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.MAIN)}
          component={ReportOptionsPage}
        />
        <Route
          path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.DISPLAY.MAIN)}
          component={DisplayOptionsPage}
        />
      </Switch>
    </Grid>
  </Grid>
);

export default ConfigurationPage;
