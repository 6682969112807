import User from '../../common/data/User';
import CryptoService from '../../../actions/CryptoService';
import Patient from '../../common/data/Patient';
import labels from '../../../constants/labels/patient/anamnesis/AnamnesisMailImportFile.json';
import AnamnesisBackOfficeAnswerConverter from "./AnamnesisBackOfficeAnswerConverter";

async function getUncryptedAESContent(lines) {
  const { val: aesKey } = await User.retrieveValueFromDb({ keyValue: 'aesKey' });
  const s = CryptoService.base64Decode(lines[4]);
  return CryptoService.aesDecrypt(s, aesKey);
}

async function getUncryptedRSAContent(lines, patient, setProgress) {
  const rsa = CryptoService.getRSA(patient.rsaKeySize);
  rsa.setPrivateKey(patient.rsaPrivateKey);
  return rsa.decryptSync(lines[4], setProgress);
}

async function getPatient(bilanUuid, lines, setProgress) {
  if (bilanUuid && lines[0].trim() === 'ORTHOSCRIBE-ANAMNESE-ENC01') {
    const [patientFromDb] = await Patient.getInitOrRenewalAssessmentPatient(
      bilanUuid.replace('{', '').replace('}', ''),
      'uuid'
    );
    const unCryptedFileContent = await getUncryptedRSAContent(lines, patientFromDb, setProgress);
    const converted = AnamnesisBackOfficeAnswerConverter(unCryptedFileContent);
    console.log({ patientFromDb, unCryptedFileContent: converted });
    return { patientFromDb, unCryptedFileContent: converted };
  }

  const unCryptedFileContent = await getUncryptedAESContent(lines);
  const [patientFromDb] = (await Patient.getInitOrRenewalAssessmentPatient(unCryptedFileContent.bilanId, 'uuid')) || [];

  const converted = AnamnesisBackOfficeAnswerConverter(unCryptedFileContent);

  console.log({ patientFromDb, unCryptedFileContent: converted });
  return { patientFromDb, unCryptedFileContent: converted };
}

const fileVerifications = [
  { assertion: lines => lines.length === 0, errorLabel: labels.errors.emptyOrInaccessible },
  {
    assertion: lines => !['ORTHOSCRIBE-ANAMNESE-ENC01', 'ORTHOSCRIBE-ANAMNESE-ENC02'].includes(lines[0]),
    errorLabel: labels.errors.unknownFormat
  }
];

const readAnamnesisFile = async (fileContent, setProgress, setError) => {
  // const fileContent = await fs.readFileSync(filename, 'UTF-8');
  const lines = fileContent.split('\n', 5);
  const [firstErrorLabel] = fileVerifications
    .filter(({ assertion }) => assertion(lines))
    .map(({ errorLabel }) => errorLabel);
  if (firstErrorLabel && setError) {
    setError(firstErrorLabel);
    return;
  }
  const [, , bilanUuid] = lines[1].split('/');
  const { patientFromDb, unCryptedFileContent } = await getPatient(bilanUuid, lines, setProgress);
  return { patientFromDb, unCryptedFileContent, lines };
};

export { getUncryptedRSAContent, getUncryptedAESContent, getPatient, readAnamnesisFile };
