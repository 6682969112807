import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import labels from '../../../constants/labels/patient/edit/Patient.json';
import { frenchClasses } from '../../../constants/Grades';
import TextFieldFormControl from '../../TextFieldFormControl';
import FormControlWithLegend from './FormControlWithLegend';

type Props = {
  patient: {
    firstName: string | undefined,
    lastName: string | undefined,
    sexe: string | undefined,
    birthDay: string | undefined,
    curclasse: string | ?number | undefined,
    plainte: string | undefined
  },
  /* eslint-disable flowtype/no-weak-types */
  onChange: Function,
  complaintFieldReplacementObject: any
};

export default function Patient(props: Props) {
  const {
    patient: { firstName = '', lastName = '', sexe = '', birthDay = '', curclasse = 999, plainte = '' } = {},
    onChange,
    complaintFieldReplacementObject
  } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControlWithLegend legend={labels.patient}>
        <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
          <TextFieldFormControl
            id="patient.firstName"
            controlName="firstName"
            label={labels.firstName}
            value={firstName}
            onChangeHandler={onChange}
            fullWidth
          />
          <TextFieldFormControl
            id="patient.lastName"
            controlName="lastName"
            label={labels.lastName}
            value={lastName}
            onChangeHandler={onChange}
            fullWidth
          />
          <Grid item xs={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">{labels.gender.label}</FormLabel>
              <RadioGroup aria-label={labels.gender.label} name="sexe" value={sexe} onChange={onChange} row>
                <FormControlLabel value="F" control={<Radio />} label={labels.gender.female} />
                <FormControlLabel value="H" control={<Radio />} label={labels.gender.male} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              id="patient.birthDate"
              label={labels.birthDate}
              format="DD/MM/YYYY"
              value={moment(birthDay, 'YYYY-MM-DD')}
              animateYearScrolling
              onChange={date => {
                const value = date ? date.format('YYYY-MM-DD') : '';
                onChange({ target: { value, name: 'birthDay' } });
              }}
              InputLabelProps={{ shrink: true }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              className="MuiFormControl-fullWidth"
            />
          </Grid>
          <TextFieldFormControl
            id="patient.curclasse"
            controlName="curclasse"
            label={labels.grade}
            value={curclasse}
            onChangeHandler={onChange}
            select
            inputLabelProps={{ shrink: true }}
            fullWidth
          >
            <MenuItem value="" />
            {frenchClasses.map(({ id, label }) => (
              <MenuItem key={id} value={`${id.toString()}`}>
                {label}
              </MenuItem>
            ))}
          </TextFieldFormControl>
          {complaintFieldReplacementObject || (
            <TextFieldFormControl
              id="patient.complaint"
              controlName="plainte"
              label={labels.complaint}
              value={plainte}
              multiline
              rows="2"
              onChangeHandler={onChange}
              fullWidth
            />
          )}
        </Grid>
      </FormControlWithLegend>
    </MuiPickersUtilsProvider>
  );
}
