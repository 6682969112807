import appVersionSpecific from '../../appVersionSpecific.ortho';
import { getLicense, isUserLegitOrthoscribe } from '../../actions/LicenceUtil';
import labels from '../../constants/labels/common/useApplicationVersion.json';
import { Grid, Tooltip } from '@material-ui/core';

export function useApplicationVersion() {
  const isHappyNeuron = appVersionSpecific.isHappyNeuron;

  const license = getLicense();
  let legitOrthoscribe = false;
  if (license) {
    legitOrthoscribe = isUserLegitOrthoscribe(license);
  }
  const appName = isHappyNeuron && !legitOrthoscribe ? 'Happy' : 'Ortho';

  // TODO complete this
  const happyNeuronImport = {};

  return {
    isHappyNeuron: isHappyNeuron && !legitOrthoscribe,
    appName,
    ...happyNeuronImport
  };
}

const HappyNeuronDisabledComponent = (props) => {
  return <Tooltip title={appVersionSpecific.isHappyNeuron ? labels.onlyAvailableInOrthoscribe : ""}>
    <Grid item md={props.md || 4} container>
      {props.children}
    </Grid>
  </Tooltip>;
};

export default HappyNeuronDisabledComponent;
