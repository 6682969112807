import React, { Fragment } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { Route, Switch } from 'react-router';
import labels from '../../constants/labels/configuration/reportOptions/ReportOptionsPage.json';
import routes from '../../constants/routes';
import ReportOptions from '../../components/configuration/reportOptions/ReportOptions';
import ReportOptionChoice from '../../components/configuration/reportOptions/ReportOptionChoice';
import GraphicOptions from '../../components/configuration/reportOptions/GraphicOptions';
import TableOptions from '../../components/configuration/reportOptions/TableOptions';

const ReportOptionsPage = () => (
  <Fragment>
    <Grid container style={{ overflow: 'hidden' }} spacing={2}>
      <Grid item md={12}>
        <Typography variant="h6">{labels.mainHeader}</Typography>
        <Typography variant="subtitle2">{labels.secondHeader}</Typography>
        <Typography variant="body2">{labels.thirdHeader}</Typography>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
    </Grid>
    <Switch>
      <Route
        exact
        path={routes.CONFIGURATION.REPORT_OPTIONS.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.REPORT)}
        component={ReportOptions}
      />
      <Route
        exact
        path={routes.CONFIGURATION.REPORT_OPTIONS.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.GRAPHIC)}
        component={GraphicOptions}
      />
      <Route
        exact
        path={routes.CONFIGURATION.REPORT_OPTIONS.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.TABLE)}
        component={TableOptions}
      />
      <Route
        path={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.REPORT_OPTIONS.MAIN)}
        component={ReportOptionChoice}
      />
    </Switch>
  </Fragment>
);

export default ReportOptionsPage;
