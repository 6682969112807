import { inputIncludesQuali } from "../../testUtils/calculateValue/QualiValue";
import labels from "../../../../../constants/labels/patient/test/TestFillingHeader.json";
import { inputIncludesTemps } from "../../testUtils/calculateValue/TimeOrTimeStandardDeviationValue";
import { inputIncludesScore } from "../../testUtils/calculateValue/ScoreOrScoreStandardDeviationValue";

const rawScore = ({ input = [], displayRawScore, columns = {}, id, testResult = {}, maxScore, fireTab }) => {
  const onlyQuali = input.length === 1 && inputIncludesQuali(input);
  let forceRawScoreButNotForQuali;
  if (onlyQuali) {
    forceRawScoreButNotForQuali = false;
  } else {
    forceRawScoreButNotForQuali = displayRawScore;
  }
  return {
    header: { condition: !!columns.SCORE || displayRawScore, label: labels.resultColumnTitles.rawScore },
    column: {
      displayCondition: inputIncludesTemps(input) || inputIncludesScore(input) || forceRawScoreButNotForQuali,
      replacementCondition: !!columns.SCORE,
      id: "SCORE",
      placeholder: "-",
      controlName: `${id}.score`,
      value: testResult.score,
      fullWidth: true,
      inputProps: { inputProps: { step: 1, min: 0, max: maxScore, onKeyPress: fireTab } }
    }
  };
};

export default rawScore;
