import {Grid} from '@material-ui/core';
import React from 'react';
import TextFieldFormControl from '../../TextFieldFormControl';

const TestFillingRowTextField = ({
  displayCondition,
  replacementCondition,
  itemMdValue,
  children,
  ...props
}: {
  displayCondition: boolean,
  replacementCondition: boolean,
  itemMdValue: number,
  // eslint-disable-next-line flowtype/no-weak-types
  children: any | undefined
}) => {
  if (displayCondition) {
    return (
      <TextFieldFormControl itemMdValue={itemMdValue} {...props}>
        {children}
      </TextFieldFormControl>
    );
  }
  if (replacementCondition) return <Grid item md={itemMdValue}>{children}</Grid>;
  return null;
};

export default TestFillingRowTextField;
