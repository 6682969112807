/* eslint  react/prop-types:off */
import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import labels from '../../../constants/labels/patient/TestHeader.json';
import routes from '../../../constants/routes';
import CalculationTableSelector from './CalculationTableSelector';
import BackButton from '../../common/BackButton';
import { getHeaderAgeAndClassText } from '../../common/Header';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold'
  },
  iconSmall: {
    fontSize: 20
  }
}));

const TestHeader = ({
  patient,
  patientId,
  changeSelectedCalculusTable,
  currentTest,
  currentTestResults,
  extraButton,
  children
}) => {
  const style = useStyles();
  const ageAndClassText = getHeaderAgeAndClassText(patient.curclasse, patient.birthDay);

  return (
    <Grid container spacing={3} alignItems="flex-start" justifyContent="space-evenly">
      <Grid md={12} item container>
        <BackButton backRoute={routes.PATIENT.getForPath({ path: routes.PATIENT.TESTS.TESTS, patientId })} />
      </Grid>
      <Grid md={12} item container>
        <Typography variant="h5">
          {labels.subTitle.part0} {currentTest.label} {labels.subTitle.part1}{' '}
          <span className={style.bold}>
            {patient.firstName} {patient.lastName}
          </span>{' '}
          ({ageAndClassText})
        </Typography>
      </Grid>
      <Grid md={12} item container>
        <Divider style={{ width: '100%' }} />
      </Grid>
      {((currentTest.calcul && currentTest.calcul.length > 1) || extraButton) && (
        <Grid md={12} item container>
          <Paper style={{ width: '100%', padding: '1em' }}>
            {currentTest.calcul && currentTest.calcul.length > 1 && !currentTestResults.$$calculOption && (
              <Grid md={12} item container>
                <Grid item md={12}>
                  <Typography variant="h6" className={style.bold}>
                    {labels.noCalculationTableSelected.title}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="body1" className={style.bold}>
                    {labels.noCalculationTableSelected.text.replace(':firstName', patient.firstName)}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {currentTest.calcul && currentTest.calcul.length && (
              <CalculationTableSelector
                currentTest={currentTest}
                currentTestResults={currentTestResults}
                changeSelectedCalculusTable={changeSelectedCalculusTable}
              />
            )}
            <Grid item md={(currentTest.calcul && currentTest.calcul.length === 1) || !currentTest.calcul ? 11 : 8}>
              {extraButton}
            </Grid>
          </Paper>
        </Grid>
      )}
      {children}
    </Grid>
  );
};

export default TestHeader;
