import currentTestHasCorrespondingCalculationTable from './CurrentTestHasCorrespondingCalculationTable';
import calculateValues from './CalculateValues';
import updateCurrentTestResultsFromCalculOption from './UpdateCurrentTestResultsFromCalculOption';
import getComment from './GetComment';

const updateCurrentTestResultsWithImportValues = async (
  currentTest,
  calculOption,
  currentTestResults,
  values,
  firstName,
  lastName,
  addUserSelectedMemorizedTextsAsDefaultComment
) => {
  const { $$calculOption, ...results } = currentTestResults;
  const existingCalculationTable = currentTestHasCorrespondingCalculationTable(currentTest, calculOption);
  results.$$calculOption = existingCalculationTable ? calculOption : $$calculOption;

  const { updatedCurrentTestResults, examinations, calculationData } = updateCurrentTestResultsFromCalculOption(
    currentTest,
    results
  );

  const { testCode } = currentTest;

  const array = await Promise.all(
    Object.entries(values)
      .map(([eprCode, value]) => ({ eprCode, value: { ...(updatedCurrentTestResults[eprCode] || {}), ...value } }))
      .map(async ({ eprCode, value }) => {
        const examination = examinations.filter(({ id }) => id === eprCode).shift();
        if (examination && updatedCurrentTestResults.$$calculOption) {
          const { returnValue } = calculateValues(calculationData[eprCode], value.score, examination.input);
          const previousComment = (results[eprCode] && results[eprCode].comment) || '';
          const comment = await getComment(
            { comment: previousComment },
            value,
            testCode,
            eprCode,
            firstName,
            lastName,
            addUserSelectedMemorizedTextsAsDefaultComment
          );
          const newValue = {
            ...{
              ...value,
              ...returnValue,
              comment
            }
          };
          return { eprCode, value: newValue };
        }
        return new Promise(resolve => {
          resolve({ eprCode, value });
        });
      })
  );

  return array.reduce((acc, { eprCode, value }) => ({ ...acc, [eprCode]: value }), updatedCurrentTestResults);
};

export default updateCurrentTestResultsWithImportValues;
