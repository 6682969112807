import React, { useState } from 'react';
import { InputAdornment, MenuItem, TextField } from '@material-ui/core';

import TextFieldFormControl from '../../../TextFieldFormControl';
import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis.json';

type Props = {
  id?: string,
  label: string,
  placeholder?: string,
  unit?: string,
  span?: number,
  myValue?: {
    unit?: {
      id?: string,
      label?: string
    },
    value?: string
  },
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function
};

const Age = ({ id, label, placeholder, unit, span, myValue: propValue, changeHandler }: Props) => {
  const [myValue, setMyValue] = useState(
    propValue || {
      myValue: { unit: { id: 'semaines', label: 'semaines' }, value: '' }
    }
  );
  return (
    <TextFieldFormControl
      key={id || label}
      id={id || label}
      controlName="value"
      itemMdValue={span}
      label={label}
      placeholder={placeholder}
      value={myValue.value}
      onChangeHandler={event =>
        setMyValue({ value: event.target.value, unit: { id: myValue.unit.id, label: myValue.unit.label } })
      }
      onBlurHandler={() => changeHandler({ target: { name: id, value: myValue } })}
      fullWidth
      inputType="number"
      inputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <TextField
              select
              value={myValue.unit.id || unit}
              onChange={event => {
                changeHandler({
                  target: {
                    name: id,
                    value: {
                      value: myValue.value,
                      unit: { id: event.target.value, label: event.target.value }
                    }
                  }
                });
              }}
              name={`${id}.unit`}
            >
              <MenuItem value="semaines">{labels.tabs.form.right.age.weeks}</MenuItem>
              <MenuItem value="mois">{labels.tabs.form.right.age.months}</MenuItem>
              <MenuItem value="an">{labels.tabs.form.right.age.years}</MenuItem>
            </TextField>
          </InputAdornment>
        )
      }}
    />
  );
};

Age.defaultProps = {
  id: '',
  span: 12,
  unit: 'mois',
  placeholder: '',
  myValue: {
    unit: {
      id: 'semaines',
      label: 'semaines'
    },
    value: ''
  },
  changeHandler: () => {}
};

export default Age;
