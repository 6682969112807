import React, { useRef } from 'react';

import { useDrop } from 'react-dnd';

import '../../common/styles/dragAndDrop.css';

type Props = {
  rowNumber: number,
  isDragging: boolean
};

const DropRow = (props: Props) => {
  const { isDragging, rowNumber } = props;
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: 'anamneseFormElement',
    drop: () => ({ rowNumber, columnNumber: 0, newRow: true }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  drop(ref);
  return (
    <div ref={ref} className={`${isOver ? "dropZone" : ''} ${isDragging ? "emptyDropRow" : ''} `} />
  );
};

export default DropRow;
