import React from 'react';
import { render } from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Root from "./containers/Root";
import { configureStore, history } from './store/configureStore.dev';
import '@fortawesome/fontawesome-free';
import '@fontsource/montserrat';
import '@fontsource/roboto';
import 'animate.min.css';

const store = configureStore();

render(
  <React.StrictMode>
    <Root store={store} history={history} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
