const AMOCodes = {
  getText: (amo, sessionCount) =>
    `Je demande donc ${Math.round(sessionCount).toString()} AMO ${amo.code} (${
      amo.fullLabel
    }) à raison d'une séance par semaine.`,
  codes: [
    {
      shortLabel: "Troubles d'articulation isolés sans d'affection neurologique",
      code: '5,1',
      fullLabel:
        "Rééducation des troubles d'articulation isolés chez des personnes ne présentant pas d'affection neurologique"
    },
    {
      shortLabel: "Troubles de l'articulation / déficiences perceptives (avant le 25/10/2022)",
      code: '8',
      fullLabel: "Rééducation des troubles de l'articulation liés à des déficiences perceptives"
    },
    {
      shortLabel: "Troubles de l'articulation / déficiences organique (avant le 25/10/2022)",
      code: '8',
      fullLabel: "Rééducation des troubles de l'articulation liés à des déficiences d'origine organique"
    },
    {
      shortLabel: 'Déglutition atypique (avant le 25/10/2022)',
      code: '8',
      fullLabel: 'Rééducation de la déglutition atypique'
    },
    {
      shortLabel: 'Vélo-tubo-tympanique (avant le 25/10/2022)',
      code: '8',
      fullLabel: 'Rééducation vélo-tubo-tympanique'
    },
    {
      shortLabel: "Troubles de la voix d'origine organique/fonctionnelle",
      code: '11,4',
      fullLabel: "Rééducation des troubles de la voix d'origine organique ou fonctionnelle"
    },
    {
      shortLabel: 'Dyskinésies laryngées, par séance ',
      code: '11,3',
      fullLabel: 'Rééducation des dyskinésies laryngées'
    },
    {
      shortLabel: 'Dysarthries neurologiques',
      code: '11',
      fullLabel: 'Rééducation des dysarthries neurologiques'
    },
    {
      shortLabel: "Dysphagies chez l'adulte et chez l'enfant (avant le 25/10/2022)",
      code: '11',
      fullLabel: "Rééducation des dysphagies chez l'adulte et chez l'enfant"
    },
    {
      shortLabel: 'Anomalies des fonctions oro-myo-faciales ',
      code: '10,3',
      fullLabel:
        "Rééducation des anomalies des fonctions oro-myo-faciales entraînant des troubles de l'articulation et de la parole"
    },
    {
      shortLabel: 'Voix oro-oesophagienne et/ou trachéo-oesophagienne (avant le 25/10/2022)',
      code: '11,2',
      fullLabel: "Éducation à l'acquisition et à l'utilisation de la voix oro-oesophagienne et/ou trachéo-oesophagienne"
    },
    {
      shortLabel: 'Utilisation des prothèses phonatoires ',
      code: '11,1',
      fullLabel: "Éducation à l'utilisation des prothèses phonatoires quel qu'en soit le mécanisme par séance"
    },
    {
      shortLabel: 'Troubles de la communication et du langage écrit (avant le 25/10/2022)',
      code: '10,1',
      fullLabel: 'Rééducation des troubles de la communication et du langage écrit'
    },
    {
      shortLabel: 'Troubles du calcul et du raisonnement logico-mathématique (avant le 25/10/2022)',
      code: '10,2',
      fullLabel: 'Rééducation des troubles du calcul et du raisonnement logico-mathématique'
    },
    {
      shortLabel: 'Troubles du graphisme et de l’écriture (avant le 25/10/2022)',
      code: '10',
      fullLabel: 'Rééducation des troubles du graphisme et de l’écriture'
    },
    {
      shortLabel: 'Retards de parole, troubles de la communication et du langage oral',
      code: '12,1',
      fullLabel: 'Rééducation des retards de parole, des troubles de la communication et du langage oral'
    },
    {
      shortLabel: 'Bégaiement, troubles de la fluence',
      code: '12,2',
      fullLabel: 'Rééducation des bégaiements et des autres troubles de la fluence'
    },
    {
      shortLabel: 'Communication dans les surdités ',
      code: '12',
      fullLabel:
        'Réadaptation à la communication dans les surdités acquises appareillées et/ou éducation à la pratique de la lecture labiale'
    },
    {
      shortLabel: 'Éducation précoce au langage / handicap sensoriel, moteur, mental',
      code: '13,6',
      fullLabel:
        "Éducation précoce à la communication et au langage dans les handicaps de l'enfant de type sensoriel, moteur, mental"
    },
    {
      shortLabel: "rééducation des anomalies des fonctions oro-myo-faciales et de l'oralité",
      code: '13,5',
      fullLabel: "rééducation des anomalies des fonctions oro-myo-faciales et de l'oralité"
    },
    {
      shortLabel: "Langage dans les handicaps de l'enfant de type sensoriel, moteur, mental",
      code: '13,8',
      fullLabel:
        "Éducation ou rééducation de la communication et du langage dans les handicaps de l'enfant de type sensoriel, moteur, mental"
    },
    {
      shortLabel: 'Communication et langage / paralysies cérébrales',
      code: '13,8',
      fullLabel: 'Éducation ou rééducation de la communication et du langage dans le cadre des paralysies cérébrales'
    },
    {
      shortLabel: 'Communication et langage / troubles envahissants du développement',
      code: '13,8',
      fullLabel:
        'Éducation ou rééducation de la communication et du langage dans le cadre des troubles envahissants du développement'
    },
    {
      shortLabel: 'Communication et langage / maladies génétiques',
      code: '13,8',
      fullLabel: 'Éducation ou rééducation de la communication et du langage dans le cadre des maladies génétiques'
    },
    {
      shortLabel: 'Dysphasies (30 min)',
      code: '14',
      fullLabel: "Rééducation des dysphasies, par séance d'une durée minimale de 30 minutes"
    },
    {
      shortLabel: 'Communication et langage, aphasies',
      code: '15,6',
      fullLabel: 'Rééducation de la communication et du langage dans les aphasies'
    },
    {
      shortLabel: 'Communication et langage, non aphasiques',
      code: '15,2',
      fullLabel:
        "Rééducation des troubles de la communication et du langage non aphasiques dans le cadre d'autres atteintes\nneurologiques"
    },
    {
      shortLabel: 'Communication / maladies neurodégénératives',
      code: '15',
      fullLabel:
        'Maintien et adaptation des fonctions de communication chez les personnes atteintes de maladies\nneurodégénératives'
    },
    {
      shortLabel: 'Démutisation dans les surdités du premier âge',
      code: '15,4',
      fullLabel:
        "Démutisation dans les surdités du premier âge, appareillées ou non, y compris en cas d'implantation cochléaire, par\nséance"
    },
    {
      shortLabel: 'Communication, langage et parole dans les surdités',
      code: '15,1',
      fullLabel:
        "Rééducation ou conservation de la communication, du langage et de la parole dans les surdités appareillées ou non,\ny compris en cas d'implantation cochléaire"
    },
    {
      shortLabel: "Troubles de la voix d'origine organique ou fonctionnelle (groupe avant le 25/10/2022)",
      code: '5',
      fullLabel: "Rééducation des troubles de la voix d'origine organique ou fonctionnelle"
    },
    {
      shortLabel: 'Voix oro-oesophagienne et/ou trachéo-oesophagienne (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel: "Éducation à l'acquisition et à l'utilisation de la voix oro-oesophagienne et/ou trachéo-oesophagienne"
    },
    {
      shortLabel: 'Troubles de la communication et du langage écrit (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel: 'Rééducation des troubles de la communication et du langage écrit'
    },
    {
      shortLabel: 'Troubles du calcul / raisonnement logico-mathématique (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel: 'Rééducation des troubles du calcul et du raisonnement logico-mathématique'
    },
    {
      shortLabel: 'Retards de parole, des troubles de la communication et du langage oral (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel: 'Rééducation des retards de parole, des troubles de la communication et du langage oral'
    },
    {
      shortLabel: 'Bégaiements et des autres troubles de la fluence (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel: 'Rééducation des bégaiements et des autres troubles de la fluence'
    },
    {
      shortLabel: 'Pratique de la lecture labiale (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel: 'Éducation à la pratique de la lecture labiale'
    },
    {
      shortLabel: 'Dysphasies (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel: 'Rééducation des dysphasies'
    },
    {
      shortLabel: 'Communication et langage, aphasies (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel: 'Rééducation de la communication et du langage dans les aphasies'
    },
    {
      shortLabel: 'Communication et langage, non aphasiques (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel:
        "Rééducation des troubles de la communication et du langage non aphasiques dans le cadre d'autres atteintes\nneurologiques"
    },
    {
      shortLabel: 'Communication / maladies neurodégénératives (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel:
        'Maintien et adaptation des fonctions de communication chez les personnes atteintes de maladies\nneurodégénératives'
    },
    {
      shortLabel: 'Démutisation dans les surdités du premier âge (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel:
        "Démutisation dans les surdités du premier âge, appareillées ou non, y compris en cas d'implantation cochléaire, par\nséance"
    },
    {
      shortLabel: 'Communication, langage et parole dans les surdités (groupe avant le 25/10/2022)',
      code: '5',
      fullLabel:
        "Rééducation ou conservation de la communication, du langage et de la parole dans les surdités appareillées ou non,\ny compris en cas d'implantation cochléaire"
    },
    {
      shortLabel: 'Retards de parole, troubles de la communication et du langage oral (3 à 6 ans)',
      code: '12,6',
      fullLabel:
        'rééducation des retards de parole, des troubles de la communication et du langage oral chez les enfants de 3 à 6 ans'
    },
    {
      shortLabel: "Troubles du graphisme et de l'écriture (entre Octobre 2022 et Juillet 2023)",
      code: '10,7',
      fullLabel: "Troubles du graphisme et de l'écriture (entre Octobre 2022 et Juillet 2023)"
    },
    {
      shortLabel: 'Troubles de la communication et du langage écrit (entre Octobre 2022 et Juillet 2023)',
      code: '10,8',
      fullLabel: 'Troubles de la communication et du langage écrit (entre Octobre 2022 et Juillet 2023)'
    },
    {
      shortLabel: 'Troubles du calcul et du raisonnement logico-mathématique (entre Octobre 2022 et Juillet 2023)',
      code: '10,9',
      fullLabel: 'Troubles du calcul et du raisonnement logico-mathématique (entre Octobre 2022 et Juillet 2023)'
    },
    {
      shortLabel: "Troubles du graphisme et de l'écriture (à partir de Juillet 2023)",
      code: '11,5',
      fullLabel: "Troubles du graphisme et de l'écriture (à partir de Juillet 2023)"
    },
    {
      shortLabel: 'Troubles de la communication et du langage écrit (à partir de Juillet 2023)',
      code: '11,6',
      fullLabel: 'Troubles de la communication et du langage écrit (à partir de Juillet 2023)'
    },
    {
      shortLabel: 'Troubles du calcul et du raisonnement logico-mathématique (à partir de Juillet 2023)',
      code: '11,7',
      fullLabel: 'Troubles du calcul et du raisonnement logico-mathématique (à partir de Juillet 2023)'
    },
    {
      shortLabel: "Troubles de la voix d'origine organique ou fonctionnelle (groupe à partir du 25/10/2022)",
      code: '9',
      fullLabel: "Rééducation des troubles de la voix d'origine organique ou fonctionnelle"
    },
    {
      shortLabel: 'Voix oro-oesophagienne et/ou trachéo-oesophagienne (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel: "Éducation à l'acquisition et à l'utilisation de la voix oro-oesophagienne et/ou trachéo-oesophagienne"
    },
    {
      shortLabel: 'Troubles de la communication et du langage écrit (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel: 'Rééducation des troubles de la communication et du langage écrit'
    },
    {
      shortLabel: 'Troubles du calcul / raisonnement logico-mathématique (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel: 'Rééducation des troubles du calcul et du raisonnement logico-mathématique'
    },
    {
      shortLabel:
        'Retards de parole, des troubles de la communication et du langage oral (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel: 'Rééducation des retards de parole, des troubles de la communication et du langage oral'
    },
    {
      shortLabel: 'Bégaiements et des autres troubles de la fluence (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel: 'Rééducation des bégaiements et des autres troubles de la fluence'
    },
    {
      shortLabel: 'Pratique de la lecture labiale (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel: 'Éducation à la pratique de la lecture labiale'
    },
    {
      shortLabel: 'Dysphasies (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel: 'Rééducation des dysphasies'
    },
    {
      shortLabel: 'Communication et langage, aphasies (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel: 'Rééducation de la communication et du langage dans les aphasies'
    },
    {
      shortLabel: 'Communication et langage, non aphasiques (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel:
        "Rééducation des troubles de la communication et du langage non aphasiques dans le cadre d'autres atteintes\nneurologiques"
    },
    {
      shortLabel: 'Communication / maladies neurodégénératives (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel:
        'Maintien et adaptation des fonctions de communication chez les personnes atteintes de maladies\nneurodégénératives'
    },
    {
      shortLabel: 'Démutisation dans les surdités du premier âge (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel:
        "Démutisation dans les surdités du premier âge, appareillées ou non, y compris en cas d'implantation cochléaire, par\nséance"
    },
    {
      shortLabel: 'Communication, langage et parole dans les surdités (groupe à partir du 25/10/2022)',
      code: '9',
      fullLabel:
        "Rééducation ou conservation de la communication, du langage et de la parole dans les surdités appareillées ou non,\ny compris en cas d'implantation cochléaire"
    },
    {
      shortLabel: "Dysphagies chez l'adulte et l'enfant (à partir du 25/10/2022)",
      code: '12,8',
      fullLabel: "Dysphagies chez l'adulte et l'enfant (à partir du 25/10/2022)"
    },
    {
      shortLabel: 'Voix oro-oesophagienne et/ou trachéo-oesophagienne (à partir du 25/10/2022)',
      code: '13',
      fullLabel: 'Voix oro-oesophagienne et/ou trachéo-oesophagienne (à partir du 25/10/2022)'
    },
    {
      shortLabel: "Troubles de l'articulation / déficiences organiques (à partir du 25/10/2022)",
      code: '9,7',
      fullLabel: "Troubles de l'articulation / déficiences organiques (à partir du 25/10/2022)"
    },
    {
      shortLabel: "Troubles de l'articulation / déficiences perceptives (à partir du 25/10/2022)",
      code: '9,7',
      fullLabel: "Troubles de l'articulation / déficiences perceptives (à partir du 25/10/2022)"
    },
    {
      shortLabel: 'Déglutition atypique (à partir du 25/10/2022)',
      code: '9,8',
      fullLabel: 'Déglutition atypique (à partir du 25/10/2022)'
    },
    {
      shortLabel: 'Vélo-tubo-tympanique (à partir du 25/10/2022)',
      code: '9,9',
      fullLabel: 'Vélo-tubo-tympanique (à partir du 25/10/2022)'
    }
  ]
};

export default AMOCodes;
