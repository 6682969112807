import React from 'react';

import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid, InputAdornment,
  MenuItem, Radio,
  RadioGroup, Switch,
  Typography
} from "@material-ui/core";
import ReportGraphicsChart from "../../patient/report/ReportGraphicsChart";
import appVersionSpecific from "../../../appVersionSpecific.ortho";
import TextFieldFormControl from "../../TextFieldFormControl";
import labels from "../../../constants/labels/common/reportOptions/ReportGraphicForm.json";
import ColorResultEditor from "../../patient/report/ColorResultEditor";

const ReportGraphicForm = ({content, options, change, gTypes, setShowAdvancedOptions, showAdvancedOptions, switchChange, renewal, switchLegendTypeChange, colorResultEditors, resetColors, selectFont}) => {
  return <Grid container>
    <Grid item xs={12}>
      <ReportGraphicsChart content={content} debounceDelay={500}/>
    </Grid>
    <Grid item xs={12}>
      <Divider/>
    </Grid>
      <Grid item xs={6} container>
        <TextFieldFormControl
          itemMdValue={12}
          id="mainChartFormat"
          controlName="mainChartFormat"
          label={labels.graphicType.label}
          select
          value={options.mainChartFormat}
          onChangeHandler={change}
        >
          {gTypes.map(({id, label}) => (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          ))}
        </TextFieldFormControl>
        <Grid item xs={6}>
          <Typography variant="h6">{labels.graphicOptions.label}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
            {showAdvancedOptions
              ? labels.graphicOptions.advancedOptions.hide
              : labels.graphicOptions.advancedOptions.show}{' '}
            {labels.graphicOptions.advancedOptions.label}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{labels.graphicOptions.subGraphicType.label}</Typography>
          {(options.mainChartFormat === 'vertical-columns' || options.mainChartFormat === 'vertical-columns-2') && (
            <FormControl component="fieldset">
              <RadioGroup
                name="mainChartOptions.verticalColumnsSubtype"
                value={options.mainChartOptions.verticalColumnsSubtype}
                onChange={change}
              >
                <FormControlLabel
                  value="detailed"
                  control={<Radio/>}
                  label={
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        {labels.graphicOptions.subGraphicType.verticalColumns.detailed}
                      </Grid>
                      <Grid item xs={6} container>
                        {showAdvancedOptions && (
                          <TextFieldFormControl
                            itemMdValue={12}
                            id="mainChartOptions.verticalColumnsMaxColumns"
                            controlName="mainChartOptions.verticalColumnsMaxColumns"
                            value={options.mainChartOptions.verticalColumnsMaxColumns}
                            label={
                              labels.graphicOptions.subGraphicType.verticalColumns.advancedOptions
                                .verticalColumnsMaxColumns
                            }
                            inputType="number"
                            fullWidth
                            onChangeHandler={change}
                            disabled={options.mainChartOptions.verticalColumnsSubtype !== 'detailed'}
                            inputProps={{
                              min: 4,
                              max: 16
                            }}
                          />
                        )}
                        {showAdvancedOptions && (
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={options.mainChartOptions.verticalColumnsSubtype !== 'detailed'}
                                  checked={options.mainChartOptions.verticalColumnsSort}
                                  onChange={switchChange('mainChartOptions.verticalColumnsSort')}
                                />
                              }
                              label={
                                labels.graphicOptions.subGraphicType.verticalColumns.advancedOptions
                                  .verticalColumnsSort
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  }
                />
                <FormControlLabel
                  value="average"
                  control={<Radio/>}
                  label={labels.graphicOptions.subGraphicType.verticalColumns.simpleMean}
                />
              </RadioGroup>
            </FormControl>
          )}
          {options.mainChartFormat === 'vertical-points' && (
            <FormControl component="fieldset">
              <RadioGroup
                name="mainChartOptions.verticalPointsSubtype"
                value={options.mainChartOptions.verticalPointsSubtype}
                onChange={change}
              >
                <FormControlLabel
                  value="nopoint"
                  control={<Radio/>}
                  label={labels.graphicOptions.subGraphicType.verticalPoints.pointOnly}
                />
                <FormControlLabel
                  value="minmax"
                  control={<Radio/>}
                  label={labels.graphicOptions.subGraphicType.verticalPoints.pointAndBar}
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        {options.mainChartFormat === 'vertical-points' && (
          <Grid item xs={12}>
            <Typography variant="body1">
              {labels.graphicOptions.subGraphicType.verticalPoints.curvePlot.title}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={options.mainChartOptions.verticalPointsDrawLine}
                  onChange={switchChange('mainChartOptions.verticalPointsDrawLine')}
                />
              }
              label={labels.graphicOptions.subGraphicType.verticalPoints.curvePlot.label}
            />
          </Grid>
        )}
        {showAdvancedOptions && options.mainChartFormat === 'vertical-points' && (
          <Grid item xs={12} container spacing={8}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.pointParameters.title}
              </Typography>
            </Grid>
            <TextFieldFormControl
              itemMdValue={6}
              id="mainChartOptions.verticalPointsRadius"
              controlName="mainChartOptions.verticalPointsRadius"
              onChangeHandler={change}
              value={options.mainChartOptions.verticalPointsRadius}
              label={labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.pointParameters.label}
              fullWidth
              inputProps={{
                size: 1,
                min: 1,
                max: 99,
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.pointParameters
                        .endAdornment
                    }
                  </InputAdornment>
                )
              }}
            />
            <Grid item xs={6}/>
            <TextFieldFormControl
              itemMdValue={6}
              id="mainChartOptions.verticalPointsLineColor"
              controlName="mainChartOptions.verticalPointsLineColor"
              onChangeHandler={change}
              fullWidth
              label={
                labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.pointParameters.contourColor
              }
              disabled={options.mainChartOptions.verticalPointsDrawLine}
              inputType="color"
              value={options.mainChartOptions.verticalPointsLineColor}
            />
            <TextFieldFormControl
              id="mainChartOptions.verticalPointsWidth"
              controlName="mainChartOptions.verticalPointsWidth"
              label={
                labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.pointParameters.contourThickness
                  .label
              }
              onChangeHandler={change}
              fullWidth
              value={options.mainChartOptions.verticalPointsWidth}
              inputProps={{
                size: 2,
                min: 1,
                max: 32,
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.pointParameters
                        .contourThickness.endAdornment
                    }
                  </InputAdornment>
                )
              }}
            />
            <Grid item xs={12}>
              <Typography variant="body1">
                {labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.minMaxBarParameters.title}
              </Typography>
            </Grid>
            <TextFieldFormControl
              itemMdValue={6}
              id="mainChartOptions.verticalPointsBarColor"
              controlName="mainChartOptions.verticalPointsBarColor"
              onChangeHandler={change}
              fullWidth
              label={
                labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.minMaxBarParameters
                  .barBackgroundColor
              }
              disabled={!options.mainChartOptions.verticalPointsBarColor}
              inputType="color"
              value={options.mainChartOptions.verticalPointsBarColor}
            />
            <TextFieldFormControl
              id="mainChartOptions.verticalPointsMinMaxWidth"
              controlName="mainChartOptions.verticalPointsMinMaxWidth"
              label={
                labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.minMaxBarParameters.barwidth
                  .label
              }
              onChangeHandler={change}
              fullWidth
              value={options.mainChartOptions.verticalPointsMinMaxWidth}
              inputProps={{
                size: 2,
                min: 2,
                max: 99,
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      labels.graphicOptions.subGraphicType.verticalPoints.advancedOptions.minMaxBarParameters
                        .barwidth.endAdornment
                    }
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1">{labels.graphicOptions.otherOptions.label}</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={options.mainChartOptions.drawColumnsSeparator}
                onChange={switchChange('mainChartOptions.drawColumnsSeparator')}
              />
            }
            label={labels.graphicOptions.otherOptions.columnsMarking}
          />
        </Grid>
        {renewal && (
          <Grid item xs={12}>
            <Typography variant="h6">{labels.renewalCheckup.label}</Typography>
          </Grid>
        )}
        {renewal && (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                name="renewalOptions.useMarker"
                value={options.renewalOptions.useMarker}
                onChange={change}
              >
                <FormControlLabel value="none" control={<Radio/>} label={labels.renewalCheckup.useMarker.none}/>
                <FormControlLabel
                  value="diamond"
                  control={<Radio/>}
                  label={labels.renewalCheckup.useMarker.diamond}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        {renewal && showAdvancedOptions && (
          <TextFieldFormControl
            itemMdValue={6}
            id="renewalOptions.markerColor"
            controlName="renewalOptions.markerColor"
            disabled={options.renewalOptions.useMarker === 'none'}
            inputType="color"
            label={labels.renewalCheckup.markerColor}
            value={options.renewalOptions.markerColor}
            fullWidth
            onChangeHandler={change}
          />
        )}
      </Grid>
    <Grid item xs={6} container>
      <Grid item xs={12}>
        <Typography variant="h6">{labels.colorAndLegends.label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={options.legendOptions.averageDetails}
              onChange={switchChange('legendOptions.averageDetails')}
            />
          }
          label={labels.colorAndLegends.highAndLowMeanDiscernment}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch checked={options.legendType === 'legacy'} onChange={switchLegendTypeChange('legendType')}/>
          }
          label={labels.colorAndLegends.useV1Legend}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">{labels.colorAndLegends.graphicColors.label}</Typography>
        <Divider/>
      </Grid>
      {colorResultEditors.map(editor => (
        <ColorResultEditor key={editor.colorId} change={change} {...editor} />
      ))}
      <Grid item xs={8}/>
      <Grid item xs={4}>
        <Button color="primary" onClick={resetColors}>
          {labels.colorAndLegends.reinitColors}
        </Button>
      </Grid>
      {appVersionSpecific.reportOptions.reportGraphics.showFontSelection && (
        <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="h6">{labels.font.label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {options.mainChartFont.family}, {options.mainChartFont.size} {labels.font.unit}
            </Typography>
            <Button onClick={selectFont}>{labels.font.modify}</Button>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  </Grid>;
};

export default ReportGraphicForm;
