import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, FormControlLabel, Grid, MenuItem, Switch, Typography } from '@material-ui/core';
import { ShowChart } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';

import routes from '../../../constants/routes';
import Patient from '../../common/data/Patient';
import TestListingHeader from './TestListingHeader';
import TextFieldFormControl from '../../TextFieldFormControl';
import labels from '../../../constants/labels/patient/test/TestListingByModel.json';
import User from '../../common/data/User';
import {
  getTestValues,
  listBilanDoneTestCountByTestCode,
  listDisplayableBilanTests,
  updateCurrentTestResultsFromCalculOption
} from './TestUtils';
import { filterChilds, mapWithChilds } from './TestListingByModelUtils';
import TestListingByModelElement from './TestListingByModelElement';
import CalculationTableSelector from './CalculationTableSelector';
import BehaviourComments from '../comments/BehaviourComments';
import ReportComments from '../comments/ReportComments';
import TestListingMoreActionLine from './TestListingMoreActionLine';
import defaultReportGenerationOptions from '../../../constants/user/defaultReportGenerationOptions';
import ReportGraphicsChart from '../report/ReportGraphicsChart';
import ReportAnamnesis from '../report/ReportAnamnesis';
import TestListingStyle from './TestListingStyle.json';
import addCustomTestAffectationsToCheckUpModel from '../report/ModelCustomTestUtil';
import AccordionWithTitleAndContent from './testListingByModel/AccordionWithTitleAndContent';
import PatientContentUtil from '../PatientContentUtil';

const useStyles = makeStyles(TestListingStyle);

type Props = {
  match: { params: { patientId: string } }
};

const TestListingByModel = ({
  match: {
    params: { patientId }
  }
}: Props) => {
  const classes = useStyles();

  const [patient, setPatient] = useState({});
  const [bilan, setBilan] = useState([]);
  const [userSelectedModel, setUserSelectedModel] = useState({ elements: [] });
  const [userSelectedModelElementsWithChilds, setUserSelectedModelElementsWithChilds] = useState([]);
  const [checkupModels, setCheckupModels] = useState([]);
  const [testValuesByTestCode, setTestValuesByTestCode] = useState({});
  const [initialTestValuesByTestCode, setInitialTestValuesByTestCode] = useState({});
  const [customTests, setCustomTests] = useState([]);
  const [cotation, setCotation] = useState({});
  const [comments, setComments] = useState({
    behaviours: [],
    summary: { text: '' },
    diagnosis: { text: '' },
    patientCare: { text: '' },
    therapeuticPlan: { text: '' }
  });
  const [content, setContent] = useState({
    anamnesisText: '',
    bilan: [],
    patient: {},
    options: defaultReportGenerationOptions
  });
  const [reloadChart, setReloadChart] = useState(true);
  const [allExpandeds, setAllExpandeds] = useState({});
  const [hideNonFilledTests, setHideNonFilledTests] = useState(false);

  function getTestValuesByTestCode(bilanTestCodes, _customTests, _cotation) {
    const testValuesByTestCodeInit = bilanTestCodes.reduce((acc, testCode) => {
      acc[testCode] = getTestValues(_customTests, testCode, _cotation);
      return acc;
    }, {});
    console.log({ testValuesByTestCodeInit, bilanTestCodes, _customTests, _cotation });
    return testValuesByTestCodeInit;
  }

  const getUserSelectedModel = useCallback(async () => {
    if (checkupModels.length !== 0) {
      const { val: defaultModelFromDb } = await User.retrieveValueFromDb({ keyValue: 'model.default' });
      const shifted = checkupModels.filter(({ _id }) => defaultModelFromDb === _id).shift();
      const selectedModel = checkupModels.length === 1 ? checkupModels[0] : shifted || checkupModels[0];
      await setUserSelectedModel(selectedModel);
    }
  }, [checkupModels]);

  useEffect(() => {
    async function retrievePatient() {
      const contentFromDb = await PatientContentUtil.getContent(patientId);
      const {
        patient: patientFromDb,
        checkupModels: checkupModelsFromDb,
        customTests: customTestsFromDb,
        initialPatient: { cotation: initialCotation = { results: {}, initialResults: {} } }= {}
      } = contentFromDb;
      const {
        bilan: bilanFromDb,
        cotation: cotationFromDb,
        comments: commentsFromDb
      } = patientFromDb;

      const bilanTestCodes = (bilanFromDb.tests || []).map(({ testCode }) => testCode).filter(testCode => !!testCode);
      const testValuesByTestCodeInit = getTestValuesByTestCode(bilanTestCodes, customTestsFromDb, cotationFromDb);
      const initialTestValuesByTestCodeInit = getTestValuesByTestCode(bilanTestCodes, customTestsFromDb, initialCotation || { results: {}, initialResults: {} });
      console.log({testValuesByTestCodeInit, initialTestValuesByTestCodeInit});
      const selectedTestCodes = (bilanFromDb.tests || []).map(test => test.testCode);
      const {
        results = {
          results: {},
          initialResults: {}
        }
      } = cotationFromDb;
      const displayableTests = await listDisplayableBilanTests(selectedTestCodes, customTestsFromDb);
      const doneTestCount = listBilanDoneTestCountByTestCode(displayableTests, results);
      const totalDoneTestCount = Object.entries(doneTestCount).reduce((acc, [, value]) => acc + value, 0);

      // should be done in ReportGeneratorUtil.getContent, but it's breaking report generation...
      checkupModelsFromDb[0] = addCustomTestAffectationsToCheckUpModel(checkupModelsFromDb[0], customTestsFromDb);

      await setHideNonFilledTests(totalDoneTestCount !== 0);
      await setCustomTests(customTestsFromDb);
      await setPatient(patientFromDb);
      await setBilan(bilanFromDb);
      await setCheckupModels(checkupModelsFromDb);
      await setTestValuesByTestCode(testValuesByTestCodeInit);
      await setCotation(cotationFromDb);
      await setComments(commentsFromDb);
      await setContent(contentFromDb);
      await setInitialTestValuesByTestCode(initialTestValuesByTestCodeInit)
    }

    retrievePatient().then();
  }, [patientId]);

  useEffect(() => {
    getUserSelectedModel().then();
  }, [checkupModels, getUserSelectedModel]);

  useEffect(() => {
    async function affectTestCodesFromChilds() {
      // addCustomTestAffectationsToCheckUpModel()
      const { elements } = userSelectedModel;
      const [firstLevel] = elements.map(({ level }) => level).sort();
      const [tvbtc, hnft] = [testValuesByTestCode, hideNonFilledTests];

      const elementsWithIndex = elements.map(({ index, ...elementRest }, idx) => ({
        index: idx,
        ...elementRest
      }));
      const elementsWithChilds = elementsWithIndex
        .map(mapWithChilds(elementsWithIndex))
        .map(e => filterChilds(e, tvbtc, hnft))
        .filter(obj => !!obj)
        .filter(({ level }) => level === firstLevel);

      await setUserSelectedModelElementsWithChilds(elementsWithChilds);
    }

    affectTestCodesFromChilds().then();
  }, [userSelectedModel, testValuesByTestCode, hideNonFilledTests]);

  useEffect(() => {
    async function initAllExpanded() {
      const { elements } = userSelectedModel;
      await setAllExpandeds(
        Object.values(elements).reduce((acc, { uuid }) => {
          acc[uuid] = true;
          return acc;
        }, {})
      );
    }

    initAllExpanded().then();
  }, [userSelectedModel]);

  useEffect(() => {
    const updateContent = async () => {
      const contentFromDb = await PatientContentUtil.getContent(patientId);
      await setContent(contentFromDb);
      await setReloadChart(r => !r);
    };

    updateContent().then();
  }, [cotation, patientId]);

  const changeUserValue = key => async event => {
    await User.save({ keyValue: key, value: event.target.value });
    await getUserSelectedModel();
  };

  const changeSelectedCalculusTableHandler = testCode => async event => {
    const {
      [testCode]: { currentTest, currentTestResults },
      ...otherTestValuesByTestCode
    } = testValuesByTestCode;

    const newTestResults = JSON.parse(JSON.stringify(currentTestResults));
    newTestResults.$$calculOption = event.target.value;

    const { updatedCurrentTestResults: values } = updateCurrentTestResultsFromCalculOption(currentTest, newTestResults);
    cotation.results[testCode] = values;
    const updatedPatient = await Patient.updatePatient({ ...patient, cotation });
    const currentTestCodeValues = getTestValues(customTests, testCode, cotation);
    await setTestValuesByTestCode({ [testCode]: currentTestCodeValues, ...otherTestValuesByTestCode });
    await setCotation(updatedPatient.cotation);
    await setPatient(updatedPatient);
  };

  const changeTestValueHandler = testCode => async event => {
    const {
      [testCode]: { currentTestResults },
      ...otherTestValuesByTestCode
    } = testValuesByTestCode;

    const newTestResults = JSON.parse(JSON.stringify(currentTestResults));

    cotation.results[testCode] = _.set(newTestResults, event.target.name, event.target.value);
    const updatedPatient = await Patient.updatePatient({ ...patient, cotation });
    const currentTestCodeValues = getTestValues(customTests, testCode, cotation);
    await setTestValuesByTestCode({ [testCode]: currentTestCodeValues, ...otherTestValuesByTestCode });
    await setCotation(updatedPatient.cotation);
    await setPatient(updatedPatient);
  };

  const updateComment = async (uuid, comment) => {
    const newComments = JSON.parse(JSON.stringify(comments));
    newComments[uuid] = comment;
    const updatedPatient = await Patient.updatePatient({ ...patient, comments: newComments });
    await setComments(newComments);
    await setPatient(updatedPatient);
  };

  const testLabelByTestCode = Object.values(testValuesByTestCode).reduce((acc, { currentTest }) => {
    acc[currentTest.testCode] = currentTest.label;
    return acc;
  }, {});

  const setModelElementExpanded = uuid => expanded => {
    setAllExpandeds({ ...allExpandeds, [uuid]: expanded });
  };

  const updateAnamnesisText = useCallback(async anamnesisText => {
    const updatedPatient = await Patient.updatePatient({ ...patient, anamnesisText });
    await setPatient(updatedPatient);
  }, [patient]);

  const isRenewal = !!bilan.initialAssessmentUUID;

  return (
    <Fragment>
      <TestListingHeader
        bilan={bilan}
        patient={patient}
        switchState
        switchRoute={routes.PATIENT.TESTS.getForPath({
          path: routes.PATIENT.TESTS.TESTS,
          patientId,
          bilanId: bilan._id
        })}
      >
        <TextFieldFormControl
          itemMdValue={12}
          id="selectedCheckupModel"
          controlName="selectedCheckupModel"
          select
          fullWidth
          label={labels.selectCheckupModel}
          onChangeHandler={changeUserValue('model.default')}
          value={userSelectedModel._id}
        >
          {checkupModels.map(({ _id, label }) => (
            <MenuItem key={_id} value={_id}>
              {label}
            </MenuItem>
          ))}
        </TextFieldFormControl>
        <Grid item md={12}>
          <FormControlLabel
            control={
              <Switch
                checked={hideNonFilledTests}
                onChange={() => setHideNonFilledTests(!hideNonFilledTests)}
                value="hideNonFilledTests"
              />
            }
            label={labels.hideNonFilledTests}
          />
        </Grid>
        <Grid item md={12}>
          <FormControlLabel
            control={
              <Switch
                checked={Object.values(allExpandeds).every(value => !!value)}
                onChange={() => {
                  const newValue = !Object.values(allExpandeds).every(value => !!value);
                  setAllExpandeds(
                    Object.values(userSelectedModel.elements).reduce((acc, { uuid }) => {
                      acc[uuid] = newValue;
                      return acc;
                    }, {})
                  );
                }}
              />
            }
            label={Object.values(allExpandeds).every(value => !!value) ? labels.expandLess : labels.expandMore}
          />
        </Grid>
      </TestListingHeader>
      <Grid container className={classNames(classes.belowHeader, classes.belowHeaderMore)} spacing={2}>
        <AccordionWithTitleAndContent
          title={labels.anamnesis}
          content={<ReportAnamnesis content={content} withLabel={false} updateAnamnesisText={updateAnamnesisText} />}
        />

        <AccordionWithTitleAndContent
          title={labels.behaviourComments}
          content={
            <BehaviourComments
              comments={comments}
              patientId={patientId || ''}
              bilanId={bilan._id || ''}
              cotation={cotation}
              testLabelByTestCode={testLabelByTestCode}
              updateComments={newComments => setComments(newComments)}
              updateCotation={newCotation => setCotation(newCotation)}
              updatePatient={newPatient => setPatient(newPatient)}
              patient={patient}
            />
          }
        />

        <AccordionWithTitleAndContent
          title={labels.calculationTables}
          content={Object.entries(testValuesByTestCode)
            .sort(
              (
                [
                  ,
                  {
                    currentTest: { label: a }
                  }
                ],
                [
                  ,
                  {
                    currentTest: { label: b }
                  }
                ]
              ) => a && b && a.localeCompare(b)
            )
            .map(([key, { currentTest, currentTestResults }]) => {
              if (currentTest.calcul && currentTest.calcul.length) {
                return (
                  <CalculationTableSelector
                    key={key}
                    currentTest={currentTest}
                    currentTestResults={currentTestResults}
                    changeSelectedCalculusTable={changeSelectedCalculusTableHandler(key)}
                    fieldLabel={currentTest.label}
                    mdValue={3}
                  />
                );
              }
              return (
                <Grid key={key} item md={3} className={classes.marginTopBottomAuto}>
                  <Typography variant="body1">{currentTest.label}</Typography>
                </Grid>
              );
            })}
        />

        <AccordionWithTitleAndContent
          title={labels.chart}
          content={<ReportGraphicsChart content={content} reloadChart={reloadChart} />}
          accordionActions={
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.PATIENT.REPORT.getForPath({
                path: routes.PATIENT.REPORT.GRAPHICS,
                patientId: patient._id
              })}
            >
              <ShowChart /> {labels.graphicsOptions}
            </Button>
          }
        />

        <AccordionWithTitleAndContent
          title={labels.checkup}
          content={userSelectedModelElementsWithChilds.map(({ uuid, ...element }) => (
            <TestListingByModelElement
              key={uuid}
              testValuesByTestCode={testValuesByTestCode}
              initialTestValuesByTestCode={initialTestValuesByTestCode}
              changeTestValueHandler={changeTestValueHandler}
              setModelElementExpanded={setModelElementExpanded}
              allExpandeds={allExpandeds}
              uuid={uuid}
              hideNonFilledTests={hideNonFilledTests}
              lastname={patient.lastName}
              firstname={patient.firstName}
              options={content.options}
              comments={comments}
              updateComment={updateComment}
              isRenewal={isRenewal}
              userSelectedModelId={userSelectedModel._id}
              {...element}
            />
          ))}
        />

        <AccordionWithTitleAndContent
          title={labels.checkupAnalysis}
          content={
            <ReportComments
              comments={comments}
              updateCommentsFunction={setComments}
              patient={patient}
              updatePatientFunction={setPatient}
            />
          }
        />
        <TestListingMoreActionLine patientId={patientId} />
      </Grid>
    </Fragment>
  );
};

export default TestListingByModel;
