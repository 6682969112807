import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Divider, Grid, IconButton, Paper, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import routes from '../../../constants/routes';
import labels from '../../../constants/labels/patient/anamnesis/AnamnesisFormCompletion.json';
import Patient from '../../common/data/Patient';
import AnamnesisRow from './AnamnesisRow';
import BackButton from '../../common/BackButton';
import AnamnesisLabel from './AnamnesisLabel';
import MedecinTraitants from '../../common/data/MedecinTraitants';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  root: {
    flexGrow: 1
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#1890ff'
  },
  tabRoot: {
    color: 'white',
    backgroundColor: 'grey',
    borderRadius: '50%',
    textTransform: 'initial',
    minWidth: 24,
    minHeight: 24,
    marginRight: '1em',
    marginLeft: '1em',
    display: 'inline',
    position: 'relative',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      opacity: 1
    },
    '&$tabSelected': {
      backgroundColor: theme.palette.secondary.main
    },
    '&:first-child::before': {
      content: 'none'
    },
    '&:last-child::after': {
      content: 'none'
    }
  },
  tabSelected: {}
}));

type Props = {
  match: ?{ params: ?{ patientId: ?string } }
};

// eslint-disable-next-line react/prop-types
const NavigationArrows = ({ selectedTabIndex, anamnesis, incrementSelectedTab, decrementSelectedTab }) => (
  <Grid item xs={12} container justifyContent="space-between">
    <Grid item xs={1}>
      {selectedTabIndex !== 0 && (
        <Tooltip title={labels.previous}>
          <IconButton onClick={() => decrementSelectedTab()}>
            <NavigateBefore />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
    <Grid item xs={1}>
      {/* eslint-disable-next-line react/prop-types */}
      {anamnesis && anamnesis.definitions && selectedTabIndex !== anamnesis.definitions.length - 1 && (
        <Tooltip title={labels.next}>
          <IconButton onClick={() => incrementSelectedTab()}>
            <NavigateNext />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  </Grid>
);

async function updateInitialAssessment(initialAssessmentUUID, updatedAnamnesis) {
  const patientInit = await Patient.getInitialAssessmentPatient(initialAssessmentUUID);
  if (patientInit) {
    await Patient.updatePatient({
      ...patientInit,
      anamnese: { ...updatedAnamnesis, bilanId: patientInit.bilan.id, patientId: patientInit._id }
    });
  }
}

async function updateRenewalAssessment(bilanUuid, updatedAnamnesis) {
  const renewalPatients = await Patient.getRenewalAssessmentPatients(bilanUuid);
  if (renewalPatients && renewalPatients.length) {
    // eslint-disable-next-line no-restricted-syntax
    for await (const renewalPatient of renewalPatients) {
      await Patient.updatePatient({
        ...renewalPatient,
        anamnese: { ...updatedAnamnesis, bilanId: renewalPatient.bilan.id, patientId: renewalPatient._id }
      });
    }
  }
}

async function getUpdatedAnamnesis(event, anamnesis) {
  console.log({ event, anamnesis });
  if (event.target.name === 'medecintraitant' && event.target.value) {
    // search in medecinTraitant list and update adressemedecin, telephonemedecin and emailmedecin fields
    const doctor = await MedecinTraitants.getValuesForName(event.target.value);
    if (!doctor) {
      await MedecinTraitants.createValueForName(event.target.value);
    }
    const { adressemedecin, telephonemedecin, emailmedecin } = doctor ||
      {
        adressemedecin: anamnesis.values.adressemedecin,
        telephonemedecin: anamnesis.values.telephonemedecin,
        emailmedecin: anamnesis.values.emailmedecin
      };
    return {
      ...anamnesis,
      values: {
        ...anamnesis.values,
        medecintraitant: event.target.value,
        adressemedecin,
        telephonemedecin,
        emailmedecin
      }
    };
  }

  if (['adressemedecin', 'telephonemedecin', 'emailmedecin'].includes(event.target.name)) {
    // update value in MedecinTraitant list
    await MedecinTraitants.updateMedecin({
      field: event.target.name,
      value: event.target.value,
      name: anamnesis.values.medecintraitant
    });
    return {
      ...anamnesis,
      values: {
        ...anamnesis.values,
        [event.target.name]: event.target.value
      }
    };
  }

  return new Promise(async resolve => {
    resolve({
      ...anamnesis,
      values: {
        ...anamnesis.values,
        [event.target.name]: event.target.value
      }
    });
  });
}

const AnamnesisFormCompletion = ({
  match: {
    params: { patientId }
  }
}: Props) => {
  const [patient, setPatient] = useState({});
  const [anamnesis, setAnamnesis] = useState({ definitions: [] });
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const fetchPatient = useCallback(async () => {
    const patientFromDb = await Patient.retrievePatient(patientId);
    await setPatient(patientFromDb);
    await setAnamnesis(patientFromDb.anamnese);
  }, [patientId]);

  useEffect(() => {
    fetchPatient().then();
  }, [patientId, fetchPatient]);

  const handleTabChange = (event, value) => {
    setSelectedTabIndex(value);
  };

  const deleteDoctor = async event => {
    console.log('############################HERE##################');
    await MedecinTraitants.deleteMedecin(event.target.value);
    const updatedAnamnesis = {
      ...anamnesis,
      values: {
        ...anamnesis.values,
        medecintraitant: '',
        adressemedecin: '',
        telephonemedecin: '',
        emailmedecin: ''
      }
    };
    await updateAnamnesis(updatedAnamnesis);
  };

  async function updateAnamnesis(updatedAnamnesis) {
    if (patient && patient.bilan && patient.bilan.initialAssessmentUUID) {
      await updateInitialAssessment(patient.bilan.initialAssessmentUUID, updatedAnamnesis);
    }
    if (patient && patient.bilan && patient.bilan.uuid) {
      await updateRenewalAssessment(patient.bilan.uuid, updatedAnamnesis);
    }
    const updatedPatient = await Patient.updatePatient({ ...patient, anamnese: updatedAnamnesis });
    setPatient(updatedPatient);
    setAnamnesis(updatedPatient.anamnese);
  }

  const onChange = async event => {
    const updatedAnamnesis = await getUpdatedAnamnesis(event, anamnesis);
    await updateAnamnesis(updatedAnamnesis);
  };

  const classes = useStyles();

  console.log({ anamnesis });

  if (anamnesis && anamnesis._id && patient.plainte && !anamnesis.values.plainte) {
    console.log({ patient, anamnesis });
    onChange({ target: { name: 'plainte', value: patient.plainte } });
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <BackButton backRoute={routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId })} />
        <Grid item xs={12}>
          <Typography variant="h4">
            {labels.subtitle}
            <AnamnesisLabel patientId={patientId} patient={patient} onAnamnesisModelChange={fetchPatient} />
            {labels.subtitle2}
            <span className={classes.bold}>
              {patient.firstName} {patient.lastName}
            </span>
            {' : '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Tabs value={selectedTabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="on">
            {anamnesis &&
              anamnesis.definitions &&
              anamnesis.definitions.map(({ id, titre }, index) => (
                <Tooltip title={titre} key={id}>
                  <Tab
                    disableRipple
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    label={`${index + 1}`}
                  />
                </Tooltip>
              ))}
          </Tabs>
        </Grid>
        <Paper style={{ width: '100%', paddingLeft: '1em', paddingRight: '1em' }}>
          <NavigationArrows
            selectedTabIndex={selectedTabIndex}
            anamnesis={anamnesis}
            decrementSelectedTab={() => setSelectedTabIndex(selectedTabIndex - 1)}
            incrementSelectedTab={() => setSelectedTabIndex(selectedTabIndex + 1)}
          />
          {anamnesis &&
            anamnesis.definitions &&
            anamnesis.definitions[selectedTabIndex] &&
            anamnesis.definitions[selectedTabIndex].rows.map((rowArray, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item xs={12} container justifyContent="space-between">
                <AnamnesisRow
                  row={rowArray}
                  values={anamnesis.values}
                  changeHandler={onChange}
                  deleteDoctor={deleteDoctor}
                />
              </Grid>
            ))}
          <NavigationArrows
            selectedTabIndex={selectedTabIndex}
            anamnesis={anamnesis}
            decrementSelectedTab={() => setSelectedTabIndex(selectedTabIndex - 1)}
            incrementSelectedTab={() => setSelectedTabIndex(selectedTabIndex + 1)}
          />
        </Paper>
      </Grid>
    </Fragment>
  );
};

export default AnamnesisFormCompletion;
