export const inputIncludesScore = (input) => input.includes("SCORE");
export const inputIncludesScoreET = (input) => input.includes("SCORE_ET");
export const inputIncludesScoreOrScoreET = (input) => inputIncludesScore(input) || inputIncludesScoreET(input);

export const getScoreOrScoreETValue = (value, percentileObject, testRowData) => ({
  returnValue: {
    score: value,
    scoreET: Math.round(((value - testRowData.moyenne) / testRowData.ET) * 100) / 100,
    scoreCentile: percentileObject.percentileValue || percentileObject.value,
    inScoreET: true
  },
  percentileObject
});
