import React, { Component } from 'react';

import {
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Announcement } from '@material-ui/icons';

import AnamnesisElement from './AnamnesisElement';
import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class CustomInput extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    return (
      <AnamnesisElement
        {...props}
        editableElement={editableElement}
        readOnlyElement={readOnlyElement}
      />
    );
  }
}

function editableElement(element, handleChange) {
  const { label, placeholder, reportLabelType, reportLabel } = element;
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={label}
          fullWidth
          name="label"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={placeholder}
          fullWidth
          name="placeholder"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            {labels.tabs.form.right.customInput.radioLabel}
          </FormLabel>
          <RadioGroup
            value={reportLabelType}
            name="reportLabelType"
            onChange={handleChange}
          >
            <FormControlLabel
              value="reuse"
              control={<Radio />}
              label={labels.tabs.form.right.customInput.option1}
            />
            <FormControlLabel
              value="set"
              control={<Radio />}
              label={labels.tabs.form.right.customInput.option2}
            />
            <Tooltip title={labels.tabs.form.right.customInput.noteBene}>
              <Announcement />
            </Tooltip>
          </RadioGroup>
        </FormControl>
      </Grid>
      {reportLabelType === 'set' && (
        <Grid item xs={12}>
          <TextField
            value={reportLabel}
            fullWidth
            name="reportLabel"
            onChange={handleChange}
          />
        </Grid>
      )}
    </Grid>
  );
}

function readOnlyElement(element) {
  const { label, placeholder, reportLabelType, reportLabel } = element;
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormLabel component="legend">{label}</FormLabel>
        <TextField
          disabled
          fullWidth
          defaultValue={placeholder}
          style={{ marginBottom: '2rem' }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            {labels.tabs.form.right.customInput.radioLabel}
          </FormLabel>
          <RadioGroup value={reportLabelType}>
            <FormControlLabel
              value="reuse"
              control={<Radio />}
              disabled
              label={labels.tabs.form.right.customInput.option1}
            />
            <FormControlLabel
              value="set"
              control={<Radio />}
              disabled
              label={labels.tabs.form.right.customInput.option2}
            />
            <Tooltip title={labels.tabs.form.right.customInput.noteBene}>
              <Announcement />
            </Tooltip>
          </RadioGroup>
        </FormControl>
      </Grid>
      {reportLabelType === 'set' && (
        <Grid item xs={12}>
          <TextField disabled value={reportLabel} />
        </Grid>
      )}
    </Grid>
  );
}
