import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import labels from '../../constants/labels/configuration/ConfigurationCenterPage.json';
import appVersionSpecific from '../../appVersionSpecific.ortho';
import routes from '../../constants/routes';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 'auto',
    maxHeight: 'fit-content',
    height: 'fit-content',
    marginTop: '5vh'
  },
  media: {
    height: '15vh'
  }
});

const ConfigurationCenterPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const [{ subMenu: configurationMenu }] = appVersionSpecific.mainMenu.filter(({ route }) => route === routes.CONFIGURATION.MAIN);

  return (
    <Grid item md={12} container>
      <Grid item md={12}>
        <Typography variant="h6">{labels.mainHeader}</Typography>
        <Typography variant="subtitle2">{labels.secondHeader}</Typography>
        <Typography variant="body2">{labels.thirdHeader}</Typography>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
      {configurationMenu.map(({ label, route, icon, description, actions, image }) => (
        <Card variant="outlined" className={classes.root} key={label}>
          {image && <CardMedia className={classes.media} image={image} title={label} component={Link} to={route} />}
          <CardActionArea component={Link} to={route}>
            <CardContent>
              <Typography color="textPrimary" gutterBottom variant="h6">
                {icon}
                {` ${label}`}
              </Typography>
              <Typography variant="body2" component="p">
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
          {actions && actions.length > 0 && (
            <CardActions disableSpacing>
              {actions.map(({ title, route: actionRoute, action }) => {
                if (action) {
                  return (
                    <Button key={title} size="small" color="primary" onClick={() => action(history)}>
                      {title}
                    </Button>
                  );
                }
                if (actionRoute) {
                  return (
                    <Button key={actionRoute} size="small" color="primary" component={Link} to={actionRoute}>
                      {title}
                    </Button>
                  );
                }
                return null;
              })}
            </CardActions>
          )}
        </Card>
      ))}
    </Grid>
  );
};

export default ConfigurationCenterPage;
