import React, { Component } from 'react';
import { SvgIcon } from '@material-ui/core';

export default class PaperclipIcon extends Component<Props> {
  render(): React.ReactNode {
    return (
      <SvgIcon viewBox="0 0 612.001 612.001" fontSize="small">
        <path
          d="M565.488,74.616l-2.868-2.833c-63.386-61.375-164.907-60.728-227.507,1.889L45.34,363.532
				C23.501,385.406,0,425.134,0,460.683c0,33.38,13.027,64.802,36.65,88.407c23.641,23.658,55.08,36.686,88.53,36.686h0.018
				c33.45-0.018,64.89-13.045,88.513-36.702l250.151-250.168c17.188-17.188,26.596-41.004,25.756-65.379
				c-0.786-22.364-9.932-43.364-25.756-59.154c-16.646-16.629-38.749-25.792-62.284-25.792c-23.536,0-45.655,9.145-62.249,25.792
				L147.754,365.963c-4.826,4.773-7.851,11.383-7.851,18.691c0,14.479,11.733,26.229,26.229,26.229
				c7.239,0,13.779-2.938,18.517-7.676l0.018,0.018l191.766-191.8c6.854-6.837,16.314-10.194,25.739-10.037
				c9.04,0.14,18.027,3.515,24.619,10.089c6.383,6.382,10.072,14.88,10.404,23.851c0.35,10.002-3.357,19.427-10.422,26.491
				l-250.15,250.167c-13.744,13.744-31.999,21.315-51.425,21.333h-0.018c-19.427,0-37.699-7.589-51.443-21.333
				c-13.709-13.709-21.28-31.929-21.28-51.303c0-16.297,13.744-43.784,29.988-60.063l289.773-289.843
				c42.455-42.49,111.349-42.788,154.188-1.049l2.78,2.798c41.074,42.945,40.497,111.297-1.73,153.542L287.623,505.918
				c-4.809,4.773-7.799,11.349-7.799,18.657c0,14.479,11.733,26.229,26.229,26.229c7.24,0,13.761-2.938,18.518-7.658l0.017,0.018
				l239.975-239.991C627.51,240.188,627.807,137.967,565.488,74.616z"
        />
      </SvgIcon>
    );
  }
}
