// @flow
import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';
import { Divider, Grid, Typography } from '@material-ui/core';

import labels from '../../constants/labels/patient/PatientConsult.json';
import Patient from '../common/data/Patient';

import BackButton from '../common/BackButton';
import CustomTests from '../common/data/CustomTests';
import Anamnesis from './patientConsult/Anamnesis';
import PatientDetail from './patientConsult/PatientDetail';
import TestList from './patientConsult/TestList';
import Checkup from './patientConsult/Checkup';
import CurrentOperations from './patientConsult/CurrentOperations';
import AnamnesisResponses from '../common/data/AnamnesisResponses';

type Props = {
  match: ?{ params: ?{ patientId: ?string } }
};

class PatientConsult extends Component<Props> {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {
      patient: {
        _id: uuidv4(),
        firstName: '',
        lastName: '',
        _rev: '',
        sexe: '',
        birthDay: '',
        curclasse: 0,
        curclassefree: '',
        plainte: '',
        rdvDay: '',
        rdvTime: '',
        rdvDuration: '',
        typeBilan: '',
        typeTests: '',
        contactFirstName: '',
        contactLastName: '',
        contactSexe: '',
        contactLien: '',
        contactTel: '',
        contactMail: '',
        rsaPrivateKey: '',
        rsaPublicKey: '',
        rsaKeySize: '',
        registration: '',
        lookup: '',
        modificationDate: '',
        creationDate: '',
        meta: ''
      },
      bilan: {
        initialAssessmentUUID: null,
        renewalNumber: null,
        tests: []
      },
      patientInit: {
        _id: uuidv4(),
        firstName: '',
        lastName: '',
        _rev: ''
      },
      anamnesis: {
        status: null
      },
      customTests: [],
      patientHasAnamnesisResponseAvailable: false
    };
  }

  async componentDidMount() {
    await this.loadDatas();
  }

  async loadDatas() {
    const {
      match: { params }
    } = this.props;
    if (params.patientId !== 'new') {
      const patient = await Patient.retrievePatient(params.patientId);

      this.setState({ patient });

      const { bilan, anamnese: anamnesis } = patient;
      this.setState({ bilan });

      if (bilan && bilan.initialAssessmentUUID) {
        const patientInit = await Patient.retrievePatient(patient.initialPatientId);
        if (patientInit) {
          this.setState({ patientInit });
        }
      }

      if (anamnesis) {
        this.setState({ anamnesis });
      }

      const customTests = await CustomTests.findAll();
      this.setState({ customTests });

      const anamnesisResponses = await AnamnesisResponses.listResponsesOnDocumentStorageForPatient(params.patientId);
      this.setState({patientHasAnamnesisResponseAvailable : !!anamnesisResponses.length})
    }
  }

  render() {
    const {
      patient,
      bilan = {
        initialAssessmentUUID: null,
        renewalNumber: null,
        tests: []
      },
      patientInit,
      anamnesis,
      customTests,
      patientHasAnamnesisResponseAvailable
    } = this.state;
    console.log({ test: bilan.tests });

    return (
      <Grid container>
        <Grid item xs={12}>
          <BackButton />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">{labels.fileDetail}</Typography>
          <Divider style={{ marginBottom: '2rem', marginTop: '1rem' }} />
        </Grid>
        <PatientDetail patient={patient} />
        <Anamnesis patient={patient} anamnesis={anamnesis} patientHasAnamnesisResponseAvailable={patientHasAnamnesisResponseAvailable}/>
        <TestList patient={patient} bilan={bilan} customTests={customTests} />
        <Checkup patient={patient} patientInit={patientInit} bilan={bilan} />
        <CurrentOperations
          patient={patient}
          loadDatas={() => {
            this.loadDatas();
          }}
        />
      </Grid>
    );
  }
}

export default PatientConsult;
