import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import labels from '../../constants/labels/home/Home.json';

import HomePatientListHeaderStyle from '../../constants/styles/HomePatientListHeaderStyle.json';
import appVersionSpecific from '../../appVersionSpecific.ortho';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  sortField: any,
  // eslint-disable-next-line flowtype/no-weak-types
  sortOrder: any,
  // eslint-disable-next-line flowtype/no-weak-types
  sortBy: (() => Promise<void>) | any
};

const useStyles = makeStyles(HomePatientListHeaderStyle);

function HomePatientListHeader({ sortBy, sortField, sortOrder }: Props) {
  const classes = useStyles();
  return (
    <Grid item md={12} container>
      <Grid item md={1} />
      <Grid item md={2}>
        <Typography variant="h6" gutterBottom className={classes.sortableField} onClick={sortBy('firstName')}>
          {sortField === 'firstName' && sortOrder === 'asc' && <ArrowDropUp />}
          {sortField === 'firstName' && sortOrder === 'desc' && <ArrowDropDown />}
          {labels.titleRow.firstName}
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant="h6" gutterBottom className={classes.sortableField} onClick={sortBy('lastName')}>
          {labels.titleRow.lastName}
          {sortField === 'lastName' && sortOrder === 'asc' && <ArrowDropUp />}
          {sortField === 'lastName' && sortOrder === 'desc' && <ArrowDropDown />}
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant="h6" gutterBottom className={classes.sortableField} onClick={sortBy('rdvDay')}>
          {labels.titleRow.checkupDate}
          {sortField === 'rdvDay' && sortOrder === 'asc' && <ArrowDropUp />}
          {sortField === 'rdvDay' && sortOrder === 'desc' && <ArrowDropDown />}
        </Typography>
      </Grid>
      {appVersionSpecific.homePage.showType && (
        <Grid item md={1}>
          <Typography variant="h6" gutterBottom>
            {labels.titleRow.checkupType}
          </Typography>
        </Grid>
      )}
      {appVersionSpecific.homePage.showAnamnesis && (
        <Grid item md={1}>
          <Typography variant="h6" gutterBottom>
            {labels.titleRow.anamnesis}
          </Typography>
        </Grid>
      )}
      {appVersionSpecific.homePage.showTestList && (
        <Grid item md={1}>
          <Typography variant="h6" gutterBottom>
            {labels.titleRow.tests}
          </Typography>
        </Grid>
      )}
      <Grid item md={1}>
        {appVersionSpecific.homePage.showReportLink && (
          <Typography variant="h6" gutterBottom>
            {labels.titleRow.report}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default HomePatientListHeader;
