import React from 'react';
import { SvgIcon } from '@material-ui/core';

type Props = {
  color?: string
};

const SmileIcon = ({ color }: Props) => (
  <SvgIcon viewBox="0 0 24 24">
    <circle fill={color} cx="15.5" cy="9.5" r="1.5" />
    <circle fill={color} cx="8.5" cy="9.5" r="1.5" />
    <path
      fill={color}
      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-4c-1.48 0-2.75-.81-3.45-2H6.88c.8 2.05 2.79 3.5 5.12 3.5s4.32-1.45 5.12-3.5h-1.67c-.7 1.19-1.97 2-3.45 2z"
    />
  </SvgIcon>
);

SmileIcon.defaultProps = {
  color: 'grey'
};
export default SmileIcon;
