import React, { Fragment, useEffect, useState } from 'react';
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Receipt } from '@material-ui/icons';
import moment from 'moment';

import User from '../common/data/User';
import labels from '../../constants/labels/user/Invoices.json';
import Loader from '../common/Loader';
import { fetchJson, retrieveWebSiteHost } from '../common/OrthoscribeWebSite';

async function fetchInvoices(setIsLoading,
setLoadingError, setInvoices, webSiteHost) {
  try {
    await setIsLoading(true);
    const { val: authToken } = await User.retrieveValueFromDb({ keyValue: 'authToken' });
    const { status, payload } = await fetchJson(`${webSiteHost}/api/user/loadInvoices`, {
      method: 'POST',
      body: JSON.stringify({ authToken }),
      headers: {
        'Content-Type': 'application/json',
        dataType: 'json'
      }
    });

    console.log({ status, payload });
    if (status !== 'ok') {
      await setIsLoading(false);
      await setLoadingError(true);
      return;
    }
    await setInvoices(payload);
    await setIsLoading(false);
  } catch (e) {
    await setIsLoading(false);
    await setLoadingError(true);
  }
}

export default function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [webSiteHost, setWebSiteHost] = useState();

  async function updateWebSiteHost() {
    const value = await retrieveWebSiteHost();
    await setWebSiteHost(value);
  }

  useEffect(() => {
    if (webSiteHost) {
      fetchInvoices(setIsLoading, setLoadingError, setInvoices, webSiteHost);
    }
  }, [webSiteHost]);

  useEffect(() => {
    updateWebSiteHost();
  }, []);

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <Loader />
      </Grid>
    );
  }

  if (loadingError) {
    return (
      <Grid item xs={6}>
        {' '}
        {labels.loadingError}{' '}
      </Grid>
    );
  }

  if (invoices.length > 0) {
    return (
      <Fragment>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ paddingBottom: '1em', paddingTop: '1em' }}>
            {labels.header.invoices}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Paper elevation={1} style={{ width: '100%', marginTop: '1em', padding: '1em' }}>
            <List dense>
              {invoices.map(
                ({
                  id,
                  invoiceId,
                  creationDate,
                  totalPriceVAT: { price: totalVAT },
                  totalPriceNoVAT: { price: totalNoVAT }
                }) => (
                  <ListItem key={id} button component="a" href={`${webSiteHost}/facture/${id}`} target="_blank" rel="noreferrer">
                    <ListItemText
                      primary={`${labels.invoiceNumber} ${invoiceId} ${labels.onDate} ${moment(
                        new Date(creationDate),
                        'YYYY-MM-DD'
                      ).format('DD/MM/YYYY')}`}
                      secondary={`${labels.vatAmount} ${totalVAT} €, ${labels.noVatAmount} ${totalNoVAT} €`}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title={labels.viewInvoice}>
                        <IconButton component="a" href={`${webSiteHost}/facture/${id}`} target="_blank" rel="noreferrer">
                          <Receipt />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              )}
            </List>
          </Paper>
        </Grid>
      </Fragment>
    );
  }
  return null;
}
