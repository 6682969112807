import React, { useState } from 'react';
import { Grid, IconButton, MenuItem, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import TextFieldFormControl from '../../TextFieldFormControl';
import labels from '../../../constants/labels/patient/comments/AMOChoice.json';
import AMOCodes from '../../../constants/labels/patient/AmoCodes';

type Props = {
  value: string,
  controlName: string,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler: Function
};

const AMOChoice = ({ value, controlName, changeHandler }: Props) => {
  const [amoNumber, setAmoNumber] = useState(30);
  const [amoIndex, setAmoIndex] = useState(-1);

  const addAmo = async () => {
    changeHandler({
      target: {
        name: controlName,
        value: value + AMOCodes.getText(AMOCodes.codes[amoIndex], amoNumber)
      }
    });
  };

  return (
    <Grid item xs={12} container>
      <TextFieldFormControl
        itemMdValue={2}
        label={labels.number}
        placeholder={labels.number}
        fullWidth
        inputType="number"
        value={amoNumber}
        id="amoNumber"
        controlName="amoNumber"
        onChangeHandler={event => setAmoNumber(event.target.value)}
      />
      <TextFieldFormControl
        select
        itemMdValue={6}
        fullWidth
        label={labels.amoCode}
        placeholder={labels.selectCode}
        value={amoIndex}
        onChangeHandler={event => setAmoIndex(event.target.value)}
        id="amoIndex"
        controlName="amoIndex"
      >
        {AMOCodes.codes
          .sort((a, b) => Number(a.code.replace(',', '.')) - Number(b.code.replace(',', '.')))
          .map(({ shortLabel: amoShortLabel, code }, index) => (
            <MenuItem key={code + amoShortLabel} value={index}>
              {`${code} - ${amoShortLabel}`}
            </MenuItem>
          ))}
      </TextFieldFormControl>
      <Grid item xs={1} style={{ margin: 'auto', marginLeft: 0 }}>
        {amoIndex !== -1 && amoNumber && (
          <Tooltip title={labels.addButton}>
            <IconButton onClick={addAmo}>
              <Add />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default AMOChoice;
