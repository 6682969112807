import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import labels from '../../../constants/labels/patient/test/TestListingMoreActionLine.json';
import routes from '../../../constants/routes';
import OnLineOffLineSwitcher from '../../common/OnLineOffLineSwitcher';

const TestListingMoreActionLine = ({ patientId }: { patientId: string }) => (
  <Grid xs={12} item container>
    <Grid item xs={3}>
      <OnLineOffLineSwitcher
        onLine={
          <Link
            to={routes.PATIENT.getForPath({
              path: routes.PATIENT.ANAMNESIS.default,
              patientId
            })}
          >
            {labels.sendAnamnesisForm}
          </Link>
        }
        offLine={null}
      />
    </Grid>
    <Grid item xs={3}>
      <Link
        to={routes.PATIENT.getForPath({
          path: routes.PATIENT.TESTS.SELECT,
          patientId
        })}
      >
        {labels.editTestList}
      </Link>
    </Grid>
    <Grid item xs={3}>
      <Link to={routes.PATIENT.getForPath({ path: routes.PATIENT.EDIT, patientId })}>{labels.editFile}</Link>
    </Grid>
    <Grid item xs={3}>
      <OnLineOffLineSwitcher
        onLine={
          <Link to={routes.PATIENT.getForPath({ path: routes.PATIENT.REPORT.GENERATE, patientId })}>
            {labels.createReport}
          </Link>
        }
        offLine={null}
      />
    </Grid>
  </Grid>
);

export default TestListingMoreActionLine;
