import React, { Component } from 'react';
import { Divider, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import { CloudUpload, Save } from '@material-ui/icons';
import _ from 'lodash';

import labels from '../../../constants/labels/patient/report/ReportOptions.json';
import SaveCancelRow from '../../common/SaveCancelRow';
import routes from '../../../constants/routes';
import User from '../../common/data/User';
import log from '../../Logger';
import Patient from '../../common/data/Patient';
import { getHeaderAgeAndClassText } from '../../common/Header';
import PatientContentUtil from '../PatientContentUtil';
import ReportOptionsForm from '../../common/reportOptions/ReportOptionsForm';
import defaultReportGenerationOptions from "../../../constants/user/defaultReportGenerationOptions";

const styles = theme => ({
  row: {
    marginBottom: `${theme.spacing(3)}px`
  },
  bold: {
    fontWeight: 'bold'
  },
  bgGreen: {
    backgroundColor: '#559944',
    '&:hover': {
      backgroundColor: '#2b9931'
    }
  },
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: `${theme.spacing(1)}px 0`
  }
});

type Props = {
  match: { params: { patientId: string } },
  // eslint-disable-next-line flowtype/no-weak-types
  classes: any,
  // eslint-disable-next-line flowtype/no-weak-types
  history: any
};

class ReportOptions extends Component<Props> {
  props: Props;

  state = {
    content: {
      bilan: {},
      patient: {},
      options: defaultReportGenerationOptions
    },
    reloadChart: true
  };

  async componentDidMount(): void {
    const {
      match: {
        params: { patientId }
      }
    } = this.props;
    try {
      const content = await PatientContentUtil.getContent(patientId);
      this.setState({ content });
    } catch (err) {
      log.error('Error retrieving datas', err);
    }
  }

  handleSubmit = asDefault => async () => {
    const {
      match: {
        params: { patientId }
      },
      history
    } = this.props;
    const {
      content: { bilan, options, patient }
    } = this.state;

    await Patient.updatePatient({ ...patient, bilan: { ...bilan, reportOptions: options } });
    if (asDefault) {
      await User.saveReportGenerationOptions(options);
    }
    history.push(routes.PATIENT.REPORT.getForPath({ path: routes.PATIENT.REPORT.GENERATE, patientId }));
  };

  change = async event => {
    const { content, reloadChart } = this.state;
    content.options = _.set(content.options, event.target.name, event.target.value);
    this.setState({ content, reloadChart: !reloadChart });
  };

  switchChange = name => event => {
    this.change({ target: { name, value: event.target.checked } });
  };

  resetHorizBar = () => {
    const { content, reloadChart } = this.state;
    const defaultValues = {
      horizBarSize: 10,
      displayBars: true,
      tableChartFormat: 'simple',
      tableChartOptions: { renewalMark: 'diamond' }
    };
    content.options = _.merge(content.options, defaultValues);
    this.setState({ content, reloadChart: !reloadChart });
  };

  render(): React.ReactNode {
    const {
      match: {
        params: { patientId }
      },
      classes
    } = this.props;
    const { content, reloadChart } = this.state;
    const { patient, bilan, options } = content;
    const ageAndClassText = getHeaderAgeAndClassText(patient.curclasse, patient.birthDay);

    console.log({ options });

    return (
      <div>
        <Typography variant="h3">{labels.title}</Typography>
        <Typography variant="h4">
          {labels.subTitle.part0}{' '}
          {bilan.renewalNumber ? labels.subTitle.renewal + bilan.renewalNumber : labels.subTitle.initial}{' '}
          {labels.subTitle.part1}{' '}
          <span className={classes.bold}>
            {patient.firstName} {patient.lastName}
          </span>{' '}
          ({ageAndClassText})
        </Typography>
        <Divider />
        <SaveCancelRow
          cancelRoute={routes.PATIENT.REPORT.getForPath({ path: routes.PATIENT.REPORT.GENERATE, patientId })}
          saveAction={this.handleSubmit(false)}
          saveIcon={<Save />}
          saveTooltip={labels.save.tooltip}
          moreActionsMenu={[
            {
              key: 'entry0',
              element: (
                <Tooltip title={labels.saveAsDefault.tooltip}>
                  <IconButton onClick={this.handleSubmit(true)} variant="contained" color="primary">
                    <CloudUpload />
                  </IconButton>
                </Tooltip>
              )
            }
          ]}
        />
        <Divider />
        <ReportOptionsForm
          options={options}
          reloadChart={reloadChart}
          content={content}
          change={this.change}
          switchChange={this.switchChange}
          resetHorizBar={this.resetHorizBar}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ReportOptions);
