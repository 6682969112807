import React, { Fragment } from 'react';
import { Button, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Delete, NoteAdd, Visibility } from '@material-ui/icons';
import CheckupCommentEditor from './CheckupCommentEditor';
import labels from '../../../constants/labels/patient/comments/BehaviourComments.json';
import routes from '../../../constants/routes';
import Patient from '../../common/data/Patient';
import { getKeyFromTestCodeAndId } from '../../common/data/MemoUtil';

type Props = {
  comments:
    | {
        behaviours: Array | undefined
      }
    | undefined,
  cotation: {
    // eslint-disable-next-line flowtype/no-weak-types
    results: any
  },
  // eslint-disable-next-line flowtype/no-weak-types
  testLabelByTestCode: any,
  patientId: string,
  bilanId: string,
  updateComments: () => void,
  updateCotation: () => void,
  updatePatient: () => void,
  patient: {
    lastName: string | undefined,
    firstName: string | undefined
  }
};

const BehaviourComments = ({
  comments,
  cotation,
  testLabelByTestCode,
  patientId,
  bilanId,
  updateComments,
  updateCotation,
  updatePatient,
  patient
}: Props) => {
  const handleTestCommentChange = async event => {
    const { results, ...spread } = cotation;
    if (!results[event.target.name]) {
      results[event.target.name] = {};
    }
    results[event.target.name].behaviourComment = event.target.value;
    const updatedPatient = await Patient.updatePatient({ ...patient, cotation: { results, ...spread } });
    updateCotation(updatedPatient.cotation);
    updatePatient(updatedPatient);
  };

  const handleFreeTextCommentChange = index => async event => {
    const { behaviours, ...spread } = comments;
    behaviours[index].text = event.target.value;
    const updatedPatient = await Patient.updatePatient({ ...patient, comments: { behaviours, ...spread } });
    updateComments(updatedPatient.comments);
    updatePatient(updatedPatient);
  };

  const moveComment = async (index, hoverIndex) => {
    const { behaviours, ...spread } = comments;
    const movedElement = behaviours.splice(index, 1);
    behaviours.splice(hoverIndex, 0, movedElement[0]);
    const updatedPatient = await Patient.updatePatient({ ...patient, comments: { behaviours, ...spread } });
    updateComments(updatedPatient.comments);
    updatePatient(updatedPatient);
  };

  const removeFreeText = index => async () => {
    const { behaviours, ...spread } = comments;
    behaviours.splice(index, 1);
    const updatedPatient = await Patient.updatePatient({ ...patient, comments: { behaviours, ...spread } });
    updateComments(updatedPatient.comments);
    updatePatient(updatedPatient);
  };

  const addFreeText = async () => {
    const { behaviours, ...spread } = comments;
    behaviours.push({ isTestReference: false, text: '' });
    const updatedPatient = await Patient.updatePatient({ ...patient, comments: { behaviours, ...spread } });
    updateComments(updatedPatient.comments);
    updatePatient(updatedPatient);
  };

  const { lastName, firstName } = patient;
  return (
    <Fragment>
      <Grid xs={11} item>
        <Typography variant="h6">{labels.title}</Typography>
      </Grid>
      <Grid xs={1} item>
        <Tooltip title={labels.addText}>
          <IconButton onClick={addFreeText}>
            <NoteAdd />
          </IconButton>
        </Tooltip>
      </Grid>
      {(comments.behaviours || []).map(({ isTestReference, text }, index) => (
        <CheckupCommentEditor
          key={text}
          value={isTestReference ? (cotation.results[text] || {}).behaviourComment || '' : text || ''}
          onChange={isTestReference ? handleTestCommentChange : handleFreeTextCommentChange(index)}
          controlName={text}
          index={index}
          moveComment={moveComment}
          isDraggable
          memo={getKeyFromTestCodeAndId('GLOBAL', 'behaviour')}
          memoByCategory
          lastName={lastName}
          firstName={firstName}
          title={`${labels.title} ${testLabelByTestCode[text]}`}
          extraButton={
            isTestReference ? (
              <Tooltip title={`${labels.displayTestResults} ${testLabelByTestCode[text]}`}>
                <Button
                  component={Link}
                  to={routes.PATIENT.TESTS.getForPath({
                    path: routes.PATIENT.TESTS.FILLING,
                    patientId,
                    testCode: text,
                    bilanId
                  })}
                  startIcon={<Visibility />}
                >
                  {testLabelByTestCode[text]}
                </Button>
              </Tooltip>
            ) : (
              <IconButton onClick={removeFreeText(index)}>
                <Delete />
              </IconButton>
            )
          }
        />
      ))}
    </Fragment>
  );
};

export default BehaviourComments;
