import appVersionSpecific from '../appVersionSpecific.ortho';

const server = {
  main: {
    url: appVersionSpecific.backend.url
  },
  secured: {
    url: appVersionSpecific.backend.secure,
    couchDbUrl: appVersionSpecific.backend.securedDb
  },
  documentStorage: {
    documentModel: "https://documentstorage.orthoscribe.fr/documentmodel",
    anamnesis: "https://documentstorage.orthoscribe.fr/anamnesis"
  }
};

export default server;
