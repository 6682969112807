import uuidv4 from 'uuid/v4';
import User from './User';

export default class Bilan {
  static TYPE = 'bilans';

  static async getNewEmptyOne({
    bilanId = uuidv4(),
    patientId,
    anamneseId,
    renewalNumber,
    initialAssessmentUUID,
    initialAssessmentTests
  }) {
    const { val: defaultUserOptions } = await User.getReportGenerationOptions();
    return {
      _id: bilanId,
      uuid: `{${bilanId}}`,
      creationDate: new Date(),
      tests: initialAssessmentTests || [],
      dbtype: Bilan.TYPE,
      modificationDate: new Date(),
      archived: 0,
      patientId,
      anamneseId,
      renewalNumber,
      initialAssessmentUUID,
      initialAssessmentTests,
      displayInfo: { rowColor: 'white' },
      reportOptions: defaultUserOptions
    };
  }
}
