import React, {Fragment} from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import labels from '../../../constants/labels/patient/test/TestFillingHeader.json';
import { getHeaders } from './TestFillingUtil';
import SearchField from '../../common/SearchField';
import TestFillingInitialHeaderColumns from "./testFilling/TestFillingInitialHeaderColumns";
import TestFillingHeaderColumns from "./testFilling/TestFillingHeaderColumns";
import TypoInGrid from "./testFilling/TypoInGrid";

const useStyles = makeStyles({
  sortableField: {
    cursor: 'default',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  padding20: {
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  marginTopBottomAuto: {
    marginTop: 'auto',
    marginBottom: 'auto',
    '& > .MuiFormControl-marginNormal': {
      marginTop: '4px',
      marginBottom: '4px'
    }
  }
});

type Props = {
  currentTest?: { canNotBeSorted?: boolean },
  // eslint-disable-next-line flowtype/no-weak-types
  onChangeSortOrder: () => any,
  // eslint-disable-next-line flowtype/no-weak-types
  onChangeTitleFilter: string => void,
  titleFilter: string,
  sortField: string,
  sortOrder: string,
  columns: {
    SCORE?: boolean,
    TEMPS?: boolean,
    NS19?: boolean,
    TEMPS_ET?: boolean,
    SCORE_ET?: boolean,
    ERR_SCORE_ET?: boolean,
    ERR_TEMPS_ET?: boolean,
    SCORE_CENTILE?: boolean,
    SCORE_QUART?: boolean,
    SCORE_QUINT?: boolean,
    SCORE_SEPTILE?: boolean,
    SCORE_OCTILE?: boolean,
    ERR_CENTILE?: boolean,
    TEMPS_CENTILE?: boolean,
    ERR_TEMPSCENTILE?: boolean,
    SCORE_AD?: boolean
  },
  displayRawScore: boolean,
  columnWidth: number,
  disableCentileColumn: boolean,
  displayCalculatedPercentile: boolean,
  testResult?: { disabled: boolean | undefined },
  isRenewal: boolean,
  renewalNumber?: ?number
};

const TestFillingHeader = ({
  currentTest,
  onChangeSortOrder,
  onChangeTitleFilter,
  titleFilter,
  sortField,
  sortOrder,
  columns,
  displayRawScore,
  columnWidth,
  disableCentileColumn,
  displayCalculatedPercentile,
  isRenewal,
  renewalNumber
}: Props) => {
  const style = useStyles();

  const headers = getHeaders(columns, displayRawScore, disableCentileColumn);
  const canBeSorted = !currentTest.canNotBeSorted;
  // console.log({ columns, displayRawScore, disableCentileColumn });
  // console.table(headers);
  return (
    <Grid item xs={12} container>
      <Grid item md={12} lg={6} container>
        <Grid item md={2} className={style.marginTopBottomAuto}>
          <Tooltip placement="top-start" title={canBeSorted && labels.moreOptions.sortExaminationsByName}>
            <Typography
              variant="h6"
              className={classNames(style.bold, {[style.sortableField]: canBeSorted})}
              onClick={canBeSorted ? onChangeSortOrder : undefined}
            >
              {canBeSorted && sortField === 'testLabel' && sortOrder === 'asc' && <ArrowDropUp/>}
              {canBeSorted && sortField === 'testLabel' && sortOrder === 'desc' && <ArrowDropDown/>}
              {labels.testLabel}
            </Typography>
          </Tooltip>
        </Grid>
        <Tooltip placement="top-start" title={labels.moreOptions.filterExaminationsByName}>
          <SearchField
            q={titleFilter}
            searchChange={event => onChangeTitleFilter(event.target.value)}
            className={style.marginTopBottomAuto}
          />
        </Tooltip>
      </Grid>
      <Grid item md={12} lg={6} container>
        {
          isRenewal ?
          <Fragment>
            <TestFillingInitialHeaderColumns
              headers={headers}
              columnWidth={columnWidth}
              style={style}
            />
            <Grid item md={12} lg={6} container className={style.extraDenseInput}>
              <TypoInGrid condition size={12} style={style} label={`Bilan R${renewalNumber}`} />
              <TestFillingHeaderColumns
                displayCalculatedPercentile={displayCalculatedPercentile}
                headers={headers}
                columnWidth={columnWidth}
                style={style}
              />
            </Grid>
          </Fragment>
          :
          <TestFillingHeaderColumns
            displayCalculatedPercentile={displayCalculatedPercentile}
            headers={headers}
            columnWidth={columnWidth}
            style={style}
          />
        }
      </Grid>
    </Grid>
  );
};

TestFillingHeader.defaultProps = {
  currentTest: { canNotBeSorted: false },
  testResult: {},
  renewalNumber: -1
};

export default TestFillingHeader;
