import React, { Component } from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';

import icons from '../../../common/anamnesis/icons/AnamnesisIcons';
import AnamnesisElement from './AnamnesisElement';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  // eslint-disable-next-line flowtype/no-weak-types
  child: any,
  isDragging: boolean,
  rowNumber: number,
  columnNumber: number
};

export default class IconPrepend extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    return <AnamnesisElement {...props} editableElement={editableElement} readOnlyElement={readOnlyElement} />;
  }
}

function editableElement(element, handleChange) {
  const { label, placeholder, icon } = element;
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <TextField InputLabelProps={{ shrink: true }} value={label} fullWidth name="label" onChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={placeholder}
          fullWidth
          name="placeholder"
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">{icons[icon]}</InputAdornment>
          }}
        />
      </Grid>
    </Grid>
  );
}

function readOnlyElement(element) {
  const { label, placeholder, icon } = element;
  return (
    <TextField
      disabled
      label={label}
      defaultValue={placeholder}
      InputProps={{
        startAdornment: <InputAdornment position="start">{icons[icon]}</InputAdornment>
      }}
      style={{ marginBottom: '2rem' }}
    />
  );
}
