// @flow
import type {ReportGraphicOptions} from '../../report/ReportGraphicsDefaultOptions';
import defaultOptions from '../../report/ReportGraphicsDefaultOptions';

const MAXVAL = 5;
const MINVAL = -5;

export type Zone = {
  topY: number,
  bottomY: number,
  topValue: number,
  bottomValue: number,
  name: string,
  scoreClass: number
};

const Examath58NormZones: ReportGraphicOptions => Array<Zone> = (options = defaultOptions) => [
  { topValue: MAXVAL, bottomValue: 2.56697, topY: 0, bottomY: 0, scoreClass: 1, name: '' },
  {
    topValue: 2.56697,
    bottomValue: 1.51,
    topY: 0,
    bottomY: 0.25,
    scoreClass: 1,
    name: options.legendOptions.aboveAverage
  },
  { topValue: 1.51, bottomValue: 0, topY: 0.25, bottomY: 0.5, scoreClass: 2, name: options.legendOptions.averageHigh },
  { topValue: 0, bottomValue: -1.5, topY: 0.5, bottomY: 0.75, scoreClass: 3, name: options.legendOptions.averageLow },
  {
    topValue: -1.5,
    bottomValue: -2,
    topY: 0.75,
    bottomY: 0.87,
    scoreClass: 4,
    name: options.legendOptions.belowAverage
  },
  {
    topValue: -2,
    bottomValue: -2.56697,
    topY: 0.87,
    bottomY: 0.94,
    scoreClass: 5,
    name: options.legendOptions.pathological
  },
  {
    topValue: -2.56697,
    bottomValue: MINVAL,
    topY: 0.94,
    bottomY: 1,
    scoreClass: 6,
    name: options.legendOptions.srslypathological
  }
];

const LinearNormZones: ReportGraphicOptions => Array<Zone> = (options = defaultOptions) => [
  { topValue: MAXVAL, bottomValue: 1.5, topY: 0, bottomY: 0, scoreClass: 1, name: options.legendOptions.aboveAverage },
  { topValue: 1.5, bottomValue: 0, topY: 0.2, bottomY: 0.4, scoreClass: 2, name: options.legendOptions.averageHigh },
  { topValue: 0, bottomValue: -1.5, topY: 0.4, bottomY: 0.6, scoreClass: 3, name: options.legendOptions.averageLow },
  { topValue: -1.5, bottomValue: -2, topY: 0.6, bottomY: 0.8, scoreClass: 4, name: options.legendOptions.belowAverage },
  { topValue: -2, bottomValue: MINVAL, topY: 0.8, bottomY: 1, scoreClass: 5, name: options.legendOptions.pathological }
];

const ExalangNormZones: ReportGraphicOptions => Array<Zone> = (options = defaultOptions) => [
  { topValue: MAXVAL, bottomValue: 2.6, topY: 0, bottomY: 0, scoreClass: 1, name: '' },
  { topValue: 2.6, bottomValue: 1.51, topY: 0, bottomY: 0.25, scoreClass: 1, name: options.legendOptions.aboveAverage },
  {
    topValue: 1.51,
    bottomValue: 0.02,
    topY: 0.25,
    bottomY: 0.5,
    scoreClass: 2,
    name: options.legendOptions.averageHigh
  },
  {
    topValue: 0.02,
    bottomValue: -1.48,
    topY: 0.5,
    bottomY: 0.75,
    scoreClass: 3,
    name: options.legendOptions.averageLow
  },
  {
    topValue: -1.48,
    bottomValue: -1.98,
    topY: 0.75,
    bottomY: 0.87,
    scoreClass: 4,
    name: options.legendOptions.belowAverage
  },
  {
    topValue: -1.98,
    bottomValue: -2.48,
    topY: 0.87,
    bottomY: 0.94,
    scoreClass: 5,
    name: options.legendOptions.pathological
  },
  {
    topValue: -2.48,
    bottomValue: MINVAL,
    topY: 0.94,
    bottomY: 1,
    scoreClass: 6,
    name: options.legendOptions.srslypathological
  }
];

const NearlogNormZones: ReportGraphicOptions => Array<Zone> = (options = defaultOptions) => [
  {
    topValue: MAXVAL,
    bottomValue: 1.5,
    topY: 0,
    bottomY: 0.25,
    scoreClass: 1,
    name: options.legendOptions.aboveAverage
  },
  { topValue: 1.5, bottomValue: 0, topY: 0.25, bottomY: 0.5, scoreClass: 2, name: options.legendOptions.averageHigh },
  { topValue: 0, bottomValue: -1.5, topY: 0.5, bottomY: 0.75, scoreClass: 3, name: options.legendOptions.averageLow },
  {
    topValue: -1.5,
    bottomValue: -2,
    topY: 0.75,
    bottomY: 0.9,
    scoreClass: 4,
    name: options.legendOptions.belowAverage
  },
  { topValue: -2, bottomValue: MINVAL, topY: 0.9, bottomY: 1, scoreClass: 5, name: options.legendOptions.pathological }
];

const getZones: ReportGraphicOptions => Array<Zone> = options =>
  ({
    exalang: ExalangNormZones(options),
    linear: LinearNormZones(options),
    nearlog: NearlogNormZones(options),
    examath58: Examath58NormZones(options)
  }[options.graphVScale] || NearlogNormZones(options));

const getZoneColor: (Zone, ReportGraphicOptions) => string = (zone, options) => {
  if (!zone) {
    return '';
  }
  if (!options.legendOptions.averageDetails && zone.scoreClass === 3) {
    return options.gfxcolor2;
  }
  return options[`gfxcolor${zone.scoreClass}`];
};

function getScoreLimited(score) {
  if (score > MAXVAL) {
    return MAXVAL-0.01;
  }
  if (score < MINVAL) {
    return MINVAL+0.01;
  }
  return score;
}

const findZone: (number, Array<Zone>) => Zone = (score, zones) => {
  const scoreLimited = getScoreLimited(score);
  return zones.find(({bottomValue, topValue}) => scoreLimited > bottomValue && scoreLimited <= topValue);
};

export { getZones, getZoneColor, findZone };
