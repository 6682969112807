
import appVersionSpecific from '../../appVersionSpecific.ortho';
import server from '../../constants/server';
import { fetchJson } from './OrthoscribeWebSite';
import MyStore from "../../actions/MyStore";

const loadTeamNews = async () => {
  const webSiteHost = MyStore.get(appVersionSpecific.electronStoreWebSiteHost) || server.main.url;
  const { payload, status } = await fetchJson(`${webSiteHost}/news`);
  if (status !== 'ok') {
    return;
  }
  return { payload, status };
};

const getLastestNewsDate = newsPayload => {
  if (!newsPayload || !newsPayload.length > 0) {
    return;
  }
  const { postingDate } = newsPayload.sort(({ postingDate: a }, { postingDate: b }) => a - b)[newsPayload.length - 1];
  return postingDate;
};

export { loadTeamNews, getLastestNewsDate };
