import React, { Fragment, useEffect, useState, ChangeEvent } from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import IconPrepend from './IconPrepend';
import MedecinTraitants from '../../../common/data/MedecinTraitants';
import labels from '../../../../constants/labels/patient/anamnesis/elements/MedecinTraitant.json';

const useStyles = makeStyles({
  marginAuto: {
    margin: 'auto'
  }
});

type Props = {
  deleteDoctor: ChangeEvent => void,
  myValue: string | undefined
};

const MedecinTraitant = ({ deleteDoctor, myValue, ...props }: Props) => {
  const [doctors, setDoctors] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getDoctors();
  }, []);

  const getDoctors = async () => {
    const val = await MedecinTraitants.getList();
    setDoctors(val || []);
  };

  return (
    <Fragment>
      <IconPrepend
        myValue={myValue}
        span={11}
        {...props}
        datalist={doctors.map(({ medecintraitant }) => (
          <option key={medecintraitant} value={medecintraitant} />
        ))}
      />
      {myValue && (
        <Grid item md={1} className={classes.marginAuto}>
          <Tooltip title={labels.deleteDoctor}>
            <IconButton onClick={() => deleteDoctor({ target: { value: myValue } })}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Fragment>
  );
};

export default MedecinTraitant;
