import getFilteredPercentileTableByMinPercentile from "./GetFilteredPercentileTableByMinPercentile";

const moreThan95ResultAndMatchFunction = {matchFunction: (value, pctTable) => value < pctTable.at(-1).v, result :{ percentileValue: 100, label: '≤100', sign: '≤' }};

const p5ResultAndMatchFunction = {matchFunction: (value, pctTable) => value >= pctTable[0].v, result : {percentileValue: 5, label: '≤5', sign: "≤"}};

const percentileMatchFunction = (value, pctTable, index) => value >= pctTable[index].v

const calculateEtalDescending = ({ value, pctTable }) => {
    if (pctTable.length !== 7) {
        return {};
    }
    const filteredPercentileTableByMinPercentile = getFilteredPercentileTableByMinPercentile(pctTable);

    filteredPercentileTableByMinPercentile.sort(({p:a}, {p:b}) => a-b);

    // test for percentile > 95
    if (pctTable.find(({p}) => p === 95) && moreThan95ResultAndMatchFunction.matchFunction(value, pctTable)) {
        return moreThan95ResultAndMatchFunction.result;
    }

    // test for percentile <= 5
    if (filteredPercentileTableByMinPercentile.find(({p}) => p === 5) && p5ResultAndMatchFunction.matchFunction(value, pctTable)) {
        return p5ResultAndMatchFunction.result;
    }

    const transformedFilteredPctTable = filteredPercentileTableByMinPercentile.map(({p}, index) => ({matchFunction: percentileMatchFunction, result: {percentileValue: filteredPercentileTableByMinPercentile[index].p, label: `≤${filteredPercentileTableByMinPercentile[index].p}`, sign: '≤'}, index}));

    const found = transformedFilteredPctTable.find(({ matchFunction, index }) => matchFunction(value, filteredPercentileTableByMinPercentile, index));
    return found ? found.result : transformedFilteredPctTable[transformedFilteredPctTable.length -1].result;

};

export default calculateEtalDescending;
