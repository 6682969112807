import User from './User';

const keyValue = 'anamnese.doctors';

export default class MedecinTraitants {
  static async getList() {
    const { val } = await User.retrieveValueFromDb({ keyValue, defaultValue: [] });
    console.log({ val });
    if (typeof val === 'string') {
      return JSON.parse(val);
    }
    return val;
  }

  static async getValuesForName(name) {
    const val = await this.getList();
    return ((val || []).filter(({ medecintraitant }) => medecintraitant === name) || [{}]).shift();
  }

  static async updateMedecin({ name, field, value }) {
    const val = await this.getList();
    const newUserValue = val.map(({ medecintraitant, ...props }) => {
      if (medecintraitant === name) {
        return {
          medecintraitant,
          ...props,
          [field]: value
        };
      }
      return { medecintraitant, ...props };
    });
    await User.save({ keyValue, value: newUserValue });
  }

  static async createValueForName(name) {
    const val = await this.getList();
    await User.save({ keyValue, value: [...val, { medecintraitant: name }] });
  }

  static async deleteMedecin(name) {
    const val = await this.getList();
    const value = val.filter(({ medecintraitant }) => medecintraitant !== name);
    await User.save({ keyValue, value });
  }
}
