import moment from 'moment';

import PREDILEM from '../../../../constants/tests/PREDILEM.json';
import MathUtil from '../../../common/Math';
import {getLocation} from './exalangCommonImport';
import {jsonDownload} from "../../import/utils/HappyneuronFileDownloader";

const parseFile = async ({filePath, userId, ticket, programId}) => {
  const predilem = await jsonDownload({userId, ticket, programId});
  console.log("predilem", {predilem, filePath})
  const classe = getPredilemClass(predilem.user);

  return predilem.workouts.map(workout => ({
    firstname: predilem.user.firstName,
    lastname: predilem.user.lastName,
    birthDate: predilem.user.birthDate,
    passationDate: workout.startDate,
    passationNumber: workout.id,
    data: { workout },
    hnWorkoutId: workout.id,
    classe,
    text: `${predilem.user.firstName} ${predilem.user.lastName} (${workout.startDate})`
  }));
};

const getPredilemClass = user => {
  const birthDate = moment(user.birthDate, 'DD/MM/YYYY');
  const age = MathUtil.calculateAge(birthDate.toDate());
  if (!age) return '';
  const y = age;
  let part1 = '';
  if (y >= 20 && y <= 49) {
    part1 = '20 à 49 ans';
  } else if (y >= 50 && y < 60) {
    part1 = '50 à 59 ans';
  } else if (y >= 60 && y < 70) {
    part1 = '60 à 69 ans';
  } else if (y >= 70 && y < 80) {
    part1 = '70 à 79 ans';
  } else if (y >= 80 && y < 90) {
    part1 = '80 à 90 ans';
  }
  if (!part1) return '';
  const part2 = user.class === '15' ? '> Bac+3' : '> Bac';
  return `${part1}, ${part2}`;
};

// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
const executeImport = ({ data, classe }) => {
  if (data && data.workout) {
    const { results } = data.workout;
    const values = PREDILEM.children
      .map(({ id }) => parseInt(id.substring('PREDILEM_'.length), 10))
      .map(gameId => {
        const result = (results.filter(({ gameId: resultGameId }) => resultGameId === gameId) || []).shift();
        if (!(result && (result.score === 0 || result.score))) return {};
        const data3Index = (result.details || '').indexOf('_data3:');
        const comment =
          data3Index >= 0 && result.details.substr(data3Index + 7) ? result.details.substr(data3Index + 7) : undefined;
        return { gameId, score: result.score, comment };
      })
      .filter(Boolean)
      .reduce((acc, { gameId, score, comment }) => {
        acc[`PREDILEM_${gameId}`] = { score, comment };
        return acc;
      }, {});
    return { count: Object.entries(values).length, values, calculOption: classe, calculOptionType: 'classe' };
  }
};

const PredilemImport = () => ({ getLocation, parseFile, executeImport });
export default PredilemImport;
