import React, { useEffect, useState } from 'react';
import { Divider, Typography } from '@material-ui/core';
import labels from '../../../constants/labels/patient/anamnesis/AnamnesisMailImportFileFromServer.json';
import AnamnesisResponses from '../../common/data/AnamnesisResponses';
import ResponseList from './AnamnesisResponseList';
import AnamnesisFileContentImport from './AnamnesisFileContentImport';

type Props = {
  match: ?{ params: ?{ patientId: ?string } }
};

const AnamnesisMailImportFileFromServer = ({
                                             match: {
                                               params: { patientId }
                                             }
                                           }: Props) => {
  // On liste les fichiers et sur l'import, on supprime tout

  const [fileList, setFileList] = useState([]);
  const [fileContent, setFileContent] = useState();
  const [filename, setFilename] = useState("");

  useEffect(() => {
    const fetchPatientAnamnesisFiles = async () =>  {
      const responsesOnDocumentStorageForPatient = await AnamnesisResponses.listResponsesOnDocumentStorageForPatient(patientId);
      setFileList(responsesOnDocumentStorageForPatient);
    };

    fetchPatientAnamnesisFiles().then();
  }, [patientId]);
  useEffect(() => {
    if (fileList.length === 1) {
      setFilename(fileList[0].fullName);
    }
  }, [fileList]);
  useEffect(() => {
    const parseFile = async () => {
      const content = await AnamnesisResponses.downloadFileFromServer(filename)
      setFileContent(content);
    };

    filename && parseFile().then();
  }, [filename]);

  return (
    <div>
      <Typography variant="h6">{labels.mainTitle}</Typography>
      <Typography variant="subtitle1">{labels.subTitle}</Typography>
      <Divider />
      {!filename && <ResponseList fileList={fileList} setFilename={setFilename} />}
      {!!filename && <AnamnesisFileContentImport fileContent={fileContent} filename={filename} />}
    </div>
);

};

export default AnamnesisMailImportFileFromServer;
