import React from 'react';
import { Route, Switch } from 'react-router';
import routes from './constants/routes';
import HomePage from './containers/HomePage';
import PatientPage from './containers/patient/PatientPage';
import ConfigurationPage from './containers/configuration/ConfigurationPage';
import UserProfilePage from './containers/UserProfilePage';
import ImportPage from './containers/ImportPage';

const Routes = () => (
  <Switch>
    <Route exact path={routes.HOME} component={HomePage} />
    <Route path={routes.PATIENT.MAIN} component={PatientPage} />
    <Route path={routes.CONFIGURATION.MAIN} component={ConfigurationPage} />
    <Route path={routes.USER.MAIN} component={UserProfilePage} />
    <Route path={routes.IMPORT.MAIN} component={ImportPage} />
  </Switch>
);

export default Routes;