import uuidv4 from 'uuid/v4';
import localDatabases from '../../../database/database';
import log from '../../Logger';
import { prepareTestForDisplay } from '../../../constants/tests/PrepareTestForDisplay';

export default class CustomTests {
  static TYPE = 'customtests';

  static async retrieveCustomTestsById(customTestId) {
    try {
      const id = CustomTests.setId(customTestId);
      const customTest = await localDatabases.getInstance().v1databaseRepository.get(id);
      customTest._id = CustomTests.getId(customTest._id);
      return customTest;
    } catch (err) {
      log.error('Error retrieving customTest', err);
      throw err;
    }
  }

  static async findAll() {
    try {
      const customTestsFromDb = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id: { $gte: null },
          dbtype: CustomTests.TYPE
        }
      });
      log.info('customTestsFromDb', customTestsFromDb);
      const tests = customTestsFromDb.docs || [];
      const updatedTests = tests
        .map(({ _id, testCode, uuid, testName, ...rest }) => ({
          ...rest,
          uuid,
          testName,
          label: testName,
          custom: true,
          testCode: testCode || uuid,
          _id: CustomTests.getId(_id)
        }))
        .map(test => prepareTestForDisplay(test));
      log.info('CustomTests.findAll(), found docs : ');
      log.table(updatedTests);
      updatedTests.sort(({ label: aLabel }, { label: bLabel }) => (aLabel || '').localeCompare(bLabel));
      return updatedTests;
    } catch (err) {
      log.error('Error retrieving customTests', err);
      throw err;
    }
  }

  static async save(customTestToSave) {
    const { ...customTest } = customTestToSave;
    customTest.dbtype = CustomTests.TYPE;
    customTest._id = CustomTests.setId(customTest._id);
    customTest.testCode = customTest.testCode || customTest._id;
    customTest.children
      .filter(({ id }, index) => {
        const array = JSON.parse(JSON.stringify(customTest.children));
        array.splice(index, 1);
        return array.some(({ id: someId }) => someId === id);
      })
      .forEach(element => {
        const newId = `custom_${uuidv4()}`;
        const currentId = element.id;
        customTest.affectation
          .filter(({ epr }) => epr === currentId)
          .forEach(affectation => {
            // eslint-disable-next-line no-param-reassign
            affectation.epr = newId;
          });
        // eslint-disable-next-line no-param-reassign
        element.id = newId;
      });

    const updatedTest = await localDatabases.getInstance().v1databaseRepository.put(customTest);
    customTest._rev = updatedTest.rev;
    return customTest;
  }

  static async delete(test) {
    const { _id, ...testTodelete } = test;
    testTodelete._id = CustomTests.setId(_id);
    await localDatabases.getInstance().v1databaseRepository.remove(testTodelete);
  }

  static setId(id) {
    if (id && typeof id === 'string' && id.startsWith(CustomTests.TYPE)) {
      return id;
    }
    return CustomTests.TYPE + id;
  }

  static getId(id) {
    if (id && typeof id === 'string' && id.startsWith(CustomTests.TYPE)) {
      return id.substring(CustomTests.TYPE.length);
    }
    return id;
  }
}
