import localDatabases from '../../../database/database';
import defaultAnamnese from '../../../constants/default-anamnese';
import log from '../../Logger';

export default class AnamnesisModel {
  static TYPE = 'anamnesemodels';

  static async getAll() {
    try {
      const documents = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id: { $gte: null },
          name: { $gte: null },
          archived: { $ne: true },
          dbtype: AnamnesisModel.TYPE
        }
      });

      const { docs } = documents;
      // log.table(docs);
      docs.sort((a, b) => a.name.localeCompare(b.name));
      const cleanedDocs = docs.map(({ _id, ...cleanedModel }) => ({
        ...cleanedModel,
        _id: AnamnesisModel.getId(_id)
      }));
      // log.info('AnamnesisModel.getAll(), found docs : ');
      // log.table(cleanedDocs);
      return cleanedDocs;
    } catch (err) {
      log.error('Error retrieving anamneseModels', err);
      throw err;
    }
  }

  static getDefault() {
    return defaultAnamnese;
  }

  static async get(id) {
    if (id === 0) {
      return defaultAnamnese;
    }
    const cleanedId = AnamnesisModel.setId(id);
    try {
      const anamnesisModelDocs = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id: cleanedId,
          dbtype: AnamnesisModel.TYPE
        }
      });
      const anamnesisModel = anamnesisModelDocs.docs[0];
      anamnesisModel._id = AnamnesisModel.getId(anamnesisModel._id);
      return anamnesisModel;
    } catch (err) {
      log.error('Error retrieving anamneseModels', err);
      throw err;
    }
  }

  static async save(modelToSave) {
    const { _id, ...model } = modelToSave;
    try {
      model.modificationDate = new Date();
      model.dbtype = AnamnesisModel.TYPE;
      model._id = AnamnesisModel.setId(_id);
      const updatedAnamnesis = await localDatabases.getInstance().v1databaseRepository.put(model);
      model._rev = updatedAnamnesis.rev;
      model._id = _id;
      return model;
    } catch (err) {
      log.error('Error saving anamneseModels', err);
      throw err;
    }
  }

  static setId(id) {
    if (id && typeof id === 'string' && id.startsWith(AnamnesisModel.TYPE)) {
      return id;
    }
    return AnamnesisModel.TYPE + id;
  }

  static getId(id) {
    if (id && typeof id === 'string' && id.startsWith(AnamnesisModel.TYPE)) {
      return id.substring(AnamnesisModel.TYPE.length);
    }
    return id;
  }
}
