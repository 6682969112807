export const inputIncludesCentile = (input) => input.includes("SCORE_CENTILE");
export const inputIncludesQuart = (input) => input.includes("SCORE_QUART");
export const inputIncludesQuartQuint = (input) => input.includes("SCORE_QUART") || input.includes("SCORE_QUINT");
export const inputIncludesSeptile = (input) => input.includes("SCORE_SEPTILE");
export const inputIncludeScoreOctile = (input) => input.includes("SCORE_OCTILE");
export const inputIncludesCentileKey = (input) => inputIncludesCentile(input) || inputIncludesQuartQuint(input) || inputIncludesSeptile(input) || inputIncludeScoreOctile(input);

export const getCentileValue = (value, percentileObject) => ({
  returnValue: {
    score: value,
    scoreCentile: percentileObject.percentileValue || percentileObject.value,
    inScoreCentile: true
  },
  percentileObject
});
