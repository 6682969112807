import React from 'react';

import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { Edit } from '@material-ui/icons';

import './ElementStyle.css';
import labels from '../../../../constants/Labels';
import scrolling from '../../../common/scrolling';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  readOnlyElement: any,
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  rowNumber: number,
  columnNumber: number,
  elementFunction: {
    // eslint-disable-next-line flowtype/no-weak-types
    onDrop: Function
  },
  // eslint-disable-next-line flowtype/no-weak-types
  onDragStateChange: Function,
  // eslint-disable-next-line flowtype/no-weak-types
  switchEditState: Function
};

const AnamnesisReadOnlyDragElement = (props: Props) => {
  const {
    readOnlyElement,
    element,
    switchEditState,
    elementFunction: { onDrop },
    onDragStateChange,
    rowNumber,
    columnNumber
  } = props;

  const [, drag] = useDrag({
    item: { value: element, rowNumber, columnNumber, type: 'anamneseFormElement' },
    begin: () => {
      onDragStateChange(true);
      scrolling.addEventListenerForSidebar();
    },
    end: (item, monitor) => {
      scrolling.removeEventListenerForSidebar();
      onDragStateChange(false);
      const dropResult = monitor.getDropResult();
      if (item && dropResult && onDrop) {
        onDrop(
          item.value,
          dropResult.rowNumber,
          dropResult.columnNumber,
          dropResult.newRow,
          item.rowNumber,
          item.columnNumber
        );
      }
    },
    collect: monitor => ({
      isSelfDragging: monitor.isDragging(),
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div ref={drag}>
      <Grid container className="showTooltipOnHover">
        <Tooltip title={labels.edit}>
          <IconButton onClick={switchEditState}>
            <Edit />
          </IconButton>
        </Tooltip>
        {readOnlyElement(element)}
      </Grid>
    </div>
  );
};

export default AnamnesisReadOnlyDragElement;
