import User from "./data/User";
import server from "../../constants/server";

const fetchXml = async (url, init) => {
  const response = await fetch(url, init);
  if (!response.ok) {
    throw new Error(`HttpError: ${response.status} ${response.statusText}`);
  }
  return response.text();
};

const fetchJson = async (url, init) => {
  const response = await fetch(url, init);
  if (!response.ok) {
    throw new Error(`HttpError: ${response.status} ${response.statusText}`);
  }
  return response.json();
};

const retrieveWebSiteHost = async () => {
  const { val: webSiteHost } = await User.retrieveValueFromDb({
    keyValue: 'webSiteHost',
    defaultValue: server.main.url
  });
  return webSiteHost;
}

export { fetchJson, fetchXml, retrieveWebSiteHost};
