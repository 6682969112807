import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import labels from '../../constants/labels/home/HomeMultiActionButton.json';
import Patient from '../common/data/Patient';

type Props = {
  archived: boolean,
  multiActionSelectedPatientList: Array<string>,
  refreshList: () => void
};

const HomeMultiActionButton = ({ archived, multiActionSelectedPatientList, refreshList }: Props) => {
  const archiveAll = async () => {
    await Promise.all(multiActionSelectedPatientList.map(id => Patient.setArchived(id, true)));
    refreshList();
  };

  const unArchiveAll = async () => {
    await Promise.all(multiActionSelectedPatientList.map(id => Patient.setArchived(id, false)));
    refreshList();
  };

  const deleteAll = async () => {
    await Promise.all(multiActionSelectedPatientList.map(id => Patient.delete(id)));
    refreshList();
  };

  return (
    <Grid item md={12} xs={6} container>
      <Grid item md={12} xs={6}>
        <Typography>
          {multiActionSelectedPatientList.length} {labels.selectedPatient}
        </Typography>
      </Grid>
      <Grid item md={12} xs={6}>
        {archived && !!multiActionSelectedPatientList.length && (
          <React.Fragment>
            <Button variant="contained" color="primary" onClick={unArchiveAll}>
              {labels.unarchive}
            </Button>
            <Button variant="contained" color="primary" onClick={deleteAll}>
              {labels.delete}
            </Button>
          </React.Fragment>
        )}
        {!archived && !!multiActionSelectedPatientList.length && (
          <Button variant="contained" color="primary" onClick={archiveAll}>
            {labels.setArchived}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default HomeMultiActionButton;
