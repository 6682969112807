import React, { useRef, useState } from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useDrag, useDrop } from 'react-dnd';

import Comment from '../test/Comment';
import labels from '../../../constants/labels/patient/comments/CheckupCommentEditor.json';
import '../../common/styles/dragAndDrop.css';
import scrolling from '../../common/scrolling';

const styles = {
  row: {
    backgroundColor: '#d7dfe6',
    borderRadius: '5px',
    marginTop: '4px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
};

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function,
  // eslint-disable-next-line flowtype/no-weak-types
  classes?: any,
  extraElements?: React.ReactElement | undefined,
  // eslint-disable-next-line flowtype/no-weak-types
  extraButton?: any,
  value: string,
  memo?: string,
  memoByCategory: boolean,
  controlName: string,
  isDraggable?: boolean,
  lastName?: string | undefined,
  firstName?: string | undefined,
  title?: string | undefined
};

function editingState(
  toggleDisplayCommentEditor,
  isOver,
  extraElements,
  value,
  controlName,
  onChange,
  memo,
  memoByCategory,
  ref,
  lastName,
  firstName,
  title
) {
  return (
    <div ref={ref} style={{ width: '100%' }} className={isOver ? "dropZone" : ''}>
      <Grid container xs={12} item>
        <Grid item xs={12}>
          <Comment
            key={value}
            value={value}
            closeFunction={toggleDisplayCommentEditor}
            controlName={controlName}
            onChange={onChange}
            memo={memo}
            memoByCategory={memoByCategory}
            lastname={lastName}
            firstname={firstName}
            forceTextUpdate
            title={title}
            extraElements={extraElements}
          />
        </Grid>
      </Grid>
    </div>
  );
}

function readOnlyState(toggleDisplayCommentEditor, isOver, classes, extraButton, value, ref) {
  return (
    <div ref={ref} style={{ width: '100%' }} className={isOver ? "dropZone" : classes.row}>
      <Grid item xs={12} container onClick={toggleDisplayCommentEditor}>
        <Grid item xs={extraButton ? 10 : 11}>
          <Typography
            variant="body1"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: value }}
          />
        </Grid>
        {extraButton && (
          <Grid item xs={1}>
            {extraButton}
          </Grid>
        )}
        <Grid item xs={1}>
          <Tooltip title={labels.edit}>
            <IconButton variant="contained">
              <Edit />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}

const CheckupCommentEditor = ({
  extraButton = undefined,
  isDraggable = false,
  value,
  onChange,
  extraElements,
  controlName,
  index,
  moveComment,
  memo,
  memoByCategory,
  lastName,
  firstName,
  title
}: Props) => {
  const [editorVisible, setEditorVisible] = useState(false);

  const toggleDisplayCommentEditor = () => {
    setEditorVisible(!editorVisible);
  };

  const classes = makeStyles(styles)();
  const dropRef = useRef(null);
  const dragDropRef = useRef(null);
  const [, drag] = useDrag({
    item: { index, type: 'checkupComment' },
    begin: () => {
      scrolling.addEventListenerForSidebar();
    },
    end: (item, monitor) => {
      scrolling.removeEventListenerForSidebar();
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        moveComment(item.index, dropResult.hoverIndex);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const [{ isOver }, drop] = useDrop({
    accept: 'checkupComment',
    drop: () => ({ hoverIndex: index }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  drop(dropRef);
  drag(drop(dragDropRef));

  if (editorVisible) {
    return editingState(
      toggleDisplayCommentEditor,
      isOver,
      extraElements,
      value,
      controlName,
      onChange,
      memo,
      memoByCategory,
      isDraggable ? dropRef : null,
      lastName,
      firstName,
      title
    );
  }
  return readOnlyState(
    toggleDisplayCommentEditor,
    isOver,
    classes,
    extraButton,
    value,
    isDraggable ? dragDropRef : null
  );
};

CheckupCommentEditor.defaultProps = {
  classes: null,
  firstname: '',
  lastname: '',
  extraElements: null
};

export default CheckupCommentEditor;
