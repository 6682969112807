import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import labels from '../../../constants/labels/patient/import/ImportPassationList.json';
import normalizeImporterClasse from '../test/importers/NormalizeImporterClasse';

const ImportPassationList = ({
  classe,
  test,
  programId,
  passationNumber,
  name,
  firstname,
  lastname,
  passationDate,
  selectPassation
}: Props) => {
  const [checked, setChecked] = useState(false);
  const [choosenCalcul, setChoosenCalcul] = useState(null);

  useEffect(() => {
    const normalizedClasse = normalizeImporterClasse(classe);
    if (test.calcul.map(({ id }) => id).includes(normalizedClasse)) {
      setChoosenCalcul(normalizedClasse);
    } else if (test.calcul.length === 1) {
      setChoosenCalcul(test.calcul[0].id);
    }
  }, [classe, test.calcul]);

  const checkChange = () => {
    setChecked(!checked);
    selectPassation({ programId, passationNumber, choosenCalcul })();
  };

  const choosenCalculChange = e => {
    setChoosenCalcul(e.target.value);
    selectPassation({ programId, passationNumber, choosenCalcul: e.target.value })();
  };

  return (
    <ListItem onClick={checkChange}>
      <ListItemIcon>
        <Checkbox
          checked={checked}
          onChange={checkChange}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': name }}
        />
      </ListItemIcon>
      <ListItemText primary={test.label} secondary={`${firstname} ${lastname} ${passationDate} ${classe}`} />
      {checked && (
        <ListItemSecondaryAction>
          <FormControl error={!choosenCalcul}>
            <Select value={choosenCalcul} onChange={choosenCalculChange}>
              <MenuItem value={null} />
              {test.calcul.map(({ label, id }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{!choosenCalcul && labels.chooseValue}</FormHelperText>
          </FormControl>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default ImportPassationList;
