import React, {Fragment, useState} from "react";
import {
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, Tooltip,
  Typography
} from "@material-ui/core";
import classNames from "classnames";
import {ExpandLess, ExpandMore, SpeakerNotes} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import {CHECKUP_MODEL_MEMO_PREFIX, getKeyFromTestCodeAndId} from "../../../common/data/MemoUtil";
import mtpStyle from "../MemorizedTextsPageStyle";
import buildOneLevelTreeModelElement from "../../../common/chekupModel/BuildOneLevelTreeModelElement";
import labels from "../../../../constants/configuration/memorizedTexts/commentType/CheckupModelStructureElementsSelection.json";

const useStyles = makeStyles(theme => ({
  ...mtpStyle(theme),
  checkupModelStructure: {
    '& h1': {
      fontSize: '2rem'
    },
  '& h2': {
      fontSize: '1rem'
    },
    '& h3': {
      fontSize: '0.9rem'
    },
    '& h4': {
      fontSize: '0.8rem'
    },
    '& h5': {
      fontSize: '0.7rem'
    },
    '& h6': {
      fontSize: '0.6rem'
    }
  }
}));

type Props = {
  selectedTestCode: {
    elements: Array<{index: number | undefined, label: string, uuid: string, level: number}>,
    _id: string | number,
    label: string
  },
  filteredMemoKeys: Array<string> | undefined,
  selectedMemoKey: string | null | undefined,
  setSelectedMemoKey: (string) => void,
  memoKeyWithTexts: Array<string> | undefined
};

const elementHasChild = element => element.childs && element.childs.length > 0;
const typographyVariantLevel = element => `h${(element.level + 1 >= 6) ? 6 : (element.level + 1)}`;
const typographyText = element => `${"-".repeat(element.level)} ${element.label}`;
const filterElementAndChilds = (filteredMemoKeys, selectedTestCode) => ({uuid, childs}) => {
  if (!filteredMemoKeys || !filteredMemoKeys.length) return true;
  const childUuids = childs.map(({uuid: childUuid}) => childUuid)
  return (filteredMemoKeys.includes(getKeyFromTestCodeAndId(`${CHECKUP_MODEL_MEMO_PREFIX}${selectedTestCode._id}`, uuid))
    || childUuids.some(childUuid => filteredMemoKeys.includes(getKeyFromTestCodeAndId(`${CHECKUP_MODEL_MEMO_PREFIX}${selectedTestCode._id}`, childUuid)))
  );
};

const ElementOrChildHasAMemorizedTextIcon = () => <Tooltip title={labels.elementOrChildContainsMemorizedText}><SpeakerNotes/></Tooltip>

const CheckupModelStructureElementSelection = ({selectedTestCode, selectedMemoKey, setSelectedMemoKey, filteredMemoKeys, memoKeyWithTexts, ...element}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const childTree = buildOneLevelTreeModelElement(element.childs, element.level+1);

  const memoKey = getKeyFromTestCodeAndId(`${CHECKUP_MODEL_MEMO_PREFIX}${selectedTestCode._id}`, element.uuid);

  const childMemoKeys = element.childs.map(({uuid}) => getKeyFromTestCodeAndId(`${CHECKUP_MODEL_MEMO_PREFIX}${selectedTestCode._id}`, uuid));

  const thisElementOrItsChildContainsAMemorizedText = memoKeyWithTexts.includes(memoKey) || childMemoKeys.some(childMemoKey => memoKeyWithTexts.includes(childMemoKey)) ;

  return <Fragment>
    <ListItem
      button
      className={classNames(classes.root, classes.checkupModelStructure)}
      onClick={() => setSelectedMemoKey(memoKey)}
      selected={memoKey === selectedMemoKey}
    >
      <ListItemIcon>
        <IconButton edge="start" aria-label={open ? "expand": "collapse"} onClick={() => setOpen(!open)}>
          {open ? (
            elementHasChild(element) && <ExpandLess />
          ) : (
            elementHasChild(element) && <ExpandMore />
          )}
        </IconButton>
      </ListItemIcon>
      <ListItemIcon>
        {thisElementOrItsChildContainsAMemorizedText && <ElementOrChildHasAMemorizedTextIcon/>}
      </ListItemIcon>
      <ListItemText primary={<Typography variant={typographyVariantLevel(element)}>{typographyText(element)}</Typography>}/>
    </ListItem>
    {element.childs &&
      <Collapse in={open} timeout="auto" unmountOnExit>
        {childTree.filter(filterElementAndChilds(filteredMemoKeys, selectedTestCode))
          .map(({uuid, ...childElement}) => {
          if (elementHasChild(childElement)) {
            return <CheckupModelStructureElementSelection key={uuid} {...{selectedTestCode, selectedMemoKey, setSelectedMemoKey, uuid, filteredMemoKeys, memoKeyWithTexts, ...childElement}} />
          }
            const childMemoKey = getKeyFromTestCodeAndId(`${CHECKUP_MODEL_MEMO_PREFIX}${selectedTestCode._id}`, uuid);
            return <ListItem
            key={uuid}
            button
            selected={childMemoKey === selectedMemoKey}
            onClick={() => setSelectedMemoKey(childMemoKey)}
            className={classNames({[classes.nested]: elementHasChild(childElement)}, {[classes.root]: !elementHasChild(childElement)}, classes.checkupModelStructure)}
          >
            <ListItemIcon/>
            <ListItemIcon>
              <IconButton edge="start">
                {memoKeyWithTexts.includes(childMemoKey) && <ElementOrChildHasAMemorizedTextIcon/>}
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={<Typography variant={typographyVariantLevel(childElement)}>{typographyText(childElement)}</Typography>} />
          </ListItem>
        })}
      </Collapse>
    }
  </Fragment>
};
const CheckupModelStructureElementsSelection = ({selectedTestCode, filteredMemoKeys, selectedMemoKey, setSelectedMemoKey, memoKeyWithTexts}: Props) => {
  const classes = useStyles();
  const tree = buildOneLevelTreeModelElement(selectedTestCode.elements);

  return <Grid item md={3}>
    <Typography variant="h4">{selectedTestCode.label}</Typography>
    <List component="nav" aria-label="sub" className={classes.mainList}>
      {tree
        .filter(filterElementAndChilds(filteredMemoKeys, selectedTestCode))
        .map(({uuid, ...element}) => <CheckupModelStructureElementSelection
          key={uuid}
          {...{selectedTestCode, selectedMemoKey, setSelectedMemoKey, uuid, filteredMemoKeys, memoKeyWithTexts, ...element}}
          />
        )
      }
    </List>
  </Grid>
};

export default CheckupModelStructureElementsSelection;
