import React from 'react';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

type Props = {
  title: string,
  content: React.ReactElement,
  accordionActions?: React.ReactElement | undefined
};

const AccordionWithTitleAndContent = ({ title, content, accordionActions }: Props) => (
  <Grid item md={12}>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>{title}</AccordionSummary>
      {accordionActions && <AccordionActions>{accordionActions}</AccordionActions>}
      <AccordionDetails>
        <Grid container spacing={2}>
          {content}
        </Grid>
      </AccordionDetails>
    </Accordion>
  </Grid>
);

AccordionWithTitleAndContent.defaultProps = {
  accordionActions: null
};

export default AccordionWithTitleAndContent;
