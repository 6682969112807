import React from 'react';
import { Button, Grid, MobileStepper } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import labels from '../../constants/labels/patient/TestFillingStepper.json';

type Props = {
  activeStep: number,
  array: { id: string }[],
  index: number,
  setCurrentlyOpenedExaminationCommentEditorId: string => void
};

const TestFillingStepper = ({ activeStep, array, index, setCurrentlyOpenedExaminationCommentEditorId }: Props) => (
  <Grid item md={12}>
    {array.length > 1 && (
      <MobileStepper
        variant="dots"
        steps={array.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={() => {
              setCurrentlyOpenedExaminationCommentEditorId(array[index + 1].id);
            }}
            disabled={index === array.length - 1}
          >
            {labels.nextExamination}
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={() => {
              setCurrentlyOpenedExaminationCommentEditorId(array[index - 1].id);
            }}
            disabled={index === 0}
          >
            <KeyboardArrowLeft />
            {labels.previousExamination}
          </Button>
        }
      />
    )}
  </Grid>
);

export default TestFillingStepper;
