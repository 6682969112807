const addCustomTestAffectationsToCheckUpModel = (checkUpModel, customTests) => {
  const { elements, ...props } = checkUpModel;

  // first transform custom tests affectation to an object containing :
  // the key is the element id to which the value must be affected
  // the value is an array of object {id, testCode}
  const allAffectation = customTests
    .flatMap(({ testCode, uuid, affectation }) =>
      affectation.map(({ epr: id, model }) => ({ testCode: testCode || uuid, elementId: model, id }))
    )
    .reduce((acc, { testCode, elementId, id }) => {
      acc[elementId] = [...(acc[elementId] || []), { id, testCode }];
      return acc;
    }, {});

  // then push those values to the elements of the checkupModel
  const newElements = elements.map(({ tests, uuid, ...rest }) => ({
    ...rest,
    uuid,
    tests: [...tests, ...(allAffectation[uuid] || [])]
  }));

  return { elements: newElements, ...props };
};

export default addCustomTestAffectationsToCheckUpModel;
