import React, { Component } from 'react';
import { Divider, Grid, IconButton, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Home } from '@material-ui/icons';
import _ from 'lodash';

import labels from '../../../constants/labels/patient/anamnesis/AnamnesisMailResult.json';
import routes from '../../../constants/routes';
import User from '../../common/data/User';
import CryptoService from '../../../actions/CryptoService';
import Patient from '../../common/data/Patient';
import AnamnesisModel from '../../common/data/AnamnesisModel';
import { fetchJson, retrieveWebSiteHost } from '../../common/OrthoscribeWebSite';
import appVersionSpecific from '../../../appVersionSpecific.ortho';
import { withRouter } from 'react-router';

type Props = {
  match: ?{ params: ?{ patientId: ?string } }
};

class AnamnesisMailResult extends Component<Props> {
  props: Props;

  state = {
    result: 'Waiting'
  };

  async componentDidMount(): void {
    const {
      match: {
        params: { patientId }
      }
    } = this.props;
    const patient = await Patient.retrievePatient(patientId);

    const keySize = 1024;
    const RSA = CryptoService.getRSA(keySize);

    if (!patient.rsaPrivateKey || !patient.rsaPublicKey || !patient.rsaKeySize) {
      RSA.generateKeys(async () => {
        patient.rsaPrivateKey = RSA.getPrivateKey();
        patient.rsaPublicKey = RSA.getPublicKey();
        patient.rsaKeySize = keySize;
        const updatedPatient = await Patient.updatePatient(patient);
        const result = await cryptAndSave(updatedPatient);
        this.setState({ result });
      });
    } else {
      const result = await cryptAndSave(patient);
      this.setState({ result });
      const { history } = this.props;
      if( result === 'ok') {
        setTimeout(() => {
          history.push(routes.PATIENT.getForPath({path: '', patientId}));
        }, 10000)
      }
    }
  }

  render(): React.ReactNode {
    const { result } = this.state;
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">{labels.title}</Typography>
          </Grid>
          <Grid item xs={12} />
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">{labels.result[result]}</Typography>
          </Grid>
          {result === 'Waiting' && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
          <Grid item xs={12}>
            <Tooltip title={labels.back}>
              <IconButton component={Link} to={routes.HOME}>
                <Home />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(AnamnesisMailResult);

async function cryptAndSave(patient) {
  const { anamnese: anamnesis, bilan } = patient;
  const anamnesisModel = await AnamnesisModel.get(anamnesis.modelId);

  bilan.anamneseId = anamnesis.id;
  const patientCopy = _.cloneDeep(patient);
  delete patientCopy.rsaPrivateKey;
  delete patientCopy.rsaPublicKey;
  delete patientCopy.rsaKeySize;

  const isHappyNeuron = appVersionSpecific.isHappyNeuron;

  const payload = {
    anamnese: _.cloneDeep(anamnesis),
    plainte: patient.plainte,
    key: _.cloneDeep(patient.rsaPublicKey),
    keySize: _.cloneDeep(patient.rsaKeySize)
  };
  if (!payload.anamnese.values) {
    payload.anamnese.values = {};
  }
  payload.anamnese.values.orthoplainte = patient.plainte;
  const { val: aesKey } = await User.retrieveValueFromDb({ keyValue: 'aesKey' });
  const { val: email } = await User.retrieveValueFromDb({ keyValue: 'email' });
  const { val: authToken } = await User.retrieveValueFromDb({ keyValue: 'authToken' });
  const data = CryptoService.aesEncrypt(payload, aesKey);
  const message = {
    recipient: patient.contactMail,
    mailSubject: anamnesis.mailSubject,
    mailBody: anamnesis.mailBody,
    patientId: bilan.patientId,
    bilanUUID: bilan._id,
    disableAddressDisplay: anamnesisModel.disableAddressDisplay,
    loginMessage: anamnesisModel.loginMessage,
    bilanId: bilan._id,
    payload: data,
    'happyneuron-user': isHappyNeuron ? email : undefined,
    authToken
  };

  if (anamnesis.serverId) {
    message.serverId = anamnesis.serverId;
  }
  const webSiteHost = await retrieveWebSiteHost();
  let result;
  try {
    const { payload: responsePayload, status } = await fetchJson(`${webSiteHost}/api/anamnese/create`, {
      method: 'POST',
      body: JSON.stringify(message),
      headers: {
        'Content-Type': 'application/json',
        dataType: 'json'
      }
    });

    result = status;
    anamnesis.serverId = responsePayload;
    anamnesis.status = 'SENT';
    anamnesis.modificationDate = new Date();
  } catch (e) {
    console.error('############# ERROR #############', e);
    result = 'ko';
  }
  bilan.modificationDate = new Date();
  bilan.anamneseId = anamnesis._id;
  await Patient.updatePatient({ ...patient, anamnese: anamnesis, bilan });
  return result;
}
