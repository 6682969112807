import React, { Component } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { Delete, Edit, ExpandMore } from '@material-ui/icons';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import uuidv4 from 'uuid/v4';

import labels from '../../../constants/labels/configuration/anamnesis/Anamnesis.json';

import AnamnesisFormElements from './AnamnesisFormElements';
import TabEdition from './TabEdition';
import DragButton from './elements/DragButton';
import AnamnesisRow from './AnamnesisRow';
import DropRow from './DropRow';
import log from '../../Logger';
import AnamnesisPageList from './AnamnesisPageList';

type Props = {
  definitions: array,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function
};

class FormEdition extends Component<Props> {
  props: Props;

  state = {
    expanded: 'panel1',
    selectedTabIndex: 0,
    editCurrentTab: false,
    isDragging: false
  };

  handlePanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  addDefinition = () => {
    const { definitions, onChange } = this.props;
    const [...newDefinitions] = definitions;
    newDefinitions.push({
      titre: labels.tabs.form.right.newDefinition.title,
      titreCourt: labels.tabs.form.right.newDefinition.shortTitle,
      description: labels.tabs.form.right.newDefinition.description,
      id: uuidv4(),
      rows: []
    });
    onChange({ target: { name: 'definitions', value: newDefinitions } });
    this.setState({
      selectedTabIndex: definitions.length,
      editCurrentTab: true
    });
  };

  handleTabChange = (id, index) => () => {
    this.setState({ selectedTabIndex: index, editCurrentTab: false });
  };

  handleEditTab = index => () => {
    this.setState({ selectedTabIndex: index, editCurrentTab: true });
  };

  handleChangeDefinition = event => {
    const { definitions, onChange } = this.props;
    const { selectedTabIndex } = this.state;
    const [...newDefinitions] = definitions;
    newDefinitions.splice(selectedTabIndex, 1, event.target.value);
    this.setState({ editCurrentTab: false });
    onChange({ target: { name: 'definitions', value: newDefinitions } });
  };

  deleteDefinition = index => () => {
    const { definitions, onChange } = this.props;
    const [...newDefinitions] = definitions;
    newDefinitions.splice(index, 1);
    this.setState({ selectedTabIndex: 0 });
    onChange({ target: { name: 'definitions', value: newDefinitions } });
  };

  setDragState = isDragging => {
    this.setState({ isDragging });
  };

  removeElement = (rowNumber, columnNumber) => () => {
    const { definitions, onChange } = this.props;
    const { selectedTabIndex } = this.state;
    const [...newDefinitions] = definitions;
    const [...newRows] = definitions[selectedTabIndex].rows;
    newRows[rowNumber].splice(columnNumber, 1);
    newDefinitions[selectedTabIndex].rows = newRows;
    onChange({ target: { name: 'definitions', value: newDefinitions } });
  };

  onChangeElement = (element, rowNumber, columnNumber) => {
    const { definitions, onChange } = this.props;
    const { selectedTabIndex } = this.state;
    const [...newDefinitions] = definitions;
    const [...newRows] = definitions[selectedTabIndex].rows;
    newRows[rowNumber][columnNumber] = element;
    newDefinitions[selectedTabIndex].rows = newRows;
    onChange({ target: { name: 'definitions', value: newDefinitions } });
  };

  onDropFormElement = (value, rowNumber, columnNumber, newRow) => {
    const { definitions, onChange } = this.props;
    const { selectedTabIndex } = this.state;
    const newDefinitions = dropFormElement(definitions, selectedTabIndex, value, newRow, rowNumber, columnNumber);
    onChange({ target: { name: 'definitions', value: newDefinitions } });
  };

  onMoveFormElement = (value, rowNumber, columnNumber, newRow, oldRowNumber, oldColumnNumber) => {
    const { definitions, onChange } = this.props;
    const { selectedTabIndex } = this.state;
    const newDefinitions = removeFormElement(definitions, selectedTabIndex, oldRowNumber, oldColumnNumber);
    const definitionsWithNewElement = dropFormElement(
      newDefinitions,
      selectedTabIndex,
      value,
      newRow,
      rowNumber,
      columnNumber
    );
    const cleanedDefinitionsWithNewElement = cleanEmptyRow(definitionsWithNewElement, selectedTabIndex);
    onChange({
      target: { name: 'definitions', value: cleanedDefinitionsWithNewElement }
    });
  };

  render() {
    const { expanded, selectedTabIndex, editCurrentTab, isDragging } = this.state;
    const { definitions } = this.props;
    const definition = definitions[selectedTabIndex];
    log.info('render FormEdition', selectedTabIndex, definitions, definition);
    return (
      <DndProvider backend={HTML5Backend}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={2}>
            <Paper>
              <Typography variant="h5">{labels.tabs.form.left.title1}</Typography>
              <Typography variant="body2">{labels.tabs.form.left.chapter}</Typography>
              <Typography variant="h5">{labels.tabs.form.left.title2}</Typography>

              {AnamnesisFormElements.map(({ groupTitle, panelId, elements }) => (
                <ExpandablePanel
                  key={panelId}
                  expanded={expanded}
                  panelId={panelId}
                  titleLabel={groupTitle}
                  handleChange={this.handlePanelChange}
                >
                  <Grid container>
                    {elements.map(({ icon, label, value }) => (
                      <Grid item sm={12} md={6} key={label}>
                        <DragButton
                          icon={icon}
                          label={label}
                          value={value}
                          onDragStateChange={this.setDragState}
                          onDrop={this.onDropFormElement}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </ExpandablePanel>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <AnamnesisPageList
              definitions={definitions}
              selectedTabIndex={selectedTabIndex}
              handleTabChange={this.handleTabChange}
              deleteDefinition={this.deleteDefinition}
              addDefinition={this.addDefinition}
            />
          </Grid>
          <Grid item xs={8}>
            {editCurrentTab && (
              <TabEdition
                definition={definition}
                onChange={this.handleChangeDefinition}
                deleteTab={this.deleteDefinition(selectedTabIndex)}
              />
            )}
            {!editCurrentTab && (
              <Definition
                definition={definition}
                editTab={this.handleEditTab(selectedTabIndex)}
                deleteTab={this.deleteDefinition(selectedTabIndex)}
                isDragging={isDragging}
                onDragStateChange={this.setDragState}
                elementFunction={{
                  removeElement: this.removeElement,
                  changeElement: this.onChangeElement,
                  onDrop: this.onMoveFormElement
                }}
              />
            )}
          </Grid>
        </Grid>
      </DndProvider>
    );
  }
}

export default FormEdition;

function ExpandablePanel(props) {
  // eslint-disable-next-line react/prop-types
  const { expanded, panelId, titleLabel, handleChange, children } = props;
  return (
    <Accordion expanded={expanded === panelId} onChange={handleChange(panelId)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{titleLabel}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

function Definition({
  // eslint-disable-next-line react/prop-types
  definition,
  // eslint-disable-next-line react/prop-types
  editTab,
  // eslint-disable-next-line react/prop-types
  deleteTab,
  // eslint-disable-next-line react/prop-types
  isDragging,
  // eslint-disable-next-line react/prop-types
  elementFunction,
  // eslint-disable-next-line react/prop-types
  onDragStateChange
}) {
  const { id = '', titre = '', rows = [] } = definition || {};
  return (
    <Grid key={id} container>
      <Grid item xs={10}>
        <Typography variant="h5">{titre}</Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={editTab}>
          <Edit />
        </IconButton>
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={deleteTab}>
          <Delete />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <DropRow isDragging={isDragging} rowNumber={0} />
      </Grid>
      {rows.map((rowArray, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={index} item xs={12} container justifyContent="space-between">
          <AnamnesisRow
            row={rowArray}
            index={index}
            isDragging={isDragging}
            elementFunction={elementFunction}
            onDragStateChange={onDragStateChange}
          />
          <Grid item xs={12}>
            <DropRow isDragging={isDragging} rowNumber={index + 1} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

function cleanEmptyRow(definitions, selectedTabIndex) {
  const [...newDefinitions] = definitions;
  const [...rows] = definitions[selectedTabIndex].rows;
  const [...filteredRows] = rows.filter(row => row.length !== 0);
  newDefinitions[selectedTabIndex].rows = filteredRows;
  return newDefinitions;
}

function dropFormElement(definitions, selectedTabIndex, value, newRow, rowNumber, columnNumber) {
  const [...newDefinitions] = definitions;
  const [...newRows] = definitions[selectedTabIndex].rows;
  const { id, ...newElement } = JSON.parse(JSON.stringify(value));
  newElement.id = id || uuidv4();
  if (newRow) {
    newRows.splice(rowNumber, 0, [newElement]);
  } else {
    const rowSpanValueSum = newRows[rowNumber].map(el => el.span).reduce((acc, currVal) => acc + currVal, 0);
    if (rowSpanValueSum + (newElement.span || 12) > 12) {
      newRows.splice(rowNumber + 1, 0, [newElement]);
    } else {
      newRows[rowNumber].splice(columnNumber, 0, newElement);
    }
  }
  newDefinitions[selectedTabIndex].rows = newRows;
  return newDefinitions;
}

function removeFormElement(definitions, selectedTabIndex, oldRowNumber, oldColumnNumber) {
  const [...newDefinitions] = definitions;
  const [...newRows] = definitions[selectedTabIndex].rows;
  newRows[oldRowNumber].splice(oldColumnNumber, 1);
  newDefinitions[selectedTabIndex].rows = newRows;
  return newDefinitions;
}
