import React, { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Grid, List, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';

import Memo from '../../../common/data/Memo';
import labels from '../../../../constants/labels/patient/comments/memorizedTexts/MemorizedTexts.json';
import AddListItem from './AddListItem';
import MemorizedListItem from './MemorizedListItem';
import MoreActionsMenu from '../../../home/MoreActionsMenu';
import SearchField from '../../../common/SearchField';
import useDebounce from '../../../common/UseDebounce';

const useStyles = makeStyles(theme => ({
  limitHeightExternalBig: {},
  limitHeightInternalBig: {
    height: '56vh',
    maxHeight: '56vh'
  },
  limitHeightExternalSmall: {
    height: '31vh',
    maxHeight: '31vh'
  },
  limitHeightInternalSmall: {
    height: '16vh',
    maxHeight: '16vh'
  },
  hiddenOverflow: {
    overflow: 'hidden',
    '& .MuiList-padding': {
      padding: 0
    }
  },
  scrollOverflow: {
    overflowY: 'auto'
  },
  showActionButtonOnHover: {
    '& div.MuiListItemSecondaryAction-root': {
      display: 'none'
    },
    '& li:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '& li:hover div.MuiListItemSecondaryAction-root': {
      display: 'contents'
    }
  },
  compactedList: {
    '& li.MuiListItem-container div.MuiListItemText-root p': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  backgroundWhite: {
    backgroundColor: theme.palette.background.paper
  },
  cardGhost: {
    transition: 'transform 0.18s ease',
    transform: 'rotateZ(5deg)'
  }
}));

type Props = {
  memoKey: string,
  bigSize?: boolean,
  byCategory: boolean,
  readOnly?: boolean,
  showHistory?: boolean,
  withCommandPanel?: boolean,
  defaultSearchTerm?: string,
  copyAction: | undefined
    | ((
        {
          canModify: boolean,
          label: string,
          texts: Array,
          uuid: string
        },
        string
      ) => void),
  onTextSelected: string => void,
  onCategorySelected: string => void,
  selectedCategoryUuid?: string | undefined
};

const MayBeContainer = ({ onDrop, classes, containerPresent, children }) => {
  if (containerPresent) {
    return (
      <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop} dragClass={classes.cardGhost}>
        {children}
      </Container>
    );
  }
  return children;
};

const MayBeDraggable = ({ draggablePresent, children }) => {
  if (draggablePresent) {
    return <Draggable>{children}</Draggable>;
  }
  return children;
};

function MemorizedTexts({
  memoKey,
  byCategory,
  onTextSelected,
  onCategorySelected,
  bigSize,
  copyAction,
  showHistory,
  withCommandPanel,
  defaultSearchTerm,
  selectedCategoryUuid,
  readOnly
}: Props) {
  const classes = useStyles();

  const [memo, setMemo] = useState({ val: { nodes: [], enabledPersonalInfoReplacement: false } });
  const [selectedTexts, setSelectedTexts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const searchTermChanged = useCallback(async () => {
    if(searchTerm && searchTerm !== "") {
      onCategorySelected(null);
      setSelectedTexts([]);
      const mtexts = memo.val.nodes
        .reduce((acc, { texts }) => [...acc, ...texts], [])
        .filter(({ text }) => text.toUpperCase().includes(searchTerm.toUpperCase()));
      console.log({ nodes: memo.val.nodes, mtexts });

      await setSelectedTexts(mtexts);
    }
  }, [memo.val.nodes, onCategorySelected, searchTerm]);

  useEffect(() => {
    const fetchMemorizedTexts = async () => {
      const memoFromDb = await Memo.findByKey(memoKey);
      // console.log({ memoFromDb });
      setMemo(memoFromDb);
    };

    fetchMemorizedTexts().then();
  }, [memoKey]);

  useEffect(() => {
    if (!byCategory || memo.val.nodes.length >= 1) {
      if (!memo.val.nodes[0]) {
        memo.val.nodes.push({
          canModify: true,
          label: 'Par défaut',
          texts: [],
          uuid: uuidv4()
        });
      }
      const node = memo.val.nodes.filter(({ uuid }) => uuid === selectedCategoryUuid).pop();
      if (!node) {
        onCategorySelected(memo.val.nodes[0].uuid);
        setSelectedTexts(memo.val.nodes[0].texts);
      } else {
        const { texts } = node || { texts: [] };
        setSelectedTexts(texts);
      }

      if (defaultSearchTerm) {
        searchTermChanged().then();
      }
    }
  }, [memo, byCategory, searchTermChanged, defaultSearchTerm, selectedCategoryUuid, onCategorySelected]);

  useEffect(() => {
    searchTermChanged().then();
  }, [debouncedSearchTerm, searchTermChanged]);


  const changeEnabledPersonalInfoReplacement = async () => {
    const newMemo = {
      ...JSON.parse(JSON.stringify(memo)),
      val: {
        ...JSON.parse(JSON.stringify(memo.val)),
        enabledPersonalInfoReplacement: !memo.val.enabledPersonalInfoReplacement
      }
    };
    const updatedMemo = await Memo.save(newMemo);
    setMemo(updatedMemo);
  };

  const addCategory = async newCategoryLabel => {
    const { updatedMemo, newCategory } = await Memo.addCategory(memo, newCategoryLabel);
    setMemo(updatedMemo);
    setSelectedTexts([]);
    onCategorySelected(newCategory.uuid);
  };

  const deleteCategory = async uuid => {
    const updatedMemo = await Memo.deleteCategory(memo, uuid);
    setMemo(updatedMemo);
    if (selectedCategoryUuid === uuid) {
      onCategorySelected(null);
    }
  };

  const updateCategoryLabel = async (uuid, newLabel) => {
    const updatedMemo = await Memo.updateCategoryLabel(memo, uuid, newLabel);
    setMemo(updatedMemo);
  };

  const addTextToCategory = async newTextLabel => {
    const { updatedMemo, texts } = await Memo.addTextToCategory(memo, newTextLabel, selectedCategoryUuid);
    setMemo(updatedMemo);
    setSelectedTexts(texts);
  };

  const deleteTextFromCategory = async (uuid, categoryUuid) => {
    const { updatedMemo, texts } = await Memo.deleteTextFromCategory(memo, uuid, categoryUuid);
    setMemo(updatedMemo);
    setSelectedTexts(texts);
  };

  const updateTextInCategory = async (uuid, categoryUuid, newText) => {
    const { updatedMemo, texts } = await Memo.updateTextInCategory(memo, uuid, categoryUuid, newText);
    setMemo(updatedMemo);
    setSelectedTexts(texts);
  };

  const setInsertedByDefaultTextInCategory = async (insertedByDefault, uuid, categoryUuid) => {
    const { updatedMemo, texts } = await Memo.updateInsertedByDefaultTextInCategory(
      memo,
      uuid,
      categoryUuid,
      insertedByDefault
    );
    setMemo(updatedMemo);
    setSelectedTexts(texts);
  };

  const onMemorizedTextSelected = async text => {
    const updatedMemo = await Memo.pushToHistory(memo, text);
    setMemo(updatedMemo);
    onTextSelected(text, memo.val.enabledPersonalInfoReplacement);
  };

  const copyTextAction = text => {
    const categoryIndex = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === selectedCategoryUuid);
    const [{ texts, ...category }] = memo.val.nodes.slice(categoryIndex, categoryIndex + 1);
    copyAction({ ...category, uuid: uuidv4(), texts: [] }, [text]);
  };

  const copyCategoryAction = categoryUuid => () => {
    const categoryIndex = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === categoryUuid);
    const [{ texts, ...category }] = memo.val.nodes.slice(categoryIndex, categoryIndex + 1);
    copyAction({ ...category, uuid: uuidv4(), texts: [] }, [...texts.map(({ text }) => text)]);
  };

  const onDropText = async ({ removedIndex, addedIndex }) => {
    if (Number.isInteger(removedIndex) && Number.isInteger(addedIndex)) {
      const newTexts = arrayMove(selectedTexts, removedIndex, addedIndex);
      const { updatedMemo, texts } = await Memo.updateAllTextsInCategory(memo, selectedCategoryUuid, newTexts);
      setMemo(updatedMemo);
      setSelectedTexts(texts);
    }
  };

  const onDropCategory = async ({ removedIndex, addedIndex }) => {
    console.log("onDropCategory")
    if (Number.isInteger(removedIndex) && Number.isInteger(addedIndex)) {
      const nodes = arrayMove(memo.val.nodes, removedIndex, addedIndex);
      const updatedMemo = await Memo.updateMemoNode(memo, nodes);
      setMemo(updatedMemo);
    }
  };

  const hasHistory = showHistory && memo && memo.val && memo.val.history && !!memo.val.history.length;
  return (
    <Grid
      item
      container
      className={classNames(
        { [classes.limitHeightExternalSmall]: !bigSize, [classes.limitHeightExternalBig]: bigSize },
        classes.hiddenOverflow,
        classes.backgroundWhite
      )}
    >
      {withCommandPanel && (
        <Grid item container md={12} alignItems="center">
          <Grid item md={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={memo.val.enabledPersonalInfoReplacement}
                  onChange={changeEnabledPersonalInfoReplacement}
                />
              }
              label={labels.enabledPersonalInfoReplacement}
            />
          </Grid>
          {hasHistory && (
            <Grid item md={3}>
              <MoreActionsMenu
                menuItemEntries={memo.val.history.map((historyText, index) => ({
                  key: index,
                  element: historyText,
                  onClick: async () => {
                    await onMemorizedTextSelected(historyText, memo.val.enabledPersonalInfoReplacement);
                  }
                }))}
                buttonText="historique"
                format="button"
                icon={null}
              />
            </Grid>
          )}
          <Grid item md={5} container>
            <SearchField
              q={searchTerm}
              searchChange={async e => {
                await setSearchTerm(e.target.value);
              }}
              itemMdValue={12}
              placeholder={labels.searchFieldPlaceholder}
            />
          </Grid>
        </Grid>
      )}
      {byCategory && (
        <Grid
          item
          md={3}
          className={classNames(
            { [classes.limitHeightInternalSmall]: !bigSize, [classes.limitHeightInternalBig]: bigSize },
            classes.hiddenOverflow
          )}
        >
          <List
            component="nav"
            dense
            className={classNames(
              { [classes.limitHeightInternalSmall]: !bigSize, [classes.limitHeightInternalBig]: bigSize },
              classes.scrollOverflow,
              classes.showActionButtonOnHover,
              classes.compactedList
            )}
          >
            {!readOnly && <AddListItem label={labels.addCategory} onAdd={addCategory} simple />}
            <MayBeContainer onDrop={onDropCategory} classes={classes} containerPresent>
              {memo &&
                memo.val &&
                memo.val.nodes.map(({ label, canModify = false, texts: thisTexts, uuid }) => (
                  <MayBeDraggable key={uuid} draggablePresent>
                    <MemorizedListItem
                      key={uuid}
                      onChange={newText => updateCategoryLabel(selectedCategoryUuid, newText)}
                      onDelete={() => deleteCategory(uuid)}
                      onItemSelected={() => {
                        setSelectedTexts(thisTexts);
                        onCategorySelected(uuid);
                      }}
                      text={label}
                      selected={selectedCategoryUuid === uuid}
                      original={!canModify}
                      copyAction={copyCategoryAction(uuid)}
                      simple
                      readOnly={readOnly}
                    />
                  </MayBeDraggable>
                ))}
            </MayBeContainer>
          </List>
        </Grid>
      )}
      <Grid item md={byCategory ? 9 : 12}>
        <List
          component="nav"
          dense
          key={selectedCategoryUuid}
          className={classNames(
            { [classes.limitHeightInternalSmall]: !bigSize, [classes.limitHeightInternalBig]: bigSize },
            classes.scrollOverflow,
            classes.showActionButtonOnHover
          )}
        >
          {selectedCategoryUuid && <AddListItem label={labels.addText} onAdd={addTextToCategory} />}
          <MayBeContainer
            onDrop={onDropText}
            classes={classes}
            containerPresent={(selectedTexts || []).every(({ original }) => !original) && !searchTerm}
          >
            {(selectedTexts || []).map(({ text, original = false, uuid, insertedByDefault }) => (
              <MayBeDraggable key={uuid} draggablePresent={!original && !searchTerm}>
                <MemorizedListItem
                  onChange={newText => updateTextInCategory(uuid, selectedCategoryUuid, newText)}
                  onDelete={() => deleteTextFromCategory(uuid, selectedCategoryUuid)}
                  onItemSelected={() => onMemorizedTextSelected(text, memo.val.enabledPersonalInfoReplacement)}
                  text={text}
                  original={original}
                  copyAction={copyTextAction}
                  readOnly={readOnly}
                  insertedByDefault={insertedByDefault}
                  setInsertedByDefault={b => setInsertedByDefaultTextInCategory(b, uuid, selectedCategoryUuid)}
                />
              </MayBeDraggable>
            ))}
          </MayBeContainer>
        </List>
      </Grid>
    </Grid>
  );
}

MemorizedTexts.defaultProps = {
  bigSize: false,
  showHistory: true,
  withCommandPanel: true,
  defaultSearchTerm: '',
  selectedCategoryUuid: null,
  readOnly: false
};

export default MemorizedTexts;
