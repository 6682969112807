import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import labels from '../../constants/labels/patient/report/ReportGeneration.json';

type Props = { warnings: Array<string> };

const Warning = ({ warnings }: Props) =>
  warnings &&
  !!warnings.length && (
    <Grid item md={12} container justifyContent="center">
      <Alert severity="warning">
        <AlertTitle>{labels.warning}</AlertTitle>
        {warnings.map(s => (
          <Typography key={s}>{s}</Typography>
        ))}
      </Alert>
    </Grid>
  );

const useWarnings = (defaultWarnings = []) => {
  const [warnings, setWarnings] = useState(defaultWarnings);

  const addWarning = async s => {
    await setWarnings(state => [...new Set([...state, s])]);
  };

  const clearWarnings = async () => {
    await setWarnings(() => []);
  };

  return [warnings, addWarning, clearWarnings];
};

export { Warning, useWarnings };
