import { createMuiTheme } from '@material-ui/core/styles';

const theme = ({ fontFamily }) =>
  createMuiTheme({
    palette: {
      background: {
        default: 'rgba(0,0,0,0)',
        paper: 'white'
      },
      primary: { main: '#477988' },
      secondary: { main: '#5bb5a7' }
    },
    typography: {
      fontFamily: [fontFamily].join(','),
      h5: {
        fontWeight: 700
      },
      h6: {
        fontWeight: 700
      }
    },
    overrides: {
      // Style sheet name ⚛️
      MuiButton: {
        // Name of the rule
        contained: {
          borderRadius: '24px'
        },
        outlined: {
          borderRadius: '24px',
          color: '#477988'
        }
      },
      MuiSvgIcon: {
        colorAction: {
          color: '#48a092'
        }
      },
      MuiPaginationItem: {
        page: {
          color: '#48a092'
        }
      },
      MuiTypography: {
        colorTextPrimary: {
          color: '#477988'
        }
      }
    }
  });

export default theme;
