import React from 'react';
import { Grid, IconButton, Switch, Tooltip, Typography } from '@material-ui/core';
import { Restore, Sync } from '@material-ui/icons';

import labels from '../../../constants/labels/configuration/tests/IncludedTestsOptions.json';
import SeuilEditor from './SeuilEditor';
import MathUtil from '../../common/Math';

type TestOption = {
  testCode: string,
  defaultPathologicalThreshold?: string,
  defaultDifficultyThreshold?: string,
  pathologicalThreshold?: string,
  difficultyThreshold?: string,
  defaultAutoConv: boolean,
  autoConv: boolean,
  modified: boolean,
  label: string,
  disableAutoconv: boolean,
  disabledThresholdConfig: boolean
};

type Props = {
  testOption: TestOption,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function
};

const IncludedTestOption = ({ testOption, onChange }: Props) => {
  const evaluateIfTestIsDefaultValue = (test: TestOption) =>
    !(
      test.autoConv === test.defaultAutoConv &&
      test.difficultyThreshold === test.defaultDifficultyThreshold &&
      test.pathologicalThreshold === test.defaultPathologicalThreshold
    );

  const convert = (threshold: string) => {
    const [unit, value] = threshold.split(':');
    const newUnit = unit === 'CENT' ? 'ET' : 'CENT';
    const newValue =
      unit === 'CENT' || unit === 'PC'
        ? MathUtil.percentileToStdDeviation(value)
        : MathUtil.stdDeviationToPercentile(value);
    return `${newUnit}:${newValue}`;
  };

  const resetDefault = () => {
    const resetedTestOptions = JSON.parse(JSON.stringify(testOption));
    resetedTestOptions.pathologicalThreshold = testOption.defaultPathologicalThreshold;
    resetedTestOptions.difficultyThreshold = testOption.defaultDifficultyThreshold;
    resetedTestOptions.autoConv = testOption.defaultAutoConv;
    resetedTestOptions.modified = false;
    onChange(resetedTestOptions);
  };

  const changeAutoConv = () => {
    const { ...modifiedTestOptions } = testOption;
    modifiedTestOptions.autoConv = !testOption.autoConv;
    modifiedTestOptions.modified = evaluateIfTestIsDefaultValue(modifiedTestOptions);
    onChange(modifiedTestOptions);
  };

  const changeSeuil = event => {
    const { ...modifiedTestOptions } = testOption;
    modifiedTestOptions[event.target.name] = event.target.value;
    modifiedTestOptions.modified = evaluateIfTestIsDefaultValue(modifiedTestOptions);
    onChange(modifiedTestOptions);
  };

  const changeUnit = () => {
    const { ...modifiedTestOptions } = testOption;
    modifiedTestOptions.pathologicalThreshold = convert(modifiedTestOptions.pathologicalThreshold);
    modifiedTestOptions.difficultyThreshold = convert(modifiedTestOptions.difficultyThreshold);
    modifiedTestOptions.modified = evaluateIfTestIsDefaultValue(modifiedTestOptions);
    onChange(modifiedTestOptions);
  };

  const {
    testCode,
    pathologicalThreshold,
    difficultyThreshold,
    autoConv,
    modified,
    label,
    disableAutoconv,
    disabledThresholdConfig
  } = testOption;
  const bgColor = modified ? 'yellow' : 'initial';
  return (
    <Grid item xs={12} container key={testCode} style={{ borderBottom: 'thin solid' }}>
      <Grid item xs={3}>
        <Typography variant="body1" style={{ backgroundColor: bgColor }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {!disableAutoconv && (
          <Tooltip title={labels.unitChange}>
            <IconButton onClick={changeUnit}>
              <Sync />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <SeuilEditor
        key={`${testCode}pathologicalThreshold${pathologicalThreshold}`}
        controlName="pathologicalThreshold"
        label=""
        value={pathologicalThreshold}
        onChangeHandler={changeSeuil}
        defaultValueCent=""
        defaultValueET=""
        defaultValueText=""
        displayChangeUnitButton={false}
        itemMdValue={2}
        disabled={disabledThresholdConfig}
        textFieldStyle={{ maxWidth: '70%', margin: 'auto 1em' }}
      />
      <SeuilEditor
        key={`${testCode}difficultyThreshold${difficultyThreshold}`}
        controlName="difficultyThreshold"
        label=""
        value={difficultyThreshold}
        onChangeHandler={changeSeuil}
        defaultValueCent=""
        defaultValueET=""
        defaultValueText=""
        displayChangeUnitButton={false}
        itemMdValue={2}
        disabled={disabledThresholdConfig}
        textFieldStyle={{ maxWidth: '70%', margin: 'auto 1em' }}
      />
      {!disableAutoconv && (
        <Grid item xs={3}>
          <Tooltip title={labels.autoConversion}>
            <Switch checked={autoConv} onChange={changeAutoConv} />
          </Tooltip>
        </Grid>
      )}
      {!disableAutoconv && (
        <Grid item xs={1}>
          {modified && (
            <Tooltip title={labels.defaultValues}>
              <IconButton onClick={resetDefault}>
                <Restore />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default IncludedTestOption;
