import React, { useState } from 'react';
import throttle from 'lodash/throttle';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import TextFieldFormControl from '../TextFieldFormControl';

const useStyles = makeStyles({
  searchField: {
    backgroundColor: 'white',
    borderRadius: '30px',
    marginTop: '4px',
    marginBottom: '4px',
    border: '#e8e8e8 1.5px solid;',
    '& > .MuiFilledInput-root': {
      backgroundColor: 'unset'
    },
    '& .MuiInputAdornment-root': {
      margin: 'auto !important'
    },
    '& .MuiFilledInput-input': {
      paddingTop: '8px',
      paddingBottom: '8px'
    }
  }
});

type Props = {
  q: string,
  itemMdValue?: number,
  placeholder: string | undefined,
  searchChange: Event => void
};

const SearchField = ({ q, searchChange, itemMdValue, placeholder }: Props) => {
  const classes = useStyles();
  const [myQ, setMyQ] = useState(q);

  const searchChangeThrottled = throttle(searchChange, 10000, { trailing: false });

  const onChange = event => {
    setMyQ(event.target.value);
    searchChangeThrottled(event);
  };

  const inputProps = {
    disableUnderline: true,
    startAdornment: (
      <InputAdornment position="start">
        <Search />
      </InputAdornment>
    )
  };

  if (myQ) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <IconButton onClick={() => onChange({ target: { value: '' } })}>
          <Clear />
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <TextFieldFormControl
      id="q"
      controlName="q"
      fullWidth
      itemMdValue={itemMdValue}
      variant="filled"
      className={classes.searchField}
      inputProps={inputProps}
      value={myQ}
      onChangeHandler={onChange}
      placeholder={placeholder}
    />
  );
};

SearchField.defaultProps = {
  itemMdValue: 6
};

export default SearchField;
