import React from 'react';
import { List } from '@material-ui/core';
import HomePatientListItem from './HomePatientListItem';
import AnamnesisResponses from '../common/data/AnamnesisResponses';

type Props = {
  patientBase: Array<{
    firstName: string,
    lastName: string,
    // eslint-disable-next-line flowtype/no-weak-types
    _id: any,
    // eslint-disable-next-line flowtype/no-weak-types
    _rev: any,
    // eslint-disable-next-line flowtype/no-weak-types
    rdvDay: any,
    // eslint-disable-next-line flowtype/no-weak-types
    rdvTime: any,
    // eslint-disable-next-line flowtype/no-weak-types
    anamnese: any,
    // eslint-disable-next-line flowtype/no-weak-types
    bilan: any
  }>,
  testFillingRowCompactDisplay: boolean,
  goToPatient: () => void,
  multiAction: boolean,
  anamnesisResponses: Array,
  multiActionSelectedPatientList: Array<string>,
  setMultiActionSelectedPatientList: (Array<string>) => void,
  refreshList: void => void
};

const HomePatientList = ({
  testFillingRowCompactDisplay,
  multiAction,
  goToPatient,
  setMultiActionSelectedPatientList,
  multiActionSelectedPatientList,
  patientBase,
  refreshList,
  anamnesisResponses
}: Props) => (
  <List style={{ width: '100%' }}>
    {patientBase.map(patient => (
      <HomePatientListItem
        key={`${patient._id}${patient._rev}`}
        patient={patient}
        testFillingRowCompactDisplay={testFillingRowCompactDisplay}
        multiAction={multiAction}
        goToPatient={goToPatient}
        setMultiActionSelectedPatientList={setMultiActionSelectedPatientList}
        multiActionSelectedPatientList={multiActionSelectedPatientList}
        refreshList={refreshList}
        patientHasAnamnesisResponseAvailable={AnamnesisResponses.patientHasResponseAvailable(anamnesisResponses, patient._id)}
      />
    ))}
  </List>
);

export default HomePatientList;
