import React, { useCallback, useEffect, useState } from 'react';
import { Divider, IconButton, Tooltip, Typography } from "@material-ui/core";
import { CloudUpload, Save } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import _ from "lodash";

import User from "../../common/data/User";
import labels from "../../../constants/labels/patient/report/ReportGraphics.json";
import SaveCancelRow from "../../common/SaveCancelRow";
import graphicTypes from "../../../constants/report/graphicTypes.json";
import log from "../../Logger";
import defaultOptions from "./ReportGraphicsDefaultOptions";
import { bilanHasExalang, bilanHasExamath58, bilanIsExalangOnly, bilanIsExamath58Only } from "../test/TestUtils";
import Patient from "../../common/data/Patient";
import { getHeaderAgeAndClassText } from "../../common/Header";
import PatientContentUtil from "../PatientContentUtil";
import ReportGraphicForm from "../../common/reportOptions/ReportGraphicForm";
import initColorResultEditors from "../../common/options/InitColorResultEditors";

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: `${theme.spacing(3)}px`
  },
  bold: {
    fontWeight: 'bold'
  },
  bgGreen: {
    backgroundColor: '#559944',
    '&:hover': {
      backgroundColor: '#2b9931'
    }
  },
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: `${theme.spacing(1)}px 0`
  },
  progression: { width: '16px' },
  height: {
    margin: '6px auto',
    height: '27px',
    width: 'fit-content'
  }
}));

type Props = {
  match: { params: { patientId: string } }
};

const ReportGraphics = ({
  match: {
    params: { patientId }
  }
}: Props) => {
  const history = useHistory();

  const [content, setContent] = useState({
    bilan: [],
    patient: {},
    options: defaultOptions
  });
  const { patient, bilan, options } = content;
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [gTypes, setGTypes] = useState(graphicTypes);

  useEffect(() => {
    const updateContent = async () => {
      try {
        const newContent = await PatientContentUtil.getContent(patientId);
        log.info({ newContent });
        setContent(newContent);
      } catch (err) {
        log.error('Error retrieving datas', err);
      }
    };

    updateContent().then();
  }, [patientId]);

  const change = useCallback(async event => {
    const options = _.set({ ...JSON.parse(JSON.stringify(content.options)) }, event.target.name, event.target.value);
    setContent({ ...JSON.parse(JSON.stringify(content)), options });
  }, [content]);

  useEffect(() => {
    const { bilan } = content;
    const hasExalang = bilanHasExalang(bilan);
    const hasExamath58 = bilanHasExamath58(bilan);
    if (hasExalang || hasExamath58) {
      setGTypes((graphicTypes || []).filter(({ id }) => id !== 'vertical-points'));
      if (options.mainChartFormat === 'vertical-points') {
        change({ target: { name: 'mainChartFormat', value: 'vertical-columns' } }).then();
      }
    }
  }, [change, content, options.mainChartFormat]);

  const handleSubmit = asDefault => async () => {
    const { bilan, options, patient } = content;
    await Patient.updatePatient({ ...patient, bilan: { ...bilan, reportOptions: options } });
    if (asDefault) {
      await User.saveReportGenerationOptions(options);
    }
    history.goBack();
  };

  const switchChange = name => event => {
    change({ target: { name, value: event.target.checked } }).then();
  };

  const switchLegendTypeChange = name => event => {
    change({ target: { name, value: event.target.checked ? 'legacy' : 'internal' } }).then();
  };

  const selectFont = () => {
    // TODO voir avec Rodrigo si font native ou pas.
  };

  const resetColors = async () => {
    const options = {
      ...JSON.parse(JSON.stringify(content.options)),
      gfxcolor1: defaultOptions.gfxcolor1,
      gfxcolor2: defaultOptions.gfxcolor2,
      gfxcolor3: defaultOptions.gfxcolor3,
      gfxcolor4: defaultOptions.gfxcolor4,
      gfxcolor5: defaultOptions.gfxcolor5,
      gfxcolor6: defaultOptions.gfxcolor6,
      legendOptions: {
        ...JSON.parse(JSON.stringify(content.options.legendOptions)),
        aboveAverage: defaultOptions.legendOptions.aboveAverage,
        averageHigh: defaultOptions.legendOptions.averageHigh,
        averageLow: defaultOptions.legendOptions.averageLow,
        average: defaultOptions.legendOptions.average,
        belowAverage: defaultOptions.legendOptions.belowAverage,
        pathological: defaultOptions.legendOptions.pathological,
        srslypathological: defaultOptions.legendOptions.srslypathological
      }
    };
    setContent({ ...JSON.parse(JSON.stringify(content)), options });
  };

  const classes = useStyles();
  const exalangOnly = bilanIsExalangOnly(bilan);
  const examath58Only = bilanIsExamath58Only(bilan);
  const colorResultEditors = initColorResultEditors(options, exalangOnly, examath58Only);

  const renewal = !!bilan.initialAssessmentUUID;
  log.info({ content });
  log.info({ options });
  const ageAndClassText = getHeaderAgeAndClassText(patient.curclasse, patient.birthDay);

  return (
    <div>
      <Typography variant="h3">{labels.title}</Typography>
      <Typography variant="h4">
        {labels.subTitle.part0}{' '}
        {bilan.renewalNumber ? labels.subTitle.renewal + bilan.renewalNumber : labels.subTitle.initial}{' '}
        {labels.subTitle.part1}{' '}
        <span className={classes.bold}>
          {patient.firstName} {patient.lastName}
        </span>{' '}
        ({ageAndClassText})
      </Typography>
      <Divider/>
      <SaveCancelRow
        cancelAction={() => history.goBack()}
        saveAction={handleSubmit(false)}
        saveIcon={<Save/>}
        saveTooltip={labels.save.tooltip}
        moreActionsMenu={[
          {
            key: 'entry0',
            element: (
              <Tooltip title={labels.saveAsDefault.tooltip}>
                <IconButton onClick={handleSubmit(true)} variant="contained" color="primary">
                  <CloudUpload/>
                </IconButton>
              </Tooltip>
            )
          }
        ]}
      />
      <Divider/>
      <ReportGraphicForm
        content={content}
        options={options}
        change={change}
        gTypes={gTypes}
        setShowAdvancedOptions={setShowAdvancedOptions}
        showAdvancedOptions={showAdvancedOptions}
        switchChange={switchChange}
        renewal={renewal}
        switchLegendTypeChange={switchLegendTypeChange}
        colorResultEditors={colorResultEditors}
        resetColors={resetColors}
        selectFont={selectFont}
      />
    </div>
  );
};

export default ReportGraphics;
