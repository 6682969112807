import React from 'react';
import RichTextEditor from 'react-rte';
import {
  BLOCK_ALIGNMENT_BUTTONS,
  BLOCK_COLOR_BUTTONS,
  BLOCK_TYPE_BUTTONS,
  BLOCK_TYPE_DROPDOWN,
  INLINE_STYLE_BUTTONS
} from 'react-rte/lib/lib/EditorToolbarConfig';

// eslint-disable-next-line react/prop-types
const MyRichTextEditor = ({ editorRef, withColors, ...props }) => {
  const toolbarConfig = {
    display: [
      'INLINE_STYLE_BUTTONS',
      'BLOCK_ALIGNMENT_BUTTONS',
      'BLOCK_TYPE_BUTTONS',
      'LINK_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS'
    ],
    INLINE_STYLE_BUTTONS,
    BLOCK_ALIGNMENT_BUTTONS,
    BLOCK_TYPE_DROPDOWN,
    BLOCK_TYPE_BUTTONS
  };

  if (withColors) {
    toolbarConfig.display.push('COLOR_BUTTONS');
    toolbarConfig.BLOCK_COLOR_BUTTONS = BLOCK_COLOR_BUTTONS;
  }

  return <RichTextEditor toolbarConfig={toolbarConfig} ref={editorRef} {...props} />;
};

export default MyRichTextEditor;
