const toCalculationResult = ({ p, v }, labelPrefix: string = '') => ({
  label: `${labelPrefix} ${p.toString()}`,
  value: p,
  score: v
});

const findAscending = (value, pctTable) => {
  const workTable = JSON.parse(JSON.stringify(pctTable));
  const returnValue = { sign: '', percentileValue: '', label: '' };
  workTable.sort((a, b) => (a.v === b.v ? a.p - b.p : a.v - b.v));
  workTable.push({ p: 100, v: Infinity });
  for (let i = 0; i < workTable.length - 1; i += 1) {
    if (i === 0 && value < workTable[i].v) {
      returnValue.sign = '<';
      returnValue.percentileValue = workTable[i].p;
      // console.log("case1 - less then minimal value");
      break;
    } else if (value === workTable[i].v) {
      returnValue.percentileValue = workTable[i].p;
      // console.log("case2 - exact value");
      // no break the higher percentile is taken
    } else if (i === workTable.length - 2 && value > workTable[i - 1].v) {
      returnValue.sign = '>';
      returnValue.percentileValue = workTable[i].p;
      // console.log("case3 -sup than max");
      break;
    } else if (value > workTable[i].v && value < workTable[i + 1].v) {
      returnValue.percentileValue = workTable[i + 1].p;
      // console.log("case4 - in between, taking superior value");
      break;
    }
  }
  returnValue.label = `${returnValue.sign} ${returnValue.percentileValue}`;
  return returnValue;
};

const findDescending = (value, pctTable) => {
  const workTable = JSON.parse(JSON.stringify(pctTable));
  const returnValue = { sign: '', percentileValue: '', label: '' };
  workTable.sort((a, b) => (a.v === b.v ? a.p - b.p : a.v - b.v));
  workTable.unshift({ p: 100, v: -Infinity });
  for (let i = workTable.length - 1; i > 0; i -= 1) {
    if (i === workTable.length - 1 && value > workTable[i].v) {
      returnValue.sign = '<';
      returnValue.percentileValue = workTable[i].p;
      // console.log("case1 - less then minimal value");
      break;
    } else if (value === workTable[i].v) {
      returnValue.percentileValue = workTable[i].p;
      // console.log("case2 - exact value");
      // no break the higher percentile is taken
    } else if (i === 1 && value < workTable[i].v) {
      returnValue.sign = '>';
      returnValue.percentileValue = workTable[i].p;
      // console.log("case3 -sup than max");
      break;
    } else if (value < workTable[i].v && value > workTable[i - 1].v) {
      returnValue.percentileValue = workTable[i - 1].p;
      // console.log("case4 - in between, taking superior value");
      break;
    }
  }
  returnValue.label = `${returnValue.sign} ${returnValue.percentileValue}`;
  return returnValue;
};

const findAscendingBest = (value, pctTable) => {
  const workTable = JSON.parse(JSON.stringify(pctTable));
  if (workTable.length === 0) {
    return {};
  }
  let bestIndex = 0;
  let label = '';

  for (let i = 0; i < workTable.length; i += 1) {
    const { v } = workTable[i];
    if (i === 0 && value < v) {
      bestIndex = 0;
      break;
    } else if (value >= v) {
      bestIndex = i + 1;
    } else if (value < v) {
      break;
    }
  }

  if (bestIndex >= workTable.length) {
    bestIndex = workTable.length - 1;
    label = '>';
  }
  return toCalculationResult(workTable[bestIndex], label);
};

const findDescendingBest = (value, pctTable) => {
  const workTable = JSON.parse(JSON.stringify(pctTable));
  if (workTable.length === 0) {
    return {};
  }
  let bestIndex = 0;
  let label = '';

  for (let i = 0; i < workTable.length; i += 1) {
    const { v } = workTable[i];
    if (i === 0 && value > v) {
      bestIndex = 0;
      break;
    } else if (value <= v) {
      bestIndex = i + 1;
    } else if (value > v) {
      break;
    }
  }
  if (bestIndex >= workTable.length) {
    bestIndex = workTable.length - 1;
    label = '>';
  }
  return toCalculationResult(workTable[bestIndex], label);
};

const findTimeDescendingBest = (value, pctTable) => {
  const workTable = JSON.parse(JSON.stringify(pctTable));
  if (workTable.length === 0) {
    return {};
  }
  let bestIndex = 0;
  let label = '';

  for (let i = 0; i < workTable.length; i += 1) {
    const { v } = workTable[i];
    if (i === 0 && value > v) {
      bestIndex = 0;
      break;
    } else if (value < v) {
      bestIndex = i + 1;
    } else if (value >= v) {
      break;
    }
  }
  if (bestIndex >= workTable.length) {
    bestIndex = workTable.length - 1;
    label = '>';
  }
  return toCalculationResult(workTable[bestIndex], label);
};

const findByMiddle = (value, pctTable, indexOfpc50) => {
  const workTable = JSON.parse(JSON.stringify(pctTable));
  let last = workTable[indexOfpc50];

  if (value === last.v) {
    return { label: last.p.toString(), value: last.p, score: last.v };
  }

  const step = value > last.v ? 1 : -1;
  for (let i = indexOfpc50 + step, len = workTable.length; i >= 0 && i < len; i += step) {
    const percentileObject = workTable[i];
    if (percentileObject.v === value) {
      return toCalculationResult(percentileObject);
    }
    if ((step > 0 && value < percentileObject.v) || (step < 0 && value > percentileObject.v)) {
      return toCalculationResult(last);
    }
    last = percentileObject;
  }

  return toCalculationResult(last);
};

const findIndexOfPc50OrClosest = pctTable => {
  const workTable = JSON.parse(JSON.stringify(pctTable));
  let foundIndex = 0;
  let currentDifferenceTo50 = 999;
  workTable.forEach((u, index: number) => {
    const diff = Math.max(u.p, 50) - Math.min(u.p, 50);
    if (diff < currentDifferenceTo50) {
      foundIndex = index;
      currentDifferenceTo50 = diff;
    }
  });
  return foundIndex;
};

export {
  findAscending,
  findDescending,
  findAscendingBest,
  findDescendingBest,
  findTimeDescendingBest,
  findByMiddle,
  findIndexOfPc50OrClosest
};
