import { jsonDownload } from '../../../import/utils/HappyneuronFileDownloader';
import happyneuronExalangClassToOrthoscribeClasse from '../HappyneuronExalangClassToOrthoscribeClasse';
import DEFAULT_HN_CLASSES from '../DEFAULT_HN_CLASSES.json';

const parseFile = async ({filePath, userId, ticket, programId}) => {
  const evadys = await jsonDownload({userId, ticket, programId});
  console.log("evalec", {evadys, filePath})
  const classe = getEvadysClass(evadys.user);

  return evadys.workouts.map(workout => ({
    firstname: evadys.user.firstName,
    lastname: evadys.user.lastName,
    birthDate: evadys.user.birthDate,
    passationDate: workout.startDate,
    passationNumber: workout.id,
    data: { workout, scores: evadys.scores },
    hnWorkoutId: workout.id,
    classe,
    text: `${evadys.user.firstName} ${evadys.user.lastName} (${workout.startDate})`
  }));
};

const getEvadysClass = user => {
  const { code } = happyneuronExalangClassToOrthoscribeClasse(DEFAULT_HN_CLASSES, user.class);
  if (['PSM', 'MSM', 'GSM', 'CP', 'CE1', 'CE2', 'CM1'].indexOf(code) !== -1) {
    return code;
  }
  if (code === 'CM2') {
    return `CM2 (${user.gender === 'H' ? 'Garçon' : 'Fille'})`;
  }
  if (['6EME', '5EME', '4EME', '3EME'].indexOf(code) !== -1) {
    return `Collège (${user.gender === 'H' ? 'Garçon' : 'Fille'})`;
  }
  return `Lycée, adulte (${user.gender === 'H' ? 'Homme' : 'Femme'})`;
};

export default parseFile;
