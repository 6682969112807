import React, { useState } from 'react';
import { Divider, Grid, MenuItem } from '@material-ui/core';
import Age from './Age';
import TextFieldFormControl from '../../../TextFieldFormControl';

const relations = [
  { label: 'Frère', id: 'frere', sex: 'm' },
  { label: 'Soeur', id: 'soeur', sex: 'f' },
  { label: 'Demi-frère', id: 'demifrere', sex: 'm' },
  { label: 'Demi-soeur', id: 'demisoeur', sex: 'f' }
];

const FratrieDetails = ({ id, index, ageValue, relation, name, changeHandler, myValue }: Props) => {
  const [internalName, setInternalName] = useState(name);

  const onChangeAge = ageIndex => event => {
    const value = JSON.parse(JSON.stringify(myValue));
    value.detail[ageIndex].age = event.target.value.value;
    value.detail[ageIndex].unit = event.target.value.unit;
    changeHandler({ target: { name: id, value } });
  };

  const onChangeRelation = ageIndex => event => {
    const value = JSON.parse(JSON.stringify(myValue));
    // eslint-disable-next-line prefer-destructuring
    value.detail[ageIndex].relation = relations.filter(({ id: relationId }) => relationId === event.target.value)[0];
    changeHandler({ target: { name: id, value } });
  };

  const onBlur = () => {
    const value = JSON.parse(JSON.stringify(myValue));
    value.detail[index].name = internalName;
    changeHandler({ target: { name: id, value } });
  };

  return (
    <Grid key={index} item xs={12} container>
      <Grid xs={12} item>
        <Divider />
      </Grid>
      <Age
        span={3}
        label=""
        id={`fratrie[${index}].age`}
        unit="ans"
        myValue={ageValue}
        changeHandler={onChangeAge(index)}
      />
      <Grid item xs={1} />
      <TextFieldFormControl
        id={`fratrie.detail[${index}].name`}
        controlName={`detail[${index}].name`}
        itemMdValue={4}
        value={internalName}
        fullWidth
        onChangeHandler={e => setInternalName(e.target.value)}
        onBlurHandler={onBlur}
        style={{ marginBottom: '2rem' }}
      />
      <Grid item xs={2} />
      <TextFieldFormControl
        id={`fratrie.detail[${index}].relation`}
        controlName={`detail[${index}].relation`}
        itemMdValue={2}
        value={relation.id}
        fullWidth
        select
        onChangeHandler={onChangeRelation(index)}
        style={{ marginBottom: '2rem' }}
      >
        {relations.map(({ label: relationLabel, id: relationId }) => (
          <MenuItem key={relationId} value={relationId}>
            {relationLabel}
          </MenuItem>
        ))}
      </TextFieldFormControl>
    </Grid>
  );
};

export default FratrieDetails;
