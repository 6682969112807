import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SwapHoriz } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import labels from '../../../constants/labels/patient/RenewalCheckupLinkRemoval.json';
import SaveCancelRow from '../../common/SaveCancelRow';
import routes from '../../../constants/routes';
import Patient from '../../common/data/Patient';

const styles = {
  bold: {
    fontWeight: 'bold'
  }
};

type Props = {
  match: { params: { patientId: string } },
  // eslint-disable-next-line flowtype/no-weak-types
  classes: any,
  history: {
    push: string => void
  }
};

class RenewalCheckupLinkRemoval extends Component<Props> {
  props: Props;

  state = {
    patient: {},
    patientInit: {
      firstName: '',
      lastName: ''
    }
  };

  async componentDidMount() {
    const {
      match: {
        params: { patientId }
      }
    } = this.props;
    const patient = await Patient.retrievePatient(patientId);
    const { bilan } = patient;

    const patientInit = await Patient.retrievePatient(patient.initialPatientId);

    this.setState({ patient, bilan, patientInit });
  }

  detachCheckup = async () => {
    const {
      history,
      match: {
        params: { patientId }
      }
    } = this.props;
    const { bilan, patient } = this.state;
    bilan.initialAssessmentUUID = null;
    bilan.initialAssessmentTests = null;
    bilan.renewalNumber = null;

    patient.typeBilan = 'initial';
    await Patient.updatePatient({ ...patient, bilan });
    history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId }));
  };

  render() {
    const {
      classes,
      match: {
        params: { patientId }
      }
    } = this.props;
    const {
      patient: { firstName, lastName, rdvDay },
      patientInit
    } = this.state;

    return (
      <Grid container>
        <SaveCancelRow
          cancelRoute={routes.PATIENT.getForPath({
            path: routes.PATIENT.CONSULT,
            patientId
          })}
          saveAction={this.detachCheckup}
          saveTooltip={labels.saveLabel}
          saveIcon={<SwapHoriz />}
        />
        <Grid item xs={12}>
          <Typography variant="h3">{labels.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            {labels.subTitle.part0} <span className={classes.bold}>{labels.subTitle.part1}</span>{' '}
            {labels.subTitle.part2}
          </Typography>
          <ul>
            <li>
              {firstName} {lastName}
            </li>
            <li>
              {labels.checkupDate} {new Date(rdvDay).toLocaleDateString()}
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{labels.previousCheckup}</Typography>
          <ul>
            <li>
              {patientInit.firstName} {patientInit.lastName}
            </li>
          </ul>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(RenewalCheckupLinkRemoval);
