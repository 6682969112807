import React, { Fragment } from "react";
import { Button, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Save, Undo } from '@material-ui/icons';

import commonLabels from '../../constants/Labels.json';
import MoreActionsMenu from '../home/MoreActionsMenu';
import routes from '../../constants/routes';
import BackButton from './BackButton';

type MoreActionMenuEntry = {
  element: React.Node,
  key: string,
  // eslint-disable-next-line flowtype/no-weak-types
  onClick: Function | void
};

type Props = {
  cancelRoute?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  saveAction?: ?Function,
  // eslint-disable-next-line flowtype/no-weak-types
  cancelAction?: Function,
  moreActionsMenu?: ?Array<MoreActionMenuEntry>,
  title?: string | React.ReactComponentElement,
  saveIcon?: React.Node,
  saveButtonLabel?: string,
  saveTooltip?: ?string,
  saveActionDisabled?: boolean
};

const SaveCancelRowBackButton = ({cancelAction, cancelRoute}: {
  // eslint-disable-next-line flowtype/no-weak-types
  cancelAction: Function | undefined,
  cancelRoute: string
}) => {
  if (cancelAction) {
    return <IconButton onClick={cancelAction}><Undo /></IconButton>;
  }
  return <BackButton backRoute={cancelRoute} />
}

const SaveCancelRow = ({
  cancelRoute,
  saveAction,
  saveIcon,
  moreActionsMenu,
  cancelAction,
  title,
  saveButtonLabel,
  saveActionDisabled,
  saveTooltip
}: Props) => {
  const saveButton = (
    <Button disabled={saveActionDisabled} variant="contained" color="primary" onClick={saveAction} startIcon={saveIcon}>
      {saveButtonLabel}
    </Button>
  );

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"

    >
      <Grid item xs={2}>
        <Tooltip title={commonLabels.backButton}>
          <Fragment>
            <SaveCancelRowBackButton cancelAction={cancelAction} cancelRoute={cancelRoute}/>
          </Fragment>
        </Tooltip>
      </Grid>
      {title &&
        <Grid item xs={8} container justifyContent="center">
          {typeof title === "string" ? <Typography variant="h5">{title}</Typography> : title}
        </Grid>
      }
      {saveAction && (
        <Grid item xs={2} container justifyContent="flex-end">
          {saveTooltip ? <Tooltip title={saveTooltip}><Fragment>{saveButton}</Fragment></Tooltip> : saveButton}
          {moreActionsMenu && moreActionsMenu.length === 1 && moreActionsMenu[0].element}
          {moreActionsMenu && moreActionsMenu.length > 1 && <MoreActionsMenu menuItemEntries={moreActionsMenu} />}
        </Grid>
      )}
    </Grid>
  );
};

SaveCancelRow.defaultProps = {
  saveActionDisabled: false,
  moreActionsMenu: null,
  cancelAction: undefined,
  saveAction: undefined,
  cancelRoute: routes.HOME,
  title: '',
  saveIcon: <Save />,
  saveButtonLabel: commonLabels.saveButton,
  saveTooltip: null
};
export default SaveCancelRow;
