import React, { useState } from 'react';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import labels from '../../../constants/labels/patient/anamnesis/AnamnesisMailImportFile.json';
import AnamnesisFileContentImport from './AnamnesisFileContentImport';

const AnamnesisMailImportFile = () => {
  const [filename, setFilename] = useState("");
  const [fileContent, setFileContent] = useState();

  const parseFile = async (filePath) => {
    if (filePath) {
      setFilename(filePath.name);
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const content = new TextDecoder().decode(fileReader.result);
        setFileContent(content);
      };
      fileReader.readAsArrayBuffer(filePath);
    }
  };

  return (
    <div>
      <Typography variant="h6">{labels.mainTitle}</Typography>
      <Typography variant="subtitle1">{labels.subTitle}</Typography>
      <Divider />
      {!filename &&
        <Grid item md={12}>
          <input
            id="contained-button-file"
            type="file"
            hidden
            onChange={(event) => {
              const filePath = event.target.files[0]
              if (!filePath) {
                return;
              }
              parseFile(filePath)
            }}
            accept=".osa"
          />
          <label htmlFor="contained-button-file">
            <Button variant="outlined" component="span">
              {labels.selectFile}
            </Button>
          </label>
        </Grid>
      }
      {!!filename &&<AnamnesisFileContentImport fileContent={fileContent} filename={filename} /> }
    </div>
);
};

export default AnamnesisMailImportFile;
