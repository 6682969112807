import parse from 'json-templates';
import calculateRawResult from './CalculateRawResult';
import EVADYS from '../../../../../constants/tests/EVADYS.json';

// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
const executeImport = ({ data, classe }) => {
  if (data && data.workout) {
    const calculationElements = EVADYS.calcul.reduce((prev, cur) => (cur.id === classe ? cur : prev), null);
    const values = EVADYS.children
      .map(({ id }) => calculateRawResult(id, data.workout, data))
      .filter(Boolean)
      .reduce((acc, { eprId, score, annotation }) => {
        if (labelRules[eprId] && calculationElements.data[eprId]) {
          console.log({ eprId, calculationElements });
          const comment = labelRules[eprId](score, calculationElements.data[eprId]);
          return { ...acc, [eprId]: { score, comment } };
        }
        return { ...acc, [eprId]: { score, comment: annotation } };
      }, {});

    Object.keys(compositeScores).forEach(totalKey => {
      const eprIds = compositeScores[totalKey];
      const scores = eprIds.map(eprId => values[eprId].score);
      if (scores.every(rawScore => !!rawScore)) {
        const value = average(scores);
        values[totalKey].score = value;
        const text = resultatGlobal(value, calculationElements.data[totalKey]);
        values[totalKey].comment = values[totalKey].comment ? `${values[totalKey].comment} <br> ${text}` : text;
      } else {
        values[totalKey].score = undefined;
      }
    });

    const result = { count: Object.entries(values).length, values, calculOption: classe, calculOptionType: 'classe' };
    console.log({ result });
    return result;
  }
};

const average = arr => Math.round((arr.reduce((p, c) => p + c, 0) / arr.length) * 100) / 100;

const compositeScores = {
  EVADYS_206: ['EVADYS_629_240', 'EVADYS_630_243'],
  EVADYS_238: ['EVADYS_629_241', 'EVADYS_630_244'],
  EVADYS_239: ['EVADYS_629_242', 'EVADYS_630_245']
};

const seuilDeLettreText = (seuil, calculationTable) => {
  const [p10Percentile] = calculationTable.pct.filter(({ p }) => p === 10);
  if (seuil === 150) {
    return labels.seuilDeLettresNonCalculable;
  }
  if (seuil < 150 && seuil > p10Percentile.v) {
    return labels.seuilDeLettresCalculable;
  }
  return labels.seuilDeLettresDefaut;
};

const empanReportGlobal5Lettres = (empanReportGlobal, calculationTable) => {
  const [p5Percentile] = calculationTable.pct.filter(({ p }) => p === 5);
  const [p15Percentile] = calculationTable.pct.filter(({ p }) => p === 15);
  if (empanReportGlobal < p5Percentile.v) {
    return parse(labels.reportGlobalSeuil5Pourcent)({empanReportGlobal});
  }
  if (empanReportGlobal < p15Percentile.v) {
    return parse(labels.reportGlobalSeuil15Pourcent)({empanReportGlobal});
  }
  return parse(labels.reportGlobalDefaut)({empanReportGlobal});
};

const empanReportPartiel5Lettres = (empanReportPartiel, calculationTable) => {
  const [p5Percentile] = calculationTable.pct.filter(({ p }) => p === 5);
  const [p15Percentile] = calculationTable.pct.filter(({ p }) => p === 15);
  if (empanReportPartiel < p5Percentile.v) {
    return parse(labels.reportPartielSeuil5Pourcent)({empanReportPartiel});
  }
  if (empanReportPartiel < p15Percentile.v) {
    return parse(labels.reportPartielSeuil15Pourcent)({empanReportPartiel});
  }
  return parse(labels.reportPartielDefaut)({empanReportPartiel});
};

const resultatGlobal = (moyenneEmpanComposite: number, calculationTable) => {
  const [p5Percentile] = calculationTable.pct.filter(({ p }) => p === 5);
  const [p15Percentile] = calculationTable.pct.filter(({ p }) => p === 15);
  if (moyenneEmpanComposite < p5Percentile.v) {
    return parse(labels.empanCompositeSeuil5Pourcent)({moyenneEmpanComposite});
  }
  if (moyenneEmpanComposite < p15Percentile.v) {
    return parse(labels.empanCompositeSeuil15Pourcent)({moyenneEmpanComposite});
  }
  return parse(labels.empanCompositeDefaut)({moyenneEmpanComposite});
};

const labelRules = {
  EVADYS_629_240: empanReportGlobal5Lettres,
  EVADYS_630_243: empanReportPartiel5Lettres,
  EVADYS_629_242: empanReportGlobal5Lettres,
  EVADYS_630_245: empanReportPartiel5Lettres,
  EVADYS_629_241: empanReportGlobal5Lettres,
  EVADYS_630_244: empanReportPartiel5Lettres,
  EVADYS_631_200: seuilDeLettreText
};

const labels = {
  seuilDeLettresNonCalculable:
    "Le seuil du patient ne peut pas être calculé puisque moins de 80% des lettres sont correctement identifiées au temps maximum d'exposition de 101 ms. Ceci traduit des difficultés dès l'identification des lettres isolées. De faibles performances observées dans ce contexte sur les épreuves de report global et partiel pourraient être la conséquence du trouble de l'identification des lettres isolées sans atteinte de l'empan visuo-attentionnel.",
  seuilDeLettresCalculable:
    "Le seuil du patient se situe parmi les performances les plus faibles pour sa tranche d'âge, ce qui traduit des difficultés dès l'identification des lettres isolées. Même si des difficultés sont observées sur les épreuves de report global et partiel,on ne pourra conclure avec certitude à un déficit de l'empan visuo-attentionnel.",
  seuilDeLettresDefaut: "Le patient ne présente pas de trouble d'identification de lettres isolées.",
  reportPartielSeuil5Pourcent:
    "Avec un empan visuo-attentionnel pour le report partiel de {{empanReportPartiel}}, le patient fait partie des 5% des individus les plus faibles de cette épreuve dans la tranche d'âge considérée.",
  reportPartielSeuil15Pourcent:
    'Avec un empan visuo-attentionnel pour le report partiel de {{empanReportPartiel}}, le patient fait partie des 15% des individus les moins performants de cette épreuve.',
  reportPartielDefaut: 'Avec un empan visuo-attentionnel pour le report partiel de {{empanReportPartiel}}, le patient se situe dans la norme des témoins ou au-dessus.',
  empanCompositeSeuil5Pourcent:
    "Avec un empan visuo-attentionnel composite de {{moyenneEmpanComposite}}, le patient a obtenu un score inférieur au cinquième centile. Il présente un trouble de l'empan visuo-attentionnel.",
  empanCompositeSeuil15Pourcent:
    'Avec un empan visuo-attentionnel composite de {{moyenneEmpanComposite}}, le patient a obtenu un score inférieur au quinzième centile. Il fait partie des individus qui présentent un empan visuo-attentionnel faible.',
  empanCompositeDefaut: "Avec un empan visuo-attentionnel composite de {{moyenneEmpanComposite}}, le patient  ne présente pas de trouble de l'empan visuo-attentionnel",
  reportGlobalSeuil5Pourcent:
    "Avec un empan visuo-attentionnel pour le report global de {{empanReportGlobal}}, le patient fait partie des 5% des individus les plus faibles de cette épreuve dans la tranche d'âge considérée.",
  reportGlobalSeuil15Pourcent:
    "Avec un empan visuo-attentionnel pour le report global de {{empanReportGlobal}}, le patient fait partie des 15% des individus les moins performants de cette épreuve dans la tranche d'âge considérée.",
  reportGlobalDefaut: 'Avec un empan visuo-attentionnel pour le report global de {{empanReportGlobal}}, le patient se situe dans la norme des témoins ou au-dessus.',
};

export default executeImport;
