import { shouldHideRow } from './TestUtils';

function filterCheckupModelTestEntryByItsVisibility(testValuesByTestCode) {
  return ({ testCode, id }) =>
    testValuesByTestCode[testCode].currentTestResults[id] &&
    !testValuesByTestCode[testCode].currentTestResults[id].$$HIDDENFROMVIEW;
}

function filterCheckupModelTestEntryByItsAvailabilityOnCalculationTable(testValuesByTestCode) {
  return ({ testCode, id }) =>
    testValuesByTestCode[testCode].examinations.filter(({ id: examinationId }) => examinationId === id).length === 1;
}

function mapWithChilds(elements) {
  return element => {
    const nextElementOnSameLevel = elements
      .filter(({ index, level }) => level === element.level && index > element.index)
      .shift() || {
      level: element.level,
      index: elements.length + 1
    };
    const childLessChilds = elements.filter(
      ({ index, level }) => index > element.index && index < nextElementOnSameLevel.index && level === element.level + 1
    );
    const childs = childLessChilds.map(mapWithChilds(elements));
    return { childs, ...element };
  };
}

function elementOrHisChildHasTestInCurrentSelection(testValuesByTestCode, hideNonFilledTests) {
  return props => {
    const { tests, childs } = props;
    const currentElementHasTest = !!tests
      .filter(({ testCode }) => Object.keys(testValuesByTestCode).includes(testCode))
      .filter(filterCheckupModelTestEntryByItsVisibility(testValuesByTestCode))
      .filter(filterCheckupModelTestEntryByItsAvailabilityOnCalculationTable(testValuesByTestCode))
      .filter(({ id, testCode }) =>
        shouldHideRow(hideNonFilledTests, testValuesByTestCode[testCode].currentTestResults)({ id })
      ).length;

    const childHasTest = !!childs.filter(
      elementOrHisChildHasTestInCurrentSelection(testValuesByTestCode, hideNonFilledTests)
    ).length;

    return currentElementHasTest || childHasTest;
  };
}

function filterChilds({ childs, ...element }, testValuesByTestCode, hideNonFilledTests) {
  const filteredChilds = childs
    // eslint-disable-next-line react/prop-types
    .filter(elementOrHisChildHasTestInCurrentSelection(testValuesByTestCode, hideNonFilledTests))
    .map(e => filterChilds(e, testValuesByTestCode, hideNonFilledTests));

  if (
    elementOrHisChildHasTestInCurrentSelection(
      testValuesByTestCode,
      hideNonFilledTests
    )({ childs: filteredChilds, ...element })
  ) {
    return { childs: filteredChilds, ...element };
  }
  return undefined;
}

export {
  filterCheckupModelTestEntryByItsVisibility,
  filterCheckupModelTestEntryByItsAvailabilityOnCalculationTable,
  mapWithChilds,
  elementOrHisChildHasTestInCurrentSelection,
  filterChilds
};
