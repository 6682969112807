import uuidv4 from 'uuid/v4';
import localDatabases from '../../../database/database';
import log from '../../Logger';
import { mergeAndDeduplicateMemos, reduceData, getKeyFromTestCodeAndId, cleanNodesDuplicateIds } from './MemoUtil';

const defaultValue = {
  val: {
    history: [],
    enabledPersonalInfoReplacement: true,
    nodes: [],
    texts: []
  }
};

const defaultNode = {
  canModify: true,
  label: 'Par défaut',
  texts: []
};

class Memo {
  static TYPE = 'memo';

  static MAX_HISTORY: number = 10;

  static async deleteAll() {
    try {
      const memosFromDb = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id: { $gte: null },
          dbtype: Memo.TYPE
        }
      });
      log.info({ memosFromDb });
      // eslint-disable-next-line no-restricted-syntax
      for (const memo of memosFromDb.docs) {
        localDatabases.getInstance().v1databaseRepository.remove(memo);
      }
    } catch (err) {
      log.info('unable to delete memo', { err });
    }
  }

  static async findAll() {
    try {
      const memosFromDb = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id: { $gte: null },
          dbtype: Memo.TYPE
        }
      });
      // log.info({ memosFromDb });
      const memos = memosFromDb.docs || [];
      const updatedMemos = memos
        .map(({ _id, k, ...memo }) => ({
          ...memo,
          _id: Memo.getId(_id),
          k: k || Memo.getId(_id)
        }))
        .map(cleanNodesDuplicateIds);
      log.info('Memo.findAll(), found docs : ');
      log.table(updatedMemos);
      return updatedMemos;
    } catch (err) {
      log.error('Error retrieving memos', err);
      throw err;
    }
  }

  static setId(id) {
    if (id && typeof id === 'string' && id.startsWith(Memo.TYPE)) {
      return id;
    }
    return Memo.TYPE + id;
  }

  static getId(id) {
    if (id && typeof id === 'string' && id.startsWith(Memo.TYPE)) {
      return id.substring(Memo.TYPE.length);
    }
    return id;
  }

  static async findByKey(key) {
    try {
      const _id = Memo.setId(key);
      const documents = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id,
          dbtype: Memo.TYPE
        }
      });
      let {
        docs: [memoFromDb]
      } = documents;
      if (!memoFromDb || !memoFromDb.val) {
        memoFromDb = {};
        memoFromDb.val = { ...defaultValue.val };
        memoFromDb._id = key;
        memoFromDb.k = key;
        memoFromDb.dbtype = Memo.TYPE;
      }
      if (!memoFromDb.val.nodes || !memoFromDb.val.nodes.length) {
        const node = { ...defaultNode, uuid: uuidv4() };
        memoFromDb.val.nodes = [node];
      }
      return cleanNodesDuplicateIds(memoFromDb);
    } catch (err) {
      log.error('Error retrieving memos', err);
      throw err;
    }
  }

  static async listKeyContainingString(stringContainedInKey) {
    try {
      const memosFromDb = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id: { $regex: new RegExp(stringContainedInKey) },
          dbtype: Memo.TYPE
        }
      });
      log.info({ memosFromDb });
      const memos = memosFromDb.docs || [];
      const updatedMemos = memos.map(({ _id, k, ...memo }) => ({
        ...memo,
        _id: Memo.getId(_id),
        k: k || Memo.getId(_id)
      }));
      // log.info('Memo.findAll(), found docs : ');
      // log.table(updatedMemos);
      return updatedMemos;
    } catch (err) {
      log.error('Error retrieving memos', err);
      throw err;
    }
  }

  static async save(memoToSave) {
    try {
      const { val, _id } = JSON.parse(JSON.stringify(memoToSave));
      const memoFromDb = await Memo.findByKey(_id);
      memoFromDb._id = Memo.setId(_id);
      const updatedMemo = await localDatabases.getInstance().v1databaseRepository.put({ ...memoFromDb, val });
      memoFromDb.dbtype = Memo.TYPE;
      memoFromDb._id = _id;
      memoFromDb.k = _id;
      memoFromDb._rev = updatedMemo.rev;
      memoFromDb.val = val;
      return memoFromDb;
    } catch (err) {
      log.error('Error saving Memo', err);
      throw err;
    }
  }

  static findCategoryUuidByLabel(memo, categoryLabel) {
    const category = memo.val.nodes.find(({ label }) => label === categoryLabel);
    if (category) {
      return category.uuid;
    }
    return null;
  }

  static async deleteCategory(memo, categoryUuid) {
    const indexToRemove = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === categoryUuid);
    const nodes = [...memo.val.nodes.slice(0, indexToRemove), ...memo.val.nodes.slice(indexToRemove + 1)];
    const updatedMemo = await Memo.updateMemoNode(memo, nodes);
    return updatedMemo;
  }

  static async addCategory(memo, newCategoryLabel) {
    const newCategory = {
      canModify: true,
      label: newCategoryLabel,
      uuid: uuidv4(),
      texts: []
    };
    const nodes = [...memo.val.nodes, newCategory];
    const updatedMemo = await Memo.updateMemoNode(memo, nodes);
    return { updatedMemo, newCategory };
  }

  static async updateCategoryLabel(memo, uuid, newLabel) {
    const indexToUpdate = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === uuid);
    const category = { ...memo.val.nodes[indexToUpdate], label: newLabel };
    const nodes = [...memo.val.nodes.slice(0, indexToUpdate), category, ...memo.val.nodes.slice(indexToUpdate + 1)];
    const updatedMemo = await Memo.updateMemoNode(memo, nodes);
    return updatedMemo;
  }

  static async updateMemoNode(memo, nodes) {
    const newMemo = {
      ...JSON.parse(JSON.stringify(memo)),
      val: {
        ...JSON.parse(JSON.stringify(memo.val)),
        nodes
      }
    };
    const updatedMemo = await Memo.save(newMemo);
    return updatedMemo;
  }

  static async deleteTextFromCategory(memo, uuid, categoryUuid) {
    const categoryIndex = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === categoryUuid);
    const [{ texts: originalTexts, ...category }] = memo.val.nodes.slice(categoryIndex, categoryIndex + 1);
    const indexToRemove = originalTexts.findIndex(({ uuid: uuidInArray }) => uuidInArray === uuid);
    const texts = [...originalTexts.slice(0, indexToRemove), ...originalTexts.slice(indexToRemove + 1)];
    const updatedMemo = await Memo.updateTextsInCategory(memo, categoryIndex, category, texts);
    return { updatedMemo, texts };
  }

  static async updateAllTextsInCategory(memo, categoryUuid, newTexts) {
    const categoryIndex = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === categoryUuid);
    const [{ texts: originalTexts, ...category }] = memo.val.nodes.slice(categoryIndex, categoryIndex + 1);
    const updatedMemo = await Memo.updateTextsInCategory(memo, categoryIndex, category, newTexts);
    return { updatedMemo, texts: newTexts };
  }

  static async updateTextInCategory(memo, uuid, categoryUuid, newText) {
    const categoryIndex = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === categoryUuid);
    const [{ texts: originalTexts, ...category }] = memo.val.nodes.slice(categoryIndex, categoryIndex + 1);
    const indexToUpdate = originalTexts.findIndex(({ uuid: uuidInArray }) => uuidInArray === uuid);
    const updatedText = { ...originalTexts[indexToUpdate], text: newText };
    const texts = [...originalTexts.slice(0, indexToUpdate), updatedText, ...originalTexts.slice(indexToUpdate + 1)];
    const updatedMemo = await Memo.updateTextsInCategory(memo, categoryIndex, category, texts);
    return { updatedMemo, texts };
  }

  static async updateInsertedByDefaultTextInCategory(memo, uuid, categoryUuid, insertedByDefault) {
    const categoryIndex = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === categoryUuid);
    const [{ texts: originalTexts, ...category }] = memo.val.nodes.slice(categoryIndex, categoryIndex + 1);
    const indexToUpdate = originalTexts.findIndex(({ uuid: uuidInArray }) => uuidInArray === uuid);
    const updatedText = { ...originalTexts[indexToUpdate], insertedByDefault };
    const texts = [...originalTexts.slice(0, indexToUpdate), updatedText, ...originalTexts.slice(indexToUpdate + 1)];
    const updatedMemo = await Memo.updateTextsInCategory(memo, categoryIndex, category, texts);
    return { updatedMemo, texts };
  }

  static async addTextsToCategory(memo, newTextLabels, categoryUuid) {
    const categoryIndex = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === categoryUuid);
    const [{ texts: originalTexts, ...category }] = memo.val.nodes.slice(categoryIndex, categoryIndex + 1);
    const newTexts = newTextLabels.map(text => ({
      original: false,
      text,
      uuid: uuidv4()
    }));
    const texts = [...originalTexts, ...newTexts];
    const updatedMemo = await Memo.updateTextsInCategory(memo, categoryIndex, category, texts);
    return { updatedMemo, texts };
  }

  static async addTextToCategory(memo, newTextLabel, categoryUuid) {
    const index = memo.val.nodes.findIndex(({ uuid: uuidInArray }) => uuidInArray === categoryUuid);
    // workaround : if categoryIndex === -1, this is due to uuid generated for non-existing default category,
    // so we forcibly choose the first category
    const categoryIndex = index === -1 ? 0 : index;
    const [{ texts: originalTexts, ...category }] = memo.val.nodes.slice(categoryIndex, categoryIndex + 1);
    const texts = [
      ...originalTexts,
      {
        original: false,
        text: newTextLabel,
        uuid: uuidv4()
      }
    ];
    const updatedMemo = await Memo.updateTextsInCategory(memo, categoryIndex, category, texts);
    return { updatedMemo, texts };
  }

  static async updateTextsInCategory(memo, categoryIndex, category, texts) {
    const nodes = [
      ...memo.val.nodes.slice(0, categoryIndex),
      {
        ...category,
        texts
      },
      ...memo.val.nodes.slice(categoryIndex + 1)
    ];
    const newMemo = {
      ...JSON.parse(JSON.stringify(memo)),
      val: {
        ...JSON.parse(JSON.stringify(memo.val)),
        nodes: nodes.filter(({ texts: nodeTexts }) => !!nodeTexts)
      }
    };
    const updatedMemo = await Memo.save(newMemo);
    return updatedMemo;
  }

  static async pushToHistory(memo, text) {
    if (memo.val && memo.val.history && text) {
      const removedDuplicates = [...new Set(memo.val.history)];
      const index = removedDuplicates.indexOf(text);
      if (index !== -1) {
        removedDuplicates.splice(index, 1);
      }
      const history = [text, ...removedDuplicates].slice(0, 10);
      console.log({ previousHistory: memo.val.history, index, history });
      const newMemo = {
        ...JSON.parse(JSON.stringify(memo)),
        val: {
          ...JSON.parse(JSON.stringify(memo.val)),
          history
        }
      };
      const updatedMemo = await Memo.save(newMemo);
      return updatedMemo;
    }
    return memo;
  }

  static replaceValues(memorizedText, enabledPersonalInfoReplacement, lastname, firstname) {
    let newText = memorizedText.replace(/<div>/gi, '<p>').replace(/<\/div>/gi, '</p>\r\n');
    if (newText.match(/<p>/gi)) {
      newText = newText.replace(/<p>/i, '</p>\r\n<p>').replace(/^/gi, '<p>');
    } else {
      newText = newText.replace(/^/gi, '<p>').replace(/$/gi, '</p>\r\n');
    }
    if (enabledPersonalInfoReplacement && memorizedText) {
      return newText.replace(/@nom/gi, lastname).replace(/@prenom/gi, firstname);
    }
    return newText;
  }

  static replaceValuesInverted(currentText, enabledPersonalInfoReplacement, lastname, firstname) {
    if (enabledPersonalInfoReplacement && currentText) {
      let string = currentText;
      if (lastname) {
        const lastnameRegExp = new RegExp(lastname, 'gi');
        string = string.replace(lastnameRegExp, '@nom');
      }
      if (firstname) {
        const firstnameRegExp = new RegExp(firstname, 'gi');
        string = string.replace(firstnameRegExp, '@prenom');
      }
      return string;
    }
    return currentText;
  }

  static async deduplicateAndAddMemos(datas) {
    const keyValueObject = reduceData(datas);
    // eslint-disable-next-line no-restricted-syntax
    for await (const [key, { nodes }] of Object.entries(keyValueObject)) {
      const memo = await Memo.findByKey(key);
      const newNodes = mergeAndDeduplicateMemos(nodes, memo.val.nodes);
      // console.log({memoNodes : memo.val.nodes, nodes, newNodes, arrai : {commonNodes, nodeFromMemoOnly, nodeFromFileOnly}})
      await Memo.updateMemoNode(memo, newNodes);
    }
  }

  static async verifyIfMemoByTestCodeAndIdHasInsertedByDefaultTextAndAddThem(
    testCode,
    id,
    comment = '',
    firstname,
    lastname,
    addUserSelectedMemorizedTextsAsDefaultComment
  ) {
    const key = getKeyFromTestCodeAndId(testCode, id);
    return Memo.verifyIfMemoByKeyHasInsertedByDefaultTextAndAddThem(
      key,
      lastname,
      firstname,
      comment,
      addUserSelectedMemorizedTextsAsDefaultComment
    );
  }

  static async verifyIfMemoByKeyHasInsertedByDefaultTextAndAddThem(
    key,
    lastname,
    firstname,
    comment,
    addUserSelectedMemorizedTextsAsDefaultComment
  ) {
    if (!addUserSelectedMemorizedTextsAsDefaultComment) {
      console.error('!addUserSelectedMemorizedTextsAsDefaultComment');
      return comment;
    }

    const memo = await Memo.findByKey(key);

    const { enabledPersonalInfoReplacement, nodes } = memo.val;
    const joined = nodes
      .flatMap(({ texts }) => texts || [])
      .filter(({ insertedByDefault }) => !!insertedByDefault)
      .map(({ text }) => text)
      .filter(s => !!s)
      .filter(s => {
        const sReplaced = Memo.replaceValues(s, enabledPersonalInfoReplacement, lastname, firstname)
          .replace(/<[^>]*>?/gm, '')
          .replace(/[\n\r]+/g, '');
        const commentReplaced = Memo.replaceValues(comment, enabledPersonalInfoReplacement, lastname, firstname)
          .replace(/<[^>]*>?/gm, '')
          .replace(/[\n\r]+/g, '');
        return !commentReplaced.includes(sReplaced);
      })
      .join('<br/>');

    if (!joined) {
      return comment;
    }
    return Memo.replaceValues(joined + comment, enabledPersonalInfoReplacement, lastname, firstname);
  }
}

export default Memo;
