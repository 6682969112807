import React from 'react';
import { Grid, Typography } from '@material-ui/core';

type Props = {
  id?: string,
  label: string,
  span?: number,
  myValue?: string
};

const Info = ({ id, label, span, myValue }: Props) => (
  <Grid key={id || label} item xs={span} container style={{ marginBottom: '1.5em' }}>
    <Grid item xs={12}>
      <Typography variant="h6">{label}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body1">{myValue}</Typography>
    </Grid>
  </Grid>
);

Info.defaultProps = {
  id: '',
  myValue: '',
  span: 12
};

export default Info;
