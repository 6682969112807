import { frenchClasses } from '../../constants/Grades';
import MathUtil from './Math';
import labels from '../../constants/labels/common/Header.json';

// eslint-disable-next-line import/prefer-default-export
export const getHeaderAgeAndClassText = (curclasse, birthDay) => {
  const age = birthDay ? `${MathUtil.calculateAge(new Date(birthDay) || Date.now())} ${labels.years}` : null;
  const curClasses = frenchClasses.filter(({ id }) => id === Number(curclasse));
  const { shortLabel } = curClasses[0] || { shortLabel: '' };
  return [age, shortLabel].filter(Boolean).join(', ');
};
