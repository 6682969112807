import React from 'react';
import './Loader2.css';

export default function Loader() {
  return (
    <div className='lds-spinner'>
      {[...Array(12).keys()].map(id => (
        <div key={id} />
      ))}
    </div>
  );
}
