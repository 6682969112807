import React, { Component, Fragment } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import _ from 'lodash';

import labels from '../../constants/labels/patient/TestFilling.json';
import Patient from '../common/data/Patient';
import TestHeader from './test/TestHeader';
import TestFillingRow from './test/TestFillingRow';
import { getTestValues, shouldHideRow, updateCurrentTestResultsFromCalculOption } from './test/TestUtils';
import TestFillingHeader from './test/TestFillingHeader';
import { displayedColumns } from './test/TestFillingUtil';
import CheckupCommentEditor from './comments/CheckupCommentEditor';
import isEmptyComment from '../common/RichTextEditorUtil';
import defaultReportGenerationOptions from '../../constants/user/defaultReportGenerationOptions';
import TestFillingStepper from './TestFillingStepper';
import PatientContentUtil from './PatientContentUtil';
import { getKeyFromTestCodeAndId } from '../common/data/MemoUtil';
// import { testCodesWithImportInTestFilling } from '../../constants/tests/happyneuronTests';
import MyStore from '../../actions/MyStore';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  classes: any,
  match: {
    params: {
      patientId: string,
      testCode: string,
      bilanId: string
    }
  }
};

const giveMeSortMethod = (sortField, sortOrder, canNotBeSorted = false) => {
  if (!canNotBeSorted && sortField) {
    return ({ IHMLabel: IHMLabelA, label: labelA }, { IHMLabel: IHMLabelB, label: labelB }) => {
      const a = IHMLabelA || labelA;
      const b = IHMLabelB || labelB;
      return sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    };
  }
  return ({ index: indexA }, { index: indexB }) => indexA - indexB;
};

class TestFilling extends Component<Props> {
  state = {
    patient: {
      bilan: {}
    },
    currentTest: {
      children: []
    },
    currentTestResults: {
      $$calculOption: '',
      $$displayRawScore: true
    },
    initialTestResults: {},
    examinations: [],
    calculationData: {},
    commentButtonFullText: false,
    hideNonFilledTests: false,
    sortExaminationsByName: false,
    titleFilter: '',
    sortField: '',
    sortOrder: 'asc',
    cotation: {},
    columns: {},
    currentTestHasCalculOptions: false,
    disableCentileColumn: false,
    displayForceCentilesOption: true,
    displayCalculatedPercentile: false,
    mouseScrollWheelScoreEdition: false,
    testFillingRowCompactDisplay: false,
    options: defaultReportGenerationOptions,
    currentlyOpenedExaminationCommentEditorId: null
  };

  async componentDidMount() {
    const {
      match: {
        params: { patientId, testCode }
      }
    } = this.props;
    const testFillingRowCompactDisplay = MyStore.getAsBoolean('testFillingRowCompactDisplay', false);
    const content = await PatientContentUtil.getContent(patientId);
    const { patient, options, customTests, initialPatient = {} } = content;
    const { cotation = { results: {}, initialResults: {} } } = patient;
    const { cotation: initialCotation = { results: {}, initialResults: {} } } = initialPatient;
    const {
      currentTest,
      currentTestResults,
      examinations,
      calculationData,
      columns,
      disableCentileColumn,
      currentTestHasCalculOptions,
      displayCalculatedPercentile
    } = getTestValues(customTests, testCode, cotation);
    const { currentTestResults: initialTestResults } = getTestValues(customTests, testCode, initialCotation);
    cotation.results[testCode] = currentTestResults;
    const updatedPatient = await Patient.updatePatient({ ...patient, cotation });
    this.setState({
      patient: updatedPatient,
      currentTest,
      cotation: updatedPatient.cotation,
      currentTestResults,
      initialTestResults,
      examinations,
      calculationData,
      columns,
      disableCentileColumn,
      currentTestHasCalculOptions,
      displayCalculatedPercentile,
      testFillingRowCompactDisplay,
      options
    });
  }

  handleCurrentTestResultsChange = async event => {
    const {
      match: {
        params: { testCode }
      }
    } = this.props;
    const { patient, cotation, currentTestResults } = this.state;
    cotation.results[testCode] = _.set(currentTestResults, event.target.name, event.target.value);
    const updatedPatient = await Patient.updatePatient({ ...patient, cotation });
    this.setState({
      currentTestResults: updatedPatient.cotation.results[testCode],
      cotation: updatedPatient.cotation,
      patient: updatedPatient
    });
  };

  changeSelectedCalculusTable = async event => {
    const {
      match: {
        params: { testCode }
      }
    } = this.props;
    const { patient, currentTest, cotation, currentTestResults } = this.state;
    const updatedTestsResults = _.merge({}, currentTestResults);
    updatedTestsResults.$$calculOption = event.target.value;

    const {
      examinations,
      calculationData,
      columns,
      disableCentileColumn,
      currentTestHasCalculOptions,
      updatedCurrentTestResults: values
    } = updateCurrentTestResultsFromCalculOption(currentTest, updatedTestsResults);
    cotation.results[testCode] = values;
    const updatedPatient = await Patient.updatePatient({ ...patient, cotation });

    this.setState({
      patient: updatedPatient,
      examinations,
      calculationData,
      columns,
      disableCentileColumn,
      cotation: updatedPatient.cotation,
      currentTestResults: updatedPatient.cotation.results[testCode],
      currentTestHasCalculOptions
    });
  };

  handleOptionsChange = optionName => event => {
    this.setState({ [optionName]: event.target.checked });
  };

  handleCurrentTestResultPropertyChange = currentTestResultProperty => async event => {
    const {
      match: {
        params: { testCode }
      }
    } = this.props;
    const { patient, cotation, currentTestResults } = this.state;
    currentTestResults[currentTestResultProperty] = event.target.checked;
    cotation.results[testCode] = currentTestResults;
    const updatedPatient = await Patient.updatePatient({ ...patient, cotation });
    this.setState({
      currentTestResults: updatedPatient.cotation.results[testCode],
      cotation: updatedPatient.cotation,
      patient: updatedPatient
    });
  };

  sortBy = field => () => {
    const { sortField, sortOrder } = this.state;
    if (field === sortField && sortOrder === 'asc') {
      this.setState({ sortOrder: 'desc' });
    } else if (field === sortField) {
      this.setState({ sortField: '' });
    } else {
      this.setState({ sortField: field, sortOrder: 'asc' });
    }
  };

  filterBy = titleFilter => {
    this.setState({ titleFilter });
  };

  render() {
    const {
      match: {
        params: { patientId, testCode, bilanId }
      }
    } = this.props;
    const {
      testFillingRowCompactDisplay,
      mouseScrollWheelScoreEdition,
      commentButtonFullText,
      examinations,
      calculationData,
      currentTestResults,
      initialTestResults,
      columns,
      disableCentileColumn,
      displayForceCentilesOption,
      displayCalculatedPercentile,
      currentTestHasCalculOptions,
      hideNonFilledTests,
      currentTest,
      titleFilter,
      sortField,
      sortOrder,
      patient,
      options,
      currentlyOpenedExaminationCommentEditorId
    } = this.state;
    const displayedColumns1 = displayedColumns(columns, disableCentileColumn);
    console.log({ displayedColumns1 });
    const forceDisplayRawScore = !displayedColumns1.rawScore && currentTestResults.$$displayRawScore ? 1 : 0;
    const columnWidth = Math.floor(
      12 / (Object.values(displayedColumns1).filter(value => value).length + forceDisplayRawScore)
    );
    console.log('############################ render test filling #################################');

    const filteredAndSortedExaminations = examinations
      .filter(({ id }) => currentTestResults[id] && !currentTestResults[id].$$HIDDENFROMVIEW)
      .filter(shouldHideRow(hideNonFilledTests, currentTestResults))
      .map((item, index) => ({
        ...item,
        index
      }))
      .sort(giveMeSortMethod(sortField, sortOrder, currentTest.canNotBeSorted))
      .filter(({ label }) => {
        if (!titleFilter) {
          return true;
        }
        return label.toLowerCase().includes(titleFilter.toLowerCase());
      });
    console.log({ filteredAndSortedExaminations });
    const isRenewal = !!patient.bilan.initialAssessmentUUID;
    return (
      <Fragment>
        <TestHeader
          {...this.state}
          bilanId={bilanId}
          patientId={patientId}
          changeSelectedCalculusTable={this.changeSelectedCalculusTable}
          // extraButton={
          //   testCodesWithImportInTestFilling.includes(testCode) && (
          //     <Tooltip title={labels.importTestResults}>
          //       <IconButton
          //         component={Link}
          //         to={routes.PATIENT.TESTS.getForPath({
          //           path: routes.PATIENT.TESTS.IMPORT_TEST,
          //           patientId,
          //           testCode,
          //           bilanId
          //         })}
          //       >
          //         <Archive />
          //       </IconButton>
          //     </Tooltip>
          //   )
          // }
        >
          <Fragment>
            <Grid item xs={12} container>
              <Accordion style={{ width: '100%' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>{labels.moreOptions.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    {!currentTestHasCalculOptions && (
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={currentTestResults.$$displayRawScore}
                              onChange={this.handleCurrentTestResultPropertyChange('$$displayRawScore')}
                              value="$$displayRawScore"
                            />
                          }
                          label={labels.moreOptions.displayRawScore}
                        />
                      </Grid>
                    )}
                    {displayForceCentilesOption && (
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={currentTestResults.forceCentiles}
                              onChange={this.handleCurrentTestResultPropertyChange('forceCentiles')}
                              value="forceCentiles"
                            />
                          }
                          label={labels.moreOptions.displayForceCentilesOption}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={mouseScrollWheelScoreEdition}
                            onChange={this.handleOptionsChange('mouseScrollWheelScoreEdition')}
                            value="mouseScrollWheelScoreEdition"
                          />
                        }
                        label={labels.moreOptions.mouseScrollWheelScoreEdition}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={commentButtonFullText}
                            onChange={this.handleOptionsChange('commentButtonFullText')}
                            value="commentButtonFullText"
                          />
                        }
                        label={labels.moreOptions.commentButtonFullText}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={hideNonFilledTests}
                            onChange={this.handleOptionsChange('hideNonFilledTests')}
                            value="hideNonFilledTests"
                          />
                        }
                        label={labels.moreOptions.hideNonFilledTests}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} container>
              <Paper style={{ width: '100%', padding: '0.5em' }}>
                {isEmptyComment(currentTestResults.behaviourComment) && (
                  <Typography variant="h6">{labels.commentOnPassation}</Typography>
                )}
                <CheckupCommentEditor
                  value={currentTestResults.behaviourComment || ''}
                  onChange={this.handleCurrentTestResultsChange}
                  controlName="behaviourComment"
                  index={0}
                  memo={getKeyFromTestCodeAndId('GLOBAL', 'behaviour')}
                  memoByCategory
                  lastName={patient.lastName}
                  firstName={patient.firstName}
                  title={labels.commentOnPassation}
                />
              </Paper>
            </Grid>
          </Fragment>
        </TestHeader>
        <Grid container alignItems="flex-start" justifyContent="space-evenly" style={{ marginTop: '1em' }}>
          <Paper style={{ width: '100%', padding: '0.5em' }}>
            <TestFillingHeader
              currentTest={currentTest}
              onChangeSortOrder={this.sortBy('testLabel')}
              onChangeTitleFilter={this.filterBy}
              titleFilter={titleFilter}
              sortField={sortField}
              sortOrder={sortOrder}
              columns={columns}
              displayRawScore={currentTestResults.$$displayRawScore}
              columnWidth={columnWidth}
              disableCentileColumn={disableCentileColumn}
              displayCalculatedPercentile={displayCalculatedPercentile}
              isRenewal={isRenewal}
              renewalNumber={patient.bilan.renewalNumber}
            />
            {filteredAndSortedExaminations.map(({ id, ...props }, index, array) => (
              <TestFillingRow
                key={id}
                id={id}
                oddRow={index % 2 === 0}
                testRowData={calculationData ? calculationData[id] : null}
                currentTestResult={currentTestResults[id]}
                isRenewal={isRenewal}
                initialTestResult={initialTestResults[id]}
                showCommentLabel={commentButtonFullText}
                columns={columns}
                columnWidth={columnWidth}
                disableCentileColumn={disableCentileColumn}
                handleChange={this.handleCurrentTestResultsChange}
                displayRawScore={!!currentTestResults.$$displayRawScore}
                displayCalculatedPercentile={displayCalculatedPercentile}
                mouseScrollWheelScoreEdition={mouseScrollWheelScoreEdition}
                testCode={testCode}
                lastname={patient.lastName}
                firstname={patient.firstName}
                testFillingRowCompactDisplay={testFillingRowCompactDisplay}
                options={options}
                showCommentEditor={currentlyOpenedExaminationCommentEditorId === id}
                setShowCommentEditor={i => {
                  this.setState({ currentlyOpenedExaminationCommentEditorId: i });
                }}
                stepper={
                  <TestFillingStepper
                    array={array}
                    activeStep={currentlyOpenedExaminationCommentEditorId === id ? index : 0}
                    steps={array.length}
                    index={index}
                    setCurrentlyOpenedExaminationCommentEditorId={i =>
                      this.setState({ currentlyOpenedExaminationCommentEditorId: i })
                    }
                  />
                }
                {...props}
              />
            ))}
          </Paper>
        </Grid>
      </Fragment>
    );
  }
}

export default TestFilling;
