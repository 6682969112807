import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function TextAreaIcon() {
  return (
    <SvgIcon viewBox="0 0 10240 10240">
      <path
        style={{ fill: '#252525' }}
        d="m 1920,6400 3659.209,0 0,640 -3659.209,0 0,-640 z m 0,-2560 5488.8135,0 0,640 -5488.8135,0 0,-640 z m 0,1280 5488.8135,0 0,640 -5488.8135,0 0,-640 z"
      />
      <path
        style={{ fill: '#252525' }}
        d="M1280 2560l7680 0 0 5120 -7680 0 0 -5120zm-320 -640c-176,0 -320,144 -320,320l0 5760c0,176 144,320 320,320l8320 0c176,0 320,-144 320,-320l0 -5760c0,-176 -144,-320 -320,-320l-8320 0z"
      />
      <path
        style={{ fill: '#252525' }}
        d="m 5876.6101,6069.1524 959.9999,0 0,204.2938 -320,0 0,817.1751 320,0 0,204.2938 -959.9999,0 0,-204.2938 320,0 0,-817.1751 -320,0 z"
      />
    </SvgIcon>
  );
}
