import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import labels from '../../../../constants/labels/patient/comments/memorizedTexts/AddListItem.json';
import EditingListItem from './EditingListItem';
import SimpleTextEditingListItem from './SimpleTextEditingListItem';

type Props = {
  label: string,
  simple?: boolean,
  onAdd: string => void
};

function AddListItem({ label, onAdd, simple }: Props) {
  const [addingItem, setAddingItem] = useState(false);

  const addNewLabel = newLabel => {
    onAdd(newLabel);
    cancelAdd();
  };

  const cancelAdd = () => {
    setAddingItem(false);
  };

  if (addingItem) {
    if (simple) {
      return (
        <SimpleTextEditingListItem
          label={labels.newLabel}
          onValidateLabel={newLabel => addNewLabel(newLabel)}
          onCancelAdd={cancelAdd}
        />
      );
    }

    return (
      <EditingListItem
        label={labels.newLabel}
        onValidateLabel={newLabel => addNewLabel(newLabel)}
        onCancelAdd={cancelAdd}
      />
    );
  }
  return (
    <ListItem button onClick={() => setAddingItem(true)}>
      <ListItemIcon>
        <Add />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
}

AddListItem.defaultProps = {
  simple: false
};

export default AddListItem;
