import React, { useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Person, Save } from '@material-ui/icons';
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import classNames from 'classnames';

import * as Tests from '../../constants/tests/tests';
import labels from '../../constants/labels/patient/TestSelection.json';

import '../configuration/tests/TestList.css';
import SaveCancelRow from '../common/SaveCancelRow';
import routes from '../../constants/routes';
import { frenchClasses } from '../../constants/Grades';
import MathUtil from '../common/Math';
import Patient from '../common/data/Patient';
import User from '../common/data/User';
import CustomTests from '../common/data/CustomTests';
import log from '../Logger';
import TestSelectionAlert from './test/TestSelectionAlert';
import SearchField from "../common/SearchField";
import appVersionSpecific from '../../appVersionSpecific.ortho';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold'
  }
});

const TestSelection = () => {
  const [testSelectionData, updateTestSelectionData] = useReducer((prev, next) => ({ ...prev, ...next }),
    { displayableTests: [], ageTarget: true, patient: {bilan: {tests:[]}}, selectedTestCodes: [], q: "" }
  );

  const {displayableTests,
    ageTarget,
    patient,
    selectedTestCodes,
    q
  } = testSelectionData;

  const history = useHistory();
  const classes = useStyles();
  const { patientId } = useParams();

  useEffect( () => {
    const updatePatient = async () => {
      try {
        const patientFromDb = await Patient.retrievePatient(patientId);
        await updateTestSelectionData({patient: patientFromDb});
      } catch (err) {
        log.error("Error retrieving datas", err);
      }
    };

    updatePatient().then();
  }, [patientId]);

  useEffect(() => {
    const updateSelectedTestCodes = () => {
      const { bilan } = patient;
      const patientSelectedTestCodes = (bilan.tests || []).map(test => test.testCode);
      updateTestSelectionData({selectedTestCodes: patientSelectedTestCodes});
    };

    updateSelectedTestCodes();
  }, [patient])

  useEffect(() => {
    const updateDatas = async () => {
      const testsFromDb = await User.retrieveValueFromDb({ keyValue: "tests", defaultValue: [] });
      const tests = testsFromDb.val.length === 0 ? Tests.filteredLabeledTests : testsFromDb.val;
      const testCodes = tests.map(test => test.testCode).concat(selectedTestCodes);
      const customTestsFromDb = await CustomTests.findAll();
      const displayableTestsFromDb = Tests.filteredLabeledTests
        .filter(test => testCodes.includes(test.testCode))
        .concat(customTestsFromDb)
        .filter(test => {
          if(!q) {
            return true;
          }
          const regexpQ = new RegExp(q, 'i');
          return regexpQ.test(test.IHMLabel || test.label);
        });
      await updateTestSelectionData({displayableTests: displayableTestsFromDb});
    };

    updateDatas().then();
  }, [selectedTestCodes, q]);


  const handleToggle = value => () => {
    const stc = JSON.parse(JSON.stringify(selectedTestCodes));
    const currentIndex = selectedTestCodes.indexOf(value);
    if (currentIndex === -1) {
      stc.push(value);
    } else {
      stc.splice(currentIndex, 1);
    }
    updateTestSelectionData({selectedTestCodes: stc})
  };

  const handleSubmit = goToTestPage => async () => {
    try {
      const newTests = selectedTestCodes.map(testCode => ({testCode}));
      const updatedPatient = await Patient.updatePatient({ ...patient, bilan: {...patient.bilan, tests: newTests} });
      await updateTestSelectionData({patient: updatedPatient});
    } catch (err) {
      log.error('Error updating selected user tests', err);
    }
    if (goToTestPage) {
      history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.TESTS.TESTS, patientId }));
      return;
    }
    history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId }));
  };

  const searchChange = (event) => {
    updateTestSelectionData({q: event.target.value});
  };

  const allTestsByType = displayableTests.reduce((acc, obj) => {
    const cle = obj.type;
    if (!acc[cle]) {
      acc[cle] = [];
    }
    acc[cle].push(obj);
    return acc;
  }, {});
  const testsByType = [
    {
      label: labels.types.LE,
      tests: allTestsByType.LE || []
    },
    {
      label: labels.types.LE_LO,
      tests: allTestsByType['LE/LO'] || []
    },
    {
      label: labels.types.LO,
      tests: allTestsByType.LO || []
    },
    {
      label: labels.types.OTHER,
      tests: allTestsByType.Autre || []
    }
  ];
  const curClasses = frenchClasses.filter(({ id }) => id === Number(patient.curclasse));
  const { shortLabel } = curClasses[0] || { shortLabel: '' };
  const age = `${MathUtil.calculateAge(new Date(patient.birthDay) || Date.now())} ${labels.years}`;
  const ageAndClassText = [age, shortLabel].join(', ');
  const { bilan: { initialAssessmentTests = [] } = {} } = patient;
  const initialAssessmentTestcodes = (initialAssessmentTests || []).map(({ testCode }) => testCode);
  const isHappyNeuronImport = appVersionSpecific.isHappyNeuron;

  return (
    <div>
      <Typography variant="h3">{labels.title}</Typography>
      <Typography variant="h4">
        {labels.subTitle.part0}{' '}
        {patient.bilan.renewalNumber ? labels.subTitle.renewal + patient.bilan.renewalNumber : labels.subTitle.initial}{' '}
        {labels.subTitle.part1} {patient.firstName} {patient.lastName}({ageAndClassText})
      </Typography>
      <Divider />
      <form onSubmit={handleSubmit(false)}>
        <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly">
          {!isHappyNeuronImport && (
            <Grid xs={12} item>
              <Typography variant="body2">
                <span className={classes.bold}>{labels.notaBene.boldLabel}</span>
                {labels.notaBene.label}{' '}
                <Link to={routes.CONFIGURATION.MAIN + routes.CONFIGURATION.TESTS.LIST}>{labels.notaBene.link}</Link>
              </Typography>
            </Grid>
          )}
          <SaveCancelRow
            cancelRoute={routes.HOME}
            saveAction={handleSubmit(false)}
            saveIcon=""
            title={<SearchField q={q} searchChange={searchChange} itemMdValue={12} placeholder={labels.filterTestsHere}/>}
            moreActionsMenu={[
              {
                key: 'entry0',
                element: (
                  <Tooltip title={labels.saveAndContinue}>
                    <IconButton onClick={handleSubmit(true)} variant="contained" color="primary">
                      <Save />
                    </IconButton>
                  </Tooltip>
                )
              }
            ]}
          />
          <Grid item xs={12}>
            <TestSelectionAlert />
          </Grid>
          {testsByType.map(({ label, tests: allTestsOfLabel }) => (
            <Grid item xs={3} key={label}>
              <Paper elevation={3}>
                <Typography variant="h6">{label}</Typography>
                <List dense>
                  {allTestsOfLabel.map(
                    ({
                       label: testLabel,
                       classe: { min = '', max = '' } = {},
                       custom = false,
                       IHMLabel,
                       testCode
                     }) => (
                      <ListItem
                        key={IHMLabel || testLabel}
                        className="listElement"
                        onClick={handleToggle(testCode)}
                      >
                        <Checkbox
                          checked={selectedTestCodes.includes(testCode)}
                          tabIndex={-1}
                          disableRipple
                          className="smallCheckbox"
                        />
                        {custom && (
                          <ListItemIcon>
                            <Person />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          className={classNames(
                            {
                              "selectedInInitialAssessmentTest": initialAssessmentTestcodes.includes(
                                testCode
                              )
                            },
                            "listElement"
                          )}
                          primary={IHMLabel || testLabel}
                          secondary={ageTarget && min && max ? `${min} ${labels.classesFromTo} ${max}` : ''}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <FormControlLabel
          id="ageTargetDisplaySwitch"
          control={<Switch checked={ageTarget} onChange={(e) => {updateTestSelectionData({ageTarget: e.target.checked})}} value="ageTarget" />}
          label={labels.displayAgeTarget}
        />
      </form>
    </div>
  );

};
export default TestSelection;
