import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function TextFieldIcon() {
  return (
    <SvgIcon viewBox="0 0 10240 10240">
      <path
        style={{ fill: '#252525' }}
        d="M640 3200l8960 0 0 3840 -8960 0 0 -3840zm640 640l0 2560 7680 0 0 -2560 -7680 0z"
      />
      <path
        style={{ fill: '#252525' }}
        d="M1600,4160 2560,4160 2560,4480 2240,4480 2240,5760 2560,5760 2560,6080 1600,6080 1600,5760 1920,5760 1920,4480 1600,4480z"
      />
    </SvgIcon>
  );
}
