/* eslint react/prop-types:0 */
import React, { Fragment, useEffect, useState } from 'react';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';

import ReportGeneratorUtil from './ReportGeneratorUtil';
import Loader from '../../common/Loader';
import labels from '../../../constants/labels/patient/report/ReportAnamnesis.json';
import AnamnesisActionPhrase from '../../common/anamnesis/AnamnesisActionPhrase';
import CheckupCommentEditor from '../comments/CheckupCommentEditor';
import OnLineOffLineSwitcher from '../../common/OnLineOffLineSwitcher';
import useNavigatorOnLine from '../../common/UseNavigatorOnLine';

const ReportAnamnesis = ({ content, withLabel = true, updateAnamnesisText }) => {
  const [anamnesisText, setAnamnesisText] = useState();
  const [loaded, setLoaded] = useState(false);

  const { anamnesis: contentAnamnesis, anamnesisText: contentAnamnesisText } = content;

  const isOnline = useNavigatorOnLine();

  const resetAnamnesisText = async () => {
    await setAnamnesisText(null);
    setLoaded(false);
    const myAnamnesis = await ReportGeneratorUtil.generateAnamnesis(content);
    setAnamnesisText(myAnamnesis);
    setLoaded(true);
  };

  const changeAnamnesisText = async event => {
    updateAnamnesisText(event.target.value);
    setAnamnesisText(event.target.value);
  };

  useEffect(() => {
    const fetchAnamnesis = async () => {
      await setAnamnesisText(null);
      if (contentAnamnesisText) {
        updateAnamnesisText(contentAnamnesisText);
        setAnamnesisText(contentAnamnesisText);
        setLoaded(true);
        return;
      }
      if (isOnline) {
        if (!contentAnamnesisText && content.patient._id && Object.entries(contentAnamnesis.values).length !== 0) {
          const myAnamnesis = await ReportGeneratorUtil.generateAnamnesis(content);
          setAnamnesisText(myAnamnesis);
          setLoaded(true);
          return;
        }
        if (contentAnamnesis && Object.entries(contentAnamnesis.values).length === 0) {
          setAnamnesisText('');
          setLoaded(true);
        }
      }
    };

    fetchAnamnesis().then();
  }, [content, contentAnamnesis, contentAnamnesisText, isOnline, updateAnamnesisText]);

  if (isOnline && !loaded) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const resetAnamnesisButton = (
    <OnLineOffLineSwitcher
      onLine={
        <Tooltip title={labels.resetText}>
          <IconButton onClick={resetAnamnesisText}>
            <Refresh />
          </IconButton>
        </Tooltip>
      }
    />
  );

  return (
    <Fragment>
      {withLabel && <Typography variant="h6">{labels.anamnesis}</Typography>}
      {/* eslint-disable-next-line react/no-danger */}
      {anamnesisText && anamnesisText !== '' && (
        <CheckupCommentEditor
          value={anamnesisText}
          controlName=""
          onChange={changeAnamnesisText}
          memoByCategory={false}
          title={labels.anamnesis}
          extraButton={resetAnamnesisButton}
        />
      )}
      {!isOnline && !anamnesisText && <Typography variant="text">{labels.offlineGenerationNotPossible}</Typography>}
      {anamnesisText === '' && <AnamnesisActionPhrase anamnese={content.anamnesis} patientId={content.patient._id} />}
    </Fragment>
  );
};

export default ReportAnamnesis;
