import {fetchJson, fetchXml} from '../../../common/OrthoscribeWebSite';
import buildInfo from '../../../../buildInfo.json';

const AppName = "Happyscribe";
export const hnParamsDownload = async ({userId, ticket}) => {

  const init = {
    headers: new Headers({
      ticket,
      AppName,
      AppVersion: buildInfo.version
    })
  };
  const happyneuronUrl = "https://api-preprod.happyneuronpro.com";
  const hnJsonContent = await fetchJson(`${happyneuronUrl}/users/${userId}/happyscribe`, init);
  console.log({hnJsonContent})
  return hnJsonContent;
}

export const jsonDownload = async ({userId, ticket, programId}) => {

  const init = {
    headers: new Headers({
      ticket,
      AppName,
      AppVersion: buildInfo.version
    })
  };
  const happyneuronUrl = "https://api-preprod.happyneuronpro.com";
  const hnJsonContent = await fetchJson(`${happyneuronUrl}/users/${userId}/results/programs/${programId}`, init);
  console.log({hnJsonContent})
  return hnJsonContent;
}

export const xmlDownload = async ({userId, ticket, programId}) => {

  const init = {
    headers: new Headers({
      ticket,
      AppName,
      AppVersion: buildInfo.version
    })
  };
  const happyneuronUrl = "https://api-preprod.happyneuronpro.com";
  const hnXmlContent = await fetchXml(`${happyneuronUrl}/users/${userId}/results/programs/${programId}`, init);
  console.log({hnXmlContent})
  return hnXmlContent;
}