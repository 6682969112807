const classes = [
  {
    id: 0,
    label: 'Petite section',
    shortLabel: 'PSM',
    code: 'PSM',
    age: 3,
    detpos1: 'de la',
    detpos2: 'à la',
    det: 'la',
    category: 'Maternelle',
    commentId: 0,
    hn: ['PSM']
  },
  {
    id: 1,
    label: 'Moyenne section',
    shortLabel: 'MSM',
    code: 'MSM',
    age: 4,
    detpos1: 'de la',
    detpos2: 'à la',
    det: 'la',
    category: 'Maternelle',
    commentId: 0,
    hn: ['MSM']
  },
  {
    id: 2,
    label: 'Grande section',
    shortLabel: 'GSM',
    code: 'GSM',
    age: 5,
    detpos1: 'de la',
    detpos2: 'à la',
    det: 'la',
    category: 'Maternelle',
    commentId: 0,
    hn: ['GSM']
  },
  {
    id: 3,
    label: 'CP',
    shortLabel: 'CP',
    code: 'CP',
    age: 6,
    detpos1: 'du',
    detpos2: 'au',
    det: 'le',
    category: 'Primaire',
    commentId: 1,
    hn: ['CP']
  },
  {
    id: 4,
    label: 'CE1',
    shortLabel: 'CE1',
    code: 'CE1',
    age: 7,
    detpos1: 'du',
    detpos2: 'au',
    det: 'le',
    category: 'Primaire',
    commentId: 1,
    hn: ['CE1']
  },
  {
    id: 5,
    label: 'CE2',
    shortLabel: 'CE2',
    code: 'CE2',
    age: 8,
    detpos1: 'du',
    detpos2: 'au',
    det: 'le',
    category: 'Primaire',
    commentId: 1,
    hn: ['CE2']
  },
  {
    id: 6,
    label: 'CM1',
    shortLabel: 'CM1',
    code: 'CM1',
    age: 9,
    detpos1: 'du',
    detpos2: 'au',
    det: 'le',
    category: 'Primaire',
    commentId: 1,
    hn: ['CM1']
  },
  {
    id: 7,
    label: 'CM2',
    shortLabel: 'CM2',
    code: 'CM2',
    age: 10,
    detpos1: 'du',
    detpos2: 'au',
    det: 'le',
    category: 'Primaire',
    commentId: 1,
    hn: ['CM2']
  },
  {
    id: 8,
    label: '6ème',
    shortLabel: '6ème',
    code: '6EME',
    age: 11,
    detpos1: 'de la',
    detpos2: 'à la',
    det: 'la',
    category: 'Collège',
    commentId: 2,
    hn: ['6ème']
  },
  {
    id: 9,
    label: '5ème',
    shortLabel: '5ème',
    code: '5EME',
    age: 12,
    detpos1: 'de la',
    detpos2: 'à la',
    det: 'la',
    category: 'Collège',
    commentId: 2,
    hn: ['5ème']
  },
  {
    id: 10,
    label: '4ème',
    shortLabel: '4ème',
    code: '4EME',
    age: 13,
    detpos1: 'de la',
    detpos2: 'à la',
    det: 'la',
    category: 'Collège',
    commentId: 2,
    hn: ['4ème']
  },
  {
    id: 11,
    label: '3ème',
    shortLabel: '3ème',
    code: '3EME',
    age: 14,
    detpos1: 'de la',
    detpos2: 'à la',
    det: 'la',
    category: 'Collège',
    commentId: 2,
    hn: ['3ème']
  },
  {
    id: 12,
    label: '2nde',
    shortLabel: '2nde',
    code: '2NDE',
    age: 15,
    detpos1: 'de la',
    detpos2: 'à la',
    det: 'la',
    category: 'Lycée',
    commentId: 3,
    hn: ['2nde']
  },
  {
    id: 13,
    label: '1ère',
    shortLabel: '1ère',
    code: '1ERE',
    age: 16,
    detpos1: 'de la',
    detpos2: 'à la',
    det: 'la',
    category: 'Lycée',
    commentId: 3,
    hn: ['1ère']
  },
  {
    id: 14,
    label: 'Terminale',
    shortLabel: 'Terminale',
    code: 'TERM',
    age: 17,
    detpos1: 'de la',
    detpos2: 'à la',
    category: 'Lycée',
    commentId: 3,
    hn: ['Terminale']
  },
  {
    id: 90,
    label: '< Bac',
    code: 'ADULTE',
    category: 'other',
    commentId: 4,
    hn: ['MOINS QUE BAC', '< Bac']
  },
  {
    id: 91,
    label: '> Bac',
    code: 'ADULTE',
    category: 'other',
    commentId: 4,
    hn: ['> Bac', 'BAC ET PLUS']
  },
  {
    id: 92,
    label: '> Bac+3',
    code: 'ADULTE',
    category: 'other',
    commentId: 4,
    hn: ['BAC PLUS 3', '> Bac+3']
  },
  { id: 93, label: 'Bac Pro', code: 'ADULTE', category: 'other', commentId: 4, hn: ['Bac Pro'] },
  { id: 94, label: 'BEP / CAP', code: 'ADULTE', category: 'other', commentId: 4, hn: ['BEP / CAP'] },
  { id: 95, label: 'L1 (Bac +1)', code: 'ADULTE', category: 'other', commentId: 4, hn: ['L1 (Bac +1)'] },
  { id: 96, label: 'L2 (Bac +2)', code: 'ADULTE', category: 'other', commentId: 4, hn: ['L2 (Bac +2)'] },
  { id: 97, label: 'L3 (Bac +3)', code: 'ADULTE', category: 'other', commentId: 4, hn: ['L3 (Bac +3)'] },
  { id: 99, label: 'Adulte', code: 'ADULTE', category: 'other', commentId: 4, hn: ['> Bac'] },
  { id: 100, label: 'Autre...', category: 'other', commentId: 5, hn: ['Autre'] },
  { id: 101, label: 'M1 (Bac +4)', code: 'ADULTE', category: 'other', commentId: 4, hn: ['M1 (Bac +4)'] },
  { id: 102, label: 'M2 (Bac +5)', code: 'ADULTE', category: 'other', commentId: 4, hn: ['M2 (Bac +5)'] },
  { id: 103, label: 'Bac +6', code: 'ADULTE', category: 'other', commentId: 4, hn: ['Bac +6'] },
  { id: 104, label: 'Bac +7', code: 'ADULTE', category: 'other', commentId: 4, hn: ['Bac +7'] },
  { id: 105, label: 'Doctorat (Bac +8)', code: 'ADULTE', category: 'other', commentId: 4, hn: ['Doctorat (Bac +8)'] }
];

export const frenchClasses = classes;

export const getClassByCode = classCode => {
  const list = frenchClasses.filter(({ code }) => classCode === code);
  return list ? list[0] : {};
};

export const classesByCategory = classes.reduce((acc, obj) => {
  const cle = obj.category;
  if (!acc[cle]) {
    acc[cle] = [];
  }
  acc[cle].push(obj);
  return acc;
}, {});

export function classMatchConstraint(
  classCode: string,
  acceptableMinClassCode: string,
  acceptableMaxClassCode: string
): boolean {
  const current = getClassByCode(classCode);
  const min = acceptableMinClassCode !== undefined ? getClassByCode(acceptableMinClassCode) : undefined;
  const max = acceptableMaxClassCode !== undefined ? getClassByCode(acceptableMaxClassCode) : undefined;

  if (current && min && current.id < min.id) {
    return false;
  }
  return !(current && max && current.id > max.id);
}
