import React, { useState } from 'react';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { Add, GetApp } from '@material-ui/icons';
import uuidv4 from 'uuid/v4';
import { useHistory } from 'react-router';

import labels from '../../constants/labels/configuration/anamnesis/Anamnesis.json';
import AnamneseList from '../../components/configuration/anamnesis/AnamneseList';
import routes from '../../constants/routes';
import AnamnesisModel from '../../components/common/data/AnamnesisModel';
import { useWarnings, Warning } from '../../components/common/Warning';
import { uncompress, compress, download } from '../../actions/FileImportExport';

const AnamnesePage = () => {
  const [value, setValue] = useState(true);
  const [warnings, addWarning, clearWarnings, writeFileWarning] = useWarnings();
  const history = useHistory();

  const importAnamnesis = async (event) => {
    const filePath = event.target.files[0]
    if (!filePath) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const { data } = await uncompress(fileReader.result);
      const model = JSON.parse(data);
      console.log(model)
      model._id = uuidv4();
      model.customDefinitions = model.customDefinitions || {};
      await AnamnesisModel.save(model);
      setValue(!value);
    };
    fileReader.readAsArrayBuffer(filePath);
  };

  const exportAnamneseModel = async idToExport => {
    await clearWarnings();
    const modelToExport = await AnamnesisModel.get(idToExport);
    const filename = modelToExport.name;
    const { _id, _rev, ...cleanedModel } = modelToExport;
    try {
      const compressedContent = compress(JSON.stringify(cleanedModel, null, 4), 'FORMULAIRE-ANAMNESTIQUE');
      download(`${filename}.orthoform`, compressedContent);
    } catch (e) {
      console.error(e)
      await addWarning(writeFileWarning(filename));
    }
  };

  const createNew = async () => {
    const { name, ...anamneseModelProps } = AnamnesisModel.getDefault();
    const _id = uuidv4();
    await AnamnesisModel.save({ _id, name: `${labels.copyOf} ${name}`, ...anamneseModelProps });
    history.push(routes.CONFIGURATION.ANAMNESIS.getForPath(_id));
  };

  return (
    <Grid item md={12} container>
      <Grid item md={12}>
        <Typography variant="h6">{labels.mainHeader}</Typography>
        <Typography variant="subtitle2">{labels.secondHeader}</Typography>
        <Typography variant="body2">{labels.thirdHeader}</Typography>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={2}>
        <Button variant="contained" color="primary" onClick={createNew} startIcon={<Add />}>
          {labels.createModel}
        </Button>
      </Grid>
      <Grid item md={2}>
        <input
          id="contained-button-file"
          type="file"
          hidden
          onChange={importAnamnesis}
          accept=".orthoform"
        />
        <label htmlFor="contained-button-file">
          <Button variant="outlined" component="span" startIcon={<GetApp />}>
            {labels.importModel}
          </Button>
        </label>
      </Grid>
      <Warning warnings={warnings} />
      <Grid item md={8} />
      <AnamneseList key={value} exportAnamneseModel={exportAnamneseModel} />
    </Grid>
  );
};
export default AnamnesePage;
