import React, { useEffect, useState } from 'react';
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';

type Props = {
  label: string,
  defaultValue?: string,
  onValidateLabel: string => void,
  onCancelAdd: void => void
};

function SimpleTextEditingListItem({ label, defaultValue, onValidateLabel, onCancelAdd }: Props) {
  const [newLabel, setNewLabel] = useState('');

  useEffect(() => {
    setNewLabel(defaultValue);
  }, [defaultValue]);

  const catchReturn = event => {
    if (event.charCode === 13) {
      // enter key pressed
      event.preventDefault();
      addNewLabel();
    }
  };

  const addNewLabel = () => {
    onValidateLabel(newLabel);
    cancelAdd();
  };

  const cancelAdd = () => {
    setNewLabel('');
    onCancelAdd();
  };

  return (
    <ListItem>
      <ListItemText
        primary={
          <TextField
            label={label}
            value={newLabel}
            onChange={e => setNewLabel(e.target.value)}
            onKeyPress={catchReturn}
            fullWidth
            inputRef={input => input && input.focus()}
          />
        }
      />
      <ListItemSecondaryAction>
        <IconButton onClick={addNewLabel}>
          <Check />
        </IconButton>
        <IconButton onClick={cancelAdd}>
          <Clear />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

SimpleTextEditingListItem.defaultProps = {
  defaultValue: ''
};

export default SimpleTextEditingListItem;
