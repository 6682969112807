import React from 'react';
import { Route, Switch } from 'react-router';
import { Grid } from '@material-ui/core';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import routes from '../../constants/routes';
import PatientConsult from '../../components/patient/PatientConsult';
import PatientEdit from '../../components/patient/PatientEdit';
import TestListing from '../../components/patient/TestListing';
import TestSelection from '../../components/patient/TestSelection';
import RenewalCheckupSelection from '../../components/patient/renewalCheckup/RenewalCheckupSelection';
import RenewalCheckupLink from '../../components/patient/renewalCheckup/RenewalCheckupLink';
import RenewalCheckupCreate from '../../components/patient/renewalCheckup/RenewalCheckupCreate';
import RenewalCheckupLinkRemoval from '../../components/patient/renewalCheckup/RenewalCheckupLinkRemoval';
import TestFilling from '../../components/patient/TestFilling';
import CheckupComments from '../../components/patient/comments/CheckupComments';
import ReportGenerationPage from './ReportGenerationPage';
// import TestImport from '../../components/patient/TestImport';
import AnamnesisPage from './AnamnesisPage';
import TestListingByModel from '../../components/patient/test/TestListingByModel';

const PatientPage = () => (
  <Grid container>
    <Grid item xs={12}>
      <DndProvider backend={HTML5Backend}>
        <Switch>
          <Route exact path={`${routes.PATIENT.MAIN}`} component={PatientConsult} />
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.CONSULT}`} component={PatientConsult} />
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.EDIT}`} component={PatientEdit} />
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.ANAMNESIS.MAIN}`} component={AnamnesisPage} />
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.TESTS.SELECT}`} component={TestSelection} />
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.TESTS.TESTS}`} component={TestListing} />
          <Route
            path={`${routes.PATIENT.MAIN}${routes.PATIENT.TESTS.LISTING_BY_MODEL}`}
            component={TestListingByModel}
          />
          <Route exact path={`${routes.PATIENT.MAIN}${routes.PATIENT.TESTS.FILLING}`} component={TestFilling} />
          {/* <Route exact path={`${routes.PATIENT.MAIN}${routes.PATIENT.TESTS.IMPORT_TEST}`} component={TestImport} /> */}
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.REPORT.GENERATE}`} component={ReportGenerationPage} />
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.RENEWAL.CHOOSE}`} component={RenewalCheckupSelection} />
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.RENEWAL.LINK}`} component={RenewalCheckupLink} />
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.RENEWAL.CREATE}`} component={RenewalCheckupCreate} />
          <Route
            path={`${routes.PATIENT.MAIN}${routes.PATIENT.RENEWAL.REMOVE}`}
            component={RenewalCheckupLinkRemoval}
          />
          <Route path={`${routes.PATIENT.MAIN}${routes.PATIENT.CHECKUP.COMMENTS}`} component={CheckupComments} />
        </Switch>
      </DndProvider>
    </Grid>
  </Grid>
);

export default PatientPage;
