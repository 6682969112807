import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import FrownIcon from '../../../common/anamnesis/icons/FrownIcon';
import MehIcon from '../../../common/anamnesis/icons/MehIcon';
import SmileIcon from '../../../common/anamnesis/icons/SmileIcon';
import type { ParcoursScolaireType } from './ParcoursScolaireType';

const HeadIconTrio = ({
  changeHandler,
  id,
  index,
  type,
  myValue
}: {
  changeHandler: Event => void,
  id: string,
  index?: number,
  type: string,
  myValue: ParcoursScolaireType
}) => {
  const updateValue = value => {
    const appreciations = [...myValue.appreciation];
    appreciations[index][type] = value;
    changeHandler({
      target: {
        name: `${id}.appreciation[${index}].${type}`,
        value: {
          ...myValue,
          appreciation: [...appreciations]
        }
      }
    });
  };

  return (
    <Grid item xs={4}>
      <IconButton onClick={() => updateValue(0)}>
        {myValue.appreciation[index][type] === 0 ? <FrownIcon color="orange" /> : <FrownIcon />}
      </IconButton>
      <IconButton onClick={() => updateValue(5)}>
        {myValue.appreciation[index][type] === 5 ? <MehIcon color="blue" /> : <MehIcon />}
      </IconButton>
      <IconButton onClick={() => updateValue(10)}>
        {myValue.appreciation[index][type] === 10 ? <SmileIcon color="green" /> : <SmileIcon />}
      </IconButton>
    </Grid>
  );
};
HeadIconTrio.defaultProps = { index: 0 };

export default HeadIconTrio;
