import React, { Fragment, useEffect, useState } from 'react';
import { Button, Divider, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as Tests from '../../../constants/tests/tests';
import labels from '../../../constants/configuration/tests/TestList.json';
import User from '../../common/data/User';
import log from '../../Logger';
import SelectAllCheckBox from '../../common/SelectAllCheckBox';
import TestsOfLabelList from './TestsOfLabelList';
import routes from '../../../constants/routes';

const TestList = () => {
  const [tests, setTests] = useState([]);
  const [ageTarget, setAgeTarget] = useState(true);
  const [selectAll, setSelectAll] = useState({ checked: false, indeterminate: false });

  const loadTests = async () => {
    const mtests = await User.retrieveValueFromDb({ keyValue: 'tests', defaultValue: [] });
    setTests(mtests.val);
    setSelectAll({
      indeterminate: !!mtests.val.length && mtests.val.length < Tests.filteredLabeledTests.length,
      checked: mtests.val.length === Tests.filteredLabeledTests.length
    });
  };

  useEffect(() => {
    loadTests();
  }, []);

  const handleToggle = value => async () => {
    const currentIndex = tests.map(({ testCode }) => testCode).indexOf(value);
    const newTests = [...tests];

    if (currentIndex === -1) {
      newTests.push({ testCode: value });
    } else {
      newTests.splice(currentIndex, 1);
    }

    try {
      await User.save({ keyValue: 'tests', value: newTests });
      await loadTests();
    } catch (err) {
      log.error('Error updating selected user tests', err);
    }
  };

  const allTestsByType = Tests.filteredLabeledTests.reduce((acc, obj) => {
    const cle = obj.type;
    if (!acc[cle]) {
      acc[cle] = [];
    }
    acc[cle].push(obj);
    return acc;
  }, {});

  const testsByType = [
    { label: labels.types.LE, tests: allTestsByType.LE || [] },
    {
      label: labels.types.LE_LO,
      tests: allTestsByType['LE/LO'] || []
    },
    { label: labels.types.LO, tests: allTestsByType.LO || [] },
    {
      label: labels.types.OTHER,
      tests: allTestsByType.Autre || []
    }
  ];

  const addSelectedTests = async testListToAdd => {
    const formattedListToAdd = testListToAdd
      .map(({ testCode }) => ({ testCode }))
      .filter(({ testCode: myTestCode }) => tests.map(({ testCode }) => testCode).indexOf(myTestCode) === -1);

    try {
      await User.save({ keyValue: 'tests', value: [...tests, ...formattedListToAdd] });
      await loadTests();
    } catch (err) {
      log.error('Error updating selected user tests', err);
    }
  };

  const removeSelectedTests = async testListToRemove => {
    const newTestList = tests.filter(
      ({ testCode: myTestCode }) => testListToRemove.map(({ testCode }) => testCode).indexOf(myTestCode) === -1
    );

    try {
      await User.save({ keyValue: 'tests', value: [...newTestList] });
      await loadTests();
    } catch (err) {
      log.error('Error updating selected user tests', err);
    }
  };

  const selectAllValues = [
    { label: labels.all_LE, fn: () => addSelectedTests(allTestsByType.LE) },
    { label: labels.all_LE_LO, fn: () => addSelectedTests(allTestsByType['LE/LO']) },
    { label: labels.all_LO, fn: () => addSelectedTests(allTestsByType.LO) },
    { label: labels.all_OTHER, fn: () => addSelectedTests(allTestsByType.Autre) }
  ];

  return (
    <Fragment>
      <Grid item md={12}>
        <Typography variant="h6">{labels.mainHeader}</Typography>
        <Typography variant="subtitle2">{labels.secondHeader}</Typography>
        <Typography variant="body2">{labels.thirdHeader}</Typography>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={12} container>
        <Grid item md={7} />
        <Grid item md={4} container>
          <Grid item md={2}>
            <SelectAllCheckBox
              selectAll={selectAll}
              addAllValues={async () => {
                await addSelectedTests(Tests.filteredLabeledTests);
              }}
              removeAllValues={async () => {
                await removeSelectedTests(Tests.filteredLabeledTests);
              }}
              values={selectAllValues}
            />
          </Grid>
          <Grid item md={10}>
            <FormControlLabel
              control={
                <Switch checked={ageTarget} onChange={event => setAgeTarget(event.target.checked)} value="ageTarget" />
              }
              label={labels.displayAgeTarget}
            />
          </Grid>
        </Grid>
        <Grid item md={1}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.OPTIONS)}
          >
            {labels.includedTestsOptions}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-evenly" alignItems="stretch" spacing={3}>
        {testsByType.map(({ label, tests: allTestsOfLabel }) => (
          <TestsOfLabelList
            key={label}
            label={label}
            allTestsOfLabel={allTestsOfLabel}
            tests={tests}
            handleToggle={handleToggle}
            ageTarget={ageTarget}
            addSelectedTests={addSelectedTests}
            removeSelectedTests={removeSelectedTests}
          />
        ))}
      </Grid>
    </Fragment>
  );
};

export default TestList;
