import uuidv4 from 'uuid/v4';
import moment from 'moment';
import dateFormat from 'dateformat';

import localDatabases from '../../../database/database';
import Bilan from './Bilan';
import Anamnesis from './Anamnesis';
import Cotation from './Cotation';
import PatientUtil from './PatientUtil';
import log from '../../Logger';
import Comments from './Comments';
import checkupTypes from '../../../constants/CheckupTypes.json';

export const defaultPatient = async () => {
  const patientId = uuidv4();
  const bilanId = uuidv4();
  const anamnesisId = uuidv4();
  const cotationId = uuidv4();
  const bilan = await Bilan.getNewEmptyOne({ bilanId, patientId, anamnesisId });
  const anamnese = Anamnesis.getNewEmptyOne({ anamnesisId, patientId, bilanId });
  const cotation = Cotation.getNewEmptyOne({ cotationId, bilanId });
  const comments = await Comments.getNewEmptyOne();
  return {
    _id: patientId,
    firstName: '',
    lastName: '',
    _rev: '',
    sexe: '',
    birthDay: `${moment('2000-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD')}`,
    curclasse: '0',
    curclassefree: '',
    plainte: '',
    rdvDay: `${moment(new Date(), 'YYYY-MM-DD')
      .add(1, 'day')
      .format('YYYY-MM-DD')}`,
    rdvTime: '09:00',
    rdvDuration: '01:00',
    typeBilan: '',
    typeTests: '',
    contactFirstName: '',
    contactLastName: '',
    contactSexe: '',
    contactLien: '',
    contactTel: '',
    contactMail: '',
    rsaPrivateKey: '',
    rsaPublicKey: '',
    rsaKeySize: '',
    registration: '',
    lookup: '',
    modificationDate: '',
    creationDate: '',
    meta: '',
    bilan,
    anamnese,
    cotation,
    comments
  };
};

export default class Patient {
  static TYPE = 'patients';

  static async getRenewalAssessmentPatients(initialAssessmentUUID) {
    return Patient.getInitOrRenewalAssessmentPatient(initialAssessmentUUID, 'initialAssessmentUUID');
  }

  static async getInitialAssessmentPatient(initialAssessmentUUID) {
    const docs = await Patient.getInitOrRenewalAssessmentPatient(initialAssessmentUUID, 'uuid');
    if (docs && docs.length > 0) {
      return docs[0];
    }
    return null;
  }

  static async getHappyneuronPatient(hnUserId) {
    const find = {
      selector: {
        $or: [{ [`meta.hn.uid`]: hnUserId }],
        dbtype: PatientUtil.TYPE
      }
    };
    const { docs } = await localDatabases.getInstance().v1databaseRepository.find(find);
    if (docs && docs.length > 0) {
      return docs.map(({ _id, ...patient }) => ({ ...patient, _id: PatientUtil.getId(_id) }));
    }
    return [];
  }

  static async getInitOrRenewalAssessmentPatient(initialAssessmentUUID, key) {
    const find = {
      selector: {
        $or: [
          { [`bilan.${key}`]: initialAssessmentUUID },
          { [`bilan.${key}`]: `{${initialAssessmentUUID}}` },
          { [`bilan._id`]: initialAssessmentUUID },
          { [`bilan.id`]: initialAssessmentUUID }
        ],
        dbtype: PatientUtil.TYPE
      }
    };
    const { docs } = await localDatabases.getInstance().v1databaseRepository.find(find);
    if (docs && docs.length > 0) {
      return docs.map(({ _id, ...patient }) => ({ ...patient, _id: PatientUtil.getId(_id) }));
    }
    return null;
  }

  static async getPatientList(archived, q, sortField = 'firstName', sortOrder = 'asc', pageNumber = 1, pageSize) {
    // eslint-disable-next-line no-nested-ternary
    const value = archived && q ? 'a&q' : archived ? 'a' : q ? 'q' : 'none';

    const { docs: notPatientsss } = await localDatabases.getInstance().v1databaseRepository.find({
      selector: { $or: [{ $not: { dbtype: PatientUtil.TYPE } }, { dbtype: { $exists: false } }] }
    });

    console.log({ notPatientsss });

    const find = {
      'a&q': {
        selector: {
          dbtype: PatientUtil.TYPE,
          archived: { $eq: archived },
          lookup: { $regex: new RegExp(`.*${q}.*`, 'i') },
          none: { $lt: false }
        }
      },
      a: {
        selector: {
          dbtype: PatientUtil.TYPE,
          archived: { $eq: archived }
        }
      },
      q: {
        selector: {
          dbtype: PatientUtil.TYPE,
          $or: [{ archived: { $eq: false } }, { archived: { $exists: false } }],
          lookup: { $regex: new RegExp(`.*${q}.*`, 'i') },
          none: { $lt: false }
        }
      },
      none: {
        selector: {
          dbtype: PatientUtil.TYPE,
          $or: [{ archived: { $eq: false } }, { archived: { $exists: false } }]
        }
      }
    }[value];

    const { docs } = await localDatabases.getInstance().v1databaseRepository.find(find);

    if (sortOrder === 'asc') {
      docs.sort((a, b) => (a[sortField] && b[sortField] ? a[sortField].localeCompare(b[sortField]) : -1));
    } else {
      docs.sort((a, b) => (a[sortField] && b[sortField] ? b[sortField].localeCompare(a[sortField]) : 1));
    }

    const size = pageNumber === 1 && !pageSize ? docs.length : pageSize;
    const patients = docs
      .slice((pageNumber - 1) * size, pageNumber * size)
      .map(({ _id, ...patient }) => ({ ...patient, _id: PatientUtil.getId(_id) }));
    log.info('Patient.getPatientList(), patients : ');
    log.table(patients);
    return { totalLength: docs.length, patients };
  }

  static async findAll() {
    try {
      const { docs } = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          dbtype: PatientUtil.TYPE
        }
      });
      // patient._id = PatientUtil.getId(patientId);
      log.info('Patient.findAll(), patients: ', docs);
      return docs.map(({ _id, ...patient }) => ({ ...patient, _id: PatientUtil.getId(_id) }));
    } catch (err) {
      log.error('Error retrieving patients', err);
      throw err;
    }
  }

  static async retrievePatient(patientId) {
    const _id = PatientUtil.setId(patientId);
    try {
      const patient = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id,
          dbtype: PatientUtil.TYPE
        }
      });
      // patient._id = PatientUtil.getId(patientId);
      log.info('Patient.retrievePatient(), patient: ', patient);
      if (!patient.docs[0]) return {};
      const { label: typeTestsString } =
        (patient.docs[0] && checkupTypes.filter(({ id }) => id === patient.docs[0].typeTests)[0]) || {};
      return (
        {
          ...patient.docs[0],
          _id: PatientUtil.getId(patientId),
          typeTestsString
        } || {}
      );
    } catch (err) {
      log.error('Error retrieving patient', err);
      throw err;
    }
  }

  static async retrievePatientWithRawId(patientId) {
    const _id = PatientUtil.setId(patientId);
    try {
      const patient = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id,
          dbtype: PatientUtil.TYPE
        }
      });
      log.info('Patient.retrievePatient(), patient: ', patient);
      return patient.docs[0] || {};
    } catch (err) {
      log.error('Error retrieving patient', err);
      throw err;
    }
  }

  static async updatePatient(patientToSave, doNotUpdateModificationDate) {
    const { _id, ...patient } = patientToSave;
    patient.dbtype = PatientUtil.TYPE;
    patient._id = PatientUtil.setId(_id);
    patient.lookup = `${patient.firstName} ${patient.lastName}`;
    if (!doNotUpdateModificationDate) {
      patient.modificationDate = dateFormat(new Date(), 'isoUtcDateTime');
    }
    const patientWithDefaultValues = Patient.setDefaultValueIfNull(patient);
    const updatedPatient = await localDatabases.getInstance().v1databaseRepository.put(patientWithDefaultValues);
    patient._rev = updatedPatient.rev;
    patient._id = _id;
    log.info('Patient.updatePatient(), patient :', patient);
    return patient;
  }

  static setDefaultValueIfNull(patient) {
    const birthDay = patient.birthDay || `${moment('2000-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD')}`;
    const rdvDay =
      patient.rdvDay ||
      `${moment(new Date(), 'YYYY-MM-DD')
        .add(1, 'day')
        .format('YYYY-MM-DD')}`;
    const rdvTime = patient.rdvTime || '09:00';
    const rdvDuration = patient.rdvDuration || '01:00';
    return { ...patient, birthDay, rdvDay, rdvTime, rdvDuration };
  }

  static async createPatient(patientToSave, renewal = null, initialAssessment = {}, initialAnamnesis = {}) {
    const { _id, ...patient } = patientToSave;
    patient.lookup = `${patient.firstName} ${patient.lastName}`;
    patient.dbtype = PatientUtil.TYPE;
    patient._id = PatientUtil.setId(_id || uuidv4());
    patient.comments = await Comments.getNewEmptyOne(true, patient.firstName, patient.lastName);
    const patientWithDefaultValues = Patient.setDefaultValueIfNull(patient);
    const updatedPatient = await localDatabases.getInstance().v1databaseRepository.put(patientWithDefaultValues);
    patient._rev = updatedPatient.rev;
    patient._id = updatedPatient.id;
    log.info('Patient.createPatient(), patient :', patient);
    const bilanId = uuidv4();
    const anamnesisId = uuidv4();
    const cotationId = uuidv4();

    patient.bilan = await Bilan.getNewEmptyOne({
      bilanId,
      patientId: patient._id,
      anamneseId: anamnesisId,
      initialAssessmentTests: initialAssessment.tests || null,
      initialAssessmentUUID: initialAssessment.uuid || null,
      renewalNumber: renewal
    });

    patient.anamnese = Anamnesis.getNewEmptyOne({
      anamnesisId,
      bilanId,
      patientId: patient._id,
      definitions: initialAnamnesis.definitions,
      values: initialAnamnesis.values
    });
    patient.cotation = Cotation.getNewEmptyOne({ cotationId, bilanId });
    return patient;
  }

  static async setArchived(patientId, archived) {
    const patient = await Patient.retrievePatient(patientId);
    patient.archived = archived;
    patient.lookup = `${patient.firstName} ${patient.lastName}`;
    await Patient.updatePatient(patient);
  }

  static async setColor(patientId, color) {
    const patient = await Patient.retrievePatient(patientId);
    if (!patient.bilan.displayInfo) {
      patient.bilan.displayInfo = {};
    }

    if (typeof patient.bilan.displayInfo === 'string') {
      patient.bilan.displayInfo = JSON.parse(patient.bilan.displayInfo);
    }
    patient.bilan.displayInfo.rowColor = color;
    patient.lookup = `${patient.firstName} ${patient.lastName}`;
    await Patient.updatePatient(patient, true);
  }

  static async delete(patientId) {
    const patient = await Patient.retrievePatientWithRawId(patientId);
    try {
      await localDatabases.getInstance().v1databaseRepository.remove(patient);
    } catch (err) {
      log.info('unable to delete patient', { err });
    }
  }

  static async regenerateLookupField() {
    const patients = await Patient.findAll();
    console.log({ patients });
    patients.map(patient => Patient.updatePatient(patient, true));
  }

  static async deleteElement(_id) {
    const element = await localDatabases.getInstance().v1databaseRepository.get(_id);
    await localDatabases.getInstance().v1databaseRepository.remove(element);
  }
}
