const get = (key, defaultValue) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + key + "=");
  if (parts.length === 2) return window.unescape(parts.pop().split(";").shift());
  return defaultValue;
}

const getAsBoolean = (key, defaultValue) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + key + "=");
  if (parts.length === 2) {
    const s = parts.pop().split(";").shift();
    return s === "true";
  }
  return defaultValue;
}

const set = (key, value) => {
  document.cookie=`${key}=${window.escape(value)};`
}

const mdelete = (key) => {
  document.cookie=`${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
}

const MyStore = {get, set, delete: mdelete, getAsBoolean};
export default MyStore;
