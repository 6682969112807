import React, { Component } from 'react';
import { Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import _ from 'lodash';

import routes from '../../../constants/routes';
import labels from '../../../constants/labels/configuration/anamnesis/Anamnesis.json';
import TextFieldFormControl from '../../TextFieldFormControl';
import EmailEdition from './EmailEdition';
import FormEdition from './FormEdition';
import ConfigurationEdition from './ConfigurationEdition';
import AnamnesisModel from '../../common/data/AnamnesisModel';
import log from '../../Logger';
import BackButton from '../../common/BackButton';

type Props = {
  id: string
};

class AnamneseEdit extends Component<Props> {
  props: Props;

  state = {
    anamneseModel: AnamnesisModel.getDefault(),
    selectedTabIndex: 0
  };

  async componentDidMount() {
    const { id } = this.props;
    if (id !== 'new') {
      try {
        await this.updateFromDb(id, true);
      } catch (err) {
        log.error('Error retrieving checkUpModel data', err);
      }
    }
  }

  async updateFromDb(id, withTabSwitch) {
    const anamneseModel = await AnamnesisModel.get(id);
    const { selectedTabIndex } = this.state;
    let nextSelectedTabIndex: number;
    if (withTabSwitch) {
      nextSelectedTabIndex = this.getBorderedTabIndex(selectedTabIndex);
    } else {
      nextSelectedTabIndex = selectedTabIndex;
    }
    this.setState({
      anamneseModel,
      selectedTabIndex: nextSelectedTabIndex
    });
    log.info('componentDidMount, anamneseModel.get, anamneseModel: ', anamneseModel);
    if (withTabSwitch) {
      setTimeout(() => {
        this.setState({ selectedTabIndex });
      }, 50);
    }
  }

  getBorderedTabIndex = selectedTabIndex => (selectedTabIndex + 1 <= 2 ? selectedTabIndex + 1 : selectedTabIndex - 1);

  handleTabChange = (event, value) => {
    this.setState({ selectedTabIndex: value });
  };

  resetLoginMessage = () => {
    const value = labels.tabs.config.right.defaultLoginMessage;
    this.handleChange({ target: { name: 'loginMessage', value } });
    const { selectedTabIndex } = this.state;
    this.setState({ selectedTabIndex: selectedTabIndex + 1 <= 2 ? selectedTabIndex + 1 : selectedTabIndex - 1 });
    setTimeout(() => {
      this.setState({ selectedTabIndex });
    }, 50);
  };

  handleChange = event => {
    let { anamneseModel } = this.state;
    anamneseModel = _.set(anamneseModel, event.target.name, event.target.value);
    this.setState({ anamneseModel });
  };

  handleSubmit = withTabSwitch => async event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    const { anamneseModel } = this.state;
    await AnamnesisModel.save(anamneseModel);
    await this.updateFromDb(anamneseModel._id, withTabSwitch);
  };

  render() {
    const {
      anamneseModel: { name, mailBody, mailSubject, definitions, loginMessage, disableAddressDisplay },
      selectedTabIndex
    } = this.state;
    return (
      <Grid item md={12}>
        <BackButton backRoute={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.ANAMNESIS.MAIN)} />
        <Typography variant="h6">{labels.mainHeader}</Typography>
        <Divider />
        <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
          <TextFieldFormControl
            id="anamneseModel.name"
            controlName="name"
            value={name}
            itemMdValue={12}
            fullWidth
            label={labels.modelTitle}
            onChangeHandler={this.handleChange}
            onBlurHandler={this.handleSubmit(false)}
          />
          <Grid item xs={12}>
            <Tabs value={selectedTabIndex} onChange={this.handleTabChange} fullWidth>
              <Tab label={labels.tabs.email.tabTitle} />
              <Tab label={labels.tabs.form.tabTitle} />
              <Tab label={labels.tabs.config.tabTitle} />
            </Tabs>
          </Grid>
          {selectedTabIndex === 0 && (
            <EmailEdition
              mailBody={mailBody}
              mailSubject={mailSubject}
              onChange={this.handleChange}
              onBlur={this.handleSubmit(true)}
            />
          )}
          {selectedTabIndex === 1 && (
            <FormEdition
              definitions={definitions || []}
              onChange={e => {
                this.handleChange(e);
                this.handleSubmit(false)(e);
              }}
            />
          )}
          {selectedTabIndex === 2 && (
            <ConfigurationEdition
              loginMessage={loginMessage}
              disableAddressDisplay={disableAddressDisplay}
              onChange={this.handleChange}
              onBlur={this.handleSubmit(true)}
              resetLoginMessage={this.resetLoginMessage}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default AnamneseEdit;
