import React, { Component } from 'react';

import {
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  IconButton,
  Tooltip,
  InputAdornment
} from '@material-ui/core';
import { Announcement, AddCircle, RemoveCircle } from '@material-ui/icons';
import uuidv4 from 'uuid/v4';

import AnamnesisElement from './AnamnesisElement';
import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis.json';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class CustomRadio extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    return <AnamnesisElement {...props} editableElement={editableElement} readOnlyElement={readOnlyElement} />;
  }
}

function editableElement(element, handleChange) {
  const { label, reportLabelType, reportLabel, options } = element;

  function addOption() {
    handleChange({
      target: {
        name: `options[${options.length}]`,
        value: { id: uuidv4(), label: 'Option' }
      }
    });
  }

  function removeOption(optionId) {
    handleChange({
      target: {
        name: `options`,
        value: options.filter(({ id }) => id !== optionId)
      }
    });
  }

  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <TextField InputLabelProps={{ shrink: true }} value={label} fullWidth name="label" onChange={handleChange} />
      </Grid>
      {options.map(({ id = '', label: optionLabel }, index) => (
        <Grid item xs={12} container>
          <Grid item xs={2}>
            <Radio disabled />
          </Grid>
          <Grid item xs={10}>
            <TextField
              key={id}
              InputLabelProps={{ shrink: true }}
              value={optionLabel}
              fullWidth
              name={`options[${index}].label`}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => removeOption(id)}>
                      <RemoveCircle />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} container>
        <Tooltip title={labels.tabs.form.right.customRadio.addOption}>
          <IconButton onClick={addOption}>
            <AddCircle />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{labels.tabs.form.right.customInput.radioLabel}</FormLabel>
          <RadioGroup value={reportLabelType} name="reportLabelType" onChange={handleChange}>
            <FormControlLabel value="reuse" control={<Radio />} label={labels.tabs.form.right.customInput.option1} />
            <FormControlLabel value="set" control={<Radio />} label={labels.tabs.form.right.customInput.option2} />
            <Tooltip title={labels.tabs.form.right.customInput.noteBene}>
              <Announcement />
            </Tooltip>
          </RadioGroup>
        </FormControl>
      </Grid>
      {reportLabelType === 'set' && (
        <Grid item xs={12}>
          <TextField value={reportLabel} fullWidth name="reportLabel" onChange={handleChange} />
        </Grid>
      )}
    </Grid>
  );
}

function readOnlyElement(element) {
  const { label, reportLabelType, reportLabel, options } = element;
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormLabel component="legend">{label}</FormLabel>
        <FormControl component="fieldset">
          <RadioGroup value={reportLabelType}>
            {options.map(({ id = '', label: optionLabel }) => (
              <FormControlLabel key={id} value="label" control={<Radio />} disabled label={optionLabel} />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{labels.tabs.form.right.customInput.radioLabel}</FormLabel>
          <RadioGroup value={reportLabelType}>
            <FormControlLabel
              value="reuse"
              control={<Radio />}
              disabled
              label={labels.tabs.form.right.customInput.option1}
            />
            <FormControlLabel
              value="set"
              control={<Radio />}
              disabled
              label={labels.tabs.form.right.customInput.option2}
            />
            <Tooltip title={labels.tabs.form.right.customInput.noteBene}>
              <Announcement />
            </Tooltip>
          </RadioGroup>
        </FormControl>
      </Grid>
      {reportLabelType === 'set' && (
        <Grid item xs={12}>
          <TextField disabled value={reportLabel} />
        </Grid>
      )}
    </Grid>
  );
}
