import { compress, download } from './FileImportExport';
import Patient from '../components/common/data/Patient';
import log from '../components/Logger';

async function exportPatient(patientId, fileName) {
  await exportCleanedPatient(patientId, fileName, patient => patient);
}

async function exportAnonymousPatient(patientId, fileName) {
  await exportCleanedPatient(patientId, fileName, anonymisePatient, true);
}

async function exportCleanedPatient(patientId, fileName, dataCleanFunction, anonymous) {
  const { _id: pId, _rev: pRev, meta, ...patient } = await Patient.retrievePatient(patientId.toString());
  const { anamnese: anamnesis, bilan, cotation, comments } = patient;

  const data = {};
  data.patientUUID = patient.uuid || pId;
  data.anamneseUUID = anamnesis.uuid;
  data.bilanUUID = bilan.uuid;
  data.jsonPatient = JSON.stringify(dataCleanFunction(patient), null, 2);
  data.jsonAnamnese = JSON.stringify(anamnesis, null, 2);
  data.jsonBilan = JSON.stringify(bilan, null, 2);
  data.jsonCotations = JSON.stringify(cotation, null, 2);
  data.reportComments = JSON.stringify(comments, null, 2);
  data.stamp = Date.now();
  log.info(data);
  const content = await compress(JSON.stringify(data), anonymous ? 'ANONYMIZEDPATIENT' : 'PATIENT');
  download(fileName, content);
}

function anonymisePatient(patient) {
  const { ...cleanedPatient } = patient;
  delete cleanedPatient.contactFirstName;
  delete cleanedPatient.contactLastName;
  delete cleanedPatient.contactLien;
  delete cleanedPatient.lookup;
  cleanedPatient.firstName = '';
  cleanedPatient.lasstName = '';
  return cleanedPatient;
}

export { exportPatient, exportAnonymousPatient };
