// @flow

const defaultOptions = {
  mainChartFont: {
    family: 'Arial',
    size: 12
  },
  gfxcolor1: '#90faa4',
  gfxcolor2: '#d7fca4',
  gfxcolor3: '#feffa7',
  gfxcolor4: '#f7cd76',
  gfxcolor5: '#f29c4b',
  gfxcolor6: '#b95e29',
  mainChartFormat: 'vertical-columns',
  mainChartOptions: {
    verticalColumnsMaxColumns: '20',
    verticalColumnsSort: true,
    verticalColumnsSubtype: 'detailed',
    drawColumnsSeparator: false,
    verticalPointsSubtype: 'nopoint',
    verticalPointsDrawLine: false,
    verticalPointsRadius: 1,
    verticalPointsLineColor: '#555555',
    verticalPointsWidth: 6,
    verticalPointsBarColor: '#CCCCCC',
    verticalPointsMinMaxWidth: 25
  },
  renewalOptions: {
    useMarker: 'none',
    markerColor: '#000000'
  },
  legendType: 'internal',
  legendOptions: {
    averageDetails: true,
    aboveAverage: 'Excellent résultat',
    averageHigh: 'Résultat dans la moyenne haute',
    averageLow: 'Résultat dans la moyenne basse',
    average: 'Résultat dans la norme',
    belowAverage: 'Zone de fragilité',
    pathological: 'Zone de difficulté',
    srslypathological: 'Zone de difficulté sévère'
  }
};

export default defaultOptions;

export type Color = string;

export type ReportGraphicOptions = {
  mainChartFont: {
    family: string,
    size: number
  },
  gfxcolor1: Color,
  gfxcolor2: Color,
  gfxcolor3: Color,
  gfxcolor4: Color,
  gfxcolor5: Color,
  gfxcolor6: Color,
  mainChartFormat: 'vertical-columns' | 'vertical-columns-2' | 'vertical-points',
  mainChartOptions: {
    verticalColumnsMaxColumns: string,
    verticalColumnsSort: boolean,
    verticalColumnsSubtype: 'detailed' | 'average',
    drawColumnsSeparator: boolean,
    verticalPointsSubtype: 'nopoint' | 'minmax',
    verticalPointsDrawLine: boolean,
    verticalPointsRadius: number,
    verticalPointsLineColor: Color,
    verticalPointsWidth: number,
    verticalPointsBarColor: Color,
    verticalPointsMinMaxWidth: number
  },
  renewalOptions: {
    useMarker: 'none' | 'diamond',
    markerColor: Color
  },
  legendType: 'legacy' | 'internal',
  legendOptions: {
    averageDetails: boolean,
    aboveAverage: string,
    averageHigh: string,
    averageLow: string,
    average: string,
    belowAverage: string,
    pathological: string,
    srslypathological: string
  },
  graphVScale: 'exalang' | 'linear' | 'nearlog' | undefined
};
