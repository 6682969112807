import {inputIncludesTempsOrTempsET} from "../../testUtils/calculateValue/TimeOrTimeStandardDeviationValue";

const calculateRawResult = (eprId, workout, data, input) => {
  const splitted = eprId.split('_');
  const gameId = splitted[1];

  const workoutResult = workout.results.filter(({ gameId: gId }) => gId === gameId || gId === Number(gameId)).shift();
  if (!workoutResult) {
    return { eprId, score: undefined, annotation: undefined };
  }

  const { score, playTime } = workoutResult;
  if (inputIncludesTempsOrTempsET(input)) {
    return { eprId, score: playTime/1000 };
  }
  if (score === -1) {
    return { eprId, score: undefined, annotation: undefined };
  }

  return { eprId, score };
};

export default calculateRawResult;
