import { getLocation } from "./exalangCommonImport";
import executeImport from "./examath58/ExecuteImport";
import { jsonDownload } from '../../import/utils/HappyneuronFileDownloader';

const parseFile = async ({filePath, userId, ticket, programId}) => {
  const fileContent = await jsonDownload({userId, ticket, programId});

  const classe = getExamath58Class(fileContent.user, fileContent.classes);

  return fileContent.workouts.map(workout => ({
    firstname: fileContent.user.firstName,
    lastname: fileContent.user.lastName,
    birthDate: fileContent.user.birthDate,
    passationDate: workout.startDate,
    passationNumber: workout.id,
    data: { workout, scores: fileContent.scores },
    hnWorkoutId: workout.id,
    classe,
    text: `${fileContent.user.firstName} ${fileContent.user.lastName} (${workout.startDate})`
  }));
};

const getExamath58Class = (user, classes) => {
  const classe = Object.values(classes)
    .filter(({ classId }) => user.class === classId)
    .pop();
  return classe ? classe.name : '';
};

const Examath58Import = () => ({ getLocation, parseFile, executeImport });
export default Examath58Import;
