import React from 'react';
import { Route, Switch } from 'react-router';
import routes from '../../constants/routes';
import AnamnesisFormCompletion from '../../components/patient/anamnesis/AnamnesisFormCompletion';
import AnamnesisFormSend from '../../components/patient/anamnesis/AnamnesisFormSend';
import AnamnesisMailResult from '../../components/patient/anamnesis/AnamnesisMailResult';
import AnamnesisModelSelection from '../../components/patient/anamnesis/AnamnesisModelSelection';
import AnamnesisMailRecap from '../../components/patient/anamnesis/AnamnesisMailRecap';

const AnamnesisPage = () => (
  <Switch>
    <Route
      exact
      path={`${routes.PATIENT.MAIN}${routes.PATIENT.ANAMNESIS.RESPONSE}`}
      component={AnamnesisFormCompletion}
    />
    <Route exact path={`${routes.PATIENT.MAIN}${routes.PATIENT.ANAMNESIS.default}`} component={AnamnesisFormSend} />
    <Route
      exact
      path={`${routes.PATIENT.MAIN}${routes.PATIENT.ANAMNESIS.MAIL_RESULT}`}
      component={AnamnesisMailResult}
    />
    <Route
      exact
      path={`${routes.PATIENT.MAIN}${routes.PATIENT.ANAMNESIS.changeModel}`}
      component={AnamnesisModelSelection}
    />
    <Route exact path={`${routes.PATIENT.MAIN}${routes.PATIENT.ANAMNESIS.MAIL_RECAP}`} component={AnamnesisMailRecap} />
  </Switch>
);

export default AnamnesisPage;
