import React, { Component } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { AccessTime, HourglassFull } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import labels from '../../../constants/labels/patient/edit/Checkup.json';
import checkupTypes from '../../../constants/CheckupTypes.json';
import TextFieldFormControl from '../../TextFieldFormControl';
import FormControlWithLegend from './FormControlWithLegend';

type Props = {
  patient?: {
    rdvDay?: string,
    rdvTime?: string,
    typeTests?: string,
    rdvDuration?: string
  },
  checkupTypeVisible?: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function
};

export default class Checkup extends Component<Props> {
  props: Props;

  static defaultProps = {
    checkupTypeVisible: true,
    patient: {
      rdvDay: '',
      rdvTime: '',
      typeTests: '',
      rdvDuration: ''
    }
  };

  render() {
    const { patient: { rdvDay, rdvTime, rdvDuration, typeTests = '' } = {}, onChange, checkupTypeVisible } = this.props;
    const momentRdvDay = moment(rdvDay, 'YYYY-MM-DD');

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <FormControlWithLegend legend={labels.checkup}>
          <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
            <Grid item xs={6}>
              <KeyboardDatePicker
                label={labels.date}
                format="DD/MM/YYYY"
                value={momentRdvDay}
                animateYearScrolling
                onChange={date =>
                  onChange({ target: { value: date ? date.format('YYYY-MM-DD') : '', name: 'rdvDay' } })
                }
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                  'aria-label': `${labels.date}`
                }}
                className="MuiFormControl-fullWidth"
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardTimePicker
                label={labels.time}
                value={moment(rdvTime, 'HH:mm')}
                onChange={date => onChange({ target: { value: date ? date.format('HH:mm') : '', name: 'rdvTime' } })}
                ampm={false}
                keyboardIcon={<AccessTime />}
                InputLabelProps={{ shrink: true }}
                className="MuiFormControl-fullWidth"
              />
            </Grid>
            {checkupTypeVisible && (
              <TextFieldFormControl
                id="patient.typeTests"
                controlName="typeTests"
                onChangeHandler={onChange}
                itemMdValue={6}
                fullWidth
                select
                label={labels.type}
                placeholder={labels.type}
                value={typeTests}
              >
                <MenuItem value="" />
                {checkupTypes
                  .filter(({ startDate, endDate }) => {
                    if (Number.isInteger(startDate)) {
                      const momentStartDate = moment(new Date(startDate));
                      if (Number.isInteger(endDate)) {
                        const momentEndDate = moment(new Date(endDate));
                        return momentRdvDay.isBetween(momentStartDate, momentEndDate);
                      }
                      return momentRdvDay.isAfter(momentStartDate);
                    }
                    return false;
                  })
                  .map(({ id, label }) => (
                    <MenuItem value={id} key={id}>
                      {label}
                    </MenuItem>
                  ))}
              </TextFieldFormControl>
            )}
            {!checkupTypeVisible && <Grid item xs={6} />}
            <Grid item xs={6}>
              <KeyboardTimePicker
                label={labels.duration}
                value={moment(rdvDuration, 'HH:mm')}
                onChange={date =>
                  onChange({ target: { value: date ? date.format('HH:mm') : '', name: 'rdvDuration' } })
                }
                ampm={false}
                keyboardIcon={<HourglassFull />}
                InputLabelProps={{ shrink: true }}
                className="MuiFormControl-fullWidth"
              />
            </Grid>
          </Grid>
        </FormControlWithLegend>
      </MuiPickersUtilsProvider>
    );
  }
}
