import MathUtil from '../../components/common/Math';

function transformAge(age) {
  if (age) {
    const [years, months] = age.split('.');
    return `${years} an${years > 1 ? 's' : ''} ${months !== '0' ? `et ${months} mois` : ''}`;
  }
  return '';
}

function getCalcul(calcul) {
  if (!calcul) {
    return undefined;
  }
  return calcul.map(({ id, label, classes, age, ...otherProps }) => ({
    id: id || label || classes || age,
    label: label || classes || transformAge(age) || id,
    classes,
    age,
    ...otherProps
  }));
}

export const parseThresholdAsZscore = (thresholdValue, defaultValue) => {
  if (thresholdValue) {
    const thresholdType = thresholdValue.split(':')[0];
    const threshold = thresholdValue.split(':')[1];
    if (thresholdType === 'ET') {
      return {threshold, unit: thresholdType};
    }
    if (['CENT', 'PC'].includes(thresholdType)) {
      if (threshold === 0) return MathUtil.percentileToStdDeviation(0.001);
      if (threshold > 99.999) return MathUtil.percentileToStdDeviation(99.999);
      return {threshold: MathUtil.percentileToStdDeviation(threshold), unit: 'CENT'};
    }
  }
  return {threshold: defaultValue, unit: 'ET'};
};

// eslint-disable-next-line import/prefer-default-export
export const prepareTestForDisplay = ({
  IHMLabel,
  label,
  calcul,
  pathoThreshold,
  difficultyThreshold,
  ...allOtherTestProps
}) => {
  const { threshold: pathoThresholdAsZscore} = parseThresholdAsZscore(pathoThreshold, -1.6449);
  const { threshold: difficultyThresholdAsZscore } = parseThresholdAsZscore(difficultyThreshold, -1.3);

  return ({
    label: IHMLabel || label,
    IHMLabel,
    calcul: getCalcul(calcul),
    pathoThresholdAsZscore,
    difficultyThresholdAsZscore,
    pathoThreshold,
    difficultyThreshold,
    ...allOtherTestProps
  });
};
