/* eslint flowtype/no-weak-types: 0 react/require-default-props: 0 */
import React from 'react';
import { FormControl, FormControlProps, Grid, TextField, Tooltip } from '@material-ui/core';

type Props =
  | {
      id: string,
      controlName: string,
      label?: ?string,
      value?: any,
      inputProps?: {},
      inputLabelProps?: {},
      onChangeHandler?: Function,
      onBlurHandler?: Function,
      inputType?: string,
      multiline?: boolean,
      rows?: string,
      itemMdValue?: number,
      margin?: string,
      fullWidth?: boolean,
      helperText?: string,
      placeholder?: string,
      select?: boolean,
      disabled?: boolean,
      children?: any,
      onWheel?: any,
      list: string,
      tooltip?: string,
      datalist?: Array<React.ComponentElement> | undefined
    }
  | FormControlProps;

const TextFieldFormControl = ({
  id,
  controlName,
  value = '',
  inputProps = {},
  inputLabelProps = {},
  multiline = false,
  rows = '1',
  inputType = 'text',
  itemMdValue = 6,
  margin = 'normal',
  fullWidth = false,
  helperText = '',
  placeholder = '',
  select = false,
  label = null,
  disabled = false,
  children = null,
  onChangeHandler = () => {},
  onBlurHandler = () => {},
  onWheel = false,
  tooltip = '',
  datalist = [],
  ...formControlProps
}: Props) => {
  const moreProps = {
    ...formControlProps,
    inputProps: { list: datalist && datalist.length > 0 ? `${id}List` : undefined }
  };
  return (
    <Grid item md={itemMdValue}>
      <FormControl fullWidth={fullWidth} component="fieldset">
        <Tooltip title={tooltip} arrow>
          <TextField
            margin={margin}
            id={id}
            name={controlName}
            label={label}
            value={value}
            InputProps={{ ...inputProps, list: datalist && datalist.length > 0 ? `${id}List` : undefined }}
            InputLabelProps={inputLabelProps}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            type={inputType}
            multiline={multiline}
            rows={rows}
            helperText={helperText}
            placeholder={placeholder}
            select={select}
            disabled={disabled}
            onWheel={
              onWheel
                ? () => {}
                : event => {
                    event.preventDefault();
                  }
            }
            {...moreProps}
          >
            {children}
          </TextField>
        </Tooltip>
        {datalist && datalist.length > 0 && <datalist id={`${id}List`}>{datalist.map(n => n)}</datalist>}
      </FormControl>
    </Grid>
  );
};
export default TextFieldFormControl;
