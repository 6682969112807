import React from 'react';
import { ListItemText } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import scrolling from '../../../../common/scrolling';
import '../../../../common/styles/dragAndDrop.css';

type Props = {
  label: string,
  testId: string,
  // eslint-disable-next-line flowtype/no-weak-types
  onDrop: Function
};

const AssignmentDragZone = (props: Props) => {
  const { testId, label, onDrop } = props;

  const [, drag] = useDrag({
    item: { testId, type: 'assignment' },
    begin: () => {
      scrolling.addEventListenerForSidebar();
    },
    end: (item, monitor) => {
      scrolling.removeEventListenerForSidebar();
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(item.testId, dropResult.model);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div ref={drag} className="borderDashed">
      <ListItemText primary={label} />
    </div>
  );
};

export default AssignmentDragZone;
