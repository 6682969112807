import React from 'react';
import OnLineLogin from '../components/login/OnLineLogin';
import useNavigatorOnLine from '../components/common/UseNavigatorOnLine';
import OffLineLogin from '../components/login/OffLineLogin';

const LoginPage = () => {
  const isOnline = useNavigatorOnLine();
  return isOnline ? <OnLineLogin /> : <OffLineLogin />;
};

export default LoginPage;
