import Memo from './Memo';
import {CHECKUP_MODEL_MEMO_PREFIX, MEMO_PREFIX} from "./MemoUtil";

export default class Comments {
  static async getNewEmptyOne(withMemorizedTextsComments = false, firstname, lastname) {
    if (withMemorizedTextsComments) {
      const commentSummary = await Memo.verifyIfMemoByKeyHasInsertedByDefaultTextAndAddThem(
        'comment-summary',
        lastname,
        firstname,
        '',
        withMemorizedTextsComments
      );
      const commentDiagnosis = await Memo.verifyIfMemoByKeyHasInsertedByDefaultTextAndAddThem(
        'comment-diagnosis',
        lastname,
        firstname,
        '',
        withMemorizedTextsComments
      );
      const commentPatientCare = await Memo.verifyIfMemoByKeyHasInsertedByDefaultTextAndAddThem(
        'comment-patientCare',
        lastname,
        firstname,
        '',
        withMemorizedTextsComments
      );
      const commentTherapeuticPlan = await Memo.verifyIfMemoByKeyHasInsertedByDefaultTextAndAddThem(
        'comment-therapeuticPlan',
        lastname,
        firstname,
        '',
        withMemorizedTextsComments
      );

      const memos = await Memo.listKeyContainingString(CHECKUP_MODEL_MEMO_PREFIX);
      const memoIds = memos.map(({_id}) => _id);
      const array = await Promise.all(
        memoIds.map(async id => {
          const prefixLength = `${MEMO_PREFIX}${CHECKUP_MODEL_MEMO_PREFIX}`.length;
          const indexOfLastDot = id.indexOf(".", prefixLength);
          const newId = id.substring(indexOfLastDot+1);
          return ({
            id: newId,
            comment: await Memo.verifyIfMemoByKeyHasInsertedByDefaultTextAndAddThem(
              id,
              lastname,
              firstname,
              '',
              withMemorizedTextsComments
            )
          });
          }
        ));
      const checkupComments = array.reduce((acc, {id, comment}) => ({...acc, [id]: comment}), {});
      return {
        behaviours: [],
        summary: { text: commentSummary },
        diagnosis: { text: commentDiagnosis },
        patientCare: { text: commentPatientCare },
        therapeuticPlan: { text: commentTherapeuticPlan },
        ...checkupComments
      };
    }
    return {
      behaviours: [],
      summary: { text: '' },
      diagnosis: { text: '' },
      patientCare: { text: '' },
      therapeuticPlan: { text: '' }
    };
  }
}
