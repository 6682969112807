import React, { Component } from 'react';

import { ListItemText } from '@material-ui/core';
import Patient from '../../common/data/Patient';

type Props = {
  patientId: string,
  firstName: string,
  lastName: string,
  typeBilan: string,
  rdvDay: string
};

export default class PatientListRow extends Component<Props> {
  props: Props;

  state = {
    renewalNumber: ''
  };

  async componentDidMount() {
    const { patientId } = this.props;
    const patient = await Patient.retrievePatient(patientId);
    const { bilan } = patient;
    this.setState({
      renewalNumber: (bilan && bilan.renewalNumber) || ''
    });
  }

  render() {
    const { firstName, lastName, typeBilan, rdvDay } = this.props;
    const { renewalNumber } = this.state;
    return <ListItemText primary={`${firstName} ${lastName}`} secondary={`${typeBilan} ${renewalNumber} ${rdvDay}`} />;
  }
}
