import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Grid, IconButton, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import labels from '../../../../constants/labels/patient/report/ReportGeneration.json';
import TextFieldFormControl from '../../../TextFieldFormControl';
import User from '../../../common/data/User';
import PaperclipIcon from '../PaperclipIcon';
import { fileNameFormatsById } from '../../../../constants/report/fileNameFormats';
import log from '../../../Logger';
import ReportGeneratorUtil from '../ReportGeneratorUtil';
import CheckUpModel from '../../../common/data/CheckUpModel';
import templateList from '../../../../constants/report/template-list';
import GenerationActionRow from './GenerationActionRow';
import NonFilledTestInModel from '../NonFilledTestInModel';
import GenerationOptions from './GenerationOptions';
import BackButton from '../../../common/BackButton';
import appVersionSpecific from '../../../../appVersionSpecific.ortho';
import { getHeaderAgeAndClassText } from '../../../common/Header';
import Loader from '../../../common/Loader2';
import { useWarnings, Warning } from '../../../common/Warning';
import routes from '../../../../constants/routes';
import PatientContentUtil from '../../PatientContentUtil';
import { applyDataCorrectionForReportGenerator } from './GenerationUtil';
import DocumentModels from '../../../common/data/DocumentModels';
import { download } from '../../../../actions/FileImportExport';
import { useDeveloperMode } from '../../../common/useDeveloperMode';
import HappyNeuronDisabledComponent from '../../../common/useApplicationVersion';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold'
  }
});

type Props = {
  match: { params: { patientId: string } }
};

const Generation = ({
  match: {
    params: { patientId }
  }
}: Props) => {
  const classes = useStyles();

  const [content, setContent] = useState({
    bilan: {},
    patient: {},
    user: { checkupModel: 0 },
    cotation: {},
    notLoaded: true
  });
  const [checkupModels, setCheckupModels] = useState([]);
  const [specificModel, setSpecificModel] = useState({ _id: 0 });
  const [warnings, addWarning, clearWarnings, writeFileWarning] = useWarnings();
  const [currentlyGenerating, setCurrentlyGenerating] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [documentCheckupModelList, setDocumentCheckupModelList] = useState([]);
  const isDeveloperMode = useDeveloperMode();

  const isHappyNeuron = appVersionSpecific.isHappyNeuron;

  useEffect(() => {
    const updateContent = async () => {
      try {
        const newContent = await PatientContentUtil.getContent(patientId);
        newContent.user.lastName = `${newContent.user.lastName}${
          newContent.options.header.addPersonalCompany ? ' - EI' : ''
        }`;
        const newCheckupModels = await CheckUpModel.retrieveModelList();
        newCheckupModels.splice(0, 0, { label: 'Structure standard', _id: 0 });
        const filenames = await DocumentModels.listFilesOnDocumentModelStorage();

        setDocumentCheckupModelList(filenames);
        setContent(newContent);
        setSpecificModel(newContent.specificModel);
        setCheckupModels(newCheckupModels);
        setDataLoaded(true);
      } catch (err) {
        log.error('Error retrieving datas', err);
      }
    };

    updateContent().then();
  }, [patientId]);

  const generateCR = async () => {
    setCurrentlyGenerating(true);
    await clearWarnings();
    const model = content.user['doctemplate.default'];

    const [modelType, ...b] = model.split(':');
    const modelValue = b.join(':');


    const fileName = fileNameFormatsById[content.bilan.reportOptions.fileNameFormat].template(content.patient);
    const extension = modelValue
      .replace(/^.*[\\/]/, '')
      .split('.')
      .pop();

    const filename = `${fileName}${
      content.bilan.renewalNumber ? `_[R${content.bilan.renewalNumber}]` : ''
    }.${extension}`;

    const reportContent = applyDataCorrectionForReportGenerator(content);

    console.log({ reportContent });

    const fileReader = new FileReader();
    fileReader.onload = async event => {
      try {
        download(filename, event.target.result);
        if (isDeveloperMode) {
          download(`${filename}_content.json`, JSON.stringify(reportContent, null, 4));
        }
        setCurrentlyGenerating(false);
      } catch (ex) {
        console.log({ ex });
        setCurrentlyGenerating(false);
        await addWarning(writeFileWarning(filename));
      }
    };

    try {
      const { method, model } = modelType === 'app' ?
        {
          method: 'generateReportUrl',
          model: `https://web.orthoscribe.fr/templates/${modelValue}`
        }
      :
        {
          method: 'generateReportS3',
          model: modelValue
        };

      const message = await ReportGeneratorUtil[method](model, reportContent);
      if (message) {
        fileReader.readAsArrayBuffer(message);
      }
    } catch (e) {
      await addWarning(labels.errorGeneratingReport);
      setCurrentlyGenerating(false);
      throw e
    }
  };

  const changeUserValue = key => async event => {
    await User.save({ keyValue: key, value: event.target.value });
    const newContent = await PatientContentUtil.getContent(patientId);
    setContent(newContent);
    if (key === 'model.default') {
      setSpecificModel(newContent.specificModel);
    }
  };

  const changeSelectedModelIfNotExisting = async () => {
    await changeUserValue('doctemplate.default')({ target: { value: 'app:orthoTemplate_odt.odt' } });
    await addWarning(
      "Le modèle de bilan que vous aviez choisi n'est pas disponible, le modèle par défaut sera utilisé."
    );
  };

  const { bilan, patient, user, cotation } = content;

  if (
    documentCheckupModelList.map(({ fileName }) => `loc:${fileName}`)
      .includes(user['doctemplate.default'])
  ) {
    changeSelectedModelIfNotExisting();
  }

  const ageAndClassText = getHeaderAgeAndClassText(patient.curclasse, patient.birthDay);

  if (!dataLoaded) {
    return <Loader />;
  }

  return (
    <Grid item md={12} container>
      <Grid item md={12}>
        <BackButton backRoute={appVersionSpecific.mainPagePatientLink(patientId)} />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">{labels.title}</Typography>
        <Typography variant="subtitle2">
          {labels.subTitle.part0}{' '}
          {bilan.renewalNumber ? labels.subTitle.renewal + bilan.renewalNumber : labels.subTitle.initial}{' '}
          {labels.subTitle.part1}{' '}
          <span className={classes.bold}>
            {patient.firstName} {patient.lastName}
          </span>{' '}
          ({ageAndClassText})
        </Typography>
      </Grid>
      <NonFilledTestInModel key={specificModel._id} cotation={cotation} bilan={bilan} checkupModel={specificModel} />
      {!content.notLoaded && (
        <Fragment>
          <Divider />
          <Warning warnings={warnings} />
          {currentlyGenerating ? (
            <Grid item md={12} container justifyContent="center">
              <Loader />
            </Grid>
          ) : (
            <GenerationActionRow generateCR={generateCR} rdvDay={bilan.rdvDay} patientId={patientId} {...patient} />
          )}
        </Fragment>
      )}
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item container md={12}>
        <HappyNeuronDisabledComponent md={6}>
          <TextFieldFormControl
            itemMdValue={11}
            id="selectedCheckupModel"
            controlName="selectedCheckupModel"
            select
            fullWidth
            label={labels.selectCheckupModel}
            onChangeHandler={changeUserValue('model.default')}
            value={user['model.default']}
            disabled={isHappyNeuron}
          >
            {checkupModels.map(({ _id, label }) => (
              <MenuItem key={_id} value={_id}>
                {label}
              </MenuItem>
            ))}
          </TextFieldFormControl>
          <Grid item container md={1}>
            <Tooltip title={labels.editCheckupModel}>
              <IconButton
                component={Link}
                to={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.MODELS.MAIN)}
                disabled={isHappyNeuron}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Grid>
        </HappyNeuronDisabledComponent>
        <TextFieldFormControl
          itemMdValue={5}
          id="selectedDocumentModel"
          controlName="selectedDocumentModel"
          select
          fullWidth
          label={labels.selectDocumentModel}
          onChangeHandler={changeUserValue('doctemplate.default')}
          value={user['doctemplate.default']}
        >
          {templateList.map(({ label, fileName, icon }) => (
            <MenuItem key={fileName} value={`app:${fileName}`}>
              {icon}
              &nbsp;{label}
            </MenuItem>
          ))}
          {documentCheckupModelList.map(({ label, fullName }) => (
            <MenuItem key={fullName} value={`loc:${fullName}`}>
              <PaperclipIcon fontSize="small" />
              &nbsp;{label.replace(/^.*[\\/]/, '')}
            </MenuItem>
          ))}
        </TextFieldFormControl>
        <Grid item container md={1}>
          <Tooltip title={labels.editDocumentModel}>
            <IconButton component={Link} to={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.DOCUMENT_MODEL)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item md={12}>
          <Divider />
        </Grid>
        <GenerationOptions
          patientId={patientId}
          isRenewal={!!bilan.renewalNumber}
        />
      </Grid>
    </Grid>
  );
};

export default Generation;
