import React, { Component } from 'react';
import { Grid, Divider } from '@material-ui/core';

import Header from './elements/Header';
import IconPrepend from './elements/IconPrepend';
import Input from './elements/Input';
import TextArea from './elements/TextArea';
import Info from './elements/Info';
import InlineRadio from './elements/InlineRadio';
import Radios from './elements/Radios';
import Fratrie from './elements/Fratrie';
import AntecedentFamiliaux from './elements/AntecedentFamiliaux';
import Age from './elements/Age';
import Time from './elements/Time';
import ParcoursScolaire from './elements/ParcoursScolaire';
import CustomInput from './elements/CustomInput';
import CustomTextArea from './elements/CustomTextArea';
import CustomRadio from './elements/CustomRadio';
import Number from './elements/Number';
import DropColumn from './DropColumn';

type Props = {
  row: array,
  isDragging: boolean,
  index: number,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any
};

export default class AnamnesisRow extends Component<Props> {
  props: Props;

  choice = (element, elementFunction, rowNumber, columnNumber, child, isDragging, onDragStateChange, type) => {
    const { id, label } = element;
    const props = {
      key: id || label,
      element,
      elementFunction,
      rowNumber,
      columnNumber,
      child,
      isDragging,
      onDragStateChange
    };
    switch (type) {
      case 'header':
        return <Header key={id} {...props} />;
      case 'icon-prepend':
        return <IconPrepend key={id} {...props} />;
      case 'input':
        return <Input key={id} {...props} />;
      case 'textarea':
        return <TextArea key={id} {...props} />;
      case 'numsecu':
        return <IconPrepend key={id} {...props} />;
      case 'info':
        return <Info key={id} {...props} />;
      case 'inlineradios':
        return <InlineRadio key={id} {...props} />;
      case 'radios':
        return <Radios key={id} {...props} />;
      case 'fratrie':
        return <Fratrie key={id} {...props} />;
      case 'antecedentsfamiliaux':
        return <AntecedentFamiliaux key={id} {...props} />;
      case 'age':
        return <Age key={id} {...props} />;
      case 'number':
        return <Number key={id} {...props} />;
      case 'time':
        return <Time key={id} {...props} />;
      case 'parcoursscolaire':
        return <ParcoursScolaire key={id} {...props} />;
      case 'custom_input':
        return <CustomInput key={id} {...props} />;
      case 'custom_textarea':
        return <CustomTextArea key={id} {...props} />;
      case 'custom_radios':
        return <CustomRadio key={id} {...props} />;
      case '---':
        return <Divider key={id} />;
      default:
        return '';
    }
  };

  render() {
    const { row, isDragging, index, elementFunction, onDragStateChange } = this.props;
    return (
      <Grid item xs={12} container justifyContent="space-between">
        <DropColumn isDragging={isDragging} rowNumber={index} columnNumber={0} />
        {row.map((element, columnIndex) => {
          const { type, label } = element;
          const child =
            row.length > 1 && columnIndex < row.length - 1 ? (
              <DropColumn isDragging={isDragging} rowNumber={index} columnNumber={columnIndex + 1} />
            ) : (
              ''
            );
          return (
            this.choice(element, elementFunction, index, columnIndex, child, isDragging, onDragStateChange, type) ||
            `%${type}% %${label}%`
          );
        })}
        <DropColumn isDragging={isDragging} rowNumber={index} columnNumber={row.length} />
      </Grid>
    );
  }
}
