import { jsonDownload } from '../../../import/utils/HappyneuronFileDownloader';
import happyneuronExalangClassToOrthoscribeClasse from '../HappyneuronExalangClassToOrthoscribeClasse';
import DEFAULT_HN_CLASSES from '../DEFAULT_HN_CLASSES.json';

const parseFile = async ({filePath, userId, ticket, programId}) => {
  const evalec = await jsonDownload({userId, ticket, programId});
  console.log("evalec", {evalec, filePath})
  const { code: classe } = happyneuronExalangClassToOrthoscribeClasse(DEFAULT_HN_CLASSES, evalec.user.class) || {};

  const hnClasseObject = Object.values(evalec.classes).filter(({ classId }) => classId === evalec.user.class)[0];

  return evalec.workouts.map(workout => ({
    firstname: evalec.user.firstName,
    lastname: evalec.user.lastName,
    birthDate: evalec.user.birthDate,
    passationDate: workout.startDate,
    passationNumber: workout.id,
    data: { workout, scores: evalec.scores, hnClasseObject },
    hnWorkoutId: workout.id,
    classe,
    text: `${evalec.user.firstName} ${evalec.user.lastName} (${workout.startDate})`
  }));
};

export default parseFile;
