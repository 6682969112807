import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import labels from '../../../constants/labels/patient/patientConsult/TestList.json';
import TestsLink from '../../home/TestsLink';
import routes from '../../../constants/routes';
import { includedTests } from '../../../constants/tests/tests';
import patientConsultStyle from './patientConsultStyle.json';
import type {Patient} from "../Patient";

const useStyle = makeStyles({ ...patientConsultStyle });

type Props = {
  patient: Patient,
  bilan: {
    _id: string | undefined,
    tests: array<>
  },
  customTests: array<>
};

const TestList = ({ patient, bilan, customTests }: Props) => {
  const classes = useStyle();
  const bilanTestsCodes = (bilan.tests || []).map(test => test.testCode);
  const filteredTests = [...includedTests, ...customTests].filter(test => bilanTestsCodes.includes(test.testCode));
  console.log({ filteredTests });

  return (
    <Fragment>
      <Grid item xs={12} container>
        <Typography variant="h6">{labels.title}</Typography>
      </Grid>
      <Paper className={classes.paper}>
        <Grid item xs={12} container>
          <Grid item xs={1}>
            <TestsLink patientId={patient._id} bilan={bilan} withPreviewIcon />
          </Grid>
          <Grid item xs={11} className={classes.verticalAlign}>
            <Typography variant="body1">
              {filteredTests.length === 0 ? (
                <Tooltip title={labels.noTests.tooltip}>
                  <Link
                    to={routes.PATIENT.getForPath({
                      path: routes.PATIENT.TESTS.SELECT,
                      patientId: patient._id
                    })}
                  >
                    {labels.noTests.label}
                  </Link>
                </Tooltip>
              ) : (
                filteredTests
                  .map(({ IHMLabel, label, testCode }) => ({ label: IHMLabel || label, testCode }))
                  .map(({ label, testCode }) => (
                    <Button
                      key={testCode}
                      component={Link}
                      to={routes.PATIENT.TESTS.getForPath({
                        path: routes.PATIENT.TESTS.FILLING,
                        patientId: patient._id,
                        testCode,
                        bilanId: bilan._id
                      })}
                    >
                      {label}
                    </Button>
                  ))
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default TestList;
