import React, {Component} from 'react';
import {Divider, IconButton, Tooltip, Typography, withStyles} from '@material-ui/core';
import {CloudUpload, Save} from '@material-ui/icons';
import _ from 'lodash';

import labels from '../../../constants/labels/patient/report/ReportArrays.json';
import routes from '../../../constants/routes';
import SaveCancelRow from '../../common/SaveCancelRow';
import User from '../../common/data/User';
import log from '../../Logger';
import Patient from '../../common/data/Patient';
import {getHeaderAgeAndClassText} from '../../common/Header';
import PatientContentUtil from '../PatientContentUtil';
import ReportArrayForm from "../../common/reportOptions/ReportArrayForm";
import defaultReportGenerationOptions from "../../../constants/user/defaultReportGenerationOptions";

const styles = () => ({
  bold: {
    fontWeight: 'bold'
  }
});

type Props = {
  match: { params: { patientId: string } },
  // eslint-disable-next-line flowtype/no-weak-types
  classes: any,
  // eslint-disable-next-line flowtype/no-weak-types
  history: any
};

class ReportArrays extends Component<Props> {
  props: Props;

  state = {
    content: {
      bilan: [],
      patient: {},
      options: defaultReportGenerationOptions
    },
    reloadChart: true
  };

  async componentDidMount(): void {
    const {
      match: {
        params: { patientId }
      }
    } = this.props;
    try {
      const content = await PatientContentUtil.getContent(patientId);
      this.setState({ content, reloadChart: false });
    } catch (err) {
      log.error('Error retrieving datas', err);
    }
  }

  handleSubmit = asDefault => async () => {
    const {
      match: {
        params: { patientId }
      },
      history
    } = this.props;
    const {
      content: { bilan, options, patient }
    } = this.state;

    bilan.reportOptions = options;
    await Patient.updatePatient({ ...patient, bilan });
    if (asDefault) {
      await User.saveReportGenerationOptions(options);
    }
    history.push(routes.PATIENT.REPORT.getForPath({ path: routes.PATIENT.REPORT.GENERATE, patientId }));
  };

  change = event => {
    const { content } = this.state;
    content.options = _.set(content.options, event.target.name, event.target.value);
    this.setState({ content });
  };

  switchChange = name => event => {
    this.change({ target: { name, value: event.target.checked } });
  };

  render(): React.ReactNode {
    const {
      match: {
        params: { patientId }
      },
      classes
    } = this.props;
    const { content, reloadChart } = this.state;
    const { patient, bilan, options } = content;
    const renewal = !!bilan.initialAssessmentUUID;
    log.info({ options });
    const ageAndClassText = getHeaderAgeAndClassText(patient.curclasse, patient.birthDay);

    return (
      <div>
        <Typography variant="h3">{labels.title}</Typography>
        <Typography variant="h4">
          {labels.subTitle.part0}{' '}
          {bilan.renewalNumber ? labels.subTitle.renewal + bilan.renewalNumber : labels.subTitle.initial}{' '}
          {labels.subTitle.part1}{' '}
          <span className={classes.bold}>
            {patient.firstName} {patient.lastName}
          </span>{' '}
          ({ageAndClassText})
        </Typography>
        <Divider/>
        <SaveCancelRow
          cancelRoute={routes.PATIENT.REPORT.getForPath({path: routes.PATIENT.REPORT.GENERATE, patientId})}
          saveAction={this.handleSubmit(false)}
          saveIcon={<Save/>}
          saveTooltip={labels.save.tooltip}
          moreActionsMenu={[
            {
              key: 'entry0',
              element: (
                <Tooltip title={labels.saveAsDefault.tooltip}>
                  <IconButton onClick={this.handleSubmit(true)} variant="contained" color="primary">
                    <CloudUpload/>
                  </IconButton>
                </Tooltip>
              )
            }
          ]}
        />
        <Divider/>
        <ReportArrayForm
          options={options}
          content={content}
          reloadChart={reloadChart}
          renewal={renewal}
          switchChange={this.switchChange}
          change={this.change}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ReportArrays);
