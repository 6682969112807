import React from 'react';
import { Chip, Grid } from '@material-ui/core';
import { SignalWifiOff } from '@material-ui/icons';
import Logo from '../Logo';
import labels from '../../constants/labels/OffLineLogin.json';

const OffLineLogin = () => (
  <Grid container justifyContent="center">
    <Grid item xs={10} container direction="row">
      <Logo />
    </Grid>
    <Grid item xs={10} container spacing={2} justifyContent="space-between">
      <Grid item xs={12}>
        <Chip icon={<SignalWifiOff />} label={labels.message} color="secondary" />
      </Grid>
    </Grid>
  </Grid>
);

export default OffLineLogin;
