// @flow
import React, { Component } from 'react';
import { Divider, Grid } from '@material-ui/core';
import _ from 'lodash';

import Contact from './edit/Contact';
import Checkup from './edit/Checkup';
import Patient from './edit/Patient';
import PatientData, { defaultPatient } from '../common/data/Patient';
import routes from '../../constants/routes';
import log from '../Logger';
import SaveCancelRow from '../common/SaveCancelRow';

type Props = {
  match: ?{ params: ?{ patientId: ?string } },
  // eslint-disable-next-line flowtype/no-weak-types
  history: any
};

export default class Edit extends Component<Props> {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {
      patient: {}
    };
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;
    if (params.patientId !== 'new') {
      const patient = await PatientData.retrievePatient(params.patientId);
      this.setState({ patient });
    } else {
      const patient = await defaultPatient();
      this.setState({ patient });
    }
  }

  handleChange = async event => {
    let { patient } = this.state;
    patient = _.set(patient, event.target.name, event.target.value);
    this.setState({ patient });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const {
      match: { params },
      history
    } = this.props;
    const { patient } = this.state;
    if (params.patientId === 'new') {
      const updatedPatient = await PatientData.createPatient(patient);
      this.setState({ patient: updatedPatient });
      history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId: updatedPatient._id }));
    } else {
      const updatedPatient = await PatientData.updatePatient(patient);
      this.setState({ patient: updatedPatient });
      history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId: updatedPatient._id }));
    }
  };

  render() {
    const {
      match: { params }
    } = this.props;
    const {
      patient: { _id, _rev }
    } = this.state;
    const { patient } = this.state;
    log.debug(`this page url param is ${JSON.stringify(params)}`);
    log.debug(`this patient id is ${_id}`);
    log.debug(`this patient rev is ${_rev}`);
    return (
      <Grid
        container
        spacing={2}
        onKeyDown={async e => {
          const charCode = String.fromCharCode(e.which).toLowerCase();
          if((e.ctrlKey || e.metaKey) && charCode === 's') {
            await this.handleSubmit(e)
          }
        }}
      >
        <SaveCancelRow saveAction={this.handleSubmit} />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={this.handleSubmit}>
            <Patient patient={patient} onChange={this.handleChange} />
            <Checkup patient={patient} onChange={this.handleChange} />
            <Contact patient={patient} onChange={this.handleChange} />
          </form>
        </Grid>
      </Grid>
    );
  }
}
