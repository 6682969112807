import moment from 'moment';

import PREDILAC from '../../../../constants/tests/PREDILAC.json';
import MathUtil from '../../../common/Math';
import {getLocation} from './exalangCommonImport';
import {jsonDownload} from "../../import/utils/HappyneuronFileDownloader";

const parseFile = async ({filePath, userId, ticket, programId}) => {
  const predilac = await jsonDownload({userId, ticket, programId});
  console.log("predilac", {predilac, filePath})
  const classe = getPredilacClass(predilac.user);

  return predilac.workouts.map(workout => ({
    firstname: predilac.user.firstName,
    lastname: predilac.user.lastName,
    birthDate: predilac.user.birthDate,
    passationDate: workout.startDate,
    passationNumber: workout.id,
    data: { workout },
    hnWorkoutId: workout.id,
    classe,
    text: `${predilac.user.firstName} ${predilac.user.lastName} (${workout.startDate})`
  }));
};

const getPredilacClass = user => {
  const birthDate = moment(user.birthDate, 'DD/MM/YYYY');
  const age = MathUtil.calculateAge(birthDate.toDate());
  if (age) {
    let part1 = '';
    if (age >= 20 && age <= 49) {
      part1 = '20 à 49 ans';
    } else if (age >= 50 && age < 60) {
      part1 = '50 à 59 ans';
    } else if (age >= 60 && age < 70) {
      part1 = '60 à 69 ans';
    } else if (age >= 70 && age < 80) {
      part1 = '70 à 79 ans';
    } else if (age >= 80 && age < 90) {
      part1 = '80 à 90 ans';
    }
    return part1;
  }
  return '';
};

// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
const executeImport = ({ data, classe }) => {
  if (data && data.workout) {
    const { results } = data.workout;
    const values = PREDILAC.children
      .map(({ id }) => parseInt(id.substring('PREDILAC_'.length), 10))
      .map(gameId => {
        const result = (results.filter(({ gameId: resultGameId }) => resultGameId === gameId) || []).shift();
        if (!(result && (result.score === 0 || result.score))) return {};
        const data3Index = (result.details || '').indexOf('_data3:');
        const comment =
          data3Index >= 0 && result.details.substr(data3Index + 7) ? result.details.substr(data3Index + 7) : undefined;
        return { gameId, score: result.score, comment };
      })
      .filter(Boolean)
      .reduce((acc, { gameId, score, comment }) => {
        acc[`PREDILAC_${gameId}`] = { score, comment };
        return acc;
      }, {});
    return { count: Object.entries(values).length, values, calculOption: classe, calculOptionType: 'classe' };
  }
};

const PredilacImport = () => ({ getLocation, parseFile, executeImport });
export default PredilacImport;
