import DEFAULT_HN_CLASSES from './DEFAULT_HN_CLASSES.json';
import { frenchClasses } from '../../../../constants/Grades';

const happyneuronExalangClassToOrthoscribeClasse = (hnClasses = DEFAULT_HN_CLASSES, classcode: string | number) => {
  const hnClass = hnClasses
    .filter(
      ({ classId, name }) =>
        (!Number.isNaN(Number(classcode)) && classId === Number(classcode)) ||
        (typeof classcode === 'number' && classId === classcode) ||
        (typeof classcode === 'string' && name === classcode)
    )
    .shift();
  console.log({ hnClasses, classcode, hnClass });
  return (
    frenchClasses.filter(({ hn }) => hn.map(v => v.toLowerCase()).includes(hnClass.name.toLowerCase())).shift() || {}
  );
};

export default happyneuronExalangClassToOrthoscribeClasse;
