import React, { useState } from 'react';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import uuidv4 from 'uuid/v4';
import { Add, GetApp } from '@material-ui/icons';

import labels from '../../constants/Labels.json';
import CheckUpModelList from '../../components/configuration/checkUpModel/CheckUpModelList';
import CheckUpModel from '../../components/common/data/CheckUpModel';
import routes from '../../constants/routes';
import { useWarnings, Warning } from '../../components/common/Warning';
import { download, compress, uncompress } from '../../actions/FileImportExport';
import OkCancelDialog from '../../components/common/OkCancelDialog';
import { useHistory } from 'react-router';
import Memo from '../../components/common/data/Memo';
import { CHECKUP_MODEL_MEMO_PREFIX, MEMO_PREFIX } from '../../components/common/data/MemoUtil';

const CheckUpModelPage = () => {
  const history = useHistory();

  const [value, setValue] = useState(true);
  const [warnings, addWarning, clearWarnings, writeFileWarning] = useWarnings();
  const [dialogOpened, setDialogOpened] = useState(false);

  const importModel = async (event) => {
    const filePath = event.target.files[0]
    if (!filePath) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const { data } = await uncompress(fileReader.result);
      const model = JSON.parse(data);
      model._id = uuidv4();
      await CheckUpModel.save(model, true);
      setValue(!value);
    };
    fileReader.readAsArrayBuffer(filePath);
  };

  const exportModel = async idToExport => {
    await clearWarnings();

    const modelToExport = await CheckUpModel.get(idToExport);
    const filename = modelToExport.label;
    const { _id, _rev, ...cleanedModel } = modelToExport;
    const compressedContent = compress(JSON.stringify(cleanedModel, null, 4), 'MODEL');
    try {
      download(`${filename}.oml`, compressedContent);
    } catch (e) {
      await addWarning(writeFileWarning(filename));
    }
  };

  const createNewModel = async () => {
    const memos = await Memo.listKeyContainingString(`${MEMO_PREFIX}${CHECKUP_MODEL_MEMO_PREFIX}0`);
    if (memos.length > 0) {
      setDialogOpened(true);
    } else {
      history.push(routes.CONFIGURATION.MODELS.getForPath('new', false));
    }
  };

  return (
    <Grid item md={12} container>
      <Grid item md={12}>
        <Typography variant="h6">{labels.configuration.checkUpModels.mainHeader}</Typography>
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={createNewModel}
        >
          {labels.configuration.checkUpModels.createCheckUpModel}
        </Button>
        <OkCancelDialog
          handleClose={(answer) => () => {
            history.push(routes.CONFIGURATION.MODELS.getForPath('new', answer));
          }}
          open={dialogOpened}
          contentText="Des textes mémorisés existent sur la structure standard, souhaitez-vous les dupliquer sur votre nouvelle structure ?"
          title="Dupliquer les textes mémorisés ?"
          okButtonLabel="Oui"
          cancelButtonLabel="Non"
          cancelButton
        />
      </Grid>
      <Grid item md={2}>
        <input
          id="contained-button-file"
          type="file"
          hidden
          onChange={importModel}
          accept=".oml"
        />
        <label htmlFor="contained-button-file">
          <Button variant="outlined" component="span" startIcon={<GetApp />}>
            {labels.configuration.checkUpModels.importCheckUpModel}
          </Button>
        </label>
      </Grid>
      <Grid item md={8} />
      <Warning warnings={warnings} />
      <CheckUpModelList key={value} exportModel={exportModel} />
    </Grid>
  );
};
export default CheckUpModelPage;
