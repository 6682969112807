import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import uuidv4 from 'uuid/v4';
import CheckUpModelEdit from '../../components/configuration/checkUpModel/CheckUpModelEdit';
import labels from '../../constants/Labels.json';
import CheckUpModel from '../../components/common/data/CheckUpModel';
import appVersionSpecific from '../../appVersionSpecific.ortho';
import routes from '../../constants/routes';
import {CHECKUP_MODEL_MEMO_PREFIX, MEMO_PREFIX} from "../../components/common/data/MemoUtil";
import Memo from "../../components/common/data/Memo";

const modele = appVersionSpecific.modeleBilanStandard;

type Props = {
  match: ?{ params: ?{ checkUpModelId: ?string, shouldDuplicateMemo: ?boolean } }
};

const CheckUpModelEditPage = ({
  match: {
    params: { checkUpModelId,
      shouldDuplicateMemo
    }
  }
}: Props) => {

  const history = useHistory();

  useEffect(() => {
    const createNewModel = async () => {
      if (checkUpModelId === 'new') {
        const _id = uuidv4().toString();
        await CheckUpModel.save({
          ...modele,
          _id,
          label: labels.configuration.checkUpModels.copyOf + modele.label,
          creationDate: new Date()
        });

        if (shouldDuplicateMemo === "true") {
          const memos = await Memo.listKeyContainingString(`${MEMO_PREFIX}${CHECKUP_MODEL_MEMO_PREFIX}0`);
          const newMemos = memos.map(({k, _id: memoId, ...memo}) => ({
            k: k.replace(`${MEMO_PREFIX}${CHECKUP_MODEL_MEMO_PREFIX}0`, `${MEMO_PREFIX}${CHECKUP_MODEL_MEMO_PREFIX}${_id}`),
            _id: memoId.replace(`${MEMO_PREFIX}${CHECKUP_MODEL_MEMO_PREFIX}0`, `${MEMO_PREFIX}${CHECKUP_MODEL_MEMO_PREFIX}${_id}`),
            ...memo
          }));
          await Promise.all(newMemos.map(memo => Memo.save(memo)));
        }
        history.push(routes.CONFIGURATION.MODELS.getForPath(_id));
      }
    };

    createNewModel().then();
  }, [checkUpModelId, history, shouldDuplicateMemo]);

  return <CheckUpModelEdit id={checkUpModelId}/>;
};
export default CheckUpModelEditPage;
