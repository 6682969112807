import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Box, Collapse, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import '../../configuration/tests/TestList.css';
import labels from '../../../constants/labels/patient/test/TestSelectionAlert.json';

const TestSelectionAlert = () => {
  const location = useLocation();
  const { state: { alert } = {} } = location;

  const [open, setOpen] = useState(true);

  if (!alert) return null;

  if (alert === 'renewalTestsAutoSelecteds') {
    return (
      <Box sx={{ width: '100%' }}>
        <Collapse in={open}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>{labels.title}</AlertTitle>
            {labels.description.normal}
            <span className="selectedInInitialAssessmentTest">{labels.description.highlighted}</span>
          </Alert>
        </Collapse>
      </Box>
    );
  }

  return null;
};

export default TestSelectionAlert;
