export const inputIncludesErrorTempsCentile = (input) => input.includes("ERR_TEMPSCENTILE");

export const getErrorTempsCentileValue = (value, percentileObject) => ({
  returnValue: {
    score: Number.parseFloat(value).toFixed(0),
    errTempsCentile: percentileObject.percentileValue || percentileObject.value,
    inErrTempsCentile: true
  },
  percentileObject
});
