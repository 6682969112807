import React, { useEffect, useState } from 'react';
import { Divider, Grid, MenuItem, Paper, Typography } from '@material-ui/core';
import { CompareArrows } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import SaveCancelRow from '../../common/SaveCancelRow';
import routes from '../../../constants/routes';
import labels from '../../../constants/labels/patient/RenewalCheckupLink.json';
import TextFieldFormControl from '../../TextFieldFormControl';
import Patient from '../../common/data/Patient';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold'
  }
});

type Props = {
  match: {
    params: {
      patientId: string,
      originPatientId: string
    }
  }
};

const RenewalCheckupLink = ({
  match: {
    params: { patientId, originPatientId }
  }
}: Props) => {
  const history = useHistory();

  const [patient, setPatient] = useState({});
  const [patientInit, setPatientInit] = useState({ firstName: '', lastName: '' });
  const [bilan, setBilan] = useState({});
  const [bilanInit, setBilanInit] = useState();
  const [renewal, setRenewal] = useState();

  const classes = useStyles();

  useEffect(() => {
    const loadValues = async () => {
      const mPatient = await Patient.retrievePatient(patientId);
      const { bilan: mBilan } = mPatient;

      const mPatientInit = await Patient.retrievePatient(originPatientId);
      const { bilan: mBilanInit } = mPatientInit;

      setPatient(mPatient);
      setBilan(mBilan);
      setPatientInit(mPatientInit);
      setBilanInit(mBilanInit);
      setRenewal(mBilanInit.renewalNumber + 1);
    };

    loadValues().then();
  }, [originPatientId, patientId]);

  const handleChangeRenewal = event => {
    setRenewal(event.target.value);
  };

  const linkCheckup = async () => {
    bilan.initialAssessmentUUID = bilanInit.uuid;
    bilan.initialAssessmentTests = bilanInit.tests;
    bilan.renewalNumber = renewal;

    patient.typeBilan = 'renouvellement';
    await Patient.updatePatient({
      ...patient,
      initialPatientId: originPatientId,
      bilan,
      anamnese: {
        ...patient.anamnese,
        modelId: patientInit.anamnese.modelId,
        definitions: patientInit.anamnese.definitions,
        values: patientInit.anamnese.values
      }
    });
    history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.CONSULT, patientId }));
  };

  const { firstName, lastName, rdvDay } = patient;

  return (
    <Grid container>
      <SaveCancelRow
        cancelRoute={routes.PATIENT.getForPath({
          path: routes.PATIENT.RENEWAL.CHOOSE,
          patientId
        })}
        saveAction={linkCheckup}
        saveTooltip={labels.saveLabel}
        saveIcon={<CompareArrows />}
      />
      <Grid item xs={12}>
        <Typography variant="h3">{labels.title0}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Paper style={{ width: '100%', padding: '0.5em', marginTop: '0.5em' }}>
        <Grid item xs={12}>
          <Typography variant="h3">{labels.title1}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            {labels.subTitle.part0} <span className={classes.bold}>{labels.subTitle.part1}</span>{' '}
            {labels.subTitle.part2}
          </Typography>
          <ul>
            <li>
              {firstName} {lastName}
            </li>
            <li>
              {labels.checkupDate} {new Date(rdvDay).toLocaleDateString()}
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{labels.previousCheckup}</Typography>
          <ul>
            <li>
              {patientInit.firstName} {patientInit.lastName}
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{labels.renewal}</Typography>
          <TextFieldFormControl
            controlName="renewal"
            id="renewal"
            value={renewal}
            onChangeHandler={handleChangeRenewal}
            fullWidth
            select
          >
            {_.range(20).map(value => (
              <MenuItem key={value} value={value + 1}>
                {`${labels.renewal} ${value + 1}`}
              </MenuItem>
            ))}
          </TextFieldFormControl>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default RenewalCheckupLink;
