import React from 'react';
import {Divider, FormControlLabel, Grid, Switch, Tooltip, Typography} from '@material-ui/core';
import SearchField from '../common/SearchField';
import labels from '../../constants/labels/home/Home.json';
import appVersionSpecific from '../../appVersionSpecific.ortho';

type Props = {
  q: string,
  archived: boolean,
  multiAction: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  setMultiAction: any => void,
  // eslint-disable-next-line flowtype/no-weak-types
  searchChange: any => void,
  setArchived: () => void,
  setMultiActionSelectedPatientList: () => void
};

function HomeHeader({ q, searchChange, multiAction, setMultiAction, archived, setArchived, setMultiActionSelectedPatientList }: Props) {
  return (
    <React.Fragment>
      <Grid item md={12} xs={6} container justifyContent="center">
        <SearchField q={q} searchChange={searchChange} placeholder={labels.searchFieldPlaceholder}/>
      </Grid>
      <Grid item md={12} xs={6} container>
        <Grid item md={2}>
          <Typography variant="h5">{archived ? labels.archivedFiles : labels.title}</Typography>
        </Grid>
        <Grid item md={8}>
          <FormControlLabel
            control={<Switch checked={archived} onChange={() => {
                setArchived(!archived);
                setMultiActionSelectedPatientList([]);
            }} />}
            label={labels.archivedFiles}
          />
          <FormControlLabel
            control={<Switch checked={multiAction} onChange={() => setMultiAction(!multiAction)} />}
            label={labels.multiActions}
          />
        </Grid>
        <Grid item md={2} container justifyContent="flex-end">
          {!archived && <Tooltip title={labels.addPatient}>{appVersionSpecific.homePage.addIcon}</Tooltip>}
        </Grid>
      </Grid>
      <Grid item md={12} container>
        <Divider style={{ width: '100%' }} />
      </Grid>
    </React.Fragment>
  );
}

export default HomeHeader;
