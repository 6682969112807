import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import labels from '../../../constants/labels/patient/edit/Contact.json';
import TextFieldFormControl from '../../TextFieldFormControl';
import RadioFormControl from '../../RadioFormControl';
import FormControlWithLegend from './FormControlWithLegend';
import EmailFieldFormControl from '../../EmailFieldFormControl';

type Props = {
  patient: {
    contactFirstName: ?string | undefined,
    contactLastName: ?string | undefined,
    contactSexe: ?string | undefined,
    contactLien: ?string | undefined,
    contactTel: ?string | undefined,
    contactMail: ?string | undefined
  },
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function
};

export default class Contact extends Component<Props> {
  props: Props;

  render() {
    const {
      patient: {
        contactFirstName = '',
        contactLastName = '',
        contactSexe = '',
        contactLien = '',
        contactTel = '',
        contactMail = ''
      },
      onChange
    } = this.props;

    return (
      <FormControlWithLegend legend={labels.contact}>
        <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
          <TextFieldFormControl
            id="patient.contactFirstName"
            controlName="contactFirstName"
            label={labels.firstName}
            value={contactFirstName}
            onChangeHandler={onChange}
            fullWidth
          />
          <TextFieldFormControl
            id="patient.contactLastName"
            controlName="contactLastName"
            label={labels.lastName}
            value={contactLastName}
            onChangeHandler={onChange}
            fullWidth
          />
          <RadioFormControl
            controlName="contactSexe"
            label={labels.sex.label}
            value={contactSexe}
            onChangeHandler={onChange}
            choices={[
              { value: 'F', label: labels.sex.female },
              { value: 'H', label: labels.sex.male }
            ]}
            otherLabel={null}
          />
          <RadioFormControl
            controlName="contactLien"
            label={labels.patientLink.label}
            value={contactLien}
            onChangeHandler={onChange}
            choices={[
              { value: 'mere', label: labels.patientLink.mother },
              { value: 'pere', label: labels.patientLink.father }
            ]}
            otherLabel={labels.patientLink.other}
          />
          <TextFieldFormControl
            id="patient.contactTel"
            controlName="contactTel"
            label={labels.phone}
            value={contactTel}
            onChangeHandler={onChange}
            fullWidth
          />
          <EmailFieldFormControl
            id="patient.contactMail"
            controlName="contactMail"
            label={labels.mail}
            value={contactMail}
            onChangeHandler={onChange}
            fullWidth
          />
        </Grid>
      </FormControlWithLegend>
    );
  }
}
