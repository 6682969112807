import React, { Component } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { AddCircle } from '@material-ui/icons';
import uuidv4 from 'uuid/v4';
import labels from '../../../../constants/configuration/tests/personal/TestList.json';
import TestValue, { Test } from './TestValue';
import { PersonalTestTypes } from './SelectTestType';

type Props = {
  tests: array<Test>,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function,
  controlName: string
};

class TestList extends Component<Props> {
  props: Props;

  changeTest = (test, index) => {
    const { tests, onChange, controlName } = this.props;
    const newTests = JSON.parse(JSON.stringify(tests));
    newTests[index] = test;
    const event = { target: { name: controlName, value: newTests } };
    onChange(event);
  };

  onDelete = index => {
    const { tests, onChange, controlName } = this.props;
    const newTests = JSON.parse(JSON.stringify(tests));
    newTests.splice(index, 1);
    const event = { target: { name: controlName, value: newTests } };
    onChange(event);
  };

  moveTest = (index, hoverIndex) => {
    const { tests, onChange, controlName } = this.props;
    const newTests = JSON.parse(JSON.stringify(tests));
    const test = newTests.splice(index, 1);
    newTests.splice(hoverIndex, 0, test[0]);
    const event = { target: { name: controlName, value: newTests } };
    onChange(event);
  };

  addTest = () => {
    const { tests, onChange, controlName } = this.props;
    const newTests = JSON.parse(JSON.stringify(tests));
    const test = {
      id: `custom_${uuidv4()}`,
      input: [PersonalTestTypes[0].key],
      label: ''
    };
    newTests.push(test);
    const event = { target: { name: controlName, value: newTests } };
    onChange(event);
  };

  render() {
    const { tests } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={1}>
            <IconButton onClick={this.addTest}>
              <AddCircle />
            </IconButton>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6">{labels.test}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">{labels.seizure}</Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <DndProvider backend={HTML5Backend}>
          {tests &&
            tests.map((test, index) => (
              <TestValue
                key={test.id}
                test={test}
                index={index}
                onChange={this.changeTest}
                handleDelete={this.onDelete}
                moveTest={this.moveTest}
              />
            ))}
        </DndProvider>
      </div>
    );
  }
}

export default TestList;
