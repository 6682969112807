import React from 'react';
import labels from '../../../constants/labels/patient/comments/ReportComments.json';
import AMOChoice from './AMOChoice';
import CommentTextWithTitle from './CommentTextWithTitle';
import Patient from '../../common/data/Patient';

const ReportComments = ({ comments, updateCommentsFunction, patient, updatePatientFunction }) => {
  const handleTextChange = async event => {
    const { [event.target.name]: newComment, ...spread } = comments;
    newComment.text = event.target.value;
    const updatedPatient = await Patient.updatePatient({
      ...patient,
      comments: { [event.target.name]: newComment, ...spread }
    });
    updatePatientFunction(updatedPatient);
    updateCommentsFunction(updatedPatient.comments);
  };

  const {
    summary: { text: summaryText },
    diagnosis: { text: diagnosisText },
    patientCare: { text: patientCareText },
    therapeuticPlan: { text: therapeuticPlanText }
  } = comments;

  const { lastName, firstName } = patient;

  const commentTextWithTitles = [
    { title: labels.synthesis, value: summaryText, controlName: 'summary', memo: 'comment-summary' },
    { title: labels.speechDiagnostic, value: diagnosisText, controlName: 'diagnosis', memo: 'comment-diagnosis' },
    {
      title: labels.supportElements,
      value: patientCareText,
      controlName: 'patientCare',
      extraElements: <AMOChoice value={patientCareText} controlName="patientCare" changeHandler={handleTextChange} />,
      memo: 'comment-patientCare'
    },
    {
      title: labels.therapeuticProject,
      value: therapeuticPlanText,
      controlName: 'therapeuticPlan',
      memo: 'comment-therapeuticPlan'
    }
  ];

  return (
    commentTextWithTitles.length &&
    commentTextWithTitles.map(({ children, ...commentTextWithTitle }) => (
      <CommentTextWithTitle
        key={commentTextWithTitle.title}
        changeHandler={handleTextChange}
        {...commentTextWithTitle}
        firstName={firstName}
        lastName={lastName}
      />
    ))
  );
};
export default ReportComments;
