import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Check, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import AnamnesisSelectionDropDown from './AnamnesisSelectionDropDown';
import labels from '../../../constants/labels/patient/anamnesis/AnamnesisFormCompletion.json';
import appVersionSpecific from '../../../appVersionSpecific.ortho';

type Props = {
  patientId: string,
  // eslint-disable-next-line flowtype/no-weak-types
  patient: any,
  onAnamnesisModelChange: () => void
};

const useStyles = makeStyles({
  showTooltipOnHover: {
    '& button': {
      display: 'none'
    },
    '&:hover button': {
      display: 'inline-block'
    }
  },
  inline: {
    '& .MuiGrid-root': {
      display: 'inline-block'
    }
  }
});

const AnamnesisLabel = ({ patientId, patient, onAnamnesisModelChange }: Props) => {
  const classes = useStyles();
  const isHappyNeuron = appVersionSpecific.isHappyNeuron;
  const [isEditing, setIsEditing] = useState(false);

  if (!isHappyNeuron && isEditing) {
    return (
      <span className={classes.inline}>
        <AnamnesisSelectionDropDown patientId={patientId} />
        <IconButton
          onClick={() => {
            setIsEditing(false);
            onAnamnesisModelChange();
          }}
        >
          <Check />
        </IconButton>
      </span>
    );
  }
  if (patient && patient.anamnese && patient.anamnese.name) {
    return (
      <span className={classes.showTooltipOnHover}>
        &quot;
        {patient && patient.anamnese && patient.anamnese.name}
        {!isHappyNeuron && (
          <Tooltip title={labels.modifyModel}>
            <IconButton onClick={() => setIsEditing(true)}>
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        &quot;
      </span>
    );
  }
  return null;
};

export default AnamnesisLabel;
