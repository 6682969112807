import React from 'react';
import { Divider, Grid, MenuItem, Typography } from '@material-ui/core';

import labels from '../../../../constants/labels/patient/anamnesis/elements/ParcoursScolaire.json';
import { frenchClasses as classes } from '../../../../constants/Grades';
import TextFieldFormControl from '../../../TextFieldFormControl';
import TextArea from './TextArea';
import HeadIconTrio from './HeadIconTrio';
import type { ParcoursScolaireType } from './ParcoursScolaireType';

type Props = {
  id?: string,
  span?: number,
  myValue?: ParcoursScolaireType,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function
};

function getParcoursScolaireRowCount(classeId) {
  return classeId > 11 ? 12 : classeId + 1;
}

const defaultParcoursScolaire = {
  appreciation: [...Array(17).keys()].map(() => ({
    ressenti: -1,
    resultats: -1
  })),
  commentaires: { '0': '', '1': '', '2': '' },
  curclasse: { label: '' }
};

const ParcoursScolaire = ({ id, span, myValue = defaultParcoursScolaire, changeHandler }: Props) => (
  <Grid key={id} item xs={span} container>
    <TextFieldFormControl
      id="curclasse.id"
      controlName="curclasse.id"
      itemMdValue={12}
      fullWidth
      select
      label={labels.label}
      value={myValue.curclasse.id}
      onChangeHandler={event =>
        changeHandler({
          target: {
            name: id,
            value: {
              ...myValue,
              curclasse: {
                ...myValue.curclasse,
                id: event.target.value
              }
            }
          }
        })
      }
    >
      <MenuItem value={-1}>{labels.noClassLevel}</MenuItem>
      {classes.map(({ id: classeId, label: classeLabel }) => (
        <MenuItem key={classeId} value={classeId}>
          {classeLabel}
        </MenuItem>
      ))}
    </TextFieldFormControl>
    <Grid xs={12} item>
      <Divider />
    </Grid>
    {myValue.curclasse.id &&
      myValue.curclasse.id > -1 &&
      [...Array(getParcoursScolaireRowCount(myValue.curclasse.id)).keys()].map(int => (
        <Grid xs={12} item container key={classes[int].label}>
          {(int === 0 || (int > 0 && classes[int].category !== classes[int - 1].category)) && (
            <Grid item xs={12} container>
              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom>
                  {classes[int].category}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom>
                  {labels.childFeeling}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom>
                  {labels.scholarResults}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={4}>
            <Typography variant="subtitle2" gutterBottom>
              {classes[int].label}
            </Typography>
          </Grid>
          <HeadIconTrio id={id} index={int} myValue={myValue} changeHandler={changeHandler} type="ressenti" />
          <HeadIconTrio id={id} index={int} myValue={myValue} changeHandler={changeHandler} type="resultats" />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {classes[int].category !== classes[int + 1].category && (
            <TextArea
              label={labels.comments}
              id={`commentaires.${classes[int].commentId}`}
              span={12}
              myValue={myValue.commentaires[`${classes[int].commentId}`]}
              changeHandler={event => {
                changeHandler({
                  target: {
                    name: id,
                    value: {
                      ...myValue,
                      commentaires: { ...myValue.commentaires, [classes[int].commentId]: event.target.value }
                    }
                  }
                });
              }}
            />
          )}
        </Grid>
      ))}
  </Grid>
);

ParcoursScolaire.defaultProps = {
  id: '',
  span: 12,
  myValue: defaultParcoursScolaire,
  changeHandler: () => {}
};

export default ParcoursScolaire;
