/* eslint no-var:0, vars-on-top:0, no-bitwise:0, no-plusplus:0 */

export const base64DecToArr = (sBase64, nBlocksSize) => {
  // eslint-disable-next-line no-useless-escape
  const sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, '');
  const nInLen = sB64Enc.length;
  const nOutLen = nBlocksSize ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize : (nInLen * 3 + 1) >> 2;
  const taBytes = new Uint8Array(nOutLen);

  for (var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx += 1) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (18 - 6 * nMod4);
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3 += 1, nOutIdx += 1) {
        taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
      }
      nUint24 = 0;
    }
  }

  return taBytes;
};

export const b64ToUint6 = nChr => {
  if (nChr > 64 && nChr < 91) {
    return nChr - 65;
  }
  if (nChr > 96 && nChr < 123) {
    return nChr - 71;
  }
  if (nChr > 47 && nChr < 58) {
    return nChr + 4;
  }
  if (nChr === 43) {
    return 62;
  }
  if (nChr === 47) {
    return 63;
  }
  return 0;
};

export const UTF8ArrToStr = aBytes => {
  var sView = '';

  for (var nPart, nLen = aBytes.length, nIdx = 0; nIdx < nLen; nIdx += 1) {
    nPart = aBytes[nIdx];
    if (nPart > 251 && nPart < 254 && nIdx + 5 < nLen) {
      sView += String.fromCharCode(
        (nPart - 252) * 1073741824 +
          ((aBytes[++nIdx] - 128) << 24) +
          ((aBytes[++nIdx] - 128) << 18) +
          ((aBytes[++nIdx] - 128) << 12) +
          ((aBytes[++nIdx] - 128) << 6) +
          aBytes[++nIdx] -
          128
      );
    } else if (nPart > 247 && nPart < 252 && nIdx + 4 < nLen) {
      sView += String.fromCharCode(
        ((nPart - 248) << 24) +
          ((aBytes[++nIdx] - 128) << 18) +
          ((aBytes[++nIdx] - 128) << 12) +
          ((aBytes[++nIdx] - 128) << 6) +
          aBytes[++nIdx] -
          128
      );
    } else if (nPart > 239 && nPart < 248 && nIdx + 3 < nLen) {
      sView += String.fromCharCode(
        ((nPart - 240) << 18) + ((aBytes[++nIdx] - 128) << 12) + ((aBytes[++nIdx] - 128) << 6) + aBytes[++nIdx] - 128
      );
    } else if (nPart > 223 && nPart < 240 && nIdx + 2 < nLen) {
      sView += String.fromCharCode(((nPart - 224) << 12) + ((aBytes[++nIdx] - 128) << 6) + aBytes[++nIdx] - 128);
    } else {
      sView += String.fromCharCode(
        nPart > 191 && nPart < 224 && nIdx + 1 < nLen /* two bytes */
          ? ((nPart - 192) << 6) + aBytes[++nIdx] - 128 /* nPart < 127 ? */
          : /* one byte */
            nPart
      );
    }
  }
  return sView;
};
