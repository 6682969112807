import { getAnnotationIndex, getGameScores, getScoreDefinition } from '../HappyNeuronImporterUtil';

const calculateRawResult = (eprId, workout, datas, input) => {
  const splitted = eprId.split('_');
  const gameId = parseInt(splitted[1], 10); // 646
  const scoreId = parseInt(splitted[2], 10); // 258
  if (!workout.exercises[gameId]) {
    return { eprId, score: undefined, annotation: undefined };
  }
  const gameScores = getGameScores(datas.scores.games, gameId);
  const annotationIndex = getAnnotationIndex(gameScores);
  const { position: scoreIndex, variante: varianteIndex } = getScoreDefinition(gameScores, scoreId);
  if (!varianteIndex) {
    return { eprId, score: undefined, annotation: undefined };
  }
  const variante = workout.exercises[gameId].variantes[varianteIndex];
  if (!variante) {
    return { eprId, score: undefined, annotation: undefined };
  }
  const { details } = variante;
  const score = details[scoreIndex];
  if (score === -1) {
    return { eprId, score: undefined, annotation: undefined };
  }
  if (['TEMPS_ET'].some(type => input.includes(type))) {
    const result = {
      eprId,
      score: Math.round((score / 1000) * 1000),
      annotation: annotationIndex ? details[annotationIndex] : undefined
    };
    if ([648, 654, 655].includes(gameId)) {
      return {...result, score: result.score / 1000};
    }
    return result;
  }

  return { eprId, score, annotation: annotationIndex ? details[annotationIndex] : undefined };
};

export default calculateRawResult;
