import React from "react";
import {Grid, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import labels from "../../../../constants/labels/configuration/memorizedTexts/MemorizedTextsPage.json";
import {getKeyFromTestCodeAndId} from "../../../common/data/MemoUtil";
import mtpStyle from "../MemorizedTextsPageStyle";

const useStyles = makeStyles(mtpStyle);

type Props = {
  selectedTestCode: {
    children: Array<{id: string, label: string}>,
    testCode: string
  },
  filteredMemoKeys: Array<string>,
  selectedMemoKey: string | null | undefined,
  setSelectedMemoKey: (string) => void
};
const TestCommentSubTestSelection = ({selectedTestCode, filteredMemoKeys, selectedMemoKey, setSelectedMemoKey}: Props) => {
  const classes = useStyles();

  return <Grid item md={3}>
    <Typography variant="h4">{labels.titles.testsComments}</Typography>
    <List component="nav" aria-label="sub" className={classes.mainList}>
      {selectedTestCode.children
        .filter(({id}) => {
          if (!filteredMemoKeys || !filteredMemoKeys.length) return true;
          return filteredMemoKeys.includes(getKeyFromTestCodeAndId(selectedTestCode.testCode, id));
        })
        .map(({id, label}) => (
          <ListItem
            button
            key={id}
            onClick={() => setSelectedMemoKey(getKeyFromTestCodeAndId(selectedTestCode.testCode, id))}
            selected={getKeyFromTestCodeAndId(selectedTestCode.testCode, id) === selectedMemoKey}
            className={classes.root}
          >
            <ListItemText primary={label}/>
          </ListItem>
        ))}
    </List>
  </Grid>;
};

export default TestCommentSubTestSelection;
