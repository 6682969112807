import React from 'react';
import { Grid, Typography } from '@material-ui/core';

type Props = {
  label: string
};

const Header = ({ label }: Props) => (
  <Grid key={label} item xs={12} container>
    <Typography variant="h5" gutterBottom style={{ textDecoration: 'underline' }}>
      {label}
    </Typography>
  </Grid>
);

export default Header;
