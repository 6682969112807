import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import AnamnesisElement from './AnamnesisElement';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class Input extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    return (
      <AnamnesisElement
        {...props}
        editableElement={editableElement}
        readOnlyElement={readOnlyElement}
      />
    );
  }
}

function editableElement(element, handleChange) {
  const { label, placeholder } = element;
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={label}
          fullWidth
          name="label"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          value={placeholder}
          fullWidth
          name="placeholder"
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}

function readOnlyElement(element) {
  const { label, placeholder } = element;
  return (
    <TextField
      disabled
      label={label}
      defaultValue={placeholder}
      style={{ marginBottom: '2rem' }}
    />
  );
}
