import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Create, Email, HourglassFull, NotificationsActive } from '@material-ui/icons';

import labels from '../../constants/labels/home/AnamneseLink.json';
import routes from '../../constants/routes';
import MoreActionsMenu from './MoreActionsMenu';

type Props = {
  patientId: string,
  responseAvailable: boolean,
  anamnese:
    | {
        status: string | undefined
      }
    | undefined
};

const AnamneseLink = ({ patientId, anamnese = {}, responseAvailable }: Props) => {
  const statusWithDefault = (responseAvailable && anamnese.status !== "RESPONSE") ? "RESPONSE_AVAILABLE" : (anamnese.status || 'default');
  const iconFromValue = {
    SENT: <HourglassFull color="action" />,
    RESPONSE_AVAILABLE: <NotificationsActive color="error"/>,
    RESPONSE: <Create color="action" />,
    CREATED: <Email color="action" />,
    default: <Email color="action" />
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <IconButton
          component={Link}
          to={
            statusWithDefault === "RESPONSE_AVAILABLE" ?
              routes.IMPORT.ANAMNESIS.getForPath({patientId})
              :
            statusWithDefault === "SENT" ?
              routes.IMPORT.ANAMNESIS.FROMFILE
              :
              routes.PATIENT.getForPath({
                path: routes.PATIENT.ANAMNESIS[statusWithDefault],
                patientId
              }
          )}
        >
          <Tooltip title={labels[statusWithDefault]}>{iconFromValue[statusWithDefault]}</Tooltip>
        </IconButton>
      </Grid>
      <Grid item xs={3}>
        <MoreActionsMenu menuItemEntries={getMoreActionMenuItemEntries(patientId)[statusWithDefault]} />
      </Grid>
    </Grid>
  );
};

export default AnamneseLink;

function getMoreActionMenuItemEntries(patientId) {
  const fillAnamnesis = {
    key: 'fillAnamnesis',
    element: (
      <Link
        to={routes.PATIENT.getForPath({
          path: routes.PATIENT.ANAMNESIS.RESPONSE,
          patientId
        })}
      >
        {labels.fillAnamnesis}
      </Link>
    )
  };

  const sendAnamnesis = {
    key: 'sendAnamnesis',
    element: (
      <Link
        to={routes.PATIENT.getForPath({
          path: routes.PATIENT.ANAMNESIS.default,
          patientId
        })}
      >
        {labels.sendAnamnesis}
      </Link>
    )
  };
  const sendAgain = {
    key: 'sendAgain',
    element: (
      <Link
        to={routes.PATIENT.getForPath({
          path: routes.PATIENT.ANAMNESIS.default,
          patientId
        })}
      >
        {labels.sendAgain}
      </Link>
    )
  };
  const integrateResponseFile = {
    key: 'integrateResponseFile',
    element: (
      <Link
        to={routes.IMPORT.ANAMNESIS.FROMFILE}
      >
        {labels.integrateResponseFile}
      </Link>
    )
  };

  // const integrateResponseFromServer = {
  //   key: 'integrateResponseFromServer',
  //   element: (
  //     <Link
  //       to={routes.IMPORT.ANAMNESIS.getForPath({patientId})}
  //     >
  //       {labels.integrateResponseFromServer}
  //     </Link>
  //   )
  // };

  return {
    SENT: [
      fillAnamnesis,
      sendAgain
    ],
    RESPONSE: [
      integrateResponseFile,
      fillAnamnesis,
      sendAnamnesis
    ],
    CREATED: [
      sendAnamnesis,
      fillAnamnesis
    ],
    RESPONSE_AVAILABLE: [
      integrateResponseFile,
      fillAnamnesis,
      sendAgain
    ],
    default: [
      fillAnamnesis
    ]
  };
}
