import React from 'react';
import { Route, Switch } from 'react-router';
import routes from '../constants/routes';
import AnamnesisMailImportFile from '../components/patient/anamnesis/AnamnesisMailImportFile';
import AnamnesisMailImportFileFromServer from '../components/patient/anamnesis/AnamnesisMailImportFileFromServer';
import PatientImportFile from '../components/patient/PatientImportFile';
import HappyNeuronImport from '../components/patient/import/HappyNeuronImport';

const ImportPage = () => (
  <Switch>
    <Route exact path={routes.IMPORT.HAPPYNEURON} component={HappyNeuronImport} />
    <Route exact path={routes.IMPORT.ANAMNESIS.FROMFILE} component={AnamnesisMailImportFile} />
    <Route exact path={routes.IMPORT.ANAMNESIS.FROMSERVER} component={AnamnesisMailImportFileFromServer} />
    <Route exact path={routes.IMPORT.PATIENT} component={PatientImportFile} />
  </Switch>
);

export default ImportPage;
