import React, {useEffect, useState} from "react";
import labels from "../../../../constants/labels/configuration/memorizedTexts/CheckupModelStructureComments.json";
import CommentType from "./CommentType";
import CheckUpModel from "../../../common/data/CheckUpModel";
import modeleBilanStandard from "../../../../constants/modele.bilan.standard.json";

type Props = {
  changeSelectedTestCodeAndMemoKey: (string, string) => void,
  filteredMemoKeys: Array<string>,
  selectedTestCode: string | {} | {
    children: Array<{id: string, label: string}>,
    testCode: string
  }
};

const CheckupModelStructureComments = ({changeSelectedTestCodeAndMemoKey,
                                         filteredMemoKeys,
                                         selectedTestCode}: Props) => {

  const [checkupModels, setCheckupModels] = useState([]);

  useEffect(() => {
    updateCheckupModels();
  }, []);

  const updateCheckupModels = async () => {
    const list = await CheckUpModel.retrieveModelList();
    setCheckupModels(list);
  };

  return <CommentType
    label={labels.label}
    listItems={[
      {
        key: "modeleBilanStandard", label: "modèle de bilan standard", onClick: () => {changeSelectedTestCodeAndMemoKey({...modeleBilanStandard, _id: 0}, null)}, selected: selectedTestCode._id === 0
      },
      ...checkupModels.map((checkupModel) => ({key: checkupModel._id, label: checkupModel.label, onClick: () => {changeSelectedTestCodeAndMemoKey(checkupModel, null)}, selected: selectedTestCode._id === checkupModel._id}))
    ]}
  />;
};

export default CheckupModelStructureComments;
