import React, { Component } from 'react';
import { FormControl, FormLabel, Select, Typography } from '@material-ui/core';

import AnamnesisElement from './AnamnesisElement';
import labels from '../../../../constants/labels/configuration/anamnesis/Anamnesis';
import log from '../../../Logger';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class ParcoursScolaire extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    log.info(props.element);
    return <AnamnesisElement {...props} editableElement={readOnlyElement} readOnlyElement={readOnlyElement} />;
  }
}

function readOnlyElement() {
  return (
    <div>
      <FormLabel component="legend">{labels.tabs.form.right.schoolPath.label}</FormLabel>
      <FormControl>
        <Select native disabled>
          <option value="">{labels.tabs.form.right.schoolPath.smallSection}</option>
        </Select>
      </FormControl>
      <Typography variant="body2" gutterBottom>
        {labels.tabs.form.right.schoolPath.notaBene}
      </Typography>
    </div>
  );
}
