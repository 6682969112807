import React, { useState } from 'react';
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import labels from '../../../constants/configuration/tests/SeuilEditor.json';

type Props = {
  controlName: string,
  label: string,
  value?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  onChangeHandler: Function,
  defaultValueCent: string,
  defaultValueET: string,
  defaultValueText: string,
  displayChangeUnitButton?: boolean,
  itemMdValue?: number,
  // eslint-disable-next-line flowtype/no-weak-types
  textFieldStyle?: Object,
  disabled?: boolean
};

const SeuilEditor = ({
  value,
  label,
  controlName,
  defaultValueText,
  displayChangeUnitButton,
  itemMdValue,
  textFieldStyle,
  disabled,
  onChangeHandler,
  defaultValueCent,
  defaultValueET
}: Props) => {
  const [mUnit, inValue] = (value || '').split(':');
  const [mValue, setMValue] = useState(Number(inValue));

  const onChangeUnit = () => {
    const newValue = mUnit === 'CENT' ? defaultValueET : defaultValueCent;
    const event = { target: { name: controlName, value: newValue } };
    onChangeHandler(event);
  };

  return (
    <Grid item xs={itemMdValue}>
      <TextField
        name={controlName}
        label={label}
        value={mValue}
        type="number"
        onChange={e => {
          setMValue(e.target.value);
        }}
        onBlur={() => onChangeHandler({ target: { name: controlName, value: `${mUnit}:${mValue}` } })}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          step: '0.1',
          endAdornment: <InputAdornment position="end">{mUnit}</InputAdornment>
        }}
        style={textFieldStyle}
        helperText={defaultValueText}
        disabled={disabled}
      />
      {displayChangeUnitButton && <Button onClick={onChangeUnit}>{mUnit === 'CENT' ? labels.ET : labels.CENT}</Button>}
    </Grid>
  );
};

SeuilEditor.defaultProps = {
  value: '',
  displayChangeUnitButton: true,
  itemMdValue: 6,
  textFieldStyle: {},
  disabled: false
};

export default SeuilEditor;
