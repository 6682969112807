import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import appVersionSpecific from '../appVersionSpecific.ortho';

const useStyles = makeStyles(theme => ({
  listItem: {
    width: 'unset',
    color: '#bbe9f8',
    textTransform: 'uppercase',
    marginLeft: '12px',
    marginRight: '12px',
    borderRadius: '8px',
    '& > div.MuiListItemIcon-root  > svg': {
      fill: '#bbe9f8'
    }
  },
  listItemHover: {
    '&:hover': {
      backgroundColor: '#5bb5a7',
      color: 'white',
      '& > div.MuiListItemIcon-root  > svg': {
        fill: 'white'
      }
    }
  },
  currentLocation: {
    backgroundColor: 'rgba(91, 181, 167, 0.7)',
    color: 'white',
    '& > div.MuiListItemIcon-root  > svg': {
      fill: 'white'
    }
  },
  nested: {
    textTransform: 'none',
    paddingLeft: theme.spacing(4)
  }
}));

type Props = {
  route: string | undefined,
  // eslint-disable-next-line flowtype/no-weak-types
  clickAction: any,
  label: string,
  icon: React.ComponentElement,
  subMenu: Array<> | undefined,
  nested?: boolean
};

// eslint-disable-next-line react/prop-types
const TileElement = ({ route, clickAction, label, icon, subMenu, nested }: Props) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  if (subMenu && subMenu.length) {
    return (
      <React.Fragment>
        <ListItem
          className={classNames(
            classes.listItem,
            classes.listItemHover,
            { [classes.currentLocation]: pathname === route },
            { [classes.nested]: nested }
          )}
          style={{ marginTop: '2em' }}
          component={Link}
          to={route}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
        <Collapse in={pathname.includes(route)}>
          <List component="div" disablePadding>
            {subMenu.map(({ ...sm }) => (
              <TileElement key={sm.label} {...{ nested: true, ...sm }} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }
  return route && !clickAction ? (
    <ListItem
      component={Link}
      to={route}
      className={classNames(
        classes.listItem,
        classes.listItemHover,
        { [classes.currentLocation]: pathname === route },
        { [classes.nested]: nested }
      )}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  ) : (
    <ListItem button onClick={clickAction} className={classNames(classes.listItem, classes.listItemHover)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};

TileElement.defaultProps = {
  nested: false
};

const TileData = () => (
  <React.Fragment>
    {appVersionSpecific.mainMenu.map(({ label, tooltip, ...menu }) => (
      <TileElement {...{ label, ...menu }} key={label} />
    ))}
  </React.Fragment>
);

export default TileData;
