import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { Autorenew } from '@material-ui/icons';

import moment from 'moment';
import labels from '../../constants/labels/user/LicenceForm.json';
import ReportGeneratorUtil from '../patient/report/ReportGeneratorUtil';
import log from '../Logger';
import User from '../common/data/User';
import UserLogin from '../common/data/UserLogin';
import Loader from '../common/Loader';
import Invoices from './Invoices';
import { fetchJson, retrieveWebSiteHost } from '../common/OrthoscribeWebSite';

export default function LicenceForm() {
  const [{ firstName, lastName, email, expiresUnixTimestamp, testLicences, tests }, setLicense] = useState({
    firstName: '',
    lastName: '',
    email: '',
    expiresUnixTimestamp: '',
    options: '',
    testLicences: [],
    tests: {}
  });

  const [licenceStatus, setLicenceStatus] = useState('Pas de licence');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  async function fetchLicence() {
    try {
      await setIsLoading(true);
      const { val: cryptedLicense } = await User.retrieveValueFromDb({ keyValue: 'license' });
      const license = await ReportGeneratorUtil.decryptLicence(cryptedLicense);
      log.info({ license });
      await setLicense(license);
      if (moment.unix(license.expiresUnixTimestamp + 3600 * 24).isBefore(moment())) {
        await setLicenceStatus('Licence expirée');
      } else if (Object.keys(license).length > 0) {
        await setLicenceStatus('Licence complète');
      } else {
        await setLicenceStatus('Pas de licence');
      }
      await setIsLoading(false);
    } catch (e) {
      await setIsLoading(false);
      setLoadingError(true);
    }
  }

  async function renewLicence() {
    try {
      await setIsLoading(true);
      const webSiteHost = await retrieveWebSiteHost();
      const { val: authToken } = await User.retrieveValueFromDb({ keyValue: 'authToken' });

      const { status, payload } = await fetchJson(`${webSiteHost}/api/user/license/get`, {
        method: 'POST',
        body: JSON.stringify({ authToken }),
        headers: {
          'Content-Type': 'application/json',
          dataType: 'json'
        }
      });

      console.log({ status, payload });
      if (status !== 'ok') {
        if (payload && payload.code && payload.code === 'INVALIDAUTH') {
          // eslint-disable-next-line no-new
          new Notification(labels.renew.error.title, {
            body: labels.renew.error.label
          });
          await UserLogin.logout();
          setTimeout(() => {
            Window.location.reload();
          }, 1000);
        }
        await setIsLoading(false);
        await setLoadingError(true);
        return;
      }
      await User.save({ keyValue: 'license', value: payload });
      await fetchLicence();
      await setIsLoading(false);
    } catch (e) {
      await setIsLoading(false);
      await setLoadingError(true);
    }
  }

  useEffect(() => {
    fetchLicence();
  }, []);

  return (
    <Grid item container md={12} spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h5" style={{ paddingBottom: '1em', paddingTop: '1em' }}>
          {labels.header.userLicence}
        </Typography>
      </Grid>
      <Grid item xs={6} container justifyContent="flex-end">
        <Tooltip title={labels.renew.buttonLabel}>
          <IconButton onClick={renewLicence}>
            <Autorenew />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <Paper elevation={1} style={{ width: '100%', marginTop: '1em', padding: '1em' }}>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            {// eslint-disable-next-line no-nested-ternary
            isLoading ? (
              <Grid item xs={12}>
                <Loader />
              </Grid>
            ) : loadingError ? (
              <Grid item xs={6}>
                {labels.decryptError}
              </Grid>
            ) : (
              <Fragment>
                <Grid item xs={6}>
                  {labels.name}
                </Grid>
                <Grid item xs={6}>
                  {firstName} {lastName}
                </Grid>
                <Grid item xs={6}>
                  {labels.email}
                </Grid>
                <Grid item xs={6}>
                  {email}
                </Grid>
                <Grid item xs={6}>
                  {labels.licence}
                </Grid>
                <Grid item xs={6}>
                  {licenceStatus}
                </Grid>
                <Grid item xs={6}>
                  {labels.expiryDate}
                </Grid>
                <Grid item xs={6}>
                  {moment.unix(expiresUnixTimestamp).format('DD/MM/YYYY')}
                </Grid>
                <Grid item xs={6}>
                  {labels.options}
                </Grid>
                <Grid item xs={6}>
                  {testLicences.filter(name => moment.unix(tests[name] + 3600 * 24).isAfter(moment())).join(', ')}
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Invoices />
    </Grid>
  );
}
