import React, { Component } from 'react';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField
} from '@material-ui/core';

import AnamnesisElement from './AnamnesisElement';

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  element: any,
  // eslint-disable-next-line flowtype/no-weak-types
  elementFunction: any,
  rowNumber: number,
  columnNumber: number
};

export default class Radios extends Component<Props> {
  props: Props;

  render() {
    const { ...props } = this.props;
    return (
      <AnamnesisElement
        {...props}
        editableElement={editableElement}
        readOnlyElement={readOnlyElement}
      />
    );
  }
}

function editableElement(element, handleChange) {
  const { label, options } = element;
  return (
    <FormControl component="fieldset">
      <TextField
        InputLabelProps={{ shrink: true }}
        value={label}
        fullWidth
        name="label"
        onChange={handleChange}
      />
      <RadioGroup>{getOptionsMap(options)}</RadioGroup>
    </FormControl>
  );
}

function readOnlyElement(element) {
  const { label, options } = element;
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup>{getOptionsMap(options)}</RadioGroup>
    </FormControl>
  );
}

function getOptionsMap(options) {
  // TODO find the inputRef pb
  return options.map(
    ({ id, label, type }) =>
      type === 'freevalue' ? (
        <div key={id}>
          <FormControlLabel
            value={id}
            control={<Radio />}
            disabled
            label={label}
          />
          <TextField disabled />
        </div>
      ) : (
        <FormControlLabel
          key={id}
          value={id}
          control={<Radio />}
          disabled
          label={label}
        />
      )
  );
}
