import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Grid } from '@material-ui/core';

import buildInfo from '../../buildInfo.json';
import changeLogMd from '../../CHANGELOG.md';
import appVersionSpecific from '../../appVersionSpecific.ortho';
import MyStore from '../../actions/MyStore';

const css = `
h1,
h1 ~ *,
h2 ~ h1,
h2 ~ h1 ~ *,
h3 ~ h1,
h3 ~ h1 ~ * {
    margin-left: 0px;
    color: #477988;
}


h2,
h2 ~ *,
h1 ~ h2,
h1 ~ h2 ~ *:not(h1):not(h3):not(details) {
    margin-left: 40px;
}

h3,
h3 ~ *,
h1 ~ h3,
h1 ~ h3 ~ *:not(h1):not(h2):not(details) {
    margin-left: 80px;
}

details {
    margin-left: unset;
}
details summary {
    cursor: pointer;
}
details summary > * {
    display: inline
}
`;

const WhatsNew = () => {
  const [changeLog, setChangeLog] = useState();

  useEffect(() => {
    loadNews();
    setVersionViewed();
  }, []);

  const loadNews = async () => {
    const res = await fetch(changeLogMd);
    const newsContentMD = await res.text();
    setChangeLog(newsContentMD);
  };

  const setVersionViewed = () => {
    MyStore.set(appVersionSpecific.electronStoreLatestVersionNewDisplayed, buildInfo.version);
  };

  return (
    <Grid item container md={12} spacing={2}>
      <style type="text/css">{css}</style>
      <Grid item md={12}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            // Rewrite `img`s to limit their width
            img: ({ ...props }) => <img style={{ maxWidth: '70vw' }} {...props} alt="" />,
            // eslint-disable-next-line react/prop-types
            a: ({href, children, ...props}) => <a href={href} {...props} target="_blank" rel="noreferrer">{children}</a>
          }}
        >
          {changeLog}
        </ReactMarkdown>
      </Grid>
    </Grid>
  );
};

export default WhatsNew;
