import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import appVersionSpecific from '../appVersionSpecific.ortho';

const useStyles = makeStyles({
  logo: {
    fontFamily: 'montserrat',
    fontSize: '3em',
    fontWeight: '600',
    margin: '0',
    color: '#477988',
    '& span:first-child': {
      textTransform: 'capitalize',
      color: '#5bb5a7'
    },
    width: '-webkit-fill-available',
    textTransform: "none"
  }
});

const Logo = () => {
  const classes = useStyles();
  return (
    <Typography variant="subtitle1" gutterBottom color="inherit" noWrap className={classes.logo}>
      <span>{appVersionSpecific.appName}</span>
      Scribe
    </Typography>
  );
};

export default Logo;
