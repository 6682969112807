const currentTestHasCorrespondingCalculationTable = (currentTest, calculationTableKey) => {
  if (!currentTest.calcul) {
    return false;
  }
  return !!currentTest.calcul
    .filter(({ id, classes }) => calculationTableKey === id || calculationTableKey === classes)
    .shift();
};

export default currentTestHasCorrespondingCalculationTable;
