const INDEX_P95 = 6;
const INDEX_P90 = 5;
const INDEX_Q3 = 4;
const INDEX_M = 3;
const INDEX_Q1 = 2;
const INDEX_P10 = 1;
const INDEX_P5 = 0;

const calculateExalangAscending = ({ value, pctTable }) => {
  if (pctTable.length !== 7) {
    return {};
  }

  if (value >= pctTable[INDEX_P95].v) {
    return { percentileValue: 95, label: '>95', sign: '>' };
  }
  if (value >= pctTable[INDEX_P90].v && value < pctTable[INDEX_P95].v) {
    return { percentileValue: 95, label: '95' };
  }
  if (value >= pctTable[INDEX_Q3].v && value < pctTable[INDEX_P90].v) {
    return { percentileValue: 90, label: '90' };
  }
  if (value >= pctTable[INDEX_M].v && value < pctTable[INDEX_Q3].v) {
    return { percentileValue: 75, label: 'Q3' };
  }
  if (value >= pctTable[INDEX_Q1].v && value < pctTable[INDEX_M].v) {
    return { percentileValue: 50, label: 'Med.' };
  }
  if (value >= pctTable[INDEX_P10].v && value < pctTable[INDEX_Q1].v) {
    return { percentileValue: 25, label: 'Q1' };
  }
  if (value >= pctTable[INDEX_P5].v && value < pctTable[INDEX_P10].v) {
    return { percentileValue: 10, label: '10' };
  }
  return { percentileValue: 5, label: '5' };
};

const calculateExalangDescending = ({ value, pctTable }) => {
  if (pctTable.length !== 7) {
    return {};
  }

  // If the calculation data are not in the expected order, sort it reversed
  // if (!(pctTable[INDEX_P5].v > pctTable[INDEX_P90].v)) {
  //   pctTable = pctTable.reverse();
  // }

  if (value > pctTable[INDEX_P5].v) {
    return { value: 5, label: '5' };
  }
  if (value > pctTable[INDEX_P10].v && value <= pctTable[INDEX_P5].v) {
    return { value: 10, label: '10' };
  }
  if (value > pctTable[INDEX_Q1].v && value <= pctTable[INDEX_P10].v) {
    return { value: 25, label: 'Q1' };
  }
  if (value > pctTable[INDEX_M].v && value <= pctTable[INDEX_Q1].v) {
    return { value: 50, label: 'Med.' };
  }
  if (value > pctTable[INDEX_Q3].v && value <= pctTable[INDEX_M].v) {
    return { value: 75, label: 'Q3' };
  }
  if (value > pctTable[INDEX_P90].v && value <= pctTable[INDEX_Q3].v) {
    return { value: 90, label: '90' };
  }
  if (value > pctTable[INDEX_P95].v && value <= pctTable[INDEX_P90].v) {
    return { value: 95, label: '95' };
  }

  return { value: 95, label: '>95', sign: '>' };
};

export { calculateExalangAscending, calculateExalangDescending };
