function not(a, b) {
  return a.filter(value => !b.includes(value));
}

function intersection(a, b) {
  return a.filter(value => b.includes(value));
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export { not, union, intersection };
