/* eslint  react/prop-types:off */
import React from 'react';
import { useDrop } from 'react-dnd';
import { AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const AssignmentExpansionPanelSummary = props => {
  const { element, changeCurrentExpandedPanel, currentExpandedPanels } = props;

  const [, drop] = useDrop({
    accept: 'assignment',
    canDrop() {
      return false;
    },
    hover(item, monitor) {
      if (monitor.isOver({ shallow: true }) && !currentExpandedPanels.includes(element.uuid)) {
        changeCurrentExpandedPanel(element.uuid)();
      }
    }
  });

  return (
    <div ref={drop}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{element.label}</Typography>
      </AccordionSummary>
    </div>
  );
};

export default AssignmentExpansionPanelSummary;
