const templateList = [
  {
    fileName: 'orthoTemplate_rtf.rtf',
    label: 'Modèle Microsoft Word',
    isDefault: true
  },
  {
    fileName: 'orthoTemplate_odt.odt',
    label: 'Modèle LibreOffice',
    isDefault: true
  }
];

export default templateList;
