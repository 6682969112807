import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import classNames from "classnames";

const TypoInGrid = ({
                      condition,
                      size,
                      label,
                      style,
                      emptyBlocOtherwize
                    }: {
  condition: boolean,
  size: number,
  label: string,
  // eslint-disable-next-line flowtype/no-weak-types
  style: any,
  emptyBlocOtherwize?: boolean
}) => {
  if (condition) {
    return (
      <Grid item xs={size} className={classNames(style.padding20, style.marginTopBottomAuto)}>
        <Typography variant="subtitle2" className={style.bold}>
          {label}
        </Typography>
      </Grid>
    );
  }
  if (emptyBlocOtherwize) {
    return <Grid item xs={size} />;
  }
  return null;
};
TypoInGrid.defaultProps = { emptyBlocOtherwize: false };

export default TypoInGrid;
