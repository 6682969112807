import React, { Fragment } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import labels from '../../../constants/labels/patient/patientConsult/anamnesis.json';
import AnamneseLink from '../../home/AnamneseLink';
import AnamnesisActionPhrase from '../../common/anamnesis/AnamnesisActionPhrase';
import patientConsultStyle from './patientConsultStyle.json';
import dateFormatOptions from './dateFormatOptions.json';
import type {Patient} from "../Patient";

const useStyle = makeStyles({ ...patientConsultStyle });

type Props = {
  patient: Patient,
  patientHasAnamnesisResponseAvailable: boolean,
  anamnesis: | {
        status: string | undefined
      }
    | undefined
};

const Anamnesis = ({ patient, anamnesis, patientHasAnamnesisResponseAvailable }: Props) => {
  const classes = useStyle();
  const patientId = patient._id;
  return (
    <Fragment>
      <Grid item xs={12} container>
        <Typography variant="h6">{labels.title}</Typography>
      </Grid>
      <Paper className={classes.paper}>
        <Grid item xs={12} container>
          <Grid item xs={1}>
            <AnamneseLink patientId={patientId} anamnese={anamnesis} responseAvailable={patientHasAnamnesisResponseAvailable}/>
          </Grid>
          <Grid item xs={11} className={classes.verticalAlign}>
            {anamnesis.status === 'SENT' && (
              <Typography variant="body1" className={classes.verticalAlign}>
                {labels.phrase0.part0}
                <span>{anamnesis.name}</span>
                {patient.contactMail && `${labels.phrase0.part1} ${patient.contactMail}`}
                {anamnesis.creationDate &&
                  `${labels.phrase0.part2} ${new Date(anamnesis.creationDate).toLocaleDateString(
                    'fr-FR',
                    dateFormatOptions
                  )}`}
                {labels.phrase0.part3}
              </Typography>
            )}
            {(['CREATED', 'default'].includes(anamnesis.status) || !anamnesis.status) && (
              <Typography variant="body1">{labels.notSent}</Typography>
            )}
            {anamnesis.status === 'RESPONSE' && <Typography variant="body1">{labels.response}</Typography>}
          </Grid>
          <AnamnesisActionPhrase patientId={patientId} anamnese={anamnesis} />
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default Anamnesis;
