export default class Cotation {
  static TYPE = 'cotations';

  static getNewEmptyOne({ cotationId, bilanId }) {
    return {
      _id: cotationId,
      bilanId,
      dbtype: Cotation.TYPE,
      creationDate: new Date(),
      modificationDate: new Date(),
      results: {},
      initialResults: null
    };
  }
}
