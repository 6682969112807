import localDatabases from '../../../database/database';
import log from '../../Logger';

export default class AppConfig {
  static TYPE = 'AppConfig';

  static async retrieveValueFromDb({ keyValue, defaultValue = null }) {
    try {
      const databaseInstance = localDatabases.getInstance();
      if (databaseInstance) {
        const documents = await databaseInstance.v1databaseRepository.find({
          selector: {
            _id: AppConfig.setId(keyValue),
            dbtype: AppConfig.TYPE
          }
        });
        const doc = documents.docs[0] || { _id: keyValue, k: keyValue, val: defaultValue };
        // log.info('User.retrieveValueFromDb(), found doc :');
        // log.table(doc);
        return { ...doc, _id: this.getId(doc._id) };
      }
      return { _id: keyValue, k: keyValue, val: defaultValue };
    } catch (err) {
      log.error(`Error retrieving user key ${keyValue}`, err);
      throw err;
    }
  }

  static async save({ keyValue, value }) {
    const _id = AppConfig.setId(keyValue);
    try {
      const documents = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id,
          dbtype: AppConfig.TYPE
        }
      });
      const doc = documents.docs[0] || { _id, k: keyValue, val: value, dbtype: AppConfig.TYPE };
      await localDatabases
        .getInstance()
        .v1databaseRepository.put({ ...doc, val: value, k: keyValue, dbtype: AppConfig.TYPE });
    } catch (err) {
      log.error(`Error when saving user key ${keyValue}`, err);
      throw err;
    }
  }

  static setId(id) {
    if (id && typeof id === 'string' && id.startsWith(AppConfig.TYPE)) {
      return id;
    }
    return AppConfig.TYPE + id;
  }

  static getId(id) {
    if (id && typeof id === 'string' && id.startsWith(AppConfig.TYPE)) {
      return id.substring(AppConfig.TYPE.length);
    }
    return id;
  }
}
