const routes = {
  HOME: '/',
  PATIENT: {
    MAIN: '/patient/:patientId',
    EDIT: '/edit',
    CONSULT: '/consult',
    RENEWAL: {
      CHOOSE: '/renewal/choose',
      CREATE: '/renewal/create/:originPatientId',
      LINK: '/renewal/link/:originPatientId',
      REMOVE: '/renewal/remove'
    },
    CHECKUP: {
      COMMENTS: '/checkup/:bilanId/comments',
      getForPath: (path, patientId, bilanId) =>
        (routes.PATIENT.MAIN + path).replace(':patientId', patientId).replace(':bilanId', bilanId)
    },
    ANAMNESIS: {
      MAIN: '/anamnesis',
      RESPONSE: '/anamnesis/FormCompletion',
      SENT: '/anamnesis/FormCompletion',
      default: '/anamnesis/FormSend',
      changeModel: '/anamnesis/Model',
      MAIL_RECAP: '/anamnesis/MailRecap',
      MAIL_RESULT: '/anamnesis/MailResult'
    },
    TESTS: {
      SELECT: '/testsSelection',
      TESTS: '/tests',
      LISTING_BY_MODEL: '/listingByModel',
      FILLING: '/bilan/:bilanId/test/:testCode',
      IMPORT_TEST: '/bilan/:bilanId/test/:testCode/import',
      getForPath: ({ path, patientId, testCode, bilanId }) =>
        (routes.PATIENT.MAIN + path)
          .replace(':patientId', patientId)
          .replace(':testCode', testCode)
          .replace(':bilanId', bilanId)
    },
    REPORT: {
      GENERATE: '/report',
      OPTIONS: '/report/options',
      GRAPHICS: '/report/graphics',
      ARRAYS: '/report/arrays',
      getForPath: ({ path, patientId }) => (routes.PATIENT.MAIN + path).replace(':patientId', patientId)
    },
    getForPath: ({ path, patientId, originPatientId }) =>
      (routes.PATIENT.MAIN + path).replace(':patientId', patientId).replace(':originPatientId', originPatientId)
  },
  CONFIGURATION: {
    MAIN: '/configuration',
    ANAMNESIS: {
      MAIN: '/anamnesis',
      EDIT: '/anamnesis/:anamnesisId/edit',
      getForPath: anamnesisId =>
        routes.CONFIGURATION.MAIN + routes.CONFIGURATION.ANAMNESIS.EDIT.replace(':anamnesisId', anamnesisId)
    },
    TESTS: {
      MAIN: '/tests',
      LIST: '/tests/list',
      PERSONAL_TESTS: {
        LIST: '/tests/personalTests',
        EDIT: '/tests/personalTests/:testId',
        getForPath: testId =>
          routes.CONFIGURATION.MAIN + routes.CONFIGURATION.TESTS.PERSONAL_TESTS.EDIT.replace(':testId', testId)
      },
      OPTIONS: '/testOptions'
    },
    MODELS: {
      MAIN: '/models',
      EDIT: '/models/:checkUpModelId/edit/:shouldDuplicateMemo',
      getForPath: ((modelId, shouldDuplicateMemo) =>
        routes.CONFIGURATION.MAIN + routes.CONFIGURATION.MODELS.EDIT.replace(':checkUpModelId', modelId).replace(':shouldDuplicateMemo', shouldDuplicateMemo))
    },
    DOCUMENT_MODEL: '/documentModels',
    MEMORIZED_TEXTS: '/memorizedTexts',
    REPORT_OPTIONS: {
      MAIN: '/reportOptions',
      REPORT: '/report',
      GRAPHIC: '/graphic',
      TABLE: '/table',
      getForPath: path => routes.CONFIGURATION.MAIN + routes.CONFIGURATION.REPORT_OPTIONS.MAIN + path
    },
    DISPLAY: {
      MAIN: '/display'
    },
    getForPath: path => routes.CONFIGURATION.MAIN + path
  },
  OPERATIONS: '/operations',
  USER: {
    MAIN: '/user',
    PROFILE: '/user/profile',
    MAIL_UPDATE: '/user/mailUpdate'
  },
  IMPORT: {
    MAIN: '/import',
    ANAMNESIS: {
      FROMFILE: '/import/anamnesis/fromFile',
      FROMSERVER: '/import/anamnesis/fromServer/:patientId',
      getForPath: ({ patientId }) => (routes.IMPORT.ANAMNESIS.FROMSERVER).replace(':patientId', patientId)
    },
    HAPPYNEURON: '/import/happyNeuron',
    PATIENT: '/import/patient'
  }
};

export default routes;
