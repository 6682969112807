import React, { useState } from 'react';
import RichTextEditor, { CustomControl } from 'react-rte';
import { makeStyles } from '@material-ui/styles';
import { getColorEntity, getColorStyles, getTextAlignStyles } from 'react-rte/lib/lib/blockStyleFunctions';
import MyRichTextEditor from './MyRichTextEditor';

type Props = {
  value: string,
  controlName: string,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function,
  customControls?: Array<CustomControl>
};

const useStyles = makeStyles({
  editorMaxHeight: {
    '& .public-DraftEditor-content': {
      height: '45vh',
      maxHeight: '45vh'
    }
  }
});

function MyStateFullEditor({ value, controlName, customControls, onChange }: Props) {
  const classes = useStyles();

  const [text, setText] = useState(
    value
      ? RichTextEditor.createValueFromString(value, 'html', { customInlineFn: getColorEntity })
      : RichTextEditor.createEmptyValue()
  );

  const onBlur = () => {
    onChange({
      target: {
        name: controlName,
        value: text.toString('html', { blockStyleFn: getTextAlignStyles, entityStyleFn: getColorStyles })
      }
    });
  };

  return (
    <MyRichTextEditor
      value={text}
      onChange={newValue => {
        setText(newValue);
      }}
      onBlur={onBlur}
      className={classes.editorMaxHeight}
      customControls={customControls}
      withColors
    />
  );
}

MyStateFullEditor.defaultProps = {
  customControls: []
};

export default MyStateFullEditor;
