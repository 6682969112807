import React, { useState, useEffect, Fragment } from 'react';
import { Divider, Fab, Grid, Paper, Typography } from '@material-ui/core';
import labels from '../../constants/labels/user/UserMailUpdate.json';
import User from '../common/data/User';
import EmailFieldFormControl from '../EmailFieldFormControl';
import UserLogin from '../common/data/UserLogin';
import { hexEncode } from '../common/hex';
import { fetchJson, retrieveWebSiteHost } from '../common/OrthoscribeWebSite';
import localDatabases from '../../database/database';

import { useHistory } from 'react-router';
import routes from '../../constants/routes';

const UserMailUpdate = () => {
  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [mailIsValid, setMailIsValid] = useState(false);
  const history = useHistory();

  useEffect(() => {
    loadUserMail();
  }, []);

  const loadUserMail = async () => {
    const user = await User.getUser(['email']);
    if (user && user.email) {
      setCurrentEmail(user.email);
    }
  };

  const updateWebSiteEmail = async (webSiteHost, authToken, currentEmail, newEmail) => {
    const {status, payload} = await fetchJson(`${webSiteHost}/api/user/changeEmail`, {
      method: 'POST',
      body: JSON.stringify({authToken, currentEmail, newEmail}),
      headers: {
        'Content-Type': 'application/json',
        dataType: 'json'
      }
    });
    console.log({status, payload})
  }

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const updateEmail = async event => {
    event.preventDefault();
    const webSiteHost = await retrieveWebSiteHost();
    const { val: authToken } = await User.retrieveValueFromDb({ keyValue: 'authToken' });
    console.log('Synchronisation de vos dossiers de base de données.');

    localDatabases.getNewEmailInstance(hexEncode(newEmail));

    await delay(5000);

    console.log(`Mise à jour de votre adresse mail sur le site orthoscribe.fr`);
    await updateWebSiteEmail(webSiteHost, authToken, currentEmail, newEmail);

    await delay(1000);

    await UserLogin.logout();

    await delay(1000);
    history.push(routes.HOME);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <Fragment>
          <Typography variant="h5" style={{ paddingBottom: '1em', paddingTop: '1em' }}>
            {labels.title}
          </Typography>
          <Divider />
          <form onSubmit={updateEmail}>
            <Paper elevation={1} style={{ width: '100%', marginTop: '1em', padding: '1em' }}>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={4}>
                <EmailFieldFormControl
                  id="currentEmail"
                  controlName="currentEmail"
                  itemMdValue={6}
                  value={currentEmail}
                  disabled
                  onChangeHandler={() => {}}
                  fullWidth
                  label={labels.email.current}
                />
                <EmailFieldFormControl
                  id="newEmail"
                  controlName="newEmail"
                  itemMdValue={6}
                  value={newEmail}
                  onChangeHandler={({ target: { value } }) => {
                    setNewEmail(value);
                  }}
                  mailIsValid={setMailIsValid}
                  fullWidth
                  label={labels.email.new}
                />
                <Grid item xs={12} container justifyContent="flex-start">
                  <Fab
                    type="submit"
                    disabled={!mailIsValid || !newEmail || !currentEmail}
                    variant="extended"
                    color="primary"
                  >
                    {labels.submit}
                  </Fab>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </Fragment>
      </Grid>
    </Grid>
  );
};

export default UserMailUpdate;
