import React, { useState } from 'react';
import { Button, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';

import labels from '../../../constants/labels/patient/import/ImportMessage.json';
import MyStore from "../../../actions/MyStore";


const ImportMessage = () => {
  const { displayImportMessage = true } = MyStore.get('displayImportMessage') || {};
  const [display, setDisplay] = useState(displayImportMessage);

  const hidePermanently = () => {
    MyStore.set('displayImportMessage', { displayImportMessage: false });
    setDisplay(false);
  };

  return (
    display && (
      <Paper elevation={4}>
        <Grid container item xs={12} justifyContent="flex-end">
          <Tooltip title={labels.hideTemporarily}>
            <IconButton onClick={() => setDisplay(false)}>
              <HighlightOff />
            </IconButton>
          </Tooltip>
        </Grid>
        <Typography
          variant="body1"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: labels.text }}
        />
        <Grid container item xs={12} justifyContent="flex-end">
          <Button onClick={hidePermanently}>{labels.hidePermanently}</Button>
        </Grid>
      </Paper>
    )
  );
};

export default ImportMessage;
