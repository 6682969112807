import React, { useState } from 'react';
import { InputAdornment } from '@material-ui/core';

import icons from '../../../common/anamnesis/icons/AnamnesisIcons';
import TextFieldFormControl from '../../../TextFieldFormControl';

type Props = {
  id?: string,
  label: string,
  placeholder?: string,
  span?: number,
  myValue?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function
};

const Number = ({ id, label, placeholder, span, myValue: propValue, changeHandler }: Props) => {
  const [myValue, setMyValue] = useState(propValue || '');
  return (
    <TextFieldFormControl
      id={id || label}
      controlName={id}
      itemMdValue={span}
      label={label}
      placeholder={placeholder}
      fullWidth
      value={myValue}
      onChangeHandler={event => setMyValue(event.target.value)}
      onBlurHandler={() => changeHandler({ target: { name: id, value: myValue } })}
      inputType="time"
      inputLabelProps={{ shrink: true }}
      inputProps={{
        endAdornment: <InputAdornment position="end">{icons['icon-clock']}</InputAdornment>
      }}
    />
  );
};

Number.defaultProps = {
  id: '',
  span: 12,
  placeholder: '',
  myValue: '',
  changeHandler: () => {}
};

export default Number;
