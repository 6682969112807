import React, { Fragment, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import labels from '../../../constants/labels/patient/patientConsult/CurrentOperations.json';
import routes from '../../../constants/routes';
import OkCancelDialog from '../../common/OkCancelDialog';
import patientConsultStyle from './patientConsultStyle.json';
import Patient from '../../common/data/Patient';
import { exportAnonymousPatient, exportPatient } from '../../../actions/PatientExport';
import OnLineOffLineSwitcher from '../../common/OnLineOffLineSwitcher';
import PatientType from '../Patient';

const useStyle = makeStyles({ ...patientConsultStyle });

type Props = {
  patient: PatientType,
  loadDatas: () => void
};

const CurrentOperations = ({ patient, loadDatas }: Props) => {
  const classes = useStyle();
  const [dialogOpened, setDialogOpened] = useState(false);
  const history = useHistory();

  const handleDeleteConfirmation = confirmation => async () => {
    if (confirmation) {
      await Patient.delete(patient._id);
      history.push(routes.HOME);
    }
    setDialogOpened(false);
  };

  const chooseFileAndExportPatient = async () => {
    await exportPatient(patient._id, `${patient.lastName}_${patient.firstName}.patient`);
  };

  const chooseFileAndExportAnonymousPatient = async () => {
    await exportAnonymousPatient(patient._id, "PatientAnonyme.patient");
  };

  const switchArchive = async () => {
    await Patient.setArchived(patient._id, !patient.archived);
    await loadDatas();
  };

  return (
    <Fragment>
      <Grid item xs={12} container>
        <Typography variant="h6">{labels.currentOperation.title}</Typography>
      </Grid>
      <Paper className={classes.paper}>
        <Grid item xs={12} container>
          <OnLineOffLineSwitcher
            onLine={
              <Grid item xs={12}>
                <Typography variant="body1">
                  {labels.currentOperation.phrase.part0}
                  <Button
                    component={Link}
                    className={classes.noPadding}
                    to={routes.PATIENT.getForPath({ path: routes.PATIENT.REPORT.GENERATE, patientId: patient._id })}
                  >
                    {labels.currentOperation.phrase.link2}
                  </Button>
                  {labels.currentOperation.phrase.part3}
                </Typography>
              </Grid>
            }
          />
          <Grid item xs={12}>
            <Typography variant="body1">
              {labels.currentOperation.phrase.part0}
              <Button className={classes.noPadding} onClick={chooseFileAndExportPatient}>
                {labels.currentOperation.phrase.link0}
              </Button>
              {labels.currentOperation.phrase.part1}
              <Button className={classes.noPadding} onClick={chooseFileAndExportAnonymousPatient}>
                {labels.currentOperation.phrase.link1}
              </Button>
              {labels.currentOperation.phrase.part2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {labels.currentOperation.phrase.part0}
              <Button className={classes.noPadding} onClick={switchArchive}>
                {patient.archived ? labels.archiveOperations.unarchive : labels.archiveOperations.archive}.
              </Button>
            </Typography>
          </Grid>
          {patient.archived && (
            <Grid item xs={12}>
              <Typography variant="body1">
                {labels.currentOperation.phrase.part0}
                <Button
                  className={classes.noPadding}
                  onClick={() => {
                    setDialogOpened(true);
                  }}
                >
                  {labels.archiveOperations.delete}
                </Button>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
      <OkCancelDialog
        open={dialogOpened}
        handleClose={handleDeleteConfirmation}
        title={labels.chrono.resetConfirmTitle}
        contentText={labels.chrono.resetConfirmText}
      />
    </Fragment>
  );
};

export default CurrentOperations;
