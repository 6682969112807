import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import TypoInGrid from "./TypoInGrid";
import labels from "../../../../constants/labels/patient/test/TestFillingHeader.json";

// eslint-disable-next-line react/prop-types
const TestFillingHeaderColumns = ({displayCalculatedPercentile, headers, columnWidth, style}) => <Fragment>
  <Grid item md={displayCalculatedPercentile ? 9 : 10} container>
    {headers.map(({label, ...props}) => (
      <TypoInGrid key={label} label={label} size={columnWidth} style={style} {...props} />
    ))}
  </Grid>
  {displayCalculatedPercentile && (
    <TypoInGrid
      style={style}
      condition={displayCalculatedPercentile}
      label={labels.resultColumnTitles.pc}
      size={1}
      emptyBlocOtherwize
    />
  )}
  <TypoInGrid size={2} style={style} condition label={labels.resultColumnTitles.graph}/>
</Fragment>

export default TestFillingHeaderColumns;
