import React, { Fragment } from 'react';
import { Box, Button, Checkbox, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import labels from '../../constants/labels/common/SelectAllCheckBox.json';
import '../configuration/tests/TestList.css';

type Props = {
  selectAll: { checked: boolean, indeterminate: boolean },
  removeAllValues: () => void,
  addAllValues: () => void,
  values: Array<{ label: string, fn: () => void }> | undefined
};

const useStyles = makeStyles(() => ({
  widthFitContent: {
    width: 'fit-content'
  }
}));

const SelectAllCheckBox = ({ selectAll, removeAllValues, addAllValues, values }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = () => {
    if (selectAll.indeterminate || selectAll.checked) {
      removeAllValues();
    } else {
      addAllValues();
    }
  };

  return (
    <Tooltip title={labels.select}>
      <Box className={classes.widthFitContent}>
        <Checkbox disableRipple className="smallCheckbox" onChange={onChange} {...selectAll} />
        {values && (
          <Fragment>
            <Button
              size="small"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ minWidth: 'unset' }}
            >
              <ArrowDropDown fontSize="small" />
            </Button>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              {values.map(({ label, fn }) => (
                <MenuItem
                  key={label}
                  onClick={() => {
                    handleClose();
                    fn();
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Fragment>
        )}
      </Box>
    </Tooltip>
  );
};

export default SelectAllCheckBox;
