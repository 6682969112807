const isGameScoreType = searchedScoreTypes => ({ scoreType }) => searchedScoreTypes.includes(scoreType);
const isGameScoreTypeAnnotation = isGameScoreType(['A']);
const isGameScoreTypeScore = isGameScoreType(['S', 'T']);
const isGameScoreExpectedScoreId = expectedScoreId => ({ scoreId }) => scoreId === expectedScoreId;
// eslint-disable-next-line flowtype/no-weak-types
const getGameValues = (game: any) => Object.keys(game).map(scoreId => game[scoreId]);

const getGameScores = (games, gameId) => getGameValues(games[gameId]);

const getAnnotationIndex = gameScores => {
  if (gameScores.some(isGameScoreTypeAnnotation)) {
    const [{ position }] = gameScores.filter(isGameScoreTypeAnnotation);
    return position;
  }
  return null;
};

const getScoreDefinition = (gameScores, expectedScoreId) => {
  const filter = gameScores.filter(isGameScoreExpectedScoreId(expectedScoreId));
  const filtered = filter.filter(isGameScoreTypeScore);
  if (filtered.length > 0) {
    const [scoreDefinition] = filtered;
    return scoreDefinition;
  }
  return {};
};

const happyneuron2021FileFormat = {
  calculateRawResult: (eprId, workout, datas, positionCorrectionFunction, doNotImportGameIdAnnotation) => {
    const splitted = eprId.split('_');
    const gameId = parseInt(splitted[1], 10);
    const scoreId = parseInt(splitted[2], 10);
    if (!workout.exercises[gameId.toString()]) {
      return { eprId, score: undefined, annotation: undefined };
    }
    const { details: workoutResult } = workout.exercises[gameId.toString()][0];
    console.log({ gameId, details: workoutResult, datas });

    const gameScores = getGameScores(datas.scores.games, gameId);
    const annotationIndex = getAnnotationIndex(gameScores);
    const { position: scoreIndex } = getScoreDefinition(gameScores, scoreId);

    console.log({ gameId, scoreIndex, annotationIndex });
    const score = workoutResult[positionCorrectionFunction(scoreIndex)];
    if (score === -1) {
      return { eprId, score: undefined, annotation: undefined };
    }
    return {
      eprId,
      score,
      annotation:
        annotationIndex && !doNotImportGameIdAnnotation
          ? workoutResult[positionCorrectionFunction(annotationIndex)]
          : undefined
    };
  },
  executeImport: ({ data, classe, test, positionCorrectionFunction = i => i, rawResultTransformationFunction }) => {
    // returns an object with following keys :
    // count : number of row in passation that can be imported
    // values : an object with entry key the test name and value the properties of the test result
    // calculOption : the calculation table to be used after import
    // calculOptionType: string, 'age' or 'classe' depending on calculation table key
    if (data && data.workout) {
      const values = test.children
        .map(({ id, doNotImportGameIdAnnotation }) =>
          happyneuron2021FileFormat.calculateRawResult(
            id,
            data.workout,
            data,
            positionCorrectionFunction,
            doNotImportGameIdAnnotation
          )
        )
        .map(rawResult => (rawResultTransformationFunction ? rawResultTransformationFunction(rawResult) : rawResult))
        .filter(Boolean)
        .reduce((acc, { eprId, score, annotation }) => {
          acc[eprId] = { score, comment: annotation };
          return acc;
        }, {});
      return { count: Object.entries(values).length, values, calculOption: classe, calculOptionType: 'classe' };
    }
  }
};

export { getGameScores, getAnnotationIndex, getScoreDefinition, happyneuron2021FileFormat };
