import currentTestHasCorrespondingCalculationTable from "./CurrentTestHasCorrespondingCalculationTable";
import getColumns from "./GetColumns";
import { inputIncludesQuali } from "./calculateValue/QualiValue";


const buildValues = (currentTest, selectedCalculusTable) => {
  const currentTestHasCalculOptions = !!currentTest.calcul;
  const selectedCalculusTableInCalculOptions = currentTestHasCorrespondingCalculationTable(
    currentTest,
    selectedCalculusTable
  );
  const disableCentileColumn = !!(
    currentTest.calculProvidesET &&
    currentTest.calculProvidesPc &&
    currentTestHasCalculOptions
  );
  const displayCalculatedPercentile = !!(currentTest.calculProvidesET && currentTest.calculProvidesPc);
  if (currentTestHasCalculOptions && selectedCalculusTableInCalculOptions) {
    const calculationData = selectedCalculusTable
      ? currentTest.calcul.filter(
        ({ id, classes }) => id === selectedCalculusTable || classes === selectedCalculusTable
      )[0].data
      : null;

    const examinations = calculationData
      ? currentTest.children.filter(({ id, input }) => calculationData[id] || inputIncludesQuali(input))
      : [];
    const columns = getColumns(examinations);

    return {
      calculationData,
      examinations,
      columns,
      disableCentileColumn,
      currentTestHasCalculOptions,
      displayCalculatedPercentile
    };
  }
  const examinations = currentTestHasCalculOptions ? [] : currentTest.children || [];
  const columns = getColumns(examinations);

  return {
    calculationData: null,
    examinations,
    columns,
    disableCentileColumn,
    currentTestHasCalculOptions,
    displayCalculatedPercentile
  };
}

export default buildValues;
