import React, { Fragment } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router';

import routes from '../../../../constants/routes';
import SaveCancelRow from '../../../common/SaveCancelRow';
import labels from '../../../../constants/labels/patient/report/GenerationActionRow.json';
import useNavigatorOnLine from '../../../common/UseNavigatorOnLine';

type Props = {
  patientId: string | undefined,
  sexe: string | undefined,
  birthDay: string | undefined,
  plainte: string | undefined,
  rdvDay: string | undefined,
  generateCR: () => void
};

const GenerationActionRow = ({ patientId, birthDay, plainte, sexe, rdvDay, generateCR }: Props) => {
  const isOnLine = useNavigatorOnLine();
  const history = useHistory();

  if (!isOnLine) {
    return (
      <div style={{ width: '100%' }}>
        <Alert severity="info">
          <AlertTitle>{labels.currentlyOffLine}</AlertTitle>
          <Typography>{labels.offLineGenerationImpossible}</Typography>
        </Alert>
      </div>
    );
  }

  if (birthDay && plainte && sexe && rdvDay) {
    return (
      <Fragment>
        <Grid item md={10} />
        <Grid item md={2}>
          <Button variant="contained" color="primary" onClick={generateCR}>
            {labels.reportGeneration}
          </Button>
        </Grid>
      </Fragment>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Alert severity="warning">
        <AlertTitle>{labels.warning}</AlertTitle>
        {!birthDay && <Typography>- {labels.birthDay}</Typography>}
        {!plainte && <Typography>- {labels.plainte}</Typography>}
        {!sexe && <Typography>- {labels.sexe}</Typography>}
        {!rdvDay && <Typography>- {labels.rdvDay}</Typography>}
        <SaveCancelRow
          cancelRoute={routes.HOME}
          saveAction={() => history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.EDIT, patientId }))}
          saveIcon={<Edit />}
          saveButtonLabel={labels.editPatient}
        />
      </Alert>
    </div>
  );
};

export default GenerationActionRow;
