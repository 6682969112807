import React, { useEffect, useState } from 'react';
import { Badge, Grid, Tab, Tabs } from '@material-ui/core';
import { Announcement, NewReleases, Person, Star } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

import buildInfo from '../../buildInfo.json';
import labels from '../../constants/labels/UserProfile.json';
import UserForm from './UserForm';
import LicenceForm from './LicenceForm';
// import ImportDatabase from './ImportDatabase';
// import ConvertSqliteDatabase from '../../database/ConvertSqliteDatabase';
import appVersionSpecific from '../../appVersionSpecific.ortho';
import WhatsNew from './WhatsNew';
import routes from '../../constants/routes';
import TeamNews from './TeamNews';
import { loadTeamNews, getLastestNewsDate } from '../common/TeamNewsUtil';
import MyStore from "../../actions/MyStore";

function buildTabAndComponentList(badgeVisible, teamNewsBadgeVisible) {
  const isHappyNeuron = appVersionSpecific.isHappyNeuron;

  // function canImportOldDb() {
  //   return (
  //     !ConvertSqliteDatabase.isOldDatabaseTransferred() &&
  //     ConvertSqliteDatabase.oldDatabaseFileExist() &&
  //     appVersionSpecific.canImportOldOrthoscribeDatabase
  //   );
  // }


  const tabList = [];
  const componentList = [];
  // if (canImportOldDb()) {
  //   tabList.push(<Tab key={0} label={labels.tabs.importDatabase} icon={<ImportExport />} />);
  //   componentList.push(<ImportDatabase key={0} />);
  // }
  tabList.push(<Tab key={1} label={labels.tabs.user} icon={<Person />} />);
  componentList.push(<UserForm key={1} />);
  if (!isHappyNeuron) {
    tabList.push(<Tab key={2} label={labels.tabs.licence} icon={<Star />} />);
    componentList.push(<LicenceForm key={2} />);
  }

  tabList.push(
    <Tab
      key={3}
      label={labels.tabs.teamInfo}
      icon={
        <Badge color="error" badgeContent="" invisible={!teamNewsBadgeVisible}>
          <Announcement />
        </Badge>
      }
    />
  );
  componentList.push(<TeamNews key={3} />);

  tabList.push(
    <Tab
      key={4}
      label={labels.tabs.whatsNew}
      icon={
        <Badge color="error" badgeContent="" invisible={!badgeVisible}>
          <NewReleases />
        </Badge>
      }
    />
  );
  componentList.push(<WhatsNew key={4} />);
  return { tabList, componentList };
}

export default function UserProfile() {
  const location = useLocation();
  const history = useHistory();
  const { state: { selectedTab = 0 } = {} } = location;
  const [badgeVisible, setBadgeVisibility] = useState(true);
  const [teamNewsBadgeVisible, setTeamNewsBadgeVisibility] = useState(true);

  useEffect(() => {
    loadBadgeVisibility();
  }, []);

  const loadBadgeVisibility = async () => {
    const latestVersionNewsDisplayed = MyStore.get(appVersionSpecific.electronStoreLatestVersionNewDisplayed, '');
    setBadgeVisibility(buildInfo.version !== latestVersionNewsDisplayed);
    const latestVersionTeamNewsDisplayed = MyStore.get(appVersionSpecific.electronStoreLatestNewsDisplayed, 0);
    const { payload } = await loadTeamNews();
    const postingDate = getLastestNewsDate(payload);
    setTeamNewsBadgeVisibility(Number(postingDate) !== Number(latestVersionTeamNewsDisplayed));
  };

  const { tabList, componentList } = buildTabAndComponentList(badgeVisible, teamNewsBadgeVisible);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <Tabs
          value={selectedTab}
          onChange={(e, nv) => {
            history.replace({ pathname: routes.USER.PROFILE, state: { selectedTab: nv } });
          }}
        >
          {/* eslint-disable-next-line react/prop-types */}
          {tabList}
        </Tabs>
      </Grid>
      <Grid item md={10} container>
        {/* eslint-disable-next-line react/prop-types */}
        {componentList.map((component, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <div
            key={index}
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '100%' }}
          >
            {selectedTab === index && component}
          </div>
        ))}
      </Grid>
    </Grid>
  );
}
