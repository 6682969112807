import React, { Component, Fragment } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { Add, Edit, GetApp, Unarchive } from '@material-ui/icons';
import uuidv4 from 'uuid/v4';

import routes from '../../../../constants/routes';
import labels from '../../../../constants/configuration/tests/personal/PersonalTestList.json';
import { compress, download, uncompress } from '../../../../actions/FileImportExport';
import CustomTests from '../../../common/data/CustomTests';
import log from '../../../Logger';
import ListStyle from '../../../../constants/styles/ListStyle.json';

class PersonalTestList extends Component {
  props: Props;

  static exportTest(idToExport) {
    return async () => {
      const testToExport = await CustomTests.retrieveCustomTestsById(idToExport);
      const { _id, _rev, dbtype, ...cleanedTest } = testToExport;
      const {
        age,
        authors,
        children,
        classe,
        cotationtype,
        description,
        difficultyThreshold,
        editor,
        idcounter,
        label,
        options,
        patho,
        pubDate,
        type
      } = cleanedTest;
      const filename = cleanedTest.testName;
      const compressedContent = compress(
        JSON.stringify(
          {
            ...cleanedTest,
            test: {
              age,
              authors,
              children,
              classe,
              cotationtype,
              description,
              difficultyThreshold,
              editor,
              idcounter,
              label,
              options,
              patho,
              pubDate,
              type
            }
          },
          null,
          4
        ),
        'TEST'
      );
      download(`${filename}.test`, compressedContent);
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      customTests: []
    };
    this.importTest = this.importTest.bind(this);
  }

  async componentDidMount() {
    await this.updateTestList();
  }

  async updateTestList() {
    try {
      const customTests = await CustomTests.findAll();
      this.setState({ customTests });
    } catch (err) {
      log.error('Error retrieving user custom tests', err);
    }
  }

  delete = idToDelete => async () => {
    const { customTests } = this.state;
    const test = customTests.filter(({ _id }) => _id === idToDelete);
    await CustomTests.delete(test[0]);
    await this.updateTestList();
  };

  async importTest(event) {
    const filePath = event.target.files[0]
    if (!filePath) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const { data } = await uncompress(fileReader.result);
      const { test, uuid, ...importedTest } = JSON.parse(data);
      console.log({ data, test, importedTest });
      importedTest._id = (test.idcounter || (uuid && uuid.replace('{', '').replace('}', '')) || uuidv4()).toString();
      await CustomTests.save({ ...importedTest, ...test });
      await this.updateTestList();
    };
    fileReader.readAsArrayBuffer(filePath);
  }

  render() {
    const { customTests } = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid item md={12}>
          <Typography variant="h6">{labels.mainHeader}</Typography>
          <Typography variant="subtitle2">{labels.secondHeader}</Typography>
          <Typography variant="body2">{labels.thirdHeader}</Typography>
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={12} container>
          <Grid item md={2}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.CONFIGURATION.TESTS.PERSONAL_TESTS.getForPath('new')}
              startIcon={<Add />}
            >
              {labels.createPersonalTest}
            </Button>
          </Grid>
          <Grid item md={1}>
            <input
              id="contained-button-file"
              type="file"
              hidden
              onChange={this.importTest}
              accept=".test"
            />
            <label htmlFor="contained-button-file">
              <Button variant="outlined" component="span" startIcon={<GetApp />}>
                {labels.importPersonalTest}
              </Button>
            </label>
          </Grid>
          <Grid item md={9} />
        </Grid>
        <Grid item md={12}>
          <List>
            {customTests.map(({ _id, label, description }) => (
              <Box boxShadow={2} className={classes.listItemBox} key={_id}>
                <ListItem
                  button
                  component={Link}
                  to={routes.CONFIGURATION.TESTS.PERSONAL_TESTS.getForPath(_id)}
                  className={classes.orthoscribeList}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText primary={label} secondary={description} />
                  <ListItemSecondaryAction>
                    <Tooltip title={labels.deleteLabel}>
                      <IconButton onClick={this.delete(_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={labels.exportLabel}>
                      <IconButton aria-label={labels.exportLabel} onClick={PersonalTestList.exportTest(_id)}>
                        <Unarchive />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              </Box>
            ))}
          </List>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles({ ...ListStyle })(PersonalTestList);
