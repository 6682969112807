import React, { Component, Fragment } from 'react';
import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import _ from 'lodash';
import { NavigateNext } from '@material-ui/icons';

import Patient from '../../common/data/Patient';
import labels from '../../../constants/labels/patient/AnamnesisFormSend.json';
import EmailFieldFormControl from '../../EmailFieldFormControl';
import Checkup from '../edit/Checkup';
import SaveCancelRow from '../../common/SaveCancelRow';
import routes from '../../../constants/routes';
import AnamnesisSelectionDropDown from './AnamnesisSelectionDropDown';
import AnamnesisModel from '../../common/data/AnamnesisModel';

const styles = () => ({
  bold: {
    fontWeight: 'bold'
  }
});

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  classes: any,
  match: ?{ params: ?{ patientId: ?string } },
  // eslint-disable-next-line flowtype/no-weak-types
  history: any
};

class AnamnesisFormSend extends Component<Props> {
  props: Props;

  state = {
    patient: {},
    formIsValid: false
  };

  async componentDidMount(): void {
    await this.loadDatas();
  }

  loadDatas = async () => {
    const {
      match: {
        params: { patientId }
      }
    } = this.props;

    const patient = await Patient.retrievePatient(patientId);
    console.log({ patient });
    this.setState({ patient });
  };

  onChange = event => {
    const state = _.set(this.state, event.target.name, event.target.value);
    this.setState(state);
  };

  submit = async () => {
    const { patient } = this.state;
    const { history } = this.props;
    /** update anamnesis property in case the selected one has been modified since last sending */
    const model = await AnamnesisModel.get(patient.anamnese.modelId);
    await Patient.updatePatient({
      ...patient,
      anamnese: {
        ...patient.anamnese,
        name: model.name,
        definitions: model.definitions
      }
    });
    history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.ANAMNESIS.MAIL_RECAP, patientId: patient._id }));
  };

  mailIsValid = mailIsValid => {
    this.onChange({ target: { name: 'formIsValid', value: mailIsValid } });
  };

  render() {
    const { classes } = this.props;
    const { patient, formIsValid } = this.state;
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">{labels.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {labels.subtitle}
              <span className={classes.bold}>
                {patient.firstName} {patient.lastName}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly">
          <SaveCancelRow
            cancelRoute={routes.HOME}
            saveButtonLabel={labels.next}
            saveAction={this.submit}
            saveIcon={<NavigateNext />}
            saveTooltip={labels.next}
            saveActionDisabled={!formIsValid}
          />
          <EmailFieldFormControl
            id="patient.contactMail"
            controlName="patient.contactMail"
            itemMdValue={12}
            fullWidth
            label={labels.contactEmail}
            value={patient.contactMail}
            onChangeHandler={this.onChange}
            mailIsValid={this.mailIsValid}
          />
          <Grid item xs={12}>
            <Checkup
              patient={patient}
              checkupTypeVisible={false}
              onChange={event => {
                this.onChange({
                  target: {
                    name: `patient.${event.target.name}`,
                    value: event.target.value
                  }
                });
              }}
            />
          </Grid>
          {patient._id && (
            <Fragment>
              <AnamnesisSelectionDropDown patientId={patient._id} componentHasChangedPatient={this.loadDatas} />
              <Grid item xs={8} />
            </Fragment>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AnamnesisFormSend);
