import React, { useState } from 'react';
import { InputAdornment } from '@material-ui/core';

import TextFieldFormControl from '../../../TextFieldFormControl';
import icons from '../../../common/anamnesis/icons/AnamnesisIcons';

type Props = {
  id?: string,
  label: string,
  placeholder?: string,
  icon?: string,
  span?: number,
  myValue?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function,
  inputProps?: {}
};

const IconPrepend = ({
  id,
  label,
  placeholder,
  span,
  myValue: propValue,
  changeHandler,
  icon,
  inputProps,
  ...props
}: Props) => {
  const [myValue, setMyValue] = useState(propValue || '');
  return (
    <TextFieldFormControl
      {...props}
      id={id || label}
      controlName={id}
      itemMdValue={span}
      label={label}
      placeholder={placeholder}
      fullWidth
      value={myValue}
      onChangeHandler={event => setMyValue(event.target.value)}
      onBlurHandler={() => changeHandler({ target: { name: id, value: myValue } })}
      inputProps={{
        startAdornment: <InputAdornment position="start">{icons[icon]}</InputAdornment>,
        ...inputProps
      }}
    />
  );
};

IconPrepend.defaultProps = {
  id: '',
  placeholder: '',
  icon: '',
  span: 12,
  myValue: '',
  changeHandler: () => {},
  inputProps: {}
};

export default IconPrepend;
