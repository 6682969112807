export const inputIncludesTemps = (input) => input.includes("TEMPS");
export const inputIncludesTempsET = (input) => input.includes("TEMPS_ET");
export const inputIncludesTempsOrTempsET = (input) => inputIncludesTemps(input) || inputIncludesTempsET(input);

export const getTempsOrTempsETValue = (value, percentileObject, testRowData) => ({
  returnValue: {
    score: Number.parseFloat(value).toFixed(0),
    tempsET: (-1 * Math.round(((value - testRowData.moyenne) / testRowData.ET) * 100)) / 100,
    tempsCentile: percentileObject.percentileValue || percentileObject.value,
    inTempsET: true
  },
  percentileObject
});
