import React, {Component} from 'react';
import {Button, Grid, List, Typography, withStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';

import labels from '../../constants/labels/patient/TestListing.json';
import routes from '../../constants/routes';
import Patient from '../common/data/Patient';
import log from '../Logger';
import TestListingHeader from './test/TestListingHeader';
import TestListingMoreActionLine from './test/TestListingMoreActionLine';
import {listDisplayableBilanTests} from './test/TestUtils';
import TestListingStyle from './test/TestListingStyle.json';
import ListStyle from '../../constants/styles/ListStyle.json';
import {TestListingElement} from "./TestListingElement";

type Props = {
  match: { params: { patientId: string } },
  // eslint-disable-next-line flowtype/no-weak-types
  classes: any
};

class TestListing extends Component<Props> {
  state = {
    bilan: [],
    patient: {},
    displayableTests: []
  };

  async componentDidMount() {
    const {
      match: {
        params: { patientId }
      }
    } = this.props;
    try {
      const patient = await Patient.retrievePatient(patientId);
      const { bilan } = patient;
      const selectedTestCodes = (bilan.tests || []).map(test => test.testCode);
      const displayableTests = await listDisplayableBilanTests(selectedTestCodes);

      this.setState({
        bilan,
        patient,
        displayableTests
      });
    } catch (err) {
      log.error('Error retrieving datas', err);
    }
  }

  render() {
    const {
      classes,
      match: {
        params: { patientId }
      }
    } = this.props;
    const { patient, bilan, displayableTests } = this.state;

    return (
      <Grid container>
        <TestListingHeader
          bilan={bilan}
          patient={patient}
          switchState={false}
          switchRoute={routes.PATIENT.TESTS.getForPath({
            path: routes.PATIENT.TESTS.LISTING_BY_MODEL,
            patientId,
            bilanId: bilan._id
          })}
        />
        <Grid container item md={12} className={classes.belowHeader}>
          {displayableTests && displayableTests.length !== 0 ? (
            <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly">
              <Grid xs={12} item container>
                <Grid item xs={9}>
                  <Typography variant="h6" className={classes.paddingLeft56}>
                    {labels.test.name}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h6">{labels.test.seizedCount}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">{labels.test.total}</Typography>
                </Grid>
              </Grid>
              <Grid xs={12} item>
                <List component="nav">
                  {displayableTests.map(test =>
                    <TestListingElement
                      key={test.testCode}
                      patient={patient}
                      test={test}
                      bilanId={bilan._id}
                    />
                  )}
                </List>
              </Grid>
            </Grid>
          ) : (
            <Grid xs={12} item>
              <Typography variant="h4">{labels.noTestSelected}</Typography>
            </Grid>
          )}
          <Grid xs={12} item>
            <Button
              component={Link}
              to={routes.PATIENT.CHECKUP.getForPath(routes.PATIENT.CHECKUP.COMMENTS, patientId, bilan._id)}
              variant="contained"
              color="primary"
            >
              {labels.editComment}
            </Button>
          </Grid>
          <TestListingMoreActionLine patientId={patientId} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles({ ...TestListingStyle, ...ListStyle })(TestListing);
