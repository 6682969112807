import React from "react";

import labels from "../../../../constants/labels/configuration/memorizedTexts/CheckupComments.json";
import reportCommentslabels from "../../../../constants/labels/patient/comments/ReportComments.json";
import CommentType from "./CommentType";

const reportComments = [
  { label: reportCommentslabels.synthesis, memoKey: 'comment-summary' },
  { label: reportCommentslabels.speechDiagnostic, memoKey: 'comment-diagnosis' },
  { label: reportCommentslabels.supportElements, memoKey: 'comment-patientCare' },
  { label: reportCommentslabels.therapeuticProject, memoKey: 'comment-therapeuticPlan' }
];

type Props = {
  changeSelectedTestCodeAndMemoKey: (string, string) => void,
  filteredMemoKeys: Array<string>,
  selectedMemoKey: string | null | undefined
};

const CheckupComments = ({changeSelectedTestCodeAndMemoKey, filteredMemoKeys, selectedMemoKey}: Props) => <CommentType
    label={labels.checkupComments}
    listItems={reportComments
      .filter(({ memoKey }) => {
        if (!filteredMemoKeys || !filteredMemoKeys.length) return true;
        return filteredMemoKeys.includes(memoKey);
      })
      .map(({ memoKey, label }) => ({label, key: memoKey, selected: memoKey === selectedMemoKey, onClick: () => {changeSelectedTestCodeAndMemoKey({}, memoKey);}}
      ))
    }
  />;

export default CheckupComments;
