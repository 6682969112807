import React, {Fragment, useState} from "react";
import {
  Collapse, FormControlLabel,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import mtpStyle from "../MemorizedTextsPageStyle";

const useStyles = makeStyles(mtpStyle);

type Props = {
  label: string,
  listItems: Array<{
    key: string, selected: boolean, onClick: () => void, label: string
  }>,
  listItemSecondaryAction?: {
    label: string,
    control: React.ReactNode
  } | undefined
};

const CommentType = ({label, listItemSecondaryAction, listItems}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return <Fragment>
    <ListItem button className={classes.root} onClick={() => setOpen(!open)}>
      <ListItemIcon>
        <IconButton edge="start" aria-label={open ? "expand": "collapse"}>
          {open ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </IconButton>
      </ListItemIcon>
      <ListItemText primary={label}/>
      {listItemSecondaryAction && <ListItemSecondaryAction>
        <FormControlLabel
          control={listItemSecondaryAction.control}
          label={listItemSecondaryAction.label}
        />
      </ListItemSecondaryAction>}
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      {listItems.map(({key, selected, onClick, label: liLabel})=><ListItem
        key={key}
        button
        selected={selected}
        onClick={onClick}
        className={classes.nested}
      >
        <ListItemText primary={liLabel} />
      </ListItem>)}
    </Collapse>
  </Fragment>
};

CommentType.defaultProps = {
  listItemSecondaryAction: null
};

export default CommentType;
