import pako from 'pako';

async function uncompress(fileContent) {
  try {
    console.log(fileContent)
    const uint8Array = new Uint8Array(fileContent);
    const indexCarriageReturn = uint8Array.indexOf(10);
    const header = fileContent.slice(0, indexCarriageReturn);
    const length = fileContent.slice(indexCarriageReturn + 1, indexCarriageReturn + 5);
    const compressedData = fileContent.slice(indexCarriageReturn + 5);
    console.log("compressedData", compressedData);
    console.log("length", length);
    const uncompressedData = pako.inflate(compressedData, {to: 'string'});
    console.log("4", uncompressedData);
    return { header, data: uncompressedData };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

function compress(data, type) {
  const fourByteHeader = longToByteArray(data.length);
  const header = `ORTHOSCRIBE/${type}/1\n`;
  const headerAsHexa = stringToUint8Array(header);
  const encodedData = new TextEncoder().encode(data);
  const compressedData = pako.deflate(encodedData);
  return concatenateUint8Arrays(headerAsHexa, fourByteHeader, compressedData);
}

function concatenateUint8Arrays(...arrays) {
  // Calculate the total length of the concatenated array
  const totalLength = arrays.reduce((acc, arr) => acc + arr.length, 0);
  // Create a new array with the total length
  const result = new Uint8Array(totalLength);
  // Keep track of the current position to copy elements
  let position = 0;
  // Copy elements from each input array into the result array
  arrays.forEach(arr => {
    result.set(arr, position);
    position += arr.length;
  });
  return result;
}

function stringToUint8Array(str) {
  return new Uint8Array(str.split('').map(c => c.charCodeAt(0)& 0xFF));
}

function download(filename, content) {
  const blob = new Blob([content], { type: 'application/octet-stream' });
  //create a ObjectURL in order to download the created file
  const url = window.URL.createObjectURL(blob);
  //create a hidden link and set the href and click it
  const a = document.createElement("a");
  a.style = "display: none";
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}

const longToByteArray = long => {
  // we want to represent the input as a 8-bytes array
  const byteArray = new Uint8Array(4);
  let val = long;
  for (let index = byteArray.length - 1; index > 0; index -= 1) {
    // eslint-disable-next-line no-bitwise
    const byte = val & 0xff;
    byteArray[index] = byte;
    val = (long - byte) / 256;
  }
  return byteArray;
};

export { longToByteArray, uncompress, compress, download };
