import React, { Fragment, useState } from 'react';
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import parse from 'json-templates';
import OkCancelDialog from '../../common/OkCancelDialog';
import labels from '../../../constants/labels/configuration/anamnesis/AnamnesisPageList.json';

type Props = {
  definitions: Array<{ id: string, titreCourt: string }>,
  selectedTabIndex: number,
  handleTabChange: () => void,
  deleteDefinition: () => void,
  addDefinition: () => void
};

const AnamnesisPageList = ({
  definitions,
  selectedTabIndex,
  handleTabChange,
  deleteDefinition,
  addDefinition
}: Props) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);

  const displayDeleteConfirmDialog = index => {
    setIndexToDelete(index);
    setDialogOpened(true);
  };

  const handleDeleteConfirmation = confirmation => async () => {
    if (confirmation) {
      deleteDefinition(indexToDelete)();
    }
    setIndexToDelete(null);
    setDialogOpened(false);
  };

  const titleToDelete =
    definitions.length > 0 && Number.isInteger(indexToDelete) ? definitions[indexToDelete].titreCourt : '';

  return (
    <Fragment>
      <List dense component="nav">
        {definitions.map(({ id, titreCourt }, index) => (
          <ListItem key={id} button selected={selectedTabIndex === index} onClick={handleTabChange(id, index)}>
            <ListItemText primary={titreCourt} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => displayDeleteConfirmDialog(index)}>
                <Clear />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem button onClick={addDefinition}>
          <ListItemText primary={labels.addDefinition} />
        </ListItem>
      </List>
      <OkCancelDialog
        open={dialogOpened}
        handleClose={handleDeleteConfirmation}
        title={labels.deleteDialogTitle}
        contentText={parse(labels.deleteDialogContent)({ titleToDelete })}
      />
    </Fragment>
  );
};

export default AnamnesisPageList;
