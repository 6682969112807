import labels from "../../../../../constants/labels/patient/test/TestFillingHeader.json";
import {
  inputIncludesErrorScoreStandardDeviation
} from "../../testUtils/calculateValue/ErrorScoreStandardDeviationValue";
import {inputIncludesErrorTimeStandardDeviation} from "../../testUtils/calculateValue/ErrorTimeStandardDevitaiton";

const standardDeviationError = ({ input = [], columns = {}, id, testResult = {}, fireTab }) => ({
  header: {
    condition: !!(columns.ERR_SCORE_ET || columns.ERR_TEMPS_ET),
    label: labels.resultColumnTitles.standardDeviationError
  },
  column: {
    score: {
      displayCondition: inputIncludesErrorScoreStandardDeviation(input),
      replacementCondition: !!(columns.ERR_SCORE_ET && !inputIncludesErrorTimeStandardDeviation(input)),
      id: "ERR_SCORE_ET",
      placeholder: "-",
      controlName: `${id}.errScoreET`,
      value: testResult.errScoreET,
      fullWidth: true,
      inputProps: { inputProps: { step: "0.01", onKeyPress: fireTab }
      }
    },
    time: {
      displayCondition: inputIncludesErrorTimeStandardDeviation(input),
      replacementCondition: !!(columns.ERR_TEMPS_ET && !(columns.ERR_SCORE_ET && !inputIncludesErrorTimeStandardDeviation(input))),
      id: "ERR_TEMPS_ET",
      placeholder: "-",
      controlName: `${id}.errTempsET`,
      value: testResult.errTempsET,
      fullWidth: true,
      inputProps: {
        inputProps: { step: "0.01", onKeyPress: fireTab }
      }
    }
  }
});

export default standardDeviationError;
