import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Person, Email, Flag, Home, Phone, Alarm, LocalHospital } from '@material-ui/icons';

const icons = {
  'icon-user': <Person />,
  'icon-envelope': <Email />,
  'icon-flag': <Flag />,
  'icon-home': <Home />,
  'icon-phone': <Phone />,
  'icon-stethoscope': <Icon className="fas fa-stethoscope" />,
  'icon-hospital': <LocalHospital />,
  'icon-clock': <Alarm />
};

export default icons;
