// @flow
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import type { Store } from '../reducers/types';
import Routes from '../Routes';
import routes from '../constants/routes';
import App from './App';
import AppLayout from './AppLayout';
import theme from '../constants/Theme';
import MyStore from '../actions/MyStore';
import { isHappyNeuronImport } from '../actions/LicenceUtil';
import useWindowQuery from '../components/common/useWindowQuery';

type Props = {
  store: Store,
  history: {
    // eslint-disable-next-line flowtype/no-weak-types
    push: Function
  }
};

const Root = ({ store, history }: Props) => {


  const [letterSpacing, setLetterSpacing] = useState(0);
  const [fontFamily, setFontFamily] = useState('Roboto');
  const [testFillingRowCompactDisplay, setTestFillingRowCompactDisplay] = useState(false);
  const query = useWindowQuery();


  useEffect(() => {
    setFontFamily(MyStore.get('fontFamily', 'Roboto'));
    setLetterSpacing(MyStore.get('letterSpacing', 0));
    setTestFillingRowCompactDisplay(MyStore.getAsBoolean('testFillingRowCompactDisplay', false));
  }, []);

  // ipcRenderer.on('OpenAnamnesisEvent', (event, text) => {
  //   history.push({ pathname: routes.IMPORT.ANAMNESIS, state: { filename: text } });
  // });
  //
  // ipcRenderer.on('OpenPatientEvent', (event, text) => {
  //   history.push({ pathname: routes.IMPORT.PATIENT, state: { filename: text } });
  // });
  //
  // ipcRenderer.on('message', (event, text) => {
  //   const title = `${remote.app.getName()} ${remote.app.getVersion()}`;
  //   document.title = `${title} - ${text}`;
  // });

  const ticket = query.get("ticket");
  const userId = query.get("userId");

  const { isHappyNeuronImport: isHnImport } = isHappyNeuronImport({ ticket, userId })

  // --happyneuron  --import-happyneuron-patient "'/home/rodrigo/happyneuron/patient/testPredimem+fex/hnParams.json'"
  if (isHnImport) {
    setTimeout(() => {
      history.push({ pathname: routes.IMPORT.HAPPYNEURON, state: { ticket, userId } });
    }, 1000);
  }

  // if (remote.process.argv.some(string => string.indexOf('.osa') !== -1)) {
  //   const filename = remote.process.argv.filter(string => string.indexOf('.osa') !== -1).shift();
  //   console.log({ argv: remote.process.argv, filename });
  //
  //   setTimeout(() => {
  //     history.push({ pathname: routes.IMPORT.ANAMNESIS, state: { filename } });
  //   }, 1000);
  // }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme({ fontFamily })}>
          <App>
            <AppLayout
              letterSpacing={letterSpacing}
              fontFamily={fontFamily}
              key={`${letterSpacing}${fontFamily}${JSON.stringify(testFillingRowCompactDisplay)}`}
            >
              <Routes />
            </AppLayout>
          </App>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default Root;
