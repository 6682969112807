import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import { testByTestCode } from '../../../constants/tests/tests';
import Assignments from '../tests/personal/assignment/Assignments';

type Props = {
  controlName: string,
  assignmentList: array,
  elements: array,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function,
  testList: array
};

const CheckUpModelTestsAssignment = ({ assignmentList, elements, testList, onChange, controlName }: Props) => {
  const [selectedTestCode, setSelectedTestCode] = useState((testList[0] || {}).testCode);
  const [assignments, setAssignments] = useState(assignmentList.BALE || []);

  useEffect(() => {
    setAssignments(assignmentList[selectedTestCode]);
  }, [assignmentList, selectedTestCode]);

  const onChangeAssignments = e => {
    setAssignments(e.target.value);
    const event = {
      target: {
        name: controlName,
        value: { ...JSON.parse(JSON.stringify(assignmentList)), [selectedTestCode]: e.target.value }
      }
    };
    onChange(event);
  };

  const selectedTest = testByTestCode(testList, selectedTestCode);
  return (
    <Grid container>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <TextField
          select
          value={selectedTestCode}
          onChange={e => {
            setSelectedTestCode(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Menu />
              </InputAdornment>
            )
          }}
        >
          {testList.map(({ label, testCode }) => (
            <MenuItem key={testCode} value={testCode}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Assignments
        tests={selectedTest.children || []}
        assignment={assignments || []}
        elements={elements}
        controlName="assignments"
        onChange={onChangeAssignments}
      />
    </Grid>
  );
};

export default CheckUpModelTestsAssignment;
