import React, { Component } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  NativeSelect,
  Switch,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import labels from '../../../../constants/configuration/tests/personal/PersonalTestEdit.json';
import TextFieldFormControl from '../../../TextFieldFormControl';
import SeuilEditor from '../SeuilEditor';
import TestList from './TestList';
import Assignments from './assignment/Assignments';
import routes from '../../../../constants/routes';
import SaveCancelRow from '../../../common/SaveCancelRow';
import CustomTests from '../../../common/data/CustomTests';
import log from '../../../Logger';

import appVersionSpecific from '../../../../appVersionSpecific.ortho';

const modele = appVersionSpecific.modeleBilanStandard;

type Props = {
  testId: string
};

export default class PersonalTestEdit extends Component<Props> {
  props: Props;

  constructor(props) {
    super(props);
    const id = uuidv4();
    this.state = {
      selectedTabIndex: 0,
      moreElementVisible: false,
      customTest: {
        _id: id,
        uuid: `{${id}}`,
        testName: '',
        type: 'LE',
        description: '',
        json: '',
        patho: 'ET:-1.6449',
        difficultyThreshold: 'ET:-1.3',
        options: ['AUTOCONV'],
        children: [],
        affectation: []
      }
    };
  }

  async componentDidMount() {
    const { testId } = this.props;
    if (testId !== 'new') {
      await this.updateCustomTest(testId);
    }
  }

  async updateCustomTest(testId) {
    try {
      const customTest = await CustomTests.retrieveCustomTestsById(testId);
      log.info('componentDidMount, customtests.get, customTest: ', customTest);
      this.setState({ customTest });
    } catch (err) {
      log.error('Error retrieving customTest data', err);
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ selectedTabIndex: value });
  };

  handleHideShowMoreElement = () => {
    const { moreElementVisible } = this.state;
    this.setState({ moreElementVisible: !moreElementVisible });
  };

  handleAutoConvChange = () => {
    const {
      customTest: { options }
    } = this.state;
    const index = options.indexOf('AUTOCONV');
    const newOptions = [...options];
    if (index > -1) {
      newOptions.splice(index, 1);
    } else {
      newOptions.push('AUTOCONV');
    }
    const event = { target: { name: 'options', value: newOptions } };
    this.handleChange(event);
  };

  handleChange = event => {
    let { customTest } = this.state;
    customTest = _.set(customTest, event.target.name, event.target.value);
    this.setState({ customTest });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { customTest } = this.state;
    await CustomTests.save(customTest);
    await this.updateCustomTest(customTest._id);
  };

  render() {
    const {
      selectedTabIndex,
      moreElementVisible,
      customTest: {
        testName,
        type,
        description,
        affectation,
        patho,
        difficultyThreshold,
        options,
        authors,
        editor,
        pubDate,
        children
      }
    } = this.state;
    const autoconv = options && options.includes('AUTOCONV');
    return (
      <div>
        <Typography variant="h6">{labels.title}</Typography>
        <Divider />
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
            <SaveCancelRow
              cancelRoute={routes.CONFIGURATION.getForPath(routes.CONFIGURATION.TESTS.PERSONAL_TESTS.LIST)}
              saveAction={this.handleSubmit}
            />
            <TextFieldFormControl
              id="testName"
              controlName="testName"
              label={labels.testName}
              value={testName}
              onChangeHandler={this.handleChange}
              margin="none"
            />
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <InputLabel htmlFor="grade-native-helper" shrink>
                  {labels.type}
                </InputLabel>
                <NativeSelect
                  value={type}
                  onChange={this.handleChange}
                  input={<Input name="type" id="type-native-helper" />}
                >
                  <option value="LE">{labels.typeValues.LE}</option>
                  <option value="LO">{labels.typeValues.LO}</option>
                  <option value="LE/LO">{labels.typeValues.LE_LO}</option>
                </NativeSelect>
              </FormControl>
            </Grid>
            <TextFieldFormControl
              id="description"
              controlName="description"
              label={labels.description}
              value={description}
              onChangeHandler={this.handleChange}
              margin="none"
            />
            <SeuilEditor
              label={labels.pathologicalThreshold}
              controlName="patho"
              key={patho}
              value={patho}
              onChangeHandler={this.handleChange}
              defaultValueCent="CENT:5"
              defaultValueET="ET:-1.6649"
              defaultValueText={labels.pathologicalThresholdDefaultValue}
            />
            <Grid item xs={6} />
            <SeuilEditor
              label={labels.difficultyThreshold}
              controlName="difficultyThreshold"
              key={difficultyThreshold}
              value={difficultyThreshold}
              onChangeHandler={this.handleChange}
              defaultValueCent="CENT:10"
              defaultValueET="ET:-1.3"
              defaultValueText={labels.difficultyThresholdDefaultValue}
            />
            <Grid item xs={6}>
              <FormControlLabel
                label={labels.autoConversion}
                control={<Switch checked={autoconv} onChange={this.handleAutoConvChange} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Button onClick={this.handleHideShowMoreElement}>
                {moreElementVisible ? <ExpandLess /> : <ExpandMore />}
              </Button>
            </Grid>
            {moreElementVisible && (
              <Grid item xs={12} container>
                <TextFieldFormControl
                  id="authors"
                  controlName="authors"
                  label={labels.authors}
                  value={authors}
                  itemMdValue={4}
                  onChangeHandler={this.handleChange}
                />
                <TextFieldFormControl
                  id="editor"
                  controlName="editor"
                  label={labels.editor}
                  value={editor}
                  itemMdValue={4}
                  onChangeHandler={this.handleChange}
                />
                <TextFieldFormControl
                  id="pubDate"
                  controlName="pubDate"
                  label={labels.releaseDate}
                  value={pubDate}
                  itemMdValue={4}
                  onChangeHandler={this.handleChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Tabs value={selectedTabIndex} onChange={this.handleTabChange}>
                <Tab label={labels.editTab} />
                <Tab label={labels.assignmentTab} />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              {selectedTabIndex === 0 && (
                <TestList tests={children} onChange={this.handleChange} controlName="children" />
              )}
              <DndProvider backend={HTML5Backend}>
                <Grid container>
                  {selectedTabIndex === 1 && (
                    <Assignments
                      tests={children}
                      assignment={affectation}
                      onChange={this.handleChange}
                      controlName="affectation"
                      elements={modele.elements}
                    />
                  )}
                </Grid>
              </DndProvider>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}
