import React, { Component } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextFieldFormControl from '../../../TextFieldFormControl';

type Props = {
  id?: string,
  label: string,
  placeholder?: string,
  unit?: string,
  span?: number,
  myValue?: string,
  step?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  changeHandler?: Function
};

export default class Number extends Component<Props> {
  props: Props;

  static defaultProps = {
    id: '',
    span: 12,
    unit: '',
    placeholder: '',
    myValue: '',
    changeHandler: () => {},
    step: '0.1'
  };

  state = {
    myValue: ''
  };

  componentDidMount(): void {
    const { myValue } = this.props;
    this.setState({ myValue });
  }

  onChange = event => {
    this.setState({ myValue: event.target.value });
  };

  onBlur = () => {
    const { id, changeHandler } = this.props;
    const { myValue } = this.state;
    changeHandler({ target: { name: id, value: myValue } });
  };

  render() {
    const { id, unit, label, placeholder, span, step } = this.props;
    const { myValue } = this.state;
    const floatStep = parseFloat(step);
    return (
      <TextFieldFormControl
        id={id || label}
        controlName={id}
        itemMdValue={span}
        label={label}
        placeholder={placeholder}
        fullWidth
        value={myValue}
        onChangeHandler={this.onChange}
        onBlurHandler={this.onBlur}
        inputType="number"
        inputProps={{
          inputProps: { step: { floatStep } },
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>
        }}
      />
    );
  }
}
