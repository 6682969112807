import React, { useEffect, useState } from 'react';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Edit, Email } from '@material-ui/icons';
import { useHistory } from 'react-router';

import labels from '../../../constants/labels/patient/anamnesis/AnamnesisModelSelection.json';

import SaveCancelRow from '../../common/SaveCancelRow';
import routes from '../../../constants/routes';
import AnamnesisSelectionDropDown from './AnamnesisSelectionDropDown';
import Patient from '../../common/data/Patient';

type Props = {
  match: ?{ params: ?{ patientId: ?string } }
};

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold'
  },
  bordered: {
    border: '1px solid',
    borderRadius: '5px'
  },
  blueBackground: {
    backgroundColor: 'lightblue'
  }
});

const AnamnesisModelSelection = ({
  match: {
    params: { patientId }
  }
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [patient, setPatient] = useState({});

  useEffect(() => {
    const updatePatient = async () => {
      const p = await Patient.retrievePatient(patientId);
      setPatient(p);
    };

    updatePatient().then();
  }, [patientId]);

  const handleSubmit = action => async () => {
    switch (action) {
      case 'send':
        history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.ANAMNESIS.default, patientId }));
        break;
      case 'fill':
        history.push(routes.PATIENT.getForPath({ path: routes.PATIENT.ANAMNESIS.RESPONSE, patientId }));
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">{labels.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            {labels.subtitle}
            <span className={classes.bold}>
              {patient.firstName} {patient.lastName}
            </span>{' '}
            :
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <SaveCancelRow
        cancelRoute={routes.HOME}
        saveAction={handleSubmit()}
        saveTooltip={labels.apply}
        moreActionsMenu={[
          {
            key: 'entry0',
            element: (
              <Tooltip title={labels.applyAndFill}>
                <IconButton onClick={handleSubmit('fill')} variant="contained" color="primary">
                  <Edit />
                </IconButton>
              </Tooltip>
            )
          },
          {
            key: 'entry1',
            element: (
              <Tooltip title={labels.applyAndSend}>
                <IconButton onClick={handleSubmit('send')} variant="contained" color="primary">
                  <Email />
                </IconButton>
              </Tooltip>
            )
          }
        ]}
      />
      <AnamnesisSelectionDropDown patientId={patientId} />
    </div>
  );
};

export default AnamnesisModelSelection;
