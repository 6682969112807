import React from 'react';
import { SvgIcon } from '@material-ui/core';

type Props = {
  color?: string
};

const MehIcon = ({ color }: Props) => (
  <SvgIcon viewBox="0 0 24 24">
    <path d="M9 14h6v1.5H9z" fill={color} />
    <circle fill={color} cx="15.5" cy="9.5" r="1.5" />
    <circle fill={color} cx="8.5" cy="9.5" r="1.5" />
    <path
      fill={color}
      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
    />
  </SvgIcon>
);

MehIcon.defaultProps = {
  color: 'grey'
};
export default MehIcon;
