import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Grid, MenuItem, Paper, Typography } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import { useHistory } from 'react-router';

import labels from '../../../constants/labels/patient/RenewalCheckupCreate.json';
import SaveCancelRow from '../../common/SaveCancelRow';
import routes from '../../../constants/routes';
import TextFieldFormControl from '../../TextFieldFormControl';
import { frenchClasses } from '../../../constants/Grades';
import Patient from '../../common/data/Patient';
import log from '../../Logger';

type Props = {
  match: ?{ params: ?{ originPatientId: ?string } }
};

const RenewalCheckupCreate = ({
  match: {
    params: { originPatientId }
  }
}: Props) => {
  const [patient, setPatient] = useState({
    firstName: '',
    lastName: '',
    rdvDay: '',
    rdvTime: '',
    plainte: '',
    curclasse: 0,
    curclassefree: ''
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [rdvDay, setRdvDay] = useState('');
  const [rdvTime, setRdvTime] = useState('');
  const [plainte, setPlainte] = useState('');
  const [curclasse, setCurclasse] = useState('');
  const [curclassefree, setCurclassefree] = useState('');
  const [renewal, setRenewal] = useState(0);
  const [initialAssessment, setInitialAssessment] = useState({});
  const history = useHistory();

  useEffect(() => {
    const loadDatas = async () => {
      try {
        const {
          _id,
          _rev,
          rdvDay: mRdvDay,
          rdvTime: mRdvTime,
          plainte: mPlainte,
          curclasse: mCurclasse,
          curclassefree: mCurclassefree,
          bilan,
          ...mPatient
        } = await Patient.retrievePatient(originPatientId);
        log.info('componentDidMount, patientDB.get, patient: ', _id, _rev, mPatient);

        const renewalNumber = (bilan && bilan.renewalNumber) || 0;
        setPatient(mPatient);
        setFirstName(mPatient.firstName);
        setLastName(mPatient.lastName);

        setRdvDay(
          `${moment(new Date(), 'YYYY-MM-DD')
            .add(1, 'day')
            .format('YYYY-MM-DD')}`
        );
        setRdvTime('09:00');
        setPlainte(mPlainte);
        setCurclasse(mCurclasse);
        setCurclassefree(mCurclassefree);
        setRenewal(renewalNumber + 1);
        setInitialAssessment(bilan);
      } catch (err) {
        log.error('Error retrieving patient data', err);
      }
    };

    loadDatas().then();
  }, [originPatientId]);

  const handleSubmit = async event => {
    event.preventDefault();
    const mPatient = await Patient.createPatient(
      {
        ...patient,
        firstName,
        lastName,
        rdvDay,
        rdvTime,
        plainte,
        curclasse,
        curclassefree,
        typeBilan: 'renouvellement',
        initialPatientId: originPatientId,
        archived: false
      },
      renewal,
      initialAssessment,
      {
        definitions: patient.anamnese.definitions,
        values: patient.anamnese.values
      }
    );
    const updatedPatient = await Patient.updatePatient(mPatient);
    setPatient(updatedPatient);
    // after save, go to
    history.push({
      pathname: routes.PATIENT.getForPath({
        path: routes.PATIENT.TESTS.SELECT,
        patientId: updatedPatient._id
      }),
      state: {
        alert: 'renewalTestsAutoSelecteds'
      }
    });
  };

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SaveCancelRow cancelRoute={routes.HOME} saveAction={handleSubmit} />
        <Typography variant="h3">{labels.title}</Typography>
        <Typography variant="h4">{labels.subTitle1}</Typography>
        <Divider />
        <Paper style={{ marginTop: '1em', padding: '0.5em' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4} alignItems="flex-start" justifyContent="space-evenly">
              <TextFieldFormControl
                controlName="firstName"
                id="firstName"
                label={labels.firstName.label}
                placeholder={labels.firstName.placeholder}
                value={firstName}
                onChangeHandler={e => setFirstName(e.target.value)}
                fullWidth
              />
              <TextFieldFormControl
                controlName="lastName"
                id="lastName"
                label={labels.lastName.label}
                placeholder={labels.lastName.placeholder}
                value={lastName}
                onChangeHandler={e => setLastName(e.target.value)}
                fullWidth
              />
              <Grid item xs={6}>
                <KeyboardDatePicker
                  label={labels.checkupDate.label}
                  format="DD/MM/YYYY"
                  value={moment(rdvDay, 'YYYY-MM-DD')}
                  animateYearScrolling
                  onChange={date => setRdvDay(date ? date.format('YYYY-MM-DD') : '')}
                  InputLabelProps={{ shrink: true }}
                  KeyboardButtonProps={{
                    'aria-label': `${labels.date}`
                  }}
                  className="MuiFormControl-fullWidth"
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardTimePicker
                  label={labels.checkupTime}
                  value={moment(rdvTime, 'HH:mm')}
                  onChange={date => setRdvTime(date ? date.format('HH:mm') : '')}
                  ampm={false}
                  keyboardIcon={<AccessTime />}
                  InputLabelProps={{ shrink: true }}
                  className="MuiFormControl-fullWidth"
                />
              </Grid>
              <TextFieldFormControl
                controlName="renewal"
                id="renewal"
                label={labels.renewal}
                value={renewal}
                onChangeHandler={e => setRenewal(e.target.value)}
                fullWidth
                select
              >
                {_.range(20).map(value => (
                  <MenuItem key={value} value={value + 1}>
                    {`${labels.renewal} ${value + 1}`}
                  </MenuItem>
                ))}
              </TextFieldFormControl>

              <TextFieldFormControl
                controlName="plainte"
                id="plainte"
                label={labels.complaint.label}
                placeholder={labels.complaint.placeholder}
                value={plainte}
                onChangeHandler={e => setPlainte(e.target.value)}
                fullWidth
              />
              <TextFieldFormControl
                controlName="curclasse"
                id="curclasse"
                label={labels.schoolClass.label}
                value={curclasse}
                onChangeHandler={e => setCurclasse(e.target.value)}
                fullWidth
                select
              >
                {frenchClasses.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
                <MenuItem key={100} value={100}>
                  {labels.schoolClass.other}
                </MenuItem>
              </TextFieldFormControl>
              <Grid item xs={6} />
              {curclasse === 100 && (
                <TextFieldFormControl
                  controlName="curclassefree"
                  id="curclassefree"
                  label={labels.schoolClass.specifyLabel}
                  value={curclassefree}
                  onChangeHandler={e => setCurclassefree(e.target.value)}
                  fullWidth
                />
              )}
              <Grid item xs={6} />
            </Grid>
          </form>
        </Paper>
      </MuiPickersUtilsProvider>
    </Fragment>
  );
};
export default RenewalCheckupCreate;
