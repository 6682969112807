import Memo from '../../../common/data/Memo';

const getComment = async (
  previousValue,
  value,
  testCode,
  id,
  firstname,
  lastname,
  addUserSelectedMemorizedTextsAsDefaultComment
) => {
  const newComment = (value && value.comment) || '';
  const previousComment = ((previousValue && previousValue.comment) || '').replace(newComment, '');

  const comment = await Memo.verifyIfMemoByTestCodeAndIdHasInsertedByDefaultTextAndAddThem(
    testCode,
    id,
    `${newComment}${previousComment}`,
    firstname,
    lastname,
    addUserSelectedMemorizedTextsAsDefaultComment
  );

  return comment;
};

export default getComment;
