import {inputIncludesTempsOrTempsET} from "./testUtils/calculateValue/TimeOrTimeStandardDeviationValue";
import {inputIncludesTempsCentile} from "./testUtils/calculateValue/TimeCentileValue";
import {Tooltip} from "@material-ui/core";
import labels from "../../../constants/labels/patient/test/TestFillingHeader.json";
import {Alarm, CancelOutlined} from "@material-ui/icons";
import {inputIncludesErrorScoreStandardDeviation} from "./testUtils/calculateValue/ErrorScoreStandardDeviationValue";
import {inputIncludesErrorCentile} from "./testUtils/calculateValue/ErrorCentileValue";
import {inputIncludesErrorTempsCentile} from "./testUtils/calculateValue/ErrorTempsCentileValue";
import React from "react";

const displayTimeIcon = input => inputIncludesTempsOrTempsET(input) || inputIncludesTempsCentile(input);
const displayErrorIcon = input => inputIncludesErrorScoreStandardDeviation(input) || inputIncludesErrorCentile(input) || inputIncludesErrorTempsCentile(input) || inputIncludesErrorScoreStandardDeviation(input);

export const timeOrErrorIcon = ({input = []}) => ({
    header: {
        size: 1,
        condition: false,
        emptyBlocOtherwize: true,
        label: ""
    },
    column: {
        displayCondition: false,
        replacementCondition: true,
        itemMdValue: 1,
        children:
            (displayTimeIcon(input) &&
                <Tooltip title={labels.tooltips.standardDeviationTime}>
                    <Alarm/>
                </Tooltip>)
            ||
            (displayErrorIcon(input) &&
                <Tooltip title={labels.tooltips.percentileError}><CancelOutlined/></Tooltip>)
    }
});