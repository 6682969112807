import getPercentileValue from "./GetPercentileValue";
import {
  getScoreOrScoreETValue,
  inputIncludesScore,
  inputIncludesScoreET
} from "./calculateValue/ScoreOrScoreStandardDeviationValue";
import {
  getTempsOrTempsETValue,
  inputIncludesTemps,
  inputIncludesTempsET
} from "./calculateValue/TimeOrTimeStandardDeviationValue";
import { getCentileValue, inputIncludesCentileKey } from "./calculateValue/CentileValue";
import { getTempsCentileValue, inputIncludesTempsCentile } from "./calculateValue/TimeCentileValue";
import { getErrorTempsCentileValue, inputIncludesErrorTempsCentile } from "./calculateValue/ErrorTempsCentileValue";
import { getScoreADValue, inputIncludeScoreAD } from "./calculateValue/ScoreADValue";
import { getErrorScoreStandardDeviation, inputIncludesErrorScoreStandardDeviation } from "./calculateValue/ErrorScoreStandardDeviationValue";
import { getErrorCentileValue, inputIncludesErrorCentile } from "./calculateValue/ErrorCentileValue";
import { getNS19Value, inputIncludesNS19 } from "./calculateValue/NS19Value";
import isNumeric from "../../../common/isNumeric";

export const matchAndComputeFunction = [
  {name: "inputIncludesScoreET", match: inputIncludesScoreET, compute: getScoreOrScoreETValue},
  {name: "inputIncludesTempsET", match: inputIncludesTempsET, compute: getTempsOrTempsETValue},
  {name: "inputIncludeErrorScoreStandardDeviation", match: inputIncludesErrorScoreStandardDeviation, compute: getErrorScoreStandardDeviation},
  {name: "inputIncludesCentileKey", match: inputIncludesCentileKey, compute: getCentileValue},
  {name: "inputIncludesErrorTempsCentile", match: inputIncludesErrorTempsCentile, compute: getErrorTempsCentileValue},
  {name: "inputIncludeTempsCentile", match: inputIncludesTempsCentile, compute: getTempsCentileValue},
  {name: "inputIncludesScoreAD", match: inputIncludeScoreAD, compute: getScoreADValue},
  {name: "inputIncludeErrorCentile", match: inputIncludesErrorCentile, compute: getErrorCentileValue},
  {name: "inputIncludeNS19", match: inputIncludesNS19, compute: getNS19Value},
  {name: "inputIncludesScore", match: inputIncludesScore, compute: getScoreOrScoreETValue},
  {name: "inputIncludesTemps", match: inputIncludesTemps, compute: getTempsOrTempsETValue},
];

const calculateValues = (testRowData, value, input) => {
  const percentileObject =
    testRowData && testRowData.pct
      ? getPercentileValue({
          value: Number(value),
          pctTable: testRowData.pct.slice(),
          order: testRowData.order || testRowData.walkType,
          indexOfpc50: testRowData.indexOfpc50
        })
      : {};

  const found = matchAndComputeFunction.filter(({match}) => match(input))

  if (found.length) {
    const computeds = found.map(({compute}) => compute(value, percentileObject, testRowData));
    const computed = computeds.reduce((acc, curr) => ({returnValue: {...acc.returnValue, ...curr.returnValue}, percentileObject: {...acc.percentileObject, ...curr.percentileObject}}), {})
    if (!isNumeric(value)) {
      return {...computed, percentileObject: {}, returnValue: {...computed.returnValue, score: undefined, scoreCentile: undefined, scoreET: undefined}};
    }
    return computed
  }

  return { percentileObject };
};

export default calculateValues;
