import { inputIncludesErrorScoreStandardDeviation } from '../../test/testUtils/calculateValue/ErrorScoreStandardDeviationValue';

function cleanMailValue(email) {
  return (email || '').replace('@@', '@').replace('@', '@@');
}

export const applyContentUserCorrection = user => ({
  ...user,
  // #651 : email should have double @ to avoid domain being deleted
  email: cleanMailValue(user.email)
});
export const applyContentPatientCorrection = (patient, includedTestsByName, customTestsByUuid) => ({
  ...patient,
  rsaPrivateKey: undefined,
  rsaPublicKey: undefined,
  rsaKeySize: undefined,
  cotation:
    patient && patient.cotation
      ? applyContentCotationCorrection(patient.cotation, includedTestsByName, customTestsByUuid)
      : undefined,
  contactMail: patient && patient.contactMail ? cleanMailValue(patient.contactMail) : undefined,
  anamnese: patient && patient.anamnese ? applyContentAnamnesisCorrection(patient.anamnese) : undefined
});
export const applyContentAnamnesisCorrection = anamnesis => ({
  ...anamnesis,
  values: {
    ...anamnesis.values,
    email: anamnesis && anamnesis.values && anamnesis.values.email ? cleanMailValue(anamnesis.values.email) : undefined
  }
});
export const applyInitialPatientCorrection = (initialPatient, includedTestsByName, customTestsByUuid) =>
  applyContentPatientCorrection(initialPatient, includedTestsByName, customTestsByUuid);
export const applyContentCotationCorrection = (cotation, includedTestsByName, customTestsByUuid) => {
  if (!cotation) {
    return;
  }
  const orthoEditionTestToExcludeFromDataCorrection = [
    'CHRONODICTEES_A_ET.DICTA_ORTHO_PHONE',
    'CHRONODICTEES_A_ET.DICTA_ORTHO_LEXIC',
    'CHRONODICTEES_A_ET.DICTA_TOMS_SANSHS',
    'CHRONODICTEES_A_ET.DICTA_TOMS',
    'CHRONODICTEES_A_ET.DICTA_OMISS',
    'CHRONODICTEES_A_ET.DICTA_SEGME',
    'CHRONODICTEES_A_ET.DICTA_TOTAL',
    'CHRONODICTEES_B_ET.DICTB_ORTHO_PHONE',
    'CHRONODICTEES_B_ET.DICTB_ORTHO_LEXIC',
    'CHRONODICTEES_B_ET.DICTB_TOMS_SANSHS',
    'CHRONODICTEES_B_ET.DICTB_TOMS',
    'CHRONODICTEES_B_ET.DICTB_OMISS',
    'CHRONODICTEES_B_ET.DICTB_SEGME',
    'CHRONODICTEES_B_ET.DICTB_TOTAL',
    'LEVOLDUPCFIXED.ERR_LOGA',
    'LEVOLDUPCFIXED.ERR_PHON',
    'LEVOLDUPCFIXED.ERR_GLOB',
    'LEVOLDUPCFIXED.AJOU_OMISS',
    'LEVOLDUPCFIXED.INVERSIONS',
    'LEVOLDUPCFIXED.TOT_ERREURS'
  ];

  const errorScoreStandardDeviationTests = Object.entries({ ...includedTestsByName, ...customTestsByUuid })
    .flatMap(([key, value]) =>
      value.children
        .filter(({ input }) => inputIncludesErrorScoreStandardDeviation(input))
        .map(({ id }) => `${key}.${id}`)
    )
    .filter(essdt => orthoEditionTestToExcludeFromDataCorrection.includes(essdt));

  const results = Object.entries(cotation.results)
    .map(([testName, testResults]) => {
      const invertedErrScoreETValues = Object.entries(testResults)
        .filter(([testResultKey]) => errorScoreStandardDeviationTests.includes(`${testName}.${testResultKey}`))
        .map(([testResultKey, testResultValues]) => [
          testResultKey,
          { ...testResultValues, errScoreET: -testResultValues.errScoreET }
        ])
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
      return [testName, { ...testResults, ...invertedErrScoreETValues }];
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  return {
    ...cotation,
    results
  };
};
export const applyDataCorrectionForReportGenerator = content => ({
  ...content,
  cotation: applyContentCotationCorrection(content.cotation, content.includedTestsByName, content.customTestsByUuid),
  initialCotation: applyContentCotationCorrection(
    content.initialCotation,
    content.includedTestsByName,
    content.customTestsByUuid
  ),
  user: applyContentUserCorrection(content.user),
  patient: applyContentPatientCorrection(content.patient, content.includedTestsByName, content.customTestsByUuid),
  anamnesis: applyContentAnamnesisCorrection(content.anamnesis),
  initialPatient: applyInitialPatientCorrection(
    content.initialPatient,
    content.includedTestsByName,
    content.customTestsByUuid
  )
});
