const erasePreviousResults = (currentTest, currentTestResults) => {
  const updatedCurrentTestResults = { ...currentTestResults };
  currentTest.children.forEach(({ id }) => {
    if (currentTestResults && currentTestResults[id]) {
      updatedCurrentTestResults[id].score = undefined;
      updatedCurrentTestResults[id].scoreET = undefined;
      updatedCurrentTestResults[id].scoreCentile = undefined;
      updatedCurrentTestResults[id].tempsET = undefined;
      updatedCurrentTestResults[id].tempsCentile = undefined;
      updatedCurrentTestResults[id].errTempsCentile = undefined;
    }
  });
  return updatedCurrentTestResults;
};

export default erasePreviousResults;
