import { Grid, List } from '@material-ui/core';
import React from 'react';
import { AnamnesisResponse } from './AnamnesisResponse';

const ResponseList = ({fileList, setFilename}) => {
  if (fileList.length > 1) {
    return <Grid item md={12}>
      <List>
        {
          fileList.map(({ fullName, label, size }) =>
            <AnamnesisResponse
              key={fullName}
              fullName={fullName}
              setFilename={setFilename}
              label={label}
              size={size}
            />)
        }
      </List>
    </Grid>;
  }
  return null;
}

export default ResponseList;
