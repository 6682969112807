import React, { Component } from 'react';
import { Button, ButtonProps, Fab, IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

type Props = {
  menuItemEntries: array,
  icon?: React.ReactNode,
  format?: 'iconButton' | 'button' | 'fab',
  buttonText?: string,
  buttonProps?: ButtonProps
};

export default class MoreActionsMenu extends Component<Props> {
  props: Props;

  static defaultProps = {
    icon: <MoreVert color="action" />,
    format: 'iconButton',
    buttonProps: {},
    buttonText: ''
  };

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { menuItemEntries, icon, format, buttonText, buttonProps } = this.props;
    const open = Boolean(anchorEl);
    return (
      <React.Fragment>
        {
          {
            iconButton: (
              <IconButton
                aria-label="More"
                aria-owns={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                {icon}
              </IconButton>
            ),
            button: (
              <Button
                aria-label="More"
                aria-owns={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
                {...buttonProps}
              >
                {icon}
                {buttonText}
              </Button>
            ),
            fab: (
              <Fab
                aria-label="More"
                aria-owns={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
                {...buttonProps}
              >
                {icon}
                {buttonText}
              </Fab>
            )
          }[format]
        }
        <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          {menuItemEntries.map(({ key, element, onClick }) => (
            <MenuItem key={key} onClick={onClick}>
              {element}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}
