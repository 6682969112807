import React, { Fragment, useState } from 'react';
import { Accordion, Chip, Grid, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import AssignmentDragZone from './AssignmentDragZone';
import AssignmentDropZone from './AssignmentDropZone';
import AssignmentExpansionPanelSummary from './AssignmentExpansionPanelSummary';

type Assignment = {
  epr?: string,
  model?: string
};

type Test = {
  id: string,
  input: array<string>,
  label: string
};

type Domain = {
  uuid: string,
  label: string,
  level: number,
  tests: array
};

type Props = {
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function,
  tests: array<Test>,
  assignment: array<Assignment>,
  controlName: string,
  elements: array<Domain>
};

function createDisplayableModel(elements) {
  const result = [];
  let currentTitle = { element: {}, children: [] };
  elements.forEach(el => {
    if (el.level === 0) {
      currentTitle = { element: el, children: [] };
      result.push(currentTitle);
    } else {
      currentTitle.children.push(el);
    }
  });
  return result;
}

const useStyles = makeStyles({
  fixedHeigthWithNoOverflow: {
    height: '60vh',
    overflow: 'auto'
  }
});

function Assignments(props: Props) {
  const { tests, assignment, elements } = props;
  const classes = useStyles();

  const [list] = useState(createDisplayableModel(elements));
  const [currentExpandedPanels, setCurrentExpandedPanels] = useState([]);

  const testEntryById = {};
  if (tests && tests.length) {
    tests.forEach(({ id, label }) => {
      testEntryById[id] = label;
      return null;
    });
  }
  const modelEntryByUuid = {};
  elements.forEach(({ label, uuid }) => {
    modelEntryByUuid[uuid] = label;
    return null;
  });
  const displayableAssignmentsByTests = {};
  const displayableAssignmentsByModel = {};

  for (let i = 0; i < assignment.length; i += 1) {
    const { epr, model } = assignment[i];
    displayableAssignmentsByTests[epr] = modelEntryByUuid[model];
    if (displayableAssignmentsByModel[model]) {
      displayableAssignmentsByModel[model].push(testEntryById[epr]);
    } else {
      displayableAssignmentsByModel[model] = [testEntryById[epr]];
    }
  }

  const handleDelete = id => () => {
    const { onChange, controlName } = props;
    const index = _.findIndex(assignment, a => a.epr === id);
    const newAssignments = [...assignment];
    newAssignments.splice(index, 1);
    const event = { target: { name: controlName, value: newAssignments } };
    onChange(event);
  };

  const addAssignment = (epr, model) => {
    const { onChange, controlName } = props;
    const index = _.findIndex(assignment, a => a.epr === epr);
    const newAssignments = [...assignment];
    if (index !== -1) {
      newAssignments.splice(index, 1);
    }
    newAssignments.push({ epr, model });
    const event = { target: { name: controlName, value: newAssignments } };
    onChange(event);
  };

  const changeCurrentExpandedPanel = id => () => {
    setCurrentExpandedPanels(
      currentExpandedPanels.includes(id)
        ? currentExpandedPanels.filter(item => item !== id)
        : [id, ...currentExpandedPanels]
    );
  };

  return (
    <Fragment>
      <Grid item xs={6} className={classes.fixedHeigthWithNoOverflow}>
        {list.length > 0 &&
          list.map(({ element, children }) => (
            <Accordion
              key={element.uuid || children[0].uuid}
              expanded={currentExpandedPanels.includes(element.uuid)}
              onChange={changeCurrentExpandedPanel(element.uuid)}
              elevation={6}
            >
              <AssignmentExpansionPanelSummary
                element={element}
                changeCurrentExpandedPanel={changeCurrentExpandedPanel}
                currentExpandedPanels={currentExpandedPanels}
              />
              {children.map(({ label, level, uuid }) => (
                // dropZone
                <AssignmentDropZone
                  key={uuid}
                  uuid={uuid}
                  level={level}
                  label={label}
                  displayableAssignmentsByModel={displayableAssignmentsByModel}
                />
              ))}
            </Accordion>
          ))}
      </Grid>
      <Grid item xs={6} className={classes.fixedHeigthWithNoOverflow}>
        <List dense>
          {tests &&
            tests.length &&
            tests.map(({ id, label }) => (
              <ListItem key={id}>
                <AssignmentDragZone label={label} testId={id} onDrop={addAssignment} />
                {displayableAssignmentsByTests[id] ? (
                  <Chip label={displayableAssignmentsByTests[id]} variant="outlined" onDelete={handleDelete(id)} />
                ) : (
                  ''
                )}
              </ListItem>
            ))}
        </List>
      </Grid>
    </Fragment>
  );
}

export default Assignments;
