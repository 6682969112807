import React from 'react';
import {Grid} from "@material-ui/core";
import TypoInGrid from "./TypoInGrid";

const TestFillingInitialHeaderColumns = ({style, headers, columnWidth}) => {
  return <Grid item md={12} lg={6} container>
    <TypoInGrid label="bilan initial" size={12} style={style} condition/>
    <Grid item md={12} container>
      {headers.map(({label, ...props}) => (
        <TypoInGrid key={label} label={label} size={columnWidth} style={style} {...props} />
      ))}
    </Grid>
  </Grid>;
};

export default TestFillingInitialHeaderColumns;
