import _ from "lodash";
import localDatabases from "../../../database/database";
import log from "../../Logger";
import defaultReportGenerationOptions from "../../../constants/user/defaultReportGenerationOptions";

export default class User {
  static TYPE = 'user';

  static USER_INDEX_NAME = 'user.key';

  static async getUser(fields) {
    try {
      const values = await Promise.all(
        fields.map(async field => {
          const value = await this.retrieveValueFromDb({ keyValue: field });
          return value;
        })
      );

      const user = values.reduce((accumulator, row) => {
        accumulator[row.k || row._id] = row.val;
        return accumulator;
      }, {});
      log.info('User.getUser(), found docs :');
      log.table(user);
      return user;
    } catch (err) {
      log.error(`Error retrieving user`, err);
      throw err;
    }
  }

  static async saveReportGenerationOptions(value) {
    await User.save({ keyValue: 'report-generation-options', value });
  }

  static async getReportGenerationOptions() {
    const loadedOptions = await User.retrieveValueFromDb({
      keyValue: 'report-generation-options',
      defaultValue: defaultReportGenerationOptions
    });
    return _.merge({ ...loadedOptions }, { val: defaultReportGenerationOptions }, { val: { ...loadedOptions.val } });
  }

  static async retrieveValueFromDb({ keyValue, defaultValue = null }) {
    try {
      const databaseInstance = localDatabases.getInstance();
      if (databaseInstance) {
        const documents = await databaseInstance.v1databaseRepository.find({
          selector: {
            _id: User.setId(keyValue),
            dbtype: User.TYPE
          }
        });
        const doc = documents.docs[0] || { _id: keyValue, k: keyValue, val: defaultValue };
        // log.info('User.retrieveValueFromDb(), found doc :');
        // log.table(doc);
        return { ...doc, _id: this.getId(doc._id) };
      }
      return { _id: keyValue, k: keyValue, val: defaultValue };
    } catch (err) {
      log.error(`Error retrieving user key ${keyValue}`, err);
      throw err;
    }
  }

  static async findAllValues() {
    const documents = await localDatabases.getInstance().v1databaseRepository.find({
      selector: {
        dbtype: User.TYPE
      }
    });

    const docs = documents.docs
      .map(({ k, ...value }) => ({ _id: User.getId(k), ...value }))
      .reduce((accumulator, row) => {
        accumulator[row.k] = row.val;
        return accumulator;
      }, {});
    log.info('User.findAllValues(), found docs :');
    log.table(docs);
    return docs;
  }

  static async getFeatures() {
    return User.retrieveValueFromDb({keyValue: "features", defaultValue: {}});
  }

  static async getFeature(featureKey){
    const {val: features} = await User.getFeatures();
    return features[featureKey];
  }

  static async isDeveloperMode() {
    return User.getFeature("developerModeEnabled");
  }

  static async save({ keyValue, value }) {
    const id = User.setId(keyValue);
    try {
      const documents = await localDatabases.getInstance().v1databaseRepository.find({
        selector: {
          _id: id,
          dbtype: User.TYPE
        }
      });
      const doc = documents.docs[0] || { _id: id, k: keyValue, val: value, dbtype: User.TYPE };
      await localDatabases
        .getInstance()
        .v1databaseRepository.put({ ...doc, val: value, k: keyValue, dbtype: User.TYPE });
    } catch (err) {
      log.error(`Error when saving user key ${keyValue}`, err);
      throw err;
    }
  }

  static setId(id) {
    if (id && typeof id === 'string' && id.startsWith(User.TYPE)) {
      return id;
    }
    return User.TYPE + id;
  }

  static getId(id) {
    if (id && typeof id === 'string' && id.startsWith(User.TYPE)) {
      return id.substring(User.TYPE.length);
    }
    return id;
  }
}
