// @flow
import React from 'react';
import Edit from './Edit';

type Props = {
  match: ?{ params: ?{ patientId: ?string } }
};

const PatientEdit = (props: Props) => <Edit {...props} />;
export default PatientEdit;
