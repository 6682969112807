import React, { Fragment, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List
} from '@material-ui/core';
import uuidv4 from 'uuid/v4';
import { Close, ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import DomainValue from './DomainValue';
import DomainValueLevel0 from './DomainValueLevel0';
import labels from '../../../constants/labels/configuration/checkupModel/CheckUpModelStructure.json';
import buildOneLevelTreeModelElement from "../../common/chekupModel/BuildOneLevelTreeModelElement";

type Props = {
  modelElements: array,
  controlName: string,
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: Function
};
const useStyles = makeStyles(theme => ({
  accordion: {
    '& .MuiAccordionSummary-content': {
      margin: 0
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

function CheckUpModelStructure({ modelElements, controlName, onChange }: Props) {
  const classes = useStyles();

  const [dialogOpened, setDialogOpened] = useState(false);
  const [toBeMovedElementIndex, setToBeMovedElementIndex] = useState();
  const [toBeMovedHoverIndex, setToBeMovedHoverIndex] = useState();
  const [toBeMovedElementCount, setToBeMovedElementCount] = useState();

  const addChild = index => async () => {
    const [...newElements] = modelElements;
    const { level } = newElements[index];
    newElements.splice(index + 1, 0, {
      level: level + 1,
      label: '',
      uuid: uuidv4()
    });
    const event = { target: { name: controlName, value: newElements } };
    onChange(event);
  };

  const deleteChild = indexToDelete => async () => {
    const [...newElements] = modelElements;
    newElements.splice(indexToDelete, 1);
    const event = { target: { name: controlName, value: newElements } };
    onChange(event);
  };

  const changeLevel = (index, increment) => async () => {
    const [...newElements] = modelElements;
    newElements[index].level += increment;
    if (newElements[index].level < 0) newElements[index].level = 0;
    const event = { target: { name: controlName, value: newElements } };
    onChange(event);
  };

  const changeLabel = (index, value) => {
    const [...newElements] = modelElements;
    newElements[index].label = value;
    const event = { target: { name: controlName, value: newElements } };
    onChange(event);
  };

  const verifyBeforeMoveDomain = async (index, hoverIndex) => {
    const [...newElements] = modelElements;
    const movedElementLevel = newElements[index].level;
    // move only one element if it's the last element or if it has no child
    if (newElements.length === index + 1 || newElements[index + 1].level <= movedElementLevel) {
      moveDomain(index, hoverIndex, 1);
      return;
    }
    await setToBeMovedHoverIndex(hoverIndex);
    const count = newElements.slice(index + 1).findIndex(element => movedElementLevel >= element.level) + 1;
    await setToBeMovedElementCount(count);
    // otherwise, ask if moving the element with or without its children
    await setToBeMovedElementIndex(index);
    setDialogOpened(true);
  };

  const verifyBeforeMoveDomainLevel0 = async (index, hoverIndex) => {
    const [...newElements] = modelElements;
    const movedElementLevel = newElements[index].level;
    // move only one element if it's the last element or if it has no child
    if (newElements.length === index + 1 || newElements[index + 1].level <= movedElementLevel) {
      moveDomain(index, hoverIndex, 1);
      return;
    }
    // move to the end of the hovered element if it's a level 0 element dropped on a level 0 element
    const mTree = buildOneLevelTreeModelElement(modelElements);
    const { nextLevel0ElementIndex } = mTree.find(element => element.index === hoverIndex);
    const mToBeMovedHoverIndex =
      nextLevel0ElementIndex && nextLevel0ElementIndex > 1 ? nextLevel0ElementIndex - 1 : newElements.length;
    const findIndex = mTree.findIndex(element => element.index === index);
    let elementCount;
    if (findIndex === mTree.length - 1) {
      // dropped element is last level 0 element
      const { length } = mTree.find(element => element.index === index).childs;
      elementCount = length + 1;
    } else {
      elementCount = newElements.slice(index + 1).findIndex(element => movedElementLevel >= element.level) + 1;
    }
    // otherwise, ask if moving the element with or without its children
    await setToBeMovedElementIndex(index);
    await setToBeMovedHoverIndex(mToBeMovedHoverIndex);
    await setToBeMovedElementCount(elementCount);
    setDialogOpened(true);
  };

  const moveDomain = (index, hoverIndex, elementCount) => {
    const [...newElements] = modelElements;
    const movedElements = newElements.splice(index, elementCount);
    newElements.splice(hoverIndex < index ? hoverIndex + 1 : hoverIndex - elementCount + 1, 0, ...movedElements);
    const event = { target: { name: controlName, value: newElements } };
    onChange(event);
  };

  const tree = buildOneLevelTreeModelElement(modelElements);

  return (
    <Fragment>
      {tree.map(({ label: parentLabel, level: parentLevel, uuid: parentUuid, index: parentIndex, childs }) => (
        <Accordion className={classes.accordion} key={parentLabel}>
          <AccordionSummary
            key={parentUuid}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <DomainValueLevel0
              label={parentLabel}
              level={parentLevel}
              index={parentIndex}
              addChild={addChild}
              changeLevel={changeLevel}
              deleteElement={deleteChild}
              moveDomain={verifyBeforeMoveDomainLevel0}
              changeLabel={changeLabel}
            />
          </AccordionSummary>
          <AccordionDetails>
            <List dense style={{ width: '100%' }}>
              {childs.map(({ label, level, uuid, index }) => (
                <DomainValue
                  key={uuid}
                  label={label}
                  level={level}
                  index={index}
                  addChild={addChild}
                  changeLevel={changeLevel}
                  deleteElement={deleteChild}
                  moveDomain={verifyBeforeMoveDomain}
                  changeLabel={changeLabel}
                />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
      <Dialog
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {labels.movingDialog.title}
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => setDialogOpened(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{labels.movingDialog.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDialogOpened(false)} color="primary">
            {labels.movingDialog.cancel}
          </Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            onClick={() => {
              moveDomain(toBeMovedElementIndex, toBeMovedHoverIndex, 1);
              setDialogOpened(false);
            }}
            color="primary"
          >
            {labels.movingDialog.elementAlone}
          </Button>
          <Button
            onClick={() => {
              moveDomain(toBeMovedElementIndex, toBeMovedHoverIndex, toBeMovedElementCount);
              setDialogOpened(false);
            }}
            color="primary"
            autoFocus
          >
            {labels.movingDialog.elementWithChildren}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default CheckUpModelStructure;
