import React from 'react';
import { FormControl, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles({
  fieldset: {
    borderRadius: '5px',
    padding: '10px',
    marginTop: '0.25em',
    marginBottom: '0.25em',
    background: 'white',
    '&:focus-within': {
      background: '#ededed'
    }
  }
});

type Props = {
  legend: string,
  // eslint-disable-next-line flowtype/no-weak-types
  children: any
};

export default function FormControlWithLegend({ legend, children }: Props) {
  const classes = useStyle();
  return (
    <FormControl component="fieldset" fullWidth className={classes.fieldset}>
      <Typography variant="h6">{legend}</Typography>
      {children}
    </FormControl>
  );
}
