import React, { Fragment } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import labels from '../../../constants/labels/common/anamnesis/AnamnesisActionPhrase.json';
import routes from '../../../constants/routes';
import { useDeveloperMode } from '../useDeveloperMode';
import appVersionSpecific from '../../../appVersionSpecific.ortho';
import Patient from '../data/Patient';
import { readAnamnesisFile } from '../../patient/anamnesis/AnamnesisFileUtil';

type Props = {
  patientId: string,
  anamnese:
    | {
        status: string | undefined
      }
    | undefined
};

const useStyles = makeStyles({
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'none',
    color: '#48A092'
  }
});

const AnamnesisActionPhrase = ({ patientId, anamnese = {} }: Props) => {
  const classes = useStyles();

  const statusWithDefault = anamnese.status || 'default';

  const isHappyNeuron = appVersionSpecific.isHappyNeuron;

  const isDeveloperMode = useDeveloperMode();

  const { send, sendAgain, part3, link2 } = isHappyNeuron ? labels.happyneuron : labels;

  const importAnamnesisFile = async (event) => {
    const filePath = event.target.files[0]
    if (!filePath) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const fileContent = new TextDecoder().decode(fileReader.result);
      const { unCryptedFileContent } = await readAnamnesisFile(fileContent);
      if (!unCryptedFileContent) {
        return;
      }
      const patient = await Patient.retrievePatient(patientId);
      const { values, customValues } = unCryptedFileContent;
      await Patient.updatePatient({ ...patient, anamnese: { ...anamnese, values, customValues, status: 'RESPONSE' } });
    };
    fileReader.readAsArrayBuffer(filePath);
  };

  return (
    <Grid item xs={12}>
      <Typography variant="body1">
        {labels.part0}
        {labels.part1}
        {!isHappyNeuron && (
          <Fragment>
            <Button
              className={classes.noPadding}
              component={Link}
              to={routes.PATIENT.getForPath({
                path: routes.PATIENT.ANAMNESIS.changeModel,
                patientId
              })}
            >
              {labels.link0}
            </Button>
            {labels.part2}
          </Fragment>
        )}
        <Button
          className={classes.noPadding}
          component={Link}
          to={routes.PATIENT.getForPath({
            path: routes.PATIENT.ANAMNESIS.default,
            patientId
          })}
        >
          {['CREATED', 'default'].includes(statusWithDefault) ? send : sendAgain}
        </Button>
        {part3}
        <Button
          className={classes.noPadding}
          component={Link}
          to={routes.PATIENT.getForPath({
            path: routes.PATIENT.ANAMNESIS.RESPONSE,
            patientId
          })}
        >
          {link2}
        </Button>
      </Typography>
       {isDeveloperMode && ( 
         <Fragment>
           <input
             id="contained-button-file"
             type="file"
             hidden
             onChange={importAnamnesisFile}
             accept=".osa"
           />
           <label htmlFor="contained-button-file">
             <Button variant="outlined" component="span">
               Importer un fichier d&apos;anamnèse
             </Button>
           </label>
         </Fragment>
       )} 
    </Grid>
  );
};

export default AnamnesisActionPhrase;
