import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Add, ArrowLeft, ArrowRight, Cancel, Create, Delete } from '@material-ui/icons';
import classNames from 'classnames';

import labels from '../../../constants/Labels.json';
import '../../common/styles/dragAndDrop.css';
import './DomainValue.css';
import scrolling from '../../common/scrolling';

type Props = {
  label: string,
  level: number,
  index: number
};

type EditableDomainValue = {
  switchEditState: () => void,
  // eslint-disable-next-line react/no-unused-prop-types
  changeLabel: Function
};

type DraggableDomainValue = {
  moveDomain: Function,
  addChild: Function,
  changeLevel: Function,
  deleteElement: Function
};

function DomainValue(props: Props) {
  const [isEditing, setEdition] = useState(false);

  const switchEditState = () => {
    setEdition(!isEditing);
  };

  return isEditing ? (
    <EditingStateDomainValue switchEditState={switchEditState} {...props} />
  ) : (
    <ReadOnlyDomainValue isDragging={false} isOver={false} switchEditState={switchEditState} {...props} />
  );
}

export default DomainValue;

function EditingStateDomainValue(props: Props & EditableDomainValue) {
  const { label, index, level, switchEditState, changeLabel } = props;

  const [value, setValue] = useState(label);

  return (
    <ListItem
      style={{
        paddingLeft: `${level * 2}em`,
        opacity: 1
      }}
      className={`${level === 0 ? "title0" : ''}
          ${level === 1 ? "title1" : ''}
          ${"paddingTopBottom0"}`}
    >
      <ListItemIcon onClick={switchEditState}>
        <Cancel />
      </ListItemIcon>
      <TextField
        value={value}
        fullWidth
        onChange={e => setValue(e.target.value)}
        onBlur={() => {
          changeLabel(index, value);
        }}
        autoFocus
        onKeyPress={ev => {
          if (ev.key === 'Enter') {
            // Do code here
            ev.preventDefault();
            changeLabel(index, value);
            switchEditState();
          }
        }}
      />
    </ListItem>
  );
}

function ReadOnlyDomainValue({
  label,
  index,
  level,
  switchEditState,
  addChild,
  changeLevel,
  deleteElement,
  moveDomain
}: Props & EditableDomainValue & DraggableDomainValue) {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: { index, type: 'domainValue' },
    begin: () => {
      scrolling.addEventListenerForSidebar();
    },
    end: (item, monitor) => {
      scrolling.removeEventListenerForSidebar();
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        moveDomain(item.index, dropResult.hoverIndex);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'domainValue',
    drop: () => ({ hoverIndex: index }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  drag(drop(ref));
  return (
    <div ref={ref} className="paddingTopBottom0">
      <ListItem
        style={{
          paddingLeft: `${level * 2}em`,
          opacity: isDragging ? 0.8 : 1
        }}
        className={classNames(
          {
            "dropZone": isOver,
            "title0": level === 0,
            "title1": level === 1
          },
          "paddingTopBottom0"
        )}
        onClick={switchEditState}
      >
        <ListItemIcon>
          <Create />
        </ListItemIcon>
        <ListItemText primary={label} className="paddingLeft" />
        <ListItemSecondaryAction>
          {(level === 0 || level === 1) && (
            <Tooltip title={labels.configuration.checkUpModels.addSubTitle}>
              <IconButton onClick={addChild(index)}>
                <Add />
              </IconButton>
            </Tooltip>
          )}
          {level > 0 && (
            <Tooltip title={labels.configuration.checkUpModels.decrementLevel}>
              <IconButton onClick={changeLevel(index, -1)}>
                <ArrowLeft />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={labels.configuration.checkUpModels.incrementLevel}>
            <IconButton onClick={changeLevel(index, 1)}>
              <ArrowRight />
            </IconButton>
          </Tooltip>
          <Tooltip title={labels.deleteLabel}>
            <IconButton onClick={deleteElement(index)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
}
