import getFilteredPercentileTableByMaxPercentile from "./GetFilteredPercentileTableByMaxPercentile";

const moreThan95ResultAndMatchFunction = {matchFunction: (value, pctTable) => value > pctTable[pctTable.length-1].v, result :{ percentileValue: 95, label: '> 95', sign: '>' }};
const lessThanMinimalMatchFunction = (value, pctTable) => value < pctTable[0].v;

const resultPerPercentile = {
  95: { percentileValue: 95, label: '95' },
  90: { percentileValue: 90, label: '90' },
  75: { percentileValue: 75, label: 'Q3' },
  50: { percentileValue: 50, label: 'Med.' },
  25: { percentileValue: 25, label: 'Q1' },
  10: { percentileValue: 10, label: '10' },
  7: { percentileValue: 7, label: '7' },
  5: { percentileValue: 5, label: '5' },
};

const percentileMatchFunction = (value, pctTable, index) => value < pctTable[index].v && value >= pctTable[index - 1].v

const calculateExamath58Ascending = ({ value, pctTable }) => {
  if (pctTable.length !== 8) {
    return {};
  }

  const filteredPercentileTableByMaxPercentile = getFilteredPercentileTableByMaxPercentile(pctTable);

  // test for percentile > 95
  if (filteredPercentileTableByMaxPercentile.find(({p}) => p === 95) && moreThan95ResultAndMatchFunction.matchFunction(value, pctTable)) {
    return moreThan95ResultAndMatchFunction.result;
  }

  filteredPercentileTableByMaxPercentile.sort(({p:a}, {p:b}) => a-b);
  const lowestResult = resultPerPercentile[filteredPercentileTableByMaxPercentile[0].p];

  // console.log({value, filteredPercentileTableByMaxPercentile, lowestResult})

  if (lessThanMinimalMatchFunction(value, filteredPercentileTableByMaxPercentile)) {
    return lowestResult;
  }

  const transformedFilteredPctTable = filteredPercentileTableByMaxPercentile.map(({p}, index) => ({matchFunction: percentileMatchFunction, result: resultPerPercentile[p], index}));

  const found = transformedFilteredPctTable.find(({ matchFunction, index }) => matchFunction(value, filteredPercentileTableByMaxPercentile, index));
  return found ? found.result : transformedFilteredPctTable[transformedFilteredPctTable.length -1].result;
};

export default calculateExamath58Ascending;
