export const inputIncludesTempsCentile = (input) => input.includes("TEMPS_CENTILE");

export const getTempsCentileValue = (value, percentileObject) => ({
  returnValue: {
    score: Number.parseFloat(value).toFixed(0),
    tempsCentile: percentileObject.percentileValue || percentileObject.value,
    inTempsCentile: true
  },
  percentileObject
});
